export const translateText = (text) => {
    if (text.indexOf("Change Price Product") >= 0) {
        return "Change_Price_Product"
    }
    else if (text.indexOf("Billing History Ver Facturas Sujeto Excluido") >= 0) {
        return "Billing_History_Ver_Facturas_Sujeto_Excluido";
    }
    else if (text.indexOf("Management Report View Carsem") >= 0) {
        return "Report_Sellers_View"
    }
    else if (text.indexOf("Management Report View General Inventory Summary Carsem") >= 0) {
        return "Report_General_Inventory_Summary_View"
    }
    else if (text.indexOf("Management Report View Past Accounts Receivable Carsem") >= 0) {
        return "Report_Past_Accounts_Receivable_View"
    }
    else if (text.indexOf("Management Report View Summary Expired Vendor Credit Carsem") >= 0) {
        return "Report_Summary_Expired_Vendor_Credit_View"
    }
    else if (text.indexOf("Management Report View Movement Sales Summary Carsem") >= 0) {
        return "Report_Movement_Sales_Summary_View"
    }
    else if (text.indexOf("Management Report View Statement  Accounts Carsem") >= 0) {
        return "Report_Statement_Accounts_View"
    }
    else if (text.indexOf("Management Report View Cash Cut Summary Carsem") >= 0) {
        return "Report_Cash_Cut_Summary_View"
    }
    else if (text.indexOf("Management Report View Accounts Receivable Summary Carsem") >= 0) {
        return "Report_Accounts_Receivable_Summary_View"
    }
    else if (text.indexOf("Create Template") >= 0) {
        return "Create_Template";
    }
    else if (text.indexOf("Changue Name Of Product") >= 0) {
        return "Changue_Name_Of_Product";
    }
    else if (text.indexOf("Search Account") >= 0) {
        return "Search_Account_Global";
    } 
    else if (text.indexOf(" Products History ") >= 0) {   
        return "Products_History";
    }    
    else if (text.indexOf(" Services History ") >= 0) {   
        return "Services_History";
    }   
    else if (text.indexOf("Assignment of Accounts Products Assign Cost Of Sale") >= 0) {
        return "Assign_Cost_Of_Sale";
    } 
    else if (text.indexOf("Assign Inventory ") >= 0) {
        return "Assign_Inventory";
    }
    else if (text.indexOf("Assign Account") >= 0) {
        return "Assign_Account";
    }
    else if (text.indexOf("View Accounts Purchases") >= 0) {
        return "View_Accounts_Purchases";
    }
    else if (text.indexOf("Update Accounts Purchases") >= 0) {
        return "Update_Accounts_Purchases";
    }
    else if (text.indexOf("View Sales Accounts") >= 0) {
        return "View_Sales_Accounts";
    }
    else if (text.indexOf("Update Sales Accounts") >= 0) {
        return "Update_Sales_Accounts";
    }
    else if (text.indexOf("View Other Accounts") >= 0) {
        return "View_Other_Accounts";
    }
    else if (text.indexOf("Update Other Accounts") >= 0) {
        return "Update_Other_Accounts";
    }
    else if(text.indexOf("View Global Withholding Voucher History") >= 0){
        return "View_Global_Withholding_Voucher_History";
    }
    else if(text.indexOf("Create Manual Withholding Tax Receipt") >= 0){
        return "Create_Manual_Withholding_Tax_Receipt";
    }
    else if(text.indexOf("Print Withholding Tax Receipts") >= 0){
        return "Print_Withholding_Tax_Receipts";
    }
    else if (text.indexOf("Print Health Certificate") >= 0) {
        return "Print_Health_Certificate";
    }
    else if (text.indexOf("Print Medical Record") >= 0) {
        return "Print_Medical_Record";
    }
    else if(text.indexOf("Invalidating Withholding Vouchers") >= 0){
        return "Invalidating_Withholding_Vouchers";
    }
    else if(text.indexOf("Make a Credit Memo") >= 0){
        return "Make_a_Credit_Memo";
    }
    else if(text.indexOf("Credit Memo and Credit Purchase Order History") >= 0){
        return "Credit_Memo_and_Credit_Purchase_Order_History";
    }
    else if(text.indexOf("View Product Information") >= 0){
        return "View_Product_Information";
    }
    else if(text.indexOf("Add Products to Supplier") >= 0){
        return "Add_Products_to_Supplier";
    }
    else if (text.indexOf("Billing History Invalidate") >= 0) {
        return "Billing_History_Invalidate";
    }
    else if (text.indexOf("Make Contingencies") >= 0) {
        return "Make_Contingencies";
    }
    else if (text.indexOf("Quotation Billing") >= 0) {
        return "Quotation_Billing";
    }
    else if (text.indexOf("Export Excel") >= 0) {
        return "Export_Excel";
    }
    else if (text.indexOf("PDF") >= 0) {
        return "Export_PDF";
    }
    else if (text.indexOf("Make Credit Memos") >= 0) {
        return "Make_Credit_Memos";
    }
    else if (text.indexOf("View Credit History And Credit Invoices") >= 0) {
        return "View_Credit_History_And_Credit_Invoices";
    }
    else if (text.indexOf("List details") >= 0) {
        return "List_Details";
    }
    else if (text.indexOf("List details") >= 0) {
        return "List_Details";
    }
    else if (text.indexOf("Show Price List") >= 0) {
        return "Select_Price_List";
    }
    else if (text.indexOf("Lista De Precios Editar Precios Originales") >= 0) {
        return "Lista_De_Precios_Editar_Precios_Originales"
    }
    else if (text.indexOf("Lista De Precios") && text.indexOf("Lista De Precios") > 0) {
        return "Lista_De_Precios";
    }
    else if (text.indexOf("List") >= 0) {
        return "View_List";
    }
    else if (text.indexOf("Show Warehouses") >= 0) {
        return "Select_Warehouse";
    }
    else if (text.indexOf("Create Patient") >= 0) {
        return "Create_Patient";
    }
    else if (text.indexOf("Invoice Save") >= 0) {
        return "Invoice_Save";
    }
    else if (text.indexOf("Invoice Delete") >= 0) {
        return "Invoice_Delete";
    }
    else if (text.indexOf("Add Tests") >= 0) {
        return "Add_Tests";
    }
    else if (text.indexOf("Assign Test") >= 0) {
        return "Assign_Test";
    }
    else if (text.indexOf("View Assigned") >= 0) {
        return "View_Assigned";
    }
    else if (text.indexOf("Add Products") && text.indexOf("Add Products") > 0) {
        return "Add_Products";
    }
    else if (text.indexOf("View Products") >= 0) {
        return "View_Products";
    }
    else if (text.indexOf("Select W Principal") >= 0) {
        return "Select_W_Principal";
    }
    else if (text.indexOf("Import Json") >= 0) {
        return "Import_Json";
    }
    else if (text.indexOf("Previous Tests") >= 0) {
        return "Previous_Tests";
    }
    else if (text.indexOf("Save Registry") >= 0) {
        return "Save_Registry";
    }
    else if (text.indexOf("Register visit") >= 0) {
        return "Register_visit";
    }
    else if (text.indexOf("View History") >= 0) {
        return "View_History";
    }
    else if (text.indexOf("Expiring") >= 0) {
        return "Expiring";
    }
    else if (text.indexOf("Save") >= 0) {
        return "Save";
    }
    else if (text.indexOf("View") >= 0) {
        return "View";
    }
    else if (text.indexOf("Create") >= 0) {
        return "Create";
    }
    else if (text.indexOf("Update") >= 0) {
        return "Update";
    }
    else if (text.indexOf("Delete") >= 0) {
        return "Delete";
    }
    else if (text.indexOf("Send") >= 0) {
        return "Send";
    }
    else if (text.indexOf("Print") >= 0) {
        return "Print";
    }
    else if (text.indexOf("Billing") >= 0) {
        return "Billing";
    }
    else if (text.indexOf("Add") >= 0) {
        return "Add";
    }
    else if (text.indexOf("Generate Invoice Save") >= 0) {
        return "Generate_Invoice_Save";
    }
    else if (text.indexOf("Limit") >= 0) {
        return "Limit";
    }
    else if (text.indexOf("Deadlines") >= 0) {
        return "Deadlines";
    }
    else if (text.indexOf("State") >= 0) {
        return "State";
    }
    else if (text.indexOf("Modulo Reportes Ver Reportes Ventas Tab Ganancias") >= 0) {
        return "Modulo_Reportes_Ver_Reportes_Ventas_Tab_Ganancias";
    }
    else if (text.indexOf("Modulo Reportes Ver Reportes Inventario Tab Existencias Sin Costos") >= 0) {
        return "Modulo_Reportes_Ver_Reportes_Inventario_Tab_Existencias_Sin_Costos";
    }
    else if (text.indexOf("Modulo Reportes Ver Reportes Inventario Tab Existencias") >= 0) {
        return "Modulo_Reportes_Ver_Reportes_Inventario_Tab_Existencias";
    }
    else if (text.indexOf("Modulo Reportes Ver Reportes Inventario Tab Ajuste De Inventario") >= 0) {
        return "Modulo_Reportes_Ver_Reportes_Inventario_Tab_Ajuste_De_Inventario";
    }
    else if (text.indexOf("Modulo Reportes Ver Reportes Inventario Tab Promedio Ponderado") >= 0) {
        return "Modulo_Reportes_Ver_Reportes_Inventario_Tab_Promedio_Ponderado";
    }
    else if (text.indexOf("Modulo Reportes Ver Reportes Inventario Tab PEPS") >= 0) {
        return "Modulo_Reportes_Ver_Reportes_Inventario_Tab_PEPS";
    }
    else if (text.indexOf("Modulo Reportes Ver Reportes Inventario Tab UEPS") >= 0) {
        return "Modulo_Reportes_Ver_Reportes_Inventario_Tab_UEPS";
    }
    else if (text.indexOf("Modulo Reportes Ver Reportes Contables Ventas y Compras") >= 0) {
        return "Modulo_Reportes_Ver_Reportes_Contables_Ventas_y_Compras";
    }
    else if (text.indexOf("Modulo Reportes Ver Reportes Contables Exportar CSV") >= 0) {
        return "Modulo_Reportes_Ver_Reportes_Contables_Exportar_CSV";
    }
    else if (text.indexOf("Modulo Reportes Ver Reportes Contables Libros Contables") >= 0) {
        return "Modulo_Reportes_Ver_Reportes_Contables_Libros_Contables";
    }
    else if (text.indexOf("Modulo Reportes Ver Reportes Contables Balances") >= 0) {
        return "Modulo_Reportes_Ver_Reportes_Contables_Balances";
    }
    else if (text.indexOf("Generate Invoice Editar Descuento en POS") >= 0) {
        return "Generate_Invoice_Editar_Descuento_en_POS";
    }
};