<template>
    <window-portal :open="dataProps.show" @close="() => {
        dataProps.show = false
        if(dataProps?.closeCallback){
            dataProps?.closeCallback()
        };
    }
        ">
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center><div><p style="font-size: 30px;">Vista previa de impresión de Cotización</p></div></center>
            <br/>
            <center>
                <button ref="btnPrint" class="btnclean" style="font-size: 18px;" @click="Print()">
                    Imprimir
                </button>
                &nbsp;&nbsp;
                <button ref="btnDownload" class="btnclean" style="font-size: 18px;" @click="Download()">
                    Descargar
                </button>
                &nbsp;&nbsp;
                
            </center>
            <center><div v-if="loading"><p>Cargando...</p></div></center>

        </div>
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center v-if="mailLabel">
                {{ mailLabel }}
            </center>
        </div>
        <div class="book" style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px;">
                <div style="margin: 0 auto; width: 1000px;">
                    <div ref="dataprint" style="margin: 0 !important; padding: 0 !important;">
                        <!--div style="position: relative; width: 1000px; min-height: 1294px; background: white;"-->
                        <div v-for="(page, indexPage) in pages" :key="indexPage" style="position: relative; width: 1000px; min-height: 1293px; background: white;">
                            <hr/>
                            <div class="subpage" style="height: 1250px; padding-top: 20px; overflow: hidden;">
                            
                                <div style="margin-left: 80px; margin-right: 50px;">
                                    
                                    <div style="width: 100%;">
                                        <table style="font-size: 10pt; border-collapse: collapse; width: 100%; vertical-align: top;">
                                            <tr style="padding-right: 20px; padding-bottom: 5px;  color: white !important;">
                                                <td>
                                                    <img v-if="!showCanvas" ref="logo" :src="dataProps.data.businessInfo[0].BusinessLogoPath" width="200px" height="auto" />
                                                </td>
                                                <td style="color: black; text-align: end;">
                                                    <b>Fecha de Emisión:</b> {{ dataProps.data.quoteInfo.InvoiceDate }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2"><b>{{ dataProps.data.businessInfo[0].BusinessName }}</b></td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">{{ dataProps.data.businessInfo[0].DescActividad}}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">{{ dataProps.data.businessInfo[0].BranchLegalAddress}}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">Tel. {{ dataProps.data.businessInfo[0].BranchPhone}}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">Whatsapp. {{ dataProps.data.businessInfo[0].BranchMobile}}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">{{ dataProps.data.businessInfo[0].BranchEmail}}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">NIT: {{ BillingNit }}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    NRC: {{ BillingNrc }}
                                                </td>
                                            </tr>
                                                
                                        </table>
                                    </div>

                                    <hr/>

                                    <div style="width: 100%;">
                                        <table style="font-size: 10pt; border-collapse: collapse; width: 100%;">
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>ID de Cliente:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.quoteInfo.ID_Customer}}</td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Nombre:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.quoteInfo.InvoiceName}}</td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>DUI:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.quoteInfo.InvoiceDUI}}</td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>NIT:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.quoteInfo.InvoiceNIT}}</td>
                                            </tr>

                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>NRC:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.quoteInfo.InvoiceNRC}}</td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Dirección:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 300px !important;">{{dataProps.data.quoteInfo.InvoiceAddress}}</td>
                                            </tr>
                                        </table>
                                    </div>

                                    <div style="width: 100%;">
                                        <table style="width: 100% !important; font-size: 11px; border-collapse: collapse; border: 1px solid black;">
                                            <tr style="background: gray;">
                                                <td colspan="11" style="text-align: center; color: white;">CUERPO DEL DOCUMENTO</td>
                                            </tr>

                                            <tr>
                                                <td style="border: 1px solid; padding-left: 2px; width: 35px;">No.<br/>Item</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 35px;">Tipo<br/>Item</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 35px;">Cant.</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 95px;">Código</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 95px;">Imágen</td>
                                                <td style="border: 1px solid; padding-left: 2px;">Descripcion</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 65px;">Precio<br/>unitario {{dataProps.data.quoteInfo.InvoiceCurrency}}</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 65px;">Descuento {{dataProps.data.quoteInfo.InvoiceCurrency}}</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 65px;">Ventas<br/>exentas {{dataProps.data.quoteInfo.InvoiceCurrency}}</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 65px;">Ventas no<br/>sujetas {{dataProps.data.quoteInfo.InvoiceCurrency}}</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 65px;">Ventas <br/>gravadas {{dataProps.data.quoteInfo.InvoiceCurrency}}</td>
                                            </tr>
                                            <!--valuesRows[indexPage]-->
                                            <tr v-for="(item, index) in valuesRows[indexPage]" :key="index">
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{ (index+1)+(8*indexPage) }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">3</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.ItemQuantity }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.ID_Item }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">
                                                    <center>
                                                    <img :ref="index" v-if="item.ItemType == 'product' && item.ItemImagePath != ''" :src="item.ItemImagePath" width="auto" height="90px" />
                                                    <td style="height: 90px; vertical-align: middle;" v-else> SIN IMÁGEN </td>
                                                    </center>
                                                </td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.ItemDescription }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{ numberWithCommas(parseFloat(item.ItemUnitPrice).toFixed(2)) }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{ numberWithCommas(parseFloat(item.ItemDiscount).toFixed(2)) }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{ numberWithCommas(parseFloat(item.ItemExenta).toFixed(2)) }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{ numberWithCommas(parseFloat(item.ItemNoSuj).toFixed(2)) }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{ numberWithCommas(parseFloat(item.ItemGravada).toFixed(2)) }}</td>
                                            </tr>

                                            <tr v-if="indexPage == (numPages-1)">
                                                <td colspan="6" style="text-align: center; border: 1px solid;"><b>Valor en letras: </b> {{dataProps.data.quoteInfo.InvoiceTotalLetras}}</td>

                                                <td colspan="5" style="border: 1px solid;">
                                                    <table style="width: 100% !important; font-size: 11px; text-align: right;">
                                                        <tr>
                                                            <td>Sub total ventas gravadas</td>
                                                            <td>{{ numberWithCommas(dataProps.data.quoteInfo.InvoiceCurrency)}}</td>
                                                            <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.quoteInfo.InvoiceSubTotalVentas).toFixed(2))}}</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>Total descuentos</td>
                                                            <td>{{dataProps.data.quoteInfo.InvoiceCurrency}}</td>
                                                            <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.quoteInfo.InvoiceTotalDescu).toFixed(2))}}</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>IVA</td>
                                                            <td>{{dataProps.data.quoteInfo.InvoiceCurrency}}</td>
                                                            <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.quoteInfo.InvoiceTotalIva).toFixed(2))}}</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>Sub total</td>
                                                            <td>{{dataProps.data.quoteInfo.InvoiceCurrency}}</td>
                                                            <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.quoteInfo.InvoiceMontoTotalOperacion).toFixed(2))}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IVA Retenido</td>
                                                            <td>{{dataProps.data.quoteInfo.InvoiceCurrency}}</td>
                                                            <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.quoteInfo.InvoiceIvaRete1).toFixed(2))}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IVA Percibido</td>
                                                            <td>{{dataProps.data.quoteInfo.InvoiceCurrency}}</td>
                                                            <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.quoteInfo.InvoiceIvaPerce1).toFixed(2))}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Impuesto sobre la renta</td>
                                                            <td>{{dataProps.data.quoteInfo.InvoiceCurrency}}</td>
                                                            <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.quoteInfo.InvoiceReteRenta).toFixed(2))}}</td>
                                                        </tr>
                                                        <tr style="font-size: 12px;">
                                                            <td><b>Total</b></td>
                                                            <td><b>{{dataProps.data.quoteInfo.InvoiceCurrency}}</b></td>
                                                            <td style="text-align: right; width: 50px;"><b>{{numberWithCommas(parseFloat(dataProps.data.quoteInfo.InvoiceTotalPagar).toFixed(2))}}</b></td>
                                                        </tr> 
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    
                                </div>
                                
                                <div style="position: absolute; bottom: 0; width: 100%; left: 80px">
                                    <p style="font-size:10px;">Pagina {{ indexPage+1 }} de {{ numPages }}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
        </div>
    </window-portal>
</template>

<script>

import jsPDF from "jspdf"
//import html2canvas from "html2canvas"
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue"
import { addFormatToNIT, addFormatNRC } from "../../helpers/misc"
import { numberWithCommas } from "../../helpers/money";
//import html2pdf from 'html2pdf.js'
//import {numberToText} from '@/helpers/conveterNumberText.js'

export default {
    name: "PrintQuotesBill",
    props: ['dataProps'],
    components: {WindowPortal},
    data() {
        return {
            loading: false,
            numPages: 0,
            mailLabel: null,
            valuesRows: [],
            typePrint: 0,
            showCanvas: false
        }
    },
    mounted() {
    },
    computed: {
        fechadehoy() {
            return new Date();
        },
        BillingNrc() {
            return addFormatNRC(this.dataProps.data.businessInfo[0].BusinessBillingNRC)
        },
        BillingNit() {
            //console.log(this.dataProps.data.branch.BusinessBillingNIT);
            return addFormatToNIT(this.dataProps.data.businessInfo[0].BusinessBillingNIT)
        },
        pages() {
            // Generar un arreglo con `numPages` elementos
            return Array.from({ length: this.numPages });
        },
    },
    watch: {
        'dataProps.show'() {
            if(this.dataProps.show){
                setTimeout(async () => {
                    await this.calculatePagesOfDoc()
                    const btnPrint = this.$refs.btnPrint
                    const btnDownload = this.$refs.btnDownload
                    this.loading = true
                    btnPrint.disabled = true
                    btnDownload.disabled = true
                    await this.getImages()
                    btnPrint.disabled = false
                    btnDownload.disabled = false
                    this.loading = false

                }, 0)
            }
        }
    },
    methods: {
        numberWithCommas(x) {
            return numberWithCommas(x);
        },

        calculatePagesOfDoc() {
            let pages = 0
            let rows = 0
            this.valuesRows[pages] = []

            try{
                
                console.log( 'this.dataProps.data ', this.dataProps.data );

                if(this.dataProps.data.items.length < 8){
                    for(let i = 0; i < this.dataProps.data.items.length; i++){
                        this.valuesRows[pages].push(this.dataProps.data.items[i])
                    }
                }
                else{
                    for(let j = 0; j < this.dataProps.data.items.length; j++) {
                        if(rows < 7){
                            rows++
                            this.valuesRows[pages].push(this.dataProps.data.items[j])
                        }
                        else{
                            if(j === this.dataProps.data.items.length - 1) {
                                rows++
                                this.valuesRows[pages].push(this.dataProps.data.items[j])
                                break;
                            }
                            else{
                                this.valuesRows[pages].push(this.dataProps.data.items[j])
                                pages++
                                rows = 0
                                this.valuesRows[pages] = []
                            }
                        }
                    }

                }
                
            }
            catch(err) {
                console.log(err)
            }
            pages = pages + 1
            this.numPages = pages
        },

        async Print(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            })
            
            
            //doc.deletePage(2)

            doc.autoPrint({variant: 'non-conform'});
            const blob = doc.output("bloburl")
            window.open(blob)

            this.loading = false
        },

        async Download(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            });
            
            //doc.deletePage(2)
            doc.save(this.dataProps.data.quoteInfo.ID_Invoice+ "_"+this.dataProps.data.quoteInfo.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.quoteInfo.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".pdf"); //Agregado por Juan 13082023

            this.loading = false
        },

        async getImages() {
            const img = this.dataProps.data.businessInfo[0].BusinessLogoPath
            const { imageBase64: logoImageBase64 } = await this.$API.utilities.getImageInBase64({ "file": img })
            //this.$API.utilities.getImageInBase64({ "file": img }).then(response => {
                //console.log("PRUEBA", response)
            this.dataProps.data.businessInfo[0].BusinessLogoPath = `${logoImageBase64}`

            for(let i = 0; i < this.dataProps.data.items.length; i++){
                if(this.dataProps.data.items[i].ItemType == 'product' && this.dataProps.data.items[i].ItemImagePath != '') {
                    const { imageBase64: itemImageBase64 } = await this.$API.utilities.getImageInBase64({ "file": this.dataProps.data.items[i].ItemImagePath })
                    this.dataProps.data.items[i].ItemImagePath = `${itemImageBase64}`
                }
                
            }
            
        },

    }
}
</script>

<style scoped>
    .u-row .u-col-100{
        width: 100% !important;
    }
    .btnclean{
        border-radius: 15px;
        border: 1px solid white;
  background-color: #F29D35 !important;
  color: white !important;
}
    .btnclose{
        border-radius: 15px;
        border: 1px solid white;
        background-color: rgb(255, 38, 38) !important;
        color: white !important;
       
    }

    * {
        font-family: Arial, Helvetica, sans-serif !important;
    }

 

</style>
