<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="mt-2">
      BUSCAR CLIENTES
      <template v-slot:actions>
        <v-btn class="mr-5" @click.native.stop="reload()" icon small>
            <v-icon color="primary">mdi-reload</v-icon>
        </v-btn>
        <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
            <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card flat class="pa-1" rounded="lg">
        <v-card-text class="pa-0">
          <GeneralFilter  class="background border-box" :filterEndpoint="$API.customers.filterCustomers" :search="{...search, filteredFrom: 0 }" @emptyFilter="getAllCustomers" @filtered="filterHandler">
            <DxDataGrid class="isScrolledByGeneralFilter" :data-source="customers" key-expr="ID_Customer" :show-row-lines="showRowLines"
            @exporting="onExporting"
              :show-borders="showBorders" :row-alternation-enabled="rowAlternationEnabled">
              <DxExport :enabled="true" />
  
              <DxScrolling row-rendering-mode="virtual" />
              <DxPaging :page-size="15" />
              <DxColumn :width="80" cell-template="show-template" v-if="$store.getters['rolesUser/getCustomRole'](78)"
               caption=""></DxColumn>
              <DxColumn data-field="ID_Customer" caption="ID"></DxColumn>
             <!--  <DxColumn cell-template="name-template" caption="Nombre del Cliente" ></DxColumn> -->
              <DxColumn data-field="customerNameToShow" caption="Nombre "></DxColumn>
              <DxColumn data-field="customerEmailToShow" caption="Correo "></DxColumn> 
              <DxColumn data-field="LegalName" caption="Nombre Legal "></DxColumn> 
              <!-- <DxColumn data-field="Email" caption="Correo "></DxColumn> -->
           <!--    <DxColumn cell-template="email-template" caption="Correo"></DxColumn> -->
  
              <!-- <DxColumn data-field="BusinessName" caption="Nombre de Negocio"></DxColumn> -->
              <!-- <DxColumn data-field="BusinessEmail" caption="Correo de Negocio"></DxColumn> -->
  
              <DxColumn data-field="Is_a_Business" falseText="No" trueText="Si" :showEditorAlways="false"
                caption="Es negocio"></DxColumn>
  
              <DxColumn data-field="OldID" caption="Código alterno"></DxColumn> 

              <template #show-template="{ data }">
                <a class="mx-1" @click="customerSelected(data)">
                  <v-icon color="primary">mdi-eye</v-icon>
                </a>
              </template>
              <template #name-template="{ data }">
                <p v-if="data.data.Is_a_Business || data.data.Is_a_Ong  || data.data.Is_a_PublicEntity">
                  {{ data.data.BusinessName }}
                </p>
                <p v-else>
                  {{ data.data.FirstName }} {{ data.data.LastName }}
                </p>
              </template>
              <template #email-template="{ data }">
                <p v-if="data.data.Is_a_Business || data.data.Is_a_Ong  || data.data.Is_a_PublicEntity">
                  {{ data.data.BusinessEmail }}
                </p>
                <p v-else>
                  {{ data.data.Email }}
                </p>
              </template>
  
            </DxDataGrid>
          </GeneralFilter>
          <!-- <div class="background border-box">
            <div>
            </div>
          </div> -->
        </v-card-text>
      </v-card>

    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { DxDataGrid, DxColumn, DxScrolling, DxPaging,DxExport } from 'devextreme-vue/data-grid';
// import CustomerData from "./tabs/CustomerData";
import { mapGetters } from 'vuex';
import { printToExcel } from "@/helpers/printToexcel";
import GeneralFilter from  "@/components/GeneralFilter";

export default {
  name: "CustomersList",
  components: { DxDataGrid, DxColumn, DxScrolling, DxPaging, DxExport, GeneralFilter },
  props: ['win'],
  data() {
    return {
      showCustomer: false,
      customers: [],
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      search: {},
      reloadwindow: 0,
      filter: null,
    }
  },
  mounted() {
    this.search.business = JSON.parse(localStorage.getItem("user")).businessid || ""
    this.search.branch = JSON.parse(localStorage.getItem("user")).branch || ""
    this.getAllCustomers();
  },
  computed: {
    ...mapGetters(['reloadData'])
  },
  watch: {
    reloadData: {
      immediate: true,
      handler: 'reloadCustomers'
    }
  },
  methods: {
    onExporting(e) {
      printToExcel(e, 'Clientes');
    },
    reload(){
      this.getAllCustomers()
      setTimeout(() => {
                window.scrollTo(0, this.$store.getters.getScrollTop)
                //alert(this.$store.getters.getScrollTop)
                },300);
    },
    getAllCustomers() {
      this.$API.customers.getCustomersHistory(this.search)
        .then(response => {
         // response = this.prepareCustomersResponse(response);
          this.customers = response;
         /*  console.log('customers all', response); */
        })
    },
    prepareCustomersResponse(response) {
      return response.map(customer => {
        console.log('preparing ', customer);
        if (customer.Is_a_Business) {
          customer.customerNameToShow = customer.BusinessName;
          customer.customerEmailToShow = customer.BusinessEmail;
        }
        else{
          customer.customerNameToShow = customer.FirstName + ' ' + customer.LastName;
          customer.customerEmailToShow = customer.Email;
        }
        
        return customer;
      })
    },
    filterHandler(response) {
      console.log('filter handler', response);
      this.customers = response;
    },
    async reloadCustomers() {
      if (this.reloadData == 'customers') {
        await this.getAllCustomers();
        this.$store.dispatch('reloadData', null)
      }
    },
    showCustomerHandler(data) {
      this.panel = null;
      this.showCustomer = data;
      if (this.$route.query['cust_id']) {
        let query = Object.assign({}, this.$route.query);
        delete query.cust_id;
        this.$router.replace({ query });
      }
    },
    customerSelected(data) {
      let send = this.customers.filter(customer => customer.ID_Customer == data.data.ID_Customer)
      this.$store.commit('customerData/changeView', 'tab-1'); 
      this.$store.dispatch('addWindow', {
        'name': 'customerData',
        component: 'CustomerData',
        unique: false,
        meta: { customerToShow: send[0] }
      })
    }
  }
}
</script>

<style scoped>
.border-box {
  border-radius: 15px !important;
}
.background {
    background: white !important;
    color: white;
}
</style>
