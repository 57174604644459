import axios from "./axios";
// import Vuex from '../store/index';
import { userData } from "./Currentuserdata";

export default {
    /**
     * Get All Emails
     * @api {Get} manageuser
     */
    getEmails() {
        const user = userData();
        const options = {
            method: 'GET',
            url: 'emails',
            headers: {'content-type': 'application/json'},
            data: {
                ID_Branch: user.branch,
                ID_Business: user.businessid        ,
              }
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                 

                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    addEmail(data) {
        const user = userData();
        const options = {
            method: 'POST',
            url: 'emails',
            headers: {'content-type': 'application/json'},
            data:{
                ID_Branch: user.branch,
                ID_Business: user.businessid        ,
                ...data
            }
        };
        console.log(data);
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                    console.log(response.data)

                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    updateEmail(data) {
        const options = {
            method: 'PUT',
            url: `emails`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

}
