import axios from "./axios";

export default {

    /**
     * @api {POST} veterinarylaboratorytest
     */
     addVeterinaryLaboratoryTest(data) {
        const options = {
            method: 'POST',
            url: 'veterinarylaboratorytest',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    /**
     * @param {string} patientID
     * @returns {Promise}
     */
     getVeterinaryLaboratoryTest(patientID) {
        const options = {
            method: 'GET',
            url: `veterinarylaboratorytest/${patientID}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    /**
     */
     getVeterinaryLaboratoryTestParameters(data) {
        const options = {
            method: 'POST',
            url: 'veterinarylaboratorytest/parameters',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    /**
     * @api {PUT} veterinarylaboratorytest
     */
     updateVeterinaryLaboratoryTest(id, data) {
        const options = {
            method: 'PUT',
            url: `veterinarylaboratorytest/${id}`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    /**
     * @api {DELETE} veterinarylaboratorytest
     */
     deleteVeterinaryLaboratoryTest(data) {
        const options = {
            method: 'DELETE',
            url: `veterinarylaboratorytest`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageageageage)
                })
        })
    }

}