export const setTokenMasterCookie = (token) => {
    try {
        const now = new Date();
        now.setFullYear(now.getFullYear() + 1);
        document.cookie = `tokenMaster=${token};expires=${now.toUTCString()};domain=${window.location.hostname};path=/`;
    } catch (error) {
        throw new Error(`Ocurrió el siguiente error al insertar la cookie tokenMaster`)
    }
}

export const setBackendURLCookie = ( {content: { BackendUrl } } ) => {
    try {
        const now = new Date();
        now.setFullYear(now.getFullYear() + 1);
        document.cookie = `BackendUrl =${decodeURIComponent(BackendUrl)};expires=${now.toUTCString()};domain=${window.location.hostname};path=/`;
    } catch (error) {
        throw new Error(`Ocurrió el siguiente error al insertar la cookie "BackendUrl"`)
    }
}

export const setIDKeyMasterCookie = ( {content: { ID_KeyMaster } } ) => {
    try {
        const now = new Date();
        now.setFullYear(now.getFullYear() + 1);
        document.cookie = `ID_KeyMaster=["${decodeURIComponent(ID_KeyMaster)}"];expires=${now.toUTCString()};domain=${window.location.hostname};path=/`;
    } catch (error) {
        throw new Error(`Ocurrió el siguiente error al insertar la cookie "ID_KeyMaster"`)
    }
}