<template>
    <div>
        <block v-if="loading"></block>
        <v-card flat>
            <v-card-text style="z-index: 1">
                <template v-if="type === 'medicalclinic'">
                    <user-profile-lab :patient="data" :patientinfo="data" :useData="useData"></user-profile-lab>
                </template>
                <template v-else>
                    <user-profile :patientinfo="patientinfo" :data="data" :useData="useData" />
                </template>

                <v-row style="margin-top: 1rem">
                    <v-col cols="12" class="box" style="margin-top: 1rem">
                        <v-form v-model="valid" class="position-relative">
                            <div>
                                <v-row class="ml-2 mr-2" style="margin-top: 0.3rem">
                                    <v-col cols="12" class="tabsNh">
                                        <v-col cols="12" md="12" style="display: flex; justify-content: space-between">
                                            <div style="width: 158px;" ></div>
                                            <div>
                                                <v-btn v-if="$store.getters['rolesUser/getCustomRole'](107)" small class="btn-add mr-1" @click.prevent="getItemsToBeBilled(); showBillPatient = true">
                                                    <v-icon class="mr-3">mdi-account-details</v-icon> Facturar paciente
                                                </v-btn>
                                            </div>
                                            <!--  <v-btn @click.prevent="">
                                                <v-icon>
                                                    mdi-printer
                                                </v-icon>
                                            
                                                </v-btn> -->
                                            <div>
                                                <v-btn small class="btn mr-1" @click="printMedicalHistory()">
                                                    <v-icon> mdi-printer </v-icon>
                                                </v-btn>
                                                <v-btn small class="btnblue mr-1" @click="showMedicalData = !showMedicalData" v-if="$store.getters['rolesUser/getCustomRole'](138)">
                                                    <v-icon> mdi-magnify </v-icon>
                                                </v-btn>
                                                <v-btn small class="btnclean mr-1" @click.prevent.stop="resetData()">
                                                    <v-icon> mdi-broom </v-icon>
                                                </v-btn>
                                                <v-btn small v-if="selectedvalues && $store.getters['rolesUser/getCustomRole'](172)
                                                    " class="btnsave" @click.prevent.stop="saveMedicalHistory()">
                                                    <v-icon> mdi-content-save </v-icon>
                                                </v-btn>
                                                <v-btn small v-if="medicalhistory.ID_MH &&
                                                    $store.getters['rolesUser/getCustomRole'](172)
                                                    " class="btnsave" @click.prevent.stop="updateMedicalHistory()">
                                                    <v-icon> mdi-content-save </v-icon>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-col>

                                    <v-col cols="12" md="2" sm="12" style="margin-top: 1rem">
                                        <date-picker label="Fecha" @dateSelected="(param) => {
                                                date = param;
                                                checkhistoryDatetoday(param);
                                            }
                                            " v-model="date"></date-picker>
                                    </v-col>
                                    <v-col cols="6" md="2" style="margin-top: 1rem">
                                        <v-text-field label="Peso" dense outlined v-model="weight" @change="changeWeight"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" md="2" style="margin-top: 1rem">
                                        <v-select outlined dense attach @change="switchWeightUnit" v-model="weightunit" :items="['Kg', 'Lb']" label="Unidad de peso"></v-select>
                                    </v-col>
                                    <v-col cols="6" md="2" style="margin-top: 1rem">
                                        <v-text-field label="Talla" dense outlined v-model="medicalhistory.height"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" md="2" style="margin-top: 1rem">
                                        <v-text-field label="Pulso" dense outlined v-model="medicalhistory.pulse"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" md="2" style="margin-top: 1rem">
                                        <v-text-field label="Temperatura" dense outlined v-model="medicalhistory.temp"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" md="2" style="margin-top: 1rem">
                                        <v-text-field label="Frecuencia cardiaca" dense outlined v-model="medicalhistory.heartRate"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" md="2" style="margin-top: 1rem">
                                        <v-text-field label="Frecuencia respiratoria" dense outlined v-model="medicalhistory.breathRate"></v-text-field>
                                    </v-col>

                                    <v-col cols="6" md="2" style="margin-top: 1rem;">
                                        <v-checkbox label="Veterinario externo" dense outlined v-model="medicalhistory.vetExt" @change="handlerVetExt"></v-checkbox>
                                    </v-col>
                                    <v-col cols="6" md="2" style="margin-top: 1rem">
                                        <v-text-field v-if="vetExt" label="Nombre del veterinario" dense outlined v-model="medicalhistory.nameExt"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="2"
                                        style="display: flex; justify-content: flex-end; margin-top: 1rem">
                                        <v-btn @click="showComments = !showComments"
                                            :class="medicalhistory.Comment ? 'btnclean' : 'btnblack'">
                                            Comentarios
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <br />
                                <v-row class="ml-2 mr-2" style="margin-top: 0.3rem">
                                    <!-- <v-col cols="12" md="12" sm="12" >
                                            <h2>DIAGNÓSTICOS</h2>
                            
                                </v-col> -->

                                    <v-col cols="12" md="6">
                                        <label class="mb-3 d-inline-block label-forms">Resumen de historia y examen físico
                                        </label>
                                        <span class="ql-editor150">
                                            <quill-editor v-model="medicalhistory.PhysicalHistorySummary"
                                                :options="quillOptions" @change="onEditorChange($event)"></quill-editor>
                                        </span>
                                        <br />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <label class="mb-3 d-inline-block label-forms">Diagnóstico Presuntivo
                                        </label>
                                        <span class="ql-editor150">
                                            <quill-editor v-model="medicalhistory.PresumptiveDiagnosis"
                                                :options="quillOptions" @change="onEditorChange($event)"></quill-editor>
                                        </span>
                                        <br />
                                    </v-col>

                                    <v-col cols="12" md="6">
                                        <label class="mb-3 d-inline-block label-forms">Diagnóstico Definitivo
                                        </label>
                                        <span class="ql-editor150">
                                            <quill-editor v-model="medicalhistory.FinalDiagnosis" :options="quillOptions"
                                                @change="onEditorChange($event)"></quill-editor>
                                        </span>
                                        <br />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <label @click="showTreat = !showTreat"
                                            class="mb-3 d-inline-block label-forms">Tratamiento
                                        </label>
                                        <span class="ql-editor150">
                                            <quill-editor v-model="medicalhistory.Treatment" :options="quillOptions"
                                                @change="onEditorChange($event)"></quill-editor>
                                        </span>
                                        <br />
                                    </v-col>
                                </v-row>

                                <v-row style="margin-bottom: 1.2rem; margin-top: 0.6rem"> </v-row>
                            </div>
                        </v-form>
                    </v-col>
                </v-row>
                <br />
                <hr />
                <br />

                <v-dialog v-model="dialog" max-width="350">
                    <v-card>
                        <v-card-title class="text-h6"> ¿Desea eliminar el registro? </v-card-title>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="red darken-1" text @click="dialog = false"> No </v-btn>
                            <v-btn color="green darken-1" text @click="
                                deleteMedicalHistory(temp);
                            dialog = false;
                            temp = null;
                            ">
                                Si
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
                </alerts>
            </v-card-text>
            <v-dialog v-model="showMedicalData" :width="'90%'" scrollable>
                <v-card flat class="pa-4">
                    <v-card-text>
                        <div style="margin-top: 0.3rem; margin-bottom: 0.3rem; display: flex; justify-content: flex-end;">
                            <v-btn small class="btndelete" @click.prevent="showMedicalData = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </div>
                        <GeneralFilter 
                            class="background border-box" 
                            entity="cliente"
                            :filterEndpoint="$API.veterinarymedicalhistory.filterloadMedicalHistory" :search="searchHistory"
                            @emptyFilter="loadHistoryMedical" @filtered="filterHandler"
                        >
                            <DxDataGrid 
                                class="isScrolledByGeneralFilter" 
                                :show-borders="true" 
                                :data-source="medicalhistorys"
                                key-expr="" 
                                @exporting="onExporting" 
                                :column-auto-width="true"
                                style="width: fit-content;"
                            >
                                <DxExport :enabled="true" />
                                <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                                <!--  <DxColumn data-field="ID_MH" caption="Id"></DxColumn> -->
                                <DxColumn data-field="MedicalHistoryDate" caption="Fecha" :min-width="100"></DxColumn>
                                <DxColumn data-field="Weight" caption="Peso"  :min-width="100"></DxColumn>
                                <DxColumn data-field="FullName" caption="Atendido por" :min-width="200"></DxColumn>
                                <DxColumn data-field="PhysicalHistorySummaryTable" caption="Resumen de historia y examen físico" :min-width="300" cssClass="left-align"></DxColumn>
                                <DxColumn data-field="PresumptiveDiagnosisTable" caption="Diagnóstico Presuntivo" :min-width="300" cssClass="left-align"></DxColumn>
                                <DxColumn data-field="FinalDiagnosisTable" caption="Diagnóstico Definitivo" :min-width="300" cssClass="left-align"></DxColumn>
                                <DxColumn data-field="TreatmentTable" caption="Tratamiento" :min-width="300" cssClass="left-align"></DxColumn>
                                <DxColumn :width="80" cell-template="delete-template" caption="" v-if="$store.getters['rolesUser/getCustomRole'](139)">
                                </DxColumn>
                                <template #show-template="{ data }">
                                    <div>
                                        <a href="#" @click.prevent.stop="
                                            showMedicalHistory(data.data);
                                        selectedvalues = false;
                                        showMedicalData = false;
                                        ">
                                            <v-icon color="primary">mdi-eye</v-icon>
                                        </a>
                                    </div>
                                </template>
                                <template #delete-template="{ data }">
                                    <div>
                                        <a href="" class="mx-2" @click.prevent.stop="
                                            confirmdelete(data.data);
                                        showMedicalData = false;
                                        ">
                                            <v-icon color="error">mdi-delete</v-icon>
                                        </a>
                                    </div>
                                </template>
                            </DxDataGrid>
                        </GeneralFilter>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-card>

        <!-- Dialog bill patient -->
        <v-dialog v-model="showBillPatient" width="90%">
        <v-card class="py-3 px-3">
            <v-card-title class="text-h5">
            Facturar paciente 
            </v-card-title>

            <v-card-actions>
            <v-row>
                <v-col cols="12" class="tabsNh" style="padding: 14px">
                <v-col style="display: flex; justify-content: space-between; min-height: 50px">
                    <div>
                    <!-- <v-btn v-if="!showServicesToAdd" small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                        <v-icon>mdi-menu-down</v-icon>
                        Ver items
                    </v-btn>
                    <v-btn v-else small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                        <v-icon>mdi-menu-up</v-icon>
                        Ocultar
                    </v-btn> -->
                    </div>
                    <div>
                    <v-btn small class="btn-add" @click.prevent="saveItemsToBeBilled()">
                        <v-icon>mdi-content-save</v-icon>
                        Guardar
                    </v-btn>
                    </div>

                    <div>
                    <v-btn small class="btnsave" @click.prevent="billedPatientsOfCustomer()">
                        <v-icon>mdi-cash-register</v-icon>
                        Facturar cliente
                    </v-btn>
                    </div>
                </v-col>
                </v-col>
            </v-row>
            </v-card-actions>
            <div style="height: 500px; overflow-y: auto; overflow-x: hidden; margin-top: 20px; margin-bottom: 20px; padding: 0 20px;">
            <!-- Services default -->
            <v-row style="margin-top: 2rem">
                <v-row class="d-flex justify-center mb-2">
                <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                    <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                    Ver servicios por defecto
                    </v-alert>
                </v-col>
                <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                    <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                    <button @click='togglehide1 = !togglehide1' v-show='!togglehide1' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                    <button @click='togglehide1 = !togglehide1' v-show='togglehide1' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                    </v-alert>
                </v-col>
                </v-row>
                <v-col cols="12" v-if="!togglehide1">
                <DxDataGrid class="isScrolledByGeneralFilter dataGridService" :data-source="servicesDefault" 
                :show-borders="true" :column-auto-width="true" style="width: fit-content;">
                    <DxScrolling row-rendering-mode="virtual" />
                    <DxPaging :page-size="20" />
                    <DxColumn :width="150" data-field="CodeService" header-cell-template="headerCodeService" />
                    <template #headerCodeService="{ }">
                    <div>Código de<br />servicio</div>
                    </template>
                    <DxColumn data-field="NameService" header-cell-template="headerNameService" />
                    <template #headerNameService="{ }">
                    <div>Nombre de<br />servicio</div>
                    </template>
                    <DxColumn data-field="Name" header-cell-template="headerName" />
                    <template #headerName="{ }">
                    <div>Nombre a<br />facturar</div>
                    </template>
                    <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice" />
                    <template #headerTypeservice="{ }">
                    <div>Tipo de<br />servicio</div>
                    </template-->
                    <DxColumn :width="65" data-field="Price" caption="Precio" />
                    <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                    <DxColumn :width="45" cell-template="show-template" caption=""/>
                    <template #show-template="{ data }">
                    <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                        <v-icon>mdi-plus</v-icon>
                    </a>
                    </template>
                    <template #quantity-template="{ data }">
                    <p>{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService).quantity }}</p>
                    </template>
                </DxDataGrid>
                </v-col>
            </v-row>

            <!-- Services to select -->
            <v-row style="margin-top: 2rem">
                <v-row class="d-flex justify-center mb-2">
                <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                    <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                    Ver todos los servicios
                    </v-alert>
                </v-col>
                <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                    <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                    <button @click='togglehide2 = !togglehide2' v-show='!togglehide2' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                    <button @click='togglehide2 = !togglehide2' v-show='togglehide2' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                    </v-alert>
                </v-col>
                </v-row>
                <v-col cols="12" v-if="!togglehide2">
                <GeneralFilter 
                    :filterEndpoint="$API.services.getAllServicesFiltered" 
                    :search="search"
                    @emptyFilter="getServices" 
                    @filtered="servicesFilterHandler"
                >
                    <DxDataGrid 
                    class="isScrolledByGeneralFilter dataGridService" 
                    :data-source="servicesToBill"
                    key-expr="CodeService" 
                    :allow-column-reordering="true" 
                    :row-alternation-enabled="true"
                    :show-borders="true"
                    :column-auto-width="true"
                    style="width: fit-content;"
                    >
                    <DxScrolling row-rendering-mode="virtual" />
                    <DxPaging :page-size="20" />
                    <DxColumn :width="150" data-field="CodeService" caption="ID"></DxColumn>
                    <DxColumn data-field="NameService" header-cell-template="headerNameService"></DxColumn>
                    <template #headerNameService="{ }">
                        <div>Nombre de<br />servicio</div>
                    </template>
                    <DxColumn data-field="Name" header-cell-template="headerNameLab"></DxColumn>
                    <template #headerNameLab="{ }">
                        <div>Nombre a<br />facturar</div>
                    </template>
                    <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice">
                    </DxColumn-->
                    <!--template #headerTypeservice="{ }">
                        <div>Tipo de<br />servicio</div>
                    </template-->
                    <DxColumn :width="65" data-field="Price" caption="Precio"></DxColumn>
                    <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                    <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                    <template #show-template="{ data }">
                        <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                        <v-icon>mdi-plus</v-icon>
                        </a>
                    </template>
                    <template #quantity-template="{ data }">
                        <p style="margin: 0;">{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService)?.quantity || '' }}</p>
                    </template>
                    </DxDataGrid>
                </GeneralFilter>
                </v-col>
            </v-row>

            <!-- Products to select -->
            <v-row style="margin-top: 2rem" v-if="false">
                <v-row class="d-flex justify-center mb-2">
                <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                    <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                    Ver todos los productos
                    </v-alert>
                </v-col>
                <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                    <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                    <button @click='togglehide3 = !togglehide3' v-show='!togglehide3' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                    <button @click='togglehide3 = !togglehide3' v-show='togglehide3' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                    </v-alert>
                </v-col>
                </v-row>
                <v-col cols="12" v-if="!togglehide3">
                <GeneralFilter 
                    :filterEndpoint="$API.products.getProductsToBillForInvoiceFiltered" 
                    :search="search"
                    @emptyFilter="getProducts" 
                    @filtered="productsFilterHandler"
                >
                    <v-row style="padding: 1rem;">
                        <v-col cols="12" md="3" sm="4">
                            <v-autocomplete
                            v-model="selectedWarehouse"
                            item-text="Warehouse"
                            item-value="ID_Warehouse"
                            :items="warehouses"
                            @change="handleSelectWarehouse"
                            label="Almacenes"
                            ref="werehouseRef"
                            dense 
                            outlined 
                            attach
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="9" sm="8">
                            <p style="color: #e3191e !important; font-size: 12px; margin: 12px 0" v-if="warehouses.length == 0">No existen almacenes de productos para pacientes.</p>
                        </v-col>
                    </v-row>
                    <DxDataGrid 
                    class="isScrolledByGeneralFilter dataGridService" 
                    :data-source="productsToBill"
                    key-expr="ID_Item" 
                    :allow-column-reordering="true" 
                    :row-alternation-enabled="true"
                    :show-borders="true"
                    :column-auto-width="true"
                    style="width: fit-content;"
                    >
                    <DxScrolling row-rendering-mode="virtual" />
                    <DxPaging :page-size="20" />
                    <DxColumn :width="150" data-field="ID_Item" caption="ID"></DxColumn>
                    <DxColumn data-field="ItemName" header-cell-template="headerName"></DxColumn>
                    <template #headerName="{ }">
                        <div>Nombre del<br />producto</div>
                    </template>
                    <DxColumn data-field="ItemComercialName" header-cell-template="headerNameComercial"></DxColumn>
                    <template #headerNameComercial="{ }">
                        <div>Nombre<br />comercial</div>
                    </template>
                    <DxColumn :width="65" data-field="ItemPrice" caption="Precio"></DxColumn>
                    <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                    <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                    <template #show-template="{ data }">
                        <a href="#" class="mx-1" @click.prevent.stop="addProduct(data.data)">
                        <v-icon>mdi-plus</v-icon>
                        </a>
                    </template>
                    <template #quantity-template="{ data }">
                        <p style="margin: 0;">{{ productsToAdd.find(item => item.ID_Item == data.data.ID_Item)?.quantity || '' }}</p>
                    </template>
                    </DxDataGrid>
                </GeneralFilter>
                </v-col>
            </v-row>

            <!-- ServiceToAdd -->
            <v-row style="margin-top: 2rem">
                <v-col cols="12">
                <h4 style="padding: 16px;">Servicios seleccionados</h4>
                <div style="overflow-x: scroll; padding: 0 12px;">
                    <DxDataGrid 
                    class="isScrolledByGeneralFilter dataGridService" 
                    :data-source="servicesToAdd"
                    key-expr="CodeService" 
                    :allow-column-reordering="true" 
                    :row-alternation-enabled="true"
                    :show-borders="true"    
                    :column-auto-width="true"
                    style="width: fit-content;"
                    >
                    <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                    <DxScrolling row-rendering-mode="virtual" />
                    <DxPaging :page-size="5" />
                    <DxColumn data-field="CodeService" :allow-editing="false" caption="ID"></DxColumn>
                    <DxColumn data-field="NameService" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                    <template #headerNameService="{ }">
                        <div>Nombre de<br />servicio</div>
                    </template>
                    <DxColumn data-field="Name" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                    <template #headerNameLab="{ }">
                        <div>Nombre a<br />facturar</div>
                    </template>
                    <DxColumn data-field="TypeService" :allow-editing="false" header-cell-template="headerTypeservice">
                    </DxColumn>
                    <template #headerTypeservice="{ }">
                        <div>Tipo de<br />servicio</div>
                    </template>
                    <DxColumn data-field="quantity" caption="Cantidad">
                        <DxFormat typed="fixedPoint" :precision="0" />
                        <DxRequiredRule message="La cantidad del servicio a facturar es requerida" />
                        <DxPatternRule :pattern="itemQtyPattern"
                        message="La cantidad del servicio facturado solo puede contener numeros enteros mayores a 0" />
                    </DxColumn>
                    <DxColumn data-field="Price" :allow-editing="true" caption="Precio"></DxColumn>
                    <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                    <DxColumn cell-template="del-template" caption=""></DxColumn>
                    <template #del-template="{ data }">
                        <a href="#" class="mx-1" @click.prevent.stop="deleteService(data.data)">
                        <v-icon>mdi-delete</v-icon>
                        </a>
                    </template>
                    <template #total-template="{ data }">
                        <div>
                        {{ totalXQuantity( data.data ) }}
                        </div>
                    </template>
                    </DxDataGrid>
                </div>
                </v-col>
            </v-row>

            <!-- Product to add -->
            <v-row style="margin-top: 2rem">
                <v-col cols="12">
                <h4 style="padding: 16px;">Productos seleccionados</h4>
                <div style="overflow-x: scroll; padding: 0 12px;">
                    <DxDataGrid 
                    class="isScrolledByGeneralFilter dataGridService" 
                    :data-source="productsToAdd"
                    key-expr="ID_Item" 
                    :allow-column-reordering="true" 
                    :row-alternation-enabled="true"
                    :show-borders="true"    
                    :column-auto-width="true"
                    style="width: fit-content;"
                    >
                    <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                    <DxScrolling row-rendering-mode="virtual" />
                    <DxPaging :page-size="5" />
                    <DxColumn data-field="ID_Item" :allow-editing="false" caption="ID"></DxColumn>
                    <DxColumn data-field="ItemName" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                    <template #headerNameService="{ }">
                        <div>Nombre del<br />producto</div>
                    </template>
                    <DxColumn data-field="ItemComercialName" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                    <template #headerNameLab="{ }">
                        <div>Nombre a<br />facturar</div>
                    </template>
                    <DxColumn data-field="ItemType" :allow-editing="false" header-cell-template="headerTypeservice">
                    </DxColumn>
                    <template #headerTypeservice="{ }">
                        <div>Tipo de<br />producto</div>
                    </template>
                    <DxColumn data-field="quantity" caption="Cantidad">
                        <DxFormat typed="fixedPoint" :precision="0" />
                        <DxRequiredRule message="La cantidad del producto a facturar es requerida" />
                        <DxPatternRule :pattern="itemQtyPattern"
                        message="La cantidad del producto facturado solo puede contener numeros enteros mayores a 0" />
                    </DxColumn>
                    <DxColumn data-field="ItemPrice" :allow-editing="true" caption="Precio"></DxColumn>
                    <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                    <DxColumn cell-template="show-template" caption=""></DxColumn>
                    <template #show-template="{ data }">
                        <a href="#" class="mx-1" @click.prevent.stop="deleteProduct(data.data)">
                        <v-icon>mdi-delete</v-icon>
                        </a>
                    </template>
                    <template #total-template="{ data }">
                        <div>
                        {{ totalXQuantity( {...data.data, Price: data.data.ItemPrice} ) }}
                        </div>
                    </template>
                    </DxDataGrid>
                </div>
                </v-col>
            </v-row>

            </div>
        </v-card>
        </v-dialog>
        <!-- Dialog bill patient -->

        <v-dialog v-model="showComments" max-width="60%">
            <v-card class="pa-4">
                <div style="
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;
            display: flex;
            justify-content: flex-end;
          ">
                    <v-btn small class="btndelete" @click.prevent="showComments = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-col cols="12" md="12" style="">
                    <quill-editor v-model="medicalhistory.Comment" :options="quillOptions"></quill-editor>
                </v-col>
            </v-card>
        </v-dialog>

        <PrintMedicalHistory :dataProps="print"></PrintMedicalHistory>
    </div>
</template>

<script>
import Vue from "vue";
import Block from "@/components/Block";
import { DxDataGrid, DxColumn, DxPaging, DxScrolling, DxEditing, DxFormat, DxPatternRule, DxRequiredRule, DxExport } from "devextreme-vue/data-grid";
import DatePicker from "../../../components/DatePicker";
import Alerts from "@/components/Alerts";
import { mapState } from "vuex";
import { printToExcel } from "@/helpers/printToexcel";
import GeneralFilter from "@/components/GeneralFilter";
import UserProfile from "@/components/Displays/UserProfileDisplay.vue";
import UserProfileLab from "@/components/Displays/UserProfileDisplayLab.vue";
import { numberWithCommas } from "@/helpers/money";
import PrintMedicalHistory from "../../../components/ReportsAndPrintings/PrintMedicalHistory.vue";
export default {
    name: "VeterinaryMedicalH",
    components: {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxPaging,
        DxFormat,
        DxPatternRule,
        DxScrolling,
        DxRequiredRule,
        DatePicker,
        Block,
        Alerts,
        DxExport,
        GeneralFilter,
        UserProfile,
        UserProfileLab,
        PrintMedicalHistory,
    },
    props: ["data", "win", "type"],
    data: () => ({
        alert: {
            type: "success",
            show: false,
            header: "",
            body: "",
        },
        customer: {},
        currency: JSON.parse(localStorage.getItem('branch')).Currency,
        itemQtyPattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/i,
        showBillPatient: false,
        useData: {},
        showServicesToAdd: false,
        showProductsToAdd: false,
        servicesToBill: [],
        productsToBill: [],
        servicesToAdd: [],
        productsToAdd: [],
        filterProducts: {
            filter: null
        },
        search: {
            filter: null
        },
        togglehide1: true,
        togglehide2: true,
        togglehide3: true,
        warehouses: [],
        selectedWarehouse: null,
        
        datatodelete: null,
        dialog: false,
        valid: false,
        url: null,
        showComments: false,
        date: null,
        patientinfo: [],
        weightunit: '',
        weight: '',
        medicalhistorys: {},
        medicalhistory: {},
        loading: false,
        disabled1: 0,
        disabled2: 1,
        disabled3: 1,
        showFisicH: false,
        showDiagP: false,
        showDiagD: false,
        showTreat: false,
        showMedH: false,
        showRep: false,
        showSurg: false,
        showImg: false,
        vetExt: false,
        searchHistory: {},
        medicalaids: [],
        reproductions: [],
        surgerys: [],
        prescriptions: [],
        images: [],
        selectedvalues: true,
        tab: 1,
        showMedicalData: false,
        //useData: false,
        quillOptions: {
            modules: {
                toolbar: {
                    container: [
                        ['bold', 'underline', 'strike'],
                        ['blockquote'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'align': [] }],
                        [{ 'color': [] }, { 'background': [] }],
                    ],
                    handlers: {},
                },
            },
            theme: "snow",
        },
        print: {
            show: false,
            data: [],
        }
    }),

    computed: {
        ...mapState("rolesUser", {
            rolesStatus: (state) => state.roles,
        }),
    },
    async mounted() {
        if (this.data) {
            Object.keys(this.data).map((el) => {
                Vue.set(this.patientinfo, el.toLowerCase(), this.data[el]);
            });
            this.medicalhistory.vetExt = false;
            this.medicalhistory.nameExt = '';
            this.medicalhistory.Weight = this.data.Weight;
            this.url = this.$store.getters.getBackendUrl + "/public" + this.data.ProfilePicPath;
            this.useData = JSON.parse(localStorage.getItem("user"));
            this.branchConfig = JSON.parse(localStorage.getItem("branch"));
            if(this.branchConfig?.weightunit) this.weightunit = this.branchConfig.weightunit;
            if(this.data?.weightunit) this.weightunit = this.data.weightunit;

            if(this.data.VeterinarioExterno == 1 || this.data.VeterinarioExterno == true) {
                this.medicalhistory.vetExt = true;
                this.medicalhistory.nameExt = this.data.NombreVeterinarioExterno;
                
            }
            else {
                this.medicalhistory.vetExt = false;
                this.medicalhistory.nameExt = '';
            }
            this.vetExt = this.medicalhistory.vetExt;

            console.log('mounted', this.data);
            this.customer.ID_Customer = this.data.ID_Customer;
            console.log('customer', this.customer);
            
            //this.useData = JSON.parse(localStorage.getItem("user"));
            this.warehouses = await this.$API.warehouse.getWarehousesFiltered({ isPatient: true });
            this.filterProducts.business = this.useData.businessid;
            this.filterProducts.branch = this.useData.branch;
            this.selectedWarehouse = this.warehouses.length == 0 ? 0 : this.warehouses[0].ID_Warehouse;
            this.filterProducts.warehouse = this.selectedWarehouse;    
            await this.getServices();
            await this.getProducts();
            await this.getDefaultServices();
        }

        const localparams = JSON.parse(localStorage.getItem("localparams")) || null;
        if (localparams && localparams.weightunit) this.weightunit = localparams.weightunit;

        if(this.medicalhistory && this.medicalhistory.Weight.includes('Kg')) this.weightunit = 'Kg';
        if(this.medicalhistory && this.medicalhistory.Weight.includes('Lb')) this.weightunit = 'Lb';

        if(this.medicalhistory.Weight && this.medicalhistory.Weight.includes(this.weightunit)) {
            this.weight = this.medicalhistory.Weight.slice(0, -2).replace(' ', '');
        } else {
            this.weight = this.medicalhistory.Weight.replace(' ', '');
        }

        this.medicalhistory.ID_Patient = this.patientinfo.id_patient;
        this.searchHistory.patientId = this.patientinfo.id_patient;
        this.loadHistoryMedical();
        //this.$store.dispatch("rolesUser/getAllRoles");
    },
    watch: {
        date: {
            handler: function () {
                this.searchHistory.date = this.date;
                this.medicalaids = [];
            },
            immediate: true,
        },
        servicesToAdd: {
            deep: true,
            immediate: true,
            handler() {
                if(this.servicesToAdd.length !== 0) {
                    this.showServicesToAdd = true;
                }
                else {
                    this.showServicesToAdd = false;
                }
            }
        },
        productsToAdd: {
            deep: true,
            immediate: true,
            handler() {
                if(this.productsToAdd.length !== 0) {
                    this.showProductsToAdd = true;
                }
                else {
                    this.showProductsToAdd = false;
                }
            }
        },
    },
    methods: {
        printMedicalHistory() {
            this.print = {
                show: true,
                data: {
                    medicalhistory: this.medicalhistory,
                    patientinfo: this.data,
                }
            }
        },
        totalXQuantity( { Price, quantity } ) {
            return `${this.currency}${ numberWithCommas( parseFloat( Price * quantity ).toFixed( 2 ) ) }`
        },
        async handleSelectWarehouse() {
            this.loading = true;
            this.filterProducts.warehouse = this.selectedWarehouse;
            await this.getProducts();
            this.$refs.werehouseRef.blur();
            this.loading = false;
        },
        addService(data) {
            const isRepetitive = this.servicesToAdd.find((service) => service.ID_Service === data.ID_Service);

            if (!isRepetitive) {
                this.servicesToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.Price), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.Price).toFixed(4))}` });
            } else {
                const service = this.servicesToAdd.find(item => item.ID_Service === data.ID_Service)
                if(service) {
                    service.quantity++;
                }
                console.log("Service already exists in servicesToAdd");
            }
        },
        addProduct(data) {
            const isRepetitive = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
            if (!isRepetitive) {
                this.productsToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.ItemPrice), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.ItemPrice).toFixed(4))}` });
            } else {
                const product = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
                if(product) {
                    product.quantity++;
                }
                console.log("Product already exists in productsToAdd.");
            }
        },
        deleteService(data) {
            const serviceIndex = this.servicesToAdd.findIndex((service) => service.CodeService === data.CodeService);

            if (serviceIndex !== -1) {
                this.servicesToAdd.splice(serviceIndex, 1);
            }
        },
        deleteProduct(data) {
            const index = this.productsToAdd.findIndex((item) => item.ID_Item === data.ID_Item);

            if (index !== -1) {
                this.productsToAdd.splice(index, 1);
            }
        },
        getServices() {
            this.$API.services.getAllServices(this.filterServices).then((response) => {
                this.servicesToBill = response;
            });
        },
        getProducts() {
            this.$API.products.getProductsToBillForInvoice(this.filterProducts).then((response) => {
                this.productsToBill = response;
            });
        },
        getDefaultServices() {
            this.$API.services.getDefaultServices().then((response) => {
                this.servicesDefault = response;
            });
        },
        servicesFilterHandler(response) {
            this.servicesToBill = response;
        },
        productsFilterHandler(response) {
            this.productsToBill = response;
        },
        getItemsToBeBilled() {
            this.$API.veterinarypatients.getServicesToBeBilled({ 'customer': this.data.ID_Customer })
            .then((response) => {
                this.servicesToAdd = []
                for(let i = 0; i < response.length; i++){
                this.servicesToAdd.push({ ...response[i], unitPrice: `${this.currency}${numberWithCommas(parseFloat(response[i].Price).toFixed(4))}` });
                }
            });
            this.$API.veterinarypatients.getProductsToBeBilled({ 'customer': this.data.ID_Customer }).then((res) => {
                if(res.success) {
                this.productsToAdd = [];
                for (const item of res.data) {
                    this.productsToAdd.push({ ...item, quantity: item.Quantity, unitPrice: `${this.currency}${numberWithCommas(parseFloat(item.ItemPrice).toFixed(4))}`});
                }
                }
            });
        },
        async saveItemsToBeBilled() {
            this.showBillPatient = false;
            this.loading = true;

            try {
                if(this.servicesToAdd.length) {
                await this.$API.veterinarypatients.saveServicesToBeBilled({ 'customer': this.customer.ID_Customer, 'services': this.servicesToAdd })
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                    throw new Error('Ocurrio un error al guardar los servicios.');
                });
                }
                if(this.productsToAdd.length) {
                console.log(this.productsToAdd);
                
                await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd })
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                    throw new Error('Ocurrio un error al guardar los productos.');
                });
                }
                await this.getItemsToBeBilled();
                this.loading = false;
                this.showAlert("success", "Exito", "Los datos de facturación de este paciente han sido guradados.")
            } catch (error) {
                console.log(error);
                this.loading = false;
                this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
            }

        },
        async billedPatientsOfCustomer() {
            this.showBillPatient = false;
            this.loading = true;

            try{
                await this.$API.veterinarypatients.saveServicesToBeBilled({'customer': this.customer.ID_Customer, 'services': this.servicesToAdd});
                await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd });
                await this.$API.veterinarypatients.billedPatientsOfCustomer({ 'customer': this.customer.ID_Customer});

                this.loading = false;

                this.showAlert("success", "Exito", `Se ha realizado la factura de los ${this.servicesToAdd.length && this.productsToAdd.length ? 'servicios y productos' : this.servicesToAdd.length ? 'servicios' : this.productsToAdd.length ? 'productos' : 'items' } seleccionados, podrá observarla en el módulo de POS en listo para facturar.`);
                await this.getItemsToBeBilled();        
            }
            catch(error) {
                console.log(error)
                this.loading = false
                this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
            }
        },

        onExporting(e) {
            printToExcel(e, "Historial Medico");
        },
        handlerVetExt() {
            this.vetExt = this.medicalhistory.vetExt;
        },
        checkhistoryDatetoday(date) {
            /*let datetoday = false;
            if(this.medicalhistorys.length) {
                this.medicalhistorys.forEach((el) => {
                    if (el.MedicalHistoryDate == date) {
                        this.showMedicalHistory(el);
                        datetoday = true;
                    }
                });
            }
            if (!datetoday) {
                //this.resetData();
                this.date = date;
            }*/
            this.date = date;
        },
        changeWeight(e) {
            this.medicalhistory.Weight = e.replace(' ', '');
            console.log('weigth', this.medicalhistory.Weight);
        },
        switchWeightUnit(e) {
            this.weightunit = e;
            console.log(this.weightunit);
        },
        Preview_image() {
            this.url = URL.createObjectURL(this.image);
        },

        disabledOne() {
            this.disabled1 = (this.disabled1 + 1) % 2;
        },
        disabledTwo() {
            this.disabled2 = (this.disabled2 + 1) % 2;
        },
        disabledThree() {
            this.disabled3 = (this.disabled3 + 1) % 2;
        },

        showMedicalHistory(data) {
            this.loading = true;

            this.medicalhistory.Weight = data.Weight;
            
            if(this.medicalhistory.Weight.includes('Kg')) {
                this.weightunit = 'Kg';
                this.weight = this.medicalhistory.Weight.slice(0, -2).replace(' ', '');
            }
            else if(this.medicalhistory.Weight.includes('Lb')) {
                this.weightunit = 'Lb';
                this.weight = this.medicalhistory.Weight.slice(0, -2).replace(' ', '');
            }
            else {
                this.weightunit = '';
                this.weight = this.medicalhistory.Weight.replace(' ', '');
            }

            if (data.FinalDiagnosis == "<p>undefined</p>") {
                data.FinalDiagnosis = "<p></p>";
            }
            if (data.PresumptiveDiagnosis == "<p>undefined</p>") {
                data.PresumptiveDiagnosis = "<p></p>";
            }
            if (data.Treatment == "<p>undefined</p>") {
                data.Treatment = "<p></p>";
            }

            /*  data.FinalDiagnosis
                      data.Treatment */
            this.medicalhistory = {...data }; //
            this.date = this.medicalhistory.MedicalHistoryDate;

            this.medicalhistory.height = data.Talla || '';
            this.medicalhistory.pulse = data.Pulso || '';
            this.medicalhistory.temp = data.Temperatura || '';
            this.medicalhistory.heartRate = data.FrecuenciaCardiaca || '';
            this.medicalhistory.breathRate = data.FrecuenciaRespiratoria || '';
            if(data.VeterinarioExterno == 1 || data.VeterinarioExterno == true) {
                this.medicalhistory.vetExt = true;
                this.medicalhistory.nameExt = data.NombreVeterinarioExterno;
            }
            else {
                this.medicalhistory.vetExt = false;
                this.medicalhistory.nameExt = '';
            }
            this.vetExt = this.medicalhistory.vetExt;

            this.loading = false;
            this.selectedvalues = false;
        },
        filterHandler(response) {
            console.log(response);
            this.medicalhistorys = response;
        },
        loadHistoryMedical() {
            this.loading = true;
            this.$API.veterinarymedicalhistory
                .loadMedicalHistorySorted(this.searchHistory)
                .then((data) => {
                    this.medicalhistorys = data;

                    let datetoday = this.moment().format("DD/MM/YYYY");
                    data.forEach((element) => {
                        if (element.MedicalHistoryDate == datetoday) {
                            this.showMedicalHistory(element);
                            this.selectedvalues = false;
                        }
                    });
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        saveMedicalHistory() {
            let datetoday = false;
            this.loading = true;

            if (this.medicalhistorys.length > 0) {
                for (const i in this.medicalhistorys) {
                    if (this.medicalhistorys[i].MedicalHistoryDate == this.date) {
                        this.showAlert(
                            "danger",
                            "error",
                            "Ya existe un registro para la fecha seleccionada"
                        );

                        datetoday = true;
                    }
                }
            }

            if (!datetoday) {
                this.medicalhistory.MedicalHistoryDate = this.date;
                this.medicalhistory.ID_Patient = this.patientinfo.id_patient;
                this.medicalhistory.ID_User_Insert = JSON.parse(localStorage.getItem("user")).id;
                if(this.medicalhistory.Weight && this.weightunit) this.medicalhistory.Weight = this.medicalhistory.Weight.concat(' ', this.weightunit);
                this.medicalhistory.Talla = this.medicalhistory.height;
                this.medicalhistory.Pulso = this.medicalhistory.pulse;
                this.medicalhistory.FrecuenciaCardiaca = this.medicalhistory.heartRate;
                this.medicalhistory.FrecuenciaRespiratoria = this.medicalhistory.breathRate;
                this.medicalhistory.Temperatura = this.medicalhistory.temp;
                if(this.medicalhistory.vetExt) {
                    this.medicalhistory.VeterinarioExterno = 1;
                    this.medicalhistory.NombreVeterinarioExterno = this.medicalhistory.nameExt || '';
                }
                else {
                    this.medicalhistory.VeterinarioExterno = 0;
                    this.medicalhistory.NombreVeterinarioExterno = '';
                }
                this.$API.veterinarymedicalhistory
                    .saveMedicalHistory(this.medicalhistory)
                    .then(() => {
                        this.showAlert("success", "Exito", "Se guardo el registro correctamente");
                        this.resetData();
                        this.loadHistoryMedical();
                        this.loading = false;
                    })
                    .catch((data) => {
                        this.showAlert("danger", "Error", data.message);
                        this.loading = false;
                    });
            } else {
                this.loading = false;
            }
        },

        updateMedicalHistory() {
            this.loading = true;
            this.medicalhistory.MedicalHistoryDate = this.date;
            this.medicalhistory.ID_User_Update = JSON.parse(localStorage.getItem("user")).id;
            this.medicalhistory.ID_Patient = this.patientinfo.id_patient;
            if(this.medicalhistory.Weight) {
                if(!this.medicalhistory.Weight.includes('Lb') && !this.medicalhistory.Weight.includes('Kg')) {
                    this.medicalhistory.Weight = this.medicalhistory.Weight.concat(' ', this.weightunit);
                } 
                else if(!this.medicalhistory.Weight.includes(this.weightunit)) {
                    const newValue = this.medicalhistory.Weight.slice(0, -3);
                    this.medicalhistory.Weight = newValue.concat(' ', this.weightunit);
                    console.log(this.medicalhistory.Weight);
                }
            }
            this.medicalhistory.Talla = this.medicalhistory.height;
            this.medicalhistory.Pulso = this.medicalhistory.pulse;
            this.medicalhistory.FrecuenciaCardiaca = this.medicalhistory.heartRate;
            this.medicalhistory.FrecuenciaRespiratoria = this.medicalhistory.breathRate;
            this.medicalhistory.Temperatura = this.medicalhistory.temp;
            if(this.medicalhistory.vetExt) {
                this.medicalhistory.VeterinarioExterno = 1;
                this.medicalhistory.NombreVeterinarioExterno = this.medicalhistory.nameExt || '';
            }
            else {
                this.medicalhistory.VeterinarioExterno = 0;
                this.medicalhistory.NombreVeterinarioExterno = '';
            }

            this.$API.veterinarymedicalhistory
                .updateMedicalHistory(this.medicalhistory)
                .then(() => {
                    this.loading = false;
                    this.showAlert("success", "Exito", "Se actualizo el registro correctamente");
                    this.loadHistoryMedical();
                })
                .catch((data) => {
                    console.log(data);
                    this.showAlert(
                        "danger",
                        "Error",
                        "Ocurrio un error, por favor contacte al administrador."
                    );
                    this.loading = false;
                });
        },

        deleteMedicalHistory(data) {
            this.loading = true;
            this.$API.veterinarymedicalhistory
                .deleteMedicalHistory(data)
                .then(() => {
                    this.loading = true;
                    this.showAlert("success", "Exito", "Se elimino el registro correctamente");
                    this.resetData();
                    this.loadHistoryMedical();
                })
                .catch((data) => {
                    this.showAlert("danger", "Error", data.message);
                    this.loading = false;
                });
        },

        resetData() {
            this.medicalhistory = {};
            this.medicalhistory.Weight = this.data.Weight;
            
            if(this.branchConfig?.weightunit) this.weightunit = this.branchConfig.weightunit;
            if(this.data?.weightunit) this.weightunit = this.data.weightunit;

            const localparams = JSON.parse(localStorage.getItem("localparams")) || null;
            if (localparams && localparams.weightunit) this.weightunit = localparams.weightunit;

            if(this.medicalhistory && this.medicalhistory.Weight.includes('Kg')) this.weightunit = 'Kg';
            if(this.medicalhistory && this.medicalhistory.Weight.includes('Lb')) this.weightunit = 'Lb';

            if(this.medicalhistory.Weight && this.medicalhistory.Weight.includes(this.weightunit)) {
                this.weight = this.medicalhistory.Weight.slice(0, -2).replace(' ', '');
            } else {
                this.weight = this.medicalhistory.Weight.replace(' ', '');
            }
            
            this.date = this.moment().format("DD/MM/YYYY");
            this.selectedvalues = true;
        },
        closeAlert() {
            this.alert.show = false;
            if (this.alert.options == "deletedate") {
                this.datatodelete = null;
            }
        },
        acceptAlert() {
            this.alert.show = false;

            if (this.alert.options == "deletedate") {
                this.deleteMedicalHistory(this.datatodelete);
            }
        },
        showAlert(type, header, body, options = null) {
            type = type == null ? "danger" : type;
            this.alert.type = type;
            this.alert.header = header;
            this.alert.body = body;
            this.alert.show = true;
            this.alert.options = options != null ? options : null;
        },
        confirmdelete(data) {
            this.showAlert(
                "question",
                "Eliminar",
                "¿Está seguro que desea eliminar el registro?",
                "deletedate"
            );
            this.datatodelete = data;
        },

        onEditorChange() { },

        /*onEditorChange(e) {
                    //this.medicalhistory.comment = e.html;
                },*/
    },
};
</script>

<style scoped>
.btndisable {
    background-color: #41464c !important;
    color: white;
}
.btndisable2 {
    background-color: #1976d2 !important;
    color: white;
}

.tabsB {
    width: 100%;
    padding: 10px;
    background-color: #1976d2;
    color: white;
    border-radius: 15px;
    border-style: solid;
    border-color: black;
    border-width: 1px;
}

.tabsB:hover {
    cursor: pointer;
}

.tabs:hover {
    background-color: rgba(80, 80, 80, 1);
    cursor: pointer;
}

.btnblue {
    background-color: #1976d2 !important;
    color: white;
}

.btnclean {
    background-color: #f29d35 !important;
    color: white;
}

.btnsave {
    background-color: #00a178 !important;
    color: white;
}

.tabs {
    width: 100%;
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white;
}

.text-white {
    color: white !important;
}

.box {
    border-style: solid;
    border-color: black;
    border-width: 1px;
    background-color: white;

    border-radius: 15px;
}

.label-forms {
    border-radius: 15px;
}

.tabs {
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white;
}

.btnblack {
    background-color: rgba(60, 60, 60, 1) !important;
    color: white !important;
}

.tabsNh {
    width: 100%;
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;
}

.infopatient {
    margin-top: 5%;
}
</style>
