import axios from "./axios";

export default {
    sendEmail(data) {
        const options = {
            method: 'POST',
            url: `/thirdapi/sendemail`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
}