import axios from "./axios";

export default {
    getAllForms(values) {
        const options = {
            method: 'GET',
            url: 'medicalHospitalization',
            headers: {'content-type': 'application/json'},
            params: values
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        });
    },
    getFilteredForms(data) {
        const options = {
            method: 'POST',
            url: 'medicalHospitalization/filtered',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        });
    },
    saveForm(data) {
        const options = {
            method: 'POST',
            url: 'medicalHospitalization/create',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        });
    },
    updateForm(data) {
        const options = {
            method: 'PUT',
            url: 'medicalHospitalization/update',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        });
    },
    deleteForm(data) {
        const options = {
            method: 'DELETE',
            url: 'medicalHospitalization/delete',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        });
    },
    billItems(data) {
        const options = {
            method: 'POST',
            url: 'medicalHospitalization/billItems',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        });
    },
};