import axios from "./axios";
// import Vuex from '../store/index';
import { userData } from "./Currentuserdata";

export default {
    /**
     * Get All Emails
     * @api {Get} manageuser
     */
    getHF({ID_branch,ID_business}) {
        const options = {
            method: 'GET',
            url: `hf?ID_branch=${ID_branch}&ID_business=${ID_business}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                    console.log(response.data)

                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    addHF(data) {
        const options = {
            method: 'POST',
            url: 'hf',
            headers: {'content-type': 'application/json'},
            data:data
        };
        console.log(data);
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                    console.log(response.data)

                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    updateHF(data) {
        const options = {
            method: 'PUT',
            url: `hf`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deleteHF(id) {    
    const user = userData();
        const options = {

            method: 'DELETE',
            url: `hf/${id}`,
            headers: {'content-type': 'application/json'},
            data: {
                ID_Branch: user.branch,
                ID_Business: user.businessid        ,
              }
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageageageageageageage)
                })
        })
    },
}
