<template>
  <v-date-picker
    v-bind="{ ...$attrs, ...$props }"
    v-on="{ ...$listeners }"
    no-title
    scrollable
  ></v-date-picker>
  <!-- <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="date"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="on"
        prepend-inner-icon="mdi-calendar"
        readonly
        dense
        outlined
        :disabled="disabled"
      ></v-text-field>
    </template>
    <v-date-picker
      :value="date"
      @input="onInput"
      no-title
      scrollable
    ></v-date-picker>
  </v-menu> -->
</template>

<script>
export default {
  inheritAttrs: false,
  name: "DatePickerField",
};
</script>
