import axios from "./axios";

export default {

    getSurgeries(params) {
        const options = {
            method: 'GET',
            url: `surgery`,
            headers: { 'content-type': 'application/json' },
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getFilterSurgeries(params) {
        const options = {
            method: 'GET',
            url: `surgery/filter`,
            headers: { 'content-type': 'application/json' },
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getSurgeryByID(id){
        const options = {
            method: 'GET',
            url: `surgery/${id}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    },

    addSurgery(data) {
        const options = {
            method: 'POST',
            url: `surgery`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    updateSurgery(id, data) {
        const options = {
            method: 'PUT',
            url: `surgery/${id}`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deleteSurgery(id) {
        const options = {
            method: 'DELETE',
            url: `surgery/${id}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

}