import axios from "./axios";

export default {

    loadMedicalHistory(data) {
        const options = {
            method: 'POST',
            url: `veterinarymedicalhistory/loadmedicalhistory`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    loadMedicalHistorySorted(data) {
        const options = {
            method: 'POST',
            url: `veterinarymedicalhistory/loadmedicalhistorysorted`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    filterloadMedicalHistory(data) {
        const options = {
            method: 'POST',
            url: `veterinarymedicalhistory/filterloadmedicalhistory`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    saveMedicalHistory(data) {
        const options = {
            method: 'POST',
            url: `veterinarymedicalhistory/savemedicalhistory`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    updateMedicalHistory(data) {
        const options = {
            method: 'POST',
            url: `veterinarymedicalhistory/updatemedicalhistory`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deleteMedicalHistory(data) {
        const options = {
            method: 'POST',
            url: `veterinarymedicalhistory/deletemedicalhistory`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getVaccinesHistory(data) {
        const options = {
            method: 'POST',
            url: `veterinarymedicalhistory/getvaccines`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },


    getMedicationHistory(data) {
        const options = {
            method: 'POST',
            url: `veterinarymedicalhistory/getmedication`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getLaboratoryTestHistory(data) {
        const options = {
            method: 'POST',
            url: `veterinarymedicalhistory/getlaboratorytest`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    
    getReproductionHistory(data) {
        const options = {
            method: 'POST',
            url: `veterinarymedicalhistory/getreproduction`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getSurgeryHistory(data) {
        const options = {
            method: 'POST',
            url: `veterinarymedicalhistory/getsurgery`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getImagesHistory(data) {
        const options = {
            method: 'POST',
            url: `veterinarymedicalhistory/getimages`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getPrescriptionHistory(data) {
        const options = {
            method: 'POST',
            url: `veterinarymedicalhistory/getprescription`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    }

    
    
}