<template>
    <v-expansion-panel >
        <v-expansion-panel-header class="mt-2">
            AGREGAR NUEVO PROVEEDOR
            <template v-slot:actions>
                <v-btn class="mr-5" @click.native.stop="reloadView++; form = {};showBgcolorNotes = 'tabs';" icon small>
                    <v-icon color="primary">mdi-reload</v-icon>
                </v-btn>
                <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content >
            <div :key="reloadView">
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>
                    <v-tab href="#tab-add">
                        AGREGAR NUEVO PROVEEDOR
                        <v-icon>mdi-plus</v-icon>
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-add">
                        <v-card flat class="pa-1">
                            <v-card-text class="white">
                                <v-form v-model="valid">
                                    <block v-if="loading"></block>
                
                                        <v-row class="ml-2 mr-2">
                                            <v-col cols="12" class="tabsNH">
                
                                                    <v-col cols="12" class=" d-flex  " style="justify-content: flex-end; ">
                                                    <v-btn 
                                                    v-if="$store.getters['rolesUser/getCustomRole'](39)"
                                                    small  class="btnsave"   @click.prevent.stop="addProviderHandler">
                                                        <v-icon>
                                                            mdi-content-save
                                                        </v-icon>
                                                    </v-btn>
                                                     </v-col>
                
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" style="display: flex;justify-content: space-between; margin-top: 1rem;">
                                                <h2>Datos Principales</h2>
                                                <v-checkbox
                                                        v-model="form.isglobalbranch"
                                                        label="Es Sucursal Global"
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12">
                                                <label>Nota: los apartados con (*) son datos requeridos</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="3">
                                                <v-text-field outlined dense 
                                                        v-model="form.providername"
                                                        label="Nombre del Proveedor *"
                                                        required
                                                        :rules="requiredRule"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="3">
                                                <v-text-field outlined dense 
                                                        v-model="form.provideremail"
                                                        label="Correo Eléctronico del Proveedor *"
                                                        :rules="emailRules"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="3">
                                                <v-text-field outlined dense 
                                                        v-model="form.providerphone"
                                                        label="Teléfono del Proveedor"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="3">
                                                <v-text-field outlined dense 
                                                        v-model="form.providermobilenumber"
                                                        label="Celular del Proveedor *"
                                                        @input="maskNum"
                                                        required
                                                        :rules="requiredRule"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="3">
                                                <v-text-field outlined dense 
                                                        v-model="form.provideraddress"
                                                        label="Dirección"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="3">
                <v-autocomplete outlined dense   :items="countryList"  attach
                item-text="name" @change="getStates"
              
                v-model="form.providercountry" required :rules="requiredRule"
                  label="Pais*">
                </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                    <v-autocomplete outlined dense  v-if="form.providercountry == 'El Salvador'" :items="departments" v-model="form.providerstate" label="Departamento*"
                    item-text="Valores" item-value="Codigo"  required :rules="requiredRule" attach
                    >
                    </v-autocomplete>
                    <v-autocomplete outlined dense  v-else v-model="form.providerstate" label="Estado/Departamento"  @change="getCities" attach
                    :items="stateList" item-text="name" 
                    >
                        <template v-slot:no-data>
                            <div class="px-4">{{ noDataLabelStates }}</div>
                        </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-autocomplete outlined dense  :items="municipalities" v-if="form.providercountry == 'El Salvador'" :readonly="!form.providerstate"  v-model="form.providercity" label="Ciudad"
                    item-text="Valores" item-value="Codigo"  attach
                     >
                        <template v-slot:no-data>
                            <div class="px-4">{{ noDataLabelCities }}</div>
                        </template>
                    </v-autocomplete>
                    <v-autocomplete outlined dense  v-else v-model="form.providercity"  label="Ciudad" attach
                    item-text="name"  :items="cityList"  :readonly="!form.providerstate"
                    ></v-autocomplete>

                  </v-col>
                                            <v-col cols="12" sm="6" md="3">
                                                <v-text-field outlined dense 
                                                        v-model="form.providerwebsite"
                                                        label="Sitio Web "
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="3" style="margin-top: .8rem;">
                                                <h3
                                                class="tabs"
                                                @click="showLegal = !showLegal"
                                                >Información  Tributaria</h3>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" style="margin-top: .8rem;">
                                                <h3>Datos de Contacto</h3>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="3">
                                                <v-text-field outlined dense 
                                                        v-model="form.providercontactname"
                                                        label="Nombre"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="3">
                                                <v-text-field outlined dense 
                                                        v-model="form.providercontactemail"
                                                        label="Correo Eléctronico  "
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3">
                                                <v-text-field outlined dense 
                                                        v-model="form.providercontactphone"
                                                        label="Teléfono "
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3">
                                                <v-text-field outlined dense 
                                                        v-model="form.providercontactmobile"
                                                        label="Celular *"
                                                        @input="maskNum"
                                                        required
                                                        :rules="requiredRule"
                                                ></v-text-field>
                                            </v-col>
                
                                            
                                            <v-dialog
                                             v-model="showLegal"
                                             width="60%"
                                            >
                                                <v-card class="pa-4">
                                                    <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
                                                        <v-btn small class="btndelete" @click.prevent="showLegal = false" >
                                                        <v-icon>mdi-close</v-icon>
                                                        </v-btn>
                                                    </div>
                                                    <v-row class="mt-4">
                                                        <v-col cols="12" sm="6" md="3">
                                                            <v-text-field outlined dense 
                                                                    v-model="form.providerlegalname"
                                                                    label="Nombre Legal"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" sm="6" md="3">
                                                            <v-text-field outlined dense 
                                                                    v-model="form.providerlegaladdress"
                                                                    label="Dirección Legal del Proveedor"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" sm="6" md="3">
                                                            <v-text-field outlined dense 
                                                                    v-model="form.providerlegalnrc"
                                                                    label="NRC Legal del Proveedor"
                                                                    @keyup="nrcMask"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" sm="6" md="3">
                                                            <v-text-field outlined dense 
                                                                    v-model="form.providerlegalnit"
                                                                    :label="'NIT Legal del Proveedor' + (form.providercountry == 'El Salvador' ? '*' : '')"
                                                                    @keyup="maskNit" :required="this.form.providercountry == 'El Salvador' ? true : false " :rules="this.form.providercountry == 'El Salvador' ? required : null "
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" md="6">
                                                            <v-autocomplete outlined dense  label="Actividad economica:*" :items="activities"
                                                            @change="changueValueactivity"  required :rules="required"  attach
                                                            v-model="form.providerlegalsector" item-text="Valores" item-value="Codigo"></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" sm="6" md="3">
                                                            <v-autocomplete outlined dense  :items="size" label="Tamaño del Contribuyente Legal del Proveedor" attach
                                                            v-model="form.providerlegaltaxpayersize"></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" sm="6" md="3">
                                                            <!--                                                <v-text-field outlined dense -->
                                                            <!--                                                        v-model="form.providerlegalentity"-->
                                                            <!--                                                        label="Provider legal Entity"-->
                                                            <!--                                                ></v-text-field>-->
                                                            <v-select outlined dense  :items="entity" label="Entidad Legal del Proveedor" attach
                                                                      v-model="form.providerlegalentity"></v-select>
                                                        </v-col>
                                                        <!-- <v-col cols="12" sm="6" md="4">
                                                                                                          <v-text-field outlined dense -->
                                                            <!--                                                        v-model="form.providerlegaltaxpayersize"-->
                                                            <!--                                                        label="Provider Legal Tax Payer Size"-->
                                                            <!--                                                ></v-text-field>
                                                        </v-col> -->
                                                        <v-col cols="12" sm="6" md="3">
                                                            <v-checkbox
                                                                    v-model="form.providerlegalisnontaxable"
                                                                    label="Proveedor Libre de Impuestos"
                                                            ></v-checkbox>
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </v-dialog>
                                            <v-col cols="12">
                                                <h3 style="text-align: center;" :class="showBgcolorNotes" @click.prevent="showNotes = !showNotes" >Comentarios
                                        
                                        </h3>
                                        <v-dialog
                                        v-model="showNotes"
                                        width="60%"
                                        ><v-card class="pa-4">
                                                    <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
                                                        <v-btn small class="btndelete" @click.prevent="showNotes = false" >
                                                        <v-icon>mdi-close</v-icon>
                                                        </v-btn>
                                                    </div>
                                            <quill-editor
                                                    :content="form.notes"
                                                     :options="quillOptions"
                                                    @change="onEditorChange($event)"
                                            ></quill-editor>
                                            </v-card>
                                        </v-dialog>
                                            </v-col>
                
                                        </v-row>
                
                
                
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </v-expansion-panel-content>
        <alerts
        v-if="alert.show"
        v-on:close_alert="closeAlert"
        v-on:accept_alert="acceptAlert"
        :properties="alert"
        ></alerts>
        <v-btn v-show="false" ref="elementoOculto">Este elemento será oculto si mostrarElemento es falso.</v-btn>

        
    </v-expansion-panel>

</template>


<script>
    import Block from "@/components/Block";
    import Alerts from "@/components/Alerts";
    import { quillConfig } from "../../../helpers/quillconfig";
    import {  mapGetters } from "vuex";
    import { nitForHomologation as nitMask, duiForHomologation as duiMask, nrc as nrcMask, applyDUIMaskOutOfInput, applyNITMaskOutOfInput } from "../../../helpers/masks";
    import {
        validateNITHomologation
    } from "../../../helpers/object";
    import { numberSV } from "../../../helpers/masks";
    export default {
        name: "AddProvider",
        components: {Block,Alerts},
        props: ['win'],
        data() {
            return {
                reloadView: 0,
                showBgcolorNotes: "tabs",
                quillOptions: quillConfig,
                showNotes: false,
                showLegal: false,
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
                form: {},
                tab: null,
                entity: ['PERSONA NATURAL', 'SOCIEDAD ANÓNIMA', 'CORPORACIÓN'],
                size: ['PEQUEÑO', 'MEDIANO', 'GRANDE'],
                loading: false,
                requiredRule: [
                    value => !!value || 'Campo requerido.',
                ],
                emailRules: [
                    v => !!v || 'Correo eléctronico requerido',
                    v => /.+@.+/.test(v) || 'El correo eléctronico debe ser valido',
                ],
                valid: false,
                bottom: '',
                stateList: [],
                cityList: [],
                noDataLabelStates: 'Seleccione un país',     
                noDataLabelCities: 'No hay ciudades disponibles'
            }
        },
        computed: {
       
      ...mapGetters(['getCatalogMH']),
    departments(){
     
      return  this.getCatalogMH('Departamento')
    },
    municipalities(){
      const municipalities =  this.getCatalogMH('Municipio')
      if(this.form.providerstate){
        const department = municipalities.filter(mun => mun.Departamento == this.form.providerstate)
        return department
      }
      return []
    },
    activities(){
      return  this.getCatalogMH('ActividadEconomica')
    },
    countryList(){
      return this.$store.getters.getCountries;
    },

        },
        mounted() {
            this.$store.dispatch('rolesUser/getAllRoles')
            this.form.idbusiness = JSON.parse(localStorage.getItem("user")).businessid || ""
            this.form.idbranch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.form.iduserinsert = JSON.parse(localStorage.getItem("user")).id || "";
            if(this.form?.providerlegalnit?.replace('-', '').length > 9) {
                this.form.providerlegalnit && (this.form.providerlegalnit = applyDUIMaskOutOfInput(this.form.providerlegalnit));
            } else {
                this.form.providerlegalnit && (this.form.providerlegalnit = applyNITMaskOutOfInput(this.form.providerlegalnit));
            }
            this.form.providerlegalnrc && (this.form.providerlegalnrc = applyDUIMaskOutOfInput(this.form.providerlegalnrc));
            this.form.isglobalbranch = true;
            this.scrollToBottom()
        },
        methods: {
            /* scrollToBottom() {
                let bottom = document.body.scrollHeight
                window.scrollTo(0, bottom)
                
             }, */
             async maskNum() {
                if(this.form.providermobilenumber) {
                    this.form.providermobilenumber = await numberSV(this, this.form.providermobilenumber)
                }

                if(this.form.providercontactmobile) {
                    this.form.providercontactmobile = await numberSV(this, this.form.providercontactmobile)
                }
            },
            async maskNit(e){
                if(e.which === 8) return false
                if(this.form.providerlegalnit.replace('-', '').length >= 14) {
                    this.form.providerlegalnit = await nitMask(this, this.form.providerlegalnit);
                } else {
                    this.form.providerlegalnit = await duiMask(this, this.form.providerlegalnit);
                }
            },
            async nrcMask(e){
                if(e.which === 8) return false
                this.form.providerlegalnrc = await nrcMask(this, this.form.providerlegalnrc);
            },
            async getStates(){
                if(this.form && this.form.providercountry == 'El Salvador') return [];
                if(this.form.providercountry){
                    this.noDataLabelStates = 'Cargando...'
                    this.stateList = []
                    
                    this.stateList = await this.$API.general.getStates(this.form.providercountry)

                    this.noDataLabelStates = 'No hay Estados disponibles';
                }
            },
            async getCities(){
                if(this.form && this.form.providercountry == 'El Salvador') return [];
                console.log(this.form.providerstate);
                if(this.form.providerstate){
                    this.noDataLabelCities = 'Cargando...'
                    this.cityList = []
                    
                    this.cityList = await this.$API.general.getCities(this.form.providerstate);
                    this.noDataLabelCities = 'No hay Ciudades disponibles';
                }
            },
            closeAlert() {
            this.alert.show = false;
                if (this.alert.options == "providerCreated"){
                this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid }).then(() => {
                    this.$store.dispatch("addWindow", {
                    name: "ProvidersList",
                    component: "ProvidersList",
                    unique: true,
                    });
                    this.$store.dispatch("reloadData", "providers")
                })


    }

    },
            showAlert(type, header, body, options = null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
            },
            acceptAlert() {
            this.alert.show = false;
            },
            addProviderHandler() {
                if( !this.valid) {
                    this.showAlert(
                        "warning",
                        "Advertencia ",
                        "Los campos marcados con * son obligatorios",
                        );
                    return;
                }
                if( this.form.providerlegalnit ) {
                    if( !validateNITHomologation( 'form', this, 'providerlegalnit', 'Número de NIT' ) ) {
                        return;
                    }
                }
                this.loading = true;
                this.form.providerlegalnit && (this.form.providerlegalnit = this.form.providerlegalnit.replace(/-/g, ""));
                this.form.providerlegalnrc && (this.form.providerlegalnrc = this.form.providerlegalnrc.replace(/-/g, ""));
                this.$API.providers.addProvider(this.form)
                    .then(response => {
                        console.log(response);
                        this.loading = false;
                        //throw new Error("Error");
                        //this.$toasted.success(response.message);

                        this.showAlert(
                            "success",
                            "Ingreso correcto",
                            "El proveedor ha sido ingresado correctamente",
                              "providerCreated"
                             );
                             this.form={}
                    }).catch(() => {
                    this.loading = false;
                    this.showAlert(
                            "danger",
                            "Error al ingresar proveedor",
                             "lo sentimos, ha ocurrido un error al ingresar el proveedor por favor intente nuevamente mas tarde",

                             );

                })
            },
            onEditorChange(e) {
                this.form.notes = e.html
                if (this.form.notes != "" ) {
                 this.showBgcolorNotes = 'tabsContent'
                }
                else if (this.form.notes == "" ||this.form.notes == "<p><br></p>" ) {
                    this.showBgcolorNotes = 'tabs'
                }
                else{
                    this.showBgcolorNotes = 'tabs'
            }
            },
        },
    }
</script>

<style scoped>
.btnsave{
  background-color: #00A178 !important;
  color: white;
}

.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}
.tabsContent:hover {
  background-color:  #FFB266;
  cursor: pointer;
}

.tabsNH{
    width: 100%;
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;
}
.tabs{
    width: 100%;
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;
    
}
.tabsContent{
    width: 100%;
    padding: 10px;
    background-color: #F29D35;
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;
    
}

</style>
