<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      {{ $t("managetemplate") }}
      <template v-slot:actions>
        <v-btn class="mr-5" @click.native.stop="clean();reloadp++; this.allTemplates();" icon small>
          <v-icon color="primary">mdi-reload</v-icon>
        </v-btn>
        <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content :key="reloadp">
      <v-card >
        <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
          <v-tabs-slider color="red"></v-tabs-slider>
          <v-tab href="#tab-1">
            PLANTILLAS DE LABORATORIO
            <v-icon>mdi-pencil-circle</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" touchless>
          <v-tab-item value="tab-1">


              <div class="ma-1">
                <v-row  style="margin: .4rem;">
                  
                  <v-col class="tabsNh"  cols="12" >
                    <v-col  v-if="!detailsparams" cols="12" style="display:flex; justify-content: flex-end;">
                      <v-btn
                                        
                                          small
                                            class="btnclean mr-1"
                                            @click="clean"

                                          >
                                            <v-icon>mdi-broom</v-icon>
                                            
                        </v-btn>
                      <v-btn small class="btnblue mr-1" @click="showTemplates= !showTemplates">
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                      <v-btn
                        small
                        
                        class="btnsave mr-1"
                        @click.prevent.stop="typebusiness=='veterinary' ? addTemplatesvet() : addTemplates()">
                        <v-icon>mdi-content-save</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col  v-if="detailsparams" cols="12" style="display:flex; justify-content: space-between;">
                      <v-btn
                                          color="error"
                                          small
                                          @click.prevent="templateToDelete = detailsparams;
                                            showAlert('question', 'Eliminar', '¿Seguro que desea eliminar el examen de laboratorio seleccionado?', 'delete');"
                                          >
                                            <v-icon>mdi-delete</v-icon>
                                          </v-btn>
                      <div>
                        <v-btn
                                            
                                            small
                                              class="btnclean mr-1"
                                              @click="clean"
                                            >
                                              <v-icon>mdi-broom</v-icon>
                        
                          </v-btn>
                        <v-btn small class="btnblue mr-1" @click="showTemplates= !showTemplates">
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                        <v-btn
                        small
                    class="btnsave"
                    @click.prevent.stop="updatemanageTable"
                    >
                    <v-icon>mdi-content-save</v-icon>
                    </v-btn>

                      </div>
                    </v-col>
                  </v-col>
                      <v-dialog  v-model="showTemplates" scrollable :width="$vuetify.breakpoint.smAndDown?'90%':'60%'">
                          
                      <v-card height="inherit" class="pa-4 "  >
                        <p class="pt-5 text-h6 mb-0">
                          Exámenes de Laboratorios disponibles
                        </p>
                        <GeneralFilter :search="{ businesstype: typebusiness }" :filterEndpoint="$API.laboratory.manageTemplateFiltered" @emptyFilter="allTemplates" @filtered="filterHandler" >
                          <DxDataGrid :scrolling="{ useNative: true }"
                         
                         :column-auto-width="true"
                            :data-source="templatesList"
                            key-expr="ID_LaboratoryTest"
                            :show-row-lines="showRowLines"
                            :show-borders="showBorders"
                            :row-alternation-enabled="rowAlternationEnabled"
                            :key="reloadp"
  
                          >
                          
                            <DxPaging :page-size="5" />
                            <DxColumn
                              :class="columnWidth"
                              cell-template="show-template"
                              caption=""
                            ></DxColumn>
                            <DxColumn
                            :class="columnWidth"
                              cell-template="laboratory-id"
                              caption="ID"
                            ></DxColumn>
                            <DxColumn
                            :class="columnWidth"
                              data-field="LaboratoryName"
                              caption="Nombre del Examen"
                            ></DxColumn>
                            <DxColumn
                            :class="columnWidth"
                              data-field="LaboratoryNameBilling"
                              caption="Nombre de facturacion"
                              v-if="typebusiness!='veterinary'"></DxColumn>
                            <DxColumn
                            :class="columnWidth"
                              data-field="LaboratoryPaymentAmount"
                              caption="Precio del servicio"
                              v-if="typebusiness!='veterinary'"></DxColumn>
                            <DxColumn
                              :class="columnWidth"
                              cell-template="delete-template"
                              caption=""
                            ></DxColumn>
                            <template #show-template="{ data }">
                              <a
                                href="javascript:void(0)"
                                @click.prevent.stop="showLabDetails(data.data); detailsparams = data.data;showTemplates=false;"
                              >
                                <v-icon color="primary" small>mdi-eye</v-icon>
                              </a>
                            </template>
                            <template #laboratory-id="{ data }">
                               <span
                               v-if="ID_LaboratoryTest == data.data.ID_LaboratoryTest"
  
                              style="color: dodgerblue;"
                              >{{ data.data.ID_LaboratoryTest }}</span>
                              <span v-else>{{ data.data.ID_LaboratoryTest }}</span>
                            </template>
                            <template #delete-template="{ data }">
                              <div>
                              <a
                                href="javascript:void(0)"
                                @click.prevent="templateToCopy = data.data;showTemplates=false;
                                  showAlert('question2', 'Información', '¿Seguro que desea copiar el examen de laboratorio seleccionado?','copyclipboard');"
                              >
                                <v-icon small>mdi-content-copy</v-icon>
                              </a>
  
                              </div>
                            </template>
                            <!-- <template #laboratory-name="{ data }">
                               <span
                              :search-value="data.data.LaboratoryName"
                              style="color: dodgerblue;"
                              >{{ data.data.LaboratoryName }}</span>
                              <span :search-value="data.data.LaboratoryName">{{ data.data.LaboratoryName }}</span>
                            </template> -->
                          </DxDataGrid>
                        </GeneralFilter>

                      </v-card>
                    </v-dialog>
                  
                          <v-col  class="box"  cols="12" md="12" style="margin-top: 1rem;" >
                            <div style="padding: .4rem;" class="mb-3">
                              <template v-if="!detailsparams">
                                <p class="text-h6">Agregar Examen de laboratorio</p>
                              </template>
                              <template v-else>
                                <p class="text-h6">Actualizar Examen de laboratorio</p>
                              </template>
                              <v-form v-model="valid">
                                <v-row>
                                  <v-col cols="12" md="6" class="pr-8">
                                    <v-text-field outlined dense 
                                      v-model="form.LaboratoryName"
                                      label="Nombre del Examen de Laboratorio"
                                      required
                                      :rules="required"
                                      clearable
                                      ref="LaboratoryNameInput"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12"  md="6" class="pr-8">
                                    <v-text-field outlined dense 
                                      v-model="form.LaboratoryNameBilling"
                                      label="Nombre de Facturación"

                                      clearable
                                      ref="LaboratoryNameBillingInput"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12"  md="6" class="pr-8 ">
                                    <v-text-field outlined dense 
                                      v-model="form.LaboratoryPaymentAmount"
                                      label="Monto del Servicio"
                                      type="number"
                                      clearable
                                      ref="LaboratoryPaymentAmountInput"
                                    ></v-text-field>
                                    

                                    <!--   <div style=" display: flex; justify-content: space-between;">
                                        <template   v-if="!detailsparams">
                                          <v-btn

                                          :disabled="!valid"
                                          class="btnsave"
                                          @click.prevent.stop="typebusiness=='veterinary' ? addTemplatesvet() : addTemplates()">
                                          Guardar
                                          </v-btn>
                                        </template>

                                        <template v-else>
                                          <v-btn

                                            class="btnsave"
                                            @click.prevent.stop="updatemanageTable"
                                            >
                                          Actualizar
                                          </v-btn>

                                        </template>
                                        <v-btn
                                          v-if="detailsparams"
                                            class="btnclean"
                                            @click="clean"

                                          >
                                          Limpiar
                                          </v-btn>


                                          <v-btn v-if="detailsparams"
                                          class="btndelete"
                                          @click.prevent="templateToDelete = detailsparams;
                                            showAlert('question', 'Eliminar', '¿Seguro que desea eliminar el examen de laboratorio seleccionado?', 'delete');"
                                          >
                                              Borrar
                                          </v-btn>

                                      </div> -->

                                
                                  </v-col>
                                  <v-col cols="12"  md="6" class="pr-8 ">
                                    <v-autocomplete
                                      v-model="form.family"
                                      :items="families"
                                      item-text="name"
                                      item-value="id"
                                      label="Familia"
                                      outlined
                                      dense
                                      clearable
                                      attach
                                    ></v-autocomplete>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" md="6" class="pr-8 ">
                                    <v-autocomplete
                                      v-model="form.category"
                                      :items="filteredCategories"
                                      item-text="name"
                                      item-value="id"
                                      label="Categoría"
                                      outlined
                                      dense
                                      clearable
                                      attach
                                    ></v-autocomplete>
                                  </v-col>
                                  <v-col cols="12"  md="6" class="pr-8 ">
                                    <v-autocomplete
                                      v-model="form.subcategory"
                                      :items="filteredSubcategories"
                                      item-text="name"
                                      item-value="id"
                                      label="Subcategoría"
                                      outlined
                                      dense
                                      clearable
                                      attach
                                    ></v-autocomplete>
                                  </v-col>
                                </v-row>
                              </v-form>
                            </div>
                          </v-col>
                  
            

             
               <v-col   class="box"  cols="12" md="12" xl="12"  style="margin-top: 1rem;">
                      <div  style="padding: 16px;" >
                        <p class="text-h6">
                          Examen de laboratorio:
                          <span v-if="laboratoryName">{{ laboratoryName }}</span>
                          <span v-else>No hay Examen seleccionado</span>
                        </p>
                        <div  v-if="laboratoryName"  style="padding-right: .8rem; display: flex; justify-content: flex-end;flex-wrap: wrap">
                          <v-btn
                          class="btn-add mb-2"
                          @click.prevent="prevLabTest= {show: true, data: {header: laboratoryName ,body: details}, prevView: true}"
                          style="margin-right: .5rem;"
                          >
                          Vista previa
                          </v-btn>
                          <v-btn
                            class="btn-add  mb-2"
                            style="margin-right: .5rem;"
                            @click="addrow"
                          >
                            Agregar Prueba
                          </v-btn>
                          <v-btn
                            class="btnsave mb-2 "
                            style="margin-right: .5rem;"
                            @click="updateTable"
                          >
                            Actualizar Parametros
                          </v-btn>
                        </div>
                        <DxDataGrid 
                        :scrolling="{ useNative: true }"
                         style="margin-top: 2.5rem;"
                          id="gridContainer"
                          ref="gridContainer"
                          @editing-start="onEditingStart"
                          :data-source="details"
                          :allow-column-reordering="true"
                          :show-borders="true"
                          :column-auto-width="true"
                          key-expr="ID_TestParam"
                          :onRowUpdating="handleRowUpdating"
                          :onRowRemoving="handleRowDeleting"
                          @key-down="oKeyDnown"
                          >
                         <!--  @row-updated="UpdateParamsRow"
                          @row-removed="DeleteParamsRow"
                          @row-inserted="saveParams" -->
                          <DxPaging :enabled="false" />
                          <DxEditing
                            :allow-updating="true"
                            :allow-deleting="true"
                            :confirmDelete = false
                            :select-text-on-edit-start="true"
                            start-edit-action="click"
                            mode="cell"
                            :use-icons="true"
                            />
                            <DxKeyboardNavigation
                            :edit-on-key-press="true"
                            enter-key-action="moveFocus"
                            enter-key-direction="column"
                          />
                          <DxButton name="delete"/>
                          <DxColumn :class="columnWidth" data-field="ParameterOrder" caption="Orden" sort-order="asc" />
                          <DxColumn :class="columnWidth"  data-field="ParameterName" caption="Nombre" />
                          <DxColumn :class="columnWidth" 
                            data-field="ParameterValue"
                            caption="Valor"
                          ></DxColumn>
                          <DxColumn :class="columnWidth" 
                            data-field="ParameterUnit"
                            caption="Unidad"
                          ></DxColumn>
                          <DxColumn :class="columnWidth" 
                            data-field="ParameterExpected"
                            caption="Expectativa"
                          ></DxColumn>
                          <DxColumn
                            :width="200"
                            cell-template="parameter-selection"
                            caption="Tipo"
                          >
                            
                          </DxColumn>
                          <template #parameter-selection="{ data }">
                   <span class="scroll-fixing-purchase">
                    <select  @change="changueHandler(data.data)" class="form-control custom-select " v-model="data.data.ParameterType">
                      <option v-for="rt in rowType" :value="rt.ID" 
                      :key="rt.ID"
                      >
                        {{ rt.Name }}
                      </option>
                    </select>
  
  
  
    
                    </span>
                  </template>
                        </DxDataGrid>
                      </div>
                    </v-col>

         
            </v-row>

</div>


          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-expansion-panel-content>
    <v-dialog v-model="loading" max-width="290">
      <div>
    <transition name="fade">
      <div  class="loading-overlay">
        <div class="loading-dialog">
          <div class="loading-spinner"></div>
          <p class="loading-text">{{ messageload }}</p>
          <p class="loading-text">por favor no cierre el programa</p>
        </div>
      </div>
    </transition>
  </div>

    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="290">
      <delete-template-dialog
        :data="templateToDelete"
        :name="templateToDelete.LaboratoryName"
        @f_call="deleteVaccine($event)"
        v-if="deleteDialog"
        @close="closeDialog"
      >
      </delete-template-dialog>
    </v-dialog>


        <alerts
          v-if="alert.show"
          v-on:close_alert="closeAlert"
          v-on:accept_alert="acceptAlert"
          :properties="alert"
        ></alerts>



   <print-lab-test

      :data="prevLabTest"

    ></print-lab-test>


  </v-expansion-panel>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxPaging,
  //DxLookup,
 
  DxKeyboardNavigation,
  DxButton,
} from "devextreme-vue/data-grid";
import PrintLabTest from "../../../../components/ReportsAndPrintings/PrintLabTest.vue";
import Alerts from "../../../../components/Alerts.vue";
import DeleteTemplateDialog from "./DeleteTemplateDialog";
import GeneralFilter from "../../../../components/GeneralFilter.vue";
import Vue from "vue";
export default {
  name: "ManageTemplates",
  components: {
    PrintLabTest,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    GeneralFilter,
 //   DxLookup,
    DeleteTemplateDialog,
   
    Alerts,
    DxKeyboardNavigation,
    DxButton,
  },
  props: ["win"],
  data() {
    return {
      row: null,
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      required: [(v) => !!v || "El campo es requerido" ],
      valid: false,
      tab: null,
      templatesList: [],
      form: {},
      details: [],
      ID_Test: null,
      showTemplates: false,
      laboratoryName: null,
      templateToDelete: null,
      templateToCopy: null,
      deleteDialog: false,
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      ID_LaboratoryTest: "",
      id_user_login: null,
      showAddbutton: false,
      reloadp: 0 ,
      rowType: [
        {
          ID: "T",
          Name: "Titulo",
        },
        {
          ID: "V",
          Name: "Valor",
        },
        {
          ID: "W",
          Name: "Espacio en blanco",
        },
        {
          ID: "S",
          Name: "Subtitulo",
        },
        {
          ID: "P",
          Name: "Salto de página",
        },
        {
          ID: "F",
          Name: "Firma y Sello",
        },
        {
          ID: "C",
          Name: "Comentario",
        }
      ],
      search: {},
      typebusiness:null,
      sendhandler: false,
      newTable: [],
      detailsparams: null,
      loadCreated: false,
      loading: false,
      messageload: "Guardando...",
      prevLabTest:{
        show: false,
        data: []
      },
      categories: [],
      subcategories: [],
      families : [],
      
      reloadNewnames: false,
    };
  },
  watch: {
    deleteDialog() {
      if (!this.deleteDialog) {
        this.closeDialog();
      }
    },
  },
  mounted() {
    this.getAllcat();
    this.typebusiness = JSON.parse(localStorage.getItem("user")).businesstype || ""
    this.id_user_login = JSON.parse(localStorage.getItem("user")).id;
    this.search.business =
      JSON.parse(localStorage.getItem("user")).businessid || "";
    this.search.branch = JSON.parse(localStorage.getItem("user")).branch || "";
    this.form.ID_Business = this.search.business;
    this.form.ID_Branch = this.search.branch;
    this.form.ID_User_Insert = this.id_user_login;
    this.allTemplates();
  },

  computed: {
    filteredCategories() {   
      console.log('filtro')
      return this.form.family
          ? this.categories.filter(
              (category) => category.id_family == this.form.family
          )
          : [];
      
  },
  filteredSubcategories() { 
      return this.form.category
          ? this.subcategories.filter(
              (subcategory) => subcategory.id_cat == this.form.category
          )
          : [];
  },
  columnWidth() {
 return this.$vuetify.breakpoint.smAndDown ? "150px" : "auto !important";
    },

  },
  methods: {
    filterHandler(response){
      this.templatesList = response
    },
    checkEquals(){
      return this.form.LaboratoryName == this.detailsparams.LaboratoryName &&
      this.form.LaboratoryNameBilling == this.detailsparams.LaboratoryNameBilling &&
      this.form.LaboratoryPaymentAmount ==  this.detailsparams.LaboratoryPaymentAmount;


    },
    clean(){
      this.form = {};
      this.details = [];
      this.newTable = [];
      this.detailsparams = null;
      this.ID_Test = null;
      this.laboratoryName = null;
      this.ID_LaboratoryTest = null
      this.$refs.LaboratoryNameInput.reset();
      this.$refs.LaboratoryNameBillingInput.reset();
      this.$refs.LaboratoryPaymentAmountInput.reset();
      this.newTable = [];
    },
    addrow() {
     let x = this.getOrdernumber();
     //console.log(x);
      let newValue ={
        ID_TestParam: Math.random().toString(36).substr(2, 9),
        ParameterOrder: x,
        ParameterName: "",
        ParameterValue: "",
        ParameterUnit: "",
        ParameterExpected: "",
        ParameterType: "W",
        Created: true
      };
      this.details.push(newValue);
      //this.getInserted();
     // this.$refs.gridContainer.instance.addRow(newValue);
    },
    async getAllcat(){
      try{
        const [categories, subcategories, families] = await Promise.all([
          this.$API.itemtypes.getCategoriesHistory(),
          this.$API.itemtypes.getSubsHistory(),
          this.$API.itemtypes.getFamiliesHistory()
        ]);
        this.categories = categories;
        this.subcategories = subcategories;
        this.families = families;
      }
      catch(e){
        console.log(e)
      }  
    
    },
    getOrdernumber(){
            let order = 0;
            this.details.forEach(element => {
                if(element.ParameterOrder > order){
                    order = element.ParameterOrder;
                }
            });
            let x = parseInt(order);
            return x ? x + 1 : 1;
        },
    oKeyDnown(e) {
      if(e.event.key=="Tab"){
                e.event.preventDefault();
                var event = new KeyboardEvent("keydown", {
                    bubbles: true,
                    cancelable: true,
                    keyCode: 13
                });
                e.event.target.dispatchEvent(event);
                /* var clickEvent = new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window
                });
                e.event.target.dispatchEvent(clickEvent); */
            }
    },
    changueHandler(data){
      if(data.Created) return

      this.newTable.push({ data: {...data,id_user_update: this.id_user_login, id: data.ID_TestParam ,ID_Business:this.search.business, ID_Branch: this.search.branch} , methods: "update"})

    },

    onEditingStart(e) {

      if (e.column.dataField == "ParameterName") {
        if(e.data.ParameterType== "W" ) e.cancel = true;
        if(e.data.ParameterType== "P") e.cancel = true;
        if(e.data.ParameterType== "F") e.cancel = true;
      }
      else if(e.column.dataField== "ParameterUnit"){
        if(e.data.ParameterType!= "V" || e.data.ParameterType == undefined || e.data.ParameterType == null ) e.cancel = true;
      }
      else if(e.column.dataField== "ParameterExpected"){
        if(e.data.ParameterType!= "V" || e.data.ParameterType == undefined || e.data.ParameterType == null  ) e.cancel = true;
      }
      else if(e.column.dataField== "ParameterValue") e.cancel = true;
      //to cancel editing  e.cancel = true;
      //to check column name e.column.dataField
    },
    handleRowUpdating(e){
      let hasMatch = false;
      let {ParameterExpected,ParameterName,ParameterOrder,ParameterType,ParameterUnit,ParameterValue} = e.newData;
      if(ParameterOrder){
        this.details.forEach(element => {
          if(element.ParameterOrder == ParameterOrder){
          element.ParameterOrder = e.oldData.ParameterOrder;
          this.showAlert("danger", "Error", "El orden ya existe");
          hasMatch = true;
          }
        });

      }

      if(!hasMatch  ){
      ParameterOrder ? ParameterOrder: ParameterOrder = e.oldData.ParameterOrder;
      //transfer old data to new data if new data is empty or undefined
      ParameterExpected ? ParameterExpected: ParameterExpected = e.oldData.ParameterExpected;
      ParameterName ? ParameterName: ParameterName = e.oldData.ParameterName;
      ParameterType ? ParameterType: ParameterType = e.oldData.ParameterType;
      ParameterUnit ? ParameterUnit: ParameterUnit = e.oldData.ParameterUnit;
      ParameterValue ? ParameterValue: ParameterValue = e.oldData.ParameterValue;


      if(!e.oldData.Created)  this.newTable.push({ data: {ParameterExpected,ParameterName,ParameterOrder,ParameterType,ParameterUnit,ParameterValue,id_user_update: this.id_user_login, id: e.key} , methods: "update"})
      }

    },
    handleRowDeleting(e){

      this.newTable.push({id: e.data.ID_TestParam, methods: "delete"})

    },
    handleRowInserting(e){

      //const {ParameterExpected,ParameterName,ParameterOrder,ParameterType,ParameterUnit,ParameterValue} = e.data
      this.newTable.push({ data: { ...e.data,id_user_insert: this.id_user_login, id: this.ID_LaboratoryTest,ID_Business:this.search.business, ID_Branch: this.search.branch} , methods: "insert"}) //add values extra


    },

    getInserted(){
      let inserted = [];
      this.details.forEach(element => {
        if(element.Created){
          inserted.push({data: {...element,id_user_insert: this.id_user_login, id: this.ID_LaboratoryTest,ID_Business:this.search.business, ID_Branch: this.search.branch} , methods: "insert"});
        }
      });
      if(inserted.length > 0) this.newTable.push(...inserted);
    },
    updatemanageTable(){
      let data = []
      data.push({data:{... this.form,ID_LaboratoryTest: this.ID_LaboratoryTest },methods: "labtest"});
      this.$API.laboratory.updateDataTable({...this.form,ID_LaboratoryTest: this.ID_LaboratoryTest}).then(() => {

        this.loadCreated = false;
        this.sendhandler = false
        this.reloadNewnames= true;
        this.allTemplates();
        this.showAlert(
            "success",
            "Ingreso correcto",
            "Parametros ingresados correctamente",

          );

      });
      this.clean();

    },
    updateTable() {
      this.loading = true;
      //get Inserted values
      this.details.forEach(element => {
        if(element.Created){
          this.newTable.push({data: {...element,id_user_insert: this.id_user_login, id: this.ID_LaboratoryTest,ID_Business:this.search.business, ID_Branch: this.search.branch} , methods: "insert"});
        }
      });

       if(this.newTable.length == 0) {
        this.showAlert("success","No hay cambios","No se han realizado cambios en la tabla")
        this.loading = false;
        return
        }

        this.$API.laboratory.updatealltable(this.newTable).then(() => {
          this.loadCreated = false;
          this.newTable = [];
          let data ;

          this.templatesList.forEach(element => {

            if(element.ID_LaboratoryTest == this.ID_LaboratoryTest){

              data = element;
            }
          });
          if(data) this.showLabDetails(data);
          //this.clean();
          //this.showLabDetails(data);

          this.showAlert(
              "success",
              "Ingreso correcto",
              "Parametros ingresados correctamente",

            );
           this.loading = false;


        })
        .catch(() => {

          this.showAlert(
            "danger",
            "Ocurrio un problema",
            "Ha ocurrido un error al ingresar los parametros"
          );

          this.loading = false;
        });
      //dont forget reload tabl


    },
    saveParams(e) {
      e.data.id_user_insert = this.id_user_login;
      e.data.id = this.ID_LaboratoryTest;

      this.$API.laboratory
        .addParamsRow(e.data)
        .then(() => {
          //this.showLabDetails({LaboratoryName: this.LaboratoryName, ID_LaboratoryTest: this.ID_LaboratoryTest})
          this.showAlert(
              "success",
              "Ingreso correcto",
              "Parametros ingresados correctamente",
             // "insertedTable"
            );
        })
        .catch(() => {
          this.showAlert(
            "danger",
            "Ocurrio un problema",
            "Ha ocurrido un problema por favor contacte al administrador"
          );
        });

    },
    async allTemplates() {
       await this.$API.laboratory.manageTemplate(this.search).then((response) => {
        this.templatesList = response;
        if (this.reloadNewnames ){
          this.templatesList.forEach(element => {
            if(element.ID_LaboratoryTest == this.detailsparams.ID_LaboratoryTest){
              this.detailsparams = element;
            }
          });
          this.showLabDetails(this.detailsparams)
          this.reloadNewnames = false;
        }

      });
    },
    async addTemplates() {
      if(!this.valid){
        this.showAlert(
          "warning",
          "Campos vacios",
          "Por favor ingrese todos los campos requeridos"
        );
        return
      }
      this.form.ID_Business = this.search.business;
      this.form.ID_Branch = this.search.branch;
      this.form.ID_User_Insert = this.id_user_login;

      this.$API.laboratory.addTemplate(this.form).then((resp) => {
  this.showLabDetails(resp, true ) 

        this.showAlert(
              "success",
              "Ingreso correcto",
              "Datos del laboratorio ingresados correctamente",
              "insertedTable"
            );
    this.allTemplates();


      })
      .catch((res) =>{
        console.log(res)
        this.showAlert(
              "danger",
              "Ocurrio un problema",
              "Ha ocurrido un problema por favor contacte al administrador"
            );
      });









      //this.details = [];
      //this.form = {};
      this.form.ID_Business = this.search.business;
      this.form.ID_Branch = this.search.branch;
      this.form.ID_User_Insert = this.id_user_login;
    },
    async addTemplatesvet() {
      if(!this.valid){
        this.showAlert(
          "warning",
          "Campos vacios",
          "Por favor ingrese todos los campos requeridos"
        );
        return
      }

this.form.ID_Business = this.search.business;
this.form.ID_Branch = this.search.branch;
this.form.ID_User_Insert = this.id_user_login;

this.$API.laboratory.addTemplatevet(this.form).then((resp) => {
  this.showLabDetails(resp, true ) 


  this.showAlert(
        "success",
        "Ingreso correcto",
        "Datos del laboratorio ingresados correctamente",
        "insertedTable"
      );
    this.allTemplates();



})
.catch(() =>{
  this.showAlert(
        "danger",
        "Ocurrio un problema",
        "Ha ocurrido un problema por favor contacte al administrador"
      );
});









//this.details = [];
//this.form = {};
this.form.ID_Business = this.search.business;
this.form.ID_Branch = this.search.branch;
this.form.ID_User_Insert = this.id_user_login;
},
    showLabDetails(data, Hasnew = false ) {
      if(!this.templateToCopy){
      Vue.set(this.form, 'family', data.family || null);
      Vue.set(this.form, 'category', data.category || null);
      this.form.subcategory = data.subcategory || null;
      this.form.LaboratoryName = data.LaboratoryName;
      this.form.LaboratoryNameBilling = data.LaboratoryNameBilling;
      this.form.LaboratoryPaymentAmount = data.LaboratoryPaymentAmount;
      this.laboratoryName = data.LaboratoryName;
      this.ID_LaboratoryTest = data.ID_LaboratoryTest || null;
      }
      if(Hasnew){
        this.details = [];
        
      }
      else{
      this.$API.laboratory
        .showLabDetails(data.ID_LaboratoryTest)
        .then((response) => {

          if(this.templateToCopy){
           this.templateToCopy = {
             header: this.templateToCopy,
             body: response
           }



          }
          else{
            this.details = response;
            this.ID_Test = response[0].ID_TestParam;
          }



        });
      }
    },
    UpdateParamsRow(e) {
      e.data.ID_User_Upddate = this.id_user_login;
      this.$API.laboratory
        .editParamsRow(e.data.ID_TestParam, e.data)
        .then(() => {
          this.showAlert(
              "success",
              "Modificación correcta",
              "Parametros modificados correctamente"
            );
        });
    },
    DeleteParamsRow(e) {
      this.showAlert("question", "Eliminar", "¿Desea eliminar el registro?", "deleterow");
       this.row = e.data.ID_TestParam;


    },
    deleterow(rowid){
      this.$API.laboratory
        .deleteParamsRow(rowid)
        .then(() => {
          this.showAlert(
              "success",
              "Eliminación correcta",
              "Parametros eliminados correctamente"
            );
            this.row = null;

        })
        .catch(() => {
          this.showAlert(
              "danger",
              "Ocurrio un problema",
              "Ha ocurrido un problema por favor contacte al administrador"
            );
        })
    }
    ,
    delete(e) {
      this.$API.laboratory
        .deleteTemplate(e.ID_LaboratoryTest)
        .then(() => {
          this.showAlert(
              "success",
              "Eliminación correcta",
              "Datos del laboratorio eliminados correctamente",
              "reloadData"
            );
          this.allTemplates()
          this.clean();
        })
        .catch(() => {
          this.showAlert(
              "danger",
              "Ocurrio un problema",
              "Ha ocurrido un problema por favor contacte al administrador"
            );
            this.clean();
        })
    },

    closeDialog() {
      this.templateToDelete = null;
      this.deleteDialog = false;

      if (this.alert.options == "cleanDataTable") {
        this.clean();
      }


    },

    /*Function for Alerts*/
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    closeAlert() {
      this.alert.show = false;


      if (this.alert.options == "copyclipboard") {
         this.templateToCopy = null;
      }

      if(this.alert.options == "reloaddetails"){
        this.showLabDetails(this.detailsparams);
      }

      if(this.alert.options == "reloadData")
        this.allTemplates()
      /* if(this.alert.options == "insertedTable"){

        this.$API.laboratory.manageTemplate(this.search).then((response) => {
        this.templatesList = response;

        this.templatesList.forEach((element) => {

          if (element.LaboratoryName == this.form.LaboratoryName && element.LaboratoryNameBilling == this.form.LaboratoryNameBilling
          && element.LaboratoryPaymentAmount == this.form.LaboratoryPaymentAmount) {
            this.form.ID_LaboratoryTest = element.ID_LaboratoryTest;

          }
        })
        if(this.form.ID_LaboratoryTest){
          this.$API.laboratory
          .showLabDetails(this.form.ID_LaboratoryTest)
          .then((response) => {
            this.laboratoryName = this.form.LaboratoryName;
            this.ID_LaboratoryTest = this.form.ID_LaboratoryTest;
            this.details = response;
           this.ID_Test = response[0].ID_TestParam;

          });

        }
      });
      } */

    },
    acceptAlert() {
      this.alert.show = false;

      if(this.alert.options == "delete"){
        this.delete(this.templateToDelete)
      }
      if(this.alert.options == "deleterow"){

        this.deleterow(this.row)
      }
      if (this.alert.options == "copyclipboard") {
         this.createCopy();
      }

    },
    async createCopy(){
     try {
      this.loading = true;
      let form = {};
      let data = null;
      form.ID_User_Insert= JSON.parse(localStorage.getItem("user")).id;
      form.ID_Business = JSON.parse(localStorage.getItem("user")).businessid || "";
      form.ID_Branch= JSON.parse(localStorage.getItem("user")).branch || "";
      let counter = 0;

      if(this.templateToCopy.LaboratoryName.includes("-Copia ")){
        this.templateToCopy.LaboratoryName = this.templateToCopy.LaboratoryName.replace(/-Copia \d/g, "");
        counter++;
      }

      this.templatesList.forEach((element) => {
        if(element.LaboratoryName.includes(this.templateToCopy.LaboratoryName + "-Copia ")
        ) counter ++;

      });

      form.LaboratoryName = this.templateToCopy.LaboratoryName + "-Copia " + (counter + 1);
      form.LaboratoryNameBilling = this.templateToCopy.LaboratoryNameBilling;
      form.LaboratoryPaymentAmount = this.templateToCopy.LaboratoryPaymentAmount;

      await this.showLabDetails(this.templateToCopy);



      const response = await this.$API.laboratory.addTemplate(form)


      const resp = await this.$API.laboratory.manageTemplate(this.search)
      this.templatesList = resp;

      this.templatesList.forEach((element) => {

          if (element.ID_LaboratoryTest == response.ID_LaboratoryTest) {
            data = element;

          }
      });
      if(data){

        let newTable = [];
        let values = this.templateToCopy.body

        values.forEach((e) => {
          const { ParameterExpected, ParameterName,ParameterOrder,ParameterType, ParameterUnit,ParameterValue} = e
          newTable.push({ data: { ParameterExpected,ParameterName,ParameterOrder,ParameterType,ParameterUnit, ParameterValue, id: response.ID_LaboratoryTest} , methods: "insert"})
        })
        if(newTable.length > 0) await this.$API.laboratory.updatealltable(newTable)



      }
      else {
        this.showAlert(
          "danger",
          "Error al copiar plantilla",
          "No se encontro la copia"
        );
      }

      this.showAlert(
        "success",
        "Plantilla copiada",
        "La plantilla se ha copiado correctamente",
        "reloadData"
      );
      this.loading = false;
      this.templateToCopy = null;
     } catch (error) {
      this.loading = false;
       this.showAlert(
              "danger",
              "Error al copiar plantilla",
              "Ha ocurrido un problema por favor contacte al administrador"
            );
      this.templateToCopy = null;
     }




    }

  },
};
</script>

<style scoped>
.box{
    border-style: solid;
    border-color: black;
    border-width: 1px;
    background-color: white;

    border-radius: 15px;
   /*  margin: 0.5rem; */

}
.btnsave{
  background-color: #00A178 !important;
  color: white;
}
.btn-add{
  background-color: #3498DB  !important;
  color: white;
}
.btnclean{
  background-color: #F29D35 !important;
  color: white;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabsNh{
    width: 100%;
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;
    
}

.loading-dialog {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border-top: 3px solid #3490dc;
  border-right: 3px solid #3490dc;
  border-bottom: 3px solid #3490dc;
  border-left: 3px solid #eee;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-text {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #3490dc;
}
.btnblue {
    background-color: #1976d2 !important;
    color: white;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.btndelete{
  background-color: #E3191E !important;
  color: white;
}
.tabs {

padding: 10px;
background-color: rgba(60, 60, 60, 1);
color: white;
}

</style>
