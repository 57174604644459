<template>
    <div>
        <block v-if="loading"></block>
        <!-- BTN add -->
        
        <v-btn class="btn-add" @click.prevent.stop="onClickDownload">
            <v-icon class="mr-3">mdi-download</v-icon>Descargar PDF y JSON
        </v-btn>
        <!-- BTN add -->

        <!--
    <PrintPosFa :dataProps="print" />
        <PrintPosTk :dataProps="print2" />
 -->
        

      
           
            <div v-for="(printedComponent, index) in printedComponents" :key="index" style="max-height: 1px;">

                <component :is="printedComponent.component" :dataProps="printedComponent.data" :autoPrint="true"
                    :id_print="printedComponent.id_print"  @autoPrintCompleted="removeComponent" @autoPrintError="cancelPrinting" />
                
               
            </div>
            <alerts
                v-if="alert.show"
                v-on:close_alert="closeAlert"
                v-on:accept_alert="acceptAlert"
                :properties="alert"
            >
            </alerts>
     
    </div>

</template>

<script>
import Block from "@/components/Block";
import Alerts from "@/components/Alerts";

//import PrintPosTk from '@/components/ReportsAndPrintings/PrintPosTk'
//import PrintPosFa from '@/components/ReportsAndPrintings/PrintPosFa'
import PrintPosFaNotVisible from '@/components/ReportsAndPrintings/PrintPosFaNotVisible'
import PrintPosTkNotVisible from '@/components/ReportsAndPrintings/PrintPosTkNotVisible'

export default {
    name: "PrintPdfJson",
    props: {
        data: {
            type: Array,
            default: null
        },
    },
    components: { Block, Alerts, PrintPosFaNotVisible, PrintPosTkNotVisible},

    data() {
        return {
            loading: false,
            invoices: [],
            alert: {
                type: "success",
                show: false,
                header: "",
                body: "",
            },
            userinfo: JSON.parse(localStorage.getItem("user")),
            branchinfo: JSON.parse(localStorage.getItem("branch")),
            tempData: [],
            print: {
                show: false,
                data: [],
            },
            print2: {
                show: false,
                data: [],
            },
            invoiceDteJson: null,
            printedComponents: [],
            invoiceDocRelated: [],
            invoiceSelectItems: [],
            invoiceItems: [],
            searchbusiness: {},
            infobusiness: {},
            branchData: {},
            showPrintedComponents: false, 
            positionArray: 0, 
            maxPositionArray: 0
        }
    },
    mounted() {
        this.searchbusiness.branch =
            JSON.parse(localStorage.getItem("user")).branch || "";
        this.searchbusiness.business =
            JSON.parse(localStorage.getItem("user")).businessid || "";
        this.getInfoBranch()
    },
    methods: {
        removeComponent(id) {
           
            this.printedComponents = this.printedComponents.filter(x => x.id_print !== id);
            this.positionArray++;
            if(this.positionArray < this.maxPositionArray) {
                
                const invoice = this.data[this.positionArray];
                this.SendToPrint(invoice);
            }
            else {
                this.$emit('setLoading', false);
                this.$emit('displaySuccessAlert')
            }
        },
        cancelPrinting(err) {
            console.log(err)
            this.$emit('setLoading', false);
            
            this.$emit('displayErrorAlert', err)
         
        },
        async initialDataCall(InvoiceType, ID_Invoice) {
            if (InvoiceType == 'DNC' || InvoiceType == 'DND') {
                const [
                    headerFooter,
                    infoBranch,
                    invoiceProductItems,
                    invoiceServiceItems,
                    dteJsonHeaderInfo,
                    docRelated,
                ] = await Promise.all([
                    this.getHeaderFooter(),
                    this.getInfoBranch(),
                    this.getInvoiceProductItems(ID_Invoice),
                    this.getInvoiceServiceItems(ID_Invoice),
                    this.getDteJsonHeaderInfo(ID_Invoice),
                    this.getDocRelated(ID_Invoice),
                ]);
               
                let dte =  dteJsonHeaderInfo

                return {
                    headerFooter,
                    infoBranch,
                    invoiceProductItems,
                    invoiceServiceItems,
                    dte,
                    docRelated
                }
            } else {
                 const [
                    headerFooter,
                    infoBranch,
                    invoiceProductItems,
                    invoiceServiceItems,
                    dteJsonHeaderInfo,
                    docRelated

                ] = await Promise.all([
                    this.getHeaderFooter(),
                    this.getInfoBranch(),
                    this.getInvoiceProductItems(ID_Invoice),
                    this.getInvoiceServiceItems(ID_Invoice),
                    this.getDteJsonHeaderInfo(ID_Invoice),
                    this.getDocRelated(ID_Invoice)
                ]);
                let dte =  dteJsonHeaderInfo 
         
                
                return {
                    headerFooter,
                    infoBranch,
                    invoiceProductItems,
                    invoiceServiceItems,
                    dte,
                    docRelated
                }
            }
        },

        getHeaderFooter() {
            return new Promise((resolve, reject) => {
                let search = {
                    ID_Branch: this.ID_Branch,
                    ID_Business: this.ID_Business
                };
                this.$API.HF.getHF(search).then((data) => {
                    let header = [],
                        footer = [];
                    header = data.filter((x) => x.State == "1" && x.Type == "Header");
                    let headerData = header.length > 0 ? JSON.parse(header[0].Body).html : "";
                    footer = data.filter((x) => x.State == "1" && x.Type == "Footer");
                    let footerData = footer.length > 0 ? JSON.parse(footer[0].Body).html : "";
                    resolve({ header: headerData, footer: footerData })
                }).catch((err) => {
                    console.log(err)
                    reject(err``)
                });
            })

        },

        async getInfoBranch() {
            this.$API.branches.findSpecificBranch(this.searchbusiness).then((res) => {
                console.log("res es:", res)
                const infobusiness = res[0];
                const currentBranch =  JSON.parse(localStorage.getItem("branch"));
                this.branchData = {

                    AuthFeMode: infobusiness.AuthFeMode,
                    BusinessBillingNRC: infobusiness.BusinessBillingNRC,
                    BusinessBillingNIT: infobusiness.BusinessBillingNIT,
                    BusinessBillingName: infobusiness.BusinessBillingName,
                    ...currentBranch
                }
               
            }).catch((err) => {
                console.log(err)
            });
        },

        async getDteJsonHeaderInfo(ID_Invoice) {
            return new Promise((resolve, reject) => {
                this.$API.invoices
                    .getDteJsonHeaderByInvoice(ID_Invoice)
                    .then((response) => {
                        if (response.length > 0)
                            resolve(response[0] )
                        else 
                            resolve(null )
                    })
                    .catch((err) => {
                        console.log("Error", err);
                        reject(err)
                    });
            })
        },

        addNDorNC(data) {
            data.data.IsNDorNC = true
            this.showInvoice = data
        },

        showInvoiceDetail(data) {
            data.data.IsNDorNC = false
            this.showInvoice = data
        },

        async getDocRelated(ID_Invoice) {
            return new Promise((resolve, reject) => {
                let invoiceDocRelated;
                this.$API.invoices
                    .getDocRelatedByInvoice(ID_Invoice)
                    .then((response) => {
                        invoiceDocRelated = response
                        console.log("DOC RELATED", invoiceDocRelated)
                        resolve(invoiceDocRelated)
                    })
                    .catch((err) => {
                        console.log("Error", err);
                        reject(invoiceDocRelated)
                    });
            })
        },

        async getInvoiceProductItems(ID_Invoice) {
            return new Promise((resolve, reject) => {
                const invoiceItems = []
                let totalItemsPrice = 0

                this.$API.invoices
                    .getInvoiceProductItems(ID_Invoice)
                    .then((response) => {
                        if (response != []) {
                            response.map((el) => {
                                invoiceItems.push(el);
                                totalItemsPrice +=
                                    el.ItemGravada + el.ItemExenta + el.ItemNoSuj;
                            });
                            resolve({ invoiceItems, totalItemsPrice });
                        } else {
                            resolve({ invoiceItems: [], totalItemsPrice: 0 });
                        }
                    })
                    .catch((err) => {
                        console.log("Error", err);
                        reject(err)
                    });
            })
        },

        async getInvoiceServiceItems(ID_Invoice) {
            return new Promise((resolve, reject) => {
                const invoiceItems = [];
                let totalItemsPrice = 0;

                this.$API.invoices
                    .getInvoiceServiceItems(ID_Invoice)
                    .then((response) => {
                        if (response != []) {
                            response.map((el) => {
                                invoiceItems.push(el);
                                totalItemsPrice += el.ItemGravada + el.ItemExenta + el.ItemNoSuj;
                            });
                            resolve({ invoiceItems, totalItemsPrice });
                        } else {
                            resolve({ invoiceItems: [], totalItemsPrice: 0 });
                        }
                    })
                    .catch((err) => {
                        console.error("Error", err);
                        reject(err);
                    });
            });
        },


        manageprint(invoice, data) {
            // Push the component data to the printedComponents array
            const productServices =  data.invoiceProductItems.invoiceItems.concat(data.invoiceServiceItems.invoiceItems)
            let invoiceDteJson = {
                ReceptorDescActividad: null,
            }
            if(data.dte)
                invoiceDteJson = data.dte

            if (invoice.ID_Invoice.startsWith('TCKS')) {
                this.printedComponents.push({
                    component: PrintPosTkNotVisible,
                    show: true,
                    data: { 
                        show: true,
                        data: {
                            header: invoice,
                            user: this.userinfo,
                            body: productServices,
                            branch: this.branchData,
                            dteJson: invoiceDteJson,
                            docRelated: data.docRelated,
                        },
                    },
                    id_print: new Date().getTime()
                });
                this.print = {
                    show: true,
                    data: {
                            header: invoice,
                            user: this.userinfo,
                            body: productServices,
                            branch: this.branchData,
                            dteJson: invoiceDteJson,
                            docRelated: data.docRelated,
                        },
                    
                }
            } else {
               
                this.printedComponents.push({
                    component: PrintPosFaNotVisible,
                    data: { 
                        show: true,
                        data: {
                            header: invoice,
                            user: this.userinfo,
                            body: productServices,
                            branch: this.branchData,
                            dteJson: invoiceDteJson,
                            docRelated: data.docRelated,
                        },
                    },
                    id_print: new Date().getTime()
                
                });
               
            }
        },

        async downloadJsonPdf() {
            
            this.printedComponents = [];
            try{

                if(this.data.length == 0) {
                    return;
                }
                this.$emit('setLoading', true)
                this.maxPositionArray = this.data.length;
                this.positionArray = 0;
    
                const invoice = this.data[this.positionArray];
                await this.SendToPrint(invoice);
            }
            catch(err) {
                console.log(err)
                this.cancelPrinting(err);   
            }

           
           // this.showPrintedComponents = true; 
        },

        async SendToPrint(invoice) {
            this.invoiceItems = []
            this.totalItemsPrice = 0;
            const data = await this.initialDataCall(invoice.InvoiceType, invoice.ID_Invoice);
            this.tempData = data;
            console.log(invoice)
            this.manageprint(invoice, data);
            invoice.IsNDorNC = false;
           // throw new Error("Error")
        },

        onClickDownload() {
            this.showAlert("question2", "Advertencia", "¿Esta seguro que desea proceder con la descarga?", "download");
        },
        showAlert(type, header, body, options = null) {
            type = type == null ? "danger" : type;
            this.alert.type = type;
            this.alert.header = header;
            this.alert.body = body;
            this.alert.show = true;
            this.alert.options = options != null ? options : null;
        },
        closeAlert() {
            this.alert.show = false;
        },
        acceptAlert() {
            this.alert.show = false;
            if (this.alert.options == 'download') {
                this.downloadJsonPdf();
            }
        },
        showError(message) {
            this.showAlert("warning", "Advertencia", message);
        },

    },
}
</script>

<style scoped>
.btn-add {
    background-color: #00A178 !important;
    color: white;
}

.btnsave {
    background-color: #3498db !important;
    color: white;
}

.background {
    background: white !important;
    color: white;
}

.btn-position {
    position: relative;
    top: 45px;
    z-index: 1;
}

.item {
    @media screen and (min-width: 1068px) {
        position: absolute;
        z-index: 1;
    }

    @media screen and (max-width: 900px) {
        padding-bottom: 20px;
    }
}

.btn-add {
    background-color: #3498db !important;
    color: white;
}
</style>