const transferData = {
    namespaced: true,
    state: () => (
        {transfers: [], reloadData: false}
    ),
    mutations: {
        setTransfers(state, data) {
            state.transfers = data;
        },
        setReload(state, data) {
            state.reloadData = data
            console.log('info ', state.reloadData)
        }
    }
};

export default transferData
