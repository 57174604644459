<template>

            <div  class="pa-5" ref="accountscatalogchooser" >
              
              <block v-if="loading"></block>
              <div
                class="d-flex flex-wrap align-self-center justify-space-between mb-3"
              >
                <h1><b>Catalogo de cuentas</b></h1>
              </div>
              <v-row class="mb-2">
                <v-col cols="12" class="text-left">
                  <!-- <v-btn
                    class="btn-add"
                    @click.prevent.stop="expandTable"
                  >
                  {{ !expandAll ? 'Expandir' : 'Contraer'}} todos
                </v-btn> -->
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-btn
                  class="btn-add"
                  @click.prevent.stop="SendTobussines"
                  >
                    Gestión de Catálogo
                  </v-btn>

                  
                </v-col>
              </v-row>
              <div>
                <!-- Table 4 start - Services -->
                <GeneralFilter
                  entity="lista"
                  :filterEndpoint="$API.branchaccountcatalog.getActiveFilterBranchAccountCatalog"
                  :search="search"
                  @emptyFilter="getAllAcounts"
                  @filtered="filterHandler"
                >
                  <div >
                    <!-- <DxTreeList class="isScrolledByGeneralFilter"
                      :data-source="AcountList"
                      :show-row-lines="showRowLines"
                      :show-borders="showBorders"
                      @exporting="onExporting"
                      :row-alternation-enabled="rowAlternationEnabled"
                      :key-field="true"
                      :column-auto-width="true"
                      :word-wrap-enabled="true"
                      id="tasks"
                      parent-id-expr="id_parent"
                      @selection-changed="onSelectionChanged"
                      :auto-expand-all="expandAll"
                      :expanded-row-keys="expandedRowKeys"
                      ref="treeList"
                    
                    > -->
                    <DxDataGrid
                      key-expr="id"
                      :scrolling="{ useNative: true }"
                      :data-source="AcountList"
                      :show-row-lines="showRowLines"
                      :show-borders="showBorders"
                      @exporting="onExporting"
                      :column-auto-width="true"
                      :word-wrap-enabled="true"
                      :focused-row-enabled="true"
                      @focused-row-changed="onSelectionChanged"
                      ref="treeList"
                    
                    >
                   
                      <!-- <DxSelection
                          mode="single"
                        /> -->
                      <DxPaging :enabled="true" :page-size="10" />
                      <DxColumn caption="Código" cell-template="add-template"  :width="300"></DxColumn>
                      <DxColumn
                        data-field="account_description"
                        caption="Nombre"
                        :width="300"
                      ></DxColumn>
                      <DxColumn
                        data-field="SelectedItem"
                        caption="Mov"
                        :width="100"
                      ></DxColumn>
                      <DxColumn
                        cell-template="send-template"
                        caption="Acciones"
                        :width="160"
                        v-if="
                      rolesStatus.findIndex(
                        (role) => role.RoleName == 'admin'
                      ) > -1 ||
                      rolesStatus.findIndex((role) => role.RoleName == 'root') >
                        -1 ||
                      rolesStatus.findIndex((role) => role.ID_Role == 47) > -1
                    "
                        ></DxColumn>
                      <template #send-template="{ data }">
                  <div
                  class="d-flex justify-content-center align-items-center text-center "
                  >
                    <v-btn
                        v-if="data.data.SelectedItem"
                    class="btnsave"
                    @click.prevent.stop="sendSelection(data.data)"
                  >
                    <v-icon color="white">
                    
                    >
                      mdi-plus
                    </v-icon> 
                  </v-btn>
                  </div>
                </template>
                <template #add-template="{ data }">
                  <div>
                    <a
                      class="icon-size text-black"
                      :class="
                        data.data.account_code.length === 2
                          ? 'font-weight-bold'
                          : 'font-weight-regular'
                      "
                    
                    >
                      {{ data.data.account_code }}
                      
                    </a>
                  </div>
                </template>
                     
                    </DxDataGrid>
                  </div>
                </GeneralFilter>
                <!-- Table 4 end - Services -->
              </div>

              <!-- Show  Modal -->
              <v-dialog v-model="showModel" max-width="450">
                
              </v-dialog>

             
           
     
            <alerts
              v-if="alert.show"
              v-on:close_alert="closeAlert"
              v-on:accept_alert="acceptAlert"
              :properties="alert"
            ></alerts>
          </div>
   

</template>

<script>
import GeneralFilter from "@/components/GeneralFilter";
// import {
//   DxTreeList,
//   DxColumn,
//   DxScrolling,
//   DxPaging,
//   DxSelection,
  
// /*   DxExport, */
// } from "devextreme-vue/tree-list";
import {
  DxDataGrid,
  DxColumn,
  
  DxPaging,
  // DxSelection,
} from "devextreme-vue/data-grid";

import Alerts from "@/components/Alerts";
import Block from "@/components/Block";
import { mapState } from "vuex";
import { printToExcel } from "@/helpers/printToexcel";
export default {
  name: "AccountingItemForm",
  props: ["win"],
  components: {
    // DxTreeList,
    DxDataGrid,
    DxColumn,
    // DxSelection,

    Block,
   
    
    DxPaging,
  
    Alerts,
   /*  DxExport, */
    GeneralFilter,
  },

  data() {
    return {
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      tab: null,
      loading: false,
      panel: 0,
      form: {},
      keyField: "id",
      AcountList: {},
      AcountItem: null,
      expandAll: false,
      search: {
        filter: null,
      },
      valid: null,
      editService: null,
      serviceToDelete: null,
      deleteDialog: false,
      showModel: false,
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      typebusiness: null,
     
      filterServices: {},
      showOrderVacMed: false,
      optFilterOrder: 1,
      expandedRowKeys: [],
    };
  },
  watch: {
    showModel() {
      if (!this.showModel) {
        this.closeDialog();
      } else {
        this.$refs.accountscatalogchooser.addEventListener('keyup', this.enterSelection);
      }
    },
  },
  computed: {
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
  },
  beforeDestroy() {
    this.$refs.accountscatalogchooser.removeEventListener('keyup', this.enterSelection);
  },
  mounted() {
    console.log('mounted')
    this.$store.dispatch("rolesUser/getAllRoles");
    this.typebusiness =
      JSON.parse(localStorage.getItem("user")).businesstype || "";
    this.filterServices.business = JSON.parse(
      localStorage.getItem("user")
    ).businessid;
    this.filterServices.branch = JSON.parse(
      localStorage.getItem("user")
    ).branch;
 
    this.getAllAcounts();
  },
  methods: {
    expandTable() {
     
      if (this.expandAll) {
       
        
        this.expandedRowKeys = [0,1,2,3,4,5,6,7,8];
        this.expandAll = false;
        this.$refs.treeList.instance.refresh();
        return;
      }
      else{
        this.expandAll = true;
        this.$refs.treeList.instance.refresh();

        
      }
      
      
    },
    createListener(){
      this.$refs.accountscatalogchooser.addEventListener('keyup', this.enterSelection);
    },
    sendSelection(data){
      
      
      this.$emit("sendSelection", data);
      this.$refs.accountscatalogchooser.removeEventListener('keyup', this.enterSelection);
      

    },
    enterSelection(e){
      if(e.key === 'Tab') return;
      
      if(e.key === 'Enter'){
        if(this.AcountItem){
          this.$emit("sendSelection", this.AcountItem);
          this.$refs.accountscatalogchooser.removeEventListener('keyup', this.enterSelection);
        }
        else
        this.showAlert("danger", "Error", "Seleccione una cuenta valida");
      }
    },

    onSelectionChanged(e) {
      
      this.$refs.accountscatalogchooser.addEventListener('keyup', this.enterSelection);
      // console.log(e.row.data);
      if(e.row.data){
        // console.log(e.row.data.SelectedItem);
        this.AcountItem = e.row.data.SelectedItem ? e.row.data : null;
        // console.log(this.AcountItem);
      }
      // console.log(e);
    },
    onExporting(e) {
      printToExcel(e, "Servicios");
    },
    async SendTobussines() {
      try{
        this.loading = true;
        const id = JSON.parse(localStorage.getItem("user")).businessid;
        const data = await this.$API.business.FindOne(id);
        this.$emit("closeDialog");
        this.$store.dispatch("addWindow", {
          name: "business",
          component: "Business",
          unique: false,
          meta: { business: data, tab: "tab-4" },
        });
        this.loading = false;
      }catch(error){
        this.loading = false;
        console.log(error);
      }
    },
    filterHandler(data){
      const { data: dataFilter } = data;
      const NewVal = dataFilter.map((item) => {
          const has_parent = dataFilter.find((item2) => item2.id_account === item.id_parent);
          if (!has_parent) {
            item.id_parent = 0;
          }

          return item;
      });
      
      this.AcountList = NewVal
      this.formatAccounts();
    },
    async getAllAcounts(){
      try {
        const result = await this.$API.branchaccountcatalog.getActiveBranchAccountCatalog();
        this.AcountList = result.data;
        this.formatAccounts();
      } catch (error) {
        console.log(error);
      }
    },
    formatAccounts(){
      for(const index in this.AcountList) {
        this.AcountList[index].id = this.AcountList[index].id_account;
      }
    },
    closeAlert() {
      this.alert.show = false;
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "deleteservice") {
        this.deleteService(this.alert.data.ID_Service);
        //(this.alert.data);
      }
    },
    showAlert(type, header, body, options = null, data = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
      this.alert.data = data != null ? data : null;
    },
    confirmdeleteService(data) {
      this.showAlert(
        "question",
        "¿Está seguro de eliminar el servicio?",
        "Esta acción no se puede deshacer",
        "deleteservice",
        data
      );
    },
    
    closeDialog() {
      this.serviceToDelete = null;
      this.editService = false;
      this.showModel = false;
      this.deleteDialog = false;
     
    },

  
  },
};
</script>

<style scoped>
.close-icon {
  color: red;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}
.text-black {
  color: black;
}
.centered {
  display: flex;
  justify-content: center;
}
</style>