<template>
  <v-row class="mb-4 mx-2">
    <v-col v-if="!assignAll" cols="12" class="mb-3">
      <h3>Cuenta Contable</h3>
    </v-col>
    <v-col cols="12" md="2">
      <v-btn
        color="primary"
        class="mb-3"
        @click.prevent.stop="showAccountsModal = true"
        dark
      >
        Buscar Cuenta
      </v-btn>
    </v-col>
    <v-col cols="12" md="3" lg="2">
      <v-autocomplete
        outlined
        dense
        label="Código de Cuenta Contable"
        v-model="form.id_account"
        :items="accountList"
        attach
        item-text="account_code"
        item-value="id_account"
        hide-details
        class="mb-3"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" md="7" :lg="assignAll ? 6 : 8">
      <v-autocomplete
        outlined
        dense
        label="Nombre de Cuenta Contable"
        v-model="form.id_account"
        :items="accountList"
        attach
        item-text="account_description"
        item-value="id_account"
        hide-details
        class="mb-3"
      ></v-autocomplete>
    </v-col>
    <v-col v-if="assignAll" cols="12" lg="2" class="text-left text-lg-right">
      <slot></slot>
    </v-col>

    <v-dialog v-model="showAccountsModal" :width="dialogWidth">
      <v-card>
        <AccountingItemForm
          :win="win"
          v-on:sendSelection="accountSelectionHandler"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AccountingItemForm from "../../acounting/partial/AccountingItemForm.vue";

export default {
  name: "AccountingAssing",
  props: ["form", "win", "assignAll"],
  components: {
    AccountingItemForm,
  },
  data() {
    return {
      showAccountsModal: false,
      accountList: [],
    };
  },
  mounted() {
    this.getAssignableAccounts();
  },
  methods: {
    getAssignableAccounts() {
      this.$API.branchaccountcatalog
        .getAssignableAccounts()
        .then((response) => {
          this.accountList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    accountSelectionHandler(data) {
      this.showAccountsModal = false;
      this.form.id_account = data.id_account;
    },
  },
  computed: {
    dialogWidth() {
      return this.$vuetify.breakpoint.smAndDown ? "95%" : "60%";
    },
  },
};
</script>
