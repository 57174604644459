// export function convertToCSV(objArray, dataHeaders, _titleHeaders) {
export function convertToCSV(objArray, dataHeaders) {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  // Title headers
  // let titleLine = '';
  // for (let i = 0; i < titleHeaders.length; i++) {
  //   if (i > 0) titleLine += ';';
  //   const titleField = titleHeaders[i];
  //   if (typeof titleField === 'string' && titleField.includes(';')) {
  //     titleLine += `"${titleField}"`;
  //   } else {
  //     titleLine += titleField;
  //   }
  // }
  // str += titleLine + '\r\n';
  // Data rows
  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (let j = 0; j < dataHeaders.length; j++) {
      if (j > 0) line += ';';
      const field = array[i][dataHeaders[j]];
      const formattedField = typeof field === 'undefined' ? '' : field;
      if (typeof formattedField === 'string' && formattedField.includes(';')) {
        line += `"${formattedField}"`;
      } else {
        line += formattedField;
      }
    }

    str += line + '\r\n';
  }

  return str;
}



export function getCurrentDateTimeString() {
  const now = new Date();
  const dateString = now.toISOString().slice(0, 10); // Get YYYY-MM-DD format
  const timeString = now.toTimeString().slice(0, 8); // Get HH:MM:SS format

  return `${dateString} ${timeString}`;
}