<template>
    <v-card>
        <v-card-title class="text-h5">
            {{$t('vwinvoices_cadmincredentials')}}
        </v-card-title>

        <v-card-text>
            <form>
                <v-text-field outlined dense 
                        autocomplete="new-password"
                        v-model="form.email"
                        label="Email" type="email"
                ></v-text-field>
                <v-text-field outlined dense 
                        autocomplete="new-password"
                        v-model="form.password"
                        label="Password" type="password"
                ></v-text-field>
            </form>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="submit">
                OK
            </v-btn>

            <v-btn color="error darken-1" text @click="$emit('hideNullInvoiceDialog')">
                Cancel
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "NullInvoice",
        props: ['invoiceId'],
        data() {
            return {
                form: {}
            }
        },
        methods: {
            submit() {
                this.$API.invoices.nullInvoice(this.invoiceId, this.form)
                .then(response => {
                    this.$emit('hideNullInvoiceDialog');
                    this.$toasted.success(response.message)
                })
            }
        }
    }
</script>

<style scoped>

</style>