<template>
    <div>
        <v-card>
            <v-card-title class="text-h5">
                Are you sure you want to delete  -  {{this.data.Name}}?
            </v-card-title>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="deleteService">
                    Yes
                </v-btn>
                <v-btn color="green darken-1" text @click="$emit('close', {isDeleted: false})">
                    No
                </v-btn>
            </v-card-actions>
        </v-card>

    </div>
</template>

<script>
    export default {
        name: "DeleteServiceDialog",
        props: ['data'],
        methods: {
            deleteService() {
                this.$API.services.deleteService(this.data.id)
                    .then(() => {
                        this.$emit('close', {isDeleted: true})
                    })
            }
        }
    }
</script>

<style scoped>

</style>
