import axios from "axios";

export default {
    auth(data) {
        const options = {
            method: 'POST',
            url: 'https://apitest.dtes.mh.gob.sv/seguridad/auth',
            headers: {'content-type': 'application/x-www-form-urlencoded'},
            data: data
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    recepcionDTE(data, token) {
        const options = {
            method: 'POST',
            url: 'https://apitest.dtes.mh.gob.sv/fesv/recepciondte',
            headers: {'Authorization': token, 'content-type': 'application/json', 'User-Agent': 'InnovaApp'},
            data: data
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    }
}