<template>
        <v-expansion-panel>
            <v-expansion-panel-header class="mt-2">
                BUSCAR PRODUCTO : {{product.ItemName}}
                <template v-slot:actions>
                    <v-btn class="mr-5" @click.native.stop="reloadN++" icon small>
                        <v-icon color="primary">mdi-reload</v-icon>
                    </v-btn>
                    <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
                        <v-icon color="error">mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-card>
                    <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                        <v-tabs-slider color="red"></v-tabs-slider>
                        <v-tab href="#tab-1" v-if="$store.getters['rolesUser/getCustomRole'](110)">
                            GESTIÓN DE PRODUCTO
                            <v-icon>mdi-package-variant-closed</v-icon>
                        </v-tab>
                        <!--v-tab href="#tab-2">
                            EXISTENCIAS
                            <v-icon>mdi-package-variant</v-icon>
                        </v-tab-->
                     <!--    <v-tab href="#tab-3">
                            HISTORIAL DEL PRODUCTO
                            <v-icon>mdi-package-variant</v-icon>
                        </v-tab> -->
                        <v-tab href="#tab-4">
                            RESUMEN DE COMPRAS    
                            <v-icon>mdi-package-variant</v-icon>
                        </v-tab>
                        <v-tab href="#tab-5">
                            KARDEX
                            <v-icon>mdi-file-cabinet</v-icon>
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab" touchless>
                        <v-tab-item value="tab-1">
                            <v-card flat>
                                <EditProduct :key="reloadN" :data="product" @reloadData="reloadData" :win="win"></EditProduct>
                            </v-card>
                        </v-tab-item>
                  <!--       <v-tab-item value="tab-2">
                            <v-card flat>
                                <Stock></Stock>
                            </v-card>
                        </v-tab-item> -->
                       <!--  <v-tab-item value="tab-3">
                            <v-card flat>
                                <ProductHistory :key="reloadN" :data="product" :win="win"></ProductHistory>
                            </v-card>
                        </v-tab-item> -->
                        <v-tab-item value="tab-4">
                            <v-card flat>
                                <ProductIns :key="reloadN" :data="product" :win="win"></ProductIns>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item value="tab-5">
                            <v-card class="pa-1" flat>
                                <ProductKardex :key="reloadN" :data="product" :win="win"></ProductKardex>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-expansion-panel-content>
        </v-expansion-panel>
</template>

<script>
    import EditProduct from "../tabs/EditProduct";
    
    //import ProductHistory from "../tabs/ProductHistory";
    import ProductIns from "../tabs/ProductIns.vue";
    import ProductKardex from "../tabs/ProductKardex.vue";

    export default {
        name: "ManageProduct",
        props: ['win'],
        data() {
            return {
                panel: 0,
                tab:null,
                product: this.win.meta.product,
                reloadN: 0
            }
        },

        components: {
            EditProduct,
         
        //    ProductHistory,
            ProductIns,
            ProductKardex
        },
        methods: {
            reloadData(payload) {
                this.$emit('reloadData', payload)
            }
        }

    }
</script>

<style scoped>

</style>
