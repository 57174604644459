<template>
  <v-expansion-panel v-model="panel">
    <block v-if="loading"></block>
    <v-expansion-panel-header class="mt-2">
      Buscar Ordenes de Compra
      <template v-slot:actions>
        <v-btn class="mr-5" @click.native.stop="reload()" icon small>
          <v-icon color="primary">mdi-reload</v-icon>
        </v-btn>
        <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card>
        <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
          <v-tabs-slider color="red"></v-tabs-slider>
          <v-tab href="#tab-1">
            HISTORIAL DE ORDENES DE COMPRAS
            <v-icon>mdi-account-search</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1" class="databox pa-1">
            <v-card flat class="pa-5 position-relative white">
              <div
                class="d-flex flex-wrap align-self-center justify-space-between mb-3"
              >
                <h4>
                  <p class="ma-0">
                    Seleccione los productos o servicios facturados
                  </p>
                </h4>
                <v-bottom-sheet v-model="showFilter" inset>
                  <!--template v-slot:activator="{ on, attrs }">
                                              <v-btn color="orange" dark v-bind="attrs" v-on="on" class="filter-btn">FILTER
                                              </v-btn>
                                          </template-->
                  <v-sheet class="text-center">
                    <v-btn
                      class="mt-6"
                      text
                      color="error"
                      @click="showFilter = !showFilter"
                    >
                      Cerrar
                    </v-btn>
                    <v-btn
                      class="mt-6"
                      text
                      color="primary"
                      @click="showFilter = !showFilter"
                      >Filtro</v-btn
                    >
                    <div class="my-3 pa-4">
                      <div>
                        <date-picker
                          label="From Date"
                          @dateSelected="
                            (param) => (filterProducts.start = param)
                          "
                        ></date-picker>
                        <date-picker
                          label="To Date"
                          @dateSelected="
                            (param) => (filterProducts.end = param)
                          "
                        ></date-picker>
                        <v-select
                          outlined
                          dense
                          :items="providers"
                          item-text="ProviderName"
                          item-value="ID_Provider"
                          attach
                          v-model="filterProducts.provider"
                          label="SELECT PROVIDER"
                        >
                        </v-select>
                      </div>
                    </div>
                  </v-sheet>
                </v-bottom-sheet>
              </div>

              <div class="background rounded-lg pt-3">
                <date-filter
                  v-if="!loading"
                  class="item"
                  @filtered-data="(filteredData) => (ordersList = filteredData)"
                  :dataToFilter="ordersList"
                  componentToFilter="PurchareOrdersList"
                />
                <GeneralFilter class="mt-9"
                  :filterEndpoint="$API.orders.getProvidersProductsFiltered"
                  entity="lista"
                  :search="search"
                  @emptyFilter="getOrderList"
                  @filtered="filterHandler"
                >
                  <DxDataGrid class="isScrolledByGeneralFilter"
                    :data-source="ordersList"
                    :show-row-lines="showRowLines"
                    :show-borders="showBorders"
                    @exporting="onExporting"
                    :row-alternation-enabled="rowAlternationEnabled"
                    :paging="{ enabled: false }"
                    style="max-height: 600px"
                  >
                    <DxExport :enabled="true" />
                    <DxScrolling row-rendering-mode="virtual" />
                    <!--DxPaging :page-size="10" /-->
                    <DxColumn
                      :width="80"
                      cell-template="show-template"
                      caption=""
                    ></DxColumn>
                    <DxColumn
                      data-field="OrderStatus"
                      caption="Estado"
                    ></DxColumn>
                    <DxColumn
                      data-field="ID_PurchaseOrder"
                      caption="ID"
                    ></DxColumn>
                    <DxColumn
                      data-field="ID_PurchaseLegalInvoiceNumber"
                      caption="Número de factura"
                    ></DxColumn>
                    <DxColumn
                      data-field="sys_user.FirstName"
                      caption="Realizado por"
                    ></DxColumn>
                    <DxColumn
                      data-field="PurchaseOrderDate"
                      caption="Fecha de orden de compra"
                    ></DxColumn>
                    <DxColumn
                      data-field="PurchaseOrderTotal"
                      caption="Precio"
                    ></DxColumn>
                    <DxColumn
                      data-field="PurchaseInvoiceDate"
                      caption="Fecha de la orden"
                    ></DxColumn>
                    <DxColumn
                      data-field="inventory_provider.ProviderName"
                      caption="Nombre del proveedor"
                    ></DxColumn>
                    <template #show-template="{ data }">
                      <a
                        href="#"
                        class="mx-1"
                        @click.prevent.stop="
                          showOrder = data;
                          showModal = true;
                        "
                      >
                        <v-icon color="primary">mdi-eye</v-icon>
                      </a>
                    </template>
                  </DxDataGrid>
                </GeneralFilter>
              </div>

              <!-- Show  Modal -->
              <v-dialog v-model="showModal" max-width="75%">
                <OrderDetails
                  :key="Math.random()"
                  :out="true"
                  :order="showOrder"
                  v-if="showModal"
                  @close="closeDialog"
                ></OrderDetails>
              </v-dialog>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import GeneralFilter from "@/components/GeneralFilter";
import OrderDetails from "./../PurchaseOrderDetails";
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxExport,
} from "devextreme-vue/data-grid";
import { mapGetters } from "vuex";
import { printToExcel } from "@/helpers/printToexcel";
import DatePicker from "@/components/DatePicker";
import DateFilter from "@/components/DateFilter";

export default {
  name: "PurchareOrdersList",
  components: {
    DxDataGrid,
    DxColumn,
    OrderDetails,
    DatePicker,
    DxScrolling,
    DxExport,
    DateFilter,
    GeneralFilter
  },
  props: ["win"],
  data() {
    return {
      loading: false,
      tab: null,
      panel: 0,
      form: {},
      url: null,
      image: null,
      providers: [],
      showModal: false,
      showFilter: false,
      showOrder: null,
      ordersList: [],
      filterProducts: { pending: true, provider: null, start: null, end: null },
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      search: {
        filter: null,
        filterObj: null
      }
    };
  },
  mounted() {
    this.getOrderList();
    this.getAllProviders();
  },
  computed: {
    ...mapGetters(["reloadData"]),
  },
  watch: {
    showModal() {
      if (!this.showModal) {
        this.closeDialog();
      }
    },
    filterProducts: {
      deep: true,
      handler: "getOrderList",
    },
    reloadData: {
      immediate: true,
      handler: "reloadPurchase",
    },
  },

  methods: {
    onExporting(e) {
      printToExcel(e, "Ordenes de Compra");
    },
    reload() {
      this.getOrderList();
      this.getAllProviders();
      setTimeout(() => {
        window.scrollTo(0, this.$store.getters.getScrollTop);
        //alert(this.$store.getters.getScrollTop)
      }, 300);
    },
    Preview_image() {
      this.url = URL.createObjectURL(this.image);
    },
    getOrderList() {
      let filterObj = { ...this.filterProducts };
      Object.keys(filterObj).map((key) => {
        if (filterObj[key] == "All") delete filterObj[key];
      });
      let params = {
        business:
          JSON.parse(localStorage.getItem("user")).businessid != undefined
            ? JSON.parse(localStorage.getItem("user")).businessid
            : 0,
        branch:
          JSON.parse(localStorage.getItem("user")).branch != undefined
            ? JSON.parse(localStorage.getItem("user")).branch
            : 0,
      };

      filterObj = { ...filterObj, ...params };

      this.search.filterObj = filterObj
      console.log(filterObj)

      this.loading = true;
      this.$API.orders.getProvidersProductsHistory(filterObj).then((response) => {
        this.loading = false;
        this.ordersList = response;
      });
    },
    getAllProviders() {
      this.$API.providers.getProviders().then((response) => {
        this.providers = response;
      });
    },

    closeDialog() {
      this.showOrder = false;
      this.showModal = false;
      this.getOrderList();
    },
    async reloadPurchase() {
      if (this.reloadData == "purchase") {
        await this.getOrderList();
        await this.getAllProviders();
        this.$store.dispatch("reloadData", null);
      }
    },
    filterHandler(response) {
      console.log(response)
      this.ordersList = response;
    },
  },
};
</script>

<style scoped>
.close-icon {
  color: red;
}

.filter-btn {
  position: absolute;
  top: 60px;
  left: 30px;
  z-index: 9;
}

.databox {
  padding: 16px;
  border-radius: 15px;
}

/* width */
.scroll-bar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scroll-bar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.scroll-bar::-webkit-scrollbar-thumb {
  background: #262626;
  border-radius: 10px;
}
.background {
  background: white !important;
  color: white;
}
.item {
  @media screen and (min-width: 1068px) {
    position: absolute;
    z-index: 1;
  }
  @media screen and (max-width: 900px) {
    padding-bottom: 20px;
  }
}
</style>
