const vetProviders = {
    namespaced: true,
    state: () => ({
            vetProductoprov: 0,
            
        
    }),
    mutations: {
        
        setvetProductoprov(state) {
            state.vetProductoprov++
        }
       
    },
  
}

export default vetProviders