<template>
    <v-expansion-panels >
        <v-expansion-panel>
            <v-expansion-panel-header>
                Manage Users
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-card>
                    <v-tabs
                            v-model="tab"
                            dark background-color="primary"
                            icons-and-text
                    >
                        <v-tabs-slider  color="red"></v-tabs-slider>

                        <v-tab href="#tab-1">
                            Manage User
                            <v-icon>mdi-account-box</v-icon>
                        </v-tab>

                        <v-tab href="#tab-2">
                            Personal Images
                            <v-icon>mdi-image</v-icon>
                        </v-tab>

                        <v-tab href="#tab-3">
                            Add New User
                            <v-icon>mdi-plus</v-icon>
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item
                                value="tab-1"
                        >
                            <v-card flat>
                                <v-card-text>
                                    <ManageUser></ManageUser>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item
                                value="tab-2"
                        >
                            <v-card flat>
                                <v-card-text>
                                    <PersonalImages></PersonalImages>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item
                                value="tab-3"
                        >
                            <v-card flat>
                                <v-card-text>
                                    <AddUser></AddUser>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
            <v-expansion-panel-header>
                Users List
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-card>
                    <UserList></UserList>
                </v-card>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import ManageUser from './partial/UserData';
    import PersonalImages from './partial/PersonalImages';
    import AddUser from './partial/AddUser';
    import UserList from './partial/UserList';
    export default {
        name: "Users",
        data () {
            return {
                tab: null,
            }
        },
        components: {
            ManageUser,PersonalImages,AddUser,UserList
        }
    }
</script>

<style scoped>

</style>
