const vetPatientData = {
    namespaced: true,
    state: () => ({
        currentView: "tab-1",
        reloaders: {
            ManageVeterinaryPatient: 0,
            VeterinaryMedicalH: 0,
            VeterinaryGrooming: 0,
            VeterinaryVaccines: 0,
            VeterinaryMedications: 0,
            VeterinaryLaboratoryTest: 0,
            VeterinaryReproduction: 0,
            VeterinarySurgery: 0,
            VeterinaryImages: 0,
            VeterinaryPrescription: 0,
            MedicalReports: 0,
        },
        datereproduction: null,
        dataSurgery: null,
        dataImages: null,
        dataLabs: null,
    }),
    mutations: {
        changeView(state, view){
            state.currentView = view
        },
        setDatereproduction(state, payload){
            state.datereproduction = payload
        },
        setDateSurgery(state, payload){
            state.dataSurgery = payload
        },
        setDateImage(state, payload){
            state.dataImages = payload
        },
        setDateLabs(state, payload){
            state.dataLabs = payload
        },
        setReloaders(state, payload){
            switch(payload) {
                case 'ManageVeterinaryPatiente':
                    state.reloaders.ManageVeterinaryPatient++;
                    break;
                case 'VeterinaryMedicalH':
                    state.reloaders.VeterinaryMedicalH++;
                    break;
                case 'VeterinaryGrooming':
                    state.reloaders.VeterinaryGrooming++;
                    break;
                case 'VeterinaryVaccines':
                    state.reloaders.VeterinaryVaccines++;
                    break;
                case 'VeterinaryMedications':
                    state.reloaders.VeterinaryMedications++;
                    break;
                case 'VeterinaryLaboratoryTest':
                    state.reloaders.VeterinaryLaboratoryTest++;
                    break;
                case 'VeterinaryReproduction':
                    state.reloaders.VeterinaryReproduction++;
                    break;
                case 'VeterinarySurgery':
                    state.reloaders.VeterinarySurgery++;
                    break;
                case 'VeterinaryImages':
                    state.reloaders.VeterinaryImages++;
                    break;
                case 'VeterinaryPrescription':
                    state.reloaders.VeterinaryPrescription++;
                    break;
                case 'MedicalReports':
                    state.reloaders.MedicalReports++;
                    break;
                
            }
        },
        cleanData(state){
            state.datereproduction = null
            state.dataSurgery = null
            state.dataImages = null
            state.dataLabs = null
        }
    },
  
}

export default vetPatientData