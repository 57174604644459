<template>
  <v-expansion-panel v-model="panel">
    <v-expansion-panel-header class="mt-2">
      SERVICIOS
      <template v-slot:actions>
        <v-btn class="mr-5" @click.native.stop="reload()" icon small>
          <v-icon color="primary">mdi-reload</v-icon>
        </v-btn>
        <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card>
        <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
          <v-tabs-slider color="red"></v-tabs-slider>
          <v-tab href="#tab-1">
            SERVICIOS
            <v-icon>mdi-account-search</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <v-card flat color="white" class="pa-3 ma-1">
              <div
                class="d-flex flex-wrap align-self-center justify-space-between mb-3"
              >
                <h1><b>Servicios del sistema</b></h1>
              </div>
              <v-row class=" mb-2">
                <v-col class="mb-2 d-flex flex-wrap" cols="12">
                  <div>
                  <v-btn
                    class="btn-add mr-1 mb-1"
                    v-if="typebusiness == 'veterinary'"
                    @click.prevent.stop="showOrderVacMed = !showOrderVacMed"
                  >
                    Ordenar medicamentos/vacunas
                  </v-btn>
                </div>
                <div>
                  <v-btn
                    v-if="$store.getters['rolesUser/getCustomRole'](47)"
                    class="btnsave"
                    @click.prevent.stop="showModel = true"
                  >
                    Agregar un nuevo servicio
                  </v-btn>

                  <v-dialog v-model="showOrderVacMed" scrollable  :width="dialogWidth">
                    <v-card>
                      <v-card-title class="text-h5">
                        Ordenamiento de medicamentos / vacunas
                      </v-card-title>

                      <v-card-text>
                        <v-radio-group v-model="optFilterOrder">
                          <v-radio
                            :value="1"
                            label="Ver vacunas y medicamentos de caninos"
                          ></v-radio>
                          <v-radio
                            :value="2"
                            label="Ver vacunas y medicamentos de felinos"
                          ></v-radio>
                          <v-radio
                            :value="3"
                            label="Ver vacunas y medicamentos de todas las especies"
                          ></v-radio>
                        </v-radio-group>
                        <!-- Table 1 Start - ServicesCan -->
                        <GeneralFilter
                          entity="lista"
                          :filterEndpoint="$API.services.getAllServicesFiltered"
                          :search="search"
                          @emptyFilter="getAllServices"
                          @filtered="filterHandlerServiceCan"
                          v-if="optFilterOrder == 1"
                        >
                          <DxDataGrid class="isScrolledByGeneralFilter" :data-source="servicesCan"  :scrolling="{ useNative: true }"
                          >
                            <DxColumn
                              :width="80"
                              cell-template="show-order"
                              caption="Orden"
                            ></DxColumn>
                            <DxColumn
                              data-field="NameService"
                              caption="Nombre del servicio"
                            ></DxColumn>
                            <DxColumn
                              data-field="PeriodTime"
                              caption="Período"
                            ></DxColumn>
                            <DxColumn
                              cell-template="show-unit"
                              caption="Unidad de tiempo"
                            ></DxColumn>
                            <DxColumn
                              cell-template="show-type"
                              caption="Tipo"
                            ></DxColumn>
                            <template #show-order="{ data }">
                              <div
                                :allow-sorting="true"
                                style="background-color: #1976d2; padding: 4px"
                              >
                                <input
                                  type="number"
                                  v-model="data.data.Order"
                                  @change="UpdateRowCan"
                                  style="color: white"
                                />
                              </div>
                            </template>
                            <template #show-unit="{ data }">
                              <div>
                                <span v-if="data.data.UnitPeriod == 'Year'"
                                  >Año</span
                                >
                                <span v-if="data.data.UnitPeriod == 'Month'"
                                  >Mes</span
                                >
                                <span v-if="data.data.UnitPeriod == 'Week'"
                                  >Semana</span
                                >
                                <span v-if="data.data.UnitPeriod == 'Day'"
                                  >Día</span
                                >
                              </div>
                            </template>
                            <template #show-type="{ data }">
                              <div>
                                <span v-if="data.data.TypeService == 'VAC'"
                                  >Vacuna</span
                                >
                                <span v-if="data.data.TypeService == 'MED'"
                                  >Medicamento</span
                                >
                              </div>
                            </template>
                          </DxDataGrid>
                        </GeneralFilter>
                        <!-- Table 1 End - ServicesCan -->

                        <!-- Table 2 Start - ServicesFel -->
                        <GeneralFilter
                          entity="lista"
                          :filterEndpoint="$API.services.getAllServicesFiltered"
                          :search="search"
                          @emptyFilter="getAllServices"
                          @filtered="filterHandlerServicesFel"
                          v-if="optFilterOrder == 2"
                        >
                          <DxDataGrid class="isScrolledByGeneralFilter" :data-source="servicesFel">
                            <DxColumn
                              :width="80"
                              cell-template="show-order"
                              :allow-sorting="true"
                              caption="Orden"
                            ></DxColumn>
                            <DxColumn
                              data-field="NameService"
                              caption="Nombre del servicio"
                            ></DxColumn>
                            <DxColumn
                              data-field="PeriodTime"
                              caption="Período"
                            ></DxColumn>
                            <DxColumn
                              cell-template="show-unit"
                              caption="Unidad de tiempo"
                            ></DxColumn>
                            <DxColumn
                              cell-template="show-type"
                              caption="Tipo"
                            ></DxColumn>
                            <template #show-order="{ data }">
                              <div
                                style="background-color: #1976d2; padding: 4px"
                              >
                                <input
                                  type="number"
                                  v-model="data.data.Order"
                                  @change="UpdateRowFel"
                                  style="color: white"
                                />
                              </div>
                            </template>
                            <template #show-unit="{ data }">
                              <div>
                                <span v-if="data.data.UnitPeriod == 'Year'"
                                  >Año</span
                                >
                                <span v-if="data.data.UnitPeriod == 'Month'"
                                  >Mes</span
                                >
                                <span v-if="data.data.UnitPeriod == 'Week'"
                                  >Semana</span
                                >
                                <span v-if="data.data.UnitPeriod == 'Day'"
                                  >Día</span
                                >
                              </div>
                            </template>
                            <template #show-type="{ data }">
                              <div>
                                <span v-if="data.data.TypeService == 'VAC'"
                                  >Vacuna</span
                                >
                                <span v-if="data.data.TypeService == 'MED'"
                                  >Medicamento</span
                                >
                              </div>
                            </template>
                          </DxDataGrid>
                        </GeneralFilter>
                        <!-- Table 2 End - ServicesFel -->

                        <!-- Table 3 Start - ServicesAll -->
                        <GeneralFilter
                          entity="lista"
                          :filterEndpoint="$API.services.getAllServicesFiltered"
                          :search="search"
                          @emptyFilter="getAllServices"
                          @filtered="filterHandlerServicesAll"
                          v-if="optFilterOrder == 3"
                          :DxScrolling="{useNative: true}"
                        >
                          <DxDataGrid class="isScrolledByGeneralFilter" :data-source="servicesAll">
                            <DxColumn
                              :width="80"
                              cell-template="show-order"
                              :allow-sorting="true"
                              caption="Orden"
                            ></DxColumn>
                            <DxColumn
                              data-field="NameService"
                              caption="Nombre del servicio"
                            ></DxColumn>
                            <DxColumn
                              data-field="PeriodTime"
                              caption="Período"
                            ></DxColumn>
                            <DxColumn
                              cell-template="show-unit"
                              caption="Unidad de tiempo"
                            ></DxColumn>
                            <DxColumn
                              cell-template="show-type"
                              caption="Tipo"
                            ></DxColumn>
                            <template #show-order="{ data }">
                              <div
                                style="background-color: #1976d2; padding: 4px"
                              >
                                <input
                                  type="number"
                                  v-model="data.data.Order"
                                  @change="UpdateRowAll"
                                  style="color: white"
                                />
                              </div>
                            </template>
                            <template #show-unit="{ data }">
                              <div>
                                <span v-if="data.data.UnitPeriod == 'Year'"
                                  >Año</span
                                >
                                <span v-if="data.data.UnitPeriod == 'Month'"
                                  >Mes</span
                                >
                                <span v-if="data.data.UnitPeriod == 'Week'"
                                  >Semana</span
                                >
                                <span v-if="data.data.UnitPeriod == 'Day'"
                                  >Día</span
                                >
                              </div>
                            </template>
                            <template #show-type="{ data }">
                              <div>
                                <span v-if="data.data.TypeService == 'VAC'"
                                  >Vacuna</span
                                >
                                <span v-if="data.data.TypeService == 'MED'"
                                  >Medicamento</span
                                >
                              </div>
                            </template>
                          </DxDataGrid>
                        </GeneralFilter>
                        <!-- Table 3 End - ServicesAll -->
                      </v-card-text>

                      <v-card-actions>
                        <v-col class="text-right">
                          <v-btn
                            class="btn-add"
                            v-if="
                              (optFilterOrder == 1 &&
                                rolesStatus.findIndex(
                                  (role) => role.RoleName == 'admin'
                                ) > -1) ||
                              rolesStatus.findIndex(
                                (role) => role.RoleName == 'root'
                              ) > -1 ||
                              rolesStatus.findIndex(
                                (role) => role.ID_Role == 49
                              ) > -1
                            "
                            @click.prevent.stop="
                              updateOrderOfServices(servicesCan)
                            "
                          >
                            <v-icon>mdi-content-save</v-icon> Guardar
                          </v-btn>

                          <v-btn
                            class="btn-add"
                            v-if="optFilterOrder == 2"
                            @click.prevent.stop="
                              updateOrderOfServices(servicesFel)
                            "
                          >
                            <v-icon>mdi-content-save</v-icon> Guardar
                          </v-btn>

                          <v-btn
                            class="btn-add"
                            v-if="optFilterOrder == 3"
                            @click.prevent.stop="
                              updateOrderOfServices(servicesAll)
                            "
                          >
                            <v-icon>mdi-content-save</v-icon> Guardar
                          </v-btn>
                        </v-col>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
                </v-col>
              </v-row>
              <div v-if="$store.getters['rolesUser/getCustomRole'](118)">
                <!-- Table 4 start - Services -->
                <GeneralFilter
                  entity="lista"
                  :filterEndpoint="$API.services.getAllServicesFiltered"
                  :search="search"
                  @emptyFilter="getAllServices"
                  @filtered="filterHandlerServices"
                >
                  <DxDataGrid class="isScrolledByGeneralFilter"
                    :data-source="services"
                    :show-row-lines="showRowLines"
                    :show-borders="showBorders"
                    @exporting="onExporting"
                    :row-alternation-enabled="rowAlternationEnabled"
                  >
                    <DxScrolling row-rendering-mode="virtual" />
                    <DxExport :enabled="true" />
                    <DxPaging :page-size="10" />
                    <DxColumn
                      :width="80"
                      cell-template="show-template"
                      caption=""
                      v-if="$store.getters['rolesUser/getCustomRole'](119)"
                    ></DxColumn>
                    <DxColumn
                      data-field="CodeService"
                      caption="Código del Servicio"
                    ></DxColumn>
                    <DxColumn
                      data-field="NameService"
                      caption="Nombre del Servicio"
                    ></DxColumn>
                    <DxColumn
                      data-field="Name"
                      caption="Nombre a Facturar"
                    ></DxColumn>
                    <DxColumn
                      data-field="TypeService"
                      caption="Tipo de Servicio"
                    >
                      <DxLookup
                        :data-source="typeService"
                        display-expr="Name"
                        value-expr="ID"
                      />
                    </DxColumn>
                    <DxColumn
                      cell-template="show-price"
                      caption="Precio"
                    ></DxColumn>
                    <!-- <DxColumn
                      :width="100"
                      cell-template="show-iva"
                      caption="Incluye iva"
                    ></DxColumn> -->
                    <DxColumn
                      :width="80"
                      v-if="$store.getters['rolesUser/getCustomRole'](49)"
                      cell-template="delete-template"
                      caption=""
                    ></DxColumn>
                    <template #show-template="{ data }">
                      <span >
                        <a
                          class="mx-1"
                          @click.prevent.stop="
                            editService = data.data;
                            showModel = true;
                          "
                        >
                          <v-icon color="primary">mdi-eye</v-icon>
                        </a>
                      </span>
                    </template>
                    <template #delete-template="{ data }">
                      <span >
                        <a @click.prevent="confirmdeleteService(data.data)">
                          <v-icon color="error" small>mdi-delete</v-icon>
                        </a>
                      </span>
                    </template>
                    <template #show-price="{ data }">
                      <label>${{ data.data.PriceCalc }}</label>
                    </template>
                    <!-- <template #show-iva="{ data }">
                      <div class="centered" style="max-height: 100px">
                        <v-checkbox v-model="data.data.Iva" :disabled="true" />
                      </div>
                    </template> -->
                  </DxDataGrid>
                </GeneralFilter>
                <!-- Table 4 end - Services -->
              </div>

              <!-- Show  Modal -->
              <v-dialog v-model="showModel" max-width="60%" scrolleable>
                <service-details
                  @close="closeDialog"
                  v-if="showModel"
                  :data="editService"
                ></service-details>
              </v-dialog>

              <v-dialog v-model="deleteDialog" max-width="290" scrollable>
                <delete-service-dialog
                  :data="serviceToDelete"
                  @close="closeDialog"
                ></delete-service-dialog>
              </v-dialog>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-expansion-panel-content>
    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    ></alerts>
  </v-expansion-panel>
</template>

<script>
import GeneralFilter from "@/components/GeneralFilter";
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxPaging,
  DxLookup,
  DxExport,
} from "devextreme-vue/data-grid";
import ServiceDetails from "../ServiceDetails";
import DeleteServiceDialog from "./../partial/DeleteServiceDialog";
import Alerts from "@/components/Alerts";
import { mapState } from "vuex";
import { printToExcel } from "@/helpers/printToexcel";
export default {
  name: "Services",
  props: ["win"],
  components: {
    DxDataGrid,
    DxColumn,
    ServiceDetails,
    DeleteServiceDialog,
    DxScrolling,
    DxPaging,
    DxLookup,
    Alerts,
    DxExport,
    GeneralFilter,
  },

  data() {
    return {
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      tab: null,
      panel: 0,
      form: {},
      servicesSelected: {},
      services: {},
      servicesCan: {},
      servicesFel: {},
      servicesAll: {},
      search: {
        filter: null,
      },
      valid: null,
      editService: null,
      serviceToDelete: null,
      deleteDialog: false,
      showModel: false,
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      typebusiness: null,
      typeService: [
        {
          ID: "PLT",
          Name: "Test de Laboratorio",
        },
        {
          ID: "OTR",
          Name: "Otro",
        },
        {
          ID: "VAC",
          Name: "Vacuna",
        },
        {
          ID: "MED",
          Name: "Medicamento",
        },
        {
          ID: "HOST",
          Name: "Hospedaje"
        },
        {
          ID: "HOSP",
          Name: "Hospital"
        }
      ],
      filterServices: {},
      showOrderVacMed: false,
      optFilterOrder: 1,
    };
  },
  watch: {
    showModel() {
      if (!this.showModel) {
        this.closeDialog();
      }
    },
  },
  computed: {
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
    dialogWidth() {

      return this.$vuetify.breakpoint.smAndDown ? '80%' : '1000px';
    }
  },
  mounted() {
    this.$store.dispatch("rolesUser/getAllRoles");
    this.typebusiness =
      JSON.parse(localStorage.getItem("user")).businesstype || "";
    this.filterServices.business = JSON.parse(
      localStorage.getItem("user")
    ).businessid;
    this.filterServices.branch = JSON.parse(
      localStorage.getItem("user")
    ).branch;
    this.search.branch = this.filterServices.branch;
    this.search.business = this.filterServices.business;
    this.getAllServices();
  },
  methods: {
    onExporting(e) {
      printToExcel(e, "Servicios");
    },
    reload() {
      this.getAllServices();
      setTimeout(() => {
        window.scrollTo(0, this.$store.getters.getScrollTop);
        //alert(this.$store.getters.getScrollTop)
      }, 300);
    },
    closeAlert() {
      this.alert.show = false;
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "deleteservice") {
        this.deleteService(this.alert.data.ID_Service);
        //(this.alert.data);
      }
    },
    showAlert(type, header, body, options = null, data = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
      this.alert.data = data != null ? data : null;
    },
    confirmdeleteService(data) {
      this.showAlert(
        "question",
        "¿Está seguro de eliminar el servicio?",
        "Esta acción no se puede deshacer",
        "deleteservice",
        data
      );
    },
    deleteService(data) {
      this.$API.services
        .logicalDeleteService(data)
        .then(() => {
          this.showAlert(
            "success",
            "Servicio eliminado",
            "El servicio ha sido eliminado correctamente."
          );
          this.closeDialog();
        })
        .catch(() => {
          this.showAlert(
            "danger",
            "Error al eliminar servicio",
            "Ocurrio un error al elimiar el servicio seleccionado."
          );
        });
    },
    getAllServices() {
      this.$API.services
        .getAllServicesHistory(this.filterServices)
        .then((response) => {
          response.forEach((element) => {
            if (element.Iva) {
              element.PriceCalc = element.Price * 1.13;
            } else {
              element.PriceCalc = element.Price;
            }
          });
          this.services = response;
          this.servicesCan = response.filter((r) => r.species == "Canino");
          this.servicesCan.sort((a, b) => a.Order - b.Order);
          this.servicesFel = response.filter((r) => r.species == "Felino");
          this.servicesFel.sort((a, b) => a.Order - b.Order);
          this.servicesAll = response.filter(
            (r) =>
              r.species == "Todas las especies" ||
              r.species == "Canino" ||
              r.species == "Felino"
          );
          this.servicesAll.sort((a, b) => a.Order - b.Order);
        });
    },
    closeDialog() {
      this.serviceToDelete = null;
      this.editService = false;
      this.showModel = false;
      this.deleteDialog = false;
      this.getAllServices(this.filterServices);
    },

    updateOrderOfServices(data) {
      this.$API.services
        .updateOrderOfServices(data)
        .then((response) => {
          this.showOrderVacMed = !this.showOrderVacMed;
          this.showAlert("success", "Servicios actualizados", response.message);
        })
        .catch((err) => {
          this.showAlert("danger", "Error actualizando servicios", err.message);
        });
    },

    async UpdateRowCan() {
      this.servicesCan.sort((a, b) => a.Order - b.Order);
    },

    async UpdateRowFel() {
      this.servicesFel.sort((a, b) => a.Order - b.Order);
    },

    async UpdateRowAll() {
      this.servicesAll.sort((a, b) => a.Order - b.Order);
    },

    filterHandlerServiceCan(response) {
      this.servicesCan = response;
    },

    filterHandlerServicesFel(response) {
      this.servicesFan = response;
    },

    filterHandlerServicesAll(response) {
      this.servicesAll = response;
    },

    filterHandlerServices(response) {
      response.forEach((element) => {
        if (element.Iva) {
          element.PriceCalc = element.Price * 1.13;
        } else {
          element.PriceCalc = element.Price;
        }
      });
      this.services = response;
      // this.servicesCan = response.filter((r) => r.species == "Canino");
      // this.servicesCan.sort((a, b) => a.Order - b.Order);
      // this.servicesFel = response.filter((r) => r.species == "Felino");
      // this.servicesFel.sort((a, b) => a.Order - b.Order);
      // this.servicesAll = response.filter(
      //   (r) =>
      //     r.species == "Todas las especies" ||
      //     r.species == "Canino" ||
      //     r.species == "Felino"
      // );
      // this.servicesAll.sort((a, b) => a.Order - b.Order);
    },
  },
};
</script>

<style scoped>
.close-icon {
  color: red;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}
.centered {
  display: flex;
  justify-content: center;
}
</style>

