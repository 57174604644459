import axios from "./axios";

export default {
    /**
     * Add Patient
     * @api {POST} /business
     */
    addBranch(data) {
        const options = {
            method: 'POST',
            url: '/branches',
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    updateBranch(data) {
        const options = {
            method: 'PUT',
            url: '/branches',
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    findBranches(id) {
        const options = {
            method: 'GET',
            url: `/branches/${id}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    findBranchesAccounting(id) {
        const options = {
            method: 'GET',
            url: `/branches/list/accounting/${id}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    findBranchesHistory(params) {
        const options = {
            method: 'GET',
            url: `/branches/list/history`,
            headers: { 'content-type': 'application/json' },
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    findBranchesFiltered(params) {
        const options = {
            method: 'GET',
            url: `/branches/list/filtered`,
            headers: { 'content-type': 'application/json' },
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    countBranchUsers(id) {
        const options = {
            method: 'GET',
            url: `/branches/count/${id}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    logicalDeleteBranch(id) {
        const options = {
            method: "PUT",
            url: `branches/logicaldelete/${id}`,
            headers: { "content-type": "application/json" },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.message)
                });
        });
    },

    deleteBranch(id) {
        const options = {
            method: 'delete',
            url: `/branches/${id}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    findSpecificBranch(params) {
        const options = {
            method: 'POST',
            url: `/branches/infobranch`,
            headers: { 'content-type': 'application/json' },
            data: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    updateInfoMh(data) {
        const options = {
            method: 'POST',
            url: `/branches/updateinfomh`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getInfoMh(data) {
        const options = {
            method: 'POST',
            url: `/branches/getinfomh`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    fixControlNumber() {
        const options = {
            method: 'POST',
            url: `/branches/fixControlNumber`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    activeContingency(data) {
        const options = {
            method: 'POST',
            url: `/branches/activeContingency`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}