<template>
    <div>
        <v-text-field :ref="`${label}search`"
            outlined dense
            v-model="customersearch"
            :label="label"
            @focus="searchFocus"
            @blur="searchFocus"
            @input="customerInputChanges"
        ></v-text-field>
        <div v-if="searchFocusFlag" :style="`max-height: 400px; overflow-y: scroll; box-shadow: 0px 5px 10px 0px; margin-top: -27px; position: absolute; z-index: 8000; ${widthforoptions} background-color: white;`">
            <div v-if="loadingSearch" style="text-align: center;">
                <v-progress-circular style="margin: 20px;"
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </div>
            <v-list lines="one" style="" v-if="!loadingSearch && !noItemsFound">
                <v-list-item v-for="(item, index) in itemsList" :key="index" @click="selectOption(item[itemValue])" :id="item[itemValue]">
                    <v-list-item-content>
                        <v-list-item-title>{{ items[index][itemText] }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <div v-if="noItemsFound" style="text-align: center;">
                <p style="margin: 20px;">No se encontraron coincidencias</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CustomAutoComplete",
    props: ["value", "filterEndpoint", "items", "itemValue", "itemText", "search", "label", "isProd"],
    
    emits: [ 'select' ],
    data() {
        return {
            widthforoptions: '',
            searchFocusFlag: false,
            waitTime: 500,
            timer: null,
            defaultItems: [],
            itemsList: [],
            searchstring: '',
            loadingSearch: false,
            noItemsFound: false,
            fromSearch: false
        }
    },
    watch: {
        items(value) {
            this.itemsList = [ ...value ];
            // console.log(this.fromSearch);
            if( !this.fromSearch ) {
                this.defaultItems = [ ...value ];
                this.fromSearch = false;
            }
        }
    },
    computed: {
        customersearch: {
            get: function(){
                if(!this.value || this.value.length === 0 || this.isProd) {
                    return '';
                }
                if(!this.itemsList.find( current => current[this.itemValue] === this.value )) {
                    return this.searchstring;
                }
                return this.itemsList.find( current => current[this.itemValue] === this.value )[this.itemText];
            },
            set: function(val){
                this.searchstring = val;
                if(this.isProd) this.searchstring = '';
            }
        },
        
    },
    mounted() {
        try {
            this.defaultItems = [...this.items];
            this.itemsList = [...this.items];
            this.widthforoptions = `width: ${this.$refs[`${ this.label }search`].$el.clientWidth}px;`;
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        searchFocus(e) {
            if(e.relatedTarget && e.relatedTarget?.id) {
                if(this.itemsList.find( current => current[this.itemValue] === e.relatedTarget.id )) {
                    if(!this.defaultItems.find( current => current[this.itemValue] === e.relatedTarget.id )) {
                        const item = this.itemsList.find( current => current[this.itemValue] === e.relatedTarget.id )
                        this.defaultItems.push(item);
                    }
                    this.selectOption(e.relatedTarget.id);
                }
                this.searchFocusFlag = !this.searchFocusFlag;
                this.clearSearchingStatus();
            } else {
                this.searchFocusFlag = !this.searchFocusFlag
                this.clearSearchingStatus();
            }
            // console.log(this.searchFocusFlag, this.itemsList, this.itemValue, this.itemText);
        },
        async customerInputChanges(e){
            // console.log(this.items.find( current => current[this.itemValue] === this.value ))
            clearTimeout(this.timer);

            this.timer = setTimeout( async () => {
                this.loadingSearch = true;
                
                if( e.length > 0 ) {
                    try {
                        const result = await this.filterEndpoint({ ...this.search, filter: e, filteredFrom: 2 });
                        if(result.length > 0) {
                            this.noItemsFound = false;
                            this.itemsList = result;
                            this.fromSearch = true;
                            this.$emit('updateItems', result);
                            // this.fromSearch = false;
                            this.loadingSearch = false;
                        } else {
                            this.loadingSearch = false;
                            this.noItemsFound = true
                        }
                    } catch (error) {
                        this.loadingSearch = false;
                        console.log(error)
                    }
                } else {
                    this.noItemsFound = false;
                    this.loadingSearch = false;
                    this.itemsList = this.defaultItems;
                    this.$emit('updateItems', this.defaultItems);
                }
            }, this.waitTime)
            // if(e.key === 'Backspace') {
            //   if(this.customersearch.length > 0)
            //   {
            //     this.customersearch = this.customersearch.slice(0, -1);
            //   }
            // } else if(e.key.length === 1) {
            //   this.customersearch += e.key;
            // }
            // console.log(this.customersearch);
        },
        selectOption(itemValue) {
            this.$emit('select', itemValue);
        },
        clearSearchingStatus() {
            this.itemsList = this.defaultItems;
            this.$emit('updateItems', this.defaultItems);
        }
    }
}
</script>

<style scoped>

</style>