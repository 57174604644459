import axios from "./axios";
export default {

    /**
     * Get All services
     * @api {Get} services
     */
    getAllServices (data) {
        const options = {
            method: 'POST',
            url: 'services/getall',
            headers: {'content-type': 'application/json'},
            data:data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getAllServicesWithPriceList (data) {
        const options = {
            method: 'POST',
            url: 'services/getallwithpricelist',
            headers: {'content-type': 'application/json'},
            data:data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getHospService(code) {
        const options = {
            method: 'GET',
            url: 'services/getHospService',
            headers: {'content-type': 'application/json'},
            params: { CodeService: code}
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getHostService(code) {
        const options = {
            method: 'GET',
            url: 'services/getHostService',
            headers: {'content-type': 'application/json'},
            params: { CodeService: code}
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getAllHospServices() {
        const options = {
            method: 'GET',
            url: 'services/getAllHospServices',
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getAllHostServices() {
        const options = {
            method: 'GET',
            url: 'services/getAllHostServices',
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getServiceById(id) {
        const options = {
            method: 'GET',
            url: `services/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getAllServicesHistory (data) {
        const options = {
            method: 'POST',
            url: 'services/getall/history',
            headers: {'content-type': 'application/json'},
            data:data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    /**
     * Get All services
     * @api {Get} services
     */
    getAllServicesFiltered(data) {
        console.log(data);
        const options = {
            method: 'POST',
            url: 'services/getall/filtered',
            headers: {'content-type': 'application/json'},
            data:data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getAllServicesWithPriceListFiltered(data) {
        console.log(data);
        const options = {
            method: 'POST',
            url: 'services/getallwithpricelist/filtered',
            headers: {'content-type': 'application/json'},
            data:data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getAllServicesFilteredColumns(data) {
        
        const options = {
            method: 'POST',
            url: 'services/getall/filteredColumns',
            headers: {'content-type': 'application/json'},
            data:data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    /**
     * Add service
     * @api {POST} services
     */
    addService(data) {
        const options = {
            method: 'POST',
            url: 'services',
            headers: {'content-type': 'application/json'},
            data: data
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },


    /**
     * Update Service
     * @api {PUT} services/:id
     */
    updateService(id, data) {

        const options = {
            method: 'PUT',
            url: `services/${id}`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },


    /**
     * Delete Service
     * @api {DELETE} services/:id
     */
    deleteService(id) {
        const options = {
            method: 'DELETE',
            url: `services/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    logicalDeleteService(id) {
        const options = {
            method: "PUT",
            url: `services/logicaldelete/${id}`,
            headers: { "content-type": "application/json" },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.message)
                });
        });
    },

    updateOrderOfServices(data) {
        const options = {
            method: 'POST',
            url: `services/updateorderofservices`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    newGetAllServices(data) {
        const options = {
            method: 'POST',
            url: `services/newGetAllServices`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    saveServicesDefault(data) {
        const options = {
            method: 'POST',
            url: `services/saveServicesDefault`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    
    getDefaultServices(data) {
        const options = {
            method: 'POST',
            url: `services/getDefaultServices`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    }

}
