<template>
    <v-dialog v-model="show" max-width="1000" scrollable>
        <v-card>
            <v-card-title class="text-h5">
              Productos y servicios disponibles
            </v-card-title>
            
            <v-card-text>
                <div class="d-flex justify-space-between mt-8" >
                    <div style="margin-top: auto; margin-bottom: auto;">
                        <div>
                            <v-btn @click="goToPreviousPage" v-if="!familiesPage" style="border-radius: 800px !important; height: 60px;"><v-icon>mdi-arrow-left</v-icon></v-btn>
                            <label class="ml-8" style="font-size: 20px; font-weight: bold;">Seleccione una {{ currentLabel }}</label>
                        </div>
                        <div class="ml-8" v-if="currentFamilyLabel">
                            <label>Filtro actual:</label><br/>
                            <label v-if="currentFamilyLabel"><strong>Familia</strong>:{{ currentFamilyLabel }}</label><label v-if="currentCategoryLabel">, <strong>Categoría</strong>: {{ currentCategoryLabel }}</label><label v-if="currentSubCategoryLabel">, <strong>SubCategoría</strong>: {{ currentSubCategoryLabel }}</label>
                        </div>
                    </div>
                    <div>
                        <v-switch :label="`Ver productos y servicios disponibles`" v-model="showElements"></v-switch>
                        <div class="d-flex justify-center" v-if="showElements">
                            <v-radio-group v-model="elementsToShow" row >
                                <v-radio :label="`Productos (${productsToShow.length})`" :value="1"></v-radio>
                                <v-radio :label="`Servicios (${servicesToShow.length})`" :value="2"></v-radio>
                            </v-radio-group>
                        </div>
                    </div>
                </div>
                <div v-if="!displayElements" style="overflow-y: scroll; max-height: 500px;" class="mt-6">
                    <v-list v-if="familiesPage" lines="one" class="mx-auto" max-width="500">
                        <div v-for="(item, index) in families" :key="index">
                            <div v-if="familyCount(item['id']) > 0">
                                <v-list-item @click="clickFamily( item.id, categoriesPerFamily(item['id']) )" >
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{item["name"]}}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Servicios y Productos Disponibles: {{ familyCount(item["id"]) }} | Categorías Disponibles: {{ categoriesPerFamily(item["id"]) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider v-if="index != families.length - 1"></v-divider>
                            </div>
                        </div>
                    </v-list>
                    <v-list v-if="categoriesPage" lines="one" class="mx-auto" max-width="500">
                        <div v-for="(item, index) in categoriesByFamily" :key="index">
                            <div>
                                <v-list-item  @click="clickCategory( item.id, subcategoriesPerCategory( item[ 'id' ] ) )" >
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{item["name"]}}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Servicios y Productos Disponibles: {{ categoryCount(item["id"]) }} | Subcategorías Disponibles: {{ subcategoriesPerCategory(item["id"]) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider v-if="index != categoriesByFamily.length - 1"></v-divider>
                            </div>
                        </div>
                    </v-list>
                    <v-list v-if="subCategoriesPage" lines="one" class="mx-auto" max-width="500">
                        <div v-for="(item, index) in subcategoriesByCategory" :key="index">
                            <v-list-item @click="clickSubCategory( item.id )" >
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{item["name"]}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        Servicios y Productos Disponibles: {{ subcategoryCount(item["id"]) }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider v-if="index != subcategoriesByCategory.length - 1"></v-divider>
                        </div>
                    </v-list>
                </div>
                <div v-else style="overflow-y: scroll;  max-height: 500px;">
                    <v-list v-if="displayProducts" lines="one" class="mx-auto elementsList">
                        <div v-for="(item, index) in productsToShow" :key="index" :id="`product${item.type}${item.id}`">
                            <div class="elementPanel" :style=" item.active ? 'background-color: rgb(241, 240, 240);' : ''" @click="activateCurrentItem(index, item)">
                                <v-list-item>
                                    <v-list-item-content>
                                        <table>
                                            <tr>
                                                <th>
                                                    ID del Producto
                                                </th>
                                                <th>
                                                    Nombre del Producto
                                                </th>
                                                <th>
                                                    Existencias
                                                </th>
                                                <th>
                                                    Lote
                                                </th>
                                                <th>
                                                    Almacén
                                                </th>
                                                
                                            </tr>
                                            <tr>
                                                <td style="text-align: center;">
                                                    {{ `${item[ 'id' ]}`}}
                                                </td>
                                                <td style="text-align: center;">
                                                    {{ `${item[ 'name' ]}`}}
                                                </td>
                                                <td style="text-align: center;">
                                                    {{ `${item[ 'stocks' ]}`}}
                                                </td>
                                                <td style="text-align: center;">
                                                    {{ `${item[ 'batch' ]}`}}
                                                </td>
                                                <td style="text-align: center;">
                                                    {{ `${item[ 'warehouse' ]}`}}
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- <v-list-item-title>
                                            {{`${item[ 'id' ]} | ${item[ 'name' ]} | ${item[ 'stocks' ]} | ${ item[ 'batch' ] } | ${ item[ 'warehouse' ] }`}}
                                        </v-list-item-title> -->
                                    </v-list-item-content>
                                    
                                </v-list-item>
                                <v-divider v-if="index != productsToShow.length - 1"></v-divider>
                            </div>
                            <v-expand-transition>
                                <div v-if="item.active" class="quantityManagement">
                                    <div class="closeQuantityManagementPanel">
                                        <v-icon class="rightAllignedArrow" @click="() => {
                                            cancelQuantityManagement(index)
                                        }" large>
                                            mdi-menu-up
                                        </v-icon>
                                    </div>
                                    <div>
                                        <!-- <v-btn class="leftSideDownPanelButton downPanelButtons btndelete" @click="() => {
                                            cancelQuantityManagement(index)
                                        }">
                                            <v-icon>
                                                mdi-cancel
                                            </v-icon>
                                        </v-btn> -->
                                        <v-icon class="buttonIcon" x-large color="white" @click="() => {
                                            increaseQty( index )
                                        }">mdi-menu-up</v-icon>
                                        <input
                                            :id="`tableinputnumber${item.type}${item.id}`"
                                            class="tableinputnumber"
                                            v-model="item.cantidad"
                                            type="text"
                                            @input="e => {
                                                onKeyPressQuantity( e, index );
                                            }"
                                            label="Nivel"
                                        />
                                        <v-icon class="buttonIcon" x-large color="white" @click="() => {
                                            decreaseQty( index )
                                        }">mdi-menu-down</v-icon>
                                        <v-icon class="downPanelButtons" large color="white" @click="() => {
                                            sendElement( index )
                                        }">
                                            mdi-check
                                        </v-icon>
                                    </div>
                                    <div :id="`errorspan${item.type}${item.id}`" class="hideSpan divErrorSpan">
                                        <div class="errorSpan"></div>
                                    </div>
                                </div>
                            </v-expand-transition>
                        </div>
                    </v-list>
                    <v-list v-if="displayServices" lines="one" class="mx-auto elementsList">
                        <div v-for="(item, index) in servicesToShow" :key="index" :id="`service${item.type}${item.id}`">
                            <div class="elementPanel" :style=" item.active ? 'background-color: rgb(241, 240, 240);' : ''" @click="activateCurrentItem(index, item)">
                                <v-list-item>
                                    <v-list-item-content>
                                        <table>
                                            <tr>
                                                <th>
                                                    ID del Servicio
                                                </th>
                                                <th>
                                                    Nombre del Servicio
                                                </th>
                                            </tr>
                                            <tr>
                                                <td style="text-align: center;">
                                                    {{ `${item[ 'id' ]}`}}
                                                </td>
                                                <td style="text-align: center;">
                                                    {{ `${item[ 'name' ]}`}}
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- <v-list-item-title>
                                            {{`${item[ 'id' ]} | ${item[ 'name' ]} | ${item[ 'stocks' ]} | ${ item[ 'batch' ] } | ${ item[ 'warehouse' ] }`}}
                                        </v-list-item-title> -->
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider v-if="index != servicesToShow.length - 1"></v-divider>
                            </div>
                            <v-expand-transition>
                                <div v-if="item.active" class="quantityManagement">
                                    <div class="closeQuantityManagementPanel">
                                        <v-icon class="rightAllignedArrow" @click="() => {
                                            cancelQuantityManagement(index)
                                        }" large>
                                            mdi-menu-up
                                        </v-icon>
                                    </div>
                                    <div>
                                        <!-- <v-btn class="leftSideDownPanelButton downPanelButtons btndelete" @click="() => {
                                            cancelQuantityManagement(index)
                                        }">
                                            <v-icon>
                                                mdi-cancel
                                            </v-icon>
                                        </v-btn> -->
                                        <v-icon class="buttonIcon" x-large color="white" @click="() => {
                                            increaseQty( index )
                                        }">mdi-menu-up</v-icon>
                                        <input
                                            :id="`tableinputnumber${item.type}${item.id}`"
                                            class="tableinputnumber"
                                            v-model="item.cantidad"
                                            type="text"
                                            @input="e => {
                                                onKeyPressQuantity( e, index );
                                            }"
                                            label="Nivel"
                                        />
                                        <v-icon class="buttonIcon" x-large color="white" @click="() => {
                                            decreaseQty( index )
                                        }">mdi-menu-down</v-icon>
                                        <v-icon class="downPanelButtons" large color="white" @click="() => {
                                            sendElement( index )
                                        }">
                                            mdi-check
                                        </v-icon>
                                        <!-- <v-btn>
                                        </v-btn> -->
                                    </div>
                                    <div :id="`errorspan${item.type}${item.id}`" class="hideSpan divErrorSpan">
                                        <div class="errorSpan"></div>
                                    </div>
                                </div>
                            </v-expand-transition>
                        </div>
                    </v-list>
                </div>
                <!-- <div style="display: flex; margin-left: 5px">
                    <input type="radio" id="InvoiceProductBtn" value="products" v-model="displayInvoice" />
                    <h5 style="margin: 5px" for="InvoiceProductBtn">Productos</h5>
                    <input style="margin-top: 1px; margin-left: 10px" type="radio" id="InvoiceServiceBtn" value="services"
                    v-model="displayInvoice" />
                    <h5 style="margin: 5px" for="InvoiceServiceBtn">Servicios</h5>
                </div>
                <template v-if="displayInvoice === 'products'">
                    
                </template>
                <template v-else-if="displayInvoice === 'services'">
                    
                </template> -->
            </v-card-text>
            <v-col class="text-right">
              <v-btn @click.stop="show = false" class="btndisable">
                Continuar
              </v-btn>
            </v-col>
        </v-card>
    </v-dialog>
</template>
<script>
import { onlyDigits } from "@/helpers/masks";

export default {
    name: 'InvoiceProductsClasificationSelector',
    components: {
    
    },
    emits: [ "close" ],
    props: [
        "value",
        "products",
        "services"
    ],
    data() {
        return {
            displayInvoice: 'services',
            families: [],
            categories: [],
            subcategories: [],
            categoriesByFamily: [],
            subcategoriesByCategory: [],
            familiesPage: true,
            categoriesPage: false,
            subCategoriesPage: false,
            showElements: false,
            elementsToShow: 2,
            hasDetails: true,
            currentFilter: {
                family: null,
                category: null,
                subcategory: null
            },
            productsMapFilter: {
                family: "IdFamily",
                category: "IdCategory",
                subcategory: "IdSubCategory"
            },
            productsToShow: [],
            servicesToShow: [],
        }
    },
    computed: {
        currentFamilyLabel(){
            return this.families.find( current => {
                const { id } = current
                return id === this.currentFilter.family
            } )?.name;
        },
        currentCategoryLabel(){
            return this.categories.find( current => {
                const { id } = current
                return id === this.currentFilter.category
            } )?.name;
        },
        currentSubCategoryLabel(){
            return this.subcategories.find( current => {
                const { id } = current
                return id === this.currentFilter.subcategory
            } )?.name;
        },
        selectedElementsToShow() {
            return this.displayServices ? this.servicesToShow : this.productsToShow;
        },
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        totalProducts() {
            return this.products.filter( current => current.IdFamily != null )
        },
        totalServices() {
            return this.services.filter( current => current.family != null )
        },
        currentLabel(){
            if( this.familiesPage ) {
                return "familia";
            } else if( this.categoriesPage ) {
                return "categoría";
            } else {
                return "subcategoría";
            }
        },
        displayElements() {
            if( this.showElements ) {
                return true;
            }

            return false;
        },
        displayProducts() {
            return this.showElements && this.elementsToShow === 1;
        },
        displayServices() {
            return this.showElements && this.elementsToShow === 2;
        },
    },
    watch: {
        hasDetails(value) {
            if( !value ) {
                this.showElements = true;
            } else {
                this.showElements = false;
            }
        },
        products() {
            this.productsToShow = this.returnProductsWithCurrentFilter();
        },
        services() {
            this.servicesToShow = this.returnServicesWithCurrentFilter();
        }
    },
    async mounted() {
        await this.getAllInfo();
        this.loadElements();
    },
    methods: {
        loadElements() {
            this.productsToShow = this.returnProductsWithCurrentFilter();
            this.servicesToShow = this.returnServicesWithCurrentFilter();
        },
        returnProductsWithCurrentFilter() {
            const filteredProducts = this.products.filter( current => {
                let returnValue = false;
                for( const property in this.currentFilter ) {
                    if( this.currentFilter[ property ] ) {
                        if( !returnValue ) {
                            returnValue = current[ this.productsMapFilter[ property ] ] === this.currentFilter[ property ];
                        } else {
                            returnValue = returnValue && current[ this.productsMapFilter[ property ] ] === this.currentFilter[ property ];
                        }
                    }
                }
                return returnValue;
            } );

            const productsArray =
                this.currentFilter.family ?
                    filteredProducts.map( current => {
                        const { ID_Item, ItemName, ItemCurrentQuantity, ItemMeasureUnit, ItemLote, NameWarehouse } = current;

                        return {
                            type: 1,
                            id: ID_Item,
                            name: ItemName,
                            cantidad: 0,
                            stocks: `${ItemCurrentQuantity} ${ItemMeasureUnit}`,
                            batch: ItemLote,
                            warehouse: NameWarehouse,
                            hasError: false,
                            active: false,
                            data: {...current}
                        };
                    } ) :
                    this.totalProducts.map( current => {
                        const { ID_Item, ItemName, ItemCurrentQuantity, ItemMeasureUnit, ItemLote, NameWarehouse } = current;
                        
                        return {
                            type: 1,
                            id: ID_Item,
                            name: ItemName,
                            cantidad: 0,
                            stocks: `${ItemCurrentQuantity} ${ItemMeasureUnit}`,
                            batch: ItemLote,
                            warehouse: NameWarehouse,
                            hasError: false,
                            active: false,
                            data: {...current}
                        };
                    } );

            return productsArray;
        },
        returnServicesWithCurrentFilter() {
            const filteredServices = this.services.filter( current => {
                let returnValue = false;
                for( const property in this.currentFilter ) {
                    if( this.currentFilter[ property ] ) {
                        if( !returnValue ) {
                            returnValue = current[ property ] === this.currentFilter[ property ];
                        } else {
                            returnValue = returnValue && current[ property ] === this.currentFilter[ property ];
                        }
                    }
                }

                return returnValue;
            } );

            const servicesArray = 
                this.currentFilter.family ?
                    filteredServices.map( current => {
                        const { CodeService, NameService } = current;

                        return {
                            type: 2,
                            id: CodeService,
                            name: NameService,
                            cantidad: 0,
                            hasError: false,
                            active: false,
                            data: {...current}
                        };
                    } ) :
                    this.totalServices.map( current => {
                        const { CodeService, NameService } = current;

                        return {
                            type: 2,
                            id: CodeService,
                            name: NameService,
                            cantidad: 0,
                            hasError: false,
                            active: false,
                            data: {...current}
                        };
                    } );

            return servicesArray;
        },
        activateCurrentItem(index) {
            for(const i in this.selectedElementsToShow) {
                if( this.selectedElementsToShow[ i ].active ) {
                    this.cancelQuantityManagement( i );
                }
            }
            
            this.selectedElementsToShow[ index ].active = true;
        },
        cancelQuantityManagement( index ) {
            const refItem = this.selectedElementsToShow[ index ];
            const { type, id } = refItem;
            const target = document.getElementById( `tableinputnumber${type}${id}` );

            [...target.classList].includes( 'erroredtableinputnumber' ) && target.classList.remove( 'erroredtableinputnumber' );

            refItem.cantidad = 0;
            refItem.hasError = false;
            refItem.active = false;
        },
        async onKeyPressQuantity(e, index){
            const refItem = this.selectedElementsToShow[ index ];
            const { data: { ItemCurrentQuantity }, type, id } = refItem;
            const errorSpan = document.getElementById(  `errorspan${type}${id}` );

            // console.log(errorSpan.classList);
            ![...errorSpan.classList].includes( 'hideSpan' ) && errorSpan.classList.add( 'hideSpan' );
            refItem.error = '';

            // console.log('refItem ', refItem);
            
            e.target.value = await onlyDigits( this, e.target.value);
            
            const { target } = e;
            const { value } = target;

            if( value.length > 0 ) {
                const numberValue = parseInt( value );
                if( numberValue > ItemCurrentQuantity ) {
                    errorSpan.classList.remove( 'hideSpan' );
                    target.classList.add( 'erroredtableinputnumber' );
                    errorSpan.children[0].innerHTML = 'La cantidad ingresada no puede ser mayor a las existencias del producto seleccionado'
                    refItem.hasError = true;
                    // console.log( refItem.hasError, refItem.error )
                } else if ( numberValue <= 0 ) {
                    errorSpan.classList.remove( 'hideSpan' );
                    target.classList.add( 'erroredtableinputnumber' );
                    errorSpan.children[0].innerHTML = 'La cantidad ingresada no puede ser menor o igual a 0'
                    refItem.hasError = true;
                } else {
                    refItem.hasError = false;
                    target.classList.remove( 'erroredtableinputnumber' );
                }
            } else {
                refItem.hasError = false;
                target.classList.remove( 'erroredtableinputnumber' );
            }
        },
        increaseQty(index) {
            this.selectedElementsToShow[ index ].cantidad = parseInt( this.selectedElementsToShow[ index ].cantidad ) + 1
            this.validateOutOfInput( index );
        },
        decreaseQty(index) {
            const decreaseResult = parseInt( this.selectedElementsToShow[ index ].cantidad ) - 1
            if( decreaseResult < 0 ) {
                this.selectedElementsToShow[ index ].cantidad = 0
            } else {
                this.selectedElementsToShow[ index ].cantidad = decreaseResult
            }
            this.validateOutOfInput( index );
        },
        validateOutOfInput(index){
            const refItem = this.selectedElementsToShow[ index ];
            const { data: { ItemCurrentQuantity }, type, id, cantidad } = refItem;
            const errorSpan = document.getElementById(  `errorspan${type}${id}` );
            const target = document.getElementById( `tableinputnumber${type}${id}` )

            // console.log(errorSpan.classList);
            ![...errorSpan.classList].includes( 'hideSpan' ) && errorSpan.classList.add( 'hideSpan' );
            refItem.error = '';

            // console.log('refItem ', refItem);
            const numberValue = parseInt( cantidad );

            if( numberValue > ItemCurrentQuantity ) {
                errorSpan.classList.remove( 'hideSpan' );
                target.classList.add( 'erroredtableinputnumber' );
                errorSpan.children[0].innerHTML = 'La cantidad ingresada no puede ser mayor a las existencias del producto seleccionado'
                refItem.hasError = true;
                target.focus();
                // console.log( refItem.hasError, refItem.error )
            } else if ( numberValue <= 0 ) {
                errorSpan.classList.remove( 'hideSpan' );
                target.classList.add( 'erroredtableinputnumber' );
                errorSpan.children[0].innerHTML = 'La cantidad ingresada no puede ser menor o igual a 0'
                refItem.hasError = true;
                target.focus();
            } else {
                refItem.hasError = false;
                target.classList.remove( 'erroredtableinputnumber' );
            }
        },
        lostFocus(index){
            const refItem = this.selectedElementsToShow[ index ];
            const { type, id } = refItem;
            const errorSpan = document.getElementById(  `errorspan${type}${id}` );
            ![...errorSpan.classList].includes( 'hideSpan' ) && errorSpan.classList.add( 'hideSpan' );
        },
        focus(e, index){
            const refItem = this.selectedElementsToShow[ index ];
            const { data: { ItemCurrentQuantity }, type, id } = refItem;
            const { target: { value } } = e;
            const numberValue = parseInt( value );

            const errorSpan = document.getElementById(  `errorspan${type}${id}` );
            if( [...errorSpan.classList].includes( 'hideSpan' ) && ItemCurrentQuantity < numberValue){
                errorSpan.classList.remove( 'hideSpan' );
            } 
        },
        async getAllInfo() {
            await this.getFamilies();
            this.getCategories();
            this.getSubs();
        },
        sortFunction(a, b) {
            if( a.name > b.name ) {
                return 1;
            }
            if( a.name < b.name ) {
                return -1;
            }
            return 0;
        },
        async getFamilies() {
            try {
                const response = await this.$API.itemtypes.getFamiliesHistory();
                const sortedResponse = [ ...response.sort( this.sortFunction ) ];
                this.families = [ ...sortedResponse ];
            } catch (error) {
                console.log( error );
            }
        },
        async getCategories() {
            try {
                const response = await this.$API.itemtypes.getCategoriesHistory();
                const sortedResponse = [ ...response.sort( this.sortFunction ) ];
                this.categories = [ ...sortedResponse ];
            } catch (error) {
                console.log(error);
            }
        },
        async getSubs() {
            try {
                const response = await this.$API.itemtypes.getSubsHistory();
                const sortedResponse = [ ...response.sort( this.sortFunction ) ];
                this.subcategories = [ ...sortedResponse];
            } catch (error) {
                console.log(error)  
            }
        },
        familyCount(id) {
            const productsLength = this.products.filter( ( {IdFamily} ) => {
                return IdFamily === id;
            } ).length

            const servicesLength = this.services.filter( ( {family} ) => {
                return family === id;
            } ).length

            return productsLength + servicesLength;
        },
        categoriesPerFamily(id) {
            return this.categories.filter( ( { id_family } ) => {
                return id_family === id;
            } ).length;
        },
        subcategoriesPerCategory(id) {
            return this.subcategories.filter( ( { id_cat } ) => {
                return id_cat === id;
            } ).length;
        },
        categoryCount(id) {
            const productsLength = this.products.filter( ( {IdCategory} ) => {
                return IdCategory === id;
            } ).length

            const servicesLength = this.services.filter( ( {category} ) => {
                return category === id;
            } ).length

            return productsLength + servicesLength;
        },
        subcategoryCount(id) {
            const productsLength = this.products.filter( ( {IdSubCategory} ) => {
                return IdSubCategory === id;
            } ).length

            const servicesLength = this.services.filter( ( {subcategory} ) => {
                return subcategory === id;
            } ).length

            return productsLength + servicesLength;
        },
        clickFamily(id, count) {
            const filteredCategories = [ ...this.categories.filter( ( { id_family } ) => {
                return id_family === id;
            } ) ];
            this.categoriesByFamily = filteredCategories;

            this.currentFilter.family = id;
            this.cleanPageFlags();
            count > 0 ? this.hasDetails = true : this.hasDetails = false;
            this.categoriesPage = true;
            this.loadElements();
        },
        clickCategory(id, count) {
            const filteredSubCategories = [ ...this.subcategories.filter( ( { id_cat } ) => {
                return id_cat === id;
            } ) ];
            this.subcategoriesByCategory = filteredSubCategories;

            this.currentFilter.category = id;
            this.cleanPageFlags();
            count > 0 ? this.hasDetails = true : this.hasDetails = false;
            this.subCategoriesPage = true;
            this.loadElements();
        },
        sendElement( index ){
            this.validateOutOfInput(index)
            const { data, type, cantidad, id } = this.selectedElementsToShow[ index ];

            if( !this.selectedElementsToShow[ index ].hasError ) {
                data.qty = cantidad;
                this.$emit( "addElement", { data, type } );
                this.cancelQuantityManagement( index )
            } else {
                const target = document.getElementById( `tableinputnumber${type}${id}` )
                this.addShakeClass(target);
            }
        },
        addShakeClass( target ) {
            target.classList.add('shake');
            setTimeout(
                () => {
                    target.classList.remove('shake');
                },
                500
            )
        },
        clickSubCategory( id ) {
            this.currentFilter.subcategory = id;
            this.showElements = true;
            this.loadElements();
        },
        cleanPageFlags(){
            this.familiesPage = false;
            this.categoriesPage = false;
            this.subCategoriesPage = false;
            this.hasDetails = true;
        },
        goToPreviousPage(){
            if( this.categoriesPage ) {
                this.cleanPageFlags();
                this.familiesPage = true;
                this.showElements = false;
                this.currentFilter.family = null;
                this.currentFilter.category = null
                this.loadElements();
            } else {
                this.cleanPageFlags();
                this.showElements = false;
                this.currentFilter.category = null
                this.currentFilter.subcategory = null;
                this.categoriesPage = true;
                this.loadElements();
            }
        }
    }
}

</script>
<style scoped>
    .btndisable {
        background-color: #41464c !important;
        color: white;
    }
    
    .tableinputnumber {
        width: 75px;
        height: 40px;
        border: 1px solid rgb(17, 0, 255);
        border-radius: 7px;
        font-size: 25px;
        text-align: right;
        padding-right: 7px;
        background-color: rgb(241, 240, 240);
    }

    .erroredtableinputnumber {
        border: 1px solid rgb(245, 62, 62) !important;
    }
    .hideSpan {
        display: none;
    }

    .errorSpan {
        margin-left: 5%;
        margin-right: 5%;
    }

    .divErrorSpan {
        width: 50%;
        margin: 0 auto;
        width: 500px;
        border-radius: 7px;
        font-weight: bold;
        color: white;
        margin-bottom: 15px;
        background-color: rgb(245, 62, 62);
    }

    .buttonIcon{
        border-radius: 50%;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 10px;
        box-shadow: 0 4px rgb(142, 142, 224);
        background-color: rgb(86, 86, 199);
    }

    .buttonIcon:active {
        background-color: rgb(49, 49, 175);
        box-shadow: 0 0px rgb(96, 96, 187);
        transform: translateY(4px);
    }

    .buttonIcon:hover {
        background-color: rgb(49, 49, 175);
    }

    .quantityManagement{
        text-align: center;
        padding-bottom: 1.5%;
        border-radius: 0px 0px 25px 25px;
        background-color: #949699;
    }

    .quantityManagementDownPanel {
        text-align: center;
    }

    .leftSideDownPanelButton{
        margin-right: 10px;
    }
    .downPanelButtons {
        border-radius: 50%;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 10px;
        box-shadow: 0 4px rgb(172, 172, 172);
        background-color: #00A178;
    }
    
    .downPanelButtons:active {
        box-shadow: 0 0px rgb(172, 172, 172);
        transform: translateY(4px);
    }
    .downPanelButtons:hover {
        background-color: #027559;
    }
    .elementsList {
        overflow-x: scroll;
        min-width: 900px;
    }

    .elementPanel {
        border-radius: 15px 15px 0px 0px;
    }

    .elementPanel:active {
        background-color: rgb(241, 240, 240);
    }

    .shake {
        animation: shake 500ms infinite;
    }

    @keyframes shake {
        0% { transform: translateX(0); }
        5% { transform: translateX(-4px); }
        15% { transform: translateX(4px); }
        25% { transform: translateX(-4px); }
        35% { transform: translateX(4px); }
        45% { transform: translateX(-4px); }
        55% { transform: translateX(4px); }
        65% { transform: translateX(-4px); }
        75% { transform: translateX(3px); }
        85% { transform: translateX(-3px); }
        100% { transform: translateX(0); }
    }

    .closeQuantityManagementPanel {
        text-align: right;
        height: 20px;
    }
</style>