import axios from "./axios";

export default {
  dashboardNumbers() {
    const options = {
      method: 'GET',
      url: 'dashboard/numbers',
      headers: { 'content-type': 'application/json' },
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },
  getLineData(businessType) {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'POST',
      url: 'dashboard/general',
      headers: { 'content-type': 'application/json' },
      data: { user: user, businessType }

    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },
  getTop() {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'POST',
      url: 'dashboard/customers',
      headers: { 'content-type': 'application/json' },
      data: { user: user }

    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },
  getProductsServices() {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'POST',
      url: 'dashboard/productservices',
      headers: { 'content-type': 'application/json' },
      data: { user: user }

    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },
  getProductsServicesByDate(body) {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'POST',
      url: 'dashboard/productservicesbydate',
      headers: { 'content-type': 'application/json' },
      data: { user: user, ...body }

    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },
  getTopCustomersRange(start, end) {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'POST',
      url: 'dashboard/customersrange',
      headers: { 'content-type': 'application/json' },
      data: { user, start: start, end: end }

    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },
  getWeeksCustom(start, end) {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'POST',
      url: 'dashboard/customweeks',
      headers: { 'content-type': 'application/json' },
      data: { user, start: start, end: end }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },
  getYearsCustom(start, end) {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'POST',
      url: 'dashboard/customyears',
      headers: { 'content-type': 'application/json' },
      data: { user, start: start, end: end }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },
  getYearsMonthsCustom(datesRange) {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'POST',
      url: 'dashboard/customyearsmonths',
      headers: { 'content-type': 'application/json' },
      data: { user, datesRange}
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },
  getYears() {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'GET',
      url: 'dashboard/getYears',
      headers: { 'content-type': 'application/json' },
      params: { user }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },
  getMonths(year) {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'GET',
      url: 'dashboard/getMonths',
      headers: { 'content-type': 'application/json' },
      params: { user, year }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },
  getProductsRange(start, end) {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'POST',
      url: 'dashboard/productsrange',
      headers: { 'content-type': 'application/json' },
      data: { user, start: start, end: end }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },
  getTopCustomersw() {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'POST',
      url: 'dashboard/customersw',
      headers: { 'content-type': 'application/json' },
      data: { user: user }

    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },
  getTopCustomersm() {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'POST',
      url: 'dashboard/customersm',
      headers: { 'content-type': 'application/json' },
      data: { user: user }

    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },
  getTopCustomersy() {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'POST',
      url: 'dashboard/customersy',
      headers: { 'content-type': 'application/json' },
      data: { user: user }

    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },
  getProducts() {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'POST',
      url: 'dashboard/products',
      headers: { 'content-type': 'application/json' },
      data: { user }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message)
        })
    })
  },

  getProductsw() {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'POST',
      url: 'dashboard/productsw',
      headers: { 'content-type': 'application/json' },
      data: { user }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },
  getProductsm() {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'POST',
      url: 'dashboard/productsm',
      headers: { 'content-type': 'application/json' },
      data: { user }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },
  getProductsy() {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'POST',
      url: 'dashboard/productsy',
      headers: { 'content-type': 'application/json' },
      data: { user }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },
  /*  CustomersDay() {
     let user = JSON.parse(localStorage.getItem('user'))
     const options = {
       method: 'POST',
       url: 'dashboard/customerspd',
       headers: { 'content-type': 'application/json' },
       data: { user: user }

     };
     return new Promise((resolve, reject) => {
       axios(options)
         .then(response => {
           resolve(response.data);
         })
         .catch(error => {
            reject(error.message);
         })
     })
   }, */
  CustomersMonth() {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'POST',
      url: 'dashboard/customerspm',
      headers: { 'content-type': 'application/json' },
      data: { user: user }

    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },
  LineGraph() {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'POST',
      url: 'dashboard/years',
      headers: { 'content-type': 'application/json' },
      data: { user: user }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },

  LineWeek(){
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'POST',
      url: 'dashboard/weeks',
      headers: { 'content-type': 'application/json' },
      data: { user: user }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },

  CustomersYear() {
    let user = JSON.parse(localStorage.getItem('user'))
    const options = {
      method: 'POST',
      url: 'dashboard/customerspy',
      headers: { 'content-type': 'application/json' },
      data: { user: user }

    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        })
    })
  },
  ProductsWarehouses() {
    const options = {
      method: 'POST',
      url: 'dashboard/productswarehouses',
      headers: { 'content-type': 'application/json' }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          if(response === undefined){
            reject('No hay productos registrados');
          }
          if(response.data.success){
            resolve(response.data.productsWarehouses);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error.message)
        })
    })
  },
  ProductsWarehousesChartOptions() {
    const options = {
      method: 'POST',
      url: 'dashboard/productswarehouses/options',
      headers: { 'content-type': 'application/json' }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          console.log(response);
          if(response === undefined){
            reject('No hay productos registrados');
          }
          if(response.data.success){
            
            resolve(response.data.productsWarehouses);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error.message)
        })
    })
  },
  SpecificProductWarehouses(ID_Item) {
    const options = {
      method: 'POST',
      url: 'dashboard/specificproductwarehouse',
      headers: { 'content-type': 'application/json' },
      data: { ID_Item }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
          console.log(response);
          if(response === undefined){
            reject('No hay productos registrados');
          }
          if(response.data.success){
            
            resolve(response.data.productsWarehouses);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error.message)
        })
    })
  },
}
