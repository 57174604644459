import axios from "./axios";

export default {
    createSeller(data) {
        const options = {
            method: 'POST',
            url: 'manageseller',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getAllSellers() {
        const options = {
            method: 'GET',
            url: 'manageseller',
            headers: {"content-type": "application/json"},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getAllSellersFiltered(params) {
        const options = {
            method: 'GET',
            url: 'manageseller/list/filtered',
            headers: {'content-type': 'application/json'},
            params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getSeller(id) {
        const options = {
            method: 'GET',
            url: `manageseller/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    updateSeller(id, data) {
        const options = {
            method: 'PUT',
            url: `manageseller/${id}`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deleteSeller(id) {
        const options = {
            method: 'DELETE',
            url: `manageseller/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
}
