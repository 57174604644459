var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"translate":"no"}},[(_vm.token)?[_c('drawer',{style:(!_vm.minimizeSidebar
            ? !_vm.drawerMini
              ? 'width: 192px;'
              : 'width:56px;'
            : 'max-width:192px'),attrs:{"drawer-mini":_vm.drawerMini,"drawerMini":_vm.drawerMini,"drawer-visibility":_vm.drawerVisibility},on:{"update:drawerVisibility":function($event){_vm.drawerVisibility=$event},"update:drawer-visibility":function($event){_vm.drawerVisibility=$event},"toggleDrawer":function($event){_vm.drawerMini = true},"chevron":_vm.changeIsShow,"showSidebarOptions":_vm.hideButton,"mouse-over":function($event){_vm.mouseIn = true},"mouse-out":function($event){_vm.mouseIn = false}}}),_c('v-main',{staticClass:"pr-3",style:(_vm.mainStyle)},[(_vm.isShow && !_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{class:{
            'fixed-display-m': _vm.drawerMini,
            'fixed-display-l': !_vm.drawerMini,
            'fixed-display-mobile': _vm.showMobileToggleButton,
           

          },style:(_vm.isSidebarOpen ? 'display: none;' : ''),attrs:{"color":"pink","fab":"","dark":"","small":"","fixed":"","elevation":"7"},on:{"click":function($event){return _vm.changeDrawerView()}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.drawerMini ? "mdi-chevron-right" : "mdi-chevron-left"))])],1):_vm._e(),(_vm.isMobile)?_c('v-app-bar',{attrs:{"fixed":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawerVisibility = !_vm.drawerVisibility}}})],1):_vm._e(),_c('router-view')],1)]:_c('router-view'),_c('block',{attrs:{"value":_vm.loading}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }