<template>
  <v-expansion-panel class="my-2">
    <v-expansion-panel-header class="py-2">
      <slot name="title">Título del panel</slot>
      <template #actions>
        <v-btn class="mr-5" @click.stop="onReloadPage" icon small>
          <v-icon color="primary">mdi-reload</v-icon>
        </v-btn>
        <v-btn @click.stop="onClosePage" icon small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <slot>Contenido del panel</slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "WorkspacePanel",
  props: ["win"],
  methods: {
    onClosePage() {
      this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid });
    },
    onReloadPage() {
      window.scrollTo(0, this.$store.getters.getScrollTop);
      this.$emit('reload')
    }
  },
};
</script>
