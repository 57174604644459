import axios from "./axios";

export default {
    /**
     * Get lab tests related to patient
     * @param {string} patientID
     * @returns {Promise}
     */

    getVaccines(params) {
        const options = {
            method: 'GET',
            url: `managevaccine`,
            headers: { 'content-type': 'application/json' },
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    addVaccine(data) {
   /*    console.log("asdd vacune data ",data); */
        const options = {
            method: 'POST',
            url: `managevaccine`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    addVaccinevet(data) {
      /*    console.log("asdd vacune data ",data); */
           const options = {
               method: 'POST',
               url: `managevaccine/vet`,
               headers: { 'content-type': 'application/json' },
               data: data
           };
           return new Promise((resolve, reject) => {
               axios(options)
                   .then(response => {
                       resolve(response.data);
                   })
                   .catch(error => {
                       reject(error.messageageageageageageage)
                   })
           })
       },
    deleteVaccine(id) {
        const options = {
            method: 'DELETE',
            url: `managevaccine/${id}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    addVaccinePlan(data) {
        const options = {
            method: 'POST',
            url: `managevaccine/plan`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    updateVaccinePlanHeader(data){
        const options = {
            method: 'PUT',
            url: `managevaccine`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    updateVaccinePlan(id, data) {
        const options = {
            method: 'PUT',
            url: `managevaccine/plan/${id}`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deletePlanRow(id) {
        const options = {
            method: 'DELETE',
            url: `managevaccine/plan/${id}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    showVaccineDetails(id) {
        const options = {
            method: 'GET',
            url: `managevaccine/plan/${id}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getViewsVaccine(data) {
        const options = {
            method: 'POST',
            url: `managevaccine/inventry`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    },
}
