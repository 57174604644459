import axios from "./axios";

export default {

    getMedicationPlans() {
        const options = {
            method: 'GET',
            url: `veterinaryMedications`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getNextvac(data) {
        const options = {
            method: 'GET',
            url: `veterinaryMedications/Nextimevac`,
            headers: { 'content-type': 'application/json' },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getNextvacFiltered(data) {
        const options = {
            method: 'GET',
            url: `veterinaryMedications/Nextimevac/filtered`,
            headers: { 'content-type': 'application/json' },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getMedicationPlansByBranch({ID_Business,ID_Branch}) {
        const options = {
            method: 'GET',
            url: `veterinaryMedications/ByBussnies?ID_Business=${ID_Business}&ID_Branch=${ID_Branch}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    showParametersByPlanID({id,ID_Business,ID_Branch}) {
        const options = {
            method: 'GET',
            url: `veterinaryMedications/${id}?ID_Business=${ID_Business}&ID_Branch=${ID_Branch}`,
            headers: { 'content-type': 'application/json' },
            
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    addVeterinaryMedicationParameter(data) {
        const options = {
            method: 'POST',
            url: `veterinaryMedications`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    updateVeterinaryMedicationPerameter(plan, data) {
        const options = {
            method: 'PUT',
            url: `veterinaryMedications/`,
            headers: { 'content-type': 'application/json' },
            data: {
                headers: plan,
                details: data
            },

        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deleteVeterinaryMedicationPerameter(id) {
        const options = {
            method: 'DELETE',
            url: `veterinaryMedications/${id}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deleteVeterinaryMedicationParameterBulk(data) {
        const options = {
            method: 'POST',
            url: `veterinaryMedications/bulkDelete`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getVeterinaryPerameterOfPlans(data) {
        const options = {
            method: 'POST',
            url: `veterinaryMedications/bulk/Manageperameters`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getVeterinaryMedicationHeader(id) {
        const options = {
            method: 'GET',
            url: `veterinaryMedications/veterinary/headers/${id}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getVeterinaryMedicationHeaderHistory(id) {
        const options = {
            method: 'GET',
            url: `veterinaryMedications/veterinary/headers/history/${id}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    filterVeterinaryMedicationHeader({ID_Patient, filter}) {
        console.log(ID_Patient, filter)
        const options = {
            method: 'GET',
            url: `veterinaryMedications/veterinary/headersFilter`,
            headers: { 'content-type': 'application/json' },
            params: {
                id: ID_Patient,
                filter
            }
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    
    getVeterinaryMedicationProducts(params) {
        const options = {
            method: 'GET',
            url: `veterinaryMedications/products`,
            headers: { 'content-type': 'application/json' },
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getVeterinaryMedicationProductsHistory(params) {
        const options = {
            method: 'GET',
            url: `veterinaryMedications/products/list/history`,
            headers: { 'content-type': 'application/json' },
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getVeterinaryMedicationProductsFiltered(params) {
        const options = {
            method: 'GET',
            url: `veterinaryMedications/products/list/filtered`,
            headers: { 'content-type': 'application/json' },
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

}