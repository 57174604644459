<template>
    <v-card flat>
        <v-card-text class="pa-1">
            <v-card class="background border-box">
              <GeneralFilter :search="search" :filterEndpoint="$API.products.getINsHistoryProductFiltered" @filtered="filterHandler" @emptyFilter="getHistory">
                <DxDataGrid :data-source="products" :scrolling="{useNative:true}">
                    <DxColumn :width="columnWidth" data-field="ProviderName" caption="Proveedor"></DxColumn>
                    <!--DxColumn :width="columnWidth" data-field="UnitsPackaging" caption="Embalaje Unitario"></DxColumn-->
                    <DxColumn :width="columnWidth" data-field="UPCUnitarioFormatted" caption="U.P.C. Unitario"></DxColumn>
                    <DxColumn :width="columnWidth" data-field="UPCIVAFormatted" caption="U.P.C. (IVA)"></DxColumn>
                    <DxColumn :width="columnWidth" data-field="PMPCFormatted" caption="P.M.P.C"></DxColumn>
                    <DxColumn :width="columnWidth" data-field="PMPCIVAFormatted" caption="P.M.P.C (IVA)"></DxColumn>
                    <DxColumn :width="columnWidth" data-field="InvoicePurchaseOrder_Date" caption="Fecha Última Compra"></DxColumn>
                </DxDataGrid>
              </GeneralFilter>
            </v-card>
        </v-card-text>
    </v-card>
</template>

<script>
    import GeneralFilter from "@/components/GeneralFilter";
    import {DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
    export default {
        name: "ProductIns",
        props: ['data'],
        components: { DxDataGrid, DxColumn, GeneralFilter },
        data () {
            return {
                search: {
                    filter: null
                },
                products: null,
                isFiltered: false,
                productsListFiltered: [],
            }
        },
        mounted() {
            this.getHistory();

        },
        methods: {
            getHistory() {
                this.search.itemid = this.data.ID_Item;
                this.$API.products.getINsHistoryProductHistory({itemid: this.data.ID_Item})
                    .then(response => {
                        this.products = response
                    })
            },
            filterHandler(response){
                this.products = response
            }
        },
        computed:{
            columnWidth(){
                return this.$vuetify.breakpoint.smAndDown ? '150px' : 'auto !important'
            }
        }

    }
</script>

<style scoped>
.border-box {
    border-radius: 15px !important;
  }
  .background {
      background: white !important;
      color: white;
  }
</style>
