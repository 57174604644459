<template>
    <div>
        <block v-if="loading"></block>
        <v-card flat>
      <v-card-text style="z-index: 1;">


        <template v-if="type=== 'medicalclinic'">
            <user-profile-lab :patient="data" :patientinfo="data" :useData="useData"></user-profile-lab>
        </template>
        <template v-else>
            <user-profile :patientinfo="patientinfo" :data="data" :useData="useData" />
        </template>
           
                   
                   
                                

                   
        <v-row style="margin-top: 1rem;">

           

            <v-col cols="12" class="box" style="margin-top: 1rem;">
                <v-form v-model="valid" class="position-relative">
                    <block v-if="loading"></block>
                 
                            <div >
                            <v-row  class="ml-2 mr-2" style="margin-top: .3rem;">
                                <v-col cols="12" class="tabsNh">
                                    
                                        <v-col cols="12" md="12"  style="display: flex; justify-content: flex-end;">
                                          
                                               <!--  <v-btn @click.prevent="">
                                                <v-icon>
                                                    mdi-printer
                                                </v-icon>
                                            
                                                </v-btn> -->
                                                <v-btn small class="btnblue mr-1" @click="showMedicalData = !showMedicalData" >
                                                    <v-icon>
                                                        mdi-magnify
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn small class="btnclean mr-1" @click.prevent.stop="resetData()">
                                                                 <v-icon>
                                                                    mdi-broom
                                                                 </v-icon>
                                                             </v-btn>
                                                <v-btn small v-if="selectedvalues && 
                                                (rolesStatus.findIndex((role) => role.RoleName == 'admin') > -1 ||
            rolesStatus.findIndex((role) => role.RoleName == 'root') > -1 || rolesStatus.findIndex((role) => role.ID_Role == 21) > -1)
                                                " class="btnsave" @click.prevent.stop="saveMedicalHistory()">
                                                    <v-icon>
                                                    mdi-content-save
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn small v-if="medicalhistory.ID_MH &&
                                                (rolesStatus.findIndex((role) => role.RoleName == 'admin') > -1 ||
            rolesStatus.findIndex((role) => role.RoleName == 'root') > -1 || rolesStatus.findIndex((role) => role.ID_Role == 22) > -1)
                                                " class="btnsave" @click.prevent.stop="updateMedicalHistory()">
                                                    <v-icon>
                                                    mdi-content-save
                                                    </v-icon>
                                                </v-btn>
                                            
                                </v-col>
                                  
                                </v-col>

                                <v-col cols="12" md="3" sm="12" style="margin-top: 1rem;">
                                 <div style="width: 265px !important; display: flex;">
                                        <date-picker label="Fecha"
                                                     @dateSelected="(param) => {
                                                        date = param
                                                        checkhistoryDatetoday(param)
                                                        }"
                                                     v-model="date"
                                                     ></date-picker>
                                    
                                                    
                                                    </div>

                                    </v-col>
                                    <v-col cols="12" md="8" sm="12">

                                                &nbsp;
                                               
                                     </v-col>
                                     
                                     <v-col cols="12" md="1" style="display: flex;justify-content: flex-end;margin-top: .5rem">
                                    <v-btn
                                    @click="showComments = !showComments"
                                    :class="medicalhistory.Comment ? 'btnclean' : 'btnblack'"
                                    >
                                    Comentarios
                                    </v-btn>
                  </v-col>
                            </v-row>



                            <br/>
                            <v-row  class="ml-2 mr-2" style="margin-top: .3rem;">
                                <!-- <v-col cols="12" md="12" sm="12" >
                                            <h2>DIAGNÓSTICOS</h2>
                            
                                </v-col> -->
                         
                                 
                                    <v-col cols="12" md="6" >
                                        <label class="mb-3 d-inline-block label-forms ">Resumen de historia y examen físico
                                        
                                        </label>
                                        <span class="ql-editor150">
                                        <quill-editor v-model="medicalhistory.PhysicalHistorySummary" :options="quillOptions"
                                        @change="onEditorChange($event)"></quill-editor>
                                        </span>
                                        <br/>
                                    </v-col>
                                 <v-col cols="12" md="6">
                                     
                                        <label  class="mb-3 d-inline-block label-forms ">Diagnóstico Presuntivo
                                            
                                        </label>
                                        <span class="ql-editor150">
                                        <quill-editor  v-model="medicalhistory.PresumptiveDiagnosis" :options="quillOptions"
                                            @change="onEditorChange($event)"></quill-editor>
                                            </span>
                                            <br/>
                                 </v-col>
                                  
                                    <v-col cols="12" md="6">
                                        <label  class="mb-3 d-inline-block label-forms">Diagnóstico Definitivo
                                        
                                        </label>
                                        <span class="ql-editor150">
                                        <quill-editor  v-model="medicalhistory.FinalDiagnosis" :options="quillOptions"
                                            @change="onEditorChange($event)"></quill-editor>
                                            </span>
                                            <br/>
                                        
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <label  @click="showTreat = !showTreat" class="mb-3 d-inline-block label-forms" >Tratamiento
                                        
                                        </label>
                                        <span class="ql-editor150">
                                            <quill-editor v-model="medicalhistory.Treatment" :options="quillOptions"
                                                @change="onEditorChange($event)"></quill-editor>
                                        </span>
                                            <br/>
                                    </v-col>
                         
                                  <!--   <v-col cols="12" md="12">
                                        <h2> Ayuda Diagnóstica</h2>
                                        <br/>
                                    </v-col>
                                   
                        
                              
                                <v-col cols="12" md="6">
                                    <label   @click="showMedH = !showMedH" class="d-inline-block label-forms">Registros de ayudas médicas:
                                    
                                    
                                    </label>
                                    <DxDataGrid class="isScrolledByGeneralFilter"
                                    :show-borders="true"
                                    :data-source="medicalaids" key-expr="">
                                        <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                                        <DxColumn data-field="Type" caption="Ayuda médica"></DxColumn>
                                        <DxColumn data-field="Date" caption="Fecha"></DxColumn>
                                        <DxColumn data-field="UserName" caption="Registrado por"></DxColumn>
                                        <template #show-template="{data}">
                                            <div>
                                                <a href="#" @click.prevent.stop="showLabsHelp(data.data)">
                                                    <v-icon color="primary">mdi-eye</v-icon>
                                                </a>
                                            </div>
                                        </template>
                                    </DxDataGrid>
                                    <br/>
                                    <br />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <label   @click="showRep = !showRep" class="d-inline-block label-forms">Registros de Reproducciones:
                                    
                                    </label>
                                    <DxDataGrid class="isScrolledByGeneralFilter" :show-borders="true"
                                    :data-source="reproductions" key-expr="">
                                        <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                                        <DxColumn data-field="ID_Reproduction" caption="ID"></DxColumn>
                                        <DxColumn data-field="ReproductionDate" caption="Fecha"></DxColumn>
                                        <DxColumn data-field="UserName" caption="Registrado por"></DxColumn>
                                        <template #show-template="{data}">
                                            <div>
                                                <a href="#" @click.prevent.stop="showreproductionData(data.data)">
                                                    <v-icon color="primary">mdi-eye</v-icon>
                                                </a>
                                            </div>
                                        </template>
                                    </DxDataGrid>
                                    <br />
                                    <br />
                                </v-col>
                                <v-col cols="12" md="6">
                                    
                                    <label   @click="showSurg = !showSurg" class="d-inline-block label-forms">Registros de Cirugías:
                                    
                                    </label>
                                    <DxDataGrid class="isScrolledByGeneralFilter" :show-borders="true"
                                    
                                    :data-source="surgerys" key-expr="">
                                        <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                                        <DxColumn data-field="ID_Surgery" caption="ID"></DxColumn>
                                        <DxColumn data-field="SurgeryDate" caption="Fecha"></DxColumn>
                                        <DxColumn data-field="UserName" caption="Registrado por"></DxColumn>
                                        <template #show-template="{data}">
                                            <div>
                                                <a href="#" @click.prevent.stop="showMedicalHelp(data.data)">
                                                    <v-icon color="primary">mdi-eye</v-icon>
                                                </a>
                                            </div>
                                        </template>
                                    </DxDataGrid>
                                    <br />
                                    <br />
                                </v-col>
                                <v-col>
                                    <label   @click="showImg = !showImg" class="d-inline-block label-forms">Registros de Imágenes:
                                       
                                    </label>
                                    <DxDataGrid class="isScrolledByGeneralFilter" :show-borders="true"
                                   
                                    :data-source="images" key-expr="">
                                        <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                                        <DxColumn data-field="ID_Images" caption="ID"></DxColumn>
                                        <DxColumn data-field="ImagesDate" caption="Fecha"></DxColumn>
                                        <DxColumn data-field="UserName" caption="Registrado por"></DxColumn>
                                        <template #show-template="{data}">
                                            <div>
                                                <a href="#" @click.prevent.stop="showImageHelp(data.data)">
                                                    <v-icon color="primary">mdi-eye</v-icon>
                                                </a>
                                            </div>
                                        </template>
                                    </DxDataGrid>
                                    <br />
                                    <br />
                                </v-col>
                                <v-col cols="12" md="12">
                                    <h2>Prescripciones</h2>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <DxDataGrid class="isScrolledByGeneralFilter" :show-borders="true"
                                :data-source="prescriptions" key-expr="">
                                    <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                                    <DxColumn data-field="ID_PVP" caption="ID"></DxColumn>
                                    <DxColumn data-field="PrescriptionDate" caption="Fecha"></DxColumn>
                                    <DxColumn data-field="UserName" caption="Registrado Por"></DxColumn>
                                    <template #show-template="{data}">
                                        <div>
                                            <a href="#" @click.prevent.stop="data">
                                                <v-icon color="primary">mdi-eye</v-icon>
                                            </a>
                                        </div>
                                    </template>
                                </DxDataGrid>
                                </v-col>
                             -->
                            </v-row>

                           

                              
                                
                               
                        
                            <v-row style="margin-bottom: 1.2rem; margin-top: .6rem;">
                                
                            </v-row>

                        </div>
                            <!-- <div class="tabs" @click="disabledOne">
                                <v-icon v-if="disabled1==0" color="white" left>
                                    mdi-plus
                                </v-icon>
                                <v-icon v-if="disabled1==1" color="white" left>
                                    mdi-minus
                                </v-icon>
                                Diagnóstico
                            </div>
 -->

 <!--
     <br/>
    <div class="tabs" @click="disabledTwo">
                                <v-icon v-if="disabled2==0" color="white" left>
                                    mdi-plus
                                </v-icon>
                                <v-icon v-if="disabled2==1" color="white" left>
                                   mdi-minus
                                </v-icon>
                                Ayudas Diagnósticas
                            </div> -->



                            <!-- <br/>
                            <div class="tabs" @click="disabledThree">
                                <v-icon v-if="disabled3==0" color="white" left>
                                    mdi-plus
                                </v-icon>
                                <v-icon v-if="disabled3==1" color="white" left>
                                    mdi-minus
                                </v-icon>
                                Prescripciones
                            </div> -->



                     
                </v-form>
            </v-col>
        </v-row>
        <br/>
        <hr/>
        <br/>


        <v-dialog v-model="dialog" max-width="350">
            <v-card>
                <v-card-title class="text-h6">
                    ¿Desea eliminar el registro?
                </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="dialog = false">
                        No
                    </v-btn>
                    <v-btn color="green darken-1" text @click="deleteMedicalHistory(temp); dialog = false; temp = null">
                        Si
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    ></alerts>
      </v-card-text>
      <v-dialog  v-model="showMedicalData"
      scrollable :width="'90%'"
      >
          <v-card class="pa-4">
        <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
            <v-btn small class="btndelete" @click.prevent="showMedicalData = false" >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <GeneralFilter  class="background border-box" entity="cliente" :filterEndpoint="$API.medicalhistory.filterloadMedicalHistory" :search="searchHistory" @emptyFilter="loadHistoryMedical" @filtered="filterHandler">

            <!-- <DxDataGrid 
                class="isScrolledByGeneralFilter" 
                :show-borders="true"
                :data-source="medicalhistorys" 
                key-expr=""
                @exporting="onExporting"
            >
                <DxExport :enabled="true" />
                <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                <DxColumn data-field="MedicalHistoryDate" caption="Fecha"></DxColumn>
                <DxColumn data-field="UserName" caption="Atendido Por"></DxColumn> -->
            
            <DxDataGrid 
                class="isScrolledByGeneralFilter" 
                :show-borders="true" 
                :data-source="medicalhistorys"
                key-expr="" 
                @exporting="onExporting" 
                :column-auto-width="true"
                style="width: fit-content;"
            >
                <DxExport :enabled="true" />
                <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                <!--  <DxColumn data-field="ID_MH" caption="Id"></DxColumn> -->
                <DxColumn data-field="MedicalHistoryDate" caption="Fecha" :min-width="100"></DxColumn>
                <DxColumn data-field="FullName" caption="Atendido por" :min-width="200"></DxColumn>
                <DxColumn data-field="PhysicalHistorySummaryTable" caption="Resumen de historia y examen físico" :min-width="300" cssClass="left-align"></DxColumn>
                <DxColumn data-field="PresumptiveDiagnosisTable" caption="Diagnóstico Presuntivo" :min-width="300" cssClass="left-align"></DxColumn>
                <DxColumn data-field="FinalDiagnosisTable" caption="Diagnóstico Definitivo" :min-width="300" cssClass="left-align"></DxColumn>
                <DxColumn data-field="TreatmentTable" caption="Tratamiento" :min-width="300" cssClass="left-align"></DxColumn>

                <DxColumn :width="80" cell-template="delete-template" caption="" v-if="rolesStatus.findIndex((role) => role.RoleName == 'admin') > -1 ||rolesStatus.findIndex((role) => role.RoleName == 'root') > -1 || rolesStatus.findIndex((role) => role.ID_Role == 23) > -1">
                </DxColumn>
                <template #show-template="{data}">
                    <div>
                        <a href="#" @click.prevent.stop="showMedicalHistory(data.data); selectedvalues= false; showMedicalData =false">
                            <v-icon color="primary">mdi-eye</v-icon>
                        </a>
                    </div>
                </template>
                <template #delete-template="{data}">
                    <div>
                        <a href="" class="mx-2" @click.prevent.stop="confirmdelete( data.data); showMedicalData =false">
                            <v-icon color="error">mdi-delete</v-icon>
                        </a>
                    </div>
                </template>                    
            </DxDataGrid>
            </GeneralFilter>
          </v-card>
      </v-dialog>
    </v-card>
    <v-dialog
      v-model="showComments"
      scrollable :max-width="$vuetify.breakpoint.smAndDown ? '90%':'60%'"
      >
      <v-card class="pa-4">
        <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
            <v-btn small class="btndelete" @click.prevent="showComments = false" >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        <v-col cols="12" md="12" style="">
                          <quill-editor v-model="medicalhistory.Comment" :options="quillOptions"></quill-editor>
                        </v-col>
      </v-card>
    </v-dialog>
    </div>
</template>

<script>
    import Vue from "vue";
    import Block from "@/components/Block";
    import { DxDataGrid, DxColumn, DxExport } from 'devextreme-vue/data-grid';
    import DatePicker from "../../../components/DatePicker";
    import Alerts from "@/components/Alerts";
    import { mapState } from "vuex";
    import { printToExcel } from "@/helpers/printToexcel";
    import GeneralFilter from  "@/components/GeneralFilter";
    import UserProfile from "@/components/Displays/UserProfileDisplay.vue";
    import UserProfileLab from "@/components/Displays/UserProfileDisplayLab.vue";


    //import VeterinaryVaccines from './VeterinaryVaccines';
    //import VeterinaryMedications from './VeterinaryMedications';
    //import VeterinaryLaboratoryTest from './VeterinaryLaboratoryTest';
    //import VeterinaryReproduction from './VeterinaryReproduction';
    //import VeterinarySurgery from './VeterinarySurgery';
    //import VeterinaryImages from './VeterinaryImages';
    //import VeterinaryPrescription from './VeterinaryPrescription';
    export default {
        name: "MedicalMedicalH",
        components: { DxDataGrid, DxColumn,  DatePicker, Block, Alerts,DxExport,
            GeneralFilter, UserProfile, UserProfileLab
            //VeterinaryVaccines,
            //VeterinaryMedications,
            //VeterinaryLaboratoryTest,
            //VeterinaryReproduction,
            //VeterinarySurgery,
            //VeterinaryImages,
            //VeterinaryPrescription
            },
        props: ['data', 'win', 'type'],
        data: () => ({
            alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
            datatodelete: null,
            dialog: false,
            valid: false,
            url: null,
            showComments: false,
            date: null,
            patientinfo : [],
            medicalhistorys : {},
            medicalhistory : {},
            loading: false,
            disabled1: 0,
            disabled2: 1,
            disabled3: 1,
            showFisicH: false,
            showDiagP: false,
            showDiagD: false,
            showTreat: false,
            showMedH: false,
            showRep: false,
            showSurg: false,
            showImg: false,
            searchHistory : {},
            medicalaids: [],
            reproductions: [],
            surgerys: [],
            prescriptions: [],
            images: [],
            selectedvalues: true,
            tab: 1,
            showMedicalData: false,
            useData: false,
            quillOptions: {
        modules: {
          toolbar: {
            container:
        [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'align': [] }],

        ['clean'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'formula': '' }]
         ],
            handlers: {}
          }
        },
        theme: 'snow'
      },
        }),

        computed: {
            ...mapState(
                "rolesUser",
                {
                    rolesStatus: (state) => state.roles,
                })
        },
        mounted() {

                        if (this.data) {
                Object.keys(this.data).map(el => {
                    Vue.set(this.patientinfo, el.toLowerCase(), this.data[el]);
                });

                this.url = this.$store.getters.getBackendUrl + '/public' + this.data.ProfilePicPath;
                this.useData = JSON.parse(localStorage.getItem("user"))
            }

            this.medicalhistory.ID_Patient = this.patientinfo.id_patient
            this.searchHistory.patientId = this.patientinfo.id_patient
            this.loadHistoryMedical()
            this.$store.dispatch('rolesUser/getAllRoles')
        },
        watch: {
            'date': {
                handler: function(){
                    this.searchHistory.date = this.date
                    this.medicalaids = []
                    /* this.getMedicalAids()
                   
                    this.getImagesHistory()
                    this.getSurgeryHistory() */
                },
                immediate: true
            },
            // medicalhistorys: {
            //     deep: true,
            //     inmediate: true,
            //     handler(newVal) {
            //         for (const item of newVal) {
            //             Object.keys(item).forEach(key =>{
            //             if(typeof(item[key]) === 'string') {
            //                 const value = item[key].replace(/<[^>]+>/g, ' ');
            //                 item[key] = value;
            //             }
            //             if(item[key] == 'undefined') item[key] = '';
            //             })
            //         }
            //         newVal = newVal.sort((a, b) => {
            //             const [aDay, aMonth, aYear] = a.MedicalHistoryDate.split('/');
            //             const aDate = new Date(aYear, aMonth - 1, aDay);
            //             const [bDay, bMonth, bYear] = b.MedicalHistoryDate.split('/');
            //             const bDate = new Date(bYear, bMonth - 1, bDay);
            //             return bDate - aDate;
            //         });
            //         return newVal;
            //     }
            // },
        },
        methods: {
            onExporting(e){
      printToExcel(e, 'Historial Medico')
    },
            checkhistoryDatetoday(date){
                let datetoday = false;
                this.medicalhistorys.forEach(el=>{
                    if(el.MedicalHistoryDate == date){
                        this.showMedicalHistory(el)
                    datetoday = true;
                }
            })
            if(!datetoday) {
                this.resetData()
                this.date = date;
            }


            },
             Preview_image() {
                this.url = URL.createObjectURL(this.image)
            },

            disabledOne(){
                this.disabled1 = (this.disabled1 + 1) % 2
            },
            disabledTwo(){
                this.disabled2 = (this.disabled2 + 1) % 2
            },
            disabledThree(){
                this.disabled3 = (this.disabled3 + 1) % 2
            },

            showMedicalHistory(data){
                
                this.loading = true


                if(data.FinalDiagnosis == "<p>undefined</p>"){
                    data.FinalDiagnosis = "<p></p>"
                }
                if(data.PresumptiveDiagnosis == "<p>undefined</p>"){
                    data.PresumptiveDiagnosis = "<p></p>"
                }
                if(data.Treatment == "<p>undefined</p>"){
                    data.Treatment = "<p></p>"
                }

               /*  data.FinalDiagnosis
                data.Treatment */
                this.medicalhistory = data //
                this.date = this.medicalhistory.MedicalHistoryDate
                this.loading = false
                this.selectedvalues = false
            },
            filterHandler(response) {
                console.log(response)
               this.medicalhistorys = response
            },
            loadHistoryMedical(){
                this.loading = true
                this.$API.medicalhistory.loadMedicalHistory(this.searchHistory)
                    .then((data) => {
                        this.medicalhistorys = data

                       let datetoday = this.moment().format("DD/MM/YYYY")
                       data.forEach(element => {

                            if(element.MedicalHistoryDate == datetoday){
                                this.showMedicalHistory(element)
                                this.selectedvalues = false
                            }
                        });

                        this.loading = false
                    }).catch(() =>{

                        this.loading = false
                    })
            },

            saveMedicalHistory(){

                let datetoday = false;
                this.loading = true



                this.medicalhistorys.forEach(element => {
                    if(element.MedicalHistoryDate == this.date){
                       this.showAlert("danger","error", "Ya existe un registro para la fecha seleccionada")

                        datetoday = true;
                    }
                });
                if(!datetoday){
                this.medicalhistory.MedicalHistoryDate = this.date
                this.medicalhistory.ID_Patient = this.patientinfo.id_patient;
                this.medicalhistory.ID_User_Insert = JSON.parse(localStorage.getItem("user")).id;

               this.$API.medicalhistory.saveMedicalHistory(this.medicalhistory)
                    .then(() => {
                        this.showAlert("success", "Exito", "Se guardo el registro correctamente")
                        this.resetData()
                        this.loadHistoryMedical()
                        this.loading = false
                    }).catch((data) => {
                        this.showAlert("danger", "Error", data.message)
                        this.loading = false
                    })
              }else{
                this.loading = false
              }



            },

            updateMedicalHistory(){
                this.loading = true
                this.medicalhistory.MedicalHistoryDate = this.date
                this.medicalhistory.ID_User_Update = JSON.parse(localStorage.getItem("user")).id;
                this.medicalhistory.ID_Patient = this.patientinfo.id_patient;
                this.$API.medicalhistory.updateMedicalHistory(this.medicalhistory)
                    .then(() => {
                        this.loading = true
                        this.showAlert("success", "Exito", "Se actualizo el registro correctamente")
                        this.loadHistoryMedical()
                    }).catch((data) => {
                        console.log(data)
                        this.showAlert("danger", "Error", "Ocurrio un error, por favor contacte al administrador.")
                        this.loading = false
                    })
            },

            deleteMedicalHistory(data){
                this.loading = true
                this.$API.medicalhistory.deleteMedicalHistory(data)
                    .then(() => {
                        this.loading = true
                        this.showAlert("success", "Exito", "Se elimino el registro correctamente")
                        this.resetData()
                        this.loadHistoryMedical()
                    }).catch((data) => {
                        this.showAlert("danger", "Error", data.message)
                        this.loading = false
                    })
            },

            getMedicalAids(){
                this.$API.medicalhistory.getVaccinesHistory(this.searchHistory)
                .then((response) => {
                    response.forEach(element => this.medicalaids.push({...element, type:"Vacuna"}));

                }).catch(() => {
                  //  this.showAlert("danger", "Error", "Ocurrio un error, por favor contacte al administrador.")
                })

                this.$API.veterinarymedicalhistory.getMedicationHistory(this.searchHistory)
                .then((response) => {
                    response.forEach(element => this.medicalaids.push({...element, type:"Medicamento"}));
                }).catch(() => {
                    //this.showAlert("danger", "Error", "Ocurrio un error, por favor contacte al administrador.")
                })

                this.$API.medicalhistory.getLaboratoryTestHistory(this.searchHistory)
                .then((response) => {
                    response.forEach(element => this.medicalaids.push(element));
                }).catch(() => {
                  // this.showAlert("danger", "Error", "Ocurrio un error, por favor contacte al administrador.")
                })
            },

           

            getPrescriptionHistory(){
                this.$API.medicalhistory.getPrescriptionHistory(this.searchHistory)
                .then((response) => {
                    this.prescriptions = response

                }).catch(() => {
                   // this.showAlert("danger", "Error", "Ocurrio un error, por favor contacte al administrador.")
                })
            },

            getImagesHistory(){
                this.$API.medicalhistory.getImagesHistory(this.searchHistory)
                .then((response) => {
                    this.images = response

                }).catch(() => {
                  //  this.showAlert("danger", "Error", "Ocurrio un error, por favor contacte al administrador.")
                })
            },

            getSurgeryHistory(){
                this.$API.medicalhistory.getSurgeryHistory(this.searchHistory)
                .then((response) => {
                    this.surgerys = response

                }).catch(() => {
                  //  this.showAlert("danger", "Error", "Ocurrio un error, por favor contacte al administrador.")
                })
            },

            resetData(){
                this.medicalhistory = {}
                this.date = this.moment().format("DD/MM/YYYY")
                this.selectedvalues = true;
            },
            closeAlert() {
            this.alert.show = false;
                if(this.alert.options == "deletedate"){
                    this.datatodelete = null;
                }
            },
            acceptAlert() {
            this.alert.show = false;

            if (this.alert.options == "deletedate") {
               this.deleteMedicalHistory(this.datatodelete)
            }
            },
            showAlert(type, header, body, options = null) {
            type = type == null ? "danger" : type;
            this.alert.type = type;
            this.alert.header = header;
            this.alert.body = body;
            this.alert.show = true;
            this.alert.options = options != null ? options : null;
            },
            confirmdelete(data){
                this.showAlert("question", "Eliminar", "¿Está seguro que desea eliminar el registro?", "deletedate");
                this.datatodelete =data;
            },
            showreproductionData(data){
                //console.log(data)
                this.$store.commit('vetPatientData/setDatereproduction',data.ReproductionDate);

                this.$store.commit('vetPatientData/changeView', 'tab-7');
                this.$store.commit('vetPatientData/setReloaders','VeterinaryReproduction');
            },
            showMedicalHelp(data){

                this.$store.commit('vetPatientData/setDateSurgery',data.SurgeryDate);
                this.$store.commit('vetPatientData/changeView', 'tab-8');
                this.$store.commit('vetPatientData/setReloaders','VeterinarySurgery');
            },
            showImageHelp(data){
                console.log(data)
                this.$store.commit('vetPatientData/setDateImage',data.ImagesDate);
                this.$store.commit('vetPatientData/changeView', 'tab-9');
                this.$store.commit('vetPatientData/setReloaders','VeterinaryImages');
            },
            showLabsHelp(data){
                if(data.type== "Medicamento"){
                    this.$store.commit('vetPatientData/setDateLabs',data.Date);
                    this.$store.commit('vetPatientData/changeView', 'tab-5');
                    this.$store.commit('vetPatientData/setReloaders','VeterinaryMedications');
                }
                else if(data.type== "Vacuna"){
                    this.$store.commit('vetPatientData/setDateLabs',data.Date);
                    this.$store.commit('vetPatientData/changeView', 'tab-4');
                    this.$store.commit('vetPatientData/setReloaders','VeterinaryVaccines');
                }

                console.log(data)
            },

            onEditorChange(){

            },

            /*onEditorChange(e) {
                //this.medicalhistory.comment = e.html;
            },*/
        }
    }
</script>

<style scoped>

.tabsB{
    width: 100%;
        padding: 10px;
        background-color: #1976d2;
        color: white;
 border-radius: 15px;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    }
.tabsB:hover{
   
    cursor: pointer;
}

.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}
.btnblue {
    background-color: #1976d2 !important;
    color: white;
}

    .btnclean{
    background-color: #F29D35 !important;
    color: white;
    }

    .btnsave{
    background-color: #00A178 !important;
    color: white;
    }

    .tabs{
        width: 100%;
        padding: 10px;
        background-color: rgba(60, 60, 60, 1);
        color: white;
    }

    .text-white{
        color: white !important;
    }
    
    .box{
    border-style: solid;
    border-color: black;
    border-width: 1px;
    background-color: white;
    
    border-radius: 15px;
   

}
.label-forms {

  border-radius: 15px;
 
}

.tabs {

  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white;
}
.btnblack{
    background-color: rgba(60, 60, 60, 1) !important;
    color: white !important;

  }

.tabsNh{
    width: 100%;
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;
    
}


    .infopatient{
        margin-top: 5%;
    }
</style>
