<template>
  <v-card color="white" class="pa-3" flat tile>
    <v-card-title>
      <!-- toolbar options -->
      <v-row justify="space-between" class="mb-1">
        <v-col cols="10" sm="6">
          <v-btn @click="onToday" color="grey" outlined>Hoy</v-btn>
          <v-btn @click="onPrev" class="mx-1" color="grey" icon>
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn @click="onNext" class="mx-1" color="grey" icon>
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <!-- date picker -->
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="text-h6"
                color="grey"
                @click="menu = true"
                v-bind="attrs"
                v-on="on"
                outlined
                >{{ selectedDate.format("MMMM YYYY") }}</v-btn
              >
            </template>
            <v-date-picker
              :value="date"
              no-title
              scrollable
              @change="onChangeDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2" sm="6" md="4" lg="3" class="inline">
          <v-select
            v-if="!hideControls"
            class="mr-2"
            :items="controls"
            v-model="typeCalendar"
            color="grey"
            outlined
            dense
            hide-details
          ></v-select>
          <slot name="action">
            <v-btn v-if="!hideAction" color="primary">Action</v-btn>
          </slot>
        </v-col>
      </v-row>
    </v-card-title>

    <!-- calendar day week -->
    <v-simple-table
      v-if="['week', 'day'].includes(typeCalendar)"
      class="table-border"
      :height="height"
      fixed-header
    >
      <template #default>
        <thead>
          <tr>
            <th class="text-h6 text-center cell" style="width: 10%">Hora</th>
            <th
              v-for="day in days"
              :key="`d-${day.dayMonth}`"
              class="text-h6 text-center py-2 cell"
              style="width: 13%"
            >
              <div
                :class="`text-uppercase text-center ${
                  day.today
                    ? 'primary--text font-weight-bold'
                    : 'text--secondary'
                } text-body-2`"
              >
                {{ day.dayWeek }}
              </div>
              <v-btn
                class="elevation-0 text-h6 font-weight-medium"
                :color="day.today ? 'primary' : 'white'"
                fab
                >{{ day.dayMonth }}</v-btn
              >
              <div
                :class="`text-uppercase text-center ${
                  day.today
                    ? 'primary--text font-weight-bold'
                    : 'text--secondary'
                } text-body-2`"
              >
                {{ day.month }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="interval in intervals" :key="interval.time" class="white">
            <td class="font-weight-bold text--secondary cell">
              <div
                class="d-flex align-center justify-center"
                style="min-height: 100px"
              >
                {{ interval.time }}
              </div>
            </td>
            <td
              class="cell px-1"
              v-for="day in days"
              :key="`d-${interval.time}-${day.dayMonth}`"
              @click.prevent="
                $emit('click:time', { date: day.date, time: interval.time })
              "
            >
              <template v-for="(event, index) in eventsCalendar[day.date]">
                <v-card
                  v-if="
                    event &&
                    event.value >= interval.start &&
                    event.value < interval.end
                  "
                  :key="`ev-${index}`"
                  @click.prevent="
                    $emit('click:event', { nativeEvent: $event, event })
                  "
                  class="pa-1"
                  rounded="lg"
                  :color="event.color"
                  flat
                  dark
                >
                  <div class="font-weight-bold">{{ event.name }}</div>
                  <div>
                    <span class="font-weight-bold">P:</span>
                    <span>{{ event.patient }}</span>
                  </div>
                  <div>
                    <span class="font-weight-bold">C:</span>
                    <span>{{ event.customer }}</span>
                  </div>
                  <div v-if="event.appointmentDetails">
                    <span>{{ event.appointmentDetails }}</span>
                  </div>
                  <div>{{ event.timeStart }} - {{ event.timeEnd }}</div>
                </v-card>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- calendar month -->
    <v-calendar
      class="text-body-1"
      v-else
      color="primary"
      :value="date"
    ></v-calendar>
  </v-card>
</template>

<script>
export default {
  model: {
    prop: "date",
    event: "input",
  },
  props: {
    interval: {
      type: Number,
      default: 0.5,
    },
    type: {
      type: String,
      default: "week",
    },
    types: {
      type: Array,
      default: () => ["month", "week", "day"],
    },
    height: {
      type: String,
      default: "auto",
    },
    date: {
      type: String,
      default: "",
    },
    events: {
      type: Array,
      default: () => [],
    },
    hideControls: {
      type: Boolean,
      default: false,
    },
    hideAction: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    typeCalendar: "week",
    menu: false,
    range: {
      start: {
        date: "",
      },
      end: {
        date: "",
      },
    },
  }),

  mounted() {
    this.setRange(this.selectedDate.format("YYYY-MM-DD"));
  },

  computed: {
    controls() {
      const options = [
        { text: "Vista por mes", value: "month" },
        { text: "Vista por semana", value: "week" },
        { text: "Vista por día", value: "day" },
      ];
      return options.filter((option) => this.types.includes(option.value));
    },
    intervals() {
      const intervals = [];
      for (let n = 7.0; n < 18; n += this.interval) {
        const hour = Math.floor(n);
        const minutes = (n - hour) * 60;
        const hourStr = ("0" + hour).slice(-2);
        const minutesStr = ("0" + minutes).slice(-2);
        intervals.push({
          start: n,
          end: n + this.interval,
          time: `${hourStr}:${minutesStr}`,
        });
      }
      return intervals;
    },
    selectedDate() {
      return this.date ? this.moment(this.date) : this.moment();
    },
    days() {
      const now = this.moment().clone();
      const date = this.selectedDate;
      const days = [];
      const dayMonth = date.date();

      if (this.typeCalendar === "day") {
        days.push({
          date: now.format("YYYY-MM-DD"),
          dayMonth,
          dayWeek: date.format("ddd").slice(0, 3),
          month: date.format("MMM").slice(0, 3),
          today: date.isSame(now.format("YYYY-MM-DD")),
        });
      } else if (this.typeCalendar === "week") {
        const start = date.clone().weekday(0);
        const end = date.clone().weekday(7);

        for (let day = start; day.isBefore(end); day.add(1, "day")) {
          days.push({
            date: day.format("YYYY-MM-DD"),
            dayMonth: day.date(),
            dayWeek: day.format("ddd").slice(0, 3),
            month: date.format("MMM").slice(0, 3),
            today: day.format("YYYY-MM-DD") === now.format("YYYY-MM-DD"),
          });
        }
      }
      return days;
    },
    eventsCalendar() {
      const events = {};

      for (const event of this.events) {
        const eventTmp = {
          ...event,
          date: this.moment(event.start).utc().format("YYYY-MM-DD"),
          timeStart: this.moment(event.start).utc().format("HH:mm"),
          timeEnd: this.moment(event.end).utc().format("HH:mm"),
          value:
            this.moment(event.start).utc().hour() +
            this.moment(event.start).utc().minute() / 60,
        };
        if (!events[eventTmp.date]) {
          events[eventTmp.date] = [];
        }
        events[eventTmp.date].push(eventTmp);
      }
      return events;
    },
  },

  methods: {
    onNext() {
      let nextDate;
      const date = this.selectedDate.clone();
      if (this.typeCalendar === "week") {
        nextDate = date.add(7, "day");
      } else if (this.typeCalendar === "day") {
        nextDate = date.add(1, "day");
      } else {
        nextDate = date.add(1, "month");
      }
      const nextDateStr = nextDate.format("YYYY-MM-DD");
      this.setRange(nextDateStr);
      this.$emit("input", nextDateStr);
    },
    onToday() {
      const today = this.moment().format("YYYY-MM-DD");
      this.setRange(today);
      this.$emit("input", today);
    },
    onPrev() {
      let prevDate;
      const date = this.selectedDate.clone();
      if (this.typeCalendar === "week") {
        prevDate = date.subtract(7, "day");
      } else if (this.typeCalendar === "day") {
        prevDate = date.subtract(1, "day");
      } else {
        prevDate = date.subtract(1, "month");
      }
      const prevDateStr = prevDate.format("YYYY-MM-DD");
      this.setRange(prevDateStr);
      this.$emit("input", prevDateStr);
    },
    onChangeDate(date) {
      this.$emit("input", date);
      this.menu = false;
    },
    setRange(date) {
      const dateStart = this.moment(date).startOf("month").format("YYYY-MM-DD");
      const dateEnd = this.moment(date).endOf("month").format("YYYY-MM-DD");
      if (
        this.range.start.date !== dateStart &&
        this.range.end.date !== dateEnd
      ) {
        this.range = {
          start: { date: dateStart },
          end: { date: dateEnd },
        };
      }
    },
  },

  watch: {
    type(val) {
      this.typeCalendar = val;
    },
    range(val) {
      this.$emit("change", val);
    },
  },
};
</script>

<style>
.table-border {
  border: thin solid rgba(0, 0, 0, 0.12);
}
.cell {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.inline {
  display: flex;
  justify-content: end;
}
</style>
