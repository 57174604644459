<template #all>
  <div>
    <v-card flat>
      <v-card-text>
        <alerts
          v-if="alert.show"
          v-on:close_alert="closeAlert"
          v-on:accept_alert="acceptAlert"
          :properties="alert"
        >
        </alerts>
        <template v-if="type === 'medicalclinic'">
          <user-profile-lab
            :patient="data"
            :patientinfo="data"
            :useData="useData"
          ></user-profile-lab>
        </template>
        <template v-else>
          <user-profile
            :patientinfo="patientinfo"
            :data="data"
            :useData="useData"
          />
        </template>

        <v-row style="margin-top: 1rem">
          <v-col cols="12" class="box" style="margin-top: 1rem">
            <ValidationObserver ref="observer" tag="form">
              <v-form v-model="valid" class="position-relative">
                <block v-if="loading"></block>

                <v-row class="ml-2 mr-2" style="margin-top: 0.3rem">
                  <v-col cols="12" class="tabsNh">
                    <v-col
                      cols="12"
                      v-if="form.ID_Images"
                      style="display: flex; justify-content: space-between"
                    >
                      <div>
                        <v-btn
                          v-if="
                            rolesStatus.findIndex(
                              (role) => role.RoleName == 'admin'
                            ) > -1 ||
                            rolesStatus.findIndex(
                              (role) => role.RoleName == 'root'
                            ) > -1 ||
                            rolesStatus.findIndex(
                              (role) => role.ID_Role == 23
                            ) > -1
                          "
                          small
                          color="error"
                          @click.prevent="showDelete(toDelete)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                      <div>
                        <v-btn
                          small
                          class="btnblue mr-1"
                          @click="showhistory = !showhistory"
                        >
                          <v-icon> mdi-magnify </v-icon>
                        </v-btn>

                        <v-btn
                          small
                          class="btnclean"
                          @click.prevent="clearData"
                          style="margin-right: 0.3rem"
                        >
                          <v-icon> mdi-broom </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="
                            rolesStatus.findIndex(
                              (role) => role.RoleName == 'admin'
                            ) > -1 ||
                            rolesStatus.findIndex(
                              (role) => role.RoleName == 'root'
                            ) > -1 ||
                            rolesStatus.findIndex(
                              (role) => role.ID_Role == 22
                            ) > -1
                          "
                          small
                          class="btnsave"
                          @click.prevent="updateImageWithFiles"
                        >
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="!form.ID_Images"
                      style="display: flex; justify-content: flex-end"
                    >
                      <v-btn
                        small
                        class="btnblue mr-1"
                        @click="showhistory = !showhistory"
                      >
                        <v-icon> mdi-magnify </v-icon>
                      </v-btn>

                      <v-btn
                        small
                        class="btnclean"
                        @click.prevent="clearData"
                        style="margin-right: 0.3rem"
                      >
                        <v-icon> mdi-broom </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="
                          rolesStatus.findIndex(
                            (role) => role.RoleName == 'admin'
                          ) > -1 ||
                          rolesStatus.findIndex(
                            (role) => role.RoleName == 'root'
                          ) > -1 ||
                          rolesStatus.findIndex((role) => role.ID_Role == 21) >
                            -1
                        "
                        small
                        class="btnsave"
                        @click.prevent.stop="SaveImageData"
                      >
                        <v-icon>mdi-content-save</v-icon>
                      </v-btn>
                    </v-col>
                  </v-col>
                  <v-col cols="12" md="3" sm="12" style="margin-top: 1rem">
                    <div style="width: 265px !important; display: flex">
                      <date-picker
                        :value="form.ImagesDate"
                        label="Fecha"
                        @dateSelected="(param) => this.changeDate(param)"
                      ></date-picker>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <template v-if="data"> </template>
                  </v-col>
                  <v-col cols="12" md="1"></v-col>
                  <v-col
                    cols="12"
                    md="2"
                    style="display: flex; justify-content: flex-end"
                  >
                    <v-btn
                      style="margin-top: 1rem"
                      @click="showComment = !showComment"
                      :class="form.Comment ? 'btnclean' : 'btnblack'"
                    >
                      Comentarios
                    </v-btn>
                  </v-col>
                </v-row>

                <br />

                <v-row class="ml-2 mr-2">
                  <v-col cols="12" md="12">
                    <v-dialog
                      v-model="showComment"
                      scrollable
                      :max-width="$vuetify.breakpoint.smAndDown ? '90%' : '60%'"
                    >
                      <v-card class="pa-4">
                        <quill-editor
                          v-model="form.Comment"
                          :options="quillOptions"
                        ></quill-editor>
                      </v-card>
                    </v-dialog>
                  </v-col>

                  <v-col cols="12" md="12" class="mb-3" style="text-align: right">
                    <br />
                    <v-btn
                      class="btn-add"
                      @click.prevent.stop="addImagesDialog = !addImagesDialog"
                    >
                      Añadir Documentos
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <DxDataGrid
                        :scrolling="{ useNative: true }"
                        :show-borders="true"
                        :data-source="images"
                        key-expr=""
                        :column-auto-width="true"
                        style="width: fit-content;"
                      >
                        <!--       <DxColumn :width="columnWidth" :width="80"
                          cell-template="showdownload" caption="Descargar"></DxColumn> -->
                        <DxColumn
                          :min-width="300"
                          caption="Documentos"
                          cell-template="show-image"
                        ></DxColumn>
                        <DxColumn
                          :min-width="200"
                          cell-template="ImageType"
                          caption="Extensión archivo"
                        ></DxColumn>
                        <DxColumn
                          :min-width="200"
                          cell-template="category"
                          caption="Categoría"
                        ></DxColumn>
                        <DxColumn
                          :width="80"
                          caption=""
                          cell-template="template-delete"
                        ></DxColumn>
                        <!-- <DxColumn
                          :width="80"
                          cell-template="show-template"
                          caption=""
                        ></DxColumn> -->
                        <template #showdownload="{ data }">
                          <div>
                            <a
                              href=""
                              class="mx-2"
                              @click.prevent.stop="downloadFile(data.data)"
                            >
                              <v-icon>mdi-download</v-icon>
                            </a>
                          </div>
                        </template>
                        <template #show-image="{ data }">
                          <div class="position-relative">
                            <v-icon
                              dark
                              class="extra-css-magnify"
                              :color="'black'"
                              @click="
                                (imageUrl = data.data.src),
                                  (ZoomDialog = !ZoomDialog)
                              "
                              v-if="
                                data.data.src &&
                                !data.data.isdoc &&
                                !data.data.Picture.endsWith('.mp4') &&
                                !data.data.Picture.endsWith('.MP4')
                              "
                              >mdi-magnify-plus</v-icon
                            >
                            <v-icon
                              dark
                              class="extra-css-magnify"
                              :color="'white'"
                              v-if="
                                (data.data.src && data.data.isdoc) ||
                                data.data.Picture.endsWith('.mp4') ||
                                data.data.Picture.endsWith('.MP4')
                              "
                              >mdi-magnify-plus</v-icon
                            >
                            <img
                              :src="data.data.src"
                              v-if="
                                /*form.TypeOfImage != 'MP4' &&*/ data.data.src &&
                                !data.data.isdoc &&
                                !data.data.Picture.endsWith('.mp4')
                              "
                              class="img"
                            />
                            <v-icon
                              dark
                              class="extra-css-files"
                              v-if="
                                (data.data.src && data.data.isdoc) ||
                                data.data.Picture.endsWith('.mp4') ||
                                data.data.Picture.endsWith('.MP4')
                              "
                              >mdi-folder-file-outline
                            </v-icon>
                            <!--video   id="video" :src="data.data.src" type="video/mp4" v-if=""
                              disabled></video-->
                            <span v-if="!data.data.src">{{
                              data.data.file.name
                            }}</span>

                            <a
                              :href="data.data.src"
                              style="vertical-align: bottom"
                              target="_blank"
                              rel="noopener"
                              v-if="data.data.src"
                              >Documento, imagen o video
                              {{
                                data.data.ID_Image ? "ID " + data.data.ID_Image : ""
                              }}</a
                            >
                          </div>
                        </template>
                        <template #ImageType="{ data }">
                          <div
                            style="
                              margin: auto;
                              width: 50%;

                              padding: 10px;
                            "
                          >
                            Archivo
                            {{
                              data.data.Picture.substring(
                                data.data.Picture.length -
                                  (data.data.Picture.length -
                                    data.data.Picture.indexOf(".")) +
                                  1
                              )
                            }}
                          </div>
                        </template>
                        <template #category="{ data }">
                          <div style="margin: auto; width: 50%; padding: 10px">
                            {{ data.data.TypeOfImage }}
                          </div>
                        </template>
                        <template #template-delete="{ data }">
                          <div>
                            <a
                              href=""
                              class="mx-2"
                              @click.prevent="
                                showAlert(
                                  'question',
                                  'Confirmar',
                                  'Esta seguro de eliminar este registro',
                                  'confirmDelete'
                                );
                                dataTodelete = data.data.ID_Image;
                              "
                            >
                              <v-icon color="error"> mdi-delete </v-icon>
                            </a>
                          </div>
                        </template>
                        <!-- <template #show-template="{ data }">
                          <div>
                            <a
                              href=""
                              class="mx-2"
                              @click.prevent.stop="DeleteImageRow(data.data)"
                            >
                              <v-icon color="error">mdi-delete</v-icon>
                            </a>
                          </div>
                        </template> -->
                      </DxDataGrid>
                    </v-col>
                  </v-row>
                <br />
                <v-col style="margin-top: 0.6rem; margin-bottom: 1rem"> </v-col>
              </v-form>
            </ValidationObserver>
          </v-col>
        </v-row>
        <v-dialog v-model="showhistory" scrollable :width="'90%'">
          <v-card class="pa-4" height="inherit" style="overflow: auto;">
            <div
              style="
                margin-top: 0.3rem;
                margin-bottom: 0.3rem;
                display: flex;
                justify-content: flex-end;
              "
            >
              <v-btn
                small
                class="btndelete"
                @click.prevent="showhistory = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <GeneralFilter
              :search="{ ID_Patient: data.ID_Patient }"
              :filterEndpoint="$API.medicalimages.getImagesFiltered"
              @emptyFilter="getImages"
              @filtered="filterHandler"
            >
              <!-- <DxDataGrid
             :scrolling="{useNative:true}"
                :show-borders="true"
                :data-source="masterData"
                key-expr="ID_Images"
                @exporting="onExporting"
              >
                <DxExport :enabled="true" />
                <DxColumn
                  :width="70"
                  cell-template="show-template"
                  caption=""
                ></DxColumn>
                <DxPaging :page-size="10" />
                <DxColumn :width="columnWidth" data-field="ID_Images" caption="Id"></DxColumn>
                <DxColumn :width="columnWidth" data-field="ImagesDate" caption="Fecha"></DxColumn>
                <DxColumn
                  data-field="UserName"
                  caption="Atendido por"
                ></DxColumn> -->

              <DxDataGrid 
                :show-borders="true" 
                :data-source="masterData"
                key-expr="ID_Images" 
                @exporting="onExporting" 
                :column-auto-width="true"
                style="width: fit-content;"
              >
                <DxExport :enabled="true" />
                <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                <DxPaging :page-size="10" />
                <DxColumn :min-width="100" data-field="ImagesDate" caption="Fecha"></DxColumn>
                <DxColumn :min-width="100" data-field="ID_Images" caption="Id"></DxColumn>
                <DxColumn :min-width="200" data-field="FullName" caption="Atendido por"></DxColumn>
                <DxColumn :min-width="300" data-field="TypeOfImage" caption="Tipo de imagen"></DxColumn>

                <DxColumn
                  v-if="
                    rolesStatus.findIndex((role) => role.RoleName == 'admin') >
                      -1 ||
                    rolesStatus.findIndex((role) => role.RoleName == 'root') >
                      -1 ||
                    rolesStatus.findIndex((role) => role.ID_Role == 23) > -1
                  "
                  :width="80"
                  cell-template="show-delete"
                  caption=""
                ></DxColumn>
                <template #show-delete="{ data }">
                  <div>
                    <a
                      href=""
                      class="mx-2"
                      @click.prevent.stop="
                        showDelete(data.data.ID_Images);
                        showhistory = false;
                      "
                    >
                      <v-icon color="error">mdi-delete</v-icon>
                    </a>
                  </div>
                </template>
                <template #show-template="{ data }">
                  <div>
                    <a
                      href="#"
                      @click.prevent.stop="
                        filesByImageID(data.data.ID_Images);
                        showhistory = false;
                      "
                    >
                      <v-icon color="primary">mdi-eye</v-icon>
                    </a>
                  </div>
                </template>
                <DxPager
                  :allowed-page-sizes="pageSizes"
                  :show-page-size-selector="true"
                />
                <DxPaging :page-size="5" />
              </DxDataGrid>
            </GeneralFilter>
          </v-card>
        </v-dialog>

        <v-dialog v-model="addImagesDialog" scrollable
                      :max-width="$vuetify.breakpoint.smAndDown ? '90%' : '80%'">
          <AddImages
            @uploadedImage="uploadedImageData($event)"
            @closepopup="addImagesDialog = false"
            :type="form.TypeOfImage || ''"
          ></AddImages>
        </v-dialog>
        <v-dialog v-model="ZoomDialog" v-if="imageUrl" scrollable max-width="90%">
          <ZoomImage
            :ZoomDialog="ZoomDialog"
            :imageUrl="imageUrl"
            :type="form.TypeOfImage || ''"
          ></ZoomImage>
        </v-dialog>
        <!--    <v-dialog v-model="deleteDialog" v-if="imageToDelete" max-width="700">
      <DeleteUserDialog :data="imageToDelete" :name="'Image Type'" v-if="deleteDialog" @close="deleteDialog = false"
        @f_call="destroyImageWithFiles($event)"></DeleteUserDialog>
    </v-dialog> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Alerts from "../../../components/Alerts.vue";
import Vue from "vue";
import Block from "@/components/Block";
import {
  DxDataGrid,
  DxExport,
  DxColumn,
  DxPager,
  DxPaging,
  // DxEditing,
} from "devextreme-vue/data-grid";
import DatePicker from "@/components/DatePicker";
import AddImages from "@/components/VeterinaryImage/addImages.vue";
import ZoomImage from "@/components/VeterinaryImage/zoomImageView.vue";
import { saveAs } from "file-saver";
import { printToExcel } from "@/helpers/printToexcel";
/* import DeleteUserDialog from "../../settings/business/partial/DeleteTemplateDialog.vue"; */
import { mapState } from "vuex";
import GeneralFilter from "@/components/GeneralFilter.vue";
import UserProfile from "@/components/Displays/UserProfileDisplay.vue";
import UserProfileLab from "@/components/Displays/UserProfileDisplayLab.vue";

export default {
  name: "MedicalImages",
  components: {
    DxDataGrid,
    DxColumn,
    DxExport,
    DxPager,
    DxPaging,
    // DxEditing,
    DatePicker,
    Block,
    AddImages,
    ZoomImage,
    GeneralFilter,
    UserProfile,
    UserProfileLab,
    /* DeleteUserDialog, */
    Alerts,
  },
  props: ["data", "win", "type"],
  data: () => ({
    dataTodelete: null,
    all: 0,
    valid: false,
    url: null,
    dateExists: false,
    patientinfo: [],
    dateRegistered: false,
    showComment: false,
    images: [],
    alert: {
      type: "success",
      show: false,
      header: "",
      body: "",
    },
    toDelete: undefined,
    image: null,
    loading: false,
    form: {},
    TypeOfImage: "",
    ExtensionImage: "",
    addImagesDialog: false,
    ZoomDialog: false,
    imageUrl: null,
    showhistory: false,
    loginUserID: null,
    masterData: [],
    pageSizes: [5, 15, 25, 100],
    deleteDialog: false,
    imageToDelete: null,
    tempFiles: [],
    isEdit: false,
    date: null,
    showImagesUploaded: false,
    quillOptions: {
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ align: [] }],

            ["clean"],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ script: "sub" }, { script: "super" }],
            [{ formula: "" }],
          ],
          handlers: {},
        },
      },
      theme: "snow",
    },
    useData: false,
    refdelete: null,
  }),
  computed: {
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
    columnWidth() {
      return this.$vuetify.breakpoint.smAndDown ? "150px" : "auto";
    },
  },
  mounted() {
    this.$store.dispatch("rolesUser/getAllRoles");
    this.getImages();
    this.loginUserID = JSON.parse(localStorage.getItem("user")).id;
    this.useData = JSON.parse(localStorage.getItem("user"));
    if (this.data) {
      Object.keys(this.data).map((el) => {
        Vue.set(this.patientinfo, el.toLowerCase(), this.data[el]);
      });
      this.url = this.data.ProfilePicPath;
    }
  },
  watch: {
    // masterData: {
    //     deep: true,
    //     inmediate: true,
    //     handler(newVal) {
    //       console.log(newVal);
    //         for (const item of newVal) {
    //             Object.keys(item).forEach(key =>{
    //               if(typeof(item[key]) === 'string') {
    //                 const value = item[key].replace(/<[^>]+>/g, ' ');
    //                 item[key] = value;
    //               }
    //               if(item[key] == 'undefined') item[key] = '';
    //             })
    //         }
    //         newVal = newVal.sort((a, b) => {
    //             const [aDay, aMonth, aYear] = a.ImagesDate.split('/');
    //             const aDate = new Date(aYear, aMonth - 1, aDay);
    //             const [bDay, bMonth, bYear] = b.ImagesDate.split('/');
    //             const bDate = new Date(bYear, bMonth - 1, bDay);
    //             return bDate - aDate;
    //         });
    //         return newVal;
    //     }
    // },
  },
  methods: {
    filterHandler(response) {
      this.masterData = response;
    },
    onExporting(e) {
      printToExcel(e, "Historial de Imágenes");
    },
    async downloadFile(data) {
      console.log("data", data.Path);
      const res = await fetch(data.Path, {
        method: "GET",
        headers: new Headers({ "Content-type": "application/json" }),
        //Access-Control-Allow-Origi

        withCredentials: true,
        crossorigin: true,
        mode: "no-cors",
      });
      console.log("res", res);
      const blob = await res.blob();
      saveAs(blob, data.Picture);
    },
    uploadedImageData(e) {
      /*  if (e.Path.startsWith('blob')) {
         e.Path = URL.createObjectURL(e.Path)
       } */

      /*  let imgbb = this.blobtoImg(e.Path)

        console.log('img bb', imgbb); */
      console.log("esto recibe", e);
      let newFile = {
        ...e,
        Picture: e.file.name,
      };
      this.images.push(newFile);
      if (this.isEdit) this.tempFiles.push(newFile);
      this.addImagesDialog = false;
    },
    isBlob(string) {
      return string.startsWith("blob");
    },
    async SaveImageData() {
      if (!this.form.ID_Images && this.images.length < 1) {
        this.showAlert("danger", "Error", "Por favor seleccione una imagen");
        return;
      }

      console.log("form imagesdate", this.form.ImagesDate);
      this.masterData.forEach((element) => {
        console.log("elementmaster imagesdate", element.ImagesDate);
        if (element.ImagesDate == this.form.ImagesDate) {
          this.dateExists = true;
        }
      });
      if (!this.dateExists) {
        this.loading = true;
        this.$refs.observer.validate();
        const isValid = await this.$refs.observer.validate();
        if (isValid) {
          let arr = [];
          for (let x of this.images) {
            /*  console.log('XXTypeOfImage',x.TypeOfImage); */

            x.file["TypeOfImage"] = x.TypeOfImage;
            arr.push(x.file);
          }

          this.form.files = arr;
          this.form.ID_Patient = this.patientinfo.id_patient;
          this.form.ID_User_Insert = this.loginUserID;
          this.form.ID_Business = JSON.parse(
            localStorage.getItem("user")
          ).businessid;
          this.form.ID_Branch = JSON.parse(localStorage.getItem("user")).branch;
          this.form.TypeOfImage = this.TypeOfImage;
          const formData = new FormData();
          for (const i of Object.keys(arr)) {
            formData.append("file", arr[i]);
            /*    console.log("type of new register", arr[i].TypeOfImage); */
            formData.append("types", arr[i].TypeOfImage);
          }
          formData.append("form", JSON.stringify(this.form));
          setTimeout(() => {
            this.$API.medicalimages.createImagesData(formData).then(
              (reg) => {
                this.showAlert("success", "Éxito", "Registro Agregado");
                this.getImages();
                this.loading = false;
                this.dateExists = false;
                this.filesByImageID(reg.ID_Images);
              },
              (err) => {
                // this.$toasted.info(err.message);
                console.log("error", err);
                this.loading = false;
                this.showAlert("danger", "Error", "No se guardó Registro");
                this.dateExists = false;
              }
            );
          }, 1000);
        } else {
          this.loading = false;
          this.dateExists = false;
        }
      } else {
        this.showAlert(
          "danger",
          "Error",
          "No se puede guardar mas de un registro por fecha"
        );
        this.dateExists = false;
      }
    },
    showDelete(id) {
      this.toDelete = id;
      return this.showAlert(
        "question",
        "Eliminar Paciente",
        "¿Está seguro que desea eliminar este paciente?",
        "destroyImageWithFiles"
      );
    },
    getImages() {
      this.loading = true;
      this.$API.medicalimages.getImages(this.data.ID_Patient).then(
        (res) => {
          this.masterData = res;
          console.log("master data", this.masterData);
          this.loading = false;
          this.clearData();

          if (this.$store.state.vetPatientData.dataImages) {
            let date = this.$store.state.vetPatientData.dataImages;
            this.$store.commit("vetPatientData/cleanData");
            let dateelement = null;
            this.masterData.forEach((element) => {
              if (element.ImagesDate == date) {
                dateelement = element;
              }
            });
            console.log("dateelement", dateelement);
            if (dateelement) this.filesByImageID(dateelement.ID_Images);
          }
        },
        (err) => {
          //  this.$toasted.info(err.message);
          console.log("error", err);
          this.loading = false;
          this.showAlert(
            "danger",
            "Error",
            "Hubo un error al obtener los datos"
          );
        }
      );
    },
    closeAlert() {
      this.alert.show = false;

      if (this.alert.options == "redirectcustomer") {
        this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid });
        this.$store.dispatch("addWindow", {
          name: "customerList",
          component: "CustomersList",
          unique: true,
        });
        this.$store.dispatch("reloadData", "customers");
      }
      if (this.alert.options == "confirmDelete") {
        this.dataTodelete = null;
      }
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "deletecustomer") {
        this.deleteCustomer();
      }
      if (this.alert.options == "confirmDelete") {
        this.DeleteImageRow(this.dataTodelete);
      }
      if (this.alert.options == "destroyImageWithFiles") {
        this.destroyImageWithFiles(this.toDelete);
      }
      this.toDelete = undefined;
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    destroyImageWithFiles(id) {
      this.loading = true;
      this.$API.medicalimages.deleteImageWithFiles(id).then(
        () => {
          this.showAlert("success", "Éxito", "Registro Eliminado");
          // this.$toasted.info("El registro de imágenes ha sido eliminado.");
          this.getImages();
          this.deleteDialog = false;
          this.loading = false;
          this.form = {};
          this.all += 1;
        },
        (err) => {
          this.showAlert(
            "danger",
            "Error",
            "Hubo un error al eliminarel registro"
          );
          console.log("error", err);
          // this.$toasted.error(err.message);
          this.loading = false;
        }
      );
    },

    blobtoImg(blob) {
      return URL.createObjectURL(blob);
    },
    DeleteImageRow(id) {
      if (this.isEdit) {
        this.loading = true;
        this.$API.medicalimages.deletespecifiedFile(id).then(
          () => {
            this.showAlert("success", "Éxito", "Registro Eliminado");
            this.images = this.images.filter((item) => item.ID_Image != id);
            this.loading = false;
            this.dataTodelete = null;
          },
          (err) => {
            this.showAlert(
              "danger",
              "Error",
              "Ocurrió un error al eliminar el Registro"
            );
            //  this.$toasted.info(err.message);
            console.log("error", err);
            this.loading = false;
          }
        );
      }
    },

    changeDate(date) {
      this.form.ImagesDate = date;
      this.masterData.forEach((element) => {
        if (element.ImagesDate == date) {
          this.filesByImageID(element.ID_Images);
        } else {
          Object.keys(this.form).forEach((key) => {
            if (key !== "ImagesDate") {
              delete this.form[key];
            }
          });
          this.images = [];
        }
      });
    },
    checkFileType(filename) {
      const validExtensions = ["xlsx", "xls", "pdf", "doc", "docx"];
      const fileExtension = filename.split(".").pop().toLowerCase();
      return validExtensions.includes(fileExtension);
    },
    filesByImageID(id) {
      this.loading = true;
      this.toDelete = id;

      this.$API.medicalimages.filesByImageID(id).then(
        (res) => {
          console.log("res files by image", res);
          this.images = res.files;
          this.images.map((ele) => {
            ele.isdoc = this.checkFileType(ele.Picture);
            ele.src = `${ele.Path}`;
          });
          console.log("images", this.images);
          this.form.ImagesDate = res.images.ImagesDate;
          this.form.TypeOfImage = res.images.TypeOfImage;
          this.TypeOfImage = res.images.TypeOfImage;

          this.form.Comment = res.images.Comment;
          this.form.ID_Images = res.images.ID_Images;

          this.loading = false;
          this.isEdit = true;
        },
        (err) => {
          // this.$toasted.info(err.message);
          console.log("error", err);
          this.loading = false;
        }
      );
    },

    async updateImageWithFiles() {
      this.loading = true;
      this.$refs.observer.validate();
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        let arr = [];
        for (let x of this.tempFiles) {
          x.file["TypeOfImage"] = x.TypeOfImage;
          arr.push(x.file);
        }
        this.form.ID_User_Update = this.loginUserID;
        this.form.ID_Business = JSON.parse(
          localStorage.getItem("user")
        ).businessid;
        this.form.ID_Branch = JSON.parse(localStorage.getItem("user")).branch;
        /*         console.log("este es el arr ", arr); */
        const formData = new FormData();
        for (const i of Object.keys(arr)) {
          formData.append("file", arr[i]);
          console.log("array i", arr[i].TypeOfImage);
          formData.append("types", arr[i].TypeOfImage);
        }
        formData.append("form", JSON.stringify(this.form));
        /*  console.log('este es el form',formData); */
        /* console.log("formdata files", formData.files); */
        setTimeout(() => {
          this.$API.medicalimages
            .updateImageswithFiles(this.form.ID_Images, formData)
            .then(
              () => {
                this.filesByImageID(this.form.ID_Images);
                this.getImages();
                this.showAlert("success", "Éxito", "Registro actualizado");
                /*    this.$toasted.info("El registro de imágenes seleccionado ha sido actualizado."); */
                this.loading = false;
                setTimeout(() => {
                  this.filesByImageID(this.form.ID_Images);
                }, 2000);
              },
              (err) => {
                // this.$toasted.info(err.message);
                console.log("error", err);
                this.loading = false;
                this.showAlert(
                  "danger",
                  "Error",
                  "No se pudo actualizar el Registro"
                );
                this.filesByImageID(this.form.ID_Images);
              }
            );
        }, 1000);
      }
    },

    clearData() {
      this.form = {};
      this.form = {
        ImagesDate: this.moment().format("DD/MM/YYYY"),
      };
      this.images = [];
      this.TypeOfImage = "";
      this.$refs.observer.reset();
      this.tempFiles = [];
      this.isEdit = false;
    },
  },
};
</script>

<style scoped>
.btn-add {
  background-color: #3498db !important;
  color: white;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
}

.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.tabs {
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white;
}
.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;
  border-radius: 15px;
}
.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}
.tabsB {
  width: 100%;
  padding: 10px;
  background-color: #1976d2;
  color: white;
  border-radius: 15px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
}
.tabsB:hover {
  cursor: pointer;
}

.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;

  border-radius: 15px;
}

.infopatient {
  margin-top: 5%;
}

.img {
  max-width: 100%;
  max-height: 100%;
  width: 50px !important;
  height: 50px !important;
  margin-right: 10px;
}

#video {
  max-width: 100%;
  max-height: 100%;
  width: 50px !important;
  height: 50px !important;
}
.btnblack {
  background-color: rgba(60, 60, 60, 1) !important;
  color: white !important;
}
.btnblue {
  background-color: #1976d2 !important;
  color: white;
}

.extra-css-magnify {
  bottom: 15px;
  position: relative;
  left: 2px;
  margin-right: 20px;
}

.extra-css-files {
  font-size: 50px !important;
  margin-right: 10px;
}
.position-relative {
  vertical-align: middle;
}
</style>
