<template>
  <v-card>
    <v-card-title class="text-h5"> Plantillas de Medicamentos/Vacunas </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" lg="12">
          <DxDataGrid class="isScrolledByGeneralFilter"
            :show-borders="true"
            :data-source="medicationPlan"
            key-expr="ID_Medication_Plan"
          >
            <DxColumn
              :width="80"
              cell-template="show-template"
              caption=""
            ></DxColumn>
            <DxColumn data-field="ID_Medication_Plan" caption="Plan ID"></DxColumn>
            <DxColumn data-field="PlanName" caption="Plan Name"></DxColumn>
            <DxColumn data-field="ID_User_Insert" caption="User ID"></DxColumn>
            <template #show-template="{ data }">
              <div>
                <v-checkbox
                  v-model="data.data.checkbox"
                  class="pt-0"
                  @click="getSelectedRow(data.data)"
                ></v-checkbox>
                <!-- <input type="checkbox" id="checkbox" @change="getSelectedRow(data.data)"> -->
              </div>
            </template>
          </DxDataGrid>
   <!--        <div class="options">

<div class="options-container">
  <div class="option">

    <DxButton text="Contained" styling-mode="contained" style="min-width: 120px;" :height="50"
      @click="saveAssign()">
      <template #content>
        <span>
          <DxLoadIndicator :visible="loadIndicatorVisible" class="button-indicator" />
          <span class="dx-button-text">Asignar seleccionados</span>
        </span>
      </template>
    </DxButton>
  </div>
  <div class="option">

    <DxButton text="Contained" styling-mode="contained" style="min-width: 120px;" :height="50"
      @click="addAll()">
      <template #content>
        <span>
          <DxLoadIndicator :visible="loadIndicatorVisible" class="button-indicator" />
          <span class="dx-button-text">Asignar Todos</span>
        </span>
      </template>
    </DxButton>
  </div>
  &nbsp;

</div>
</div> -->
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
/* import { DxButton } from 'devextreme-vue/button'; */
import { DxDataGrid, DxColumn } from "devextreme-vue/data-grid";
export default {
  name: "MedicationPlanPopUp",
  components: { DxDataGrid, DxColumn, /* DxButton */ },
  data() {
    return {
      medicationPlan: [],
      assignedMedication:[],
      checkbox: true,
    };
  },
  mounted() {
    this.getAllPlans();
  },
  methods: {
    getAllPlans() {
      let search ={};
     search.ID_Business = JSON.parse(localStorage.getItem("user")).businessid || ""
      search.ID_Branch = JSON.parse(localStorage.getItem("user")).branch || ""
      this.$API.veterinarymedication.getMedicationPlansByBranch(search).then(
        (response) => {
          response.map((el) => {
            el.checkbox = false;
          });
          this.medicationPlan = response;
        }
      );
    },
    saveAssign() {
      this.assignedMedication.forEach(element => {
        this.data = element
        this.name = element.ItemName;
        this.performfunction()
      });
      this.$emit("refreshData");
      this.closeDialog()
      this.assignedVaccines = []
    },
    addAll() {
      this.medicationPlan.forEach(element => {
        this.data = element
        this.name = element.ItemName;
        this.performfunction()
      });
      this.$emit("refreshData");
      this.closeDialog()
    },
    getSelectedRow(e) {
    /*   console.log(e); */
      if (e.checkbox == true) {
        this.medicationPlan.map((el) => {
          if (el.ID_Medication_Plan == e.ID_Medication_Plan) el.checkbox = true;
          else el.checkbox = false;
        });
        this.$emit("closePlan", e);
        this.getAllPlans();
      } else {
        this.getAllPlans();
      }
    },
  },
};
</script>

<style>
.dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: top;
  text-align: center !important;
}
.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  min-width: 24%;
  display: inline-block;
  margin: 10px;
}
</style>
