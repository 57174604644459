<template>
  <div>

    <v-card flat>
      <v-card-text>
       


        <user-profile :patientinfo="patientinfo" :data="data" :useData="useData" />

        <v-row style="margin-top: 1rem;">



          <v-col cols="12" class="box" style="margin-top: 1rem;">


            <ValidationObserver ref="observer" tag="form">
              <v-form v-model="valid" class="position-relative">
                <block v-if="loading"></block>

                <v-row class="ml-2 mr-2" style="margin-top: .3rem;">
                  <v-col cols="12" class="tabsNh">

                    <v-col v-if="selectedvalues" cols="12" md="12" style="display: flex; justify-content: space-between;">

                      <div style="width: 212px;" ></div>
                      <div>
                          <v-btn v-if="$store.getters['rolesUser/getCustomRole'](107)" small class="btn-add mr-1" @click.prevent="getItemsToBeBilled(); showBillPatient = true">
                              <v-icon class="mr-3">mdi-account-details</v-icon> Facturar paciente
                          </v-btn>
                      </div>
                      <div>
                        <v-btn class="mr-1" small
                          v-if="$store.getters['rolesUser/getCustomRole'](136)"
                          @click="print = { show: true, data: { groming: grooming, patientinfo: patientinfo, useData: useData, customer: patientinfo.customer } }">
                          <v-icon> mdi-printer </v-icon>

                        </v-btn>
                        <v-btn  v-if="$store.getters['rolesUser/getCustomRole'](133)" small class="btnblue mr-1" @click="showhistory = !showhistory">
                          <v-icon>
                            mdi-magnify
                          </v-icon>
                        </v-btn>
                        <v-btn small class="btnclean" @click.prevent="resetGrooming">
                          <v-icon>
                            mdi-broom
                          </v-icon>

                        </v-btn>
                      
                        <v-btn small v-if="selectedvalues && (
                          $store.getters['rolesUser/getCustomRole'](134)
                        )" class="btnsave" @click.prevent.stop="addGrooming">
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col cols="12" md="12" v-if="grooming.id_vpg"
                      style="display: flex; justify-content: space-between; ">
                      <div style="width: 212px;">
                        <v-btn v-if="$store.getters['rolesUser/getCustomRole'](135)
                          " small color="error" @click.prevent="confirmDelete(todelete)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                      <div>
                          <v-btn v-if="$store.getters['rolesUser/getCustomRole'](107)" small class="btn-add mr-1" @click.prevent="getItemsToBeBilled(); showBillPatient = true">
                              <v-icon class="mr-3">mdi-account-details</v-icon> Facturar paciente
                          </v-btn>
                      </div>
                      <div>
                        <v-btn small class="mr-1"
                          v-if="$store.getters['rolesUser/getCustomRole'](136)"
                          @click="print = { show: true, data: { groming: grooming, patientinfo: patientinfo, useData: useData, customer: patientinfo.customer } }">
                          <v-icon> mdi-printer </v-icon>
                        </v-btn>
                        <v-btn small 
                          v-if="$store.getters['rolesUser/getCustomRole'](133)"
                          class="btnblue mr-1" @click="showhistory = !showhistory">
                          <v-icon>
                            mdi-magnify
                          </v-icon>
                        </v-btn>
                        <v-btn small class="btnclean" @click.prevent="resetGrooming">
                          <v-icon>
                            mdi-broom
                          </v-icon>
                        </v-btn>
                        <v-btn small class="btnsave" @click.prevent="updateGrooming" v-if="grooming.id_vpg &&
                          $store.getters['rolesUser/getCustomRole'](134)"
                          >
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>

                      </div>
                    </v-col>



                  </v-col>
                  <v-col cols="12" md="3" sm="12" style="margin-top: 1rem;">
                    <div style="width: 285px !important; display: flex;">

                      <date-picker label="Fecha" @dateSelected="(param) => {

                        grooming.date = param
                        checktodaygroming(param)
                      }" v-model="grooming.date"></date-picker>

                    </div>
                  </v-col>
                  <v-col cols="12" md="8" sm="12">






                  </v-col>

                  <v-col cols="12" md="1" sm="6" style="display: flex; justify-content: flex-end; margin-top: 1rem;">
                    <v-btn :class="showBgcolorNotes" @click.prevent="showNotes = !showNotes">
                      Comentarios

                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="3">

                    <v-select outlined dense :items="services" :error-messages="errors" v-model="grooming.typeofservice"
                      label="Tipo de servicio" item-text="label" item-value="value" attach>
                    </v-select>

                  </v-col>
                  <v-col cols="12" md="3">

                    <v-select outlined dense attach :items="sizes" :error-messages="errors" v-model="grooming.size"
                      label="Selecciona tamaño" item-text="label" item-value="value">
                    </v-select>

                  </v-col>
                  <v-col cols="12" md="3">

                    <v-text-field outlined dense :error-messages="errors" v-model="grooming.groomer" label="Peluquero"
                      placeholder="Escribe el nombre del peluquero">
                    </v-text-field>

                  </v-col>
                  <v-col cols="12" md="3">

                    <v-text-field outlined dense :error-messages="errors" v-model="grooming.visit" label="Visita"
                      placeholder="Escribe sobre la visita">
                    </v-text-field>

                  </v-col>
                  <v-col cols="12" md="2">
                    <date-picker label="Siguiente cita" @dateSelected="(param) => (grooming.nextappointment = param)"
                      v-model="grooming.nextappointment"></date-picker>
                  </v-col>
                  <v-col cols="12" md="12">

                    <!-- <quill-editor v-model="grooming.comments" :options="quillOptions"  v-show="showNotes"  @change="onEditorChange($event)"></quill-editor> -->
                  </v-col>
                  <v-col cols="12" md="12"
                    style="display: flex; justify-content: flex-end;margin-top: 1.5rem; margin-bottom: 1rem;"></v-col>

                  <!--  <v-col v-if="selectedvalues"  cols="12" md="12" style="display: flex; justify-content: flex-end;margin-top: 1.5rem; margin-bottom: 1rem;" >
                      <v-btn v-if="selectedvalues" class="btnsave" @click.prevent.stop="addGrooming">
                          Agregar registro
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="12"  v-if="grooming.id_vpg" style="display: flex; justify-content: space-between; margin-top: 1.5rem; margin-bottom: 1rem;">
                      <v-btn class="btndelete"  @click.prevent="confirmDelete(todelete)">
                        Eliminar registro
                      </v-btn>
                      <v-btn class="btnsave" @click.prevent="updateGrooming" v-if="grooming.id_vpg">
                          Guardar registro
                        </v-btn>
                     </v-col>
                   -->
                </v-row>

              </v-form>
            </ValidationObserver>

          </v-col>
        </v-row>
        <br />

        <v-dialog v-model="showNotes" max-width="60%">
          <v-card style="padding: 14px;">
            <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
              <v-btn small class="btndelete" @click.prevent="showNotes = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <quill-editor v-model="grooming.comments" :options="quillOptions"
              @change="onEditorChange($event)"></quill-editor>
          </v-card>

        </v-dialog>

        <!-- Dialog bill patient -->
        <v-dialog v-model="showBillPatient" width="90%">
          <v-card class="py-3 px-3">
              <v-card-title class="text-h5">
              Facturar paciente 
              </v-card-title>

              <v-card-actions>
              <v-row>
                  <v-col cols="12" class="tabsNh" style="padding: 14px">
                  <v-col style="display: flex; justify-content: space-between; min-height: 50px">
                      <div>
                      <!-- <v-btn v-if="!showServicesToAdd" small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                          <v-icon>mdi-menu-down</v-icon>
                          Ver items
                      </v-btn>
                      <v-btn v-else small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                          <v-icon>mdi-menu-up</v-icon>
                          Ocultar
                      </v-btn> -->
                      </div>
                      <div>
                      <v-btn small class="btn-add" @click.prevent="saveItemsToBeBilled()">
                          <v-icon>mdi-content-save</v-icon>
                          Guardar
                      </v-btn>
                      </div>

                      <div>
                      <v-btn small class="btnsave" @click.prevent="billedPatientsOfCustomer()">
                          <v-icon>mdi-cash-register</v-icon>
                          Facturar cliente
                      </v-btn>
                      </div>
                  </v-col>
                  </v-col>
              </v-row>
              </v-card-actions>
              <div style="height: 500px; overflow-y: auto; overflow-x: hidden; margin-top: 20px; margin-bottom: 20px; padding: 0 20px;">
              <!-- Services default -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver servicios por defecto
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide1 = !togglehide1' v-show='!togglehide1' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide1 = !togglehide1' v-show='togglehide1' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide1">
                  <DxDataGrid class="isScrolledByGeneralFilter dataGridService" :data-source="servicesDefault" 
                  :show-borders="true" :column-auto-width="true" style="width: fit-content;">
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="CodeService" header-cell-template="headerCodeService" />
                      <template #headerCodeService="{ }">
                      <div>Código de<br />servicio</div>
                      </template>
                      <DxColumn data-field="NameService" header-cell-template="headerNameService" />
                      <template #headerNameService="{ }">
                      <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" header-cell-template="headerName" />
                      <template #headerName="{ }">
                      <div>Nombre a<br />facturar</div>
                      </template>
                      <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice" />
                      <template #headerTypeservice="{ }">
                      <div>Tipo de<br />servicio</div>
                      </template-->
                      <DxColumn :width="65" data-field="Price" caption="Precio" />
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""/>
                      <template #show-template="{ data }">
                      <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                          <v-icon>mdi-plus</v-icon>
                      </a>
                      </template>
                      <template #quantity-template="{ data }">
                      <p>{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService).quantity }}</p>
                      </template>
                  </DxDataGrid>
                  </v-col>
              </v-row>

              <!-- Services to select -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver todos los servicios
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide2 = !togglehide2' v-show='!togglehide2' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide2 = !togglehide2' v-show='togglehide2' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide2">
                  <GeneralFilter 
                      :filterEndpoint="$API.services.getAllServicesFiltered" 
                      :search="searchToBill"
                      @emptyFilter="getServices" 
                      @filtered="servicesFilterHandler"
                  >
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="servicesToBill"
                      key-expr="CodeService" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="CodeService" caption="ID"></DxColumn>
                      <DxColumn data-field="NameService" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice">
                      </DxColumn-->
                      <!--template #headerTypeservice="{ }">
                          <div>Tipo de<br />servicio</div>
                      </template-->
                      <DxColumn :width="65" data-field="Price" caption="Precio"></DxColumn>
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                          <v-icon>mdi-plus</v-icon>
                          </a>
                      </template>
                      <template #quantity-template="{ data }">
                          <p style="margin: 0;">{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService)?.quantity || '' }}</p>
                      </template>
                      </DxDataGrid>
                  </GeneralFilter>
                  </v-col>
              </v-row>

              <!-- Products to select -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver todos los productos
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide3 = !togglehide3' v-show='!togglehide3' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide3 = !togglehide3' v-show='togglehide3' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide3">
                  <GeneralFilter 
                      :filterEndpoint="$API.products.getProductsToBillForInvoiceFiltered" 
                      :search="searchToBill"
                      @emptyFilter="getProducts" 
                      @filtered="productsFilterHandler"
                  >
                      <v-row style="padding: 1rem;">
                          <v-col cols="12" md="3" sm="4">
                              <v-autocomplete
                              v-model="selectedWarehouse"
                              item-text="Warehouse"
                              item-value="ID_Warehouse"
                              :items="warehouses"
                              @change="handleSelectWarehouse"
                              label="Almacenes"
                              ref="werehouseRef"
                              dense 
                              outlined 
                              attach
                              >
                              </v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="9" sm="8">
                              <p style="color: #e3191e !important; font-size: 12px; margin: 12px 0" v-if="warehouses.length == 0">No existen almacenes de productos para pacientes.</p>
                          </v-col>
                      </v-row>
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="productsToBill"
                      key-expr="ID_Item" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="ID_Item" caption="ID"></DxColumn>
                      <DxColumn data-field="ItemName" header-cell-template="headerName"></DxColumn>
                      <template #headerName="{ }">
                          <div>Nombre del<br />producto</div>
                      </template>
                      <DxColumn data-field="ItemComercialName" header-cell-template="headerNameComercial"></DxColumn>
                      <template #headerNameComercial="{ }">
                          <div>Nombre<br />comercial</div>
                      </template>
                      <DxColumn :width="65" data-field="ItemPrice" caption="Precio"></DxColumn>
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="addProduct(data.data)">
                          <v-icon>mdi-plus</v-icon>
                          </a>
                      </template>
                      <template #quantity-template="{ data }">
                        <p style="margin: 0;">{{ productsToAdd.find(item => item.ID_Item == data.data.ID_Item)?.quantity || '' }}</p>
                      </template>
                      </DxDataGrid>
                  </GeneralFilter>
                  </v-col>
              </v-row>

              <!-- ServiceToAdd -->
              <v-row style="margin-top: 2rem">
                  <v-col cols="12">
                  <h4 style="padding: 16px;">Servicios seleccionados</h4>
                  <div style="overflow-x: scroll; padding: 0 12px;">
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="servicesToAdd"
                      key-expr="CodeService" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"    
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="5" />
                      <DxColumn data-field="CodeService" :allow-editing="false" caption="ID"></DxColumn>
                      <DxColumn data-field="NameService" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <DxColumn data-field="TypeService" :allow-editing="false" header-cell-template="headerTypeservice">
                      </DxColumn>
                      <template #headerTypeservice="{ }">
                          <div>Tipo de<br />servicio</div>
                      </template>
                      <DxColumn data-field="quantity" caption="Cantidad">
                          <DxFormat typed="fixedPoint" :precision="0" />
                          <DxRequiredRule message="La cantidad del servicio a facturar es requerida" />
                          <DxPatternRule :pattern="itemQtyPattern"
                          message="La cantidad del servicio facturado solo puede contener numeros enteros mayores a 0" />
                      </DxColumn>
                      <DxColumn data-field="Price" :allow-editing="true" caption="Precio"></DxColumn>
                      <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                      <DxColumn cell-template="del-template" caption=""></DxColumn>
                      <template #del-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="deleteService(data.data)">
                          <v-icon>mdi-delete</v-icon>
                          </a>
                      </template>
                      <template #total-template="{ data }">
                          <div>
                          {{ totalXQuantity( data.data ) }}
                          </div>
                      </template>
                      </DxDataGrid>
                  </div>
                  </v-col>
              </v-row>

              <!-- Product to add -->
              <v-row style="margin-top: 2rem">
                  <v-col cols="12">
                  <h4 style="padding: 16px;">Productos seleccionados</h4>
                  <div style="overflow-x: scroll; padding: 0 12px;">
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="productsToAdd"
                      key-expr="ID_Item" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"    
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="5" />
                      <DxColumn data-field="ID_Item" :allow-editing="false" caption="ID"></DxColumn>
                      <DxColumn data-field="ItemName" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre del<br />producto</div>
                      </template>
                      <DxColumn data-field="ItemComercialName" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <DxColumn data-field="ItemType" :allow-editing="false" header-cell-template="headerTypeservice">
                      </DxColumn>
                      <template #headerTypeservice="{ }">
                          <div>Tipo de<br />producto</div>
                      </template>
                      <DxColumn data-field="quantity" caption="Cantidad">
                          <DxFormat typed="fixedPoint" :precision="0" />
                          <DxRequiredRule message="La cantidad del producto a facturar es requerida" />
                          <DxPatternRule :pattern="itemQtyPattern"
                          message="La cantidad del producto facturado solo puede contener numeros enteros mayores a 0" />
                      </DxColumn>
                      <DxColumn data-field="ItemPrice" :allow-editing="true" caption="Precio"></DxColumn>
                      <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                      <DxColumn cell-template="del-template" caption=""></DxColumn>
                      <template #del-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="deleteProduct(data.data)">
                          <v-icon>mdi-delete</v-icon>
                          </a>
                      </template>
                      <template #total-template="{ data }">
                          <div>
                          {{ totalXQuantity( {...data.data, Price: data.data.ItemPrice} ) }}
                          </div>
                      </template>
                      </DxDataGrid>
                  </div>
                  </v-col>
              </v-row>

              </div>
          </v-card>
        </v-dialog>
        <!-- Dialog bill patient -->

        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
        </alerts>


        <!--  <v-dialog v-model="dialog" max-width="350">
        <v-card>
          <v-card-title class="text-h6">
            ¿Desea eliminar el registro?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialog = false">
              No
            </v-btn>
            <v-btn color="green darken-1" text @click="deleteGrooming(temp); dialog = false; temp = null">
              Si
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>   -->
        <Grommings :data="print"></Grommings>
      </v-card-text>
      <v-dialog v-model="showhistory" :width="'90%'" scrollable>
        <v-card class="pa-4">
          <v-card-text>
            <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
              <v-btn small class="btndelete" @click.prevent="showhistory = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>

            <GeneralFilter entity="peluquería" :filterEndpoint="$API.veterinarypatientgrooming.filterGroomingToPatient"
              :search="search" @emptyFilter="getAllPatientGrooming" @filtered="filterHandler">
              <DxDataGrid class="isScrolledByGeneralFilter" :show-borders="true" :data-source="groomings" key-expr="ID_VPG" @exporting="onExporting" :column-auto-width="true"
              style="width: fit-content;">
                <DxExport :enabled="true" />
                <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                <DxPaging :page-size="10" />
                <!-- <DxColumn data-field="ID_VPG" caption="Id"></DxColumn> -->
                <DxColumn data-field="Date" caption="Fecha" :min-width="100"></DxColumn>
                <DxColumn data-field="NextAppointment" caption="Proxima cita" :min-width="100"></DxColumn>
                <DxColumn data-field="TypeOfService" caption="Tipo de servicio" :min-width="200"></DxColumn>
                <DxColumn data-field="Groomer" caption="Peluquero" :min-width="200"></DxColumn>
                <DxColumn v-if="$store.getters['rolesUser/getCustomRole'](135)" :width="80" cell-template="show-delete" caption=""></DxColumn>
                <template #show-delete="{ data }">
                  <div>

                    <a href="" class="mx-2" @click.prevent.stop="confirmDelete(data.data); showhistory = false">
                      <v-icon color="error">mdi-delete</v-icon>
                    </a>
                  </div>
                </template>
                <template #show-template="{ data }">
                  <div>
                    <a href="#"
                      @click.prevent.stop="selectedGrooming(data.data); selectedvalues = false; showhistory = false">
                      <v-icon color="primary">mdi-eye</v-icon>
                    </a>
                  </div>
                </template>
              </DxDataGrid>
            </GeneralFilter>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>

  </div>
</template>

<script>

//import { jsPDF} from "jspdf"
//import html2canvas from 'html2canvas'
import Vue from "vue"
import Block from "@/components/Block"
import { DxDataGrid, DxColumn, DxPaging, DxScrolling, DxEditing, DxFormat, DxPatternRule, DxRequiredRule, DxExport } from "devextreme-vue/data-grid"
import alerts from "@/components/Alerts"
import { printToExcel } from "@/helpers/printToexcel"
import DatePicker from "../../../components/DatePicker"
import Grommings from "../../../components/ReportsAndPrintings/Grommings.vue"
import { quillConfig } from "../../../helpers/quillconfig"
import { mapState } from 'vuex'
import GeneralFilter from '@/components/GeneralFilter.vue'
//import Block from "@/components/Block";
import UserProfile from "@/components/Displays/UserProfileDisplay.vue";
import { numberWithCommas } from "@/helpers/money";



export default {
  name: "VeterinaryGrooming",
  components: { DxDataGrid, DxColumn, DxEditing, DxPaging, DxFormat, DxPatternRule, DxScrolling, DxRequiredRule, DatePicker, Block, alerts, Grommings, DxExport, GeneralFilter,UserProfile },
  props: ["data", "win"],
  data: () => ({
    print: {
      show: false,
      data: []
    },
    alert: {
      type: "success",
      show: false,
      header: "",
      body: "",
    },
    currency: JSON.parse(localStorage.getItem('branch')).Currency,
    itemQtyPattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/i,
    showBillPatient: false,
    useData: {},
    showServicesToAdd: false,
    showProductsToAdd: false,
    servicesToBill: [],
    productsToBill: [],
    servicesToAdd: [],
    productsToAdd: [],
    filterProducts: {
        filter: null
    },
    searchToBill: {
        filter: null
    },
    togglehide1: true,
    togglehide2: true,
    togglehide3: true,
    warehouses: [],
    selectedWarehouse: null,
    customer: {},
    showBgcolorNotes: "tabs",
    quillOptions: quillConfig,
    showNotes: false,
    groomingtodelete: null,
    valid: false,
    url: null,
    services: [
      { label: 'Baño', value: "Baño" },
      { label: 'Baño Medicado', value: "Baño Medicado" },
      { label: 'Corte', value: "Corte" },
      { label: 'Corte de uñas', value: "Corte de uñas" },
      { label: 'Corte y Baño', value: "Corte y Baño" },
      { label: 'Corte y Baño Medicado', value: "Corte y Baño Medicado" },
      { label: 'Vaciamiento de glándulas adanales', value: "Vaciamiento de glándulas adanales" },
      { label: 'Recorte de pelo de orejas', value: "Recorte de pelo de orejas" },
      { label: 'Lavado de dientes', value: "Lavado de dientes" },
    ],
    sizes: [
      { label: 'Grande', value: "Grande" },
      { label: 'Mediano', value: "Mediano" },
      { label: 'Pequeño', value: "Pequeño" },
    ],
    patientinfo: [],
    groomings: {},
    grooming: {},
    loading: false,
    id_user_login: null,
    dialog: false,
    temp: null,
    selectedvalues: true,
    showGroming: false,
    todelete: null,
    showhistory: false,
    //useData: null,
    search: {}
  }),
  computed: {
    ...mapState(
      "rolesUser",
      {
        rolesStatus: (state) => state.roles,
      })
  }
  ,
  async mounted() {
    this.$store.dispatch('rolesUser/getAllRoles')
    if (this.data) {
      Object.keys(this.data).map((el) => {
        Vue.set(this.patientinfo, el.toLowerCase(), this.data[el])
      })
      this.url =
        this.$store.getters.getBackendUrl +
        "/public" +
        this.data.ProfilePicPath
      this.getAllPatientGrooming()
    }
    Vue.set(this.grooming, "id_patient", this.patientinfo.id_patient)
    this.useData = JSON.parse(localStorage.getItem("user"))
    this.id_user_login = JSON.parse(localStorage.getItem("user")).id
    this.search.patientID = this.patientinfo.id_patient
    //this.useData = JSON.parse(localStorage.getItem("user"));
    this.customer.ID_Customer = this.data.ID_Customer;
    console.log('customer', this.customer);
    this.warehouses = await this.$API.warehouse.getWarehousesFiltered({ isPatient: true });
    this.filterProducts.business = this.useData.businessid;
    this.filterProducts.branch = this.useData.branch;
    this.selectedWarehouse = this.warehouses.length == 0 ? 0 : this.warehouses[0].ID_Warehouse;
    this.filterProducts.warehouse = this.selectedWarehouse;
    await this.getServices();
    await this.getProducts();
    await this.getDefaultServices();
  },
  watch: {
    servicesToAdd: {
        deep: true,
        immediate: true,
        handler() {
            if(this.servicesToAdd.length !== 0) {
                this.showServicesToAdd = true;
            }
            else {
                this.showServicesToAdd = false;
            }
        }
    },
    productsToAdd: {
        deep: true,
        immediate: true,
        handler() {
            if(this.productsToAdd.length !== 0) {
                this.showProductsToAdd = true;
            }
            else {
                this.showProductsToAdd = false;
            }
        }
    },
  },
  methods: {
    totalXQuantity( { Price, quantity } ) {
        return `${this.currency}${ numberWithCommas( parseFloat( Price * quantity ).toFixed( 2 ) ) }`
    },
    async handleSelectWarehouse() {
        this.loading = true;
        this.filterProducts.warehouse = this.selectedWarehouse;
        await this.getProducts();
        this.$refs.werehouseRef.blur();
        this.loading = false;
    },
    addService(data) {
        const isRepetitive = this.servicesToAdd.find((service) => service.ID_Service === data.ID_Service);

        if (!isRepetitive) {
            this.servicesToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.Price), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.Price).toFixed(4))}` });
        } else {
            const service = this.servicesToAdd.find(item => item.ID_Service === data.ID_Service)
            if(service) {
                service.quantity++;
            }
            console.log("Service already exists in servicesToAdd");
        }
    },
    addProduct(data) {
        const isRepetitive = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
        if (!isRepetitive) {
            this.productsToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.ItemPrice), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.ItemPrice).toFixed(4))}` });
        } else {
            const product = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
            if(product) {
                product.quantity++;
            }
            console.log("Product already exists in productsToAdd.");
        }
    },
    deleteService(data) {
        const serviceIndex = this.servicesToAdd.findIndex((service) => service.CodeService === data.CodeService);

        if (serviceIndex !== -1) {
            this.servicesToAdd.splice(serviceIndex, 1);
        }
    },
    deleteProduct(data) {
        const index = this.productsToAdd.findIndex((item) => item.ID_Item === data.ID_Item);

        if (index !== -1) {
            this.productsToAdd.splice(index, 1);
        }
    },
    getServices() {
        this.$API.services.getAllServices(this.filterServices).then((response) => {
            this.servicesToBill = response;
        });
    },
    getProducts() {
        this.$API.products.getProductsToBillForInvoice(this.filterProducts).then((response) => {
            this.productsToBill = response;
        });
    },
    getDefaultServices() {
        this.$API.services.getDefaultServices().then((response) => {
            this.servicesDefault = response;
        });
    },
    servicesFilterHandler(response) {
        this.servicesToBill = response;
    },
    productsFilterHandler(response) {
        this.productsToBill = response;
    },
    getItemsToBeBilled() {
        this.$API.veterinarypatients.getServicesToBeBilled({ 'customer': this.data.ID_Customer })
        .then((response) => {
            this.servicesToAdd = []
            for(let i = 0; i < response.length; i++){
            this.servicesToAdd.push({ ...response[i], unitPrice: `${this.currency}${numberWithCommas(parseFloat(response[i].Price).toFixed(4))}` });
            }
        });
        this.$API.veterinarypatients.getProductsToBeBilled({ 'customer': this.data.ID_Customer }).then((res) => {
            if(res.success) {
            this.productsToAdd = [];
            for (const item of res.data) {
                this.productsToAdd.push({ ...item, quantity: item.Quantity, unitPrice: `${this.currency}${numberWithCommas(parseFloat(item.ItemPrice).toFixed(4))}`});
            }
            }
        });
    },
    async saveItemsToBeBilled() {
        this.showBillPatient = false;
        this.loading = true;

        if (this.groomings.length > 0) {
          for (const i in this.groomings) {
            if (this.groomings[i].Date == this.date) {
              this.showAlert(
                "danger",
                "error",
                "Ya existe un registro para la fecha seleccionada"
              );

              return
            }
          }
        }

        try {
            if(this.servicesToAdd.length) {
            await this.$API.veterinarypatients.saveServicesToBeBilled({ 'customer': this.customer.ID_Customer, 'services': this.servicesToAdd })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                throw new Error('Ocurrio un error al guardar los servicios.');
            });
            }
            if(this.productsToAdd.length) {
            console.log(this.productsToAdd);
            
            await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                throw new Error('Ocurrio un error al guardar los productos.');
            });
            }
            await this.getItemsToBeBilled();
            this.loading = false;
            this.showAlert("success", "Exito", "Los datos de facturación de este paciente han sido guradados.")
        } catch (error) {
            console.log(error);
            this.loading = false;
            this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
        }

    },
    async billedPatientsOfCustomer() {
        this.showBillPatient = false;
        this.loading = true;

        try{
            await this.$API.veterinarypatients.saveServicesToBeBilled({'customer': this.customer.ID_Customer, 'services': this.servicesToAdd});
            await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd });
            await this.$API.veterinarypatients.billedPatientsOfCustomer({ 'customer': this.customer.ID_Customer});

            this.loading = false;

            this.showAlert("success", "Exito", `Se ha realizado la factura de los ${this.servicesToAdd.length && this.productsToAdd.length ? 'servicios y productos' : this.servicesToAdd.length ? 'servicios' : this.productsToAdd.length ? 'productos' : 'items' } seleccionados, podrá observarla en el módulo de POS en listo para facturar.`);
            await this.getItemsToBeBilled();        
        }
        catch(error) {
            console.log(error)
            this.loading = false
            this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
        }
    },

    onExporting(e) {
      printToExcel(e, "Peluqueria")
    },
    checktodaygroming(date) {
      /*let datetoday = false
      this.groomings.forEach(el => {
        if (el.Date == date) {
          this.selectedGrooming(el)
          datetoday = true
        }
      })
      if (!datetoday) {
        this.resetGrooming()
        this.grooming.date = date
      }*/
      this.grooming.date = date

    },

    getAllPatientGrooming() {
      this.$API.veterinarypatientgrooming
        .getGroomingToPatientHistory(this.patientinfo.id_patient)
        .then((response) => {
          this.groomings = response
          let datetoday = this.moment().format("DD/MM/YYYY")
          this.groomings.forEach(element => {

            if (element.Date == datetoday) {
              this.selectedGrooming(element)
            }

          })
        })
    },

    selectedGrooming(data) {
      this.todelete = data
      Object.keys(data).map((el) => {
        Vue.set(this.grooming, el.toLowerCase(), data[el])
      })
      this.selectedvalues = false
    },

    Preview_image() {
      this.url = URL.createObjectURL(this.image)
    },

    onEditorChange(e) {
      this.grooming.comment = e.html
      if (this.grooming.comment != "") {
        this.showBgcolorNotes = 'tabsContent'
      }
      else if (this.grooming.comment == "" || this.grooming.comment == "<p><br></p>") {
        this.showBgcolorNotes = 'tabs'
      }
      else {
        this.showBgcolorNotes = 'tabs'
      }
    },

    async addGrooming() {
      this.$refs.observer.validate()
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.loading = true
        let datetoday = false
        this.groomings.forEach(element => {
          if (element.Date == this.grooming.date) {
            this.showAlert("danger", "error", "Ya existe un registro para la fecha seleccionada")

            datetoday = true
          }
        })
        if (!datetoday) {
          this.loading = false

          const formdata = new FormData()
          Vue.set(this.grooming, "id_user_insert", this.id_user_login)
          formdata.append("veterinarygrooming", JSON.stringify(this.grooming))


          this.$API.veterinarypatientgrooming
            .addGroomingToPatient(formdata)
            .then(() => {
              this.loading = false
              this.showAlert("success", "Exito", "Registro agregado correctamente")
              this.resetGrooming()
              //this.$store.dispatch('reloadData', 'veterinarypatients')
              this.getAllPatientGrooming()
            })
            .catch(() => {
              this.loading = false
              this.showAlert("danger", "error", "Error al agregar el registro")
            })
          return
        }
        else {
          this.loading = false
        }
      }
    },

    async updateGrooming() {
      this.$refs.observer.validate()
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.loading = true
        const formdata = new FormData()
        Vue.set(this.grooming, "id_user_update", this.id_user_login)
        formdata.append("veterinarygrooming", JSON.stringify(this.grooming))
        this.$API.veterinarypatientgrooming
          .updateGroomingToPatient(this.grooming.id_vpg, formdata)
          .then(() => {
            this.loading = false
            // this.$emit('reloadData');
            this.showAlert("success", "Exito", "Registro actualizado correctamente")
            this.resetGrooming()
            //this.$store.dispatch('reloadData', 'veterinarypatients')
            this.getAllPatientGrooming()
          })
          .catch(() => {
            this.loading = false
            this.showAlert("danger", "error", "Error al actualizar el registro")
          })
      }
    },

    deleteGrooming(data) {
      this.loading = true
      this.grooming.id_vpg = data.ID_VPG
      this.$API.veterinarypatientgrooming
        .deleteGroomingToPatient(this.grooming.id_vpg)
        .then(() => {
          this.loading = false
          this.showAlert("success", "Exito", "Registro eliminado correctamente")
          this.resetGrooming()
          this.getAllPatientGrooming()
          //this.$store.dispatch('deleteWindowByUuid', {uuid: this.win.uuid});
          //this.$store.dispatch('reloadData', 'veterinarypatients')
        })
        .catch(() => {
          this.loading = false
          this.showAlert("danger", "error", "Error al eliminar el registro")
        })
    },

    resetGrooming() {
      this.grooming.id_vpg = ''
      this.grooming = {}
      this.grooming.date = this.moment().format("DD/MM/YYYY")
      Vue.set(this.grooming, "id_patient", this.patientinfo.id_patient)
      this.$refs.observer.reset()
      this.selectedvalues = true
      this.todelete = null
    },
    closeAlert() {
      this.alert.show = false
      this.groomingtodelete = null


    },
    acceptAlert() {
      this.alert.show = false

      if (this.alert.options == "deletegrowing") {
        this.deleteGrooming(this.groomingtodelete)
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type
      this.alert.type = type
      this.alert.header = header
      this.alert.body = body
      this.alert.show = true
      this.alert.options = options != null ? options : null
    },
    confirmDelete(data) {
      this.showAlert("question", "Confirmar", "¿Esta seguro que desea eliminar el registro seleccionado?", "deletegrowing")
      this.groomingtodelete = data
    },
    filterHandler(response){
      this.groomings = response
    }

    /*printGrooming(){
      const doc = new jsPDF();
      doc.html("<html><body><p>Hola</p></body></html>"
      , {
        callback: function (doc) {
          doc.save();
        }
      });
    }*/
  },
}
</script>


<style scoped>
.btndisable {
    background-color: #41464c !important;
    color: white;
}
.btndisable2 {
    background-color: #1976d2 !important;
    color: white;
}

.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;

  border-radius: 15px;


}

.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;

}

.infopatient {
  margin-top: 5%;
}

.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}

.tabsContent:hover {
  background-color: #FFB266;
  cursor: pointer;
}

.tabs {

  background-color: rgba(60, 60, 60, 1) !important;
  color: white !important;


}

.tabsContent {

  background-color: #F29D35 !important;
  color: white !important;



}

.btn-add {
  background-color: #3498DB !important;
  color: white;
}

.btnsave {
  background-color: #00A178 !important;
  color: white;
  margin-left: .3rem;
  margin-right: .3rem;
}

.btnblue {
  background-color: #1976d2 !important;
  color: white;
}


.tabsB {
  width: 100%;
  padding: 10px;
  background-color: #1976d2;
  color: white;
  border-radius: 15px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
}

.tabsB:hover {

  cursor: pointer;
}

.btnclean {
  background-color: #F29D35 !important;
  color: white;


}

.btndelete {
  background-color: #E3191E !important;
  color: white;
}</style>
