import axios from "./axios";

export default {
    getAllAccountingExpenses() {
        const options = {
            method: 'GET',
            url: '/accountingexpenses',
            headers: { 'content-type': 'application/json' },
        };
        console.log(options)
        return axios(options);
    },

    getAllAccountingExpensesFiltered(filter) {
        const options = {
            method: 'GET',
            url: '/accountingexpenses/list/filtered',
            headers: { 'content-type': 'application/json' },
            params: filter
        };
        console.log(options)
        return axios(options);
    },

    saveAccountingExpenses(data) {
        const options = {
            method: 'POST',
            url: '/accountingexpenses',
            headers: { 'content-type': 'application/json' },
            data: data,
        };
        return axios(options);
    },

    updateAccountingExpenses(data) {
        const options = {
            method: 'PUT',
            url: '/accountingexpenses',
            headers: { 'content-type': 'application/json' },
            data: data,
        };
        return axios(options);
    },

    deleteAccountingExpenses(data) {
        const options = {
            method: 'DELETE',
            url: '/accountingexpenses',
            headers: { 'content-type': 'application/json' },
            data: data,
        };
        return axios(options);
    },

    getAllProvidersExpenses() {
        const options = {
            method: 'GET',
            url: '/accountingexpenses/provider',
            headers: { 'content-type': 'application/json' },
        };
        console.log(options)
        return axios(options);
    },

    getAllProvidersFiltered(filter) {
        const options = {
            method: 'GET',
            url: '/accountingexpenses/provider/list/filtered',
            headers: { 'content-type': 'application/json' },
            params: filter
        };
        console.log(options)
        return axios(options);
    },

    saveProvidersExpenses(data) {
        const options = {
            method: 'POST',
            url: '/accountingexpenses/provider',
            headers: { 'content-type': 'application/json' },
            data: data,
        };
        return axios(options);
    },

    updateProvidersExpenses(data) {
        const options = {
            method: 'PUT',
            url: '/accountingexpenses/provider',
            headers: { 'content-type': 'application/json' },
            data: data,
        };
        return axios(options);
    },

    deleteProvidersExpenses(data) {
        const options = {
            method: 'DELETE',
            url: '/accountingexpenses/provider',
            headers: { 'content-type': 'application/json' },
            data: data,
        };
        return axios(options);
    },
};

