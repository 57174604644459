<template>
    <v-container class="white pa-10 container-size" style="max-height: inherit;overflow-y: scroll;">
        <v-row class="bg-dark text-h6 pa-2 rounded-lg">
            <v-col v-if="!isTransferApplication" cols="12" md="3" align="center">
                <span class="font-weight-bold">ID Transferencia: </span>{{ transferData.data.ID_Transfer }}
            </v-col>
            <v-col v-else cols="12" md="3" align="center">
                <span class="font-weight-bold">ID Solicitud:</span> {{ transferData.data.ID_Request }}
            </v-col>
            <v-col cols="12" md="3" align="center">
                <span class="font-weight-bold">Almacen Origen:</span> 
                <div>
                    {{ transferData.data.OriginWarehouse }}
                </div>
            </v-col>
            <v-col cols="12" md="3" align="center">
                <span class="font-weight-bold"> Destino:</span> 
                <div>
                    {{ transferData.data.DestinyWarehouse }}
                </div>
            </v-col>
            <v-col cols="12" md="3" align="center">
                <span class="font-weight-bold">Usuario Solicita:</span> 
                <div>
                    {{ transferData.data.userName }}
                </div>
            </v-col>
        </v-row>
        <!-- <v-row class="bg-dark my-6 pa-2 text-h7 rounded-lg">
            <v-col cols="12" md="4">
                <span class="font-weight-bold">Comentario:</span> {{ transferData.data.Comments }}
            </v-col>
        </v-row> -->
        <v-row cols="12">
            <v-col cols="12" md="12" class="my-6 pa-2 text-h7">
                <h3 :class="transferData.data.Comments ? 'tabsOContent' :'tabsContent'" @click.prevent="showNotes = !showNotes" style="text-align: center;"> <v-icon
                        class="icon-color">mdi-comment</v-icon> Comentarios

                </h3>
            </v-col>
        </v-row>
        <v-row align="end d-flex justify-space-between">
            <!-- If it's a transfer request -->
            <template v-if="isTransferApplication">
                <v-col cols="6" md="3">
                    <v-btn v-if="checkTransferStatus && $store.getters['rolesUser/getCustomRole'](117)" class="btn-traspaso white--text" @click="showAddProducts()">
                        <v-icon class="mr-2">mdi-archive-plus</v-icon>Hacer traspaso
                    </v-btn>
                </v-col>
                <v-col cols="3" md="2">
                    <v-row>
                       
                        <v-col cols="12" md="6">
                            <span class="font-weight-bold">Fecha Solicitud:</span> {{ transferData.data.RequestDate }}
                        </v-col>
                        <v-col cols="12" md="6">
                            <span class="font-weight-bold">Fecha Aprovación:</span> {{ transferData.data.DateTransfer ? transferData.data.DateTransfer : '' }}
                        </v-col>
                    </v-row>
                </v-col>
            </template>
            <!-- It's is a normal transfer -->
            <v-col v-else md="12" class="show-date">
                <div class="float-right">
                    <span class="font-weight-bold">Fecha Aprovación:</span> {{ transferData.data.TransferDate }}
                </div>
            </v-col>
        </v-row>
            <!-- Tabla -->
            <GeneralFilter class="background border-box" entity="transferencia"
                :filterEndpoint="filterTransferProduct" :search="search" @emptyFilter="getProductsToShow"
                @filtered="filterHandler">
                <DxDataGrid ref="dxDataGridDetails"  :data-source="transferData.data.details" key="ID_Transfer" :show-row-line="ShowRowLines"
                    :show-border="showBorders" :row-alternation-enabled="rowAlternationEnabled" @exporting="onExporting" :scrolling="{useNative:true}"
                    width="100%">
                    <DxExport :enabled="true" />
                    <DxPaging :page-size="10" />
                    <DxColumn data-field="ID_Item" caption="ID Producto" :width="columnWidth" ></DxColumn>
                    <DxColumn data-field="ItemName" caption="Nombre" :width="columnWidth" ></DxColumn>
                    <DxColumn data-field="CantidadSolicitada" caption="Cantidad Solicitada" :width="columnWidth" ></DxColumn>
                    <DxColumn data-field="StockOrigenResultante" caption="Nueva existencia (Origen)" :width="columnWidth" ></DxColumn>
                    <DxColumn data-field="StockDestinoResultante" caption="Nueva existencia (Destino)" :width="columnWidth" ></DxColumn>
                </DxDataGrid>
            </GeneralFilter>
            <!-- Tabla -->
        <v-dialog width="80%" v-model="showNotes">
            <v-card class="pa-4">
                <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
                    <v-btn small class="btndelete" @click.prevent="showNotes = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
                <!-- <quill-editor :content="form.notes" :options="quillOptions"></quill-editor> -->
                <quill-editor :content="transferData.data.Comments" :options="quillOptions" disabled></quill-editor>
            </v-card>
        </v-dialog>
        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
        </alerts>
    </v-container>
</template>

<script>
import { DxDataGrid, DxExport, DxColumn, DxPaging } from 'devextreme-vue/data-grid'
import { printToExcel } from '../../helpers/printToexcel'
import { quillConfig } from "../../helpers/quillconfig"
import Alerts from "@/components/Alerts"
import { mapState, mapMutations } from 'vuex'
import GeneralFilter from '@/components/GeneralFilter.vue'

export default {
    name: "TransferDetails",
    data() {
        return {
            ShowRowLines: true,
            showBorders: true,
            rowAlternationEnabled: true,
            showNotes: false,
            quillOptions: quillConfig,
            alert: {
                type: 'success',
                show: false,
                header: '',
                body: '',
            },
            hover: false,
            search: {
                filter: null
            }
        }
    },
    components: {
        DxDataGrid,
        DxPaging,
        DxColumn,
        DxExport,
        Alerts,
        GeneralFilter
    },

    props: {
        transferData: {
            type: Object
        },
        isTransferApplication: {
            type: Boolean,
            default: false
        },
    },

    mounted(){
        this.saveTransferData()
        this.getExtraInfoTransfer()
    },

    computed: {
        columnWidth() {
    
            return this.$vuetify.breakpoint.smAndDown ? '150px' : 'auto !important';
        },
        checkTransferStatus() {
            // Status of the order
            // Pendiente == 0
            // Realizado == 1
            // No disponible == 2
            return this.transferData.data.RequestStatus == 0 || this.transferData.data.RequestStatus == 2
        },
        ...mapState(
            "rolesUser",
            {
                rolesStatus: (state) => state.roles,
            })
        ,
    },

    methods: {
        ...mapMutations({
            setReload: 'transferData/setReload'
        }),
        onExporting(e) {
            const title = this.isTransferApplication ? 'Solicitud de transferencia de inventario' : 'Transferencia de inventario'
            const id = this.isTransferApplication ? this.transferData.data.ID_Request : this.transferData.data.ID_Transfer
            printToExcel(e, `Productos de ${title} ID ${id}`)
        },
        showAddProducts() {
            if (this.searchForNonStockOnDetails()) {
                this.setTransferStatus(this.transferData.data.details, this.transferData.data.RequestStatus, this.transferData.data.ID_Request)
                this.showAlert(
                    'danger',
                    'Error',
                    'Uno o mas elementos de esta solictud no tiene el Stock suficiente para realizar el traspaso'
                )
                return
            }
            this.$store.dispatch('resetWindow').then(() => {
                this.$store.dispatch('addWindow', {
                    name: 'AddTransfer',
                    component: 'AddTransfer',
                    unique: true,

                })
            })
            this.setReload(true)
            this.sendDataToAddTransfer()
            this.reloadTable()
            this.closeModal()
        },
        closeModal() {
            this.$emit('close-modal')
        },
        reloadTable() {
            this.$emit('reload-table')
        },
        sendDataToAddTransfer() {
            this.transferData.data.tab = 'request'
            this.$store.commit('transferData/setTransfers', this.transferData.data)
        },
        searchForNonStockOnDetails() {
            for (const element of this.transferData.data.details) {
                const { StockOrigenResultante } = element
                if (StockOrigenResultante < 0) {
                    return true
                }
            }
            return false
        },
        showAlert(type, header, body, options = null) {
            type = type == null ? 'danger' : type
            this.alert.type = type
            this.alert.header = header
            this.alert.body = body
            this.alert.show = true
            this.alert.options = options != null ? options : null
        },
        closeAlert() {
            this.alert.show = false
        },
        updateTransferStatus(data) {
            this.$API.inventoryTransfer
                .updateTransferStatusRequest(data)
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        setTransferStatus(details, requestStatus, ID_Request) {
            let RequestStatus = requestStatus
            details.forEach(object => {
                // 1 = Realizado
                if (requestStatus === 1) return
                // 0 = Pendiente, 2 = No disponible
                if (requestStatus === 0 || requestStatus === 2) {
                    if (object.StockOrigenResultante <= 0) {
                        // 2 = No disponible
                        RequestStatus = 2
                        this.setTransferStatusData(RequestStatus, ID_Request)
                        return
                    }
                    if (object.StockOrigenResultante >= 0) {
                        // 0 = Pendiente
                        RequestStatus = 0
                        this.setTransferStatusData(RequestStatus, ID_Request)
                        return
                    }
                }
            })
            this.reloadTable()
            return RequestStatus
        },
        setTransferStatusData(RequestStatus, ID_Request) {

            const transferStatusData = {
                ID_Request,
                RequestStatus
            }

            this.updateTransferStatus(transferStatusData)
        },
        filterTransferProduct({ filter, products }) {
            let filteredProducts = products.filter((product) =>
                product.ID_Item.toString().includes(filter) ||
                product.ItemName.includes(filter) ||
                product.CantidadSolicitada.toString().includes(filter)
            )

            return new Promise((resolve) => {
                resolve(filteredProducts)
            })
        },
        saveTransferData() {
            this.transferDataFilter = JSON.parse(JSON.stringify(this.transferData.data.details))
            this.search = {
                products: JSON.parse(JSON.stringify(this.transferData.data.details))
            }
        },
        getProductsToShow() {
            this.transferData.data.details = JSON.parse(JSON.stringify(this.transferDataFilter))
        },
        filterHandler(response) {
            this.transferData.data.details = response
        },
        async getExtraInfoTransfer() {
            for (let element of this.transferData.data.details) {
                let result = await this.$API.inventoryTransfer.infoProductAtDestination({
                                warehouse: this.transferData.data.DestinyID_Warehouse,
                                id_itembatch: element.ID_ItemBatch,
                                id_item: element.ID_Item
                            });
                element.WarehouseName = this.transferData.data.OriginWarehouse                
                element.warehouseAtDestination = this.transferData.data.DestinyWarehouse
                element.ItemCurrentQuantityAtDestination = result.ItemCurrentQuantity || 0              
                element.StockResultAtDestination = result.ItemCurrentQuantity || 0

                if(this.checkTransferStatus) {
                    element.StockDestinoResultante = result.ItemCurrentQuantity + element.CantidadSolicitada
                }
                else {
                    if(this.isTransferApplication) {
                        element.StockOrigenResultante = element.StockOrigenResultante + element.CantidadSolicitada
                    }
                    
                    element.StockDestinoResultante = result.ItemCurrentQuantity
                }
                    
            }

            this.$refs['dxDataGridDetails'].instance.refresh();
        },
        calculateCellValue(data) {
            if(data.StockDestinoResultante) {
                return data.StockDestinoResultante
            }
            else {
                return 0
            }
        }
    },

}
</script>

<style>
.container-size {
    min-width: 100% !important;
}

.bg-dark {
    background-color: #41464c;
    color: white;
}

.btn-traspaso {
    background-color: #3498db !important;
    color: white;
}

.tabsContent {
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;

    border-radius: 10px;

}

.tabsOContent {
    padding: 10px;
    background-color: #F29D35 !important;
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;

    border-radius: 10px;

}

.icon-color {
    color: white !important
}

.red-text {
    color: #CC3333 !important;
}
</style>