import axios from "./axios";

export default {
    getAllBarCodes() {
        const options = {
            method: 'GET',
            url: '/inventoryitemsbarcodes',
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    
    getBarCodeById(id, pricelist, warehouse) {
        const options = {
            method: 'GET',
            url: `/inventoryitemsbarcodes/${id}&${pricelist}&${warehouse}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    console.log(response)
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message);
                })
        })
    },

    getBarCodeProvidersById(data) {
        const options = {
            method: 'GET',
            url: `/inventoryitemsbarcodes/list/providersByID`,
            headers: { 'content-type': 'application/json' },
                        params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    console.log(response)
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message);
                })
        })
    },

    getAllBarCodesPOS() {
        const options = {
            method: 'GET',
            url: '/inventoryitemsbarcodes/list/pos',
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getAllBarCodesProviders() {
        const options = {
            method: 'GET',
            url: '/inventoryitemsbarcodes/list/providers',
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getAllBarCodesHistory(ID_Item) {
        const options = {
            method: 'GET',
            url: '/inventoryitemsbarcodes/list/history',
            headers: { 'content-type': 'application/json' },
            params: { ID_Item: ID_Item }
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getAllBarCodesFiltered(data) {
        const options = {
            method: 'GET',
            url: '/inventoryitemsbarcodes/list/filtered',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    saveBarCode(data) {
        const options = {
            method: 'POST',
            url: '/inventoryitemsbarcodes',
            headers: { 'content-type': 'application/json' },
            data: data,
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    updateBarCode(data) {
        const options = {
            method: 'PUT',
            url: '/inventoryitemsbarcodes',
            headers: { 'content-type': 'application/json' },
            data: data,
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deleteBarCode(data) {
        const options = {
            method: 'DELETE',
            url: '/inventoryitemsbarcodes',
            headers: { 'content-type': 'application/json' },
            data: data,
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    checkBarCodeExists(data) {
        const options = {
            method: 'GET',
            url: '/inventoryitemsbarcodes/check/barcode',
            headers: { 'content-type': 'application/json' },
            params: data,
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    checkArrayBarCodesExists(data) {
        const options = {
            method: 'POST',
            url: '/inventoryitemsbarcodes/check/arrayBarcodes',
            headers: { 'content-type': 'application/json' },
            data: data,
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getBarCodesByProduct(data) {
        const options = {
            method: 'GET',
            url: '/inventoryitemsbarcodes/list/product',
            headers: { 'content-type': 'application/json' },
            params: data,
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    updatePrincipalBarCode(data) {
        const options = {
            method: 'POST',
            url: '/inventoryitemsbarcodes/updatePrincipalBarCode',
            headers: { 'content-type': 'application/json' },
            data: data,
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getInventoryStockAllProviders() {
        const options = {
            method: 'GET',
            url: '/inventoryitemsbarcodes/stock',
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getInventoryStockByProvider(ID_Provider) {
        console.log(ID_Provider);
        const options = {
            method: 'GET',
            url: `/inventoryitemsbarcodes/stock/${ID_Provider}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    }, 

};
