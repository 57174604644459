<template>
  <v-overlay :value="value" color="white">
    <v-img src="/images/Iphone-spinner-2.gif" alt="Loading"></v-img>
  </v-overlay>
</template>

<script>
export default {
  name: "Block",
  props: {
    value: {
      type: Boolean,
      /**
       * Temporalmente true hasta que se cambie toda la implementación en la
       * aplicación, posterior a esto cambiar a false */
      default: true,
    },
  },
};
</script>
