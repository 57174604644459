import axios from "./axios";

export default {

    loadMedicalHistory(data) {
        const options = {
            method: 'POST',
            url: `medicalhistory/loadmedicalhistory`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    filterloadMedicalHistory(data) {
        const options = {
            method: 'POST',
            url: `medicalhistory/filterloadmedicalhistory`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    saveMedicalHistory(data) {
        const options = {
            method: 'POST',
            url: `medicalhistory/savemedicalhistory`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    updateMedicalHistory(data) {
        const options = {
            method: 'POST',
            url: `medicalhistory/updatemedicalhistory`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deleteMedicalHistory(data) {
        const options = {
            method: 'POST',
            url: `medicalhistory/deletemedicalhistory`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getVaccinesHistory(data) {
        const options = {
            method: 'POST',
            url: `medicalhistory/getvaccines`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },


    getMedicationHistory(data) {
        const options = {
            method: 'POST',
            url: `medicalhistory/getmedication`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getLaboratoryTestHistory(data) {
        const options = {
            method: 'POST',
            url: `medicalhistory/getlaboratorytest`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    
    

    getSurgeryHistory(data) {
        const options = {
            method: 'POST',
            url: `medicalhistory/getsurgery`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getImagesHistory(data) {
        const options = {
            method: 'POST',
            url: `medicalhistory/getimages`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getPrescriptionHistory(data) {
        const options = {
            method: 'POST',
            url: `medicalhistory/getprescription`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    }

    
    
}