<template>
    <v-card>
        <v-card-title class="text-h5">
            Are you sure you want to delete {{data.UserName}} ?
        </v-card-title>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="deleteUser">
                Yes
            </v-btn>
            <v-btn color="green darken-1" text @click="$emit('close', {isDeleted: false})">
                No
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "DeleteUserDialog",
        props: ['data'],
        methods: {
            deleteUser() {
                this.$API.users.deleteUser(this.data.ID_User)
                    .then(() => {
                        this.$emit('close', {isDeleted: true})
                    })
            }
        }
    }
</script>

<style scoped>

</style>