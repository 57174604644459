export function addFormatToNIT(nit) {
    nit = nit.toString().replaceAll('-', ''); //Agregado por Juan 13082023
    if (nit.length !== 14 || isNaN(nit)){
        return nit
    }
    else{
        const group1 = nit.slice(0, 4);
        const group2 = nit.slice(4, 10);
        const group3 = nit.slice(10, 13);
        const group4 = nit.slice(13);

        const nitFormat = `${group1}-${group2}-${group3}-${group4}`;
        return nitFormat;
    }
}

export function addFormatNRC(nrc){
    nrc = nrc?.toString().replaceAll('-', ''); //Agregado por Juan 13082023
    /*if (nrc.length !== 7 || isNaN(nrc)){
        return nrc
    }*/
    if (isNaN(nrc)){
        return nrc
    }
    else{
        const group1 = nrc.slice(0,nrc.length-1); //Agregado por Juan 13082023
        const group2 = nrc.slice(-1); //Agregado por Juan 13082023

        const nrcFormat = `${group1}-${group2}`;
        return nrcFormat;
    }
}

export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}