<template>
  <div v-if="open">
    <link src="https://cdn.jsdelivr.net/npm/vuetify@3.1.5/dist/vuetify.min.css" rel="stylesheet" >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'window-portal',
  props: {
    open: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      windowRef: null,
    }
  },
  head: {
    link: [{ rel: 'stylesheet', href: 'https://cdn.jsdelivr.net/npm/vuetify@3.1.5/dist/vuetify.min.css' }]
  },
  watch: {
    open(newOpen) {
      if(newOpen) {
        this.openPortal();
      } else {
        this.closePortal();
      }
    }
  },
  methods: {
    openPortal() {
      
      this.windowRef = window.open("", "", "resizable=no, width="+window.innerWidth+", height="+window.innerHeight);
      
      this.windowRef.addEventListener('beforeunload', this.closePortal);
      // magic!
      //link.setAttribute("src", "https://cdn.jsdelivr.net/npm/vuetify@3.1.5/dist/vuetify.min.css")
      //this.windowRef.document.head.apprenChild(link);
      this.windowRef.document.body.appendChild(this.$el);
      //var link = document.createElement("link")
      
    },
    closePortal() {
      if(this.windowRef) {
        this.windowRef.close();
        this.windowRef = null;
        this.$emit('close');
      }
    }
  },
  mounted() {
    if(this.open) {
      this.openPortal();
    }
  },
  beforeDestroy() {
    if (this.windowRef) {
      this.closePortal();
    }
  }
}
</script>

<style scoped>
    * {
        font-family: Arial, Helvetica, sans-serif !important;
    }

</style>