import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#3498DB',
        error: '#E3191E',
        warning: '#F29D35',
        comment: '#41464C',
        save: '#00A178'
      },
      dark: {
        primary: '#3498DB',
        error: '#E3191E',
        warning: '#F29D35',
        comment: '#41464C',
        save: '#00A178'
      },
    },
  },
});
