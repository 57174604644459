var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"accountscatalogchooser",staticClass:"pa-5"},[(_vm.loading)?_c('block'):_vm._e(),_vm._m(0),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"12"}}),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"btn-add",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.SendTobussines.apply(null, arguments)}}},[_vm._v(" Gestión de Catálogo ")])],1)],1),_c('div',[_c('GeneralFilter',{attrs:{"entity":"lista","filterEndpoint":_vm.$API.branchaccountcatalog.getActiveFilterBranchAccountCatalog,"search":_vm.search},on:{"emptyFilter":_vm.getAllAcounts,"filtered":_vm.filterHandler}},[_c('div',[_c('DxDataGrid',{ref:"treeList",attrs:{"key-expr":"id","scrolling":{ useNative: true },"data-source":_vm.AcountList,"show-row-lines":_vm.showRowLines,"show-borders":_vm.showBorders,"column-auto-width":true,"word-wrap-enabled":true,"focused-row-enabled":true},on:{"exporting":_vm.onExporting,"focused-row-changed":_vm.onSelectionChanged},scopedSlots:_vm._u([{key:"send-template",fn:function({ data }){return [_c('div',{staticClass:"d-flex justify-content-center align-items-center text-center"},[(data.data.SelectedItem)?_c('v-btn',{staticClass:"btnsave",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.sendSelection(data.data)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" > mdi-plus ")])],1):_vm._e()],1)]}},{key:"add-template",fn:function({ data }){return [_c('div',[_c('a',{staticClass:"icon-size text-black",class:data.data.account_code.length === 2
                ? 'font-weight-bold'
                : 'font-weight-regular'},[_vm._v(" "+_vm._s(data.data.account_code)+" ")])])]}}])},[_c('DxPaging',{attrs:{"enabled":true,"page-size":10}}),_c('DxColumn',{attrs:{"caption":"Código","cell-template":"add-template","width":300}}),_c('DxColumn',{attrs:{"data-field":"account_description","caption":"Nombre","width":300}}),_c('DxColumn',{attrs:{"data-field":"SelectedItem","caption":"Mov","width":100}}),(
            _vm.rolesStatus.findIndex(
              (role) => role.RoleName == 'admin'
            ) > -1 ||
            _vm.rolesStatus.findIndex((role) => role.RoleName == 'root') >
              -1 ||
            _vm.rolesStatus.findIndex((role) => role.ID_Role == 47) > -1
          )?_c('DxColumn',{attrs:{"cell-template":"send-template","caption":"Acciones","width":160}}):_vm._e()],1)],1)])],1),_c('v-dialog',{attrs:{"max-width":"450"},model:{value:(_vm.showModel),callback:function ($$v) {_vm.showModel=$$v},expression:"showModel"}}),(_vm.alert.show)?_c('alerts',{attrs:{"properties":_vm.alert},on:{"close_alert":_vm.closeAlert,"accept_alert":_vm.acceptAlert}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-wrap align-self-center justify-space-between mb-3"},[_c('h1',[_c('b',[_vm._v("Catalogo de cuentas")])])])
}]

export { render, staticRenderFns }