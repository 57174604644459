import axios from "./axios";

export default {
    /**
     * Add Patient
     * @api {POST} /business
     */
    getBranchAccountsConfig() {
        const options = {
            method: 'GET',
            url: '/branchaccountconfig',
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    UpdateBranchAccountsConfig(data) {
        const options = {
            method: 'PUT',
            url: '/branchaccountconfig',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    
}