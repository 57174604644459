<template>
    <v-card flat class="mx-0" >
        <v-card-text class="pa-0">
            <v-card class="white border-box ">
                <div class="my-3 " >
                    <v-row  class="ml-2 mx-2">
                                <v-col class="my-6 " cols="12" sm="12" md="6" lg="3">
                                    <date-picker label="Desde"
                                                 @dateSelected="(param) => (filterProducts.start = param)"
                                    ></date-picker>
                                </v-col>
                                <v-col class="my-6" cols="12" sm="12" md="6" lg="3">
                                    <date-picker label="Hasta"
                                                 @dateSelected="(param) => (filterProducts.end = param)"
                                    ></date-picker>
                                </v-col>
                                <v-col class="my-6" cols="12" sm="12" md="4" lg="2">
                                    <v-autocomplete outlined dense 
                                        v-model="filterProducts.warehouse"
                                        :items="warehouses"
                                        item-text="Warehouse"
                                       
                                        item-value="ID_Warehouse"
                                        label="Nombre de Almacen"
                                        attach
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="my-6 d-flex " cols="12" sm="12" md="4" lg="2">
                                    <v-btn @click="setClickedTrue(); getKardex()" class="btn-add">Buscar</v-btn>
                                
                                    <v-btn  @click="clean" class="btnclean ml-1">Limpiar</v-btn>    
                                </v-col>

                            </v-row>
                </div>
                
                <GeneralFilter ref="generalFilter" :search="{}" :filterEndpoint="filterPromise" @filtered="filterHandler" @emptyFilter="clearFilter">
                    <DxDataGrid :scrolling="{useNative:true}" :data-source="!isFiltered ? products : productsListFiltered" @exporting="onExporting" >
                        <DxExport :enabled="true" />
                        <!-- <DxColumn data-field="Pk_inventory_in_out" caption="PK_inventory"></DxColumn> -->
                        <DxColumn :width="60" cell-template="show-template" caption=""></DxColumn>
                        <DxColumn :width="columnWidth" data-field="SystemDateInsert"  caption="Fecha"></DxColumn>
                        <DxColumn :width="columnWidth" data-field="Inventory_ID_InvoicePurchaseOrder" caption="ID_Transaccion"></DxColumn> 
                        <DxColumn :width="columnWidth" data-field="MovType" caption="Tipo de movimiento"></DxColumn>
                        <DxColumn :width="columnWidth" data-field="Warehouse" caption="Almacen"></DxColumn>
                        <DxColumn :width="columnWidth" data-field="Item_InOut" caption="Entradas/Salidas"></DxColumn>
                        <DxColumn :width="columnWidth" data-field="Quantity" caption="Cantidad"></DxColumn>
                        <DxColumn :width="columnWidth" data-field="TotalStock" caption="Total de existencias"></DxColumn>

                        <template #show-template="{ data }">
                                <a href="#" class="mx-1" @click.prevent.stop="
                                showDetailtInvoice(data)
                                
                                ">
                                <v-icon color="primary">mdi-eye</v-icon>
                                </a>
                        </template>
                    
                    </DxDataGrid>
                </GeneralFilter>

            </v-card>
        </v-card-text>
        <v-dialog
                v-model="showBuyOrder"
                width ="60%"
        >
            <OrderDetails :key="Math.random()" :out="true" :order="order" v-if="showBuyOrder" @close="closeDialog"
            class="scroll-bar"></OrderDetails>
        </v-dialog>
        <v-dialog v-model="showInvoice" max-width="75%" >
            <v-card v-if="showInvoice">

                <invoice-details :hasPrintButtom="true" @close="showInvoice = false;" :invoice="order" :key="order.ID_Invoice"></invoice-details>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showTransfer" width="75%" scrollable>
            <v-card>
                <v-card-text>
                    <TransferDetails :transferData="order" />
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import GeneralFilter from "@/components/GeneralFilter";
    import OrderDetails from "../PurchaseOrderDetails";
    import InvoiceDetails from "@/views/invoices/InvoiceDetails";
    import TransferDetails from "@/views/inventory/TransferDetails";
    import {DxDataGrid, DxColumn, DxExport} from 'devextreme-vue/data-grid';
    import DatePicker from "@/components/DatePicker";
    import { printToExcel } from '@/helpers/printToexcel'
    import moment from 'moment';
    import { multipleCriteriaFilter } from '@/helpers/object'
    export default {
        name: "ProductKardex",
        props: ['data'],
        components: { 
            DxDataGrid, DxColumn, 
            DatePicker,
            DxExport, OrderDetails, 
            InvoiceDetails, TransferDetails,
            GeneralFilter
        },
        data () {
            return {
                search: {
                    filter: null
                },
                products: null,
                filterProducts: {
                    start: null,
                    end: null,
                    warehouse: null,
                },
                warehouses: null,
                showBuyOrder: false,
                showInvoice: false,
                showTransfer: false,
                order: null,
                CurrentProvider: this.data,
                isFiltered: false,
                productsListFiltered: [],
                didHaveAllData: false,
                clicked: false,
            }
        },
        mounted() {
            console.log( 'this.data ', this.data );
            this.getAllWarehouses();
           

        },
        computed:{
            columnWidth(){
                return this.$vuetify.breakpoint.smAndDown ? '150px':'auto !important'
            }
        },
        methods: {
            clean() {
                this.filterProducts = {
                    start: null,
                    end: null,
                    warehouse: null,
                }
                this.clicked = false;
                this.getKardex(0);
            },
            getKardex(x=1) {
                this.$API.products.getKardexHistory({itemid: this.data.ID_Item,
                    dateStart: (x & this.clicked) ? this.filterProducts.start : null,
                    dateEnd: (x & this.clicked) ? this.filterProducts.end : null,
                    warehouse: (x & this.clicked) ? this.filterProducts.warehouse : null
                })
                    .then(response => {
                        let resp = response;
                        if (response.length > 0) {
                            resp = response.map((item) => {
                                item.SystemDateInsert = moment(item.SystemDateInsert).format('DD/MM/YYYY');
                                this.warehouses.forEach((warehouse) => {
                                    if (warehouse.ID_Warehouse === item.Warehouse) {
                                        item.Warehouse = warehouse.Warehouse;
                                    }
                                })
                                return item;
                            })
                        }
                        this.products = resp
                    })
            },
            getKardexAllList(x=1) {
                this.$API.products.getKardex({itemid: this.data.ID_Item,
                    dateStart: (x & this.clicked) ? this.filterProducts.start : null,
                    dateEnd: (x & this.clicked) ? this.filterProducts.end : null,
                    warehouse: (x & this.clicked) ? this.filterProducts.warehouse : null
                })
                    .then(response => {
                        let resp = response;
                        if (response.length > 0) {
                            resp = response.map((item) => {
                                item.SystemDateInsert = moment(item.SystemDateInsert).format('DD/MM/YYYY');
                                this.warehouses.forEach((warehouse) => {
                                    if (warehouse.ID_Warehouse === item.Warehouse) {
                                        item.Warehouse = warehouse.Warehouse;
                                    }
                                })
                                return item;
                            })
                        }
                        this.products = resp
                    })
            },
            getAllWarehouses() {
                this.$API.warehouse.getWarehouses()
                    .then(response => {
                    this.warehouses = response;
                    this.getKardex(0);
                })
            },
            showDetailtInvoice(data) {
               if(data.data.Inventory_ID_InvoicePurchaseOrder.startsWith('PO')){
                    this.$API.orders.getOneOrder({
                        id: data.data.Inventory_ID_InvoicePurchaseOrder//,
                        // provider: this.CurrentProvider.ID_Provider
                    })
                    .then(response => {
                        if (response.PurchaseOrderPendingPayment == 1) response.orderStatus = 'Pendiente';
                        if (response.OrderProcessed== 1) response.orderStatus = 'Procesada';
                        this.order = {
                            ...data,
                            data : response,
                            
                        }
                        this.showBuyOrder = true;
                    })
               }
               else if(data.data.Inventory_ID_InvoicePurchaseOrder.startsWith('TFR')){
                this.$API.inventoryTransfer.getOneTransfer(data.data.Inventory_ID_InvoicePurchaseOrder).
                    then(response => {
                        this.order = {
                            
                            data : response,
                            
                        }
                        this.showTransfer = true;
                    })
                }
               else{
                this.$API.invoices.getInvoice(
                    data.data.Inventory_ID_InvoicePurchaseOrder
                )
                .then(response => {
                    this.order = response
                    this.showInvoice = true;
                })
               }
            },
            onExporting(e) {
                printToExcel(e, 'Kardex')
            },
            closeDialog() {
                this.showBuyOrder = false;
            },
            filterPromise(filterObj) {
                const filterArray =[
                    "SystemDateInsert",
                    "Inventory_ID_InvoicePurchaseOrder",
                    "MovType",
                    "Warehouse",
                    "Item_InOut",
                    "Quantity",
                    "TotalStock",
                ];
            return new Promise((resolve, reject) => {
                try {
                    resolve(multipleCriteriaFilter(filterArray, filterObj, this.products));
                } catch (error) {
                    reject(error);
                }})
            },
            clearFilter() {
                this.isFiltered = false;
            },
            filterHandler(response){
                if (response.length === 0 && this.didHaveAllData === false) {
                    this.didHaveAllData = true;
                    this.getKardexAllList();
                    this.$refs.generalFilter.filterHandler();
                }
                this.isFiltered = true
                this.productsListFiltered = response
            },
            setClickedTrue(){
                this.clicked = true;
            },
        },

    }
</script>

<style scoped>
.border-box {
    border-radius: 15px !important;
}
</style>
