<template>
  <div>
    <v-card flat>
      <v-card-text>
        <template v-if="type === 'medicalclinic'">
          <user-profile-lab
            :patient="data"
            :patientinfo="data"
            :useData="useData"
          ></user-profile-lab>
        </template>
        <template v-else>
          <user-profile :patientinfo="patientinfo" :data="data" :useData="useData" />
        </template>
        <v-row style="margin-top: 1rem">
          <v-col cols="12" class="box" style="margin-top: 1rem">
            <v-form v-model="valid" class="position-relative">
              <block v-if="loading"></block>

              <v-row class="ml-2 mr-2" style="margin-top: 0.3rem">
                <v-col cols="12" class="tabsNh">
                  <v-col
                    v-if="surgery"
                    cols="12"
                    style="display: flex; justify-content: space-between"
                  >
                    <div>
                      <v-btn
                        v-if="$store.getters['rolesUser/getCustomRole'](160)"
                        small
                        color="error"
                        @click.prevent.stop="
                          showAlert(
                            'question',
                            'confirmar',
                            'esta seguro de eliminar el elemento esta accion no se puede deshacer',
                            'deletesurgery'
                          );
                          surgerytoDelete = surgery;
                        "
                      >
                        <v-icon> mdi-delete </v-icon>
                      </v-btn>
                    </div>

                    <div>
                      <v-btn
                        small
                        v-if="$store.getters['rolesUser/getCustomRole'](152)"
                        @click="
                          print = {
                            show: true,
                            data: {
                              surgery: form,
                              patientinfo: patientinfo,
                              useData: useData,
                              customer: patientinfo.customer,
                            },
                          }
                        "
                        style="margin-right: 0.3rem"
                      >
                        <v-icon> mdi-printer </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="$store.getters['rolesUser/getCustomRole'](158)"
                        small
                        class="btnblue mr-1"
                        @click="showhistory = !showhistory"
                      >
                        <v-icon> mdi-magnify </v-icon>
                      </v-btn>
                      <v-btn class="btnclean" @click.prevent="reset" small>
                        <v-icon> mdi-broom </v-icon>
                      </v-btn>

                      <v-btn
                        small
                        v-if="$store.getters['rolesUser/getCustomRole'](159)"
                        class="btnsave"
                        @click.prevent="updateSurgery"
                      >
                        <v-icon> mdi-content-save </v-icon>
                      </v-btn>
                    </div>
                  </v-col>

                  <v-col
                    cols="12"
                    style="display: flex; justify-content: flex-end"
                    v-if="surgery == null && selectedvalues"
                  >
                    <v-btn
                      small
                      @click="
                        print = {
                          show: true,
                          data: {
                            surgery: form,
                            patientinfo: patientinfo,
                            useData: useData,
                            customer: patientinfo.customer,
                          },
                        }
                      "
                      style="margin-right: 0.3rem"
                      v-if="$store.getters['rolesUser/getCustomRole'](152)"
                    >
                      <v-icon> mdi-printer </v-icon>
                    </v-btn>
                    <v-btn small class="btnblue mr-1" @click="showhistory = !showhistory">
                      <v-icon> mdi-magnify </v-icon>
                    </v-btn>
                    <v-btn small class="btnclean" @click.prevent="reset">
                      <v-icon> mdi-broom </v-icon>
                    </v-btn>
                    <v-btn
                      small
                      class="btnsave"
                      v-if="$store.getters['rolesUser/getCustomRole'](159)"
                      @click.prevent.stop="addSurgery"
                      style=""
                    >
                      <v-icon> mdi-content-save </v-icon>
                    </v-btn>
                  </v-col>
                </v-col>
                <v-col cols="12" md="3" sm="12" style="margin-top: 1rem">
                  <div style="width: 285px !important; display: flex">
                    <date-picker
                      label="Fecha"
                      @dateSelected="
                        (param) => {
                          form.SurgeryDate = param;
                          checktodaySurgery(param);
                        }
                      "
                      :value="form.SurgeryDate"
                    ></date-picker>
                  </div>
                </v-col>
                <v-col cols="12" md="8" style="display: flex; justify-content: flex-end">
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                  style="display: flex; justify-content: flex-end; margin-top: 0.5rem"
                >
                  <v-btn
                    @click="showComments = !showComments"
                    :class="form.Comment ? 'btnclean' : 'btnblack'"
                  >
                    Comentarios
                  </v-btn>
                </v-col>

                <!--v-btn @click.prevent="">
                    <v-icon> mdi-mail </v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn dark color="green" @click.prevent="">
                    <v-icon> mdi-whatsapp </v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn @click.prevent="">
                    <v-icon> mdi-file-excel </v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn @click.prevent="">
                    <v-icon> mdi-xml </v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn @click.prevent="">
                    <v-icon> mdi-file-pdf-box </v-icon>
                  </v-btn-->

                <v-col cols="12" md="4">
                  <br />
                  <h3>MOTIVO DE LA OPERACIÓN</h3>
                  <span class="ql-editor150">
                    <quill-editor
                      :options="quillOptions"
                      v-model="form.ReasonForOperation"
                    ></quill-editor>
                  </span>
                  <p></p>
                </v-col>

                <v-col cols="12" md="4">
                  <br />
                  <h3>PROCESO</h3>
                  <span class="ql-editor150">
                    <quill-editor
                      :options="quillOptions"
                      v-model="form.Process"
                    ></quill-editor>
                  </span>
                </v-col>

                <v-col cols="12" md="4">
                  <br />
                  <h3>RESULTADO</h3>
                  <span class="ql-editor150">
                    <quill-editor
                      :options="quillOptions"
                      v-model="form.Outcome"
                    ></quill-editor>
                  </span>
                </v-col>

                <v-col style="margin-top: 0.6rem; margin-bottom: 1rem"> </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <!-- <v-dialog v-model="deleteDialog" v-if="surgery" max-width="700">
      <DeleteUserDialog
        :data="surgery"
        :name="'Surgery'"
        v-if="deleteDialog"
        @close="deleteDialog = false"
        @f_call="deleteSurgery($event)"
      ></DeleteUserDialog>
    </v-dialog> -->
        <v-dialog v-model="showhistory" scrollable :width="'90%'">
          <v-card class="pa-4" style="overflow: auto;">
            <div
              style="
                margin-top: 0.3rem;
                margin-bottom: 0.3rem;
                display: flex;
                justify-content: flex-end;
              "
            >
              <v-btn small class="btndelete" @click.prevent="showhistory = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <GeneralFilter
              class="background border-box"
              entity="cliente"
              :filterEndpoint="$API.medicalsurgery.getFilterSurgeries"
              :search="search"
              @emptyFilter="getSurgeries"
              @filtered="filterHandler"
            >
            <!-- <DxDataGrid
               :scrolling="{useNative:true}"
                :show-borders="true"
                :data-source="surgeries"
                key-expr="ID_Surgery"
                @exporting="onExporting"
              >
                <DxExport :enabled="true" />
                <DxColumn :width="70" cell-template="show-template" caption=""></DxColumn>
                <DxPaging :page-size="10" />
                <DxColumn :width="columnWidth" data-field="ID_Surgery" caption="Id"></DxColumn>
                <DxColumn :width="columnWidth" data-field="SurgeryDate" caption="Fecha"></DxColumn>
                <DxColumn :width="columnWidth" data-field="UserName" caption="Atendido por"></DxColumn> -->

              <DxDataGrid 
                class="isScrolledByGeneralFilter"
                :show-borders="true"
                :data-source="surgeries"
                key-expr="ID_Surgery"
                @exporting="onExporting"
                :column-auto-width="true"
                style="width: fit-content;"
              >
                <DxExport :enabled="true" />
                <DxColumn
                  :width="80"
                  cell-template="show-template"
                  caption=""
                ></DxColumn>
                <DxPaging :page-size="10"/>
                <DxColumn :min-width="100" data-field="SurgeryDate" caption="Fecha"></DxColumn>
                <DxColumn :min-width="100" data-field="ID_Surgery" caption="Id"></DxColumn>
                <DxColumn :min-width="200" data-field="FullName" caption="Atendido por"></DxColumn>
                <DxColumn :min-width="300" data-field="ReasonForOperationTable" caption="Motivo de la operacion" cssClass="left-align"></DxColumn>
                <DxColumn :min-width="300" data-field="ProcessTable" caption="Proceso" cssClass="left-align"></DxColumn>
                <DxColumn :min-width="300" data-field="OutcomeTable" caption="Resultado" cssClass="left-align"></DxColumn>
                <DxColumn
                  :width="80"
                  v-if="$store.getters['rolesUser/getCustomRole'](160)"
                  cell-template="show-delete"
                  caption=""
                ></DxColumn>
                <template #show-template="{ data }">
                  <div>
                    <a
                      href="#"
                      @click.prevent.stop="
                        getSurgeryDetails(data);
                        selectedvalues = false;
                        showhistory = false;
                      "
                    >
                      <v-icon color="primary">mdi-eye</v-icon>
                    </a>
                  </div>
                </template>
                <template #show-delete="{ data }">
                  <div>
                    <a
                      href=""
                      class="mx-2"
                      @click.prevent.stop="
                        showAlert(
                          'question',
                          'confirmar',
                          'esta seguro de eliminar el elemento esta accion no se puede deshacer',
                          'deletesurgery'
                        );
                        surgerytoDelete = data;
                        showhistory = false;
                      "
                    >
                      <v-icon color="error">mdi-delete</v-icon>
                    </a>
                  </div></template
                >
              </DxDataGrid>
            </GeneralFilter>
          </v-card>
        </v-dialog>
        <alerts
          v-if="alert.show"
          v-on:close_alert="closeAlert"
          v-on:accept_alert="acceptAlert"
          :properties="alert"
        ></alerts>
        <surgery :data="print"></surgery>
        <v-dialog v-model="showComments" :max-width="$vuetify.breakpoint.smAndDown ? '90%':'60%'">
          <v-card class="pa-4">
            <div
              style="
                margin-top: 0.3rem;
                margin-bottom: 0.3rem;
                display: flex;
                justify-content: flex-end;
              "
            >
              <v-btn small class="btndelete" @click.prevent="showComments = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-col cols="12" md="12" style="">
              <quill-editor v-model="form.Comment" :options="quillOptions"></quill-editor>
            </v-col>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import Block from "@/components/Block";
import { DxDataGrid, DxColumn, DxPaging, DxExport } from "devextreme-vue/data-grid";
import DatePicker from "../../../components/DatePicker";
import Surgery from "../../../components/ReportsAndPrintings/Surgery.vue";
import { printToExcel } from "@/helpers/printToexcel";
import GeneralFilter from "@/components/GeneralFilter";
//import DeleteUserDialog from "../../settings/business/partial/DeleteTemplateDialog.vue";
import Alerts from "@/components/Alerts";
import UserProfile from "@/components/Displays/UserProfileDisplay.vue";
import UserProfileLab from "@/components/Displays/UserProfileDisplayLab.vue";

import { mapState } from "vuex";
export default {
  name: "MedicalSurgery",
  components: {
    DxDataGrid,
    DxColumn,
    DxExport,
    DatePicker,
    DxPaging,
    Block,
    Alerts,
    Surgery,
    GeneralFilter,
    UserProfile,
    UserProfileLab,
    // DeleteUserDialog,
  },
  props: ["data", "win", "type"],
  data: () => ({
    showhistory: false,
    print: {
      show: false,
      data: [],
    },
    alert: {
      type: "success",
      show: false,
      header: "",
      body: "",
    },
    valid: false,
    url: null,
    patientinfo: [],
    surgeries: [],
    surgery: null,
    loading: false,
    deleteDialog: false,
    form: {
      SurgeryDate: "",
      ReasonForOperation: "",
      Process: "",
      Outcome: "",
    },
    loginUserID: null,
    surgerytoDelete: null,
    selectedvalues: true,
    showSurgeries: false,
    showComments: false,
    search: {},
    quillOptions: {
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ align: [] }],

            ["clean"],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ script: "sub" }, { script: "super" }],
            [{ formula: "" }],
          ],
          handlers: {},
        },
      },
      theme: "snow",
    },
    useData: false,
  }),
  computed: {
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
    columnWidth(){
      return this.$vuetify.breakpoint.smAndDown ? '150px':'auto'
    }
  },
  mounted() {
    this.$store.dispatch("rolesUser/getAllRoles");
    this.loginUserID = JSON.parse(localStorage.getItem("user")).id;
    this.useData = JSON.parse(localStorage.getItem("user"));
    if (this.data) {
      Object.keys(this.data).map((el) => {
        Vue.set(this.patientinfo, el.toLowerCase(), this.data[el]);
      });
      this.search = {
        patient: this.patientinfo.id_patient,
      };
      this.url = this.$store.getters.getBackendUrl + "/public" + this.data.ProfilePicPath;
    }
    this.getSurgeries();
  },
  watch: {
    // surgeries: {
    //   deep: true,
    //   inmediate: true,
    //   handler(newVal) {
    //       for (const item of newVal) {
    //           Object.keys(item).forEach(key =>{
    //             if(typeof(item[key]) === 'string') {
    //               const value = item[key].replace(/<[^>]+>/g, ' ');
    //               item[key] = value;
    //             }
    //             if(item[key] == 'undefined') item[key] = '';
    //           })
    //       }
    //       newVal = newVal.sort((a, b) => {
    //           const [aDay, aMonth, aYear] = a.SurgeryDate.split('/');
    //           const aDate = new Date(aYear, aMonth - 1, aDay);
    //           const [bDay, bMonth, bYear] = b.SurgeryDate.split('/');
    //           const bDate = new Date(bYear, bMonth - 1, bDay);
    //           return bDate - aDate;
    //       });
    //       return newVal;
    //   }
    // },
  },
  methods: {
    onExporting(e) {
      printToExcel(e, "Cirugias");
    },
    checktodaySurgery(date) {
      console.log(date);
      let datetoday = false;
      this.surgeries.forEach((el) => {
        if (el.SurgeryDate == date) {
          let data = {
            data: {
              ID_Surgery: el.ID_Surgery,
            },
          };
          this.getSurgeryDetails(data);
          datetoday = true;
        }
      });
      console.log(datetoday);
      if (!datetoday) {
        this.reset();
        this.form.SurgeryDate = date;
      }
    },
    filterHandler(response) {
      this.surgeries = response;
    },
    getSurgeries() {
      this.loading = true;
      let params = { patient: this.patientinfo.id_patient };
      this.$API.medicalsurgery.getSurgeries(params).then(
        (res) => {
          this.surgeries = res;
          this.loading = false;
          let day;
          if (this.$store.state.vetPatientData.dataSurgery) {
            day = this.$store.state.vetPatientData.dataSurgery;
            this.$store.commit("vetPatientData/cleanData");
          } else {
            day = this.form.SurgeryDate;
          }

          res.forEach((el) => {
            if (el.SurgeryDate == day) {
              let data = {
                data: {
                  ID_Surgery: el.ID_Surgery,
                },
              };
              this.getSurgeryDetails(data);
            }
          });
        },
        () => {
          this.loading = false;
          this.showAlert(
            "danger",
            "Error",
            "Error al obtener las cirugias contacte a su administrador"
          );
        }
      );
    },

    addSurgery() {
      this.loading = true;
      if (
        this.form.SurgeryDate == "" ||
        this.form.ReasonForOperation == "" ||
        this.form.Process == "" ||
        this.form.Outcome == ""
      ) {
        this.showAlert("danger", "Error", "Todos los campos son requeridos");
        this.loading = false;
        return;
      }
      let datetoday = false;

      this.form.ID_User_Insert = this.loginUserID;
      this.form.ID_Patient = this.patientinfo.id_patient;

      this.surgeries.forEach((element) => {
        if (element.SurgeryDate == this.form.SurgeryDate) {
          datetoday = true;
          this.showAlert("danger", "Error", "Ya existe una cirugia para esta fecha");
        }
      });

      if (datetoday) {
        this.loading = false;
        return;
      }
      this.$API.medicalsurgery.addSurgery(this.form).then(
        () => {
          this.showAlert("success", "Exito", "Cirugia agregada correctamente");
          this.getSurgeries();
          this.loading = false;
          this.reset();
        },
        () => {
          this.showAlert(
            "danger",
            "Error",
            "Error al agregar la cirugia contacte a su administrador"
          );
          this.loading = false;
        }
      );
    },

    getSurgeryDetails(data) {
      this.loading = true;
      this.$API.medicalsurgery.getSurgeryByID(data.data.ID_Surgery).then(
        (res) => {
          this.surgery = res;
          this.form = res[0];
          this.loading = false;
        },
        () => {
          this.showAlert(
            "danger",
            "Error",
            "Error al obtener la cirugia contacte a su administrador"
          );
          this.loading = false;
        }
      );
      this.selectedvalues = false;
    },

    deleteSurgery(data) {
      this.loading = true;
      this.$API.medicalsurgery.deleteSurgery(data.data.ID_Surgery).then(
        () => {
          this.showAlert("success", "Exito", "Cirugia eliminada correctamente");
          this.deleteDialog = false;
          this.getSurgeries();
          this.loading = false;
          this.reset();
        },
        () => {
          this.showAlert(
            "danger",
            "Error",
            "Error al eliminar la cirugia contacte a su administrador"
          );
          this.loading = false;
        }
      );
    },

    updateSurgery() {
      this.loading = true;
      if (
        this.form.SurgeryDate == "" ||
        this.form.ReasonForOperation == "" ||
        this.form.Process == "" ||
        this.form.Outcome == ""
      ) {
        this.showAlert("danger", "Error", "Todos los campos son requeridos");
        this.loading = false;
        return;
      }
      this.form.ID_User_Update = this.loginUserID;
      this.$API.medicalsurgery.updateSurgery(this.surgery[0].ID_Surgery, this.form).then(
        () => {
          this.showAlert("success", "Exito", "Cirugia actualizada correctamente");
          this.getSurgeries();
          this.loading = false;
          this.reset();
        },
        () => {
          this.showAlert(
            "danger",
            "Error",
            "Error al actualizar la cirugia contacte a su administrador"
          );
          this.loading = false;
        }
      );
    },

    reset() {
      this.form = {
        SurgeryDate: this.moment().format("DD/MM/YYYY"),
        ReasonForOperation: "",
        Process: "",
        Outcome: "",
      };
      this.surgery = null;
      this.selectedvalues = true;
    },
    closeAlert() {
      this.alert.show = false;

      if (this.alert.options == "deletesurgery") {
        this.surgerytoDelete = null;
      }
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "deletesurgery") {
        this.deleteSurgery(this.surgerytoDelete);
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },

    /*onEditorChange(e) {
                //this.medicalhistory.comment = e.html;
            },*/
  },
};
</script>

<style scoped>
.btn-add {
  background-color: #3498db !important;
  color: white;
}
.btnsave {
  background-color: #00a178 !important;
  color: white;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}
.btnclean {
  background-color: #f29d35 !important;
  color: white;
}
.btnblack {
  background-color: rgba(60, 60, 60, 1) !important;
  color: white !important;
}

.btnblue {
  background-color: #1976d2 !important;
  color: white;
}
.tabsB {
  width: 100%;
  padding: 10px;
  background-color: #1976d2;
  color: white;
  border-radius: 15px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
}
.tabsB:hover {
  cursor: pointer;
}

.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;
  border-radius: 15px;
}
.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}
.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.infopatient {
  margin-top: 5%;
}
.tabs {
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white;
}
</style>

<style>
.ql-editor150 .ql-editor {
  min-height: 150px;
  max-height: 150px;
}

.ql-editor300 .ql-editor {
  min-height: 300px;
  max-height: 300px;
}
</style>
