import axios from "./axios"

export default {

    getAllTransfers() {
        const options = {
            method: 'GET',
            url: 'inventorytransfer',
            headers: {
                'content-type': 'application/json'
            },
        }
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error.message)
            })
        })
    },

    getAllTransfersHistory() {
        const options = {
            method: 'GET',
            url: 'inventorytransfer/transfer/history',
            headers: {
                'content-type': 'application/json'
            },
        }
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error.message)
            })
        })
    },

    filterTransfers(filter) {
        console.log("filtro pet:", filter)
        const options = {
            method: 'GET',
            url: 'inventorytransfer/transfer/filter',
            headers: {
                'content-type': 'application/json'
            },
            params: filter
        }
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getOneTransfer(id) {
        const options = {
            method: 'GET',
            url: `inventorytransfer/${id}`,

            headers: {
                'content-type': 'application/json'
            },
        }
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data)
            }).catch(error => {
                // reject(error.data)
                reject(error.message)
            })
        })
    },

    addNewTransfer(body) {
        const options = {
            method: 'POST',
            url: 'inventorytransfer',
            headers: {
                'content-type': 'application/json'
            },
            data: body
        }
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error.message)
            })
        })
    },

    addNewTransferRequest(body) {
        const options = {
            method: 'POST',
            url: 'inventorytransferrequest',
            headers: {
                'content-type': 'application/json'
            },
            data: body
        }
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error.message)
            })
        })
    },

    updateTransferStatusRequest(body) {
        const options = {
            method: 'PUT',
            url: 'inventorytransferrequest/transferstatus',
            headers: {
                'content-type': 'application/json'
            },
            data: body
        }
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error.message)
            })
        })
    },


    getAllTransferRequests() {
        const options = {
            method: 'GET',
            url: 'inventorytransferrequest',
            headers: {
                'content-type': 'application/json'
            },
        }
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error.message)
            })
        })
    },

    getAllTransferRequestsHistory() {
        const options = {
            method: 'GET',
            url: 'inventorytransferrequest/transferrequest/history',
            headers: {
                'content-type': 'application/json'
            },
        }
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error.message)
            })
        })
    },

    filterTransferRequests(filter) {
        console.log("filtro pet:", filter)
        const options = {
            method: 'GET',
            url: 'inventorytransferrequest/transferrequest/filter',
            headers: {
                'content-type': 'application/json'
            },
            params: filter
        }
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error.message)
            })
        })
    },

    infoProductAtDestination(data) {
        const options = {
            method: 'GET',
            url: 'inventorytransfer/transferinfo/infoProductAtDestination',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        }
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error.message)
            })
        })
    }

}
