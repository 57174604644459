import Vue from 'vue'
import Vuex from 'vuex'
import general from "../api/general";
import workspaceModule from "./workspaceModule"
import posData from "./pos/posData.js"
import providersData from './inventory/providersData.js'
import transferData from './inventory/transfer/transferData'
import vetPatientData from './veterinary/patient/vetPatientData/index.js'
import patientData from './patient/patientData.js'
import customerData from './customers/customerData.js';
import customerDetails from './customers/laboratory/customerDetails.js';
import vetProviders from './veterinary/provider/provdata';
import rolesUser from './roles/rolesUser';
import sysparameters from './sysparams/sysparameters';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('token'),
        refreshToken: localStorage.getItem('refreshToken'),
        user: JSON.parse(localStorage.getItem('user')),
        //backendUrl: 'http://52.33.16.113:8000',
        backendUrl: 'http://localhost:8000',
        systemParam: JSON.parse(localStorage.getItem('systemParam')),
        customizeOptions: localStorage.getItem('customizeOptions'),
        drawerMini: false,
        scrollTop: 0,
        faMh: null,
        CurrentBranch: JSON.parse(localStorage.getItem('branch')),
        catalogMH: {},
        citiesCountries: {},
        countries: {},
        changueWarehouse: false,
        changuePriceList: false,
        CurrentCodPointSale: null,
        bussinessType: '',
        loading: false
    },
    mutations: {
        setBranch(state, payload) {
            state.CurrentBranch = payload
        },
        setToken(state, payload) {
            state.token = payload
        },
        setRefreshToken(state, payload) {
            state.refreshToken = payload
        },
        setUser(state, payload) {
            state.user = payload
            state.bussinessType = payload ? payload.businesstype : ''
        },
        removeUser(state) {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            state.token = null;
            state.user = null
        },
        setSystemParam(state, payload) {
            state.systemParam = payload
        },
        setCustomizeOptions(state, payload) {
            state.customizeOptions = payload
        },
        setDrawerMini(state, payload) {
            state.drawerMini = payload
        },
        setScrollTop(state, payload) {
            state.scrollTop = payload
        },
        setCatalogMH(state, payload) {
            state.catalogMH = payload
        },
        setCitiesCountries(state, payload) {
            state.citiesCountries = payload
        },
        setCountries(state, payload) {
            state.countries = payload
        },
        setChangueWarehouse(state, payload) {
            state.changueWarehouse = payload
        },
        setChanguePriceList(state, payload) {
            state.changuePriceList = payload
        },
        setCodPointSale(state, payload) {
            state.CurrentCodPointSale = payload
        },
        bussinessType(state, payload){
            state.bussinessType = payload
        },
        loading(state, value) {
            state.loading = value
        }
    },
    getters: {
        getToken(state) {
            return state.token;
        },
        getBackendUrl(state) {
            return state.backendUrl
        },
        getSystemToken(state) {
            return state.systemParam
        },
        getDrawerMini(state) {
            return state.drawerMini
        },
        getScrollTop(state) {
            return state.scrollTop
        },
        getCatalogMH:(state) => (name) => {
        
           return state.catalogMH[name];
        },
        getCitiesCountries:(state) => (name) => {
            return state.citiesCountries[name];
        },
        getCountries(state) {
            return state.countries;
        },
        getCodPointSale(state) {
            return state.CurrentCodPointSale
        }
    },
    actions: {
        setUser({state, commit}) {
            let user = localStorage.getItem('user');
            if (!user && state.token == null) {
                return
            }
            user = JSON.parse(user);
            commit('setUser', user);
            return state.user
        },
        getParam({state}, payload) {
            if (!state.systemParam) {
                return general.systemParam()
                    .then(response => {
                        return response.data.filter(item => item.ParameterType == payload.type)
                    })
            }
            return Promise.resolve(state.systemParam.filter(item => item.ParameterType == payload.type));
        },
        async getCatalogMH({commit}) {
           const cat =  await general.getCatalogMH()
           
            commit('setCatalogMH', cat)
        },

        async getCitiesCountries({commit}) {
            const citiesCountries = await general.getCitiesCountries();
            console.log(citiesCountries)
            commit('setCitiesCountries', citiesCountries)
        },
        async getCountries({commit}) {
            const countries = await general.getCountries();
            commit('setCountries', countries)
        },
    },
    modules: {
        workspaceModule,
        posData,
        providersData,
        transferData,
        patientData,
        vetPatientData,
        customerData,
        customerDetails,
        vetProviders,
        rolesUser,
        sysparameters
    }
})
