import axios from "./axios";

export default {
    async getAccountingPeriodBatch( filter = null ) {
        try {
            const options = {
                method: 'GET',
                url: '/accountingperiodbatch',
                headers: {'content-type': 'application/json'},
            };
            if( filter ) {
                const { filter: common } = filter;
                options.params = {
                    common
                };
            }
            const { data: responseData } = await axios( options );
            const { success } = responseData;

            if ( !success ) {
                throw new Error( responseData.message );
            }

            return responseData;
        } catch (error) {
            throw new Error(`${error.message}`);
        }
    },
    disableAccountingBatch(data) {
        const options = {
            method: 'PUT',
            url: '/accountingperiodbatch/batchDisable',
            headers: {'content-type': 'application/json'},
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    console.log(response);
                    if(response === undefined){
                        reject('No se obtuvo respuesta del servidor.');
                    }
                    if(response.data.success){
                        resolve(response.data);
                    } else {
                        console.log(response);
                        reject(response.data);
                    }
                })
                .catch(error => {
                    reject(error)
                })
        });
    },
}