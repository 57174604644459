import axios from "./axios";

export default {
    /**
     * Get All Invoices
     * @api {Get} manageinvoice
     */
    getInvoices(filter) {
        const options = {
            method: 'GET',
            url: 'manageinvoice',
            headers: {'content-type': 'application/json'},
            params: filter
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getCashierCut(data) {
        const options = {
            method: 'GET',
            url: 'manageinvoice/cashiercut',
            headers: { 'content-type': 'application/json' },
            params: data
        }

        return new Promise( ( resolve, reject ) => {
            axios( options )
                .then( response => {
                    const { data: responseResult } = response;

                    const { success } = responseResult;
                    
                    if( success ) {
                        const { data } = responseResult;
                        console.log( data );
                        resolve( data );
                    } else {
                        const { message, status } = responseResult;

                        reject( { status, message } );
                    }
                } )
                .catch( error => {
                    reject( error )
                } );
        } );
    },
    getCashierCutByDates(data) {
        const options = {
            method: 'GET',
            url: 'manageinvoice/cashiercutbydates',
            headers: { 'content-type': 'application/json' },
            params: data
        }

        return new Promise( ( resolve, reject ) => {
            axios( options )
                .then( response => {
                    const { data: responseResult } = response;

                    const { success } = responseResult;
                    
                    if( success ) {
                        const { data, dataDetallada } = responseResult;
                        resolve( { data, dataDetallada } );
                    } else {
                        const { message, status } = responseResult;

                        reject( { status, message } );
                    }
                } )
                .catch( error => {
                    reject( error )
                } );
        } );
    },
    getCashierCutFiltered(filter) {
        const options = {
            method: 'GET',
            url: 'manageinvoice/cashiercut/filtered',
            headers: { 'content-type': 'application/json' },
            params: filter
        }

        return new Promise( ( resolve, reject ) => {
            axios( options )
                .then( response => {
                    const { data: responseResult } = response;

                    const { success } = responseResult;
                    
                    if( success ) {
                        const { data } = responseResult;
                        resolve( data );
                    } else {
                        const { message, status } = responseResult;

                        reject( { status, message } );
                    }
                } )
                .catch( error => {
                    reject( error )
                } );
        } );
    },
    getCashierCutFilteredByDates(filter) {
        const options = {
            method: 'GET',
            url: 'manageinvoice/cashiercut/filteredDates',
            headers: { 'content-type': 'application/json' },
            params: filter
        }

        return new Promise( ( resolve, reject ) => {
            axios( options )
                .then( response => {
                    const { data: responseResult } = response;

                    const { success } = responseResult;
                    
                    if( success ) {
                        const { data } = responseResult;
                        resolve( data );
                    } else {
                        const { message, status } = responseResult;

                        reject( { status, message } );
                    }
                } )
                .catch( error => {
                    reject( error )
                } );
        } );
    },
    getInvoicesPOSHistory(filter) {
        const options = {
            method: 'GET',
            url: 'manageinvoice/pos/history',
            headers: {'content-type': 'application/json'},
            params: filter
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getInvoicesPOSHistoryNotBilled(filter) {
        const options = {
            method: 'GET',
            url: 'manageinvoice/pos/history/notbilled',
            headers: {'content-type': 'application/json'},
            params: filter
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getInvoicesFiltering(filter) {
        const options = {
            method: 'GET',
            url: 'manageinvoice/pos/list/filtering',
            headers: {'content-type': 'application/json'},
            params: filter
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getInvoicesFilteringByDates(filterDates) {
        console.log(filterDates);
        const options = {
            method: 'GET',
            url: 'manageinvoice/pos/list/filtering/bydates',
            headers: {'content-type': 'application/json'},
            params: {...filterDates}
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getInvoicesFilteringNotBilled(filter) {
        const options = {
            method: 'GET',
            url: 'manageinvoice/pos/notbilled/filtering',
            headers: {'content-type': 'application/json'},
            params: filter
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getInvoice(id) {
        const options = {
            method: 'GET',
            url: `manageinvoice/${id}`,
            headers: {'content-type': 'application/json'}
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getSalesHistory() {
        const options = {
            method: 'GET',
            url: `manageinvoice/saleshistory`,
            headers: {'content-type': 'application/json'}
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    salesfilterhistory(filter) {
        const options = {
            method: 'GET',
            url: `manageinvoice/salesfilterhistory`,
            headers: {'content-type': 'application/json'},
            params: filter
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    salesfilterhistoryColumn(filter) {
        const options = {
            method: 'GET',
            url: `manageinvoice/salesfilterhistorycolumns`,
            headers: {'content-type': 'application/json'},
            params: filter
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    salesfilterhistoryBydate(filter) {
        const options = {
            method: 'GET',
            url: `manageinvoice/salesfilterhistoryBydate`,
            headers: {'content-type': 'application/json'},
            params: filter
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getInvoiceProductItems(invoice_id) {
        const options = {
            method: 'GET',
            url: `manageinvoice/products/${invoice_id}`,
            headers: {'content-type': 'application/json'}
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getInvoiceServiceItems(invoice_id) {
        const options = {
            method: 'GET',
            url: `manageinvoice/services/${invoice_id}`,
            headers: {'content-type': 'application/json'}
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    deleteInvoice(id) {
        const options = {
            method: 'DELETE',
            url: `manageinvoice/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    addInvoice(data) {
        const options = {
            method: 'POST',
            url: `manageinvoice`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    console.log(response);
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error)
                })
        })
    },
    addServiceToClient(data) {
        const options = {
            method: 'POST',
            url: `manageinvoice/addservices`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    nullInvoice(id, data) {
        const options = {
            method: 'PUT',
            url: `manageinvoice/nullify/${id}`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getInvoiceID(params) {
        const options = {
            method: 'GET',
            url: 'manageinvoice/invoicenumber',
            // headers: {'content-type': 'application/json'},
            params: params
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
            })
        })
    },
    loadInfoInvoice(data){
        const options = {
            method: 'POST',
            url: 'manageinvoice/loadinfoinvoice',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    saveOrUpdateInfoInvoices(data){
        const options = {
            method: 'POST',
            url: 'manageinvoice/saveorupdateinvoicescorrelatives',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    invalidInvoice(data){
        const options = {
            method: 'POST',
            url: 'manageinvoice/invalidinvoice',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    contingencyProcess(data){
        const options = {
            method: 'POST',
            url: 'manageinvoice/contingencyprocess',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    consultBatchProcess(data){
        const options = {
            method: 'POST',
            url: 'manageinvoice/consultbatchprocess',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    consultBatchProcess2(data){
        const options = {
            method: 'POST', 
            url: 'manageinvoice/consultbatchprocess2',
            headers: {'content-type': 'application/json'},
            data: data
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getDteJsonHeaderByInvoice(invoice_id) {
        const options = {
            method: 'GET',
            url: `manageinvoice/dtejsonheader/${invoice_id}`,
            headers: {'content-type': 'application/json'}
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    async getAllInvoiceItems(invoice_id) {
        try {
            const products = await this.getInvoiceProductItems(invoice_id);
            const services = await this.getInvoiceServiceItems(invoice_id);
            return products.concat(services);
        } catch (error) {
            console.log(error)
            throw new Error(error);
        }

        
    },
    async sendInvoiceMail(data) {
        // try {
        const options = {
            timeout: 120000,
            method: 'POST',
            url: `manageinvoice/sendinvoicemail`,
            headers: {'content-type': 'application/json'},
            data
        };
        console.log('entré');
        const result = await axios(options);
        
        const { data: { success, message } } = result;
        if( !success ) {
            // console.log(result);
            throw message;
        }
        return result;
        // } catch (error) {
        //     // console.log('del lado del error', error);
        //     throw error;
        // }
    },
    getDocRelatedByInvoice(invoice_id) {
        const options = {
            method: 'GET',
            url: `manageinvoice/docrelated/${invoice_id}`,
            headers: {'content-type': 'application/json'}
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getProductsSalesHistory() {
        const options = {
            method: 'GET',
            url: `manageinvoice/productssaleshistory`,
            headers: {'content-type': 'application/json'}
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    productsSalesFilterHistory(filter) {
        const options = {
            method: 'GET',
            url: `manageinvoice/productssalesfilterhistory`,
            headers: {'content-type': 'application/json'},
            params: filter
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    productsSalesFilterHistoryColumn(filter) {
        const options = {
            method: 'GET',
            url: `manageinvoice/productssalesfilterhistorycolumns`,
            headers: {'content-type': 'application/json'},
            params: filter
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    productsSalesFilterHistoryByDate(filter) {
        const options = {
            method: 'GET',
            url: `manageinvoice/productssalesfilterhistoryBydate`,
            headers: {'content-type': 'application/json'},
            params: filter
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getInvoicePayments(invoice_id) {
        const options = {
            method: 'GET',
            url: `manageinvoice/getInvoicePayments/${invoice_id}`,
            headers: {'content-type': 'application/json'}
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getCashierCutDetails(filter) {
        const options = {
            method: 'GET',
            url: 'manageinvoice/getCashierCutDetails',
            headers: { 'content-type': 'application/json' },
            params: filter
        }

        return new Promise( ( resolve, reject ) => {
            axios( options )
                .then( response => {
                    const { data: responseResult } = response;

                    const { success } = responseResult;
                    
                    if( success ) {
                        const { data } = responseResult;
                        resolve( data );
                    } else {
                        const { message, status } = responseResult;

                        reject( { status, message } );
                    }
                } )
                .catch( error => {
                    reject(error )
                } );
        } );
    },
}