import axios from "./axios";

export default {
    /**
     * Update Reminder-email Next Appointment Day-time
     * @api {PUT} /reminders-email-schedule
     */
     updateRemindersEmailSchedule(data) {
        const options = {
            method: 'PUT',
            url: '/remindersEmailSchedule',
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    saveEmailsBccReminders(data) {
        const options = {
            method: 'POST',
            url: '/remindersEmailSchedule/saveEmailsBccReminders',
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    GetRemindersEmailSchedule() {
        const options = {
            method: 'GET',
            url: '/remindersEmailSchedule',
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
}

