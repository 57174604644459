<template>
  <v-expansion-panel v-model="panel" v-if="$store.getters['rolesUser/getCustomRole'](45)">
    <v-expansion-panel-header class="mt-2">
      BUSCAR PRODUCTOS
      <template v-slot:actions>
        <v-btn class="mr-5" @click.native.stop="reload()" icon small>
          <v-icon color="primary">mdi-reload</v-icon>
        </v-btn>
        <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card>
        <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
          <v-tabs-slider color="red"></v-tabs-slider>
          <v-tab href="#tab-1">
            Lista de Productos
            <v-icon>mdi-account-search</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" touchless>
          <v-tab-item value="tab-1">
            <v-card flat class="pa-1 position-relative">
              <div class="d-flex filter-btn">
                <v-bottom-sheet v-model="showFilter" inset>
                  <!--template v-slot:activator="{ on, attrs }">
                                                  <v-btn color="orange" dark v-bind="attrs" v-on="on">FILTROS
                                                  </v-btn>
                                              </template-->
                  <v-sheet class="text-center">
                    <v-btn class="mt-6" text color="error" @click="showFilter = !showFilter">Cerrar
                    </v-btn>
                    <v-btn class="mt-6" text color="primary">Filtros</v-btn>
                    <div class="my-3 pa-4">
                      <div>
                        <date-picker label="Desde"></date-picker>
                        <date-picker label="Hasta"></date-picker>
                        <v-select outlined dense :items="['PROVIDER 1', 'PROVIDER 2', 'PROVIDER 3', 'PROVIDER 3']" attach
                          label="Selecciona Proveedor">
                          <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-bind="attrs" v-on="on">
                              <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-select>
                      </div>

                    </div>

                  </v-sheet>
                </v-bottom-sheet>

              </div>
              <v-card class="pa-4 white component-v-card">
                <v-btn class="btnclean mb-1" @click="showfilterdata = !showfilterdata">
                  Filtrar
                </v-btn>

                <!-- This following table was made just for one business -->
                <!-- Aniimate custom table-->
                <GeneralFilter :filterEndpoint="$API.products.filterProductSpecialInventory" :search="search" @emptyFilter="getAllProducts" @filtered="filterHandlerSpecialInventory">
                  <DxDataGrid :data-source="products" key-expr="ID_Item" :show-row-lines="showRowLines"
                    @exporting="onExporting" :show-borders="showBorders" :row-alternation-enabled="rowAlternationEnabled"
                    :column-auto-width="true"
                    scrolling="{ useNative: true }"
                    >
                    
                    <DxExport :enabled="true" />
                    <DxScrolling row-rendering-mode="virtual" />
                    <DxPaging :page-size="10" />
                    <DxColumn v-if="$store.getters['rolesUser/getCustomRole'](110)" :width="80" cell-template="show-template" caption=""></DxColumn>
                    <DxColumn data-field="ID_Item" caption="ID Producto"></DxColumn>
                    <DxColumn data-field="PrincipalBarCode" caption="Código Alterno Principal"></DxColumn>
                    <DxColumn cell-template="show-barcodes" caption="Códigos"></DxColumn>
                    <DxColumn data-field="ItemName" caption="Nombre del Producto"></DxColumn>
                    <DxColumn data-field="stock" caption="Disponible"></DxColumn>
                    <!--DxColumn data-field="LasPurchasePriceFormatted" caption="Último Costo de Compra"></DxColumn-->
                    <DxColumn data-field="ItemPriceFormatted" caption="Precio de Venta"></DxColumn>
                    <DxColumn data-field="WarehouseName" caption="Almacenes"></DxColumn>
                    <DxColumn data-field="Providers" caption="Proveedores"></DxColumn>
                    <template #show-template="{ data }">
                      <a href="#" class="mx-1" @click.prevent.stop="
                        $store.dispatch('addWindow', {
                          name: 'ManageProduct',
                          component: 'ManageProduct',
                          unique: false,
                          meta: { product: data.data }
                        })
                        ">
                        <v-icon color="primary">mdi-eye</v-icon>
                      </a>
                    </template>
                    <template #show-barcodes="{ data }">
                      <v-btn class="btn-add" @click="showCodesOfProduct(data.data)">
                        Ver códigos alternos
                      </v-btn>
                    </template>
                    <!-- <template #show-providers="{ data }">
                      <v-btn class="btn-add" @click="showProvidersOfProduct(data.data)">
                        Ver proveedores
                      </v-btn>
                    </template> -->
                  </DxDataGrid>
                </GeneralFilter>
              </v-card>

              <v-dialog v-model="showfilterdata" scrollable :width="$vuetify.breakpoint.smAndDown.smAndDown ?'90%':'65%'">
                <v-card class="pa-4">
                  <v-card-title>
                    <span class="headline">Filtros</span>
                  </v-card-title>
                  <v-card-text>
                    <v-select outlined dense label="Almacen" v-model="filters_selected.warehouse" item-text="name"
                      item-value="value" attach :items="filterParams.warehouse"></v-select>
                    <v-autocomplete outlined dense label="Familia" v-model="filters_selected.family" :items="families"
                      item-text="name" attach item-value="id"></v-autocomplete>
                    <v-autocomplete outlined dense label="Categoría" v-model="filters_selected.category"
                      :items="filteredCategories" item-text="name" attach item-value="id"
                      :disabled="!filters_selected.family"></v-autocomplete>
                    <v-autocomplete outlined dense label="Subcategoría" v-model="filters_selected.subcategory"
                      :items="filteredSubcategories" attach item-text="name" item-value="id"
                      :disabled="!filters_selected.category"></v-autocomplete>
                    <div style="display: flex; justify-content: space-between;flex-wrap: wrap;">
                      <v-btn class="btnclean mb-2" @click="showfilterdata = !showfilterdata; cleanFilters()">Limpiar
                        filtros</v-btn>
                      <v-btn class="btn-add mb-2" @click="showfilterdata = !showfilterdata; filterValues()">Aplicar</v-btn>

                    </div>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog v-model="showProviders" max-width="1200">
                <v-card>
                  <v-card-title>
                    Proveedores asociados al producto
                  </v-card-title>
                  <v-card-text>
                    <DxDataGrid :data-source="providers">
                      <DxColumn :width="60" cell-template="show-template" caption=""></DxColumn>
                      <DxColumn data-field="ID_Provider" caption="ID de Proveedor"></DxColumn>
                      <DxColumn data-field="ProviderName" caption="Proveedor"></DxColumn>
                      <template #show-template="{data}">
                          <a href="#" class="mx-1"
                              @click="showProviders = false; $store.dispatch('addWindow',{
                                  name: 'providerData',
                                  component: 'ProviderData',
                                  unique: false,
                                  meta: {provider: data.data}
                              })">
                              <v-icon color="primary">mdi-eye</v-icon>
                          </a>
                      </template>
                    </DxDataGrid>
                  </v-card-text>
                  <v-card-actions>
                    <v-col class="text-right">
                      <v-btn class="btncontinue" @click="showProviders = false">
                        Continuar
                      </v-btn>
                    </v-col>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="showBarCodes" max-width="600">
                <v-card>
                  <v-card-title>
                    Códigos de barra / Códigos alternos
                  </v-card-title>
                  <v-card-text>
                    <DxDataGrid :data-source="codes">
                      <DxColumn data-field="BarCode" caption="Códigos de barra / código alterno"></DxColumn>
                      <DxColumn :width="70" cell-template="check-principal" caption="Principal"></DxColumn>
                      <template #check-principal="{data}">
                        <div>
                          <center><v-checkbox readonly v-model="data.data.PrincipalBarCode"></v-checkbox></center>
                        </div>
                      </template>
                    </DxDataGrid>
                  </v-card-text>
                  <v-card-actions>
                    <v-col class="text-right">
                      <v-btn class="btncontinue" @click="showBarCodes = false">
                        Continuar
                      </v-btn>
                    </v-col>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-expansion-panel-content>
    <!--product-data v-if="showProduct"
                              :key="showProduct.data.ID_Item"
                              @reloadData="reloadProducts"
                              :product="showProduct.data"
                              :win="win"
                  ></product-data-->

  </v-expansion-panel>
</template>

<script>

import DatePicker from "../../../components/DatePicker";
import { DxDataGrid, DxColumn, DxScrolling, DxPaging, DxExport } from 'devextreme-vue/data-grid';
import { mapGetters, mapState } from "vuex";
import GeneralFilter from  "@/components/GeneralFilter";

import { printToExcel } from "@/helpers/printToexcel";
//import ProductData from "@/views/inventory/mainPages/ManageProduct";
export default {
  name: "ProductsList",
  components: { DatePicker, DxDataGrid, DxColumn, DxScrolling, DxPaging, DxExport, GeneralFilter },
  props: ['win'],
  data() {
    return {
      tab: null,
      panel: 0,
      form: {},
      showFilter: false,
      products: [],
      showProduct: false,
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      search: {},
      AllData: [],
      showfilterdata: false,
      filterWarehouses: {},
      filterParams: {
        warehouse: [{ name: 'Todos', value: 'Todos' }],
        family: [{ name: 'Todos', value: 'Todos' }],
      },
      warehouses: {},
      families: {},
      categories: {},
      subcategories: {},
      filters_selected: {
        warehouse: 'Todos',
        family: null,
        category: null,
        subcategory: null,
      },
      providers: [],
      codes: [],
      showProviders: false,
      showBarCodes: false,
      query: {
        filter: null
      },
      SpecialInventoryReport: JSON.parse(localStorage.getItem('user')).SpecialInventoryReport,
      barcodeExist: false,
    }
  },
  computed: {
    ...mapState(["CurrentBranch"]),
    ...mapGetters(['reloadData']),
    filteredCategories() {

      return this.filters_selected.family ? this.categories.filter(category => category.id_family == this.filters_selected.family) : []
    },
    filteredSubcategories() {

      return this.filters_selected.category ? this.subcategories.filter(subcategory => subcategory.id_cat == this.filters_selected.category) : []
    },
    ...mapState(
      "rolesUser",
      {
        rolesStatus: (state) => state.roles,
      })
  },
  watch: {
    'reloadData': {
      immediate: true,
      handler: 'reloadProducts2'
    }
  },
  mounted() {
    this.$store.dispatch('rolesUser/getAllRoles')
    //this.search.BranchName = JSON.parse(localStorage.getItem("user")) || ""
    this.search.business = JSON.parse(localStorage.getItem("user")).businessid || ""
    this.search.branch = JSON.parse(localStorage.getItem("user")).branch || ""
    this.search.filter = '';
    this.getFamilies()
    this.getCategories()
    this.getSubs()
    this.getWarehouse();
    this.getAllProducts();
    this.Currency = JSON.parse(localStorage.getItem('branch')).Currency;
  },

  methods: {
    onExporting(e) {
      printToExcel(e, 'Productos')
    },
    filterValues() {
      this.products = this.AllData
      if (this.filters_selected.warehouse != 'Todos') {
        this.products = this.products.filter(product => product.Warehouse == this.filters_selected.warehouse)
      }
      if (this.filters_selected.family) {
        this.products = this.products.filter(product => product.family == this.filters_selected.family)
      }
      if (this.filters_selected.category) {
        this.products = this.products.filter(product => product.category == this.filters_selected.category)
      }

    },
    cleanFilters() {
      this.filters_selected.warehouse = 'Todos'
      this.filters_selected.family = null
      this.filters_selected.category = null
      this.filters_selected.subcategory = null
      this.products = this.AllData
    },
    reload() {
      this.getAllProducts()
      setTimeout(() => {
        window.scrollTo(0, this.$store.getters.getScrollTop)
        //alert(this.$store.getters.getScrollTop)
      }, 300);
    },
    getFamilies() {
      this.$API.itemtypes.getFamilies().then(response => {
        this.families = response
        // console.log("response famili", response);
      })
    },
    getCategories() {
      this.$API.itemtypes.getCategories()
        .then(response => {
          this.categories = response
        })
    },
    getSubs() {
      this.$API.itemtypes.getSubs()
        .then(response => {
          this.subcategories = response
        })
    },
    getWarehouse() {
      let idbusiness = JSON.parse(localStorage.getItem("user")).businessid || ""
      let idbranch = JSON.parse(localStorage.getItem("user")).branch || ""
      this.filterWarehouses.branch = idbranch
      this.filterWarehouses.business = idbusiness
      this.$API.warehouse.getWarehouses(this.filterWarehouses)
        .then(response => {
          this.warehouses = response
        })
    },
    getAllProducts() {
      this.$API.products.getProductsHistory(this.search)
        .then(response => {
          console.log(response);
          this.products = response
          this.AllData = response

          this.AllData.forEach(element => {
            if (!this.filterParams.warehouse.some(w => w.value === element.Warehouse)) {
              this.warehouses.forEach(warehouse => {
                if (warehouse.ID_Warehouse == element.Warehouse) {
                  this.filterParams.warehouse.push({ name: warehouse.Warehouse, value: warehouse.ID_Warehouse })
                }
              });
            }
          });
        }).finally(() => {
          this.setProductsCurrency();
          this.checkIfBarCodeSearch();
        })
    },
    setProductsCurrency(){
      if(this.products.length > 0){
        this.products.map((product) => {
          product.ItemPriceFormatted = `${this.Currency} ${parseFloat(product.ItemPrice).toFixed(2)}`
          product.SuggestedPriceFormatted = `${this.Currency} ${parseFloat(product.Suggested_price).toFixed(2)}`
        })
      }
    },
    checkIfBarCodeSearch() {
      this.barcodeExist = false;

      if (this.products.length > 0) {
        for (const product of this.products) {
          if (product.BarCode) {
            this.barcodeExist = true;
            break;
          }
        }
      }
    },
    reloadProducts(payload) {
      if (payload) {
        this.showProduct = false;
        this.panel = 0;
      }
      this.getAllProducts()
    },
    async reloadProducts2() {
      if (this.reloadData == 'products') {
        await this.getAllProducts();
        this.$store.dispatch('reloadData', null)
      }
    },
    showProvidersOfProduct(data) {
      this.providers = []
      this.$API.providers.getCheckedProvidersOfProduct({"iditem": data.ID_Item}).then(response => {
        this.providers = response
        this.showProviders = true;
      })
    },
    showCodesOfProduct(data) {
      this.codes = []
      this.$API.inventoryitemsbarcodes.getBarCodesByProduct({ ID_Item: data.ID_Item}).then(response => {
        this.codes = response
        this.showBarCodes = true;
      })
    },
    filterHandler(response) {
      this.products = response
    },
    filterHandlerSpecialInventory(response) {
      this.products = response
      this.setProductsCurrency()
      this.checkIfBarCodeSearch()
    },
  },
}
</script>

<style scoped>
.show .v-list-item {
  min-height: auto !important;
}

.filter-btn {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 9;
}


.btn-add {
  background-color: #3498DB !important;
  color: white;
}

.btnsave {
  background-color: #00A178 !important;
  color: white;
}

.btnclean {
  background-color: #F29D35 !important;
  color: white;
}

.btncontinue {
  background-color: rgba(80, 80, 80, 1) !important;
  color: white;
}

.btndelete {
  background-color: #E3191E !important;
  color: white;
}

.justified-column {
  width: 100%;
  border: 1px solid #ccc;
  /* Solo para visualizar el contenedor */
  padding: 10px;
  /* Espaciado interno */
  word-wrap: break-word;
  /* Permite el salto de palabras */
  
}

.component-v-card{
  border-radius: 15px;
}
</style>
