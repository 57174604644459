<template>
    <v-expansion-panel >
            <v-expansion-panel-header class="mt-2">
                AGREGAR PACIENTE
                <template v-slot:actions>
                    <v-icon color="error" @click="$store.dispatch('deleteWindowByUuid',{uuid : win.uuid})">
                        mdi-close
                    </v-icon>
                </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
                <v-card class="white">
                    <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                        <v-tabs-slider color="red"></v-tabs-slider>

                        <v-tab href="#tab-1">
                            AGREGAR PACIENTE
                            <v-icon>mdi-plus</v-icon>
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item value="tab-1">
                            <v-card flat>
                                <v-card-text>
                                    <manage-patient  :win="win"></manage-patient>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-expansion-panel-content>
    </v-expansion-panel>

</template>

<script>
    import ManagePatient from "./ManagePatient";

    export default {
        name: "AddPatient",
        props: ['win'],
        components: {ManagePatient},
        data() {
            return {
                tab: null,
            }
        },
        methods: {
            reloadData(payload) {
                this.$emit('reloadData', payload)
            }
        }
    }
</script>

<style scoped>

</style>
