<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="mt-2">
            BUSCAR PROVEEDORES
            <template v-slot:actions>
                <v-btn class="mr-5" @click.native.stop="reload()" icon small>
                    <v-icon color="primary">mdi-reload</v-icon>
                </v-btn>
                <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-card>
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>
                    <v-tab href="#tab-1">
                        Buscar Proveedores
                        <v-icon>mdi-account-search</v-icon>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                        <v-card flat>
                            <v-card-text class="pa-1">
                                <v-card class="background border-box">
                                    <GeneralFilter  :filterEndpoint="$API.providers.filterProviders" :search="search" @emptyFilter="getAllProviders" @filtered="filterHandler">
                                        <DxDataGrid class="isScrolledByGeneralFilter" :data-source="providers" key-expr="ID_Provider"
                                                    :show-row-lines="showRowLines"
                                                    :show-borders="showBorders"
                                                    @exporting="onExporting"
                                                    :row-alternation-enabled="rowAlternationEnabled"
                                                    :paging="{ enabled: false }"
                                                    style="max-height: 600px;">
                                            <DxScrolling row-rendering-mode="virtual"/>
                                            <DxExport :enabled="true" />
                                            <!--DxPaging :page-size="10"/-->
                                            <DxColumn :width="80" 
                                            v-if="$store.getters['rolesUser/getCustomRole'](96)"
                                            cell-template="show-template" caption=""></DxColumn>
                                            <DxColumn data-field="ID_Provider" caption="ID"></DxColumn>
                                            <DxColumn data-field="ProviderName" caption="Nombre del Proveedor"></DxColumn>
                                            <DxColumn data-field="ProviderContactName" caption="Contacto del Proveedor"></DxColumn>
                                            <DxColumn data-field="ProviderContactMobile"
                                                    caption="Celular de Contacto del Proveedor"></DxColumn>
                                            <template #show-template="{data}">
                                                <a href="#" class="mx-1"
                                                @click="$store.dispatch('addWindow',{
                                                    name: 'providerData',
                                                    component: 'ProviderData',
                                                    unique: false,
                                                    meta: {provider: data.data}
                                                })">
                                                    <v-icon color="primary">mdi-eye</v-icon>
                                                </a>
                                            </template>
                                        </DxDataGrid>
                                    </GeneralFilter>
                                </v-card>

                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-expansion-panel-content>
    </v-expansion-panel>
<!--    <provider-data v-if="showProvider"-->
<!--                   :key="showProvider.data.ID_Provider"-->
<!--                   @reloadData="reloadProviders"-->
<!--                   :provider="showProvider.data"-->
<!--                   :win="win"-->
<!--    ></provider-data>-->
</template>

<script>
    import {DxDataGrid, DxColumn, DxScrolling,DxExport} from 'devextreme-vue/data-grid';
    import { printToExcel } from "@/helpers/printToexcel";
    import {mapGetters} from "vuex";
    // import ProviderData from "@/views/inventory/ProviderData";
    import GeneralFilter from  "@/components/GeneralFilter";

    export default {
        name: "ProvidersList",
        props: ['win'],
        components: {DxDataGrid, DxColumn , DxScrolling, DxExport, GeneralFilter},
        data() {
            return {
                tab: null,
                search: {},
                providers: [],
                showProvider: false,
                showRowLines: true,
                showBorders: true,
                rowAlternationEnabled: true

            }
        },
        computed: {
            ...mapGetters(['reloadData'])
        },
        watch: {
            'reloadData': {
                immediate: true,
                handler: 'reloadProviders'
            }
        },
        mounted() {
            this.search.business = JSON.parse(localStorage.getItem("user")).businessid || ""
            this.search.branch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.getAllProviders();
        },
        methods: {
            onExporting(e){
      printToExcel(e, 'Lista de Proveedores')
    },
          reload(){
            this.getAllProviders();
            setTimeout(() => {
                window.scrollTo(0, this.$store.getters.getScrollTop)
                //alert(this.$store.getters.getScrollTop)
                },300);
          },
            getAllProviders() {
                this.$API.providers.getProvidersHistory(this.search)
                    .then(response => {
                        this.providers = response
                    })
            },
            async reloadProviders() {
                if (this.reloadData == 'providers') {
                    await this.getAllProviders();
                    this.$store.dispatch('reloadData', null)
                }
            },
            filterHandler(response) {
                this.providers = response
            },
        },

    }
</script>

<style scoped>
  .border-box {
  border-radius: 15px !important;
}
.background {
    background: white !important;
    color: white;
}
</style>
