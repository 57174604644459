import axios from "./axiosMaster";

export default{
    /**
     *  Verify Token
     * @api {POST} verifytoken
     */
    async verifyToken(token){
        try {
            const result = await axios({
                method: 'POST',
                url: `verifytoken`,
                headers: {'content-type': 'application/json'},
                data: {
                    token
                }
            });

            return result
        } catch (error) {
            throw new Error(`Sucedió el siguiente error al intentar verificar el token ${error.message}`);
        }
    },
    async verifyBackendURL(data){
        try {
            const result = await axios({
                method: 'POST',
                url: `verifybackendurl`,
                headers: {'content-type': 'application/json'},
                data
            });

            return result
        } catch (error) {
            throw new Error(`Sucedió el siguiente error al intentar verificar la url del backend ${error.message}`);
        }
    },
    async useToken(token){
        try {
            const result = await axios({
                method: 'PUT',
                url: 'usetoken',
                headers: {'content-type': 'application/json'},
                data: {
                    token
                }
            });
            
            return result;
        } catch (error) {
            throw new Error(`Sucedió el siguiente error al intentar utilizar el token ${error.message}`);
        }
    }
}