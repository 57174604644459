<template>
  <div>
    <v-card>
      <!-- <v-row no-gutters>
              <v-col cols="12">
                <v-card-title class="text-h5"> Imágen personalizada </v-card-title>
              </v-col>
            </v-row> -->
      <v-card-text>
        <!-- <v-row>
                <v-col> Subir imágen personalizada </v-col>
              </v-row> -->
        <v-row>
          <v-col cols="12">
            <div class="centered">
              <div>
                <h4 style="text-align: center; margin: 12px;">Seleccione la categoría de documento</h4>
              </div>
            </div>
          </v-col>

          <v-col cols="12">
            <div class="centered" style="margin: 5px;">
              <span style="color: red; font-size: 10px;"> <strong>NOTA:</strong>La categoría seleccionada se aplicará a
                todas los documentos</span>
            </div>
          </v-col>

        </v-row>
        <v-row style="justify-content: center;">
          <!--   <v-col cols="4">
                  <v-img :src="url ? url : ''" class="preview-avatar mt-3 cursor-pointer" @click.prevent="selectIMAGE"
                    v-if="type.toLowerCase() != 'mp4'"></v-img>
                  <video id="video" :src="url ? url : ''" type="video/mp4" disabled class="preview-avatar mt-3 cursor-pointer"
                    v-if="type.toLowerCase() == 'mp4'"></video>
                  <input type="file" ref="file" class="d-none" :accept="accept" @change="selectedImage($event)" />
                </v-col> -->
          <v-col sm="12" md="6" lg="4" xl="3">

            <div class="centered">
              <div style="width: 100%;">
                <!--   <SelectButton v-model="TypeOfImage" :options="options" /> -->
                <v-select 
                  outlined 
                  dense  
                  v-model="TypeOfImage" 
                  :items="options" 
                  item-value="value" 
                  item-text="text" 
                  attach
                  label="Selecciona el tipo de documento" 
                  style="width: 100%;"
                  />
              </div>

            </div>
          </v-col>
          
        </v-row>
        <!--    <v-row>
                <v-col cols="12">
                  <v-btn color=" my-3" @click.prevent="selectIMAGE">
                    <v-icon> mdi-file-excel </v-icon>
                    Seleccionar una imágen de su galería
                  </v-btn>
                </v-col>
              </v-row> -->
        <v-row>
     
            <v-col cols="12">

       
              <FileUpload 
              ref="fileUpload"
              name="demo[]" 
              :customUpload="true" 
              @uploader="selectedImage"
              accept=".jpg,.png,.gif,.mp4,.xlsx,.xls,.pdf,.doc,.docx" 
              :maxFileSize="20971520" 
              :multiple="true"
              chooseLabel="Seleccionar archivo"
              uploadLabel="Subir archivo"
              cancelLabel="Cancelar"
              :showUploadButton="false"
              :showCancelButton="false"
              style="width: 100% !important;"
              >

    </FileUpload>
  </v-col>
              <!--    <template #empty>
                    <p>Arrastra un archivo aqui para subir.</p>
                  </template> -->

         
          
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex" style="justify-content: space-between;">
     
            <!-- <v-btn color="primary my-4 mx-3 float-right" @click.prevent="submitImage">
                    Subir
                  </v-btn> -->
            <v-btn color=" my-4 myx-3 float-right btndelete" @click.prevent="closeModal">
              Cancelar
            </v-btn>
            <v-btn color="primary my-4 mx-3 float-right" @click.prevent="getfiles">
              Subir 
              <v-icon>
                mdi-tray-arrow-up
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
</div>
</template>

<script>



import FileUpload from 'primevue/fileupload';
/* import SelectButton from 'primevue/selectbutton'; */
export default {
  name: "AddImages",
  components: {
    FileUpload,
    /*  SelectButton */
  },
  props: ["type"],
  data() {
    return {
      images: [],
      url: null,
      file: {},
      fileTypes: [],
      accept: "",
      TypeOfImage: 'Rayos X',
      options: [
        { value: 'Rayos X', text: 'Rayos X' },
        { value: 'Ultrasonografìa', text: 'Ultrasonografìa' },
        { value: 'Exámenes de laboratorio clínico', text: 'Exámenes de laboratorio clínico' },
        { value: 'Otro', text: 'Otro' }
      ]
    };
  },

  mounted() {
    if (this.type) this.fileTypes.push(this.type.toLowerCase());
    this.acceptValidation();
  },

  watch: {
    type() {
      this.fileTypes = [];
      this.url = null;
      this.file = {};
      if (this.type) this.fileTypes.push(this.type.toLowerCase());
      this.acceptValidation();
    },
  },

  methods: {
   getfiles(){
    const files = this.$refs.fileUpload.files
    this.selectedImage(files)
   },
    

    selectedImage(el) {
     
  if (el[0]) {
    el.map(file => {
      const parts = file.type.split("/");
      if (parts[0] === 'image') {
        // Si es una imagen
        if (this.maxSizeValidation(file)) {



          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            this.file.TypeOfImage = this.TypeOfImage;
            this.file.file = file;
            this.url = reader.result;
            this.file.src = reader.result;
            this.file['Path'] = reader.result;
            this.submitImage(reader.result);
          };
        }
      } else {
        // Si no es una imagen
        this.file.file = file;
        this.file.TypeOfImage = this.TypeOfImage;
        this.file['Path'] = file;
        this.submitImage(file);
      }
    });
  }
},


    maxSizeValidation(files) {
      const fsize = files.size;
      const file = Math.round(fsize / 1024);
      if (file >= 20000) {
        this.$toasted.error("Please select file less then 20mb.");
        return false;
      } else {
        return true;
      }
    },

    submitImage(image) {


      this.file['Path'] = image
      /* console.log('file', this.file); */
      if (this.file) this.$emit("uploadedImage", this.file);
      this.file = { "TypeOfImage": this.TypeOfImage };
      this.url = null;
    },

    closeModal() {
      this.file = {};
      this.url = null;
      this.$emit("closepopup");
    },

    acceptValidation() {
      if (this.fileTypes[0] == "mp4") {
        this.accept = "video/mp4";
      } else if (this.fileTypes[0] == "jpg") {
        this.accept = "image/jpeg";
      } else {
        this.accept = "image/" + this.fileTypes[0];
      }
    },
  },
};
</script>

<style>
.v-btn {
  text-transform: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

h5 {
  text-align: center;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.btndelete{
  background-color: #E3191E !important;
  color: white !important;
}

</style>
