<template>
    <v-card>
        <div>
            <v-card-title class="text-h5"> Imágen personalizada </v-card-title>

            <v-card-text>
                <v-img :src="url" class="preview-avatar"></v-img>
                <v-file-input @change="Preview_image" v-model="image" label="Choose your photo" prepend-icon="mdi-camera"
                    placeholder="Choose your photo" :rules="[maxFileSize]">
                </v-file-input>
            </v-card-text>
        </div>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="submit"> Subir </v-btn>
            <v-btn color="red darken-1" text @click.prevent="$emit('close')"> Cancelar </v-btn>
        </v-card-actions>

        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
        </alerts>
    </v-card>
</template>

<script>
import alerts from "@/components/Alerts.vue";
import { mapState } from "vuex";

export default {
    name: "UploadCustomImage",
    components: {
        alerts,
    },
    props: ["imageType"],
    data() {
        return {
            alert: {
                type: "success",
                show: false,
                header: "",
                body: "",
            },
            url: null,
            image: null,
            validImageSize: true,
        };
    },

    computed: {
        ...mapState(["user"]),
    },
    methods: {
        showAlert(type, header, body, options = null) {
            type = type == null ? "danger" : type;
            this.alert.type = type;
            this.alert.header = header;
            this.alert.body = body;
            this.alert.show = true;
            this.alert.options = options != null ? options : null;
        },
        closeAlert() {
            this.alert.show = false;
        },
        acceptAlert() {
            this.alert.show = false;
        },
        maxFileSize(event) {
            const allowedSize = 2 * 1024 * 1024; // 2 MB in bytes
            const fileSize = event.size;

            if (fileSize > allowedSize) {
                this.validImageSize = false;
                return `El tamaño del archivo debe ser menor a 2 Mb`;
            }

            this.validImageSize = true;
            return true;
        },
        Preview_image() {
            this.url = URL.createObjectURL(this.image);
        },
        submit() {
            if (this.checkImageSize()) return;
            let formData = new FormData();
            formData.append("filetype", this.imageType);
            formData.append("file", this.image);
            this.$API.users.customize(this.user.id, formData).then(() => {
                this.$emit("close");
            });
        },
        checkImageSize() {
            if (this.validImageSize == false) {
                this.showAlert("danger", "Error", "El tamaño de la imagen debe ser menor a 2 Mb");
                return true;
            }
            return false;
        },
    },
};
</script>

<style scoped></style>
