<template>
    <div>
        <block v-if="loading"></block>
        <div>
            <GeneralFilter class="backgroundGlobal border-boxGlobal" :search="filter" :filterEndpoint="$API.invoices.getInvoicesFilteringNotBilled" @emptyFilter="getAllInvoices" @filtered="filterHandler">
                <DxDataGrid class="isScrolledByGeneralFilter" :data-source="invoices" key-expr="ID_Invoice" @exporting="onExporting"
                    :paging="{ enabled: false }" style="max-height: 600px;">
                    <DxExport :enabled="true" />
                    <!--DxPaging :page-size="10" /-->
                    <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                    <DxColumn data-field="ID_Invoice" caption="ID de plantilla"></DxColumn>
                    <DxColumn data-field="TemplateName" caption="Nombre"></DxColumn>
                    <DxColumn data-field="InvoiceDate" caption="Fecha de creación"></DxColumn>
                    <DxColumn data-field="ID_Customer" caption="ID de cliente o negocio"></DxColumn>
                    <DxColumn data-field="InvoiceName" caption="Nombre Legal"></DxColumn>
                    <DxColumn data-field="InvoiceTotalPagarToShow" caption="Total de la Factura"></DxColumn>
                    <template #show-template="{ data }">
                        <a href="#" class="mx-1" @click.prevent.stop="showInvoice = data; showModal = true">
                            <v-icon color="primary">mdi-eye</v-icon>
                        </a>
                    </template>
                </DxDataGrid>
            </GeneralFilter>
        </div>
        <!-- Show Invoice Modal -->
        <v-dialog v-model="showModal" max-width="75%">
            <v-card v-if="showInvoice">
                <invoice-details :hasDeleteBtn="true" :invoice="showInvoice.data" :key="showInvoice.data.ID_Invoice"
                    @close="hideModal" @billNow="billNow"></invoice-details>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { DxDataGrid, DxColumn, DxExport } from 'devextreme-vue/data-grid';
import GeneralFilter from '../../components/GeneralFilter.vue';
import { printToExcel } from "@/helpers/printToexcel";

import InvoiceDetails from "./InvoiceDetails";
import Block from "@/components/Block";
import { numberWithCommas } from '../../helpers/money';

export default {
    name: "TemplateBill",
    components: { DxColumn, DxDataGrid, GeneralFilter, InvoiceDetails, Block, DxExport },
    data() {
        return {
            loading: false,
            form: {},
            showModal: false,
            filter: {
                //pay: true,
                ID_Branch: null,
                ID_Business: null,
                status: 0,
                istemplate: 1,
                isquote: 0
            },
            invoices: null,
            showInvoice: null
        }
    },
    mounted() {
        this.filter.ID_Branch = JSON.parse(localStorage.getItem("user")).branch;
        this.filter.ID_Business = JSON.parse(localStorage.getItem("user")).businessid;

        this.getAllInvoices()
    },
    methods: {
        formatInvoiceFields() {
                // const dictionary = {
                //     '00': "Prueba",
                //     '01': "Producción"
                // };
                const { Currency } = JSON.parse(localStorage.getItem('branch'));
                for(const index in this.invoices) {
                    // const { IdentificacionHorEmi, InvoiceType, SystemDateInsert, IdentificacionTipoDteDesc } = this.invoices[ index ];

                    // this.invoices[index].AmbienteToShow = dictionary[this.invoices[index].IdentificacionAmbiente];
                    this.invoices[index].InvoiceTotalPagarToShow = `${Currency}${numberWithCommas(this.invoices[index].InvoiceTotalPagar)}`;

                    // if( InvoiceType === 'TCKS' ) {
                    //     this.invoices[ index ].HorEmi = this.formatDateToHour(SystemDateInsert);
                    //     this.invoices[ index ].TipoDocumento = "TICKET";
                    // } else {
                    //     if( IdentificacionTipoDteDesc != null ) {
                    //         this.invoices[ index ].TipoDocumento = IdentificacionTipoDteDesc;
                    //     } else {
                    //         this.invoices[ index ].TipoDocumento = "";
                    //     }
                    //     if( IdentificacionHorEmi != null ) {
                    //         this.invoices[ index ].HorEmi = IdentificacionHorEmi;
                    //     } else { 
                    //         this.invoices[ index ].HorEmi = "";
                    //     }
                    // }
                    // {{ data.data.IdentificacionHorEmi != null ? data.data.IdentificacionHorEmi : "" }}
                    //     {{ data.data.InvoiceType === 'TCKS' ? formatDateToHour(data.data.SystemDateInsert) : "" }}
                }
            },
        filterHandler(response){
            this.invoices = [ ...response ];
        },
        getAllInvoices() {
            this.loading = true
            this.$API.invoices.getInvoicesPOSHistoryNotBilled(this.filter)
                .then(response => {
                    this.loading = false
                    this.invoices = [ ...response ];
                    this.formatInvoiceFields();
                })
        },
        hideModal() {
            this.showModal = false;
            this.showInvoice = null;
            this.getAllInvoices();
        },
        billNow() {
            this.hideModal();
            this.$emit('generateInvoice')
        },
        onExporting(e) {
            printToExcel(e, "Lista de plantillas")
        }
    }
}
</script>

<style scoped>
.btn-add {
    background-color: #00A178 !important;
    color: white;
}
</style>