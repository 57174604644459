<!-- eslint-disable vue/require-v-for-key -->
<template>
    <window-portal :open="data.show" @close="data.show = false">
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center><div><p style="font-size: 30px;">Vista previa de impresión de Vacunas/Medicamentos</p></div></center>
            <br/>
            <center>
                <button class="btnclean" style="font-size: 18px;" @click="Print()">
                    Imprimir
                </button>
            </center>
            <center><div v-if="loading"><p>Cargando...</p></div></center>
        </div>
        <div class="book" style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px;">

                <!--Configuración de la pagina-->
                <div class="page" ref="dataprint" style="position: relative; width: 1000px; min-height: 1294px; background: white; margin: 0 auto; margin-bottom: 20px;">

                    <!-- Encabezado-->
                    <div v-html="header" style="width: 100% !important; padding-top: 50px; margin-left: 60px;"></div>

                    <!-- Configuración del contenido de la pagina -->
                    <div class="subpage" style="height: 950px; padding-top: 20px; overflow: hidden;">

                        <div style="margin-left: 80px; margin-right: 50px;">
                            <table style="font-size: 12pt; border-collapse: collapse; width: 100%;">
                                <tr>
                                    <td style="padding-right:20px; padding-bottom: 5px;"><b>Fecha: </b> {{data.data.date}}</td>
                                </tr>
                                <tr>
                                    <td style="padding-right:20px; padding-bottom: 5px;"><b>ID de Paciente: </b> {{data.data.patientinfo.id_patient}}</td>
                                    <td style="padding-right:20px; padding-bottom: 5px;"><b>Especie: </b> {{data.data.patientinfo.species}}</td>
                                    <td style="padding-right:20px; padding-bottom: 5px;"><b>Raza: </b> {{data.data.patientinfo.breed}}</td>
                                </tr>
                                <tr>
                                    <td style="padding-right:20px; padding-bottom: 5px;"><b>Nombre del paciente: </b> {{data.data.patientinfo.namepatient}}</td>
                                    <td style="padding-right:20px; padding-bottom: 5px;">
                                        <b>{{ data.data.customer.Is_a_Business ? "Empresa: " : "Dueño: " }}</b>
                                        {{  data.data.customer.Is_a_Business ? data.data.customer.BusinessName :  data.data.customer.FirstName + " " +  data.data.customer.LastName}}
                                    </td>
                                    <td style="padding-right:10px"><b>Atendido por: </b>{{ data.data.useData.firstName + " " + data.data.useData.lastName }}</td>
                                </tr>
                            </table>
                            <br/><br/>
                            <table style="font-size: 12pt; border-collapse: collapse; width: 100%;">
                                <tr>
                                    <td style="padding-right:20px; padding-bottom: 5px;"><b>Medicamento/vacuna</b></td>
                                    <td style="padding-right:20px; padding-bottom: 5px;"><b>Período</b></td>
                                    <td style="padding-right:20px; padding-bottom: 5px;"><b>Unidad de periodo</b></td>
                                    <td style="padding-right:20px; padding-bottom: 5px;"><b>Próxima vez</b></td>
                                    <td style="padding-right:20px; padding-bottom: 5px;"><b>Tipo</b></td>
                                </tr>
                                <template v-if="data.data.medication">
                            <br/><br/>
                               <template  v-for="item in data.data.medication">

                                <tr>
                                    <td style="padding-right:20px; padding-bottom: 5px;">{{ item.Medicine }}</td>
                                    <td style="padding-right:20px; padding-bottom: 5px;">{{ item.PeriodTime }}</td>
                                    <td style="padding-right:20px; padding-bottom: 5px;">{{ getDateEsp(item.PeriodUnit) }}</td>
                                    <td style="padding-right:20px; padding-bottom: 5px;">{{ item.NextTime }}</td>
                                    <td style="padding-right:20px; padding-bottom: 5px;">{{ item.ItemType == "VAC" ? "Vacuna" : "Medicamento" }}</td>
                                </tr>
                                <br />

                               </template>
                     
                               </template>
                             
                                <!-- <tr>
                                    <td><b>Próxima cita: </b>{{ data.data.nextappointment.length > 0 ? data.data.prescription.nextappointment : ""}}</td>
                                </tr> -->
                            </table>
                        </div>
                    </div>

                    <div v-html="footer" style="width: 100% !important;"></div>
                </div>

        </div>
    </window-portal>
</template>

<script>

import jsPDF from "jspdf"
//import html2canvas from "html2canvas";
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue"
export default {
    name: "MedicationVaccines",
    props: ['data'],
    components: {WindowPortal},
    data() {
        return {
            loading: false,
            pages: 1,
            header: null,
            footer: null,
            medication: null,
        }
    },
    mounted() {
        this.getHeaderFooter()
        this.loadContent()
    },
    computed: {
    },
    methods: {
        loadContent() {
            this.medication = this.data.data.medication
            this.medication.forEach((item) => {
                item.Type = item.Type == "VAC" ? "Vacuna": "Medicamento"
               
            })
        },
        getHeaderFooter() {
            let search = {};
            search.ID_business = JSON.parse(localStorage.getItem("user")).businessid || ""
            search.ID_branch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.$API.HF.getHF(search).then((data) => {
                let header = [], footer = [];
                header = data.filter((x) => x.State == '1' && x.Type == 'Header');
                this.header = header.length > 0 ? JSON.parse(header[0].Body).html : '';
                this.header = this.header.replace(/(\n)/gm, "");
                footer = data.filter((x) => x.State == '1' && x.Type == 'Footer');
                this.footer = footer.length > 0 ? JSON.parse(footer[0].Body).html : '';
            })
        },
        getDateEsp(date){
            switch (date){
                case "Day":
                    return "Día"
                case "Week":
                    return "Semana"
                case "Month":
                    return "Mes"
                case "Year":
                    return "Año"
                default:
                    return ""
            }
        },

        async Print(){
            this.loading = true
            
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                    callback: function(doc){
                        doc.output()
                    },
                    x: 0,
                    y: 0,
                    margin: [0, 0, 0, 0],
                    html2canvas:{ 
                        scale: 0.612,
                        //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                        useCORS: true,
                        logging: false,
                        allowTaint: true
                    }
            })

            doc.autoPrint({variant: 'non-conform'});
            const blob = doc.output("bloburl")
            window.open(blob)

            this.loading = false
        }
    }
}
</script>

<style scoped>

    .btnclean{
        border-radius: 15px;
        border: 1px solid white;
        background-color: #F29D35 !important;
        color: white !important;
    }
    .btnclose{
        border-radius: 15px;
        border: 1px solid white;
        background-color: rgb(255, 38, 38) !important;
        color: white !important;
       
    }
    

</style>
