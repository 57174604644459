import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Home from '../views/Home.vue'
import Login from "../views/Login";
import Invoices from "../views/invoices/Invoices";
import Patients from "../views/patients/PatientData";
// import PatientList from "../views/patients/PatientList";
import AddProvider from "../views/inventory/partial/AddProvider";
import ProviderList from "../views/inventory/mainPages/ProvidersList";
import ManageProvider from "../views/inventory/mainPages/ManageProduct";
import PurchareOrdersList from "../views/inventory/mainPages/PurchareOrdersList";
import AddProduct from "../views/inventory/mainPages/AddProduct";
import ProductsList from "../views/inventory/mainPages/ProductsList";
import Services from "../views/inventory/mainPages/Services";
import PriceList from "../views/inventory/mainPages/PriceList";
import Warehouses from "../views/inventory/mainPages/Warehouses";
import Settings from "../views/settings/Settings";
import Users from "../views/settings/users/Users";
import Business from "../views/settings/business/Business";
// import AddUser from "../views/settings/users/partial/AddUser";
// import UserList from "../views/settings/users/partial/UserList";
// import AddBusiness from "../views/settings/business/partial/AddBusiness";
// import BusinessList from "../views/settings/business/partial/BusinessList";
// import ManageTemplates from "../views/settings/business/partial/ManageTemplates";
import HOC from "../components/HOC";
// import AddCustomer from "../views/customers/mainPages/AddCustomer";
import SearchCustomer from "../views/customers/CustomersList";
// import Notifications from "../views/customers/mainPages/Notifications";
// import Advertisement from "../views/customers/mainPages/Advertisement";
import AddPatient from "../views/patients/partial/AddPatient";
// import Dashboard from "../views/Dashboard";

import Workspace from "../views/Workspace";

// import all views for veterinary
import VeterinaryPatients from  "../views/veterinary/VeterinaryPatientData";

import ExportData from "../views/settings/outputs/ExportData";

import { VUE_APP_ENV } from '../config';

Vue.use(VueRouter);

const authGuard = (to, from, next) => {
    store.dispatch('setUser')
        .then(user => {
            if (user) {
                next();
            } else {
                next('/login')
            }
        })
};

const preventLoginGuard = (to, from, next) => {
    if(VUE_APP_ENV == 2) {
        store.dispatch('setUser')
            .then(user => {
                if (user) {
                    next('/');
                } else {
                    store.commit("removeUser");
                    next();
                }
            })
    } else {
        next();
    }
};

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: preventLoginGuard
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Workspace,
        beforeEnter: authGuard
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        beforeEnter: authGuard
    },
    {
        path: '/invoices',
        name: 'Invoices',
        component: Invoices,
        beforeEnter: authGuard
    },
    {
        path: '/patients',
        name: 'Patients',
        component: Patients,
        beforeEnter: authGuard
    },
    // {
    //     path: '/Patients/patient-list',
    //     name: 'PatientList',
    //     component: PatientList,
    //     beforeEnter: authGuard
    // },
    {
        path: '/add-patient',
        name: 'AddPatient',
        component: AddPatient,
        beforeEnter: authGuard
    },

    {
        path: '/inventory',
        beforeEnter: authGuard,
        component: HOC,
        children: [
            {
                path: 'add-provider',
                name: 'Add Provider',
                component: AddProvider
            },
            {
                path: 'provider-list',
                name: 'ProvidersList',
                component: ProviderList
            },
            {
                path: 'purchare-Orders-list',
                name: 'PurchareOrdersList',
                component: PurchareOrdersList
            },
            {
                path: 'warehouses',
                name: 'Warehouses',
                component: Warehouses
            },
            {
                path: 'add-product',
                name: 'AddProduct',
                component: AddProduct
            }, {
                path: 'products-list',
                name: 'ProductsList',
                component: ProductsList
            },
            {
                path: 'manage-provider',
                name: 'Manage Provider',
                component: ManageProvider
            },
            {
                path: 'services',
                name: 'Services',
                component: Services
            },{
                path: 'price-list',
                name: 'Price List',
                component: PriceList
            },
        ]
    },




    {
        path: '/settings',
        component: Settings,
        beforeEnter: authGuard,
        children: [
            {
                path: 'users',
                name: 'Users',
                component: Users
            }, {
                path: 'business',
                name: 'Business',
                component: Business
            },
            // {
            //     path: 'add-business',
            //     name: 'AddBusiness',
            //     component: AddBusiness
            // },
            // {
            //     path: 'business-list',
            //     name: 'BusinessList',
            //     component: BusinessList
            // },
            // {
            //     path: 'add-user',
            //     name: 'AddUser',
            //     component: AddUser
            // },
            // {
            //     path: 'user-list',
            //     name: 'UserList',
            //     component: UserList
            // },
            // {
            //     path: 'manage-templates',
            //     name: 'ManageTemplates',
            //     component: ManageTemplates
            // },
            /*{
                path: 'manage-user',
                name: 'ManageUser',
                component: ManageUser
            }*/
        ]
    },
    {
        path: '/customers',
        beforeEnter: authGuard,
        component: HOC,
        children: [
            // {
            //     path: 'add',
            //     name: 'Add Customer',
            //     component: AddCustomer
            // },
            {
                path: 'search',
                name: 'Search Customer',
                component: SearchCustomer
            },
            // {
            //     path: 'notifications',
            //     name: 'Notifications',
            //     component: Notifications
            // },
            // {
            //     path: 'advertisement',
            //     name: 'Advertisement',
            //     component: Advertisement
            // }
        ]
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/veterinarypatients',
        name: 'Veterinary Patients',
        component: VeterinaryPatients,
        beforeEnter: authGuard
    },
    {
        path: '/export-data',
        name: 'ExportData',
        component: ExportData,
        beforeEnter: authGuard
    }

];

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
});

export default router
