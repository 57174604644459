const sysparameters = {
    namespaced: true,
    state: () => ({
       parameters: []
    }),
    mutations: {
        setParameters(state, payload){
            const data =  payload.map((item) => {
                if(item.ParameterType == "ModePatient")
                    item.ParameterValue = item.ParameterValue == 'C' ? true : false 
                if(item.ParameterType == "SpeciesList")
                    item.ParameterValue = JSON.parse(item.ParameterValue)
                return item
            })
            state.parameters = data
        },
        
    },
    actions: {
        getAllparams({commit}){

        this._vm.$API.sysParams.getSysparam()
            .then(response => {
                commit('setParameters', response)
            })
            .catch(error => {
                console.log(error)
            })
        }
    }
}

export default sysparameters