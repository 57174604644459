<template>
    <v-card>
        <v-card-title class="text-h5">
            {{$t('vwinvoices_csearchcustomer')}}
        </v-card-title>

        <v-card-text>
            <v-radio-group v-model="type">
                <v-radio label="Cliente" value="regular" checked="true"></v-radio>
                <v-radio label="Negocio" value="business"></v-radio>
            </v-radio-group>

            <GeneralFilter v-if="type == 'business'" :filterEndpoint="$API.customers.filterCustomers" :search="{...search, filteredFrom: 1 }" @emptyFilter="getCustomers" @filtered="filterHandler">
                <DxDataGrid class="isScrolledByGeneralFilter" :data-source="customers" key-expr="ID_Customer">
                    <DxPaging :page-size="10" />
                    <DxColumn :width="50" cell-template="show-template" caption=""></DxColumn>
                    <DxColumn data-field="ID_Customer" caption="ID"></DxColumn>
                    <DxColumn data-field="BusinessName" caption="Nombre del negocio"></DxColumn>
                    <DxColumn data-field="BusinessMobileNumber" caption="Celular"></DxColumn>
                    <DxColumn data-field="BusinessPhone" caption="Télefono"></DxColumn>
                    <DxColumn data-field="BusinessEmail" caption="Correo"></DxColumn>
                    <DxColumn data-field="LegalName" caption="Nombre Legal"></DxColumn>
                    <DxColumn data-field="LegalAddress" caption="Dirección Legal"></DxColumn>
                    <DxColumn data-field="LegalNRC" caption="NRC"></DxColumn>
    
    
                    <template #show-template="{data}">
                        <a href="#" class="mx-1" @click.prevent.stop="$emit('customerSelected', data.data)">
                            <v-icon>mdi-check-bold</v-icon>
                        </a>
                    </template>
                </DxDataGrid>
            </GeneralFilter>

            <GeneralFilter v-else :filterEndpoint="$API.customers.filterCustomers" :search="{ ...search, filteredFrom: 1 }" @emptyFilter="getCustomers" @filtered="filterHandler">
                <DxDataGrid class="isScrolledByGeneralFilter"  :data-source="customers" key-expr="ID_Customer">
                    <DxPaging :page-size="10" />
                    <DxColumn :width="50" cell-template="show-template" caption=""></DxColumn>
                    <DxColumn data-field="ID_Customer" caption="ID"></DxColumn>
                    <DxColumn data-field="LegalName" caption="Nombre Legal"></DxColumn>
                    <DxColumn data-field="LegalAddress" caption="Dirección Legal"></DxColumn>
                   <!--  <DxColumn data-field="FirstName" caption="Nombre"></DxColumn>
                    <DxColumn data-field="LastName" caption="Apellido"></DxColumn> -->
                    <DxColumn data-field="MobileNumber" caption="Celular"></DxColumn>
                    <DxColumn data-field="PhoneNumber" caption="Télefono"></DxColumn>
                    <DxColumn data-field="Email" caption="Correo"></DxColumn>
                    <DxColumn data-field="LegalNIT" caption="DUI o NIT"></DxColumn>
    
    
                    <template #show-template="{data}">
                        <a href="#" class="mx-1" @click.prevent.stop="$emit('customerSelected', data.data)">
                            <v-icon>mdi-check-bold</v-icon>
                        </a>
                    </template>
                </DxDataGrid>
            </GeneralFilter>
        </v-card-text>
    </v-card>
</template>

<script>
    import {DxDataGrid, DxColumn, DxPaging} from 'devextreme-vue/data-grid';
    import GeneralFilter from  "@/components/GeneralFilter";

    export default {
        name: "Search",
        components: {DxDataGrid, DxColumn, GeneralFilter, DxPaging},
        data() {
            return {
                customers: [],
                type: "regular",
                search: {}
            }
        },
        watch: {
            'type'() {
                if (this.type == 'business') {
                    this.search.type = 1
                    this.getCustomers()
                } else {
                    this.search.type = 0
                    this.getCustomers();
                }
            }
        },
        mounted() {
            this.search.business = JSON.parse(localStorage.getItem("user")).businessid
            this.search.branch = JSON.parse(localStorage.getItem("user")).branch
            this.search.type = 0
            this.getCustomers();
        },
        methods: {
            getCustomers() {
                this.$API.customers.getCustomers(this.search)
                    .then(response => {
                        this.customers = response
                    })
            },
            filterHandler(response) {
                this.customers = response
            },
        }
    }
</script>

<style scoped>

</style>