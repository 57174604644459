<template>
  <div>
    <v-card class="border">
      <v-btn
        @click="closeCatalogDialog"
        elevation="0"
        color="red"
        class="close-btn"
        ><v-icon>mdi-close</v-icon></v-btn
      >
      <!-- Add new catalog start -->
      <template v-if="dialogAction === DialogAction.NEW">
        <v-sheet class="box-dialog">
          <h3 class="mb-3">Agregar nuevo catálogo de cuentas</h3>
          <v-form ref="form">
            <v-autocomplete
              v-model="newAccountingLedge.ID_Branch"
              attach
              :rules="requiredRule"
              label="Sucursal"
              :items="branches"
            ></v-autocomplete>

            <!-- Code input start-->
            <v-row>
              <v-col cols="10">
                <v-text-field
                  v-model="newAccountingLedge.account_description"
                  attach
                  label="Nombre"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="newAccountingLedge.id_level"
                  attach
                  type="number"
                  label="Nivel"
                  :rules="rules"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Code input end-->

            <div class="d-flex flex-column">
              <v-btn
                color="success"
                class="mt-4"
                block
                @click="handleAccountingLedge"
              >
                Crear
              </v-btn>
            </div>
          </v-form>
        </v-sheet>
      </template>
      <!-- Add new catalog end -->

      <!-- Add catalog or edit start -->
      <template v-else-if="dialogAction === DialogAction.ADD">
        <v-sheet class="box-dialog">
          <h3 class="mb-3">
            Agregar sub-catálogo de cuentas -
            <span class="span-account_code"
              >Código #{{ oldAccountingCode }}</span
            >
          </h3>
          <v-form ref="form">
            <v-text-field
              v-model="newAccountingLedge.account_description"
              attach
              label="Nombre"
              :rules="requiredRule"
            ></v-text-field>

            <!-- Code input start-->
            <v-row>
              <!-- <v-col cols="8" class="d-flex align-center">
                    <v-autocomplete
                      v-model="newAccountingLedge.account_code"
                      :disabled="disableCode"
                      attach
                      :rules="requiredRule"
                      label="Código padre"
                      :items="
                        nameAndCode.map((item) => ({
                          id: item.id,
                          labelName: item.labelName,
                        }))
                      "
                      item-text="labelName"
                      item-value="id"
                      @change="showNewCode"
                    ></v-autocomplete>
                  </v-col> -->
              <v-col>
                <v-text-field
                  v-model="correlative"
                  :readonly="selectedCatalog.hasChildren && dialogAction != 'add'"
                  type="number"
                  attach
                  required
                  label="Correlativo"
                  :rules="rules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete
                  v-model="newAccountingLedge.id_level"
                  attach
                  readonly
                  item-value="id_level"
                  item-text="level_description"
                  label="Nivel"
                  :items="levels"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <v-checkbox
                  v-model="newAccountingLedge.allow_transactions"
                  label="Cuenta de detalle"
                  required
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                class="my-1 mb-5 d-flex justify-center"
                v-if="!!newCodeToShow"
              >
                <span class="span-newcode"
                  >Nuevo código:
                  <span class="span-account_code"
                    >{{ selectedCatalog.account_code
                    }}<span
                      class="span-correlative"
                      :style="
                        correlativeToShow === ' No válido'
                          ? 'color: red;'
                          : 'color: black;'
                      "
                      >{{ correlativeToShow }}</span
                    >
                  </span></span
                >
              </v-col>
              <!-- <v-col>
                    <v-checkbox
                      v-model="checkboxChildren"
                      label="Hijo"
                      required
                    ></v-checkbox>
                  </v-col>
                  <v-col>
                    <v-checkbox
                      v-model="checkboxSilbing"
                      label="Hermano"
                      required
                    ></v-checkbox>
                  </v-col> -->
            </v-row>
            <!-- Code input end-->

            <div class="d-flex flex-column">
              <v-btn
                color="success"
                class="mt-4"
                block
                @click="handleAccountingLedge"
              >
                Crear
              </v-btn>
            </div>
          </v-form>
        </v-sheet>
      </template>
      <!-- Add catalog or edit end -->

      <!-- Edit catalog start -->
      <template v-else-if="dialogAction === DialogAction.EDIT">
        <v-sheet class="box-dialog">
          <h3 class="mb-3">
            Editar catálogo de cuentas -
            <span class="span-account_code"
              >Código #{{ oldAccountingCode }}</span
            >
          </h3>
          <v-form ref="form">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="newAccountingLedge.account_description"
                  attach
                  label="Nombre"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="newAccountingLedge.account_code"
                  :readonly="selectedCatalog.hasChildren && dialogAction != 'add'"
                  type="number"
                  attach
                  required
                  label="Correlativo"
                  :rules="rules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete
                  v-model="newAccountingLedge.id_level"
                  attach
                  item-value="id_level"
                  item-text="level_description"
                  label="Nivel"
                  :items="levels"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <v-checkbox
                  v-model="newAccountingLedge.allow_transactions"
                  label="Cuenta de detalle"
                  required
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                class="my-1 mb-5 d-flex justify-center"
                v-if="!!newCodeToShow"
              >
                <span class="span-newcode"
                  >Nuevo código:
                  <span class="span-account_code"
                    >{{ selectedCatalog.account_code }}
                  </span></span
                >
              </v-col>
            </v-row>

            <div class="d-flex flex-column">
              <v-btn
                color="success"
                class="mt-4"
                block
                @click="handleAccountingLedge"
              >
                Actualizar
              </v-btn>
            </div>
          </v-form>
        </v-sheet>
      </template>
      <!-- Edit catalog end -->
    </v-card>
  </div>
</template>
  
<script>
export default {
  name: "AddAccountingLedger",

  props: {
    dialogAction: {
      type: String,
      require: true,
    },
    selectedCatalog: {
      type: Object,
      require: true,
    },
    branches: {
      type: Array,
    },
    accountingLedge: {
      type: Object,
      require: true,
    },
    newLevels: {
      type: Array,
      require: true,
    },
  },

  data() {
    return {
      DialogAction: {
        NEW: "new",
        EDIT: "edit",
        ADD: "add",
      },
      correlative: null,
      rules: [(v) => !!v || "Correlativo es requerido"],
      requiredRule: [(v) => v !== null || "Valor es requerido"],
      addCatalogDialog: false,
      addSheetDialog: false,
      checkboxChildren: false,
      checkboxSilbing: false,

      levelDigits: null,
      newAccountingLedge: { ...this.accountingLedge },
      levels: [...this.newLevels],
    };
  },
  mounted() {
    this.handlers[this.dialogAction]();
  },
  watch: {
    selectedCatalog() {
      this.handlers[this.dialogAction]();
    },
  },

  computed: {
    handlers() {
      return {
        add: () => {
          this.newAccountingLedge = { ...this.selectedCatalog };
          const foundLevel = this.levels.find( current => current.order === this.newAccountingLedge.level_order + 1 );
          
          this.newAccountingLedge.id_level = foundLevel.id_level;
          this.levelDigits = foundLevel.digits - this.newAccountingLedge.account_code.length;

          this.rules.push((v) => {
            return (
              RegExp(`^[0-9]{1,${this.levelDigits}}$`).test(v) ||
              `Números 0 al 9, máximo ${this.levelDigits} digitos`
            );
          });
          this.correlative = String(this.selectedCatalog.correlative).padStart(
            this.levelDigits,
            "0"
          );
          console.log(
            this.correlative,
            this.levelDigits,
            String(this.selectedCatalog.correlative).padStart(
              this.levelDigits,
              "0"
            )
          );
        },
        edit: () => {
          this.newAccountingLedge = { ...this.selectedCatalog };
          this.levelDigits = this.levels.filter(
            (current) => current.id_level === this.newAccountingLedge.id_level
          )[0].digits;

          this.rules.push((v) => {
            return (
              RegExp(`^[0-9]{1,${this.levelDigits}}$`).test(v) ||
              `Números 0 al 9, máximo ${this.levelDigits} digitos`
            );
          });

          this.correlative = String(this.selectedCatalog.correlative).padStart(
            this.levelDigits,
            "0"
          );
        },
      };
    },
    oldAccountingCode() {
      return this.selectedCatalog.account_code;
    },
    disableCode() {
      return (
        this.correlative !== null &&
        this.correlative.length > 0 &&
        this.newAccountingLedge.account_code !== null
      );
    },
    // nameAndCode() {
    //   return this.treeListData.map((item) => ({
    //     labelName: `${item.account_description} - ${item.account_code}`,
    //     ...item,
    //   }));
    // },
    newCodeToShow() {
      return this.selectedCatalog.account_code != null;
    },
    correlativeToShow() {
      // console.log('correlative ', this.correlative)
      if(this.dialogAction === this.DialogAction.EDIT) {
        if (
          this.selectedCatalog.account_code !== null &&
          this.selectedCatalog.account_code !== undefined &&
          this.selectedCatalog.account_code.length > 0
        ) {
          // console.log('entro', this.levelDigits);
          if (this.selectedCatalog.account_code.length === this.levelDigits) {
            return this.selectedCatalog.account_code;
          } else if (this.selectedCatalog.account_code.length < this.levelDigits) {
            return `${this.selectedCatalog.account_code}_`;
          } else {
            return " No válido";
          }
        } else {
          return "__";
        }
      } else {
        if (
          this.correlative !== null &&
          this.correlative !== undefined &&
          this.correlative.length > 0
        ) {
          // console.log('entro', this.levelDigits);
          if (this.correlative.length === this.levelDigits) {
            return this.correlative;
          } else if (this.correlative.length < this.levelDigits) {
            return `${this.correlative}_`;
          } else {
            return " No válido";
          }
        } else {
          return "__";
        }
      }
    },
    validate() {
      return !Number(parseInt(this.correlativeToShow));
    },
  },

  methods: {
    clean() {
      this.dialogAction = "";
      this.correlative = null;
      this.newAccountingLedge = {
        id: null,
        branch: null,
        name: null,
        account_code: null,
        level: null,
        id_parent: null,
      };
      this.selectedCatalog = {
        id: null,
        branch: null,
        name: null,
        account_code: null,
        level: null,
        id_parent: null,
      };
    },
    handleAccountingLedge() {
      const dataToSend = {
        ...this.newAccountingLedge,
      };
      if (this.dialogAction === this.DialogAction.EDIT) {
        dataToSend.account_code = `${this.newAccountingLedge.account_code}`;
      }
      if (this.dialogAction === this.DialogAction.ADD) {
        dataToSend.account_code = `${this.newAccountingLedge.account_code}${this.correlative}`;
      }

      if (!this.validate) {
        this.$emit("handleAccountingLedge", {
          action: this.dialogAction,
          data: dataToSend,
        });
        this.closeCatalogDialog();
      } else {
        this.$emit("handleAccountingLedge", {
          action: "error",
        });
        this.closeCatalogDialog();
      }
    },
    closeCatalogDialog() {
      this.clean();
      this.$emit("closeCatalogDialog");
    },
  },
};
</script>

<style scoped>
.bar {
  width: 100%;
  background-color: rgb(60, 60, 60) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-radius: 15px !important;
  margin-top: 1rem !important;
}
.btnblue {
  background-color: #1976d2 !important;
  color: white;
}
.btnadd {
  background-color: #19d257 !important;
  color: white;
}

.text-white {
  color: white;
}

.zero-padding {
  padding: 0 !important;
  margin: 0 !important;
}

.box {
  border-radius: 15px !important;
  padding: 1rem;
}

.box-dialog {
  border-radius: 15px !important;
  padding: 2rem;
}

.border {
  border-radius: 15px !important;
}

.close-btn {
  color: white !important;
  position: relative !important;
  float: right !important;
}

.span-account_code {
  font-style: oblique;
  font-weight: 100;
}

.span-newcode {
  font-weight: bold;
  font-size: 18px;
}
.span-correlative {
  font-weight: 600;
}

#tasks {
  max-height: 540px;
  overflow-y: hidden !important;
}

#tasks .dx-treelist-rowsview td {
  vertical-align: middle;
  padding: 10px !important;
}
</style>