<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            AÑADIR NUEVO USUARIO
            <template v-slot:actions>
                <v-btn class="mr-5" @click.native.stop="reloadUsr++" icon small>
                    <v-icon color="primary">mdi-reload</v-icon>
                </v-btn>
                <v-btn @click="$store.dispatch('deleteWindowByUuid',{uuid : win.uuid})" icon small>
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-card >
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>
                    <v-tab href="#tab-1">
                        AÑADIR USUARIO
                        <v-icon>mdi-account-box</v-icon>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                        <v-card flat class="pa-1">
                            <v-card-text class="white pt-5">
                                <manage-user :key="reloadUsr" ></manage-user>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import ManageUser from "./ManageUser";

    export default {
        name: "AddUser",
        components: {ManageUser},
        props: ['win'],
        data: () => ({
            valid: false,
            reloadUsr: 0,
            tab: null,
            nameRules: [
                v => !!v || 'Name is required',
            ],
            mobileRules: [
                v => !!v || 'Mobile is required',
            ],
            userNameRules: [
                v => !!v || 'User name is required',
            ],
            email: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            modal: false,
            menu2: false,
            userHasContract: false,
            url: null,
            image: null,
            form: {
                birthday: null
            }

        }),
        computed: {
            // url() {
            //     if (!this.image) return;
            //     return URL.createObjectURL(this.image);
            // }
        },
        methods: {
            Preview_image() {
                this.url = URL.createObjectURL(this.image)
            },
            save() {
                let formdata = new FormData();
                formdata.append('file', this.image);
                formdata.append('filetype', 'profile');
                formdata.append('user', JSON.stringify(this.form));

                this.$API.users.addUser(formdata)
                    .then(response => {
                        this.$toasted.success(response.message);
                        this.$store.dispatch('addWindow',{'name':'UsersList',component:'UsersList',unique:false})
                    }).catch(() => {
                })
            }
        }
    }
</script>

<style scoped>

</style>
