import axios from "axios";

export default {
    firmarDTE(data) {
        const options = {
            method: 'POST',
            url: 'http://localhost:8113/firmardocumento/',
            headers: {'content-type': 'application/json'},
            data: data
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    }
}