import axios from "./axios";

export default {
    getCatalogTypeDocument() {
        const options = {
            method: 'GET',
            url: '/catalogtypedocument',
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getCatalogTypeDocumentForProvider() {
        const options = {
            method: 'GET',
            url: '/catalogtypedocument/provider',
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
}