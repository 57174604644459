<template>
    <div class="sidebar-options" @click.prevent.stop style="height: 100%; overflow-y: scroll; z-index: 150 !important;">
        <div class="d-flex justify-space-between align-items-center">
            <p class="ma-0">Opciones de menú lateral</p>
            <a href="#" @click.prevent.stop="$emit('closeOption')">
                <v-icon class="text--primary">mdi-close</v-icon>
            </a>
        </div>

        <div class="sidebar-colors">
            <p class="font-14">Color de menú lateral</p>

            <div class="color-panel">
                
                <a href="#"  v-for="(item, index) in sidebarBGColor" :key="index"
                @click.prevent="sidebarImage ?
                 ($set(form, 'SidebarColor', item.bg), $set(form, 'Color', item.color), colorSelected = item.bg)   :
                  ($set(form, 'SidebarColor', item.bgSolid), $set(form, 'Color', item.color), colorSelected = item.bgSolid)">
                   <!-- make a round circle whit item.color -->
                  
                   <span :class="['badge', 'filter']" :style="{ backgroundColor: item.mainColor }" :data-color="item.mainColor"></span>
                </a>
            </div>
        </div>

        <div class="sidebar-image-check" v-if="$vuetify.breakpoint.mdAndUp">
            <p class="font-14 mb-0">Minimizar menú lateral</p>
            <v-switch
                    label="primary"
                    color="primary"
                    hide-details
                    v-model="minimizeSidebar"
            ></v-switch>
        </div>

      <div class="sidebar-image-check">
            <p class="font-14 mb-0">Imagen de menú lateral</p>
            <v-switch
                    label="primary"
                    color="primary"
                    hide-details
                    v-model="sidebarImage"
            ></v-switch>
        </div> 

        <div class="sidebar-images">
            <p class="font-14 text-center">IMÁGENES</p>
            <ul>
                
                <template v-if="businesstype=='veterinary'">
                    <li v-for="(img, index) in sidebarImagesvet" :key="index">
                        <a href="#" @click.prevent="$set(form, 'SidebarImagePath', img)">
                            <img :src="`${img}`" alt="">
                        </a>
                    </li>
                </template>
                <template v-else-if="businesstype=='bloodtestlaboratory'">
                    <li v-for="(img, index) in sidebarImageslab" :key="index">
                        <a href="#" @click.prevent="$set(form, 'SidebarImagePath', img)">
                            <img :src="`${img}`" alt="">
                        </a>
                    </li>
                </template>
                <template v-else-if="businesstype=='accounting_and_bill'">
                    <li v-for="(img, index) in sidebarImagesbill" :key="index">
                        <a href="#" @click.prevent="$set(form, 'SidebarImagePath', img)">
                            <img :src="`${img}`" alt="">
                        </a>
                    </li>
                </template>
                <template v-else-if="businesstype=='medicalclinic'">
                    <li v-for="(img, index) in sidebarImagesmed" :key="index">
                        <a href="#" @click.prevent="$set(form, 'SidebarImagePath', img)">
                            <img :src="`${img}`" alt="">
                        </a>
                    </li>
                </template>
                <!-- <li v-for="(img, index) in sidebarImages" :key="index">
                    <a href="#" @click.prevent="$set(form, 'SidebarImagePath', img)">
                        <img :src="`${img}`)" alt="">
                    </a>
                </li> -->
            </ul>

            <v-btn color="primary" dark class="mt-2" @click.prevent="showModal = true; imageType = 'sidebar'">Personalizar Imagen de<br/>Menú Lateral</v-btn>
        </div>

        <p class="text-center">Opciones de fondo</p>

        <div class="sidebar-images text-center">
            <p class="font-14">IMÁGENES</p>
            <ul>
                
                <template v-if="businesstype=='veterinary'">
                    <li v-for="(img, index) in backgroundImagesvet" :key="index">
                        <a href="#" @click.prevent="$set(form, 'BackgroundImagePath', img)">
                            <img :src="`${img}`" alt="">
                        </a>
                    </li>
                </template>
                <template v-else-if="businesstype=='bloodtestlaboratory'">
                    <li v-for="(img, index) in backgroundImageslab" :key="index">
                        <a href="#" @click.prevent="$set(form, 'BackgroundImagePath', img)">
                            <img :src="`${img}`" alt="">
                        </a>
                    </li>
                </template>
                <template v-else-if="businesstype=='accounting_and_bill'">
                    <li v-for="(img, index) in backgroundImagesbill" :key="index">
                        <a href="#" @click.prevent="$set(form, 'BackgroundImagePath', img)">
                            <img :src="`${img}`" alt="">
                        </a>
                    </li>
                </template>
                <template v-else-if="businesstype=='medicalclinic'">
                    <li v-for="(img, index) in backgroundImagesmed" :key="index">
                        <a href="#" @click.prevent="$set(form, 'BackgroundImagePath', img)">
                            <img :src="`${img}`" alt="">
                        </a>
                    </li>
                </template>
                <!-- <template v-else>
                    <li v-for="(img, index) in backgroundImages" :key="index">
                    <a href="#" @click.prevent="$set(form, 'BackgroundImagePath', img)">
                        <img :src="`${img}`)" alt="">
                    </a>
                </li>
                </template>
 -->
            </ul>
            <v-btn color="primary" dark class="mt-2" @click.prevent="showModal = true; imageType = 'background'">Personalizar Imagen<br/>de Fondo</v-btn>

        </div>

        <v-dialog v-model="showModal" max-width="500">
            <upload-custom-image :imageType="imageType" @close="showModal = false; imageType = null"></upload-custom-image>
        </v-dialog>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import UploadCustomImage from "../components/UploadCustomImage";
    export default {
        name: "SidebarOptions",
        components: {UploadCustomImage},
        data() {
            return {
                sidebarImage: true,
                minimizeSidebar: false,
                form: {},
                showModal: false,
                colorSelected: null,
                sidebarBGColor: [
                    {bg: 'rgba(59, 151, 211,0.8)', color: 'white', bgSolid: 'rgba(59, 151, 211,1)', mainColor: '#3B97D3'}, 
                    {bg: 'rgba(65, 70, 77,0.8)', color: 'white', bgSolid: 'rgba(65, 70, 77,1)', mainColor: '#41464D'}, 
                    {bg: 'rgba(227, 29, 31,0.8)', color: 'white', bgSolid: 'rgba(227, 29, 31,1)', mainColor: '#E31D1F'}, 
                    {bg: 'rgba(0, 161, 120,0.8)', color: 'white', bgSolid: 'rgba(0, 161, 120,1)', mainColor: '#00A178'}, 
                    {bg: 'rgba(243, 158, 55,0.8)', color: 'black', bgSolid: 'rgba(243, 158, 55,1)', mainColor: '#F39E37'}, 
                    {bg: 'rgba(255, 255, 255,0.8)', color: 'black', bgSolid: 'rgba(255, 255, 255,1)', mainColor: '#FFFFFF'}, 
                    {bg: 'rgba(0,0,0,0.8)',         color: 'white', bgSolid: 'rgba(0, 0, 0,1)',       mainColor: '#000000'}
                ],
                sidebarImages: [
                    '/images/sidebar/sidebar.jpg',
                    '/images/sidebar/sidebar-1.jpg',
                    '/images/sidebar/sidebar-2.jpg',
                    '/images/sidebar/sidebar-3.jpg'
                ],
                sidebarImagesvet: [
                    '/images/vetsid/sidebar.jpg',
                    '/images/vetsid/sidebar-1.jpg',
                    '/images/vetsid/sidebar-2.jpg',
                    '/images/vetsid/sidebar-3.jpg'
                ],
                sidebarImagesbill: [
                    '/images/billside/sidebar.jpg',
                    '/images/billside/sidebar-1.jpg',
                    '/images/billside/sidebar-2.jpg',
                    '/images/billside/sidebar-3.jpg'
                ],
                sidebarImagesmed: [
                    '/images/medside/sidebar.jpg',
                    '/images/medside/sidebar_2.jpg',
                    '/images/medside/sidebar_3.jpg',
                    '/images/medside/sidebar_4.jpg'
                ],
                sidebarImageslab: [
                    '/images/labside/sidebar.jpg',
                    '/images/labside/sidebar-1.jpg',
                    '/images/labside/sidebar-2.jpg',
                    '/images/labside/sidebar-3.jpg'
                ],
                backgroundImages: [
                    '/images/background/bg_1.jpg',
                    '/images/background/bg_2.jpg',
                    '/images/background/bg_3.jpg',
                    '/images/background/bg_4.jpg'
                ],
                backgroundImagesbill: [
                    '/images/billback/bg_1.jpg',
                    '/images/billback/bg_2.jpg',
                    '/images/billback/bg_3.jpg',
                    '/images/billback/bg_4.jpg'
                ],
                backgroundImageslab: [
                    '/images/labback/bg_1.jpg',
                    '/images/labback/bg_2.jpg',
                    '/images/labback/bg_3.jpg',
                    '/images/labback/bg_4.jpg'
                ],
                backgroundImagesvet: [
                    '/images/vetback/bg_1.jpg',
                    '/images/vetback/bg_2.jpg',
                    '/images/vetback/bg_3.jpg',
                    '/images/vetback/bg_4.jpg'
                ],
                backgroundImagesmed: [
                    '/images/medback/bg_1.jpg',
                    '/images/medback/bg_2.jpg',
                    '/images/medback/bg_3.jpg',
                    '/images/medback/bg_4.jpg'
                ],
                imageType: null,
                businesstype: null
            }
        },
        mounted() {
            this.businesstype = JSON.parse(localStorage.getItem("user")).businesstype;
            this.form = JSON.parse(this.customizeOptions)
            this.sidebarImage = JSON.parse(this.customizeOptions).sidebarImageB;
            this.minimizeSidebar = JSON.parse(this.customizeOptions).minimizeSidebar;
            this.colorSelected  = JSON.parse(this.customizeOptions).sidebarColor;

        },
        computed: {
          ...mapState(['user', 'customizeOptions'])
        },
        watch: {
            'form': {
                
                deep: true,
                handler: 'optionsChanged'
            },
            'minimizeSidebar'() {
                if (!this.minimizeSidebar) {
                   
                    this.$emit('toggleDrawer')
                    this.$emit('expandOnHover', false)
                    this.$set(this.form, 'minimizeSidebar', false)
                } else {
                    this.$emit('expandOnHover', true)
                    this.$set(this.form, 'minimizeSidebar', true)
                }
            },
            'sidebarImage'() {
                this.$set(this.form, 'SidebarColor', this.colorSelected)
                this.$set(this.form, 'sidebarColor', this.colorSelected)
                
                if (!this.sidebarImage) {
                   //alert("False1"+ this.form.Color + " " + this.form.sidebarColor.replace("0.8", "1"))
                                    
                   
                    //this.$set(this.form, 'SidebarColor', JSON.parse(this.customizeOptions).SidebarColor)
                     //this.$set(this.form, 'Color', this.sidebarBGColor.color)
                    this.$set(this.form, 'Color', this.form.Color)
                    this.$set(this.form, 'SidebarColor', this.form.sidebarColor.replace("0.8", "1"));

                    this.$set(this.form, 'sidebarImage', 0)
                    this.$set(this.form, 'sidebarImageB', 0)
                    
                } else {
                    //alert("True1"+ this.form.Color + " " + this.form.sidebarColor.replace(",1)", ",0.8)"))
                    
                    //this.$set(this.form, 'SidebarColor', JSON.parse(this.customizeOptions).SidebarColor)
                    this.$set(this.form, 'SidebarColor', this.form.sidebarColor.replace(",1)", ",0.8)"))
                    this.$set(this.form, 'Color', this.form.Color)
                    this.$set(this.form, 'sidebarImage', 1)
                    this.$set(this.form, 'sidebarImageB', 1)
                    
                }
            }
        },
        methods: {
            optionsChanged() {
               
                this.$API.users.customize(this.user.id, {user: JSON.stringify(this.form)})
            }
        }
    }
</script>

<style scoped>

</style>