import axios from "./axios";

export default {
    getAppointmentsOfVeterinaryForms({dateStart, dateEnd}) {
        const options = {
            method: 'GET',
            url: `veterinarycalendar/getAppointmentsOfVeterinaryForms?date_start=${dateStart}&date_end=${dateEnd}`,
            headers: { 'content-type': 'application/json' }
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getPatient(params) {
        const options = {
            method: 'GET',
            url: `veterinarycalendar/getPatient`,
            headers: { 'content-type': 'application/json' },
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    },

    getCustomer() {
        const options = {
            method: 'GET',
            url: `veterinarycalendar/getCustomer`,
            headers: { 'content-type': 'application/json' }
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    postAddAppointment(data) {
        const options = {
            method: 'POST',
            url: `veterinarycalendar/postAddAppointment`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    postDelAppointment(data) {
        const options = {
            method: 'POST',
            url: `veterinarycalendar/postDelAppointment`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageageageage)
                })
        })
    },

    getPatientWithCustomer(params) {
        const options = {
            method: 'GET',
            url: `veterinarycalendar/getPatientWithCustomer`,
            headers: { 'content-type': 'application/json' },
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

}