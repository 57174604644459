<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="mt-2">
            PACIENTE: {{patient.FirstName ? patient.FirstName + " " + patient.LastName : "" }}
            <template v-slot:actions>
                 <v-btn class="mr-5" @click.native.stop="reload()" icon small>
                    <v-icon color="primary">mdi-reload</v-icon>
                </v-btn>
                <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-card >
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>

                    <v-tab href="#tab-1" @click="changeTab('tab-1')">
                        GESTIONAR PACIENTE
                        <v-icon>mdi-account-box</v-icon>
                    </v-tab>

                    <v-tab href="#tab-2" @click="changeTab('tab-2')" v-if="$store.getters['rolesUser/getCustomRole'](32)">
                        EXÁMENES DE <Br/> LABORATORIO
                        <v-icon>mdi-test-tube</v-icon>
                    </v-tab>
                    
                    <template v-if="businesstype === 'medicalclinic'">
                        <v-tab href="#tab-3" @click="changeTab('tab-3')">
                        Historial <Br/> Médico
                        <v-icon>mdi-battery-check-outline</v-icon>
                        </v-tab>
                        <v-tab href="#tab-4" @click="changeTab('tab-4')">
                        Prescripción
                        <v-icon>mdi-notebook-check</v-icon>
                        </v-tab>
                    <template v-if="businesstype === 'medicalclinic2'">
                        <v-tab href="#tab-5" @click="changeTab('tab-5')">
                        Vacunas /<Br/>
                        Medicamentos
                        <v-icon>mdi-needle</v-icon>
                         </v-tab>
                     </template>
                         <v-tab href="#tab-6" @click="changeTab('tab-6')">
                        Cirugías
                        <v-icon>mdi-box-cutter</v-icon>
                    </v-tab>

                    <v-tab href="#tab-7" @click="changeTab('tab-7')">
                        Documentos /<br />
                        Imagenes
                        <v-icon>mdi-file-document-multiple</v-icon>
                    </v-tab>
                    </template>

                    <v-tab href="#tab-8" @click="changeTab('tab-8')" v-if="$store.getters['rolesUser/getCustomRole'](32)">
                        Hospitalización
                        <v-icon>mdi-hospital-box-outline</v-icon>
                    </v-tab>

                </v-tabs>

                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item value="tab-1">
                        <v-card flat>
                            <v-card-text :key="reloadManagmentPatient" class="pa-1">
                                <ManagePatient :key="reloadNumber" :data="patient" :win="win"></ManagePatient>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-2">
                        <v-card flat >
                            <v-card-text :key="reloadLaboratoryTest" class="pa-1">
                                <LaboratoryTest :key="reloadNumber" :patient="patient"></LaboratoryTest>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <template v-if="businesstype === 'medicalclinic'">
                        <v-tab-item value="tab-3">
                        <v-card  background-color="transparent">
                            <v-card-text :key="reloadMedicalH" class="pa-1">
                                <MedicalMedicalH :type="businesstype" :key="reloadNumber" :data="patient" :win="win" />
                            </v-card-text>
                        </v-card>
                        </v-tab-item>
                        <v-tab-item value="tab-4">
                        <v-card background-color="transparent">
                            <v-card-text :key="reloadPrescription" class="pa-1">
                                <MedicalPrescription :type="businesstype" :key="reloadNumber" :data="patient" :win="win"/>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <template v-if="businesstype === 'medicalclinic2'">
                    <v-tab-item value="tab-5">
                        <v-card flat>
                            <v-card-text :key="reloadMedications">
                                <MedicalMedications :type="businesstype" :key="reloadNumber" :data="patient" :win="win" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    </template>
                    <v-tab-item value="tab-6">
                        <v-card flat>
                            <v-card-text :key="reloadSurgery" class="pa-1">
                                <MedicalSurgery :type="businesstype" :key="reloadNumber" :data="patient" :win="win"/>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item value="tab-7">
                        <v-card flat>
                            <v-card-text :key="reloadImages" class="pa-1">
                                <MedicalImages :type="businesstype" :key="reloadNumber" :data="patient" :win="win"/>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    </template>
                    <v-tab-item value="tab-8">
                        <v-card  flat>
                            <v-card-text :key="reloadManageMedicalHospitalization" class="pa-1 mt-3">
                                <ManageMedicalHospitalization :type="businesstype" :key="reloadNumber" :data="patient" :win="win"></ManageMedicalHospitalization>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import {mapState} from 'vuex';
    import ManagePatient from './partial/ManagePatient';
    import LaboratoryTest from './partial/LaboratoryTest';
    import MedicalMedicalH from '@/views/patients/partial/MedicalMedicalH';
    import MedicalMedications from '@/views/patients/partial/MedicalMedications';
    import MedicalSurgery from '@/views/patients/partial/MedicalSurgery';
    import MedicalImages from '@/views/patients/partial/MedicalImages';
    import MedicalPrescription from '@/views/patients/partial/MedicalPrescription';
    import ManageMedicalHospitalization from '@/views/patients/partial/ManageMedicalHospitalization';
    

    export default {
        name: "PatientData",
        props: ['win'],
        data() {
            return {
                patient: this.win.meta.patient,
                reloadNumber: 0,
                showReload: true,
                businesstype: null,
                tab: null,
            }
        },
        computed: {
            ...mapState('patientData', {
                tabState: state => state.currentView,
                reloadManagmentPatient: state => state.reloaders.reloadManagmentPatient,
                reloadLaboratoryTest: state => state.reloaders.reloadLaboratoryTest,
                reloadMedicalH: state => state.reloaders.MedicalH,
                reloadMedications: state => state.reloaders.Medications,
                reloadSurgery: state => state.reloaders.Surgery,
                reloadImages: state => state.reloaders.Images,
                reloadPrescription: state => state.reloaders.Prescription,
                reloadManageMedicalHospitalization: state => state.reloaders.ManageMedicalHospitalization,
            }),
            
        },
        watch: {
            tabState() {
                this.tab = this.tabState
            },
        },
        components: {
            ManagePatient, 
            LaboratoryTest, 
            MedicalSurgery, 
            MedicalImages, 
            MedicalPrescription, 
            MedicalMedications, 
            MedicalMedicalH,
            ManageMedicalHospitalization
        },
        mounted() {
            //this.patient = this.win.meta.patient;
            this.reloadNumber++;
            this.businesstype=JSON.parse(localStorage.getItem("user")).businesstype || "";
            this.showReload = true;
        },
        methods: {
            reloadData(payload) {
                this.$emit('reloadData', payload)
            },

            reload() {
                //this.$store.commit('patientData/setReloaders', 'reloadManagmentPatient')
                //this.$store.commit('patientData/setReloaders', 'reloadLaboratoryTest')
                this.reloadNumber++
                this.showReload = true
            },
            changeTab(tab){

                
                        this.$store.commit('patientData/changeView', tab)
                      
                        
                       
            
                setTimeout(() => {
                window.scrollTo(0, this.$store.getters.getScrollTop)
                
                },300);
            },
            
        }

    }
</script>

<style scoped>

</style>
