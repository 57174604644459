<template>
  <div>
    <v-card flat>
      <v-card-text>
        <template v-if="type=== 'medicalclinic'">
            <user-profile-lab :patient="data" :patientinfo="data" :useData="useData"></user-profile-lab>
        </template>
        <template v-else>
            <user-profile :patientinfo="patientinfo" :data="data" :useData="useData" />
        </template>
        <v-row style="margin-top: 1rem;">
        

      <v-col cols="12" class="box" style="margin-top: 1rem;" >
        <v-form v-model="valid" class="position-relative">
          <block v-if="loading"></block>
          
              <v-row class="ml-2 mr-2"  style="margin-top: .3rem;">
                <v-col  cols="12"  class="tabsNh">
                                  
              <v-col v-if="surgery" cols="12" style="display: flex;justify-content: space-between;">
                  <div style="width: 212px;">
                    <v-btn
                    v-if="$store.getters['rolesUser/getCustomRole'](151)"
                    small
                    color="error"
                      @click.prevent.stop="showAlert('question','confirmar','esta seguro de eliminar el elemento esta accion no se puede deshacer','deletesurgery'); surgerytoDelete= surgery;"
                    >
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </div>
                  <div>
                      <v-btn v-if="$store.getters['rolesUser/getCustomRole'](107)" small class="btn-add mr-1" @click.prevent="getItemsToBeBilled(); showBillPatient = true">
                          <v-icon class="mr-3">mdi-account-details</v-icon> Facturar paciente
                      </v-btn>
                  </div>
                  <div>
                    <v-btn 
                    small
                    @click="print = { show: true, data: { surgery: form, patientinfo: patientinfo, useData: useData, customer: patientinfo.customer} }"
                    style="margin-right: .3rem;"
                    v-if="$store.getters['rolesUser/getCustomRole'](152)"
                                  >
                    <v-icon> mdi-printer </v-icon>
                                    </v-btn>
                                    <v-btn
                                    v-if="$store.getters['rolesUser/getCustomRole'](149)"
                                     small class="btnblue mr-1" @click="showhistory = !showhistory" >
                                                    <v-icon>
                                                        mdi-magnify
                                                    </v-icon>
                                                </v-btn>
                                    <v-btn
                    class="btnclean"
                    @click.prevent="reset"
                    small
                  >
                  <v-icon>
                              mdi-broom
                            </v-icon>
            
                  </v-btn>

                    <v-btn
                    small
                    v-if="$store.getters['rolesUser/getCustomRole'](150)"
                        class="btnsave"
                    
                        @click.prevent="updateSurgery"
                      >
                        <v-icon>
                          mdi-content-save
                        </v-icon>
                      </v-btn>
                  </div>
                </v-col>
          
              <v-col cols="12"  style="display: flex;justify-content: space-between; " v-if="surgery == null && selectedvalues">

                <div style="width: 212px;" ></div>
                <div>
                    <v-btn v-if="$store.getters['rolesUser/getCustomRole'](107)" small class="btn-add mr-1" @click.prevent="getItemsToBeBilled(); showBillPatient = true">
                        <v-icon class="mr-3">mdi-account-details</v-icon> Facturar paciente
                    </v-btn>
                </div>
                <div>
                  <v-btn 
                  small
                  
                  @click="print = { show: true, data: { surgery: form, patientinfo: patientinfo, useData: useData, customer: patientinfo.customer} }"
                  v-if="$store.getters['rolesUser/getCustomRole'](152)"
                      style="margin-right: .3rem;"
                  >
                      <v-icon> mdi-printer </v-icon>
                     
              
                    </v-btn>
                    <v-btn v-if="$store.getters['rolesUser/getCustomRole'](149)" small class="btnblue mr-1" @click="showhistory = !showhistory" >
                                                      <v-icon>
                                                          mdi-magnify
                                                      </v-icon>
                                                  </v-btn>
                    <v-btn
                      small
                      class="btnclean"
                      @click.prevent="reset"
                      
                    >
                    <v-icon>
                                mdi-broom
                              </v-icon>
                    </v-btn>
                      <v-btn
                      small
                        class="btnsave"
                        v-if="$store.getters['rolesUser/getCustomRole'](150)"
                        @click.prevent.stop="addSurgery"
                       style="">
                        <v-icon>
                          mdi-content-save
                        </v-icon> 
                      </v-btn>
                </div>
              
                  
              </v-col>
                </v-col>
                <v-col cols="12" md="2" sm="12" style="margin-top: 1rem ;">
                 
                <date-picker
                  label="Fecha"
                  @dateSelected="
                    (param) =>{

                      form.SurgeryDate = param
                      checktodaySurgery(param)
                    }
                  "
                  :value="form.SurgeryDate"
                ></date-picker>
               
                
              </v-col>
              <v-col cols="6" md="2" style="margin-top: 1rem">
                  <v-text-field label="Peso" dense outlined v-model="weight" @change="changeWeight"></v-text-field>
              </v-col>
              <v-col cols="6" md="2" style="margin-top: 1rem">
                  <v-select outlined dense attach @change="switchWeightUnit" v-model="weightunit" :items="['Kg', 'Lb']" label="Unidad de peso"></v-select>
              </v-col>
              
              <v-col cols="12" md="2" style="display: flex; justify-content: flex-end; margin-top: 1rem">
                <v-btn
                  @click="showComments = !showComments"
                  :class="form.Comment ? 'btnclean' : 'btnblack'"
                  >
                  Comentarios
                </v-btn>
              </v-col> 
          
            <v-col cols="12" md="7" style="display: flex;justify-content: flex-end; height: 2rem;"></v-col>
                
                
                  <!--v-btn @click.prevent="">
                    <v-icon> mdi-mail </v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn dark color="green" @click.prevent="">
                    <v-icon> mdi-whatsapp </v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn @click.prevent="">
                    <v-icon> mdi-file-excel </v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn @click.prevent="">
                    <v-icon> mdi-xml </v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn @click.prevent="">
                    <v-icon> mdi-file-pdf-box </v-icon>
                  </v-btn-->
                
              
              <v-row>
                <v-col cols="12" md="4">
                  <br />
                  <h3>MOTIVO DE LA OPERACIÓN</h3>
                  <span  class="ql-editor150">
                    <quill-editor :options="quillOptions"
                      v-model="form.ReasonForOperation"
                    ></quill-editor>
                  </span>
                <p>



                </p>
            
                </v-col>

                <v-col cols="12" md="4">
                  <br />
                  <h3>PROCESO</h3>
                  <span class="ql-editor150">
                    <quill-editor  :options="quillOptions" v-model="form.Process"></quill-editor> 
                  </span>
                </v-col>

                <v-col cols="12" md="4">
                  <br />
                  <h3>RESULTADO</h3>
                  <span  class="ql-editor150">  
                    <quill-editor :options="quillOptions" v-model="form.Outcome"></quill-editor>
                  </span>
                </v-col>
              </v-row>
           
              <v-col style="margin-top: .6rem; margin-bottom: 1.0rem;">
             
              
              </v-col>
            </v-row>
         
        </v-form>
      </v-col>
    </v-row>

    <!-- <v-dialog v-model="deleteDialog" v-if="surgery" max-width="700">
      <DeleteUserDialog
        :data="surgery"
        :name="'Surgery'"
        v-if="deleteDialog"
        @close="deleteDialog = false"
        @f_call="deleteSurgery($event)"
      ></DeleteUserDialog>
    </v-dialog> -->
    <v-dialog
      v-model="showhistory"
      scrollable
      :width="'90%'"
      >
      <v-card class="pa-4">
        <v-card-text>
          <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
              <v-btn small class="btndelete" @click.prevent="showhistory = false" >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <GeneralFilter  class="background border-box" entity="cliente" :filterEndpoint="$API.surgery.getFilterSurgeries" :search="search" @emptyFilter="getSurgeries" @filtered="filterHandler">


              <DxDataGrid 
                class="isScrolledByGeneralFilter"
                :show-borders="true"
                :data-source="surgeries"
                key-expr=""
                @exporting="onExporting"
                :column-auto-width="true"
                style="width: fit-content;"
              >
                <DxExport :enabled="true" />
                <DxColumn
                  :width="80"
                  cell-template="show-template"
                  caption=""
                ></DxColumn>
                <DxPaging :page-size="10"/>
                <DxColumn :min-width="100" data-field="SurgeryDate" caption="Fecha"></DxColumn>
                <DxColumn :min-width="100" data-field="ID_Surgery" caption="Id"></DxColumn>
                <DxColumn :min-width="100" data-field="Weight" caption="Peso"></DxColumn>
                <DxColumn :min-width="200" data-field="FullName" caption="Atendido por"></DxColumn>
                <DxColumn :min-width="300" data-field="ReasonForOperationTable" caption="Motivo de la operacion" cssClass="left-align"></DxColumn>
                <DxColumn :min-width="300" data-field="ProcessTable" caption="Proceso" cssClass="left-align"></DxColumn>
                <DxColumn :min-width="300" data-field="OutcomeTable" caption="Resultado" cssClass="left-align"></DxColumn>
                <DxColumn
                  :width="80"
                  v-if="$store.getters['rolesUser/getCustomRole'](151)"
                  cell-template="show-delete"
                  caption=""
                ></DxColumn>
                <template #show-template="{ data }">
                  <div>
                    <a href="#" @click.prevent.stop="getSurgeryDetails(data); selectedvalues= false;showhistory= false">
                      <v-icon color="primary">mdi-eye</v-icon>
                    </a>
                  </div>
                </template>
                <template #show-delete="{ data }">
                <div>
                  <a
                      href=""
                      class="mx-2"
                      @click.prevent.stop="showAlert('question','confirmar','esta seguro de eliminar el elemento esta accion no se puede deshacer','deletesurgery'); surgerytoDelete= data;showhistory= false"
                    >
                      <v-icon color="error">mdi-delete</v-icon>
                    </a>
                </div></template>
                
              </DxDataGrid>
              </GeneralFilter>
        </v-card-text>
      </v-card>

      </v-dialog>
    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    ></alerts>
    <surgery :data="print"></surgery>
    <!-- Dialog bill patient -->
    <v-dialog v-model="showBillPatient" width="90%">
          <v-card class="py-3 px-3">
              <v-card-title class="text-h5">
              Facturar paciente 
              </v-card-title>

              <v-card-actions>
              <v-row>
                  <v-col cols="12" class="tabsNh" style="padding: 14px">
                  <v-col style="display: flex; justify-content: space-between; min-height: 50px">
                      <div>
                      <!-- <v-btn v-if="!showServicesToAdd" small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                          <v-icon>mdi-menu-down</v-icon>
                          Ver items
                      </v-btn>
                      <v-btn v-else small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                          <v-icon>mdi-menu-up</v-icon>
                          Ocultar
                      </v-btn> -->
                      </div>
                      <div>
                      <v-btn small class="btn-add" @click.prevent="saveItemsToBeBilled()">
                          <v-icon>mdi-content-save</v-icon>
                          Guardar
                      </v-btn>
                      </div>

                      <div>
                      <v-btn small class="btnsave" @click.prevent="billedPatientsOfCustomer()">
                          <v-icon>mdi-cash-register</v-icon>
                          Facturar cliente
                      </v-btn>
                      </div>
                  </v-col>
                  </v-col>
              </v-row>
              </v-card-actions>
              <div style="height: 500px; overflow-y: auto; overflow-x: hidden; margin-top: 20px; margin-bottom: 20px; padding: 0 20px;">
              <!-- Services default -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver servicios por defecto
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide1 = !togglehide1' v-show='!togglehide1' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide1 = !togglehide1' v-show='togglehide1' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide1">
                  <DxDataGrid class="isScrolledByGeneralFilter dataGridService" :data-source="servicesDefault" 
                  :show-borders="true" :column-auto-width="true" style="width: fit-content;">
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="CodeService" header-cell-template="headerCodeService" />
                      <template #headerCodeService="{ }">
                      <div>Código de<br />servicio</div>
                      </template>
                      <DxColumn data-field="NameService" header-cell-template="headerNameService" />
                      <template #headerNameService="{ }">
                      <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" header-cell-template="headerName" />
                      <template #headerName="{ }">
                      <div>Nombre a<br />facturar</div>
                      </template>
                      <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice" />
                      <template #headerTypeservice="{ }">
                      <div>Tipo de<br />servicio</div>
                      </template-->
                      <DxColumn :width="65" data-field="Price" caption="Precio" />
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""/>
                      <template #show-template="{ data }">
                      <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                          <v-icon>mdi-plus</v-icon>
                      </a>
                      </template>
                      <template #quantity-template="{ data }">
                      <p>{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService).quantity }}</p>
                      </template>
                  </DxDataGrid>
                  </v-col>
              </v-row>

              <!-- Services to select -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver todos los servicios
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide2 = !togglehide2' v-show='!togglehide2' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide2 = !togglehide2' v-show='togglehide2' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide2">
                  <GeneralFilter 
                      :filterEndpoint="$API.services.getAllServicesFiltered" 
                      :search="searchToBill"
                      @emptyFilter="getServices" 
                      @filtered="servicesFilterHandler"
                  >
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="servicesToBill"
                      key-expr="CodeService" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="CodeService" caption="ID"></DxColumn>
                      <DxColumn data-field="NameService" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice">
                      </DxColumn-->
                      <!--template #headerTypeservice="{ }">
                          <div>Tipo de<br />servicio</div>
                      </template-->
                      <DxColumn :width="65" data-field="Price" caption="Precio"></DxColumn>
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                          <v-icon>mdi-plus</v-icon>
                          </a>
                      </template>
                      <template #quantity-template="{ data }">
                          <p style="margin: 0;">{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService)?.quantity || '' }}</p>
                      </template>
                      </DxDataGrid>
                  </GeneralFilter>
                  </v-col>
              </v-row>

              <!-- Products to select -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver todos los productos
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide3 = !togglehide3' v-show='!togglehide3' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide3 = !togglehide3' v-show='togglehide3' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide3">
                  <GeneralFilter 
                      :filterEndpoint="$API.products.getProductsToBillForInvoiceFiltered" 
                      :search="searchToBill"
                      @emptyFilter="getProducts" 
                      @filtered="productsFilterHandler"
                  >
                      <v-row style="padding: 1rem;">
                          <v-col cols="12" md="3" sm="4">
                              <v-autocomplete
                              v-model="selectedWarehouse"
                              item-text="Warehouse"
                              item-value="ID_Warehouse"
                              :items="warehouses"
                              @change="handleSelectWarehouse"
                              label="Almacenes"
                              ref="werehouseRef"
                              dense 
                              outlined 
                              attach
                              >
                              </v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="9" sm="8">
                              <p style="color: #e3191e !important; font-size: 12px; margin: 12px 0" v-if="warehouses.length == 0">No existen almacenes de productos para pacientes.</p>
                          </v-col>
                      </v-row>
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="productsToBill"
                      key-expr="ID_Item" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="ID_Item" caption="ID"></DxColumn>
                      <DxColumn data-field="ItemName" header-cell-template="headerName"></DxColumn>
                      <template #headerName="{ }">
                          <div>Nombre del<br />producto</div>
                      </template>
                      <DxColumn data-field="ItemComercialName" header-cell-template="headerNameComercial"></DxColumn>
                      <template #headerNameComercial="{ }">
                          <div>Nombre<br />comercial</div>
                      </template>
                      <DxColumn :width="65" data-field="ItemPrice" caption="Precio"></DxColumn>
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="addProduct(data.data)">
                          <v-icon>mdi-plus</v-icon>
                          </a>
                      </template>
                      <template #quantity-template="{ data }">
                        <p style="margin: 0;">{{ productsToAdd.find(item => item.ID_Item == data.data.ID_Item)?.quantity || '' }}</p>
                      </template>
                      </DxDataGrid>
                  </GeneralFilter>
                  </v-col>
              </v-row>

              <!-- ServiceToAdd -->
              <v-row style="margin-top: 2rem">
                  <v-col cols="12">
                  <h4 style="padding: 16px;">Servicios seleccionados</h4>
                  <div style="overflow-x: scroll; padding: 0 12px;">
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="servicesToAdd"
                      key-expr="CodeService" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"    
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="5" />
                      <DxColumn data-field="CodeService" :allow-editing="false" caption="ID"></DxColumn>
                      <DxColumn data-field="NameService" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <DxColumn data-field="TypeService" :allow-editing="false" header-cell-template="headerTypeservice">
                      </DxColumn>
                      <template #headerTypeservice="{ }">
                          <div>Tipo de<br />servicio</div>
                      </template>
                      <DxColumn data-field="quantity" caption="Cantidad">
                          <DxFormat typed="fixedPoint" :precision="0" />
                          <DxRequiredRule message="La cantidad del servicio a facturar es requerida" />
                          <DxPatternRule :pattern="itemQtyPattern"
                          message="La cantidad del servicio facturado solo puede contener numeros enteros mayores a 0" />
                      </DxColumn>
                      <DxColumn data-field="Price" :allow-editing="true" caption="Precio"></DxColumn>
                      <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                      <DxColumn cell-template="del-template" caption=""></DxColumn>
                      <template #del-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="deleteService(data.data)">
                          <v-icon>mdi-delete</v-icon>
                          </a>
                      </template>
                      <template #total-template="{ data }">
                          <div>
                          {{ totalXQuantity( data.data ) }}
                          </div>
                      </template>
                      </DxDataGrid>
                  </div>
                  </v-col>
              </v-row>

              <!-- Product to add -->
              <v-row style="margin-top: 2rem">
                  <v-col cols="12">
                  <h4 style="padding: 16px;">Productos seleccionados</h4>
                  <div style="overflow-x: scroll; padding: 0 12px;">
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="productsToAdd"
                      key-expr="ID_Item" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"    
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="5" />
                      <DxColumn data-field="ID_Item" :allow-editing="false" caption="ID"></DxColumn>
                      <DxColumn data-field="ItemName" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre del<br />producto</div>
                      </template>
                      <DxColumn data-field="ItemComercialName" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <DxColumn data-field="ItemType" :allow-editing="false" header-cell-template="headerTypeservice">
                      </DxColumn>
                      <template #headerTypeservice="{ }">
                          <div>Tipo de<br />producto</div>
                      </template>
                      <DxColumn data-field="quantity" caption="Cantidad">
                          <DxFormat typed="fixedPoint" :precision="0" />
                          <DxRequiredRule message="La cantidad del producto a facturar es requerida" />
                          <DxPatternRule :pattern="itemQtyPattern"
                          message="La cantidad del producto facturado solo puede contener numeros enteros mayores a 0" />
                      </DxColumn>
                      <DxColumn data-field="ItemPrice" :allow-editing="true" caption="Precio"></DxColumn>
                      <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                      <DxColumn cell-template="del-template" caption=""></DxColumn>
                      <template #del-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="deleteProduct(data.data)">
                          <v-icon>mdi-delete</v-icon>
                          </a>
                      </template>
                      <template #total-template="{ data }">
                          <div>
                          {{ totalXQuantity( {...data.data, Price: data.data.ItemPrice} ) }}
                          </div>
                      </template>
                      </DxDataGrid>
                  </div>
                  </v-col>
              </v-row>

              </div>
          </v-card>
    </v-dialog>
    <!-- Dialog bill patient -->
    <v-dialog
      v-model="showComments"
      max-width="60%"
      >
      <v-card class="pa-4">
        <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
            <v-btn small class="btndelete" @click.prevent="showComments = false" >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        <v-col cols="12" md="12" style="">
                          <quill-editor v-model="form.Comment" :options="quillOptions"></quill-editor>
                        </v-col>
      </v-card>
    </v-dialog>
    </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import Block from "@/components/Block";
import { DxDataGrid, DxColumn, DxPaging, DxScrolling, DxEditing, DxFormat, DxPatternRule, DxRequiredRule, DxExport } from "devextreme-vue/data-grid";
import DatePicker from "../../../components/DatePicker";
import Surgery from "../../../components/ReportsAndPrintings/Surgery.vue";
import { printToExcel } from "@/helpers/printToexcel";
import GeneralFilter from  "@/components/GeneralFilter";
//import DeleteUserDialog from "../../settings/business/partial/DeleteTemplateDialog.vue";
import Alerts from "@/components/Alerts";
import UserProfile from "@/components/Displays/UserProfileDisplay.vue";
import UserProfileLab from "@/components/Displays/UserProfileDisplayLab.vue";
import { numberWithCommas } from "@/helpers/money";

import { mapState } from "vuex";
export default {
  name: "VeterinarySurgery",
  components: {
    DxDataGrid, DxColumn, DxEditing, DxPaging, DxFormat, DxPatternRule, DxScrolling, DxRequiredRule,
    DxExport,
    DatePicker,
    Block,
    Alerts,
    Surgery,
    GeneralFilter,
    UserProfile,
    UserProfileLab,
    // DeleteUserDialog,
  },
  props: ["data", "win","type"],
  data: () => ({
    currency: JSON.parse(localStorage.getItem('branch')).Currency,
    itemQtyPattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/i,
    showBillPatient: false,
    useData: {},
    showServicesToAdd: false,
    showProductsToAdd: false,
    servicesToBill: [],
    productsToBill: [],
    servicesToAdd: [],
    productsToAdd: [],
    filterProducts: {
        filter: null
    },
    searchToBill: {
        filter: null
    },
    togglehide1: true,
    togglehide2: true,
    togglehide3: true,
    warehouses: [],
    selectedWarehouse: null,
    customer: {},
    showhistory: false,
    print: {
      show: false,
      data: []
    },
    alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
    valid: false,
    url: null,
    patientinfo: [],
    weightunit: '',
    weight: '',
    surgeries: [],
    surgery: null,
    loading: false,
    deleteDialog: false,
    form: {
      SurgeryDate: "",
      ReasonForOperation: "",
      Process: "",
      Outcome: "",
    },
    loginUserID: null,
    surgerytoDelete: null,
    selectedvalues: true,
    showSurgeries: false,
    showComments: false,
    search: {},
    quillOptions: {
        modules: {
          toolbar: {
            container:
        [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'align': [] }],

        ['clean'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'formula': '' }]
         ],
            handlers: {}
          }
        },
        theme: 'snow'
      },
      //useData: false,
  }),
  computed:{
    ...mapState(
      "rolesUser",
      {
        rolesStatus: (state) => state.roles,
      }),
      columnWidth() {
        return this.$vuetify.breakpoint.smAndDown ? "150px" : "auto";
      },

  },
  async mounted() {
    this.$store.dispatch('rolesUser/getAllRoles')
    this.loginUserID = JSON.parse(localStorage.getItem("user")).id;
    this.useData = JSON.parse(localStorage.getItem("user"));
    if (this.data) {
      Object.keys(this.data).map((el) => {
        Vue.set(this.patientinfo, el.toLowerCase(), this.data[el]);
      });
      this.search = {
        patient: this.patientinfo.id_patient,
      };
      this.url =
        this.$store.getters.getBackendUrl +
        "/public" +
        this.data.ProfilePicPath;
      this.form.Weight = this.data.Weight;
      this.branchConfig = JSON.parse(localStorage.getItem("branch"));
      if(this.branchConfig?.weightunit) this.weightunit = this.branchConfig.weightunit;
      if(this.data?.weightunit) this.weightunit = this.data.weightunit;
    }
    const localparams = JSON.parse(localStorage.getItem("localparams")) || null;
    if (localparams && localparams.weightunit) this.weightunit = localparams.weightunit;

    if(this.form && this.form.Weight.includes('Kg')) this.weightunit = 'Kg';
    if(this.form && this.form.Weight.includes('Lb')) this.weightunit = 'Lb';

    if(this.form.Weight && this.form.Weight.includes(this.weightunit)) {
        this.weight = this.form.Weight.slice(0, -2).replace(' ', '');
    } else {
        this.weight = this.form.Weight.replace(' ', '');
    }
    await this.getSurgeries()

    //this.useData = JSON.parse(localStorage.getItem("user"));
    this.customer.ID_Customer = this.data.ID_Customer;
    console.log('customer', this.customer);
    this.warehouses = await this.$API.warehouse.getWarehousesFiltered({ isPatient: true });
    this.filterProducts.business = this.useData.businessid;
    this.filterProducts.branch = this.useData.branch;
    this.selectedWarehouse = this.warehouses.length == 0 ? 0 : this.warehouses[0].ID_Warehouse;
    this.filterProducts.warehouse = this.selectedWarehouse;    
    await this.getServices();
    await this.getProducts();
    await this.getDefaultServices();
  },
  watch: {
    servicesToAdd: {
        deep: true,
        immediate: true,
        handler() {
            if(this.servicesToAdd.length !== 0) {
                this.showServicesToAdd = true;
            }
            else {
                this.showServicesToAdd = false;
            }
        }
    },
    productsToAdd: {
        deep: true,
        immediate: true,
        handler() {
            if(this.productsToAdd.length !== 0) {
                this.showProductsToAdd = true;
            }
            else {
                this.showProductsToAdd = false;
            }
        }
    },
  },
  methods: {
    totalXQuantity( { Price, quantity } ) {
        return `${this.currency}${ numberWithCommas( parseFloat( Price * quantity ).toFixed( 2 ) ) }`
    },
    async handleSelectWarehouse() {
        this.loading = true;
        this.filterProducts.warehouse = this.selectedWarehouse;
        await this.getProducts();
        this.$refs.werehouseRef.blur();
        this.loading = false;
    },
    addService(data) {
        const isRepetitive = this.servicesToAdd.find((service) => service.ID_Service === data.ID_Service);

        if (!isRepetitive) {
            this.servicesToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.Price), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.Price).toFixed(4))}` });
        } else {
            const service = this.servicesToAdd.find(item => item.ID_Service === data.ID_Service)
            if(service) {
                service.quantity++;
            }
            console.log("Service already exists in servicesToAdd");
        }
    },
    addProduct(data) {
        const isRepetitive = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
        if (!isRepetitive) {
            this.productsToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.ItemPrice), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.ItemPrice).toFixed(4))}` });
        } else {
            const product = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
            if(product) {
                product.quantity++;
            }
            console.log("Product already exists in productsToAdd.");
        }
    },
    deleteService(data) {
        const serviceIndex = this.servicesToAdd.findIndex((service) => service.CodeService === data.CodeService);

        if (serviceIndex !== -1) {
            this.servicesToAdd.splice(serviceIndex, 1);
        }
    },
    deleteProduct(data) {
        const index = this.productsToAdd.findIndex((item) => item.ID_Item === data.ID_Item);

        if (index !== -1) {
            this.productsToAdd.splice(index, 1);
        }
    },
    getServices() {
        this.$API.services.getAllServices(this.filterServices).then((response) => {
            this.servicesToBill = response;
        });
    },
    getProducts() {
        this.$API.products.getProductsToBillForInvoice(this.filterProducts).then((response) => {
            this.productsToBill = response;
        });
    },
    getDefaultServices() {
        this.$API.services.getDefaultServices().then((response) => {
            this.servicesDefault = response;
        });
    },
    servicesFilterHandler(response) {
        this.servicesToBill = response;
    },
    productsFilterHandler(response) {
        this.productsToBill = response;
    },
    getItemsToBeBilled() {
        this.$API.veterinarypatients.getServicesToBeBilled({ 'customer': this.data.ID_Customer })
        .then((response) => {
            this.servicesToAdd = []
            for(let i = 0; i < response.length; i++){
            this.servicesToAdd.push({ ...response[i], unitPrice: `${this.currency}${numberWithCommas(parseFloat(response[i].Price).toFixed(4))}` });
            }
        });
        this.$API.veterinarypatients.getProductsToBeBilled({ 'customer': this.data.ID_Customer }).then((res) => {
            if(res.success) {
            this.productsToAdd = [];
            for (const item of res.data) {
                this.productsToAdd.push({ ...item, quantity: item.Quantity, unitPrice: `${this.currency}${numberWithCommas(parseFloat(item.ItemPrice).toFixed(4))}`});
            }
            }
        });
    },
    async saveItemsToBeBilled() {
        this.showBillPatient = false;
        this.loading = true;

        try {
            if(this.servicesToAdd.length) {
            await this.$API.veterinarypatients.saveServicesToBeBilled({ 'customer': this.customer.ID_Customer, 'services': this.servicesToAdd })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                throw new Error('Ocurrio un error al guardar los servicios.');
            });
            }
            if(this.productsToAdd.length) {
            console.log(this.productsToAdd);
            
            await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                throw new Error('Ocurrio un error al guardar los productos.');
            });
            }
            await this.getItemsToBeBilled();
            this.loading = false;
            this.showAlert("success", "Exito", "Los datos de facturación de este paciente han sido guradados.")
        } catch (error) {
            console.log(error);
            this.loading = false;
            this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
        }

    },
    async billedPatientsOfCustomer() {
        this.showBillPatient = false;
        this.loading = true;

        try{
            await this.$API.veterinarypatients.saveServicesToBeBilled({'customer': this.customer.ID_Customer, 'services': this.servicesToAdd});
            await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd });
            await this.$API.veterinarypatients.billedPatientsOfCustomer({ 'customer': this.customer.ID_Customer});

            this.loading = false;

            this.showAlert("success", "Exito", `Se ha realizado la factura de los ${this.servicesToAdd.length && this.productsToAdd.length ? 'servicios y productos' : this.servicesToAdd.length ? 'servicios' : this.productsToAdd.length ? 'productos' : 'items' } seleccionados, podrá observarla en el módulo de POS en listo para facturar.`);
            await this.getItemsToBeBilled();        
        }
        catch(error) {
            console.log(error)
            this.loading = false
            this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
        }
    },

    onExporting(e){
      printToExcel(e, 'Cirugias')
    },
    checktodaySurgery(date){
      /*console.log(date)
      let datetoday = false
      this.surgeries.forEach(el=>{

        if(el.SurgeryDate == date){
          let data  ={
                data:{
                  ID_Surgery: el.ID_Surgery,
                }
              }
          this.getSurgeryDetails(data)
          datetoday = true
        }
      })
      console.log(datetoday)
      if(!datetoday){
        this.reset()
        this.form.SurgeryDate = date
      }*/
     this.form.SurgeryDate = date
    },
    changeWeight(e) {
      this.form.Weight = e.replace(' ', '');
      console.log('weigth', this.form.Weight);
    },
    switchWeightUnit(e) {
      this.weightunit = e;
      console.log(this.weightunit);
    },
    filterHandler(response) {
      this.surgeries = response.map(
        (el) => {
          el.ReasontoShow = el.ReasonForOperation.replace(/<\/?[^>]+(>|$)/g, "");
          el.ReasontoShow = el.ReasontoShow.substring(0, 50);
          return {
            ...el,
          };
        }
      )
    },
    getSurgeries() {
      this.loading = true;
      let params = { patient: this.patientinfo.id_patient }
      this.$API.surgery.getSurgeries(params).then(
        (res) => {
         
          this.loading = false;
          let day ;
          if(this.$store.state.vetPatientData.dataSurgery){
            day = this.$store.state.vetPatientData.dataSurgery;
            this.$store.commit('vetPatientData/cleanData');
          }else{
            day = this.form.SurgeryDate;
          }

          const newData = res.map((el) => {
            el.ReasontoShow = el.ReasonForOperation.replace(/<\/?[^>]+(>|$)/g, "");
            el.ReasontoShow = el.ReasontoShow.substring(0, 50);
            if(el.SurgeryDate== day ){
              let data  ={
                data:{
                  ID_Surgery: el.ID_Surgery,
                }
              }
              this.getSurgeryDetails(data);
            }

            return {
                ...el,
              
              };
          });
          this.surgeries = newData;
        },
        () => {
          this.loading = false;
          this.showAlert("danger", "Error", "Error al obtener las cirugias contacte a su administrador");
        }
      );

    },

    addSurgery() {
      this.loading = true;
      if (
        this.form.SurgeryDate == "" ||
        this.form.ReasonForOperation == "" ||
        this.form.Process == "" ||
        this.form.Outcome == ""
      ) {
        this.showAlert("danger", "Error", "Todos los campos son requeridos");
        this.loading = false;
        return;
      }
      let datetoday = false;

      this.form.ID_User_Insert = this.loginUserID;
      this.form.ID_Patient = this.patientinfo.id_patient;
      if(this.form.Weight && this.weightunit) this.form.Weight = this.form.Weight.concat(' ', this.weightunit);

      this.surgeries.forEach((element) => {
        if (element.SurgeryDate == this.form.SurgeryDate) {
          datetoday = true;
          this.showAlert("danger", "Error", "Ya existe una cirugia para esta fecha");
        }
      });

      if (datetoday) {
        this.loading = false;
        return;
      }
      this.$API.surgery.addSurgery(this.form).then(
        () => {
          this.showAlert("success", "Exito","Cirugia agregada correctamente");
          this.getSurgeries();
          this.loading = false;
          this.reset();
        },
        () => {
          this.showAlert("danger", "Error","Error al agregar la cirugia contacte a su administrador");
          this.loading = false;
        }
      );
    },

    getSurgeryDetails(data) {
      this.loading = true;
      this.$API.surgery.getSurgeryByID(data.data.ID_Surgery).then(
        (res) => {

          this.surgery = res;
          this.form = res[0];
          if(this.form.Weight.includes('Kg')) {
            this.weightunit = 'Kg';
            this.weight = this.form.Weight.slice(0, -2).replace(' ', '');
          }
          else if(this.form.Weight.includes('Lb')) {
            this.weightunit = 'Lb';
            this.weight = this.form.Weight.slice(0, -2).replace(' ', '');
          }
          else {
            this.weightunit = '';
            this.weight = this.form.Weight.replace(' ', '');
          }

          this.loading = false;
        },
        () => {
          this.showAlert("danger", "Error","Error al obtener la cirugia contacte a su administrador");
          this.loading = false;
        }
      );
      this.selectedvalues = false;
    },

    deleteSurgery(data) {
      this.loading = true;
      this.$API.surgery.deleteSurgery(data.data.ID_Surgery).then(
        () => {
          this.showAlert("success", "Exito","Cirugia eliminada correctamente");
          this.deleteDialog = false;
          this.getSurgeries();
          this.loading = false;
          this.reset();
        },
        () => {
          this.showAlert("danger", "Error", "Error al eliminar la cirugia contacte a su administrador");
          this.loading = false;
        }

      );
    },

    updateSurgery() {
        this.loading = true;
        if (
        this.form.SurgeryDate == "" ||
        this.form.ReasonForOperation == "" ||
        this.form.Process == "" ||
        this.form.Outcome == ""
      ) {
        this.showAlert("danger", "Error", "Todos los campos son requeridos");
        this.loading = false;
        return;
      }
      this.form.ID_User_Update = this.loginUserID;
      if(this.form.Weight) {
        if(!this.form.Weight.includes('Lb') && !this.form.Weight.includes('Kg')) {
            this.form.Weight = this.form.Weight.concat(' ', this.weightunit);
        } 
        else if(!this.form.Weight.includes(this.weightunit)) {
            const newValue = this.form.Weight.slice(0, -3);
            this.form.Weight = newValue.concat(' ', this.weightunit);
            console.log(this.form.Weight);
        }
      } 
      this.$API.surgery.updateSurgery(this.surgery[0].ID_Surgery, this.form).then(
        () => {
          this.showAlert("success", "Exito", "Cirugia actualizada correctamente");
          this.getSurgeries();
          this.loading = false;
          this.reset();
        },
        () => {
          this.showAlert("danger", "Error", "Error al actualizar la cirugia contacte a su administrador");
          this.loading = false;
        }
      );
    },

    reset() {
      this.form = {
        SurgeryDate: this.moment().format("DD/MM/YYYY"),
        ReasonForOperation: "",
        Process: "",
        Outcome: "",
      };

      this.form.Weight = this.data.Weight;
      this.branchConfig = JSON.parse(localStorage.getItem("branch"));
      if(this.branchConfig?.weightunit) this.weightunit = this.branchConfig.weightunit;
      if(this.data?.weightunit) this.weightunit = this.data.weightunit;

      const localparams = JSON.parse(localStorage.getItem("localparams")) || null;
      if (localparams && localparams.weightunit) this.weightunit = localparams.weightunit;
      if(this.form && this.form.Weight.includes('Kg')) this.weightunit = 'Kg';
      if(this.form && this.form.Weight.includes('Lb')) this.weightunit = 'Lb';
      if(this.form.Weight && this.form.Weight.includes(this.weightunit)) {
          this.weight = this.form.Weight.slice(0, -2).replace(' ', '');
      } else {
          this.weight = this.form.Weight.replace(' ', '');
      }

      this.surgery = null;
      this.selectedvalues = true;
    },
    closeAlert() {
      this.alert.show = false;

      if (this.alert.options == "deletesurgery") {
        this.surgerytoDelete = null
      }
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "deletesurgery") {
        this.deleteSurgery(this.surgerytoDelete);
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },

    /*onEditorChange(e) {
                //this.medicalhistory.comment = e.html;
            },*/
  },
};
</script>

<style scoped>
.btndisable {
    background-color: #41464c !important;
    color: white;
}
.btndisable2 {
    background-color: #1976d2 !important;
    color: white;
}

.btn-add{
  background-color: #3498DB  !important;
  color: white;
}
.btnsave{
  background-color: #00A178 !important;
  color: white;
  margin-left: .3rem;
  margin-right: .3rem;
}
.btnclean{
  background-color: #F29D35 !important;
  color: white;
 
 
}
.btnblack{
    background-color: rgba(60, 60, 60, 1) !important;
    color: white !important;

}

.btnblue {
    background-color: #1976d2 !important;
    color: white;
}
.tabsB{
    width: 100%;
        padding: 10px;
        background-color: #1976d2;
        color: white;
 border-radius: 15px;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    }
.tabsB:hover{
    
    cursor: pointer;
}


.box{
    border-style: solid;
    border-color: black;
    border-width: 1px;
    background-color: white;
    border-radius: 15px;

}
.tabsNh{
    width: 100%;
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;
    
}
.btndelete{
  background-color: #E3191E !important;
  color: white;
}


.infopatient {
  margin-top: 5%;
}
.tabs {

  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white;
}

</style>

 <style>

.ql-editor150 .ql-editor{
  min-height:150px;
  max-height: 150px;
}

.ql-editor300 .ql-editor{
  min-height:300px;
  max-height: 300px;
}
</style>
