import axios from "./axios";
import { userData } from "./Currentuserdata";

export default {
//update data table
    updateDataTable(data){
        const options = {
        method: 'POST',
        url: `managetemplate/updateallmanagetemplate`,
        headers: {'content-type': 'application/json'},
        data: data
      };
      return new Promise((resolve, reject) => {
        axios(options)
            .then(response => {
                console.log(response)
                resolve(response);
            })
            .catch(error => {
                reject(error.message)
            })
        })
    },
    //update,alll
    updatealltable(data){
        const options = {
            method: 'POST',
            url: `managetemplate/updatealltable`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {

            axios(options)
                .then(response => {
                    console.log(response)
                    resolve(response);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    patientLabTests(patientID) {
        const options = {
            method: 'GET',
            url: `patientlabtest/${patientID}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    patientLabTestsFiltered(params) {
        const options = {
            method: 'GET',
            url: `patientlabtest/getOne/filtered`,
            headers: {'content-type': 'application/json'},
            params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    patientLabTest(data) {
        const options = {
            method: 'POST',
            url: 'patientlabtest/getone',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    labParams(data) {
        const options = {
            method: 'POST',
            url: `patientlabtest/params`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    saveLaboratoryTest(data) {
        const options = {
            method: 'POST',
            url: `patientlabtest/savelaboratorytest`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    deleteLab(data) {
        const options = {
            method: 'POST',
            url: `patientlabtest/del`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    },











    addLab(data) {
        const options = {
            method: 'POST',
            url: `patientlabtest`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    NewLabTest(data) {
        const options = {
            method: 'POST',
            url: `patientlabtest/newtest`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    updateLabParam(id, data) {
        const options = {
            method: 'PUT',
            url: `patientlabtest/params/${id}`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    manageTemplate(params) {
        const options = {
            method: 'GET',
            url: `managetemplate`,
            headers: {'content-type': 'application/json'},
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    manageTemplateHistory(params) {
        const options = {
            method: 'GET',
            url: `managetemplate/list/history`,
            headers: {'content-type': 'application/json'},
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    manageTemplateFiltered(params) {
        const options = {
            method: 'GET',
            url: `managetemplate/list/filtered`,
            headers: {'content-type': 'application/json'},
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    addTemplate(data) {
        const user = userData()
        const options = {
            method: 'POST',
            url: `managetemplate`,
            headers: {'content-type': 'application/json'},
            data :{
                ID_Branch: user.branch,
                ID_Business: user.businessid,  
                ...data,
            }
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    addTemplatevet(data) {
      const options = {
          method: 'POST',
          url: `managetemplate/vet`,
          headers: {'content-type': 'application/json'},
          data :data
      };
      return new Promise((resolve, reject) => {
          axios(options)
              .then(response => {
                  resolve(response.data);
              })
              .catch(error => {
                  reject(error.messageageage)
              })
      })
  },

    /**
     * Delete Template
     * @api {DELETE} managetemplate/:id
     */
    deleteTemplate(id) {
        const options = {
            method: 'DELETE',
            url: `managetemplate/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    /**
     * Get params For template
     * @api {GET} managetemplate/params/:id
     */
    showLabDetails(id) {
        const options = {
            method: 'GET',
            url: `managetemplate/params/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    },

    showLabDetailsHistory(id) {
        const options = {
            method: 'GET',
            url: `managetemplate/params/list/history/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    },
    showLabDetailsFiltered(data) {
        console.log(data)
        const options = {
            method: 'GET',
            url: `managetemplate/params/list/filtered/${data}`,
            headers: {'content-type': 'application/json'},
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    },
    /**
     * Update row of params For template
     * @api {PUT} managetemplate/params/:id
     */
    editParamsRow(id,data) {
        const options = {
            method: 'PUT',
            url: `managetemplate/params/${id}`,
            headers: {'content-type': 'application/json'},
            data : data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    /**
     * Delete row of params For template
     * @api {DELETE} managetemplate/params/:id
     */
    deleteParamsRow(id) {
        const options = {
            method: 'DELETE',
            url: `managetemplate/params/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    addParamsRow(data) {
        const options = {
            method: 'POST',
            url: `managetemplate/params/${data.id}`,
            headers: {'content-type': 'application/json'},
            data :data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    vetshowLabDetails(id) {
        const options = {
            method: 'GET',
            url: `managetemplate/params/vet/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    },

    //
    vetpatientLabTests(patientID) {
    const options = {
        method: 'GET',
        url: `vetpatientlabtest/${patientID}`,
        headers: {'content-type': 'application/json'},
    };
    return new Promise((resolve, reject) => {
        axios(options)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.message)
            })
    })
},
filtervetpatientLabTests(data) {
    const options = {
        method: 'GET',
        url: `vetpatientlabtest/filter`,
        headers: {'content-type': 'application/json'},
        params: data
    };
    return new Promise((resolve, reject) => {
        axios(options)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.message)
            })
    })
},
vetpatientLabTest(data) {
    const options = {
        method: 'POST',
        url: 'vetpatientlabtest/getone',
        headers: {'content-type': 'application/json'},
        data: data
    };
    return new Promise((resolve, reject) => {
        axios(options)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.message)
            })
    })
},

vetlabParams(data) {
    const options = {
        method: 'POST',
        url: `vetpatientlabtest/params`,
        headers: {'content-type': 'application/json'},
        data: data
    };
    return new Promise((resolve, reject) => {
        axios(options)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.message)
            })
    })
},

 vetsaveLaboratoryTest(data) {
    const options = {
        method: 'POST',
        url: `vetpatientlabtest/savelaboratorytest`,
        headers: {'content-type': 'application/json'},
        data: data
    };
    return new Promise((resolve, reject) => {
        axios(options)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.message)
            })
    })
},
vetdeleteLab(data) {
    const options = {
        method: 'POST',
        url: `vetpatientlabtest/del`,
        headers: {'content-type': 'application/json'},
        data: data
    };
    return new Promise((resolve, reject) => {
        axios(options)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.message)
            })
    })
},

AddVetLab(data) {
    const options = {
        method: 'POST',
        url: `vetpatientlabtest/newtest`,
        headers: {'content-type': 'application/json'},
        data: data
    };
    return new Promise((resolve, reject) => {
        axios(options)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.message)
            })
    })
},











vetaddLab(data) {
    const options = {
        method: 'POST',
        url: `patientlabtest`,
        headers: {'content-type': 'application/json'},
        data: data
    };
    return new Promise((resolve, reject) => {
        axios(options)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.message)
            })
    })
},

vetupdateLabParam(id, data) {
    const options = {
        method: 'PUT',
        url: `patientlabtest/params/${id}`,
        headers: {'content-type': 'application/json'},
        data: data
    };
    return new Promise((resolve, reject) => {
        axios(options)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.message)
            })
    })
},
}
