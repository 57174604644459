const rolesUser = {
    namespaced: true,
    state: () => ({
       roles : []
    }),
    mutations: {
        setRoles(state, payload){
            state.roles = payload
        },
        
    },
    actions: {
        getAllRoles({commit}){
        const user = JSON.parse(localStorage.getItem('user')).id
        console.log(user)
          this._vm.$API.roles.getUserRole(user)
            .then(response => {
                commit('setRoles', response)
            })
            .catch(error => {
                console.log(error)
            })
        }
    },
    getters: {
        isAdminOrManager(state){
            return state.roles.some((role) => ['admin', 'root'].includes(role.RoleName));
        },
        getCustomRole: (state) => (id) => {
            return state.roles.some((role) => ['admin', 'root'].includes(role.RoleName)) || state.roles.find((role) => role.ID_Role === id)
        },
        getArrayCustomRole: (state) => (array_id) => {
            return state.roles.some((role) => ['admin', 'root'].includes(role.RoleName)) || state.roles.some((role) => array_id.includes(role.ID_Role));
        },
        getArrayRequiredRole: (state) => (array_id) => {
            return state.roles.some((role) => ['admin', 'root'].includes(role.RoleName)) || state.roles.every((role) => array_id.includes(role.ID_Role));
        }
    }
}

export default rolesUser