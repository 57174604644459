<template>
    <v-card>
        <v-card-title class="text-h5">
            {{ `Buscar ${ entityLabel }`}}
        </v-card-title>

        <v-card-text>

            <GeneralFilter :filterEndpoint="filterEndpoint" :search="{...search }" @emptyFilter="getData" @filtered="filterHandler">
                <DxDataGrid class="isScrolledByGeneralFilter" :data-source="recordset" :key-expr="gridKeyExp">
                    <DxPaging :page-size="10" />
                    <DxColumn :width="50" cell-template="show-template" caption=""></DxColumn>
                    
                    <DxColumn v-for="({ caption, dataField }, index) in recordsetBody" :key="index" :data-field="dataField" :caption="caption"></DxColumn>

                    <template #show-template="{data}">
                        <a href="#" class="mx-1" @click.prevent.stop="$emit('select', data.data)">
                            <v-icon>mdi-check-bold</v-icon>
                        </a>
                    </template>
                </DxDataGrid>
            </GeneralFilter>
        </v-card-text>
    </v-card>
</template>

<script>
    import {DxDataGrid, DxColumn, DxPaging} from 'devextreme-vue/data-grid';
    import GeneralFilter from  "@/components/GeneralFilter";

    export default {
        name: "SearchDialog",
        components: {DxDataGrid, DxColumn, GeneralFilter, DxPaging},
        props: [
            'entityLabel',
            'filterEndpoint',
            'defaultEndpoint',
            'gridKeyExp',
            'recordsetBody'
        ],
        data() {
            return {
                recordset: [],
                search: {}
            }
        },
        mounted() {
            this.search.business = JSON.parse(localStorage.getItem("user")).businessid
            this.search.branch = JSON.parse(localStorage.getItem("user")).branch
            this.getData();
        },
        methods: {
            getData() {
                this.defaultEndpoint(this.search)
                    .then(response => {
                        console.log('recordset default ', response);
                        this.recordset = response
                    })
            },
            filterHandler(response) {
                console.log('recordset filtered', response);
                this.recordset = response
            },
        }
    }
</script>

<style scoped>

</style>