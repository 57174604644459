<template>
  <div>
    <div
      style="
        font-family: arial !important;
        margin-top: 10px;
        margin-bottom: 10px;
      "
    >
      <div style="text-align: center; font-size: 30px">
        Vista previa de impresión de Ticket (Factura consumidor final)
      </div>
      <br />
      <div style="text-align: center;">
        <button class="btnclean" style="font-size: 18px" @click="Print()">
          Imprimir
        </button>
        &nbsp;&nbsp;
        <button class="btnclean" style="font-size: 18px" @click="Download()">
          Descargar
        </button>
        &nbsp;&nbsp;
        <button
          v-if="!hideQr"
          class="btnclean"
          style="font-size: 18px"
          @click="sendMail()"
        >
          Enviar Correo
        </button>
        &nbsp;&nbsp;
        <button
          v-if="!hideQr"
          class="btnclean"
          style="font-size: 18px"
          @click="downLoadJSON()"
        >
          Descargar JSON
        </button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <button
          v-if="!hideQr"
          style="font-size: 18px"
          @click="$emit('input', 'regular')"
        >
          Ver en formato normal
        </button>
      </div>
      <div v-if="loading" style="text-align: center">Cargando...</div>
    </div>
    <div
      style="
        font-family: arial !important;
        margin-top: 10px;
        margin-bottom: 10px;
      "
    >
      <div v-if="mailLabel">{{ mailLabel }}</div>
    </div>

    <div
      class="book"
      style="
        margin: 0;
        padding: 0;
        background-color: rgb(105, 105, 105);
        font-family: arial !important;
        padding-top: 20px;
        padding-bottom: 20px;
      "
    >
      <div
        style="
          margin: 0 auto;
          width: 400px;
          display: flex;
          justify-content: center;
          font-size: 10pt;
        "
      >
        <div
          ref="dataprint"
          style="margin: 0 !important; padding: 0 !important; font-family: Arial !important;"
        >
          <div style="position: relative; width: 325px; background: white">
            <hr />
            <div class="subpage" style="padding-top: 20px; overflow: hidden">
              <div style="margin-left: 23px; margin-right: 33px">
                <div v-if="hideQr" style="width: 100%; background-color: red">
                  <h3 style="color: white; text-align: center">
                    DOCUMENTO SIN VALIDEZ LEGAL
                  </h3>
                </div>

                <div
                  v-if="dataProps.data.header.FeMhEstado == 'RECHAZADO'"
                  style="width: 100%; background-color: red"
                >
                  <h3 style="color: white; text-align: center">
                    DOCUMENTO RECHAZADO POR HACIENDA
                  </h3>
                </div>

                <div
                  v-if="dataProps.data.header.FeMhEstado == 'INVALIDADO'"
                  style="width: 100%; background-color: red"
                >
                  <h3 style="color: white; text-align: center">
                    DOCUMENTO INVALIDADO POR HACIENDA
                  </h3>
                </div>

                <table
                  style="
                    font-size: 8pt;
                    border-collapse: collapse;
                    width: 100%;
                    vertical-align: top;
                  "
                >
                  <tr>
                    <td
                      style="
                        text-align: center;
                        vertical-align: middle;
                        width: 100%;
                      "
                    >
                      <b>{{
                        dataProps.data.user.business != undefined ||
                        dataProps.data.user.business != null
                          ? dataProps.data.user.business.toUpperCase()
                          : ""
                      }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        color: white;
                        text-align: center;
                        vertical-align: middle;
                        width: 100%;
                      "
                    >
                      <img
                        style="max-width: 200px; height: 50px"
                        :src="dataProps.data.branch.BranchLogoPath"
                        crossorigin="anonymous"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td
                      style="
                        text-align: center;
                        vertical-align: middle;
                        width: 100%;
                      "
                    >
                      RAZÓN SOCIAL:
                      {{
                        dataProps.data.branch.BusinessBillingName !=
                          undefined ||
                        dataProps.data.branch.BusinessBillingName != null
                          ? dataProps.data.branch.BusinessBillingName.toUpperCase()
                          : ""
                      }}
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td
                      style="
                        text-align: center;
                        vertical-align: middle;
                        width: 100%;
                      "
                    >
                      ACTIVIDAD:
                      {{
                        dataProps.data.header.EmisorDescActividad !=
                          undefined ||
                        dataProps.data.header.EmisorDescActividad != null
                          ? dataProps.data.header.EmisorDescActividad.toUpperCase()
                          : ""
                      }}
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td
                      style="
                        text-align: center;
                        vertical-align: middle;
                        width: 100%;
                      "
                    >
                      DIRECCIÓN:
                      {{
                        dataProps.data.branch.BranchLegalAddress != undefined ||
                        dataProps.data.branch.BranchLegalAddress != null
                          ? dataProps.data.branch.BranchLegalAddress.toUpperCase()
                          : ""
                      }}
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td
                      style="
                        text-align: center;
                        vertical-align: middle;
                        width: 100%;
                      "
                    >
                      TÉLEFONO:
                      {{
                        dataProps.data.branch.BranchPhone != undefined ||
                        dataProps.data.branch.BranchPhone != null
                          ? dataProps.data.branch.BranchPhone.toUpperCase()
                          : ""
                      }}
                      <br />
                      WHATSAPP:
                      {{
                        dataProps.data.branch.BranchMobile ||
                        dataProps.data.branch.BranchManagerMobile ||
                        dataProps.data.branch.BranchContactMobile
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        text-align: center;
                        vertical-align: middle;
                        width: 100%;
                      "
                    >
                      E-MAIL: {{ dataProps.data.branch.BranchEmail }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        text-align: center;
                        vertical-align: middle;
                        width: 100%;
                      "
                    >
                      NIT: {{ BillingNit }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        text-align: center;
                        vertical-align: middle;
                        width: 100%;
                      "
                    >
                      NRC: {{ BillingNrc }}
                    </td>
                  </tr>
                </table>
                <hr />
                <table
                  style="
                    font-size: 8pt;
                    border-collapse: collapse;
                    width: 100%;
                    vertical-align: top;
                  "
                >
                  <tr>
                    <td
                      style="
                        text-align: left;
                        vertical-align: middle;
                        width: 100%;
                      "
                    >
                      Fecha emisión. {{ dataProps.data.header.InvoiceDate }}
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td
                      style="
                        text-align: center;
                        vertical-align: middle;
                        width: 100%;
                      "
                    >
                      FACTURA CONSUMIDOR FINAL
                    </td>
                  </tr>
                  <tr v-if="!hideQr">
                    <td
                      style="
                        text-align: center;
                        vertical-align: middle;
                        width: 100%;
                      "
                    >
                      <img
                        ref="qrcode"
                        :src="urlQr"
                        style="height: 150px"
                        crossorigin="anonymous"
                      />
                    </td>
                    <br />
                  </tr>
                  <br />
                  <tr>
                    <td
                      style="
                        text-align: left;
                        vertical-align: middle;
                        width: 100%;
                        font-size: 7pt;
                      "
                    >
                      COD. GENERACIÓN:
                      <b>{{ dataProps.data.header.CodigoGeneracion }}</b>
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td
                      style="
                        text-align: left;
                        vertical-align: middle;
                        width: 100%;
                        font-size: 7pt;
                      "
                    >
                      SELLO RECEPCIÓN:
                      <b>{{ dataProps.data.header.SelloRecepcion }}</b>
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td
                      style="
                        text-align: left;
                        vertical-align: middle;
                        width: 100%;
                        font-size: 7pt;
                      "
                    >
                      NUM. CONTROL:
                      <b>{{ dataProps.data.header.NumeroControl }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        text-align: left;
                        vertical-align: middle;
                        width: 100%;
                        font-size: 7pt;
                      "
                    >
                      CORRELATIVO FACTURA:
                      <b>{{ dataProps.data.header.ID_Invoice }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        text-align: left;
                        vertical-align: middle;
                        width: 100%;
                        font-size: 7pt;
                      "
                    >
                      MÓDELO DE FACTURACIÓN:
                      {{
                        dataProps.data.dteJson.IdentificacionTipoModelo == 1
                          ? "Previo"
                          : ""
                      }}
                      {{
                        dataProps.data.dteJson.IdentificacionTipoModelo == 2
                          ? "Diferido"
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        text-align: left;
                        vertical-align: middle;
                        width: 100%;
                        font-size: 7pt;
                      "
                    >
                      TIPO DE TRANSMISIÓN:
                      {{
                        dataProps.data.dteJson.IdentificacionTipoOperacion == 1
                          ? "Normal"
                          : ""
                      }}
                      {{
                        dataProps.data.dteJson.IdentificacionTipoOperacion == 2
                          ? "Por contingencía"
                          : ""
                      }}
                    </td>
                  </tr>
                </table>
                <hr />
                <table
                  style="
                    font-size: 8pt;
                    border-collapse: collapse;
                    width: 100%;
                    vertical-align: top;
                  "
                >
                  <tr>
                    <td
                      style="
                        text-align: left;
                        vertical-align: middle;
                        width: 100%;
                      "
                    >
                      DATOS DEL RECEPTOR
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td
                      style="
                        text-align: left;
                        vertical-align: middle;
                        width: 100%;
                        font-size: 7pt;
                      "
                    >
                      RAZÓN SOCIAL:
                      <b>{{
                        dataProps.data.header.InvoiceName != undefined ||
                        dataProps.data.header.InvoiceName != null
                          ? dataProps.data.header.InvoiceName.toUpperCase()
                          : ""
                      }}</b>
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td
                      style="
                        text-align: left;
                        vertical-align: middle;
                        width: 100%;
                        font-size: 7pt;
                      "
                    >
                      DIRECCIÓN:
                      <b>{{
                        dataProps.data.header.InvoiceAddress != undefined ||
                        dataProps.data.header.InvoiceAddress != null
                          ? dataProps.data.header.InvoiceAddress.toUpperCase()
                          : ""
                      }}</b>
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td
                      style="
                        text-align: left;
                        vertical-align: middle;
                        width: 100%;
                        font-size: 7pt;
                      "
                    >
                      NIT: <b>{{ dataProps.data.header.InvoiceNIT }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        text-align: left;
                        vertical-align: middle;
                        width: 100%;
                        font-size: 7pt;
                      "
                    >
                      DUI: <b>{{ dataProps.data.header.InvoiceDUI }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        text-align: left;
                        vertical-align: middle;
                        width: 100%;
                        font-size: 7pt;
                      "
                    >
                      ENTIDAD: <b>{{ dataProps.data.header.InvoiceEntity }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        text-align: left;
                        vertical-align: middle;
                        width: 100%;
                        font-size: 7pt;
                      "
                    >
                      MONEDA:
                      <b>{{
                        dataProps.data.header.InvoiceCurrency == "$"
                          ? "USD"
                          : ""
                      }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        text-align: left;
                        vertical-align: middle;
                        width: 100%;
                        font-size: 7pt;
                      "
                    >
                      ESTADO DE FACTURA:
                      <b>{{
                        dataProps.data.header.InvoiceStatusDescription
                      }}</b>
                    </td>
                  </tr>
                </table>
                <hr />
                <table
                  style="
                    font-size: 7pt;
                    border-collapse: collapse;
                    width: 100%;
                    vertical-align: top;
                  "
                >
                  <!-- Itera sobre cada item en dataProps.data.body -->
                  <tr v-for="(item, index) in dataProps.data.body" :key="index">
                    <td style="width: 100%">
                      <table
                        style="
                          width: 100%;
                          font-size: 7pt;
                          border-collapse: collapse;
                        "
                      >
                        <tr>
                          <td style="text-align: left; width: 50%">
                            <b>No. Item {{ index + 1 }}</b>
                          </td>
                          <td style="text-align: right; width: 50%"></td>
                        </tr>
                        <tr>
                          <td style="text-align: left; width: 50%">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cantidad
                          </td>
                          <td style="text-align: right; width: 50%">
                            {{ item.ItemQuantity }}
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: left; width: 50%">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Código
                          </td>
                          <td style="text-align: right; width: 50%">
                            {{ item.PrincipalBarCode || item.ID_Item }}
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 0" colspan="2">
                            <div
                              style="
                                width: 25%;
                                display: inline-block;
                                text-align: left;
                                vertical-align: top;
                              "
                            >
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Descripción:
                            </div>
                            <div
                              style="
                                width: 75%;
                                display: inline-block;
                                text-align: right;
                              "
                            >
                              {{ item.ItemDescription }}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: left; width: 50%">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pre. Unitario
                          </td>
                          <td style="text-align: right; width: 50%">
                            {{ dataProps.data.header.InvoiceCurrency
                            }}{{
                              numberWithCommas(
                                parseFloat(item.ItemUnitPrice).toFixed(2)
                              )
                            }}
                          </td>
                        </tr>
                        <tr v-if="item.ItemDiscount > 0">
                          <td style="text-align: left; width: 50%">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Descuento
                          </td>
                          <td style="text-align: right; width: 50%">
                            {{ dataProps.data.header.InvoiceCurrency
                            }}{{
                              numberWithCommas(
                                parseFloat(item.ItemDiscount).toFixed(2)
                              )
                            }}
                          </td>
                        </tr>
                        <tr v-if="item.ItemExenta > 0">
                          <td style="text-align: left; width: 50%">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ventas exentas
                          </td>
                          <td style="text-align: right; width: 50%">
                            {{ dataProps.data.header.InvoiceCurrency
                            }}{{
                              numberWithCommas(
                                parseFloat(item.ItemExenta).toFixed(2)
                              )
                            }}
                          </td>
                        </tr>
                        <tr v-if="item.ItemNoSuj > 0">
                          <td style="text-align: left; width: 50%">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ventas no sujetas
                          </td>
                          <td style="text-align: right; width: 50%">
                            {{ dataProps.data.header.InvoiceCurrency
                            }}{{
                              numberWithCommas(
                                parseFloat(item.ItemNoSuj).toFixed(2)
                              )
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: left; width: 50%">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ventas gravadas
                          </td>
                          <td style="text-align: right; width: 50%">
                            {{ dataProps.data.header.InvoiceCurrency
                            }}{{
                              numberWithCommas(
                                parseFloat(item.ItemGravada).toFixed(2)
                              )
                            }}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <hr />
                <table
                  style="
                    font-size: 8pt;
                    border-collapse: collapse;
                    width: 100%;
                    vertical-align: top;
                  "
                >
                  <tr>
                    <td style="text-align: right; width: 80%">
                      Sub total de ventas
                    </td>
                    <td style="text-align: right; width: 20%">
                      {{ dataProps.data.header.InvoiceCurrency
                      }}{{
                        numberWithCommas(
                          parseFloat(
                            dataProps.data.header.InvoiceSubTotalVentas
                          ).toFixed(2)
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: right; width: 80%">
                      Total descuentos
                    </td>
                    <td style="text-align: right; width: 20%">
                      {{ dataProps.data.header.InvoiceCurrency
                      }}{{
                        numberWithCommas(
                          parseFloat(
                            dataProps.data.header.InvoiceTotalDescu
                          ).toFixed(2)
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: right; width: 80%">IVA Retenido</td>
                    <td style="text-align: right; width: 20%">
                      {{ dataProps.data.header.InvoiceCurrency
                      }}{{
                        numberWithCommas(
                          parseFloat(
                            dataProps.data.header.InvoiceIvaRete1
                          ).toFixed(2)
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: right; width: 80%">
                      Impuesto sobre la renta
                    </td>
                    <td style="text-align: right; width: 20%">
                      {{ dataProps.data.header.InvoiceCurrency
                      }}{{
                        numberWithCommas(
                          parseFloat(
                            dataProps.data.header.InvoiceReteRenta
                          ).toFixed(2)
                        )
                      }}
                    </td>
                  </tr>
                </table>
                <br />
                <table
                  style="
                    font-size: 12pt;
                    border-collapse: collapse;
                    width: 100%;
                    vertical-align: top;
                  "
                >
                  <tr>
                    <td style="text-align: right; width: 75%"><b>Total:</b></td>
                    <td style="text-align: right; width: 25%">
                      <b
                        >{{ dataProps.data.header.InvoiceCurrency
                        }}{{
                          numberWithCommas(
                            parseFloat(
                              dataProps.data.header.InvoiceTotalPagar
                            ).toFixed(2)
                          )
                        }}</b
                      >
                    </td>
                  </tr>
                </table>

                <template v-if="dataProps.data.header.InvoiceInternalNote">
                  <hr />
                  <table
                    style="
                      font-size: 7pt;
                      border-collapse: collapse;
                      width: 100%;
                      vertical-align: top;
                    "
                  >
                    <tr>
                      <td><b>Observaciones: </b> {{ commentsOuterHtml }}</td>
                    </tr>
                  </table>
                </template>
                <br /><br />
                <table
                  style="
                    font-size: 7pt;
                    border-collapse: collapse;
                    width: 100%;
                    vertical-align: top;
                  "
                >
                  <tr>
                    <td style="text-align: center">¡Gracias por su visita!</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import QRCode from "qrcode";
import { addFormatToNIT, addFormatNRC } from "../../helpers/misc";
import { numberWithCommas } from "../../helpers/money";
import { numberToText } from "@/helpers/conveterNumberText.js";

export default {
  name: "PrintPosTkFcf",
  model: {
    event: "input",
    prop: "type",
  },
  props: {
    dataProps: {
      type: Object,
    },
    hideQr: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      header: null,
      footer: null,
      loading: false,
      //seal: "",
      //sealBranch: "",
      numPages: 0,
      //valuesRows: []
      mailLabel: null,
      valuesRows: [],
      urlQr: null,
      typePrint: 0,
    };
  },
  mounted() {
    this.showData()
  },
  computed: {
    BillingNrc() {
      return addFormatNRC(this.dataProps.data.branch.BusinessBillingNRC);
    },
    BillingNit() {
      //console.log(this.dataProps.data.branch.BusinessBillingNIT);
      return addFormatToNIT(this.dataProps.data.branch.BusinessBillingNIT);
    },
  },
  watch: {
    "dataProps.show"() {},
  },
  methods: {
    showData() {
      if (this.dataProps.show) {
        setTimeout(async () => {
          //await this.calculatePagesOfDoc()
          //console.log('header ', this.dataProps.data.header);
          const partes = this.dataProps.data.header.InvoiceDate.split("/");
          const dia = partes[0];
          const mes = partes[1];
          const anio = partes[2];

          // Crear una nueva fecha en formato "yyyy-mm-dd"
          const fechaNueva = `${anio}-${mes}-${dia}`;
          const url =
            this.dataProps.data.branch.AuthFeMode == 1
              ? `https://admin.factura.gob.sv/consultaPublica?ambiente=01&codGen=${this.dataProps.data.header.CodigoGeneracion}&fechaEmi=${fechaNueva}`
              : `https://admin.factura.gob.sv/consultaPublica?ambiente=00&codGen=${this.dataProps.data.header.CodigoGeneracion}&fechaEmi=${fechaNueva}`;

          // Generar el código QR y mostrarlo en el elemento con id "qrcode"

          const opt = {
            errorCorrectionLevel: "H",
            type: "image/png",
            quality: 0.3,
            margin: 1,
            color: {
              dark: "#000000",
              light: "#ffffff",
            },
            width: 100,
          };

          QRCode.toDataURL(url, opt, (error, url) => {
            if (error) {
              console.log("Error al generar el código QR:", error);
              throw error;
            }

            this.urlQr = url;
          });
          await this.getImages();
        }, 0);
      }
    },
    GetnumberToText(number) {
      if (number) return numberToText(number);
      else return "";
    },
    numberWithCommas(x) {
      return numberWithCommas(x);
    },
    /*getHeaderFooter() {
          let search = {};
          search.ID_business = JSON.parse(localStorage.getItem("user")).businessid || ""
          search.ID_branch = JSON.parse(localStorage.getItem("user")).branch || ""
          this.$API.HF.getHF(search).then((data) => {
              let header = [], footer = [];
              header = data.filter((x) => x.State == '1' && x.Type == 'Header');
              this.header = header.length > 0 ? JSON.parse(header[0].Body).html : '';
              this.header = this.header.replace(/(\n)/gm, "");
              footer = data.filter((x) => x.State == '1' && x.Type == 'Footer');
              this.footer = footer.length > 0 ? JSON.parse(footer[0].Body).html : '';
          })
      },*/

    /*calculatePagesOfDoc() {
          let pages = 0
          let rows = 0
          this.valuesRows[pages] = []

          try{
              if(this.dataProps.data.branch.BusinessBillingNRC == '3213715' || this.dataProps.data.branch.BusinessBillingNRC == '2201050') {
                  this.typePrint = 1
                  //One page
                  if(this.dataProps.data.body.length <= 24){
                      for(let i = 0; i < this.dataProps.data.body.length; i++){
                          rows++
                          this.valuesRows[pages].push(this.dataProps.data.body[i])
                      }    
                  }
                  else{
                      for(let i = 0; i < this.dataProps.data.body.length; i++){
                          if(rows < 29 && (i !== this.dataProps.data.body.length - 1)){
                              rows++
                              this.valuesRows[pages].push(this.dataProps.data.body[i])
                          }
                          else if(rows <= 24){
                              rows++
                              this.valuesRows[pages].push(this.dataProps.data.body[i])
                          }
                          else{
                              this.valuesRows[pages].push(this.dataProps.data.body[i])
                              pages++
                              rows = 0
                              this.valuesRows[pages] = []
                          }
                      }
                  }
              }
              else{
                  this.typePrint = 0
                  //Cuando es solo una página
                  if(this.dataProps.data.body.length <= 34){
                      for(let i = 0; i < this.dataProps.data.body.length; i++){
                          rows++
                          this.valuesRows[pages].push(this.dataProps.data.body[i])
                      }    
                  }
                  else{
                      for(let i = 0; i < this.dataProps.data.body.length; i++){
                          if(rows < 39 && (i !== this.dataProps.data.body.length - 1)){
                              rows++
                              this.valuesRows[pages].push(this.dataProps.data.body[i])
                          }
                          else if(rows <= 34){
                              rows++
                              this.valuesRows[pages].push(this.dataProps.data.body[i])
                          }
                          else{
                              this.valuesRows[pages].push(this.dataProps.data.body[i])
                              pages++
                              rows = 0
                              this.valuesRows[pages] = []
                          }
                      }
                  }
              }
              
          }
          catch(err) {
              alert(err)
          }
          pages = pages + 1
          this.numPages = pages
      },*/

    async Print() {
      this.loading = true;
      const pdfWidth = 8 * 28.35;
      var doc = new jsPDF({
        orientation: "p",
        unit: "pt",
        format: [pdfWidth, 400],
      });

      await doc.html(this.$refs.dataprint, {
        callback: function (doc) {
          doc.output();
        },
        x: 0,
        y: 0,
        margin: [0, 0, 0, 0],
        html2canvas: {
          scale: 0.698,
          //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
          useCORS: true,
          logging: false,
          allowTaint: true,
        },
      });

      //doc.deletePage(2)

      doc.autoPrint({ variant: "non-conform" });
      const blob = doc.output("bloburl");
      window.open(blob);

      this.loading = false;
    },

    async Download() {
      this.loading = true;
      const pdfWidth = 8 * 28.35;
      var doc = new jsPDF({
        orientation: "p",
        unit: "pt",
        format: [pdfWidth, 400],
      });
      doc.setFont("arial")
      await doc.html(this.$refs.dataprint, {
        callback: function (doc) {
          doc.output();
        },
        x: 0,
        y: 0,
        margin: [0, 0, 0, 0],
        html2canvas: {
          scale: 0.698,
          //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
          useCORS: true,
          logging: false,
          allowTaint: true,
        },
      });

      //doc.deletePage(2)
      doc.save(
        this.dataProps.data.header.ID_Invoice +
          "_" +
          this.dataProps.data.header.InvoiceDate.replaceAll("/", "-") +
          "_" +
          this.dataProps.data.header.InvoiceName.replaceAll(
            /[:/<>*|/]/g,
            ""
          ).replaceAll('\\"', "") +
          ".pdf"
      ); //Agregado por Juan 13082023

      this.loading = false;
    },
    async sendMail() {
      try {
        this.mailLabel = null;
        this.mailLabel = "Enviando...";
        const pdfWidth = 8 * 28.35;
        var doc = new jsPDF({
          orientation: "p",
          unit: "pt",
          format: [pdfWidth, 400],
        });
        //console.log(doc);
        //console.log(this.$refs.dataprint);
        await doc.html(this.$refs.dataprint, {
          callback: function (doc) {
            doc.output();
          },
          x: 0,
          y: 0,
          margin: [0, 0, 0, 0],
          html2canvas: {
            scale: 0.698,
            //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
            useCORS: true,
            logging: false,
            allowTaint: true,
          },
        });

        this.assignCuerpoDocumento();

        const {
          data: {
            header: { jsonMH },
            dteJson: { ID_Invoice },
          },
        } = this.dataProps;

        const body = {
          dtefilebuffer: new Buffer.from(doc.output("arraybuffer")),
          dtejson: { ...jsonMH, ID_Invoice },
          dtefilebufferfilename:
            this.dataProps.data.header.ID_Invoice +
            "_" +
            this.dataProps.data.header.InvoiceDate.replaceAll("/", "-") +
            "_" +
            this.dataProps.data.header.InvoiceName.replaceAll(
              /[:/<>*|/]/g,
              ""
            ).replaceAll('\\"', ""),
        };

        // console.log(body);
        this.$API.invoices.sendInvoiceMail(body);

        this.mailLabel = "Se envió el correo correctamente";
        this.$emit("mailSent");
      } catch (error) {
        this.mailLabel = "Ocurrió un error al enviar el correo";
        console.log(error);
      }
    },
    assignCuerpoDocumento() {
      this.dataProps.data.header.jsonMH.cuerpoDocumento =
        this.dataProps.data.body.map((item, index) => ({
          descripcion: item.ItemDescription,
          montoDescu: item.ItemDiscount,
          codigo: item.ID_Item,
          ventaGravada: item.ItemGravada,
          ivaItem: item.ItemIva,
          ventaNoSuj: item.ItemNoSuj,
          ventaExenta: item.ItemExenta,
          // "tributos": null,
          numItem: index + 1,
          noGravado: item.ItemNoGravada,
          psv: item.ItemPsv,
          // "tipoItem": 3,
          // "codTributo": null,
          uniMedida: item.ItemMeasureUnit,
          // "numeroDocumento": null,
          cantidad: item.ItemQuantity,
          precioUni: item.ItemUnitPrice,
        }));
      // for( const item in this.dataProps.data.body ) {
      //      [
      //         "descr"
      //     ]
      // }
    },
    async downLoadJSON() {
      try {
        const selloRecibido = JSON.parse(
          this.dataProps.data.header.ResponseMhJson
        ).selloRecibido;
        const firmaElectronica =
          this.dataProps.data.header.JsonMhSigned.documento;
        const jsonToSend = {
          ...this.dataProps.data.header.JsonMh.dteJson,
          firmaElectronica,
          selloRecibido,
        };
        delete jsonToSend.passwordPri;

        const aElement = document.createElement("a");
        aElement.setAttribute(
          "download",
          this.dataProps.data.header.ID_Invoice +
            "_" +
            this.dataProps.data.header.InvoiceDate.replaceAll("/", "-") +
            "_" +
            this.dataProps.data.header.InvoiceName.replaceAll(
              /[:/<>*|/]/g,
              ""
            ).replaceAll('\\"', "") +
            ".pdf"
        );
        this.assignCuerpoDocumento();
        const blob = new Blob([JSON.stringify(jsonToSend)], {
          type: "application/json",
        });
        const href = URL.createObjectURL(blob);
        aElement.href = href;
        aElement.download =
          this.dataProps.data.header.ID_Invoice +
          "_" +
          this.dataProps.data.header.InvoiceDate.replaceAll("/", "-") +
          "_" +
          this.dataProps.data.header.InvoiceName.replaceAll(
            /[:/<>*|/]/g,
            ""
          ).replaceAll('\\"', "") +
          ".json";
        aElement.click();
        URL.revokeObjectURL(href);
      } catch (error) {
        this.mailLabel = "Ocurrió un error al descargar JSON";
        console.log(error);
      }
    },
    async getImages() {
      const logo = this.dataProps.data.branch.BranchLogoPath;
      const { imageBase64: logoImageBase64 } =
        await this.$API.utilities.getImageInBase64({ file: logo });
      this.dataProps.data.branch.BranchLogoPath = `${logoImageBase64}`;
    },
  },
};
</script>

<style scoped>
.u-row .u-col-100 {
  width: 100% !important;
}
.btnclean {
  border-radius: 15px;
  border: 1px solid white;
  background-color: #f29d35 !important;
  color: white !important;
}
.btnclose {
  border-radius: 15px;
  border: 1px solid white;
  background-color: rgb(255, 38, 38) !important;
  color: white !important;
}

* {
  font-family: Arial, Helvetica, sans-serif !important;
  letter-spacing: 0.01px;
}
</style>
