export function formatDate(dateString) {
    if (!dateString) {
        return "Fecha es nula";
    }

    const date = new Date(dateString);

    // Ensure the date is treated as UTC
    const utcYear = date.getUTCFullYear();
    const utcMonth = String(date.getUTCMonth() + 1).padStart(2, '0');
    const utcDay = String(date.getUTCDate()).padStart(2, '0');

    const formattedDate = `${utcDay}/${utcMonth}/${utcYear}`;

    return formattedDate;
}


export function formatDatewihtZ(dateString) {
    if (!dateString) {
        return "Fecha es nula o indefinida";
    }

    const dateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}\.\d{3})Z$/;
    const match = dateString.match(dateRegex);

    if (!match) {
        return "Formato de fecha no válido";
    }

    const year = match[1];
    const month = match[2];
    const day = match[3];

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
}

export function formatDateV2(date){
    const parts = date.split('/');
    const aux = parts[0];
    parts[0] = parts[1];
    parts[1] = aux;
    return parts.join('/');
}

export function fixFormatDate(inputDate) {
    // Split the input date into day, month, and year
    const parts = inputDate.split('/')
    if (parts.length === 3) {
        const day = parseInt(parts[0], 10)
        const month = parseInt(parts[1], 10)
        const year = parseInt(parts[2], 10)

        // Create a JavaScript Date object in "MM/DD/YYYY" format
        const jsDate = new Date(year, month - 1, day) // Subtract 1 from month because it's zero-based

        // Format the date as "MM/DD/YYYY"
        const formattedDate = `${jsDate.getMonth() + 1}/${jsDate.getDate()}/${jsDate.getFullYear()}`

        // Now, formattedDate contains the date in "MM/DD/YYYY" format, which you can save in your database
        console.log(formattedDate) // Output: "09/13/2023"
        return formattedDate
    } else {
        console.log("Invalid date format")
    }
}

export function convertDateFormat(dateStr, sourceFormat, targetFormat) {
    const sourceParts = dateStr.split('/')
    const day = sourceParts[0]
    const month = sourceParts[1]
    const year = sourceParts[2]

    // Construct the date string in the target format
    let formattedDateStr = targetFormat
        .replace('dd', day)
        .replace('mm', month)
        .replace('yyyy', year)

    return formattedDateStr
}

export function convertDateFormat2(dateStr, config, targetFormat) {
    const { char, dayIndex, monthIndex, yearIndex } = config;
    const sourceParts = dateStr.split(char);
    const day = sourceParts[ dayIndex ];
    const month = sourceParts[ monthIndex ];
    const year = sourceParts[ yearIndex ];

    // Construct the date string in the target format
    let formattedDateStr = targetFormat
        .replace('dd', day)
        .replace('mm', month)
        .replace('yyyy', year);

    return formattedDateStr
}