<template>
    <v-row class="box align-items-center pt-2" :style="`background-color: white; max-height: ${$vuetify.breakpoint.smAndDown ? '100px' : '60px'};overflow-y:${$vuetify.breakpoint.smAndDown ? 'scroll':''}`">
      
      
      <v-col cols="12" sm="2" md="1" class="d-flex justify-center">
        <img :src="data.ProfilePicPath ? data.ProfilePicPath : 'https://drive.google.com/uc?export=view&id=1c8jTvxIT33xIptNpcVgm6KoKnVHvGq-Y'" :alt="patientinfo.namepatient" width="50px" height="50px">
      </v-col>
  
      
      <v-col cols="12" sm="10" md="11">
        <v-row class="py-3  align-items-center" :style="`max-height: ${$vuetify.breakpoint.smAndDown ? '100px' : '60px'}`">
          
          <v-col cols="12" sm="4" md="2" class="text-nowrap">
            <p><b>ID de Paciente:</b> {{ patientinfo.id_patient }}</p>
          </v-col>

          <v-col cols="12" sm="4" md="2" class="text-nowrap">
            <p><b>Nombre del Paciente:</b> {{ patientinfo.namepatient }}</p>
          </v-col>

          <v-col cols="12" sm="4" md="1" class="text-nowrap">
            <p><b>Fecha N.:</b> {{ patientinfo.dateofbirth }}</p>
          </v-col>

          <v-col cols="12" sm="4" md="1" class="text-nowrap">
            <p><b>Especie:</b> {{ patientinfo.species }}</p>
          </v-col>
  
          <v-col cols="12" sm="4" md="1" class="text-nowrap">
            <p><b>Raza:</b> {{ patientinfo.breed }}</p>
          </v-col>
  
          <v-col cols="12" sm="4" md="2" class="text-nowrap">
            <p><b>{{ patientinfo.customer.Is_a_Business ? "Empresa" : "Dueño" }}:</b> {{ patientinfo.customer.Is_a_Business ? patientinfo.customer.BusinessName : patientinfo.customer.FirstName + " " + patientinfo.customer.LastName }}</p>
          </v-col>
  
          <v-col cols="12" sm="4" md="2" class="text-nowrap">
            <p><b>Médico actual:</b> {{ useData.firstName + " " + useData.lastName }}</p>
          </v-col>
  
        </v-row>
      </v-col>
      
    </v-row>
  </template>
  

<script>
export default {
    name: "UserProfile",
    props: ['patientinfo', 'data', 'useData'],

}

</script>

<style scoped>

</style>