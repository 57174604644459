import axios from "./axios";

export default {
    getAccountingEntryDetailTypes(){
        console.log('aca');
        const options = {
            method: 'GET',
            url: '/accountingentrydetailtype',
            headers: {'content-type': 'application/json'},
        }
        return new Promise( ( resolve, reject ) => {
            axios(options)
                .then( response => {

                    if( response === undefined ){
                        reject( 'No se obtuvo respuesta del servidor' );
                    } else if( response.data.success ){
                        console.log('detail types ', response.data);
                        resolve( response.data );
                    } else {
                        reject( response.data );
                    }
                } )
                .catch( error => {
                    reject( error );
                } );
        } )
    }
}