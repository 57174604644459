import axios from "./axios";

import { userData } from "./Currentuserdata";

export default {
  /**
   * Get All Customers
   * @api {Get} managecustomer
   */
  getCustomers(data) {
    const options = {
      method: "GET",
      url: "managecustomer",
      headers: { "content-type": "application/json" },
      params: data,
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message)
        });
    });
  },
  getCustomersExport(data) {
    const options = {
      method: "GET",
      url: "managecustomer/export",
      headers: { "content-type": "application/json" },
      params: data,
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message)
        });
    });
  },
  getCustomersPOSCreateInvoice(data) {
    const options = {
      method: "GET",
      url: "managecustomer/pos/createInvoice",
      headers: { "content-type": "application/json" },
      params: data,
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          console.log(data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message)
        });
    });
  },
  getCustomersHistory(data) {
    const options = {
      method: "GET",
      url: "managecustomer/list/history",
      headers: { "content-type": "application/json" },
      params: data,
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message)
        });
    });
  },
  filterCustomers(data) {
    const options = {
      method: "GET",
      url: "managecustomer/list/filtered",
      headers: { "content-type": "application/json" },
      params: data,
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message)
        });
    });
  },

  //For pricelist
  customersFiltered(data) {
    const options = {
      method: "GET",
      url: "managecustomer/list/pricelist/filtered",
      headers: { "content-type": "application/json" },
      params: data,
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message)
        });
    });
  },

  getOneCustomer(id) {
    const user = userData();
    const options = {
      method: "GET",
      url: `managecustomer/${id}`,
      headers: {'content-type': 'application/json'},
      data: {
        ID_Branch: user.branch,
        ID_Business: user.businessid,
      }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message)
        });
    });
  },

  getInitialID(value) {
    const options = {
      method: 'GET',
      url: 'managecustomer/initialID',
      headers: { 'content-type': 'application/json' },
      params: { value }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
            resolve(response.data.data);
        })
        .catch(error => {
            console.log(error)
            reject(error.message)
        })
    });
  },
  checkDuplicated(value) {
    const options = {
      method: 'POST',
      url: 'managecustomer/checkDuplicated',
      headers: { 'content-type': 'application/json' },
      data: value 
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            console.log(error)
            reject(error.message)
        })
    });
  },

  /**
   * Add Customer
   * @api {POST} managecustomer
   */
  addCustomer(data) {
    const options = {
      method: "POST",
      url: "managecustomer",
      headers: { "content-type": "application/json" },
      data: data,
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message)
        });
    });
  },
  importCustomers(data) {
    const options = {
      method: "POST",
      url: "managecustomer/importCustomers",
      headers: { "content-type": "application/json" },
      data: data,
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message)
        });
    });
  },

  /**
   * Add Customer
   * @api {PUT} managecustomer
   */
  updateCustomer(data) {
    const user = userData();
    const options = {
      method: "PUT",
      url: `managecustomer/${data.id_customer}`,
      headers: { "content-type": "application/json" },
      data: {
        ...data,
        QuerySearch: {
          ID_Branch: user.branch,
          ID_Business: user.businessid,
        },
      },
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message)
        });
    });
  },

  /**
   * Delete Customer
   * @api {DELETE} managecustomer/:id
   */
  deleteCustomer(id) {
    const user = userData();
    const options = {
      method: "DELETE",
      url: `managecustomer/${id}`,
      headers: { "content-type": "application/json" },
      data: {
        ID_Branch: user.branch,
        ID_Business: user.businessid,
      }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message)
        });
    });
  },

  logicalDeleteCustomer(id) {
    const user = userData();
    const options = {
      method: "PUT",
      url: `managecustomer/logicaldelete/${id}`,
      headers: { "content-type": "application/json" },
      data: {
        ID_Branch: user.branch,
        ID_Business: user.businessid,
      }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message)
        });
    });
  },

getCustomerLabs(customerID){
  const options = {
    method: "POST",
    url: "managecustomer/getcustomerlabs",
    headers: { "content-type": "application/json" },
    data: {'ID_Customer':customerID}
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message)
      });
  });
},
getCustomerLabsvet(customerID){
  const options = {
    method: "POST",
    url: "managecustomer/getcustomerlabsvet",
    headers: { "content-type": "application/json" },
    data: {'ID_Customer':customerID}
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message)
      });
  });
},

getLabs(){
  const options = {
    method: "POST",
    url: "managecustomer/allabs",
    headers: { "content-type": "application/json" },
    data: {'ID_Patient':'ID_Patient'}
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message)
      });
  });
},
getLabsvet(){
  const options = {
    method: "POST",
    url: "managecustomer/allabsvet",
    headers: { "content-type": "application/json" },
    data: {'ID_Patient':'ID_Patient'}
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message)
      });
  });
},
getParameters(idLab){
  const options = {
    method: "POST",
    url: "managecustomer/getlabparams/"+idLab,
    headers: { "content-type": "application/json" },
    data: {'ID_Lab':idLab}
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message)
      });
  });
},
updateCustomerLab(ID_Patient, idRegister){
  const options = {
    method: "POST",
    url: "managecustomer/updatecustomerlabs/"+idRegister,
    headers: { "content-type": "application/json" },
    data: {'ID_Patient':ID_Patient}
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
},
updateCustomerLabvet(ID_Patient, idRegister){
  const options = {
    method: "POST",
    url: "managecustomer/updatecustomerlabsvet/"+idRegister,
    headers: { "content-type": "application/json" },
    data: {'ID_Patient':ID_Patient}
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
},
getInfoBilledvet(data) {
  const options = {
    method: "POST",
    url: "managecustomer/getbilledservicesvet",
    headers: { "content-type": "application/json" },
    data: data
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
},
  getInfoBilled(data) {
    const options = {
      method: "POST",
      url: "managecustomer/getbilledservices",
      headers: { "content-type": "application/json" },
      data: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },

  updatePltBilled(data) {
    const options = {
      method: "POST",
      url: "managecustomer/updatepltbilled",
      headers: { "content-type": "application/json" },
      data: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  getAcountsReceivable(data) {
    const options = {
      method: "POST",
      url: "managecustomer/getAcountsReceivable",
      headers: { "content-type": "application/json" },
      data: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  getAcountsReceivableFiltered(filter) {
    const options = {
      method: "POST",
      url: "managecustomer/getAcountsReceivableFiltered",
      headers: { "content-type": "application/json" },
      params: filter
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  getAcountsReceivableDetailed(data) {
    const options = {
      method: "POST",
      url: "managecustomer/getAcountsReceivableDetailed",
      headers: { "content-type": "application/json" },
      data: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  getAcountReceivableByCustomer(data) {
    const options = {
      method: "POST",
      url: "managecustomer/getAcountReceivableByCustomer",
      headers: { "content-type": "application/json" },
      data: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  getExpiredAccountsReceivable( requestDate, daysOfDue, id_seller ) {
    const options = {
      method: "POST",
      url: "managecustomer/getExpiredAccountsReceibableReport",
      headers: { "content-type": "application/json" },
      data: {
        requestDate,
        daysOfDue,
        id_seller
      }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  getExpiredAccountsReceivableSellers( requestDate, daysOfDue ) {
    const options = {
      method: "POST",
      url: "managecustomer/getExpiredAccountsReceibableSellers",
      headers: { "content-type": "application/json" },
      data: {
        requestDate,
        daysOfDue
      }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  getAllPaymentsHistory(typeData) {
    const options = {
      method: "GET",
      url: "managecustomer/payments/allPaymentsHistory",
      headers: { "content-type": "application/json" },
      params: { typeData },
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message)
        });
    });
  },
  getPaymentsFilteredColumns(data) {
    const options = {
      method: "GET",
      url: "managecustomer/payments/paymentsFilteredColumns",
      headers: { "content-type": "application/json" },
      params: data,
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message)
        });
    });
  },
  getAllpaymentsByInvoice(data) {
    const options = {
      method: "GET",
      url: "managecustomer/payments",
      headers: { "content-type": "application/json" },
      params: data,
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message)
        });
    });
  },
  getFilterpaymentsByInvoice(data) {
    const options = {
      method: "GET",
      url: "managecustomer/payments/filter",
      headers: { "content-type": "application/json" },
      params: data,
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message)
        });
    });
  },
  getInvoicePendingToPay(data){
    const options = {
      method: "POST",
      url: "managecustomer/getInvoicePendingToPay",
      headers: { "content-type": "application/json" },
      data: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  getSellerAccountsReceivable(data){
    const options = {
      method: "GET",
      url: "managecustomer/getSellerAccountsReceivable",
      headers: { "content-type": "application/json" },
      params: {
        id_seller: data,
      }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  getsSummaryAccountsSellers(data){
    const options = {
      method: "GET",
      url: "managecustomer/getsSummaryAccountsSellers",
      headers: { "content-type": "application/json" },
      params: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  getCustomerAccountStatus(id_customer = null, envType = null){
    const options = {
      method: "GET",
      url: "managecustomer/getCustomerAccountStatus",
      headers: { "content-type": "application/json" },
      params: {
        id_customer,
        envType
      }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  getCustomerAccountStatusBycolumn(data){
    const options = {
      method: "GET",
      url: "managecustomer/getCustomerAccountStatusBycolumn",
      headers: { "content-type": "application/json" },
      params: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  getCustomersInAccountStatus(data){
    const options = {
      method: "GET",
      url: "managecustomer/getCustomerList",
      headers: { "content-type": "application/json" },
      params: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  getsSummaryAccountsSellersBycolumn(data){
    const options = {
      method: "GET",
      url: "managecustomer/getsSummaryAccountsSellersBycolumn",
      headers: { "content-type": "application/json" },
      params: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  getSellerAccountsReceivableBycolumn(data){
    const options = {
      method: "GET",
      url: "managecustomer/getSellerAccountsReceivableBycolumn",
      headers: { "content-type": "application/json" },
      params: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  getInvoiceWithCredit(data){
    const options = {
      method: "POST",
      url: "managecustomer/getInvoiceWithCredit",
      headers: { "content-type": "application/json" },
      data: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  getFilterInvoiceWithCredit(data){
    const options = {
      method: "POST",
      url: "managecustomer/getFilterInvoiceWithCredit",
      headers: { "content-type": "application/json" },
      data: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  getAcountsReceivableDetailedByCustomer(data){
    const options = {
      method: "POST",
      url: "managecustomer/getAcountsReceivableDetailedByCustomer",
      headers: { "content-type": "application/json" },
      data: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  addPaymentToAccountReceivable(data){
    const options = {
      method: "POST",
      url: "managecustomer/addPaymentToAccountReceivable",
      headers: { "content-type": "application/json" },
      data: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  deleteReceipt(data) {
    const options = {
      method: "POST",
      url: "managecustomer/deleteReceipt",
      headers: { "content-type": "application/json" },
      data: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  getsAccountsReceivableSummaryV2(data) {
    const options = {
      method: "POST",
      url: "managecustomer/getsAccountsReceivableSummaryV2",
      headers: { "content-type": "application/json" },
      data: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }
};
