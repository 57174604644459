<template>
    <v-card flat style="padding: 14px;">
        <v-card-text class="white" style="border-radius: 15px;">
            <div class="search-table-input">
                <v-icon>mdi-magnify</v-icon>
                <input type="text" placeholder="Buscar">
            </div>

            <v-card>
                <DxDataGrid class="isScrolledByGeneralFilter" :data-source="stocks" key-expr="ID_BRANCH">
                    <DxColumn data-field="ID_BRANCH" caption="ID"></DxColumn>
                    <DxColumn data-field="WAREHOUSE" caption="Almacen"></DxColumn>
                    <DxColumn data-field="STOCK" caption="Disponible"></DxColumn>
                    <DxColumn data-field="PROVIDER" caption="Proveedor"></DxColumn>
                </DxDataGrid>

            </v-card>

        </v-card-text>
    </v-card>
</template>

<script>
    import {DxDataGrid, DxColumn} from 'devextreme-vue/data-grid';
    export default {
        name: "Stock",
        components: {DxDataGrid, DxColumn},
        data () {
            return {
                search: '',
                stocks: null,
            }
        },
    }
</script>

<style scoped>

</style>
