import axios from "./axios";
import { userData } from "./Currentuserdata";

export default {
  /**
   * Get All Customers
   * @api {Get} managecustomer
   */
  getFamilies(data) {
    const user = userData();
    const options = {
      method: "GET",
      url: "items/getallfam",
      headers: { "content-type": "application/json" },
      params: {
        ID_Branch: user.branch,
        ID_Business: user.businessid,
        ...data,
      },
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  getFamiliesHistory(data) {
    const user = userData();
    const options = {
      method: "GET",
      url: "items/getallfam/list/history",
      headers: { "content-type": "application/json" },
      params: {
        ID_Branch: user.branch,
        ID_Business: user.businessid,
        ...data,
      },
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  getFamiliesFiltered(data) {
    const user = userData();
    const options = {
      method: "GET",
      url: "items/getallfam/list/filtered",
      headers: { "content-type": "application/json" },
      params: {
        ID_Branch: user.branch,
        ID_Business: user.businessid,
        ...data,
      },
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  createData(data) {
    const options = {
      method: "POST",
      url: 'items/importDataTypes',
      headers: {'content-type': 'application/json'},
      data: data
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  createFam(data) {
    const user = userData();
    const options = {
      method: "POST",
      url: 'items/fam',
      headers: {'content-type': 'application/json'},
      data:{
        name:data,
        ID_Branch: user.branch,
        ID_Business: user.businessid,
      }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  createCat(data) {
    const user = userData();
    const options = {
      method: "POST",
      url: `items/cat`,
      headers: {'content-type': 'application/json'},
      data:{
        ID_Branch: user.branch,
        ID_Business: user.businessid,
        ...data 
      }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  createSub(data) {
    const user = userData();
    const options = {
      method: "POST",
      url: `items/sub`,
      headers: {'content-type': 'application/json'},
      data:{
        ID_Branch: user.branch,
        ID_Business: user.businessid,
        ...data
      }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.messageageage);
        });
    });
  },

  getOneFam(id) {
    const user = userData();
    const options = {
      method: "GET",
      url: `items/getonefam/${id}`,
      headers: {'content-type': 'application/json'},
      data: {
        ID_Branch: user.branch,
        ID_Business: user.businessid        ,
      }
    };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
updateFam(data){
  const user = userData();
  const options = {
    method: "PUT",
    url: `items/fam/${data.id}`,
    headers: {'content-type': 'application/json'},
    data:{
      ID_Branch: user.branch,
      ID_Business: user.businessid        ,
      name:data.name
    }
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });

},
deleteFam(id){
  const user = userData();
  const options = {
    method: "DELETE",
    url: `items/fam/${id}`,
    headers: {'content-type': 'application/json'},
    data: {
      ID_Branch: user.branch,
      ID_Business: user.businessid        ,
    }
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });

},
/* end items families */

getCategories(data) {
  const user = userData();
  const options = {
    method: "GET",
    url: "items/getallcat",
    headers: { "content-type": "application/json" },
    params: {
      ID_Branch: user.branch,
      ID_Business: user.businessid        ,
      ...data
    },
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
},
getCategoriesByFamily( data ) {
  const options = {
    method: "GET",
    url: "items/famgetcats",
    headers: { "content-type": "application/json" },
    params: {
      ...data
    },
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        console.log( 'response fam/getallcat ', response );
        resolve(response.data);
      })
      .catch((error) => {
        console.log( error );
        reject(error.message);
      });
  });
},
getCategoriesHistory(data) {
  const user = userData();
  const options = {
    method: "GET",
    url: "items/getallcat/list/history",
    headers: { "content-type": "application/json" },
    params: {
      ID_Branch: user.branch,
      ID_Business: user.businessid        ,
      ...data
    },
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
},

getCategoriesFiltered(data) {
  const user = userData();
  const options = {
    method: "GET",
    url: "items/getallcat/list/filtered",
    headers: { "content-type": "application/json" },
    params: {
      ID_Branch: user.branch,
      ID_Business: user.businessid        ,
      ...data
    },
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
},

getOneCat(id) {
  const user = userData();
  const options = {
    method: "GET",
    url: `items/getonecat/${id}`,
    headers: {'content-type': 'application/json'},
    data: {
      ID_Branch: user.branch,
      ID_Business: user.businessid        ,
    }
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
},
updateCat(data){
const user = userData();
const options = {
  method: "PUT",
  url: `items/cat/${data.id}`,
  headers: {'content-type': 'application/json'},
  data:{
    ID_Branch: user.branch,
    ID_Business: user.businessid        ,
    ...data
  }
};
return new Promise((resolve, reject) => {
  axios(options)
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject(error.message);
    });
});

},
deleteCat(id){
const user = userData();
const options = {
  method: "DELETE",
  url: `items/cat/${id}`,
  headers: {'content-type': 'application/json'},
  data: {
    ID_Branch: user.branch,
    ID_Business: user.businessid        ,
  },
};
return new Promise((resolve, reject) => {
  axios(options)
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject(error.message);
    });
});

},
/* end items categories */

getSubs(data) {
  const user = userData();
  const options = {
    method: "GET",
    url: "items/getallsub",
    headers: { "content-type": "application/json" },
    params: {
      ID_Branch: user.branch,
      ID_Business: user.businessid        ,
      ...data
    },
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
},
getSubsByCategory(data) {
  const options = {
    method: "GET",
    url: "items/catgetsubs",
    headers: { "content-type": "application/json" },
    params: {
      ...data
    },
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
},

getSubsHistory(data) {
  const user = userData();
  const options = {
    method: "GET",
    url: "items/getallsub/list/history",
    headers: { "content-type": "application/json" },
    params: {
      ID_Branch: user.branch,
      ID_Business: user.businessid        ,
      ...data
    },
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
},

getSubsFiltered(data) {
  const user = userData();
  const options = {
    method: "GET",
    url: "items/getallsub/list/filtered",
    headers: { "content-type": "application/json" },
    params: {
      ID_Branch: user.branch,
      ID_Business: user.businessid        ,
      ...data
    },
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
},

getOneSub(id) {
  const user = userData();
  const options = {
    method: "GET",
    url: `items/getonesub/${id}`,
    headers: {'content-type': 'application/json'},
    data: {
      ID_Branch: user.branch,
      ID_Business: user.businessid        ,
    },
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
},
updateSub(data){
const user = userData();
const options = {
  method: "PUT",
  url: `items/sub/${data.id}`,
  headers: {'content-type': 'application/json'},
  data:{
    ID_Branch: user.branch,
    ID_Business: user.businessid        ,
    ...data
  }
};
return new Promise((resolve, reject) => {
  axios(options)
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject(error.message);
    });
});

},
deleteSub(id){
const user = userData();
const options = {
  method: "DELETE",
  url: `items/sub/${id}`,
  headers: {'content-type': 'application/json'},
  data: {
    ID_Branch: user.branch,
    ID_Business: user.businessid        ,
  },
};
return new Promise((resolve, reject) => {
  axios(options)
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject(error.message);
    });
});

},
/* end items subcategories */
};
