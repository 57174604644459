import axios from "./axios";

export default{
    /**
     *  Verify Token
     * @api {POST} verifytoken
     */
    async getDocumentTypes(){
        try {
            const result = await axios({
                method: 'get',
                url: `documenttype`,
                headers: {'content-type': 'application/json'}
            });

            return result.data;
        } catch (error) {
            throw new Error(`Sucedió el siguiente error al intentar obtener los tipos de documentos ${error.message}`);
        }
    },
}