<template>
    <div class="white pa-4">
        <v-tabs
          v-model="tabsCategories"
          background-color="transparent"
          grow
        >
            <v-tab
                v-for="tab in ['Familias', 'Categorias', 'Subcategorias']"
                :key="tab"
                :href="`#${tab}`"
                :class="tab"
            >
            {{ tab }}
          </v-tab>
        </v-tabs>
        <!-- Familia de productos start -->
        <v-tabs-items v-model="tabsCategories">
          <v-tab-item value="Familias">
          <v-row style="background-color: white; padding: 10px">
            <v-col cols="12">
              <h2 style="text-align: center">Familias</h2>
            </v-col>
            <v-row class="mt-4">
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  v-model="familyName"
                  label="Nombre de Familia"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" >
                <div class="d-flex justify-end">
                  <v-btn class="btnsave mr-5" @click.prevent.stop="addFamily()">
                    Agregar Familia
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-col cols="12">
              <!-- Table families start -->
              <GeneralFilter
                entity="lista"
                :filterEndpoint="$API.itemtypes.getFamiliesFiltered"
                :search="search"
                @emptyFilter="getFamilies"
                @filtered="filterHandlerFamilies"
              >
                <DxDataGrid class="isScrolledByGeneralFilter"
                  :show-borders="true"
                  :data-source="families"
                  key-expr="id"
                >
                  <DxColumn
                    :width="70"
                    cell-template="show-delete"
                    caption=""
                  ></DxColumn>
                  <DxColumn
                    :width="70"
                    cell-template="show-template"
                    caption="Familias"
                  ></DxColumn>
                  <DxPaging :page-size="10" />
                  <DxColumn data-field="name" caption="Nombre"></DxColumn>

                  <!--  <DxColumn data-field="TypeOfImage" caption="Tipo de Imágen"></DxColumn> -->

                  <template #show-delete="{ data }">
                    <div>
                      <a
                        href=""
                        class="mx-2"
                        @click.prevent.stop="
                          toDelete = data.data.id;
                          showAlert(
                            'question',
                            'Eliminar Elemento',
                            '¿Está seguro que desea eliminar este Elemento?',
                            'destroyFamily'
                          );
                        "
                      >
                        <v-icon color="error">mdi-delete</v-icon>
                      </a>
                    </div>
                  </template>
                  <template #show-template="{ data }">
                    <div>
                      <a
                        href="#"
                        @click.prevent.stop="
                          (form.family = data.data.id),
                            (showFamCat = data.data.name),
                            (form.category = null),
                            (tabsCategories = 'Categorias')
                            (selectFamily())
                        "
                      >
                        <v-icon color="primary">mdi-eye</v-icon>
                      </a>
                      <!--      <a href="" class="mx-2" @click.prevent.stop="showDelete(data.data.ID_Images)">

                                <v-icon color="error">mdi-delete</v-icon>
                              </a> -->
                    </div>
                  </template>
                  <DxPager :show-page-size-selector="true" />
                  <DxPaging :page-size="5" />
                </DxDataGrid>
              </GeneralFilter>
              <!-- Table families end -->
            </v-col>
          
          </v-row>
        </v-tab-item>
        <!-- Familia de productos end -->

        <!-- Categoria de productos start -->
        <v-tab-item value="Categorias">
          <v-row
            style="background-color: white; padding: 10px"
            v-if="form.family"
          >
            <v-col cols="12">
              <h2 style="text-align: center">
                Categoria de productos <span> (familia {{ showFamCat }}) </span>
              </h2>
            </v-col>
            <v-row  class="mt-4">
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  v-model="categoryName"
                  label="Nombre de Categoria"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <div class="d-flex justify-end">
                  <v-btn
                  class="btnsave mr-5"
                  @click.prevent.stop="addCategory(form.family)"
                  >
                  Agregar Categoria
                </v-btn>
              </div>
              </v-col>
            </v-row>
            <v-col cols="12">
              <!-- Table category start -->
              <GeneralFilter
                entity="categoria"
                :filterEndpoint="$API.itemtypes.getCategoriesFiltered"
                :search="search"
                @emptyFilter="getCategories"
                @filtered="filterHandlerCategories"
              >
                <DxDataGrid class="isScrolledByGeneralFilter"
                  :show-borders="true"
                  :data-source="filteredCategories"
                  key-expr="id"
                >
                  <DxColumn
                    :width="70"
                    cell-template="show-delete"
                    caption=""
                  ></DxColumn>
                  <DxColumn
                    :width="120"
                    cell-template="show-template"
                    caption="Categorías"
                  ></DxColumn>
                  <DxPaging :page-size="10" />
                  <DxColumn data-field="name" caption="Nombre"></DxColumn>

                  <!--  <DxColumn data-field="TypeOfImage" caption="Tipo de Imágen"></DxColumn> -->

                  <template #show-delete="{ data }">
                    <div>
                      <a
                        href=""
                        class="mx-2"
                        @click.prevent.stop="
                          toDelete = data.data.id;
                          showAlert(
                            'question',
                            'Eliminar Elemento',
                            '¿Está seguro que desea eliminar este Elemento?',
                            'destroyCat'
                          );
                        "
                      >
                        <v-icon color="error">mdi-delete</v-icon>
                      </a>
                    </div>
                  </template>
                  <template #show-template="{ data }">
                    <div>
                      <a
                        href="#"
                        @click.prevent.stop="
                          (showCatSub = data.data.name),
                            (form.category = data.data.id),
                            (tabsCategories = 'Subcategorias')
                            (selectCategory())
                        "
                      >
                        <v-icon color="primary">mdi-eye</v-icon>
                      </a>
                      <!--      <a href="" class="mx-2" @click.prevent.stop="showDelete(data.data.ID_Images)">

                                <v-icon color="error">mdi-delete</v-icon>
                              </a> -->
                    </div>
                  </template>
                  <DxPager :show-page-size-selector="true" />
                  <DxPaging :page-size="5" />
                </DxDataGrid>
              </GeneralFilter>
              <!-- Table category end -->
            </v-col>
            
          </v-row>
          <v-row v-else>
            <div class="pa-5 d-flex justify-content-center">
              <h2 >
              Seleccione una familia para poder agregar una categoria
              </h2>
            </div>
          </v-row>
        </v-tab-item>
        <!-- Categoria de productos end -->

        <!-- SubCategoria de productos start -->
        <v-tab-item value="Subcategorias">
          <v-row
            style="background-color: white; padding: 10px"
            v-if="form.category"
          >
            <v-col cols="12">
              <h2 style="text-align: center">
                SubCategoria de productos
                <span>(categoria {{ showCatSub }})</span>
              </h2>
            </v-col>
            <v-row class="mt-4">
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  v-model="subName"
                  label="Nombre de SubCategoria"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <div class="d-flex justify-end">
                  <v-btn
                    class="btnsave mr-5"
                    @click.prevent.stop="addSub(form.category)"
                  >
                    Agregar SubCategoria
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-col cols="12">
              <!-- Table subcategory start -->
              <GeneralFilter
                entity="lista"
                :filterEndpoint="$API.itemtypes.getSubsFiltered"
                :search="search"
                @emptyFilter="getSubs"
                @filtered="filterHandlerSubs"
              >
                <DxDataGrid class="isScrolledByGeneralFilter"
                  :show-borders="true"
                  :data-source="filteredSubcategories"
                  key-expr="id"
                >
                  <DxColumn
                    :width="70"
                    cell-template="show-delete"
                    caption="Subcategorías"
                  ></DxColumn>
                  <!--   <DxColumn :width="70" cell-template="show-template" caption="Ver Categorias"></DxColumn> -->
                  <DxPaging :page-size="10" />
                  <DxColumn data-field="name" caption="Nombre"></DxColumn>

                  <!--  <DxColumn data-field="TypeOfImage" caption="Tipo de Imágen"></DxColumn> -->

                  <template #show-delete="{ data }">
                    <div>
                      <a
                        href=""
                        class="mx-2"
                        @click.prevent.stop="
                          toDelete = data.data.id;
                          showAlert(
                            'question',
                            'Eliminar Elemento',
                            '¿Está seguro que desea eliminar este Elemento?',
                            'destroySub'
                          );
                        "
                      >
                        <v-icon color="error">mdi-delete</v-icon>
                      </a>
                    </div>
                  </template>
                  <template #show-template="{ data }">
                    <div>
                      <a href="#" @click.prevent.stop="showFamCat = data.data.id">
                        <v-icon color="primary">mdi-eye</v-icon>
                      </a>
                    </div>
                  </template>
                  <DxPager :show-page-size-selector="true" />
                  <DxPaging :page-size="5" />
                </DxDataGrid>
              </GeneralFilter>
              <!-- Table subcategory end -->
            </v-col>
            
          </v-row>
          <v-row v-else>
            <div class="pa-5 d-flex justify-content-center">
              <h2 >
                Seleccione una categoria para poder agregar una subcategoria
              </h2>
            </div>
            </v-row>
        </v-tab-item>
        <!-- SubCategoria de productos end -->
      </v-tabs-items>
      


            <alerts
        v-if="alert.show"
        v-on:close_alert="closeAlert"
        v-on:accept_alert="acceptAlert"
        :properties="alert"
        ></alerts>
    </div>
</template>

<script>
    
    //import {mapState} from 'vuex';
    import alerts from '@/components/Alerts.vue';
    import {
  DxDataGrid,
  DxColumn,
  DxPager,
  DxPaging,
  
} from "devextreme-vue/data-grid";
import GeneralFilter from "@/components/GeneralFilter";

    export default {
        name: "ManageCategory",
        components: {
            DxDataGrid,
            DxColumn,
            DxPager,
            DxPaging,
            
            alerts,
            GeneralFilter,
        },
        props: {
            win: {
                type: Object,
                required: true
            },
            data:{
                type: Object,
                required: true
            }
        },
        data() {
            return {
                families: [],
                categories: [],
                subcategories: [],
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
                form: {
                    family: null,
                    category: null,
                    subcategory: null,
                
                },
                tabsCategories: null,
                familyName: null,
                categoryName: null,
                subName: null,
                showFamCat: null,
                showCatSub: null,
                toDelete: null,
                search: {
                    filter: null,
                },
                filteredCategories: [],
                filteredSubcategories: [],
            }
        },
        mounted() {
            this.getFamilies();
            this.getCategories();
            this.getSubs();   
        },
        computed:{
            // filteredCategories() {
            //     console.log("selected fam", this.form.family);
            //     return this.form.family
            //         ? this.$API.itemtypes.getCategoriesByFamily( { id_family: this.form.family } )
            //         : [];
            // },
            // filteredSubcategories() {
            //     console.log("selected cat", this.form.category);
            //     return this.form.category
            //         ? this.$API.itemtypes.getSubsByCategory( { id_cat: this.form.category } )
            //         : [];
            // },
            
        },
        methods: {
          addFamily() {
            this.$API.itemtypes.createFam(this.familyName).then((response) => {
              this.familyName = null;
              console.log(response);
              this.getFamilies();
              this.showAlert(
                "success",
                "Exito",
                "La familia se agregó de forma correcta"
              );
              this.$emit('reloadCategories');

            });
          },
            addCategory(id_family) {
              let toSend = {
                name: this.categoryName,
                id_family: id_family,
              };
              this.$API.itemtypes.createCat(toSend).then((response) => {
                console.log(response);
                this.getFamilies();
                this.categoryName = null;
                this.getCategories();
                this.showAlert(
                  "success",
                  "Exito",
                  "La categoría se agregó de forma correcta"
                );
                this.selectFamily();

              });
            },
            addSub(id_cat) {
              let toSend = {
                name: this.subName,
                id_cat: id_cat,
              };
              this.$API.itemtypes.createSub(toSend).then((response) => {
                console.log(response);

                this.subName = null;
                this.getFamilies();
                this.getCategories();
                this.getSubs();
                this.showAlert(
                  "success",
                  "Exito",
                  "La Subcategoria se agregó de forma correcta"
                );
                this.selectCategory();
              });
            },
            getFamilies() {
                this.$API.itemtypes.getFamiliesHistory().then((response) => {
                    this.families = response;
                });
            },
            getCategories() {
                this.$API.itemtypes.getCategoriesHistory().then((response) => {
                    this.categories = response;
                });
            },
            getSubs() {
                this.$API.itemtypes.getSubsHistory().then((response) => {
                    this.subcategories = response;
                });
            },
            filterHandlerFamilies(response) {
              this.families = response;
              console.log("response family", response);
            },

            filterHandlerCategories(response) {
              this.categories = response;
            },

            filterHandlerSubs(response) {
              this.subcategories = response;
            },
            destroyFamily(id) {
                this.$API.itemtypes.deleteFam(id).then(() => {
                    this.getFamilies();
                    this.showAlert("success", "Exito", "Se eliminó de forma correcta");
                    this.$emit('reloadCategories');
                });
            },
            destroyCat(id) {
            this.$API.itemtypes.deleteCat(id).then(() => {
                this.getCategories();
                this.showAlert("success", "Exito", "Se eliminó de forma correcta");
                this.selectFamily()
                this.selectCategory();

            });
            },
            destroySub(id) {
            this.$API.itemtypes.deleteSub(id).then(() => {
                this.getSubs();
                this.showAlert("success", "Exito", "Se eliminó de forma correcta");
                this.selectCategory();

            });
            },
            closeAlert() {
                this.alert.show = false;

            },
            acceptAlert() {
                this.alert.show = false;
            //this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid }); falta el id
            //this.$store.dispatch('addWindow',{'name':'ProductsList',component:'ProductsList',unique:false});
                if (this.alert.options == "destroyFamily") {
                    this.destroyFamily(this.toDelete);
                }
                if (this.alert.options == "destroyCat") {
                    this.destroyCat(this.toDelete);
                }

                if (this.alert.options == "destroySub") {
                    this.destroySub(this.toDelete);
                }
            },
            showAlert(type, header, body, options = null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
            },
            async selectFamily() {
              this.filteredCategories = await this.$API.itemtypes.getCategoriesByFamily( { id_family: this.form.family } );
            },
            async selectCategory() {
              this.filteredSubcategories = await this.$API.itemtypes.getSubsByCategory( { id_cat: this.form.category } );
            }
        },
        

    }
</script>

<style scoped>

</style>