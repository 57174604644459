import axios from "./axios";

export default {


    getVaccinesPlan({ID_Business,ID_Branch}) {
        const options = {
            method: 'GET',
            url: `veterinaryVaccine/getPlan?ID_Business=${ID_Business}&ID_Branch=${ID_Branch}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getVaccinesByPlanId({id,ID_Business,ID_Branch}) {
        const options = {
            method: 'GET',
            url: `veterinaryVaccine/getVaccinesByPlanId/${id}?ID_Business=${ID_Business}&ID_Branch=${ID_Branch}`,
            headers: { 'content-type': 'application/json' }
          };
          
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    addPatientVaccine(data) {
        const options = {
            method: 'POST',
            url: 'veterinaryVaccine',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getPatientPlan(id) {
        const options = {
            method: 'GET',
            url: `veterinaryVaccine/data/patient/getPlans/${id}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getVeterinaryPerametersOfPlan(data) {
        const options = {
            method: 'POST',
            url: 'veterinaryVaccine/data/patient/getPerameters',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deletePerameters(data) {
        const options = {
            method: 'POST',
            url: 'veterinaryVaccine/deletePerametrs',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    updatePatientVacDetails(data) {
        const options = {
            method: 'PUT',
            url: `veterinaryVaccine/`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deleteSinglePerameters(data) {
        const options = {
            method: 'POST',
            url: 'veterinaryVaccine/deletePerametrs/single',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },


}