import axios from "./axios";

export default {
    getAllreport(id) {
        const options = {
            method: 'GET',
            url: `medicreport/${id}`,
            headers: { 'content-type': 'application/json' }
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getFilterReport(params) {
        const options = {
            method: 'GET',
            url: `medicreport/filter`,
            headers: { 'content-type': 'application/json' },
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    },
    createreport(data) {
        const options = {
            method: 'POST',
            url: `medicreport`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

}