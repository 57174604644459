<template>
  <v-card>
   
    <v-btn color="error" width="64px" elevation="0" class="close-btn" @click="closeWithData">
        <v-icon  >
          mdi-close
        </v-icon>
    
      </v-btn>
        <v-card-title class="text-h5">
          Lista de medicamentos y vacunas ({{ count }})
        </v-card-title>
     
     
        <v-card-title v-if="businesstype=='veterinary' && template != true" class="text-h5 d-flex justify-start">
          Especíe: {{species }}
        </v-card-title>
     
      
    
    <v-card-text>
      
            <div
            style="display: flex;flex-wrap: wrap; justify-content: space-between; margin-bottom: .3rem; margin-left: .8rem;margin-right: .8rem;">
            <v-btn v-if="showdeselect" class="btnclean mr-2 mb-2" @click="deselectAll">Deseleccionar todas</v-btn>
            <v-btn v-else @click="selectAll" class="btnclean">Seleccionar todas</v-btn>
            <v-btn @click="closeWithData" class="btn-add">Agregar</v-btn>
          </div>
          <GeneralFilter ref="generalFilter" v-if="!GetALL" :search="{}" :filterEndpoint="filterPromise" @emptyFilter="clearFilter" @filtered="filterHandler">
            <DxDataGrid width="100%" height="250px" :scrolling="{useNative:true}" :paging="{ enabled: false }" :show-borders="true" :data-source="!isFiltered ? medications : medicationsFiltered" key-expr="ID_Plan_Perameter" :word-wrap-enabled=true>
              <!--DxPaging :page-size="5"/-->
              <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
              <DxColumn :width="columnWidth" data-field="ID_Medicine" caption="ID_product"></DxColumn>
              <DxColumn :width="columnWidth" data-field="Medicine" caption="Medicamento / Vacuna"></DxColumn>
              <DxColumn :width="columnWidth" cell-template="type-service" caption="Tipo"></DxColumn>
              <template #show-template="{ data }">
                <div>
                  <v-checkbox v-model="data.data.checkbox" class="pt-0" @click="getSelectedRow(data.data)"></v-checkbox>
                  <!-- <input type="checkbox" id="checkbox" @change="getSelectedRow(data.data)"> -->
                </div>
              </template>
              <template #type-service="{data}">
                <div>
                  <p v-if="data.data.ItemType == 'VAC'">Vacuna</p>
                  <p v-if="data.data.ItemType == 'MED'">Medicamento</p>
                </div>
              </template>
            </DxDataGrid>
          </GeneralFilter>
          <GeneralFilter ref="generalFilter" v-else :search="{}" :filterEndpoint="filterPromise" @emptyFilter="clearFilter" @filtered="filterHandler">
            <DxDataGrid height="250px"  :scrolling="{ useNative: true }" :paging="{ enabled: false }" 
          :column-auto-width="true" :show-borders="true" :word-wrap-enabled=true :data-source="!isFiltered ? medications : medicationsFiltered" key-expr="ID_Medicine">
              <!--DxPaging :page-size="5"/-->
              <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
            
              <DxColumn data-field="Order" caption="Orden"></DxColumn>
              <DxColumn data-field="Medicine" caption="Medicamento / Vacuna"></DxColumn>
              <DxColumn cell-template="type-service" caption="Tipo"></DxColumn>
              <DxColumn cell-template="period-time" caption="Periodo de tiempo"></DxColumn>
              <template #show-template="{ data }">
                <div>
                  <v-checkbox v-model="data.data.checkbox" class="pt-0" @click="getSelectedRow(data.data)"></v-checkbox>
                  <!-- <input type="checkbox" id="checkbox" @change="getSelectedRow(data.data)"> -->
                </div>
              </template>
              <template #type-service="{data}">
                <div>
                  <p v-if="data.data.ItemType == 'VAC'">Vacuna</p>
                  <p v-if="data.data.ItemType == 'MED'">Medicamento</p>
                </div>
              </template>
              <template #period-time="{data}">
                <div>
                  <p v-if="data.data.PeriodUnit == 'Week'">{{data.data.PeriodTime}} Semana</p>
                  <p v-if="data.data.PeriodUnit == 'Day'">{{data.data.PeriodTime}} Día</p>
                  <p v-if="data.data.PeriodUnit == 'Month'">{{data.data.PeriodTime}} Mes</p>
                  <p v-if="data.data.PeriodUnit == 'Year'">{{data.data.PeriodTime}} Año</p>
                </div>
              </template>
              
            </DxDataGrid>
          </GeneralFilter>
         <!--  <div class="options">

            <div class="options-container">
              <div class="option">

                <DxButton text="Contained" styling-mode="contained" style="min-width: 120px;" :height="50"
                  @click="closeWithData()">
                  <template #content>
                    <span>
                      <DxLoadIndicator :visible="loadIndicatorVisible" class="button-indicator" />
                      <span class="dx-button-text">Asignar seleccionados</span>
                    </span>
                  </template>
                </DxButton>
              </div>
              <div class="option">

                <DxButton text="Contained" styling-mode="contained" style="min-width: 120px;" :height="50"
                  @click="closeWithAll()">
                  <template #content>
                    <span>
                      <DxLoadIndicator :visible="loadIndicatorVisible" class="button-indicator" />
                      <span class="dx-button-text">Asignar Todos</span>
                    </span>
                  </template>
                </DxButton>
              </div>
              &nbsp;

            </div>
          </div> -->
       
    </v-card-text>
  </v-card>
</template>

<script>
import { DxDataGrid, DxColumn } from "devextreme-vue/data-grid";
import GeneralFilter from "../GeneralFilter.vue";
import { multipleCriteriaFilter } from '../../helpers/object';

/* import { DxButton } from 'devextreme-vue/button'; */
export default {
  name: "VaccineListPopUp",
  components: { DxDataGrid, DxColumn, GeneralFilter },
  props: ["PlanId", "medicationDialog", "PatientMedic", "GetALL","species","template"] ,
  data() {
    return {
      medications: [],
      assignedMedication: [],
      manageMedic: [],
      checkbox: true,
      count: 0,
      showdeselect: true,
      businesstype: null,
      isFiltered: false,
      medicationsFiltered: [],
      didHaveAllData: false,
    };
  },
  watch: {
    medicationDialog() {
      if (this.medicationDialog == false) this.closeWithData();
      else this.getmedicationsByPlan();
    },
    PatientMedic() {
      this.getmedicationsByPlan();
    },
  },
  mounted() {
    this.businesstype = JSON.parse(localStorage.getItem("user")).businesstype;
    this.getmedicationsByPlan();
  },
  computed: {
    columnWidth() {
      return this.$vuetify.breakpoint.smAndDown ? '180px' : 'auto';
    },
  },
  methods: {
    clearFilter() {
      this.isFiltered = false;
    },
    filterHandler(response) {
      if (response.length === 0 && this.didHaveAllData === false) {
        this.didHaveAllData = true;
        this.getmedicationsByPlanListAll();
        this.$refs.generalFilter.filterHandler();
      }
      this.isFiltered = true;
      this.medicationsFiltered = response;
    },
    filterPromise(filterObj) {
      const filterArray =[
        "ID_Medicine",
        "Order",
        "Medicine",
        "PeriodTime"
      ];
      return new Promise((resolve, reject) => {
        try {
          resolve(multipleCriteriaFilter(filterArray, filterObj, this.medications));
        } catch (error) {
          reject(error);
        }
      });
    },
    saveAssign() {

    },
    addAll() {

    },
    getmedicationsByPlan() {
      let data = {
        id: this.PlanId,
        ID_Business : JSON.parse(localStorage.getItem("user")).businessid || "",
        ID_Branch : JSON.parse(localStorage.getItem("user")).branch || "",
        species: this.species
      };
     
      if(this.businesstype == 'medicalclinic'){
        if(this.GetALL == true) {
          this.$API.medicalmedication.getMedicationProductsHistory(data).then((response) => {
            
          
            let x =  response;
            console.log(x);
            x.map((el) => {
              el.checkbox = false;
              return el;
            });

            x = x.flat();
          
            this.medications = x;
          });
          
        }else {
        this.$API.veterinarymedication
          .showParametersByPlanID(data)
          .then((response) => {
            response.map((el) => {
              el.checkbox = false;
            });
            this.medications = response;
          });


        }
      }
    
      

      else{
        if(this.GetALL == true) {
          this.$API.veterinarymedication.getVeterinaryMedicationProducts(data).then((response) => {
            let x = response.map((el) => {
              return {
                ...el,
                checkbox: false
              };
            });
            
            this.medications = x;
          });
          
        }else {
        this.$API.veterinarymedication
          .showParametersByPlanID(data)
          .then((response) => {
            response.map((el) => {
              el.checkbox = false;
            });
            this.medications = response;
          });


        }
      }
    },
    getmedicationsByPlanListAll() {
      let data = {
        id: this.PlanId,
        ID_Business : JSON.parse(localStorage.getItem("user")).businessid || "",
        ID_Branch : JSON.parse(localStorage.getItem("user")).branch || "",
      };
     
      if(this.businesstype == 'medicalclinic'){
        if(this.GetALL == true) {
          this.$API.medicalmedication.getMedicationProducts(data).then((response) => {
            
          
            let x =  response;
            console.log(x);
            x.map((el) => {
              el.checkbox = false;
              return el;
            });

            x = x.flat();
          
            this.medications = x;
          });
          
        }else {
        this.$API.veterinarymedication
          .showParametersByPlanID(data)
          .then((response) => {
            response.map((el) => {
              el.checkbox = false;
            });
            this.medications = response;
          });


        }
      }
    
      

      else{
        if(this.GetALL == true) {
          this.$API.veterinarymedication.getVeterinaryMedicationProducts(data).then((response) => {
            
            let x = response.map((el) => {
              return {
                ...el,
                checkbox: false
              };
            });
          
            this.medications = x;
          });
          
        }else {
        this.$API.veterinarymedication
          .showParametersByPlanID(data)
          .then((response) => {
            response.map((el) => {
              el.checkbox = false;
            });
            this.medications = response;
          });


        }
      }
    },
    getSelectedRow(e) {
      this.count = 0;
      
      if(!this.GetALL){
        if (e.checkbox == true) {
          this.medications.map((el) => {
            if (el.ID_Plan_Perameter == e.ID_Plan_Perameter) el.checkbox = true;
          });
        }
      }
      this.medications.map((el) => {
        if (el.checkbox == true) this.count++;
      });
      if (e.checkbox == true) {
        this.manageMedic.push(e);
      } else {
        if (this.manageMedic.indexOf(e) != -1) {
          this.manageMedic.splice(this.manageMedic.indexOf(e), 1)
        }
      }
    },
    closeWithAll() {
      this.selectAll();
      this.closeWithData()
    },
    closeWithData() {
      let data = [];
      this.medications.map((el) => {
        if (el.checkbox == true) data.push(el);
      });
      this.$emit("closeMedic", data);
      this.count = 0;
      this.getmedicationsByPlan();
    },
    selectAll() {
      this.medications.map((el) => {
        el.checkbox = true;

      });
      //  this.manageMedic = { ... this.medications};
      this.count = this.medications.length;
      this.showdeselect = true;
    },
    deselectAll() {
      this.medications.map((el) => {
        el.checkbox = false;
      });
      // this.manageMedic = [];
      this.count = 0;
      this.showdeselect = false;
    },

  },
};
</script>

<style>
.dx-datagrid-content .dx-datagrid-table .dx-row>td,
.dx-datagrid-content .dx-datagrid-table .dx-row>tr>td {
  vertical-align: top;
  text-align: center !important;
}

.btn-add {
  background-color: #3498DB !important;
  color: white !important;
}

.btnsave {
  background-color: #00A178 !important;
  color: white !important;
}

.btnclean {
  background-color: #F29D35 !important;
  color: white !important;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  min-width: 24%;
  display: inline-block;
  margin: 10px;
}
.close-btn {
  color: white !important;
  position: relative !important;
  float: right !important;
}
</style>
