const getDefaultState = () => {
    return {
        windowsList: [
            {
                name: 'invoices',
                component: 'Invoices',
                unique: false,
                uuid: 1,
            },
            {
                name: 'dashboard',
                component: 'DashboardPanel',
                meta: {},
                unique: false,
                uuid: 2,
            }
        ],
        activeWindowUuid: 0,
        reloadData: null
    }
  }
  
export default {
    state: {
        windowsList: [
            {
                name: 'invoices',
                component: 'Invoices',
                unique: true,
                uuid: 1,
            },
            {
                name: 'dashboard',
                component: 'DashboardPanel',
                meta: {},
                unique: true,
                uuid: 2,
            }
        ],
        activeWindowUuid: 0,
        reloadData: null
    },
    getters: {
        windowsList: state => state.windowsList,
        activeWindowUuid: state => state.activeWindowUuid,
        activeWindow: state => {
            return state.windowsList.find((w) => w.uuid == state.activeWindowUuid)
        },
        meta: state => {
            return state.windowsList.find((w) => w.uuid == state.activeWindowUuid).meta
        },
        reloadData: state => state.reloadData
    },
    actions: {
        addWindow({ commit, getters }, payload) {
            if (!payload.unique) {
                payload.uuid = Date.now();
                commit('ADD_WINDOW', payload);
            }
            else{
                let win = getters.windowsList.find((w) => w.name == payload.name);
                if (win) commit('SET_CURRENT_UUID', win.uuid);
                else commit('ADD_WINDOW', { ...payload, uuid: Date.now() })
            }

            

        },
        expanedWindow({ commit }, payload) {
            commit('SET_CURRENT_UUID', payload.uuid)
        },
        resetWindow({ commit }) {
            commit('RESET_CURRENT_UUID')
        },
        deleteWindow({ commit, getters }, payload) {
            let win = getters.windowsList.find((w) => w.name == payload.name);
            if(payload.openlastwindow)
                win.openlastwindow = 1
            if (win) commit('DELETE_WINDOW', win)
        },
        deleteWindowByUuid({ commit }, payload) {
            if(payload.openlastwindow)
                payload.openlastwindow = 1
            commit('DELETE_WINDOW', payload)
        },
        reloadData({ commit }, payload) {
            commit('RELOAD_DATA', payload)
        },
        restartState({ commit }) {
            commit('RESET_STATE')
        }
    },
    mutations: {
       async  ADD_WINDOW(state, value) {
             await state.windowsList.push(value);
        
            state.activeWindowUuid = value.uuid;

        },
        SET_CURRENT_UUID(state, value) {
            state.activeWindowUuid = value;
        },
        RESET_CURRENT_UUID(state){
            state.activeWindowUuid = 0;
        },
        DELETE_WINDOW(state, value) {
            let openLastWindow = value.openlastwindow
            let winIndex = state.windowsList.findIndex((w) => w.uuid == value.uuid);
            if (winIndex != -1) {
                if (state.activeWindowUuid == state.windowsList[winIndex].uuid && openLastWindow == 1) {
                    state.activeWindowUuid = state.windowsList[Math.max(winIndex - 1, 0)].uuid
                }
                state.windowsList.splice(winIndex, 1);
            }
        },

        RELOAD_DATA(state, payload) {
            state.reloadData = payload
        },
        RESTART_STATE(state) {
         return   Object.assign(state, getDefaultState())
          }
    }
}
