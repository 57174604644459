<template>
    
    <v-card height="inherit" style="overflow: auto;">
        <block v-if="loading"></block>
        <v-card-title class="text-h5">
            Buscar plantillas
        </v-card-title>

        <v-card-text>
            <v-row>
                <v-col cols="12" style="display: flex;justify-content: flex-end;">
                    <v-btn class="btnclean" @click="cleanData">Limpiar</v-btn>
                </v-col>
                
             <!-- btn in future   <v-col cols="6"></v-col> -->

            </v-row>
            <v-row style="margin-top: 2rem;">
                <v-col cols="12" lg="5">
                    
                    <GeneralFilter
                    entity="lista"
                    :filterEndpoint="$API.laboratory.manageTemplateFiltered"
                    :search="search"
                    @emptyFilter="getTemplates"
                    @filtered="filterHandlerTemplates">
                        <DxDataGrid class="isScrolledByGeneralFilter" :data-source="templates" :showBorders="true" :word-wrap-enabled="true" :showRowLines="true"
                                    key-expr="ID_LaboratoryTest" :scrolling="{ useNative: true }"    
          :column-auto-width="true"
                                    >
                            <DxColumn  cell-template="show"></DxColumn>
                            <DxColumn  cell-template="selectTemplate"></DxColumn>
                            <DxColumn data-field="ID_LaboratoryTest" caption="Laboratory Test ID"></DxColumn>
                            <DxColumn cell-template="laboratory-name" caption="Laboratory Name"></DxColumn>

                            <template #selectTemplate="{data}">
                                <a href=""  @click.prevent.stop="showLabDetails(data.data.ID_LaboratoryTest, true, data.data.LaboratoryName,data.data)">
                                    <v-icon>mdi-check-bold</v-icon>
                                </a>
                            </template>
                            <template #show="{data}">
                                <a href="#" @click.prevent.stop="showLabDetails(data.data.ID_LaboratoryTest)">
                                    <v-icon color="primary">mdi-eye</v-icon>
                                </a>
                            </template>
                            <template #laboratory-name="{data}">

                                <span v-if="idSelected == data.data.ID_LaboratoryTest " style="color: cornflowerblue;">{{data.data.LaboratoryName}}</span>
                                <span v-else>{{data.data.LaboratoryName}}</span>
                            </template>
                        </DxDataGrid>              
                    </GeneralFilter>
                </v-col>
                <v-col cols="12" lg="7">

                <GeneralFilter
                entity="lista"
                :filterEndpoint="$API.laboratory.showLabDetailsFiltered"
                :search="search"
                @emptyFilter="showLabDetails"
                @filtered="filterHandlerDetails">
                    <DxDataGrid class="isScrolledByGeneralFilter" :data-source="details" :showBorders="true" :word-wrap-enabled="true" :showRowLines="true" key-expr="ID_TestParam">
                        <DxColumn data-field="ParameterOrder" caption="Order"></DxColumn>
                        <DxColumn data-field="ParameterName" caption="Parameter Name"></DxColumn>
                        <DxColumn data-field="ParameterValue" caption="Parameter Value"></DxColumn>
                        <DxColumn data-field="ParameterUnit" caption="Parameter Unit"></DxColumn>
                        <DxColumn data-field="ParameterExpected" caption="Parameter Expected"></DxColumn>
                        <DxColumn data-field="ParameterType" caption="Parameter Type"></DxColumn>
                    </DxDataGrid>
                </GeneralFilter>
                </v-col>
            </v-row>

        </v-card-text>
        <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    ></alerts>
    </v-card>
   
</template>

<script>
    import GeneralFilter from "@/components/GeneralFilter";
    import {DxDataGrid, DxColumn} from 'devextreme-vue/data-grid';
    import Alerts from "@/components/Alerts";
    import Block from "@/components/Block";
    export default {
        name: "ManageTemplate",
        components: {DxColumn, DxDataGrid,Alerts, GeneralFilter, Block},

        data() {
            return {
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
                templates: [],
                details: [],
                search: {
                    filter: null
                },
                idSelected: null,
                data: {
                    filter: null
                },
                idToSearch: null,
                dataToSearch: null,
                backAndCloseSearch: null,
                loading: false,
            }
        },

        mounted() {
            this.search.business = JSON.parse(localStorage.getItem("user")).businessid || ""
            this.search.branch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.getTemplates()
        },
        methods: {
            closeAlert() {
                this.alert.show = false;
            },
            showAlert(type, header, body, options = null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
            },
            async showLabDetails(id, backAndClose = false, laboratoryName = null, data) {
                this.loading = true
                console.log("id: ",id)
                console.log("backAndClose: ", backAndClose)
                console.log("laboratoryName: ", laboratoryName)
                console.log("data: ", data)

                if(id != undefined){
                    this.idToSearch = id
                }
                this.search.id = this.idToSearch
                // if(id != undefined && id != this.idToSearch && backAndClose == true && data != undefined){
                    // this.backAndCloseSearch = true
                // }
                // if(id != undefined && id != this.idToSearch && backAndClose == false){
                    // this.backAndCloseSearch = false
                // }

                if(backAndClose == false){
                    this.idSelected = (id != undefined || id != null) ? id : this.idSelected
                    this.$API.laboratory.showLabDetailsHistory(this.idToSearch)
                        .then(response => {
                            this.loading = false
                            this.details = response
                        })
                         
                }
                else{
                    await this.$API.laboratory.showLabDetailsHistory(this.idToSearch)
                        .then(response => {
                            this.loading = false
                            this.details = response
                            this.$emit('templateSelected', this.details, laboratoryName, id,data)
                        })
                }
            },
            getTemplates(){
                this.$API.laboratory.manageTemplateHistory(this.search)
                .then(response => {
                    this.templates = response
                })
            },
            cleanData(){
                this.idSelected = null
                this.details = []
            },
            filterHandlerDetails(response) {
                this.details = response;
            },
            filterHandlerTemplates(response) {
                this.templates = response;
            }

        }
    }
</script>

<style scoped>
.btnclean{
  background-color: #F29D35 !important;
  color: white;
}


</style>