import axios from "./axios";

export default {

    createImagesData(data) {
        const options = {
            method: 'POST',
            url: `MedicalImage`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getImages(id) {
        const options = {
            method: 'GET',
            url: `MedicalImage/header/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getImagesFiltered(params) {
        const options = {
            method: 'GET',
            url: `MedicalImage/headerFiltered`,
            headers: {'content-type': 'application/json'},
            params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deleteImageWithFiles(id) {
        const options = {
            method: 'DELETE',
            url: `MedicalImage/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    filesByImageID(id) {
        const options = {
            method: 'GET',
            url: `MedicalImage/${id}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deletespecifiedFile(id) {
        const options = {
            method: 'DELETE',
            url: `MedicalImage/specificfile/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    updateImageswithFiles(id, data) {
    /*   console.log("esta es la data", data); */
        const options = {
            method: 'PUT',
            url: `MedicalImage/${id}`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

}
