import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';

export const printToExcel = (data, fileName,columnsToSum = null) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(fileName);
    exportDataGrid({
        component: data.component,
        worksheet,
        topLeftCell: { row: 4, column: 1 },
    }).then((cellRange) => {
        // header
        const headerRow = worksheet.getRow(2);
        headerRow.height = 30;
        worksheet.mergeCells(2, 1, 2, 8);

        headerRow.getCell(1).value = (fileName)
        headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 };
        headerRow.getCell(1).alignment = { horizontal: 'center' };

        // footer
        const footerRowIndex = cellRange.to.row + 2;
        const footerRow = worksheet.getRow(footerRowIndex);
        worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);

        footerRow.getCell(1).value = '-';
        footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true };
        footerRow.getCell(1).alignment = { horizontal: 'right' };
      
       
        
        
        if(columnsToSum != null){
            const sumRowName = worksheet.getRow(cellRange.to.row + 1);
            sumRowName.getCell(1).value = 'Sumatoria';
        

            const Currency = JSON.parse(localStorage.getItem('branch')).Currency;
            columnsToSum.forEach(columnName => {
                // Find the index of the column by name in the header row
                const columnIndex = worksheet.getRow(4).values.findIndex(cell => cell === columnName);
                
              
                if (columnIndex === -1) {
                    return;
                }
                // Get all values for the columns 
                let columnValues = worksheet.getColumn(columnIndex).values;
            
                columnValues = columnValues.filter(value => value.includes(Currency));

                let sumValue = 0;

                columnValues.forEach((item) => {
                    let value = item.replace(Currency, '');

                    if (value.includes('-')) {
                        value = value.replace('-', '');
                        value = value.replace(',', '');

                    }
                    sumValue += parseFloat(value);
                });

                sumRowName.getCell(columnIndex).value = Currency + '' +sumValue.toFixed(2);

            

                
            });
        }
/*  
        if (sum && sum.length > 0) {
            const sumRowIndex = cellRange.to.row + 3; // Row after the footer
            sum.forEach(columnIndex => {
                let sumValue = 0;
                for (let i = 4; i <= cellRange.to.row; i++) {
                    const cellValue = worksheet.getRow(i).getCell(columnIndex).value;
                    sumValue += (typeof cellValue === 'number' ? cellValue : 0);
                }
                const sumCell = worksheet.getRow(sumRowIndex).getCell(columnIndex);
                sumCell.value = sumValue;
                sumCell.font = { bold: true };
            });
        } */

    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName + '.xlsx');
        });
    });

};