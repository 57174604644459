const providersData = {
    namespaced: true,
    state: () => ({
        currentView: "tab-1",
        data: 'none',
        reloaders: {
            reloadAddPurchase: 0,
            reloadHistoryPurchase: 0,
        },
        orderDetails: null,
        order: null
    }),
    mutations: {
        changeView(state, view){
            state.currentView = view
        },
        setData(state, data){
            state.data = data
        },
        setReloaders(state, payload){
            switch(payload) {
                case 'reloadAddPurchase':
                    state.reloaders.reloadAddPurchase++;
                    break;
                case 'reloadHistoryPurchase':
                    state.reloaders.reloadHistoryPurchase++;
                    break;
            }
        },
        setOrderDetails(state, payload){
            state.orderDetails = payload
        },
        setOrder(state, payload){
            state.order = payload
        }
    },
    actions: {

    }
}

export default providersData