<template>
    <v-row class="mx-2 pt-5 d-flex justify-right">
        <date-picker label="Fecha inicio" @dateSelected="(param) => (startDate = param)" class="mx-5" />
        <date-picker label="Fecha fin" @dateSelected="(param) => (endDate = param)" class="mx-5" />
        <div>
            <v-btn class="btn-add my-1 mx-2" @click="filterData">Filtrar<v-icon>mdi-filter</v-icon></v-btn>
            <v-btn class="btn-add my-1 mx-2" @click="resetFilter"><v-icon>mdi-clipboard-list</v-icon>&nbsp;Mostrar todos</v-btn>
        </div>
    </v-row>
</template>

<script>
import DatePicker from '@/components/DatePicker'
import { convertDateFormat } from '@/helpers/formatDate'

export default {
    name: 'DateFilter',

    components: { DatePicker },

    props: {
        dataToFilter: {
            type: Array,
        },
        componentToFilter: {
            type: String,
            default: 'History'
        },
        filterEndpoint: {
            type: Function
        },
        isFilteredByOutsource: {
            type: Boolean
        },
        search:{
            type: Object,
            default: () => ({})
        }
    },

    mounted() {
        this.saveData()
    },

    data() {
        return {
            startDate: null,
            endDate: null,
            oldData: [],
            sourceFormat: "dd/mm/yyyy",
            targetFormat: "yyyy-mm-dd",
        }
    },

    methods: {
        async filterData() {
            await this.$nextTick();
            this.$emit('datesSelected', { start: this.startDate, end: this.endDate })
            this.setDateFormat()

            this.resetDateData()
            console.log(this.isFilteredByOutsource);
            !this.isFilteredByOutsource
                ? this.filterByEndpoint()
                : this.filterByComponent()
        },
        resetFilter() {
            
            this.$emit('filtered-data', this.oldData)
        },
        saveData() {
            this.oldData = [...this.dataToFilter]
        },
        setDateFormat() {
            if (this.startDate !== null || this.endDate !== null) {
                if (!(this.startDate instanceof Date)) {
                    this.startDate = new Date(convertDateFormat(this.startDate, this.sourceFormat, this.targetFormat))
                }
                if (!(this.endDate instanceof Date)) {
                    this.endDate = new Date(convertDateFormat(this.endDate, this.sourceFormat, this.targetFormat)) 
                }
            }
        },
        resetDateData() {
            this.dataToFilter = [...this.oldData]
        },
        filterByComponent() {
            console.log('filtrando por componente');
            if (this.componentToFilter == 'History') {
                const filteredData =
                    [...this.dataToFilter].filter((data) => {
                        const dataDate = new Date(convertDateFormat(data.InvoiceDate, this.sourceFormat, this.targetFormat))
                        const isInRange = dataDate >= this.startDate && dataDate <= this.endDate
                        return isInRange
                    })
                this.$emit('filtered-data', filteredData)
            }
            if (this.componentToFilter == 'PurchareOrdersList') {
                const filteredData =
                    [...this.dataToFilter].filter((data) => {
                        const dataDate = new Date(convertDateFormat(data.PurchaseOrderDate, this.sourceFormat, this.targetFormat))
                        const isInRange = dataDate >= this.startDate && dataDate <= this.endDate
                        return isInRange
                    }
                    )
                this.$emit('filtered-data', filteredData)
            }
        },
        async filterByEndpoint(){
            console.log('filtrando por endpoint');
           
            const result = await this.filterEndpoint({ start: this.startDate, end: this.endDate, ...this.search});
            this.$emit('filtered-data', result)
        }
    }
}
</script>

<style scoped>
.btn-add {
    background-color: #4690DB !important;
    color: white;
}

.btn-filter {
    background-color: #f29d35 !important;
    color: white;
}

.background {
    background: white !important;
    color: white;
}
</style>