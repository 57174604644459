<template>
      <v-row class="box size" :class="{'size-desktop': $vuetify.breakpoint.mdAndUp, 'size-mobile': $vuetify.breakpoint.smAndDown}" style="background-color: white; ">
        
        <v-col cols="2" md="1" style="display: flex; justify-content: center;" >
            <img :src="patient.ProfilePicPath ? patient.ProfilePicPath : 'https://drive.google.com/uc?export=view&id=1c8jTvxIT33xIptNpcVgm6KoKnVHvGq-Y'" width="50px" height="50px" alt="80px"  >

        </v-col>

        <v-col cols="10" md="11" >
<v-row style="padding-top: 15px !important ;" >
<v-col cols="12" md="4" style="height: 20px; ">
    <p><b>ID de Paciente: </b> {{ patient.ID_Patient }}</p>
</v-col>

<v-col cols="12" md="4"  style="height: 20px; ">
    <p><b>Nombres</b> {{ patient.FirstName }}</p>
</v-col>
<v-col cols="12" md="4"  style="height: 20px; ">
    <p><b>Apellidos </b> {{ patient.LastName }}</p>
</v-col>
<v-col cols="12" md="4"  style="height: 20px; ">
    <p><b>Edad </b> {{ patient.Age }}</p>
</v-col>
<v-col cols="12" md="4"  style="height: 20px; ">
    <p><b>Genero:</b> {{ patient.Gender }}</p>
</v-col>
<!-- <v-col cols="12" md="4"  style="height: 20px; ">
<p>
  <b>{{ patientinfo.customer.Is_a_Business ? "Empresa:" : "Dueño:" }}</b>
  {{  patientinfo.customer.Is_a_Business ? patientinfo.customer.BusinessName :  patientinfo.customer.FirstName + " " +  patientinfo.customer.LastName}}</p>
</v-col> -->
<v-col cols="12" md="4"  style="height: 20px; ">
    <p><b>Médico actual: </b> {{ useData.firstName + " " + useData.lastName }}</p>
</v-col>
</v-row>
       
       
                    

        </v-col>
    
</v-row>

</template>

<script>
export default {
    name: "UserProfileLab",
    props: ['patientinfo', 'patient', 'useData'],

}

</script>

<style scoped>
.size-desktop {
  height: 60px; /* Altura para pantallas grandes */
}
.size-mobile {
  height: 160px; /* Altura para pantallas móviles */
}

</style>