export const throughObj = ( obj, callback ) => {
    try {
        for(const key in obj ) {
            callback(key);
        }
    } catch (error) {
        console.log(error);
    }
}

export const objectKeysToLowerCase = obj => {
    try {
        let newObj;
        
        throughObj( obj, (key) => {
            const previuosKey = key;
            newObj[key.toLowerCase()] = obj[previuosKey]
        } );
    
        return newObj;
    } catch (error) {
        console.log(error);
    }
}

export const getAllIndexes = (arr, val) => {
    var indexes = [], i = -1;
    while ((i = arr.indexOf(val, i+1)) != -1){
        indexes.push(i);
    }
    return indexes;
}

export const multipleCriteriaFilter = ( filterArray, searchObj, toFilter ) => {
    try {
        const { filter } = searchObj;
        const lowerCaseFilter = filter.toLowerCase()
        const response = toFilter.filter((current) => {
            for(const element of filterArray) {
                if( current[element] ) {
                    const stringToCompare = current[element].toString().toLowerCase();
                    if( stringToCompare.includes( lowerCaseFilter ) ) return true;
                } 
            }
        });
        return response;
    } catch (error) {
        throw new Error(`Ocurrió el siguiente error al filtrar ${error.message}`);
    }
}

export const validateNITHomologation = ( entity, model, label, labelToShow ) => {
    try {
        const lengthToValidate = model[ entity ][ label ].replace(/-/g, "").length;
        if( lengthToValidate != 9 && lengthToValidate != 14 ) {
            model.showAlert("warning", "Advertencia", `El campo ${ labelToShow } no tiene la longitud requerida`);
            return false;
        }
        return true;
    } catch ( error ) {
        console.log(error);
        model.showAlert("error", "Error", `Ha ocurrido el siguiente error al ejecutar validaciones sobre el campo NIT: ${error}`);
        return false;
    }
}

export const vesatileCriteriaFilter = ( filterArray, searchObj, toFilter ) => {
    try {
        const { like } = searchObj;
        const filter = JSON.parse(searchObj.filter);
        let lowerCaseFilter = '';
        if(like) 
        lowerCaseFilter = like.toLowerCase();
       // console.log(searchObj);
        const response = toFilter.filter((current) => {
            if(like) 
            for(const element of filterArray) {
                if( current[element] ) {
                    const stringToCompare = current[element].toString().toLowerCase();
                    if( stringToCompare.includes( lowerCaseFilter ) ) return true;
                } 
            }
            if(filter.length > 0)
            for(const element of filter) {
                if( current[element.name] === element.value ) return true; 
            }
        });
        return response;
    } catch (error) {
        throw new Error(`Ocurrió el siguiente error al filtrar ${error.message}`);
    }
}

export const isFloat = n => {
    return Number(n) === n && n % 1 !== 0;
}

export const onlyUnique = (value, index, array) => {
    return array.indexOf(value) === index;
}
  
export const removeDuplicates = arr => {
    try {
        return arr.filter( onlyUnique );
    } catch (error) {
        console.log(error);
        throw error;
    }
};