<template>
  <div class="transparentbackground">
    <user-profile-lab
      :patient="patient"
      :patientinfo="data"
      :useData="useData"
    ></user-profile-lab>

    <!-- https://drive.google.com/uc?export=view&id=1c8jTvxIT33xIptNpcVgm6KoKnVHvGq-Y remember changue url for this use -->
    <!-- div class="infobox" style="width: 100%; height: 100px;">
            <img :src="patient.ProfilePicPath ? patient.ProfilePicPath: ' https://drive.google.com/uc?export=view&id=1c8jTvxIT33xIptNpcVgm6KoKnVHvGq-Y'" width="100px" alt="80px" class="mr-3" style=" padding: 10px; ">
            <div style="margin-top: 30px;justify-content: center;">
                <b style="">ID:</b><br /> {{ patient.ID_Patient }}
            </div>

            <div style="margin-top: 30px;"><b>Correo Electronico:</b><br />{{ patient.NotificationEmail }}</div>

            <div style="margin-top: 30px;">
                <b>Nombre:</b><br /> {{ patient.FirstName }}
            </div>
            <div style="margin-top: 30px; ">


                <b>Apellido:</b> <br />{{ patient.LastName }}
            </div>

        </div> -->
    <v-row style="margin-top: 1rem">
      <div style="margin-top: 0.3rem">
        <v-dialog
          v-model="showLabHistory"
          :width="'90%'"
        >
          <v-card class="pa-4" height="inherit">
            <v-col
              cols="12"
              style="display: flex; justify-content: space-between"
            >
              <v-btn small class="btnsave" @click="printAll">
                <v-icon>mdi-printer</v-icon>
              </v-btn>
              <v-btn
                small
                class="btndelete"
                @click.prevent="showLabHistory = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>

            <!-- /*<img :src="$store.getters.getBackendUrl + '/public' + patient.ProfilePicPath"
                    width="40"
                    alt="" class="mr-3"> -->
            <GeneralFilter
              :search="{ id: patient.ID_Patient }"
              :filterEndpoint="$API.laboratory.patientLabTestsFiltered"
              @emptyFilter="getAllLabs"
              @filtered="filterHandler"
            >
              <DxDataGrid
                :scrolling="{ useNative: true }"
                :onRowUpdating="handleRowUpdatingMaster"
                @row-updated="OrderRow"
                :show-borders="true"
                :data-source="labs"
                key-expr="ID"
                :column-auto-width="true" 
                style="width: fit-content;"
              >
                <DxPaging :page-size="5" />
                <DxEditing mode="cell" :allow-updating="true" />
                <DxColumn
                  :min-width="80"
                  cell-template="checkvalues"
                  caption="Sel"
                ></DxColumn>
                <DxColumn
                  :min-width="80"
                  cell-template="show-template"
                  caption=""
                ></DxColumn>
                <DxColumn
                  :min-width="100"
                  :allow-editing="false"
                  data-field="LaboratoryTestDate"
                  caption="Fecha"
                ></DxColumn>
                <DxColumn
                  :min-width="100"
                  data-field="Order"
                  caption="Orden"
                ></DxColumn>
                <DxColumn
                  :min-width="100"
                  :allow-editing="false"
                  data-field="ID"
                  caption="ID"
                ></DxColumn>
                <DxColumn
                  :min-width="300"
                  :allow-editing="false"
                  data-field="LaboratoryName"
                  caption="Examen"
                  cssClass="left-align"
                ></DxColumn>
                <DxColumn
                  :min-width="100"
                  cell-template="billing-template"
                  caption="Fac"
                ></DxColumn>
                <DxColumn  
                  :min-width="100"
                  cell-template="filling-template"
                  caption="Lle"
                ></DxColumn>
                <template #show-template="{ data }">
                  <div>
                    <a
                      href="#"
                      @click.prevent.stop="
                        selectedLab(data.data);
                        showLabHistory = false;
                      "
                    >
                      <v-icon color="primary">mdi-eye</v-icon>
                    </a>
                  </div>
                </template>
                <template #billing-template="{ data }">
                  <div>
                    <v-icon v-if="data.data.Billing" color="green"
                      >mdi-check</v-icon
                    >
                    <v-icon v-else color="red">mdi-close</v-icon>
                  </div>
                </template>
                <template #filling-template="{ data }">
                  <div>
                    <v-icon v-if="data.data.Filling" color="green"
                      >mdi-check</v-icon
                    >
                    <v-icon v-else color="red">mdi-close</v-icon>
                  </div>
                </template>
                <template #checkvalues="{ data }">
                  <v-checkbox
                    style="max-height: 10px;"
                    v-model="data.data.Checked"
                    color="primary"
                  ></v-checkbox>
                </template>
              </DxDataGrid>
            </GeneralFilter>
          </v-card>
        </v-dialog>
      </div>

      <v-col style="margin-top: 0.6rem" class="box" col="12" md="12">
        <v-row class="ml-2 mr-2" style="margin-top: 1rem">
          <v-col cols="12" class="tabsNh">
            <v-col
              v-if="addTemplateForm.ID != null"
              style="display: flex; justify-content: space-between"
            >
              <v-btn
                small
                color="error"
                @click.prevent="
                  showAlert(
                    'question',
                    'Borrar',
                    'Esta seguro de borrar elemento esta accion no se puede deshacer',
                    'DeleteElement'
                  )
                "
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <div>
                <v-btn
                  small
                  class="btnblue mr-1"
                  @click="showLabHistory = !showLabHistory"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
                <v-btn
                  v-if="addTemplateForm.LaboratoryName != null"
                  small
                  style="margin-right: 0.3rem"
                  @click.prevent="printHandler"
                >
                  <v-icon> mdi-printer </v-icon>
                </v-btn>
                <v-btn
                  small
                  class="btnclean"
                  @click.prevent="confirmCleanLab()"
                  style="margin-right: 0.3rem"
                >
                  <v-icon> mdi-broom </v-icon>
                </v-btn>
                <v-btn
                  v-if="$store.getters['rolesUser/getCustomRole'](33)"
                  small
                  color=" btn-add"
                  @click.prevent="updateParam"
                >
                  <v-icon> mdi-content-save </v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col v-else style="display: flex; justify-content: flex-end">
              <v-btn
                small
                class="btnblue mr-1"
                @click="showLabHistory = !showLabHistory"
                v-if="$store.getters['rolesUser/getCustomRole'](131)"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                v-if="addTemplateForm.LaboratoryName != null"
                small
                style="margin-right: 0.3rem"
                @click.prevent="
                  print = {
                    show: true,
                    data: {
                      header: addTemplateForm,
                      body: pltp,
                      profile: patient,
                    },
                    prevView: false,
                  }
                "
              >
                <v-icon> mdi-printer </v-icon>
              </v-btn>
              <v-btn
                small
                class="btnclean"
                @click.prevent="confirmCleanLab()"
                style="margin-right: 0.3rem"
              >
                <v-icon>mdi-broom</v-icon>
              </v-btn>
              <v-btn
                small
                class="btn-add"
                @click.prevent="saveTemplate"
                v-if="$store.getters['rolesUser/getCustomRole'](33)"
              >
                <v-icon> mdi-content-save </v-icon>
              </v-btn>
            </v-col>
          </v-col>

          <v-col cols="12" md="3" sm="12" style="margin-top: 1rem">
            <date-picker
              @dateSelected="
                (param) => {
                  date = param;
                }
              "
              v-model="date"
              :value="
                this.moment(date, 'DD/MM/YYYY').format('dddd, MMMM Do YYYY')
              "
            >
            </date-picker>
          </v-col>
          <v-col cols="12" md="9" sm="0" style="margin-top: 1rem"> </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              dense
              disabled
              v-model="addTemplateForm.LaboratoryName"
              label="Plantilla seleccionada"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="8" style="display: flex">
            <v-btn
              v-if="addTemplateForm.LaboratoryName == null"
              class="my-2 ml-3 btnsave"
              @click="searchTemplateDialog = true"
            >
              Plantilla de laboratorio
            </v-btn>
            <v-checkbox
              class="ml-3 my-2"
              v-model="addTemplateForm.Billing"
              :value="addTemplateForm.Billing"
              readonly
              label="Facturado"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-col
          style="
            display: flex;
            justify-content: flex-end;
            margin-bottom: 0.5rem;
            padding: 0px 16px 16px 16px;
          "
        >
          <v-btn
            class="btnsave"
            small
            @click="addDetails"
            v-if="addTemplateForm.LaboratoryName != null"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <!--   <div  >
                       <div style="padding: 14px;">
                           
                           <div style="display: flex; justify-content: flex-start; margin-top: 0.1rem;height: 30px; padding-left: 25%;">
                              < <v-checkbox v-model="addTemplateForm.Billing" :value="addTemplateForm.Billing"
                                readonly
                               label="Facturado"></v-checkbox> 
                                <v-checkbox style="display: none;" v-model="addTemplateForm.Filling" :value="addTemplateForm.Filling"
                                   label="Llenado"></v-checkbox>
                           </div>
                           <div  style="display: flex; justify-content: flex-end; margin-bottom: 0.5rem; padding: 0px 16px 16px 16px;">
                               <v-btn class="btnsave"  small @click="addDetails" v-if="addTemplateForm.LaboratoryName != null">
                                   <v-icon>mdi-plus</v-icon>
                               </v-btn>
                           </div> -->
        <DxDataGrid
          :scrolling="{ useNative: true }"
          :allow-updating="true"
          :allow-deleting="true"
          :confirm-delete="false"
          @editing-start="onEditingStart"
          :onRowUpdating="handleRowUpdating"
          :onRowRemoving="handleRowDeleting"
          :onSaved="savedparams"
          :data-source="pltp"
          ref="dataDetails"
          sortBy="parameter-order"
          :allow-column-reordering="true"
          :show-borders="true"
          @key-down="onKeyDown"
        >
          <DxPaging :enabled="false" />
          <!-- <DxEditing 
                                               >
                                                :allow-adding="addTemplateForm.LaboratoryName != null ? true : false"
                           <DxTexts saveRowChanges="Guardar" editRow="Editar" deleteRow="Eliminar"
                               cancelRowChanges="Cancelar" confirmDeleteMessage="¿Esta seguro de eliminar este registro?">
                           </DxTexts>
                                               </DxEditing> -->
          <DxEditing
            :allow-updating="true"
            :allow-deleting="true"
            :confirmDelete="false"
            :select-text-on-edit-start="true"
            start-edit-action="click"
            mode="cell"
            :use-icons="true"
          >
          </DxEditing>
          <DxKeyboardNavigation
            :edit-on-key-press="true"
            enter-key-action="moveFocus"
            enter-key-direction="column"
          />
          <Dx
          tton name="delete" />
          <DxColumn
            :width="columnWidth"
            data-field="ParameterOrder"
            caption="Orden"
          ></DxColumn>
          <DxColumn
            :width="columnWidth"
            data-field="ParameterName"
            caption="Nombre"
          ></DxColumn>
          <DxColumn
            :width="columnWidth"
            data-field="ParameterValue"
            caption="Valor"
          ></DxColumn>
          <DxColumn
            :width="columnWidth"
            data-field="ParameterUnit"
            caption="Unidad"
          ></DxColumn>
          <DxColumn
            :width="columnWidth"
            data-field="ParameterExpected"
            caption="Valor esperado"
          ></DxColumn>
          <DxColumn
            :width="columnWidth"
            cell-template="parametertype-selection"
            caption="Tipo"
          />
          <!-- <DxColumn :width="columnWidth" data-field="ParameterType" caption="Tipo">
                           <DxLookup    :data-source="rowType" display-expr="Name" value-expr="ID" />
                                               </DxColumn> -->

          <template #parametertype-selection="{ data }">
            <span class="scroll-fixing-purchase">
              <select
                class="form-control custom-select"
                v-model="data.data.ParameterType"
              >
                <option v-for="tipo in rowType" :value="tipo.ID" :key="tipo.ID">
                  {{ tipo.Name }}
                </option>
              </select>
            </span>
          </template>
          <!-- <DxColumn cell-template="parameter-reference" caption="Acciones"></DxColumn>  -->
          <!-- <template #parameter-order="{data}" class="container">
                           <input type="text"
                           
                                v-if="data.data.ParameterType== 'V' ||  data.data.ParameterType== 'T' || data.data.ParameterType== 'W' || data.data.ParameterType== 'S'"
                                :value="data.data.ParameterOrder"
                                @change="changeLabData(data.data, $event.target.value, 'ParameterOrder')">
                                                  
                                               </template>
                                               <template #parameter-name="{data}" style="text-align: left;">
                           <input type="text"
                               v-if="data.data.ParameterType== 'V' ||  data.data.ParameterType== 'T' || data.data.ParameterType== 'S' "
                               :value="data.data.ParameterName"
                               @change="changeLabData(data.data, $event.target.value, 'ParameterName')">
                           <template v-else>
                               {{ data.data.ParameterName }}
                           </template>
                                               </template>
                           
                                               <template #parameter-value="{ data }"  >
                           <input
                           v-if="data.data.ParameterType== 'V' "
                           style="text-align: left;"
                           type="text"
                           :value="data.data.ParameterValue"
                           @change="changeLabData(data.data, $event.target.value, 'ParameterValue')">
                           
                           <template  v-else>
                               {{ data.data.ParameterValue }}
                           </template>
                            v-if="data.data.ParameterType== 'V' ||  data.data.ParameterType== 'T' "
                                               </template>
                                               <template #parameter-unit="{data}">
                           <input type="text"
                               v-if="data.data.ParameterType== 'V' "
                               :value="data.data.ParameterUnit"
                               @change="changeLabData(data.data, $event.target.value, 'ParameterUnit')">
                           <template v-else>
                               {{ data.data.ParameterUnit }}
                           </template>
                                               </template>
                           
                                               <template #parameter-expected="{data}">
                           <input type="text"
                               v-if="data.data.ParameterType== 'V'"
                               :value="data.data.ParameterExpected"
                               @change="changeLabData(data.data, $event.target.value, 'ParameterExpected')">
                           <template v-else>
                               {{ data.data.ParameterExpected }}
                           </template>
                                               </template> -->
          <!--  <template #parameter-reference="{data}">
                           <button @click="confirmDelete(data)" >Borrar</button>
                                               </template> -->
          <!--template #print="{data}">
                               <input type="checkbox"
                                      :checked="data.data.PrintRow"
                                      @change="changeLabData(data.data, $event.target.checked, 'PrintRow')">
                           </template-->
        </DxDataGrid>
      </v-col>
    </v-row>

    <!--div class="d-flex width-100 justify-space-between">
                        <div class="d-flex">
                            <v-checkbox
                                    v-model="addTemplateForm.Billed"
                                    label="BILLED"
                            ></v-checkbox>
                            <v-checkbox

                                    v-model="addTemplateForm.Filled"
                                    label="FILLED"
                                    class="mx-3"
                            ></v-checkbox>
                        </div>
                        <div class="d-flex justify-lg-end">
                            <a href="#" class="mx-2" @click.prevent.stop="showModal = true; email = true">
                                <v-icon>mdi-email</v-icon>
                            </a>
                            <a href="#" class="mx-2" @click.prevent.stop="showModal = true">
                                <v-icon color="success">mdi-whatsapp</v-icon>
                            </a>
                            <a href="#" class="mx-2" @click.prevent.stop="showModal = true">
                                <v-icon>mdi-message</v-icon>
                            </a>
                        </div>

                    </div-->

    <v-dialog v-model="searchTemplateDialog" scrollable  max-width="1000">
      <manage-template @templateSelected="templateSelected"></manage-template>
    </v-dialog>
    <!-- Null Filter-->
    <v-dialog v-model="nullTest" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Filtros </v-card-title>

        <v-card-text>
          <form>
            <v-checkbox
              v-model="nullTestData.billed"
              readonly
              label="Facturado"
            ></v-checkbox>
            <v-checkbox
              v-model="nullTestData.Filled"
              readonly
              label="Llenado"
            ></v-checkbox>
          </form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="nullTest = false">
            Aceptar
          </v-btn>

          <v-btn color="error darken-1" text @click="nullTest = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Mail Modal -->
    <v-dialog v-model="showModal" max-width="800">
      <modal>
        <template slot="title">Enviar recordatorio</template>
        <template slot="body">
          <form>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  outlined
                  dense
                  v-model="form.phone"
                  label="Telefono"
                  type="number"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  outlined
                  dense
                  v-if="email"
                  v-model="form.email"
                  label="Correo"
                  type="email"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <date-picker label="Desde"></date-picker>
              </v-col>

              <v-col cols="12" sm="12" md="3">
                <date-picker label="Hasta"></date-picker>
              </v-col>
            </v-row>
          </form>
        </template>
      </modal>
    </v-dialog>

    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    >
    </alerts>

    <print-lab-test :data="print"></print-lab-test>
    <v-dialog v-model="loadingParams" max-width="290">
      <div>
        <transition name="fade">
          <div class="loading-overlay">
            <div class="loading-dialog">
              <div class="loading-spinner"></div>
              <p class="loading-text">{{ messageload }}</p>
              <p class="loading-text">por favor no cierre el programa</p>
            </div>
          </div>
        </transition>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Modal from "../../../components/Modal";
import DatePicker from "../../../components/DatePicker";
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxPaging, // DxLookup,
  DxKeyboardNavigation,

} from /* DxTexts  */ "devextreme-vue/data-grid";
import Vue from "vue";
import ManageTemplate from "./ManageTemplate";
import Alerts from "@/components/Alerts";
import PrintLabTest from "../../../components/ReportsAndPrintings/PrintLabTest";
import GeneralFilter from "../../../components/GeneralFilter.vue";
import UserProfileLab from "@/components/Displays/UserProfileDisplayLab.vue";

export default {
  name: "LaboratoryTest",
  props: ["patient"],
  components: {
    ManageTemplate,
    Modal,
    DatePicker,
    DxColumn,
    DxDataGrid,
    GeneralFilter,
    UserProfileLab,
    DxKeyboardNavigation,
   
    DxEditing,
    DxPaging,
    //   DxLookup,
    /*   DxTexts,  */
    Alerts,
    PrintLabTest,
  },
  data() {
    return {
      print: {
        show: false,
        data: [],
        prevView: false,
      },
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      nullTest: false, //Its use is not known
      nullTestData: {}, //Its use is not known
      showModal: false,
      form: {}, //For extra button

      //variables used in this page
      date: null, // Set date to initialize form
      labs: [], //Get lab test table left
      searchTemplateDialog: false, //Open modal selected template
      pltp: [], //JSON that sets a table dataset patients_laboratory_test_parameters
      addTemplateForm: {}, //JSON that sets id_patient, laboratoryname, laboratorytestdate, iduserinsert, iduserupdate billed and filled
      updateLabForm: {}, //JSON that sets the ParameterValue data to save
      id_user_login: null,
      //End variables used,
      ID_TestParam: null,
      confirmToDeleteparams: null,
      dateShow: null,
      patientData: {},
      email: null,
      //user selected
      userSelected: null,
      //loading
      loadingParams: false,
      messageload: "Cargando datos",
      //use for get all labs
      getAllLabsforPrint: [],
      rowType: [
        {
          ID: "T",
          Name: "Titulo",
        },
        {
          ID: "V",
          Name: "Valor",
        },
        {
          ID: "W",
          Name: "Espacio en blanco",
        },
        {
          ID: "S",
          Name: "Subtitulo",
        },
        {
          ID: "P",
          Name: "Salto de página",
        },
        {
          ID: "F",
          Name: "Firma y Sello",
        },
        {
          ID: "C",
          Name: "Comentario",
        },
      ],
      useData: JSON.parse(localStorage.getItem("user")),
      showLabHistory: false,
    };
  },
  watch: {
    showModal() {
      if (!this.showModal) {
        this.email = false;
      }
    },
    date: {
      handler: function () {
        /*this.addTemplateForm.ID = null
                this.addTemplateForm.LaboratoryName = null
                this.addTemplateForm.ID_LaboratoryTest = null*/
        this.addTemplateForm.LaboratoryTestDate = this.date;
        /*this.addTemplateForm.Billing = 0
                this.addTemplateForm.Filling = 0

                this.pltp = [];
                this.$API.laboratory.labParams({
                    date: this.addTemplateForm.LaboratoryTestDate,
                    patient: this.addTemplateForm.ID_Patient
                }).then(response => {
                    this.pltp = response;
                })
                
                this.$API.laboratory.patientLabTest({patient: this.patient.ID_Patient, 
                    date: this.addTemplateForm.LaboratoryTestDate})
                .then(response => {
                    this.addTemplateForm.ID = response[0].ID
                    this.addTemplateForm.ID_User_Insert = response[0].ID_User_Insert
                    this.addTemplateForm.Billing = response[0].Billing
                    this.addTemplateForm.Filling = response[0].Filling
                })*/
      },
      immediate: true,
    },
    // labs: {
    //     deep: true,
    //     immediate: true,
    //     handler(newVal) {
    //       console.log(newVal);
    //         for (const item of newVal) {
    //           Object.keys(item).forEach(key =>{
    //             if(typeof(item[key]) === 'string') {
    //               const value = item[key].replace(/<[^>]+>/g, ' ');
    //               item[key] = value;
    //             }
    //             if(item[key] == 'undefined') item[key] = '';
    //           })
    //         }
    //         newVal = newVal.sort((a, b) => {
    //             const [aDay, aMonth, aYear] = a.LaboratoryTestDate.split('/');
    //             const aDate = new Date(aYear, aMonth - 1, aDay);
    //             const [bDay, bMonth, bYear] = b.LaboratoryTestDate.split('/');
    //             const bDate = new Date(bYear, bMonth - 1, bDay);
    //             return bDate - aDate;
    //         });
    //         return newVal;
    //     }
    // },
  
  },
  computed: {
    columnWidth() {
      return this.$vuetify.breakpoint.smAndDown ? "150px" : "auto !important";
    },
  },
  mounted() {
    this.useData = JSON.parse(localStorage.getItem("user")) || "";
    this.getAllLabs();
    this.getHeaderFooter();
    this.id_user_login = JSON.parse(localStorage.getItem("user")).id;
    this.addTemplateForm.ID_Patient = this.patient.ID_Patient;
    this.addTemplateForm.ID_Customer = this.patient.ID_Customer;
    this.addTemplateForm.ID_User_Insert = this.id_user_login;
    this.addTemplateForm.Billing = 0;
    this.addTemplateForm.Filling = 0;
    this.patientData = this.patient;
  },
  methods: {
    printHandler() {
      this.print = {
        show: true,
        data: {
          header: this.addTemplateForm,
          body: this.pltp,
          profile: this.patient,
        },
        prevView: false,
      };
      console.log(this.print);
    },
    filterHandler(response) {
      this.labs = response;
    },
    printAll() {
      this.pltp.sort(function (a, b) {
        let aValue = parseInt(a.ParameterOrder);
        let bValue = parseInt(b.ParameterOrder);
        if (aValue !== bValue) {
          return aValue - bValue;
        }
        return 0;
      });

      let labsToprint = [];

      this.labs.forEach((element) => {
        if (element.Checked) {
          labsToprint.push(element);
        }
      });
      if (labsToprint.length == 0) {
        this.loadingParams = false;
        this.showAlert("danger", "Error", "No hay datos para imprimir");
        return;
      } else {
        this.getallSelctedLabs(labsToprint);
      }
    },
    async getallSelctedLabs(labsToprint) {
      let labsData = [];
      try {
        this.loadingParams = true;

        for (const element of labsToprint) {
          const resp = await this.$API.laboratory.labParams({
            idplt: element.ID,
            patient: element.ID_Patient,
          });

          labsData.push({ header: element, params: resp });
        }
        this.getAllLabsforPrint = labsData;

        this.loadingParams = false;
      } catch (error) {
        this.loadingParams = false;
        this.getAllLabsforPrint = false;
        console.log(error);
        this.showAlert("danger", "Error", "Error al obtener los datos");
      } finally {
        let data = JSON.stringify(this.getAllLabsforPrint);
        alert(data);
      }
    },
    handleRowUpdatingMaster(e) {
      let { Order } = e.newData;
      let OldOrder = e.oldData.Order;
      console.log(OldOrder);
      if (
        isNaN(Order) ||
        Order == "" ||
        Order == null ||
        Order == undefined ||
        Order < 0
      ) {
        e.cancel = true;
      }
      if (!e.cancel)
        this.labs.forEach((element) => {
          if (element.Order == Order) {
            element.Order = OldOrder;
          }
        });
    },
    OrderRow() {
      this.labs.sort(function (a, b) {
        let aValue = parseInt(a.Order);
        let bValue = parseInt(b.Order);
        console.log(aValue);
        if (aValue !== bValue) {
          return aValue - bValue;
        }
        return 0;
      });
    },
    handleRowUpdating(e) {
      let { ParameterOrder } = e.newData;

      if (ParameterOrder) {
        if (isNaN(ParameterOrder) || ParameterOrder == "") {
          this.showAlert("danger", "Error", "El Orden debe ser un número");
          return;
        }

        this.pltp.sort(function (a, b) {
          let aValue = parseInt(a.ParameterOrder);
          let bValue = parseInt(b.ParameterOrder);
          if (aValue !== bValue) {
            return aValue - bValue;
          }
          return 0;
        });
      }

      //let hasMatch = false;
      //let {ParameterExpected,ParameterName,ParameterOrder,ParameterType,ParameterUnit,ParameterValue} = e.newData;
      /* if(ParameterOrder){
        this.details.forEach(element => {
          if(element.ParameterOrder == ParameterOrder){
          element.ParameterOrder = e.oldData.ParameterOrder;
          this.showAlert("danger", "Error", "El orden ya existe");
          hasMatch = true;
          }
        });
        
      } */

      //   if(!hasMatch){
      //   ParameterOrder ? ParameterOrder: ParameterOrder = e.oldData.ParameterOrder;
      //   //transfer old data to new data if new data is empty or undefined
      //   ParameterExpected ? ParameterExpected: ParameterExpected = e.oldData.ParameterExpected;
      //   ParameterName ? ParameterName: ParameterName = e.oldData.ParameterName;
      //   ParameterType ? ParameterType: ParameterType = e.oldData.ParameterType;
      //   ParameterUnit ? ParameterUnit: ParameterUnit = e.oldData.ParameterUnit;
      //   ParameterValue ? ParameterValue: ParameterValue = e.oldData.ParameterValue;

      //this.newTable.push({ data: {ParameterExpected,ParameterName,ParameterOrder,ParameterType,ParameterUnit,ParameterValue,id_user_update: this.id_user_login, id: e.key} , methods: "update"})
      // }
    },
    handleRowDeleting() {
      // if i need get alert before delete
    },
    onEditingStart(e) {
      if (e.column.dataField == "ParameterName") {
        if (e.data.ParameterType == "W") e.cancel = true;
        if (e.data.ParameterType == "P") e.cancel = true;
        if (e.data.ParameterType == "F") e.cancel = true;
      } else if (e.column.dataField == "ParameterUnit") {
        if (
          e.data.ParameterType != "V" ||
          e.data.ParameterType == undefined ||
          e.data.ParameterType == null
        )
          e.cancel = true;
      } else if (e.column.dataField == "ParameterExpected") {
        if (
          e.data.ParameterType != "V" ||
          e.data.ParameterType == undefined ||
          e.data.ParameterType == null
        )
          e.cancel = true;
      } else if (e.column.dataField == "ParameterValue") {
        if (
          e.data.ParameterType != "V" ||
          e.data.ParameterType == undefined ||
          e.data.ParameterType == null
        )
          e.cancel = true;
      }
      //to cancel editing  e.cancel = true;
      //to check column name e.column.dataField
    },
    showDatewithformat(date) {
      console.log(this.moment(date, "DD/MM/YYYY").format("dddd, MMMM Do YYYY"));
      return this.moment(date, "DD/MM/YYYY").format("dddd, MMMM Do YYYY");
    },
    confirmDelete(data) {
      this.showAlert(
        "question",
        "¿Está seguro que desea eliminar el registro? ",
        "Esta acción no se puede deshacer",
        "deleteParamoflabTest"
      );
      this.confirmToDeleteparams = data.rowIndex;
      console.log(this.confirmToDeleteparams);
    },

    addDetails() {
      let newExam = {
        ID_LaboratoryTest: this.addTemplateForm.ID_LaboratoryTest,
        ID_Patient: undefined,
        ID_TestParam: this.ID_TestParam,
        ParameterExpected: "",
        ParameterValue: "",
        ParameterOrder: this.getOrdernumber(),
        ParameterType: "W",
        ParameterName: "",
        ParameterUnit: "",
      };
      this.pltp.push(newExam);
    },
    getOrdernumber() {
      let order = 0;
      this.pltp.forEach((element) => {
        if (element.ParameterOrder > order) {
          order = element.ParameterOrder;
        }
      });
      return parseInt(order) + 1;
    },
    savedparams() {
      this.pltp.sort(function (a, b) {
        let aValue = parseInt(a.ParameterOrder);
        let bValue = parseInt(b.ParameterOrder);
        if (aValue !== bValue) {
          return aValue - bValue;
        }
        return 0;
      });
    },
    onKeyDown(e) {
      if (e.event.key == "Tab") {
        e.event.preventDefault();
        var event = new KeyboardEvent("keydown", {
          bubbles: true,
          cancelable: true,
          keyCode: 13,
        });
        e.event.target.dispatchEvent(event);
        /* var clickEvent = new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window
                });
                e.event.target.dispatchEvent(clickEvent); */
      }
    },

    closeAlert() {
      this.alert.show = false;
      if (this.alert.options == "deleteParamoflabTest") {
        this.confirmToDeleteparams = null;
      }
    },
    acceptAlert() {
      this.alert.show = false;
      if (this.alert.options == "deleteParamoflabTest") {
        this.pltp.splice(this.confirmToDeleteparams, 1);
        this.confirmToDeleteparams = null;
      }
      if (this.alert.options == "DeleteElement") {
        this.deleteLab(this.userSelected);
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    clearData() {
      this.addTemplateForm.ID = null;
      this.addTemplateForm.LaboratoryName = null;
      this.addTemplateForm.ID_LaboratoryTest = null;
      this.addTemplateForm.LaboratoryTestDate = this.date;
      this.addTemplateForm.Billing = 0;
      this.addTemplateForm.Filling = 0;
      this.pltp = [];
      this.userSelected = null;
    },
    getAllLabs() {
      this.$API.laboratory
        .patientLabTests(this.patient.ID_Patient)
        .then((response) => {
          this.labs = response;
          let counter = 1;
          this.labs.forEach((element) => {
            element.Order = counter++;
            element.Checked = true;
          });
        });
    },
    confirmCleanLab() {
      this.clearData();
    },
    selectedLab(data) {
      this.userSelected = data;

      this.addTemplateForm.ID = data.ID;
      this.addTemplateForm.LaboratoryTestDate = data.LaboratoryTestDate;
      this.date = data.LaboratoryTestDate;
      this.addTemplateForm.Billing = data.Billing;
      this.addTemplateForm.Filling = data.Filling;
      this.addTemplateForm.LaboratoryName = data.LaboratoryName;
      this.addTemplateForm.ID_LaboratoryTest = data.ID_LaboratoryTest;
      this.addTemplateForm.ID_Patient = this.patient.ID_Patient;
      this.pltp = [];
      console.log(data.ID_LaboratoryTest);
      this.$API.laboratory
        .labParams({
          idplt: data.ID,
          patient: data.ID_Patient,
        })
        .then((response) => {
          console.log(response);
          this.pltp = response;
          if (this.pltp.length == 0 && data.ID_LaboratoryTest != "") {
            this.$API.laboratory
              .showLabDetails(data.ID_LaboratoryTest)
              .then((response) => {
                this.pltp = response;
                this.pltp.sort(function (a, b) {
                  let aValue = parseInt(a.ParameterOrder);
                  let bValue = parseInt(b.ParameterOrder);
                  if (aValue !== bValue) {
                    return aValue - bValue;
                  }
                  return 0;
                });
              });
          }
        });
    },
    changeLabData(data, value, key) {
      if (key == "ParameterOrder") {
        /* 
                console.log(data.ParameterOrder);
                
                console.log(value);
                */
        // check if the value is a number

        if (isNaN(value) || value == "") {
          this.showAlert("danger", "Error", "El Orden debe ser un número");
          return;
        }

        let validator = false;
        this.pltp.forEach((element) => {
          if (element.ParameterOrder == value) {
            this.showAlert("danger", "Error", "El Orden asignado ya existe");
            validator = true;
          }
        });
        validator ? (value = data.ParameterOrder) : value;

        console.log("ordenar array  ");
      }
      if (!this.updateLabForm[data.ID_PTP]) {
        Vue.set(this.updateLabForm, data.ID_PTP, {});
      }
      this.updateLabForm[data.ID_PTP][key] = value;
      this.updateLabForm[data.ID_PTP]["ID_User_Update"] = this.id_user_login;
      data[key] = value;
      data["ID_User_Update"] = this.id_user_login;
      //reorder array
      this.pltp.sort(function (a, b) {
        let aValue = parseInt(a.ParameterOrder);
        let bValue = parseInt(b.ParameterOrder);
        if (aValue !== bValue) {
          return aValue - bValue;
        }
        return 0;
      });
    },
    updateParam() {
      this.id_user_login = JSON.parse(localStorage.getItem("user")).id;

      this.addTemplateForm.ID_User_Update = this.id_user_login;
      for (let i = 0; i < this.pltp.length; i++) {
        if (typeof this.pltp[i].ID_Patient === "undefined") {
          this.pltp[i].ID_Patient = this.patient.ID_Patient;
          this.pltp[i].ID_LaboratoryTest =
            this.addTemplateForm.ID_LaboratoryTest;
          this.pltp[i].LaboratoryTestDate = this.date;
          this.pltp[i].ID_User_Insert = this.id_user_login;
          this.pltp[i].ID_User_Update = this.id_user_login;
        }
      }
      if (
        this.addTemplateForm.ID_Patient == undefined ||
        this.addTemplateForm.ID_Customer == undefined ||
        this.addTemplateForm.ID_User_Insert == undefined
      ) {
        this.addTemplateForm.ID_Patient = this.patient.ID_Patient;
        this.addTemplateForm.ID_Customer = this.patient.ID_Customer || "";
        this.addTemplateForm.ID_User_Insert = this.id_user_login;
        //console.log("pase x aqui");
      }
      if (this.addTemplateForm.Billing == undefined)
        this.addTemplateForm.Billing = 0;
      if (this.addTemplateForm.Filling == undefined)
        this.addTemplateForm.Filling = 0;

      if (this.addTemplateForm.ID != null) {
        this.addTemplateForm.Filling = 1;
      }

      this.$API.laboratory
        .saveLaboratoryTest({
          labTest: this.addTemplateForm,
          params: this.pltp,
        })
        .then(() => {
          this.showAlert("success", "Exito", "Datos guardados correctamente");
          this.getAllLabs();
        })
        .catch(() => {
          this.showAlert(
            "danger",
            "Error",
            "Ha ocurrido un error al guardar los datos"
          );
        });
    },
    deleteLab(data) {
      this.$API.laboratory
        .deleteLab({ id: data.ID, patient: data.ID_Patient })
        .then(() => {
          this.showAlert("success", "Exito", "Datos eliminados correctamente");
          this.addTemplateForm.ID = null;
          this.addTemplateForm.LaboratoryName = null;
          this.addTemplateForm.Billing = 0;
          this.addTemplateForm.Filling = 0;
          this.pltp = [];
          this.getAllLabs();
          this.clearData();
        })
        .catch(() => {
          this.showAlert(
            "danger",
            "Error",
            "Ha ocurrido un error al eliminar los datos"
          );
        });
    },
    templateSelected(payload, laboratoryName, ID_LaboratoryTest, data) {
      this.addTemplateForm.data = data;
      this.searchTemplateDialog = false;
      this.addTemplateForm.LaboratoryName = laboratoryName;
      this.addTemplateForm.ID_LaboratoryTest = ID_LaboratoryTest;
      this.ID_TestParam = payload[0].ID_TestParam;
      for (let i = 0; i < payload.length; i++) {
        this.pltp.push(payload[i]);
      }

      this.pltp.sort(function (a, b) {
        let aValue = parseInt(a.ParameterOrder);
        let bValue = parseInt(b.ParameterOrder);
        if (aValue !== bValue) {
          return aValue - bValue;
        }
        return 0;
      });
    },

    saveTemplate() {
      let data = {
        ...this.addTemplateForm,
        ID_Customer: this.patient.ID_Customer || "",
        ID_Patient: this.patient.ID_Patient,
        ID_User_Insert: this.id_user_login || "",
      };
      let params = [];
      if (typeof this.pltp === "object") params.push(...this.pltp);
      else {
        params.push(...this.pltp);
      }

      this.$API.laboratory
        .NewLabTest({ header: data, params })
        .then((res) => {
          console.log(res);
          this.showAlert("success", "Exito", "Datos guardados correctamente");
          this.getAllLabs();
        })
        .catch((err) => {
          console.log(err);
          this.showAlert(
            "danger",
            "Error",
            "Ha ocurrido un error al guardar los datos"
          );
        });
    },
  },
};
</script>

<style scoped>
/* Deshabilitar el sombreado redondo en v-checkbox */
.v-input--selection-controls__ripple {
  background-color: transparent !important;
}

.v-input--selection-controls__ripple::after {
  content: none !important;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-dialog {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border-top: 3px solid #3490dc;
  border-right: 3px solid #3490dc;
  border-bottom: 3px solid #3490dc;
  border-left: 3px solid #eee;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-text {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #3490dc;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.infobox {
  font-size: 16px;
  display: flex;
  justify-content: space-around;
  background-color: white;
  color: black;
  border-color: black;
  border-width: 1px;
  border-radius: 15px;
  border-style: solid;
}

.boxdata {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;
  border-radius: 15px;

  display: grid;
  grid-template-rows: (2, 1fr);
}

.tabs {
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white;
}
.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;

  border-radius: 15px;
}
.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.minibox {
  display: flex;
  margin-top: 1.5rem;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.left-aligned {
  float: left;
}

.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}

.tabs {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}
.btnblue {
  background-color: #1976d2 !important;
  color: white;
}

.tabsB {
  width: 100%;
  padding: 10px;
  background-color: #1976d2;
  color: white;
  border-radius: 15px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
}
.tabsB:hover {
  cursor: pointer;
}

.microbox {
  display: flex;
  justify-content: center;
}

.transparentbackground {
  background-color: transparent !important;
  padding: 16px;
}

@media screen and (max-device-width: 1620px), screen and (max-width: 1620px) {
  .minibox {
    display: grid;
    grid-template-rows: (2, 1fr);
  }
}
.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}
.btn-add {
  background-color: #00a178 !important;
  color: white;
}
.btnsave {
  background-color: #3498db !important;
  color: white;
}
.btnclean {
  background-color: #f29d35 !important;
  color: white;
}
</style>
