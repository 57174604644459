const patientData = {
    namespaced: true,
    state: () => ({
        currentView: 'tab-1',
        data: 'none',
        reloaders: {
            reloadManagmentPatient: 0,
            reloadLaboratoryTest: 0,
            MedicalH: 0,
            Medications: 0,
            Surgery: 0,
            Images: 0,
            Prescription: 0,
        }
    }),
    mutations: {
        changeView(state, view){
            state.currentView = view
        },
        setData(state, data){
            state.data = data
        },
        setReloaders(state, payload){
            switch(payload){
                case 'reloadManagmentPatient':
                    state.reloaders.reloadManagmentPatient++;
                    break;
                case 'reloadLaboratoryTest':
                    state.reloaders.reloadLaboratoryTest++;
                    break;
                case 'MedicalH':
                    state.reloaders.MedicalH++;
                    break;
                case 'Medications':
                    state.reloaders.Medications++;
                    break;
                case 'Surgery':
                    state.reloaders.Surgery++;
                    break;
                case 'Images':
                    state.reloaders.Images++;
                    break;
                case 'Prescription':
                    state.reloaders.Prescription++;
                    break;
                
            }
        }
    }
}

export default patientData