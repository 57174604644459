<template>
  <v-dialog
    v-bind="{ ...$attrs, ...$props, scrollable: true }"
    v-on="$listeners"
  >
    <block v-if="loading"></block>
    <v-card>
      <v-card-title>
        Configurar código de cuentas
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2"
          color="primary"
          dark
          @click="addLevel"
          :disabled="creating"
          small
        >
          <v-icon>mdi-plus</v-icon>
          Nuevo
        </v-btn>
        <v-btn
          class="mr-2"
          color="save"
          dark
          @click="saveAccountsLevels"
          :disabled="!creating"
          small
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
        <v-btn color="error" @click="$emit('input', false)" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="levels"
          :items-per-page="-1"
          hide-default-footer
          dense
        >
          <template v-slot:item.digits="{ item }">
            <v-form ref="form">
              <span v-if="!item.new">{{ item.digits }}</span>
              <v-text-field
                v-else
                v-model="item.digits"
                type="number"
                :min="item.minDigits"
                :rules="[
                  ...rulesDigits,
                  (v) =>
                    (!!v && v >= item.minDigits) ||
                    `Los dígitos deben ser mayores a ${item.minDigits}`,
                ]"
                step="1"
                reverse
                dense
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-form>
          </template>
          <template v-slot:item.options="{ item }">
            <v-btn
              v-if="item === levels[levels.length - 1]"
              @click="confirmDelete(item)"
              color="error"
              icon
            >
              <v-icon color="error">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- alert -->
    <alert
      v-model="alert.show"
      @action="deleteLevel"
      :type="alert.type"
      :title="alert.title"
      >{{ alert.message }}</alert
    >
  </v-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "ConfigAccountCodes",
  data: () => ({
    alert: {
      show: false,
      message: "",
      type: "",
      title: "",
    },
    loading: false,
    selectedItem: {},
    headers: [
      {
        text: "Nivel",
        align: "start",
        sortable: false,
        value: "level_description",
      },
      {
        text: "Dígitos del código",
        align: "end",
        sortable: false,
        value: "digits",
        width: 200,
      },
      {
        text: "",
        align: "center",
        sortable: false,
        value: "options",
        width: 50,
      },
    ],
    levels: [],
    rulesDigits: [
      (v) => !!v || "Debe ingresar un número entero",
      (v) =>
        (!!v && !/[^0-9]/.test(`${v}`)) ||
        "El código debe ser un número entero",
    ],
  }),
  mounted() {
    this.loadData();
  },
  computed: {
    creating() {
      return !!this.levels.length && this.levels[this.levels.length - 1].new;
    },
  },
  methods: {
    showConfirm(title, message) {
      this.showAlert(title, message, "confirm-danger");
    },
    showAlert(title, message, type = "info") {
      this.alert.type = type;
      this.alert.message = message;
      this.alert.title = title;
      this.alert.show = true;
    },
    confirmDelete(item) {
      this.selectedItem = item;
      this.showConfirm(
        "Borrar nivel",
        `¿Está seguro de eliminar el registro: ${this.selectedItem.level_description}?`
      );
    },
    async deleteLevel() {
      if (this.selectedItem.new) {
        this.levels.pop();
        this.alert.show = false;
      } else {
        try {
          this.loading = true;
          this.alert.show = false;
          const response = await this.$API.accountlevel.deleteAccountLevels(
            this.selectedItem.id_level
          );
          if (!response.success) {
            throw new Error(response.message);
          }
          const level_description = this.selectedItem.level_description;
          this.showAlert(
            "Información",
            `Se ha eliminado correctamente el nivel ${level_description}`
          );
          this.selectedItem = {};
        } catch (err) {
          this.showAlert("Advertencia", err.message, "warning");
        } finally {
          this.loadData();
          this.loading = false;
        }
      }
    },
    addLevel() {
      let data = {};
      if (this.levels.length) {
        const last = this.levels[this.levels.length - 1];
        const digits = last.order <= 2 ? 1 : last.digits + 2;
        data = {
          digits: digits,
          level_description: `Nivel ${last.order + 1}`,
          order: last.order + 1,
          minDigits: digits,
          new: true,
        };
      } else {
        data = {
          digits: 1,
          level_description: `Nivel 1`,
          order: 1,
          minDigits: 1,
          new: true,
        };
      }
      this.levels.push(data);
    },
    async saveAccountsLevels() {
      try {
        this.alert.show = false;
        this.loading = true;
        const { digits, level_description, order } =
          this.levels[this.levels.length - 1];
        const response = await this.$API.accountlevel.createAccountLevels({
          digits,
          level_description,
          order,
        });
        if (!response.success) {
          throw new Error(response.message);
        }
        this.showAlert(
          "Información",
          `Se ha guardado correctamente el ${level_description}`
        );
        this.selectedItem = {};
      } catch (err) {
        this.showAlert("Error", err.message, "error");
      } finally {
        await this.loadData();
        this.loading = false;
      }
    },
    async loadData() {
      try {
        this.loading = true;
        const { data } = await this.$API.accountlevel.getAccountLevels();
        this.levels = data;
      } catch (err) {
        this.alert = {
          type: "error",
          title: "Error",
          message: "Ocurrió un error al consultar los datos",
        };
        this.alert.show = true;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    "$attrs.value"(val) {
      if (val) {
        this.loadData();
      }
    },
  },
};
</script>
