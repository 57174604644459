<template>
  <div>
    <v-card flat>
      <v-card-text>
        <user-profile :patientinfo="patientinfo" :data="data" :useData="useData" />
                   
                   
                                

                 
        <v-row style="margin-top: 1rem;">



      <v-col cols="12" class="box"  style="margin-top: 1rem;">
   
  <v-form v-model="valid" >
          <block v-if="loading"></block>
         
              <v-row class="ml-2 mr-2"  style="margin-top: .3rem;">
                <template v-if="data">
                  <v-col cols="12" class="tabsNh">
                   
                      <v-col cols="12"  v-if="addTemplateForm.ID"  style="display: flex; justify-content: space-between;">
                      <div style="width: 212px;">
                        <v-btn 
                          small 
                          color="error"
                          v-if="$store.getters['rolesUser/getCustomRole'](35)" 
                          @click.prevent='showAlert("question","Confirmar","Esta seguro de eliminar este registro","confirmDelete")'>
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                      <div>
                          <v-btn v-if="$store.getters['rolesUser/getCustomRole'](107)" small class="btn-add mr-1" @click.prevent="getItemsToBeBilled(); showBillPatient = true">
                              <v-icon class="mr-3">mdi-account-details</v-icon> Facturar paciente
                          </v-btn>
                      </div>
                      <div>
                        <v-btn small @click="showPrint()"
                          style="margin-right: .3rem;"
                          v-if="$store.getters['rolesUser/getCustomRole'](109)" 
                            >
                                      <v-icon> mdi-printer </v-icon>
                            </v-btn>
                            <v-btn small class="btnblue mr-1" @click="showhistory = !showhistory" >
                                                    <v-icon>
                                                        mdi-magnify
                                                    </v-icon>
                                                </v-btn>

                            <v-btn small class="btnclean" @click.prevent.stop="cleanForm()"
                          style="margin-right: .3rem;"
                          >
                            <v-icon>
                              mdi-broom
                            </v-icon>
            
                          </v-btn>
                        
                        <v-btn small class="btnsave" 
                        v-if="$store.getters['rolesUser/getCustomRole'](33)" 
                        @click.prevent="updateParam()">
                           <v-icon>
                            mdi-content-save
                           </v-icon>
                          </v-btn>
                      </div>
                    
                    </v-col>
                  
                   
                  <v-col cols="12" v-else style="display: flex; justify-content: space-between; ">
                       
                    <!-- <v-btn small @click="showPrint()"
                      style="margin-right: .3rem;"
                      v-if="$store.getters['rolesUser/getCustomRole'](131)" 
                    >
                      <v-icon> mdi-printer </v-icon>
                    </v-btn> -->
                    <div style="width: 212px;" ></div>
                    <div>
                        <v-btn v-if="$store.getters['rolesUser/getCustomRole'](107)" small class="btn-add mr-1" @click.prevent="getItemsToBeBilled(); showBillPatient = true">
                            <v-icon class="mr-3">mdi-account-details</v-icon> Facturar paciente
                        </v-btn>
                    </div>
                    <div>
                      <v-btn small @click="showPrint()"
                        style="margin-right: .3rem;"
                      >
                        <v-icon> mdi-printer </v-icon>
                      </v-btn>
                      <!-- <v-btn 
                        v-if="$store.getters['rolesUser/getCustomRole'](131)" 
                        small class="btnblue mr-1" @click="showhistory = !showhistory" >
                        <v-icon>
                            mdi-magnify
                        </v-icon>
                      </v-btn> -->
                      <v-btn 
                        small class="btnblue mr-1" @click="showhistory = !showhistory" >
                        <v-icon>
                            mdi-magnify
                        </v-icon>
                      </v-btn>
  
                      <v-btn small class="btnclean" @click.prevent.stop="cleanForm()"
                        style="margin-right: .3rem;"
                      >
                        <v-icon>
                          mdi-broom
                        </v-icon>
        
                      </v-btn>
                      <v-btn small 
                        v-if="$store.getters['rolesUser/getCustomRole'](33)" 
                        class="btnsave" @click.prevent="addLab()"
                      >
                        <v-icon>
                          mdi-content-save
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                    
                </v-col>

                  <v-col cols="12" md="3" sm="12" style="margin-top: 1rem;">
                  <div style="width: 265px !important; display: flex;">
                        <date-picker
                          label="Fecha"
                          @dateSelected="
                            (param) => {addTemplateForm.laboratorytestdate = param; date = param;}
                          "
                          v-model="date"
                        ></date-picker>
                     
                          
                       
                      </div>
                    </v-col>
                      <v-col cols="12" md="8" sm="0" >
                      </v-col>
                      <v-col cols="12" md="1" style="display: flex;justify-content: flex-end;margin-top: .5rem">
                    <v-btn
                      @click="showComments = !showComments"
                      :class="addTemplateForm.Comment ? 'btnclean' : 'btnblack'"
                    >
                      Comentarios
                    </v-btn>
                  </v-col>
                        
                      <v-col cols="12" md="4">

                        <v-text-field
                          disabled
                          v-model="addTemplateForm.LaboratoryName"
                          label="Nombre plantilla"

                        >
                        
                      </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-btn v-if="addTemplateForm.LaboratoryName == null" class="btn-add" @click="searchTemplateDialog = true">
                          Buscar plantilla
                        </v-btn>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-checkbox @change="setOtherLaboratory" v-model="addTemplateForm.OtherLaboratory" label="Otro laboratorio" />
                      </v-col>
                </template>
              </v-row>

              <br />

           
              
              <v-row v-if="!addTemplateForm.OtherLaboratory"> 
                <v-col cols="12" md="12" sm="12" style="display: flex; justify-content: flex-end;" >
                        
                        <v-btn
                          class="btn-add"
                          small
                          @click="addDetails"
                          v-if="pltp.length > 0"

                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col></v-row>
                <div v-if="!addTemplateForm.OtherLaboratory" style="margin-top: 15px;">
                  
                  <DxDataGrid 
                    :scrolling="{useNative:true}"
                      :allow-updating="true"
                      :allow-deleting="true"
                      :confirm-delete="false"
                      @editing-start="onEditingStart"
                      :onRowUpdating="handleRowUpdating"
                      :onRowRemoving="handleRowDeleting"
                      :onSaved="savedparams"
                      :data-source="pltp"
                      ref="dataDetails"
                      sortBy="parameter-order"
                      :allow-column-reordering="true"
                      :show-borders="true"
                      @key-down="onKeyDown"
                      width="100%"
                    >
                      <DxPaging :enabled="false" />
                      <!-- <DxEditing
                      >
                       :allow-adding="addTemplateForm.LaboratoryName != null ? true : false"
                          <DxTexts saveRowChanges="Guardar" editRow="Editar" deleteRow="Eliminar"
                              cancelRowChanges="Cancelar" confirmDeleteMessage="¿Esta seguro de eliminar este registro?">
                          </DxTexts>
                      </DxEditing> -->
                      <DxEditing
                        :allow-updating="true"
                        :allow-deleting="true"
                        :confirmDelete="false"
                        :select-text-on-edit-start="true"
                        start-edit-action="click"
                        mode="cell"
                        :use-icons="true"
                      >
                      </DxEditing>
                      <DxKeyboardNavigation
                        :edit-on-key-press="true"
                        enter-key-action="moveFocus"
                        enter-key-direction="column"
                      />
                      <DxButton name="delete" />
                      <DxColumn
                      :width="columnWidth"
                        data-field="ParameterOrder"
                        caption="Orden"
                      ></DxColumn>
                      <DxColumn
                      :width="columnWidth"
                        data-field="ParameterName"
                        caption="Nombre"
                      ></DxColumn>
                      <DxColumn
                      :width="columnWidth"
                        data-field="ParameterValue"
                        caption="Valor"
                      ></DxColumn>
                      <DxColumn
                      :width="columnWidth"
                        data-field="ParameterUnit"
                        caption="Unidad"
                      ></DxColumn>
                      <DxColumn
                      :width="columnWidth"
                        data-field="ParameterExpected"
                        caption="Valor esperado"
                      ></DxColumn>
                      <DxColumn
                      :width="columnWidth"
                        cell-template="parametertype-selection"
                        caption="Tipo" 
                      ></DxColumn>
                      <!-- <DxColumn cell-template="parametertype-selection" caption="Tipo">
                        <DxLookup
                          :data-source="rowType"
                          display-expr="Name"
                          value-expr="ID"
                        />
                      </DxColumn> -->
                      <template #parametertype-selection="{ data }">
                        <span class="scroll-fixing-purchase">
                          <select class="form-control custom-select " v-model="data.data.ParameterType">
                            <option v-for="tipo in rowType" :value="tipo.ID" 
                            :key="tipo.ID"
                            >
                              {{ tipo.Name }}
                            </option>
                          </select>
                        </span>
                      </template>
                      <!-- <DxColumn cell-template="parameter-reference" caption="Acciones"></DxColumn>  -->
                      <!-- <template #parameter-order="{data}" class="container">
                          <input type="text"
                               v-if="data.data.ParameterType== 'V' ||  data.data.ParameterType== 'T' || data.data.ParameterType== 'W' || data.data.ParameterType== 'S'"
                               :value="data.data.ParameterOrder"
                               @change="changeLabData(data.data, $event.target.value, 'ParameterOrder')">
                      </template>
                      <template #parameter-name="{data}" style="text-align: left;">
                          <input type="text"
                              v-if="data.data.ParameterType== 'V' ||  data.data.ParameterType== 'T' || data.data.ParameterType== 'S' "
                              :value="data.data.ParameterName"
                              @change="changeLabData(data.data, $event.target.value, 'ParameterName')">
                          <template v-else>
                              {{ data.data.ParameterName }}
                          </template>
                      </template>
                      <template #parameter-value="{ data }"  >
                          <input
                          v-if="data.data.ParameterType== 'V' "
                          style="text-align: left;"
                          type="text"
                          :value="data.data.ParameterValue"
                          @change="changeLabData(data.data, $event.target.value, 'ParameterValue')">
                          <template  v-else>
                              {{ data.data.ParameterValue }}
                          </template>
                           v-if="data.data.ParameterType== 'V' ||  data.data.ParameterType== 'T' "
                      </template>
                      <template #parameter-unit="{data}">
                          <input type="text"
                              v-if="data.data.ParameterType== 'V' "
                              :value="data.data.ParameterUnit"
                              @change="changeLabData(data.data, $event.target.value, 'ParameterUnit')">
                          <template v-else>
                              {{ data.data.ParameterUnit }}
                          </template>
                      </template>
                      <template #parameter-expected="{data}">
                          <input type="text"
                              v-if="data.data.ParameterType== 'V'"
                              :value="data.data.ParameterExpected"
                              @change="changeLabData(data.data, $event.target.value, 'ParameterExpected')">
                          <template v-else>
                              {{ data.data.ParameterExpected }}
                          </template>
                      </template> -->
                      <!--  <template #parameter-reference="{data}">
                          <button @click="confirmDelete(data)" >Borrar</button>
                      </template> -->
                      <!--template #print="{data}">
                              <input type="checkbox"
                                     :checked="data.data.PrintRow"
                                     @change="changeLabData(data.data, $event.target.checked, 'PrintRow')">
                          </template-->
                    </DxDataGrid>
                </div>
                    

              <v-row v-if="addTemplateForm.OtherLaboratory">
                <v-col cols="12" md="6">
                   <v-text-field dense outlined label="Examen" v-model="addTemplateForm.HeaderOtherLaboratory"></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea dense outlined label="Descripción" v-model="addTemplateForm.ValueOtherLaboratory"></v-textarea>
                </v-col>
              </v-row>
              <v-col cols="12" style="display: flex; justify-content: flex-end; margin-top: 1rem;margin-bottom: 1rem;">
              </v-col>
        </v-form>


      </v-col>
    </v-row>
    <v-dialog
      v-model="showComments"
      :max-width="$vuetify.breakpoint.smAndDown ? '90%' : '60%'"
      scrollable
      >
      <v-card class="pa-4">
        <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
            <v-btn small class="btndelete" @click.prevent="showComments = false" >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        <v-col cols="12" md="12" style="">
                          <quill-editor v-model="addTemplateForm.Comment" ></quill-editor>
                        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showhistory"
      :width="'90%'"
      scrollable
      >
      <v-card class="pa-4">
        <v-card-text>
          <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
              <v-btn small class="btndelete" @click.prevent="showhistory = false" >
                <v-icon>mdi-close</v-icon>
              </v-btn>
          </div>
            <GeneralFilter  class="background border-box" entity="cliente" :filterEndpoint="$API.laboratory.filtervetpatientLabTests" :search="search" @emptyFilter="getAlllabs" @filtered="filterHandler">


              <DxDataGrid 
                class="isScrolledByGeneralFilter"
                :show-borders="true"
                :data-source="laboratoryparameters"
                key-expr="ID"
                @exporting="onExporting"
                :column-auto-width="true" 
                style="width: fit-content;"
              >
                <DxExport :enabled="true" />
                <DxColumn
                  :width="80"
                  cell-template="show-template"
                  caption=""
                ></DxColumn>
                <DxColumn data-field="LaboratoryTestDate" caption="Fecha" :min-width="100"></DxColumn>
                <DxColumn data-field="ID" caption="ID" :min-width="100"></DxColumn>
                <DxColumn data-field="LaboratoryName" caption="Nombre" :min-width="200" cssClass="left-align"></DxColumn>
                <DxColumn
                v-if="$store.getters['rolesUser/getCustomRole'](35)" 
                  :width="80"
                  cell-template="delete-template"
                  caption=""
                ></DxColumn>

                <template #show-template="{ data }">
                  <div>
                    <a href="#" @click.prevent.stop="selectedLab(data.data);showhistory= false">
                      <v-icon color="primary">mdi-eye</v-icon>
                    </a>
                    
                  </div>
                </template>
                <template #delete-template="{ data }">
                  <div>
                    
                    <a
                      href=""
                      class="mx-2"
                      @click.prevent.stop="showAlert('question','Confirmar','Esta seguro de eliminar este registro','confirmDelete');dataTodelete= data.data;showhistory= false"
                    >
                      <v-icon color="error">mdi-delete</v-icon>
                    </a>
                  </div>
                </template>

              </DxDataGrid>
            </GeneralFilter>
        </v-card-text>
        </v-card>
      </v-dialog>

    <!-- Dialog bill patient -->
    <v-dialog v-model="showBillPatient" width="90%">
          <v-card class="py-3 px-3">
              <v-card-title class="text-h5">
              Facturar paciente 
              </v-card-title>

              <v-card-actions>
              <v-row>
                  <v-col cols="12" class="tabsNh" style="padding: 14px">
                  <v-col style="display: flex; justify-content: space-between; min-height: 50px">
                      <div>
                      <!-- <v-btn v-if="!showServicesToAdd" small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                          <v-icon>mdi-menu-down</v-icon>
                          Ver items
                      </v-btn>
                      <v-btn v-else small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                          <v-icon>mdi-menu-up</v-icon>
                          Ocultar
                      </v-btn> -->
                      </div>
                      <div>
                      <v-btn small class="btn-add" @click.prevent="saveItemsToBeBilled()">
                          <v-icon>mdi-content-save</v-icon>
                          Guardar
                      </v-btn>
                      </div>

                      <div>
                      <v-btn small class="btnsave" @click.prevent="billedPatientsOfCustomer()">
                          <v-icon>mdi-cash-register</v-icon>
                          Facturar cliente
                      </v-btn>
                      </div>
                  </v-col>
                  </v-col>
              </v-row>
              </v-card-actions>
              <div style="height: 500px; overflow-y: auto; overflow-x: hidden; margin-top: 20px; margin-bottom: 20px; padding: 0 20px;">
              <!-- Services default -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver servicios por defecto
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide1 = !togglehide1' v-show='!togglehide1' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide1 = !togglehide1' v-show='togglehide1' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide1">
                  <DxDataGrid class="isScrolledByGeneralFilter dataGridService" :data-source="servicesDefault" 
                  :show-borders="true" :column-auto-width="true" style="width: fit-content;">
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="CodeService" header-cell-template="headerCodeService" />
                      <template #headerCodeService="{ }">
                      <div>Código de<br />servicio</div>
                      </template>
                      <DxColumn data-field="NameService" header-cell-template="headerNameService" />
                      <template #headerNameService="{ }">
                      <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" header-cell-template="headerName" />
                      <template #headerName="{ }">
                      <div>Nombre a<br />facturar</div>
                      </template>
                      <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice" />
                      <template #headerTypeservice="{ }">
                      <div>Tipo de<br />servicio</div>
                      </template-->
                      <DxColumn :width="65" data-field="Price" caption="Precio" />
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""/>
                      <template #show-template="{ data }">
                      <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                          <v-icon>mdi-plus</v-icon>
                      </a>
                      </template>
                      <template #quantity-template="{ data }">
                      <p>{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService).quantity }}</p>
                      </template>
                  </DxDataGrid>
                  </v-col>
              </v-row>

              <!-- Services to select -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver todos los servicios
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide2 = !togglehide2' v-show='!togglehide2' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide2 = !togglehide2' v-show='togglehide2' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide2">
                  <GeneralFilter 
                      :filterEndpoint="$API.services.getAllServicesFiltered" 
                      :search="searchToBill"
                      @emptyFilter="getServices" 
                      @filtered="servicesFilterHandler"
                  >
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="servicesToBill"
                      key-expr="CodeService" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="CodeService" caption="ID"></DxColumn>
                      <DxColumn data-field="NameService" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice">
                      </DxColumn-->
                      <!--template #headerTypeservice="{ }">
                          <div>Tipo de<br />servicio</div>
                      </template-->
                      <DxColumn :width="65" data-field="Price" caption="Precio"></DxColumn>
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                          <v-icon>mdi-plus</v-icon>
                          </a>
                      </template>
                      <template #quantity-template="{ data }">
                          <p style="margin: 0;">{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService)?.quantity || '' }}</p>
                      </template>
                      </DxDataGrid>
                  </GeneralFilter>
                  </v-col>
              </v-row>

              <!-- Products to select -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver todos los productos
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide3 = !togglehide3' v-show='!togglehide3' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide3 = !togglehide3' v-show='togglehide3' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide3">
                  <GeneralFilter 
                      :filterEndpoint="$API.products.getProductsToBillForInvoiceFiltered" 
                      :search="searchToBill"
                      @emptyFilter="getProducts" 
                      @filtered="productsFilterHandler"
                  >
                      <v-row style="padding: 1rem;">
                            <v-col cols="12" md="3" sm="4">
                                <v-autocomplete
                                v-model="selectedWarehouse"
                                item-text="Warehouse"
                                item-value="ID_Warehouse"
                                :items="warehouses"
                                @change="handleSelectWarehouse"
                                label="Almacenes"
                                ref="werehouseRef"
                                dense 
                                outlined 
                                attach
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="9" sm="8">
                                <p style="color: #e3191e !important; font-size: 12px; margin: 12px 0" v-if="warehouses.length == 0">No existen almacenes de productos para pacientes.</p>
                            </v-col>
                      </v-row>
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="productsToBill"
                      key-expr="ID_Item" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="ID_Item" caption="ID"></DxColumn>
                      <DxColumn data-field="ItemName" header-cell-template="headerName"></DxColumn>
                      <template #headerName="{ }">
                          <div>Nombre del<br />producto</div>
                      </template>
                      <DxColumn data-field="ItemComercialName" header-cell-template="headerNameComercial"></DxColumn>
                      <template #headerNameComercial="{ }">
                          <div>Nombre<br />comercial</div>
                      </template>
                      <DxColumn :width="65" data-field="ItemPrice" caption="Precio"></DxColumn>
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="addProduct(data.data)">
                          <v-icon>mdi-plus</v-icon>
                          </a>
                      </template>
                      <template #quantity-template="{ data }">
                        <p style="margin: 0;">{{ productsToAdd.find(item => item.ID_Item == data.data.ID_Item)?.quantity || '' }}</p>
                      </template>
                      </DxDataGrid>
                  </GeneralFilter>
                  </v-col>
              </v-row>

              <!-- ServiceToAdd -->
              <v-row style="margin-top: 2rem">
                  <v-col cols="12">
                  <h4 style="padding: 16px;">Servicios seleccionados</h4>
                  <div style="overflow-x: scroll; padding: 0 12px;">
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="servicesToAdd"
                      key-expr="CodeService" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"    
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="5" />
                      <DxColumn data-field="CodeService" :allow-editing="false" caption="ID"></DxColumn>
                      <DxColumn data-field="NameService" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <DxColumn data-field="TypeService" :allow-editing="false" header-cell-template="headerTypeservice">
                      </DxColumn>
                      <template #headerTypeservice="{ }">
                          <div>Tipo de<br />servicio</div>
                      </template>
                      <DxColumn data-field="quantity" caption="Cantidad">
                          <DxFormat typed="fixedPoint" :precision="0" />
                          <DxRequiredRule message="La cantidad del servicio a facturar es requerida" />
                          <DxPatternRule :pattern="itemQtyPattern"
                          message="La cantidad del servicio facturado solo puede contener numeros enteros mayores a 0" />
                      </DxColumn>
                      <DxColumn data-field="Price" :allow-editing="true" caption="Precio"></DxColumn>
                      <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                      <DxColumn cell-template="del-template" caption=""></DxColumn>
                      <template #del-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="deleteService(data.data)">
                          <v-icon>mdi-delete</v-icon>
                          </a>
                      </template>
                      <template #total-template="{ data }">
                          <div>
                          {{ totalXQuantity( data.data ) }}
                          </div>
                      </template>
                      </DxDataGrid>
                  </div>
                  </v-col>
              </v-row>

              <!-- Product to add -->
              <v-row style="margin-top: 2rem">
                  <v-col cols="12">
                  <h4 style="padding: 16px;">Productos seleccionados</h4>
                  <div style="overflow-x: scroll; padding: 0 12px;">
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="productsToAdd"
                      key-expr="ID_Item" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"    
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="5" />
                      <DxColumn data-field="ID_Item" :allow-editing="false" caption="ID"></DxColumn>
                      <DxColumn data-field="ItemName" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre del<br />producto</div>
                      </template>
                      <DxColumn data-field="ItemComercialName" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <DxColumn data-field="ItemType" :allow-editing="false" header-cell-template="headerTypeservice">
                      </DxColumn>
                      <template #headerTypeservice="{ }">
                          <div>Tipo de<br />producto</div>
                      </template>
                      <DxColumn data-field="quantity" caption="Cantidad">
                          <DxFormat typed="fixedPoint" :precision="0" />
                          <DxRequiredRule message="La cantidad del producto a facturar es requerida" />
                          <DxPatternRule :pattern="itemQtyPattern"
                          message="La cantidad del producto facturado solo puede contener numeros enteros mayores a 0" />
                      </DxColumn>
                      <DxColumn data-field="ItemPrice" :allow-editing="true" caption="Precio"></DxColumn>
                      <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                      <DxColumn cell-template="del-template" caption=""></DxColumn>
                      <template #del-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="deleteProduct(data.data)">
                          <v-icon>mdi-delete</v-icon>
                          </a>
                      </template>
                      <template #total-template="{ data }">
                          <div>
                          {{ totalXQuantity( {...data.data, Price: data.data.ItemPrice} ) }}
                          </div>
                      </template>
                      </DxDataGrid>
                  </div>
                  </v-col>
              </v-row>

              </div>
          </v-card>
    </v-dialog>
    <!-- Dialog bill patient -->


    <v-dialog v-model="searchTemplateDialog" scrollable width="80%">
      <manage-template :show="searchTemplateDialog" @templateSelected="templateSelected"></manage-template>
    </v-dialog>
    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    ></alerts>
    <print-vet-test :data="print"></print-vet-test>
    </v-card-text>
  </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import Block from "@/components/Block";
import Alerts from "@/components/Alerts";
import PrintVetTest from  "@/components/ReportsAndPrintings/PrintVetTest.vue"
import { printToExcel } from "@/helpers/printToexcel";
import GeneralFilter from  "@/components/GeneralFilter";
import {
  DxDataGrid, DxColumn, DxPaging, DxScrolling, DxEditing, DxFormat, DxPatternRule, DxRequiredRule, DxKeyboardNavigation, DxExport, DxButton, } from "devextreme-vue/data-grid";
import DatePicker from "../../../components/DatePicker";
import ManageTemplate from "./ManageTemplate";
import { mapState } from "vuex";
import UserProfile from "@/components/Displays/UserProfileDisplay.vue";
import { numberWithCommas } from "@/helpers/money";

export default {
  name: "VeterinaryLaboratoryTest",
  components: {
    PrintVetTest,
    DxDataGrid, 
    DxColumn, 
    DxEditing, 
    DxPaging, 
    DxFormat, 
    DxPatternRule, 
    DxScrolling, 
    DxRequiredRule,
    DxExport,
    DatePicker,
    Block,
    ManageTemplate,
    // DxLookup,
    DxKeyboardNavigation,
    DxButton,
    Alerts,
    GeneralFilter,
    UserProfile,
  },
  props: ["data", "win"],
  data() {
    return {
      currency: JSON.parse(localStorage.getItem('branch')).Currency,
      itemQtyPattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/i,
      showBillPatient: false,
      useData: {},
      showServicesToAdd: false,
      showProductsToAdd: false,
      servicesToBill: [],
      productsToBill: [],
      servicesToAdd: [],
      productsToAdd: [],
      filterProducts: {
          filter: null
      },
      searchToBill: {
          filter: null
      },
      togglehide1: true,
      togglehide2: true,
      togglehide3: true,
      warehouses: [],
      selectedWarehouse: null,
      customer: {},
      print: {
                show: false,
                data: []
            },
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      valid: false,
      showhistory: false,
      url: null,
      date: new Date(),
      showComments: false,
      patientinfo: [],
      laboratorys: {}, //Obtención de registros previamente ingresados
      laboratoryparameters: [], //Datos oficiales de parametros
      selectedTemplate: {}, //Datos principales obtenidos de la template
      searchTemplateDialog: false, //Mostrar y no mostrar la template
      id_user_login: null,
      resetDate: "",
      istemp: false,
      addTemplateForm: {
        OtherLaboratory: false
      },
      loading: false,
      pltp: [],
      search: {
        ID: this.data.ID_Patient ? this.data.ID_Patient : null,
      },
      rowType: [
        {
          ID: "T",
          Name: "Titulo",
        },
        {
          ID: "V",
          Name: "Valor",
        },
        {
          ID: "W",
          Name: "Espacio en blanco",
        },
        {
          ID: "S",
          Name: "Subtitulo",
        },
      ],
      showLabHistory: false,
      //useData: null,
      dataTodelete : null,
    };
  },
  computed: {
    ...mapState(
      "rolesUser",
      {
        rolesStatus: (state) => state.roles,
      }),
    columnWidth() {
      return this.$vuetify.breakpoint.smAndDown ? "150px" : "auto !important";
    }

  },
  async mounted() {
    this.$store.dispatch('rolesUser/getAllRoles')
    if (this.data) {
      Object.keys(this.data).map((el) => {
        Vue.set(this.patientinfo, el.toLowerCase(), this.data[el]);
      });
      this.url =
        this.$store.getters.getBackendUrl +
        "/public" +
        this.data.ProfilePicPath;
      await this.getAlllabs();
      // this.getDataLab();
      this.id_user_login = JSON.parse(localStorage.getItem("user")).id;
      this.useData = JSON.parse(localStorage.getItem("user"));
    
      // this.addTemplateForm.laboratorytestdate = ("0" + (this.date.getUTCMonth()+1)).slice(-2) + "/" + ("0" + this.date.getUTCDate()).slice(-2) + "/" + this.date.getUTCFullYear();
      //this.useData = JSON.parse(localStorage.getItem("user"));
      this.customer.ID_Customer = this.data.ID_Customer;
      console.log('customer', this.customer);
      this.warehouses = await this.$API.warehouse.getWarehousesFiltered({ isPatient: true });
      this.filterProducts.business = this.useData.businessid;
      this.filterProducts.branch = this.useData.branch;
      this.selectedWarehouse = this.warehouses.length == 0 ? 0 : this.warehouses[0].ID_Warehouse;
      this.filterProducts.warehouse = this.selectedWarehouse;    
      await this.getServices();
      await this.getProducts();
      await this.getDefaultServices();
    }
  },
  watch: {
    "addTemplateForm.HeaderOtherLaboratory"() {
      if (this.addTemplateForm.OtherLaboratory) {
        this.addTemplateForm.LaboratoryName = this.addTemplateForm.HeaderOtherLaboratory
      }
    },
    servicesToAdd: {
        deep: true,
        immediate: true,
        handler() {
            if(this.servicesToAdd.length !== 0) {
                this.showServicesToAdd = true;
            }
            else {
                this.showServicesToAdd = false;
            }
        }
    },
    productsToAdd: {
        deep: true,
        immediate: true,
        handler() {
            if(this.productsToAdd.length !== 0) {
                this.showProductsToAdd = true;
            }
            else {
                this.showProductsToAdd = false;
            }
        }
    },
  },
  methods: {
    totalXQuantity( { Price, quantity } ) {
        return `${this.currency}${ numberWithCommas( parseFloat( Price * quantity ).toFixed( 2 ) ) }`
    },
    async handleSelectWarehouse() {
        this.loading = true;
        this.filterProducts.warehouse = this.selectedWarehouse;
        await this.getProducts();
        this.$refs.werehouseRef.blur();
        this.loading = false;
    },
    addService(data) {
        const isRepetitive = this.servicesToAdd.find((service) => service.ID_Service === data.ID_Service);

        if (!isRepetitive) {
            this.servicesToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.Price), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.Price).toFixed(4))}` });
        } else {
            const service = this.servicesToAdd.find(item => item.ID_Service === data.ID_Service)
            if(service) {
                service.quantity++;
            }
            console.log("Service already exists in servicesToAdd");
        }
    },
    addProduct(data) {
        const isRepetitive = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
        if (!isRepetitive) {
            this.productsToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.ItemPrice), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.ItemPrice).toFixed(4))}` });
        } else {
            const product = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
            if(product) {
                product.quantity++;
            }
            console.log("Product already exists in productsToAdd.");
        }
    },
    deleteService(data) {
        const serviceIndex = this.servicesToAdd.findIndex((service) => service.CodeService === data.CodeService);

        if (serviceIndex !== -1) {
            this.servicesToAdd.splice(serviceIndex, 1);
        }
    },
    deleteProduct(data) {
        const index = this.productsToAdd.findIndex((item) => item.ID_Item === data.ID_Item);

        if (index !== -1) {
            this.productsToAdd.splice(index, 1);
        }
    },
    getServices() {
        this.$API.services.getAllServices(this.filterServices).then((response) => {
            this.servicesToBill = response;
        });
    },
    getProducts() {
        this.$API.products.getProductsToBillForInvoice(this.filterProducts).then((response) => {
            this.productsToBill = response;
        });
    },
    getDefaultServices() {
        this.$API.services.getDefaultServices().then((response) => {
            this.servicesDefault = response;
        });
    },
    servicesFilterHandler(response) {
        this.servicesToBill = response;
    },
    productsFilterHandler(response) {
        this.productsToBill = response;
    },
    getItemsToBeBilled() {
        this.$API.veterinarypatients.getServicesToBeBilled({ 'customer': this.data.ID_Customer })
        .then((response) => {
            this.servicesToAdd = []
            for(let i = 0; i < response.length; i++){
            this.servicesToAdd.push({ ...response[i], unitPrice: `${this.currency}${numberWithCommas(parseFloat(response[i].Price).toFixed(4))}` });
            }
        });
        this.$API.veterinarypatients.getProductsToBeBilled({ 'customer': this.data.ID_Customer }).then((res) => {
            if(res.success) {
            this.productsToAdd = [];
            for (const item of res.data) {
                this.productsToAdd.push({ ...item, quantity: item.Quantity, unitPrice: `${this.currency}${numberWithCommas(parseFloat(item.ItemPrice).toFixed(4))}`});
            }
            }
        });
    },
    async saveItemsToBeBilled() {
        this.showBillPatient = false;
        this.loading = true;

        try {
            if(this.servicesToAdd.length) {
            await this.$API.veterinarypatients.saveServicesToBeBilled({ 'customer': this.customer.ID_Customer, 'services': this.servicesToAdd })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                throw new Error('Ocurrio un error al guardar los servicios.');
            });
            }
            if(this.productsToAdd.length) {
            console.log(this.productsToAdd);
            
            await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                throw new Error('Ocurrio un error al guardar los productos.');
            });
            }
            await this.getItemsToBeBilled();
            this.loading = false;
            this.showAlert("success", "Exito", "Los datos de facturación de este paciente han sido guradados.")
        } catch (error) {
            console.log(error);
            this.loading = false;
            this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
        }

    },
    async billedPatientsOfCustomer() {
        this.showBillPatient = false;
        this.loading = true;

        try{
            await this.$API.veterinarypatients.saveServicesToBeBilled({'customer': this.customer.ID_Customer, 'services': this.servicesToAdd});
            await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd });
            await this.$API.veterinarypatients.billedPatientsOfCustomer({ 'customer': this.customer.ID_Customer});

            this.loading = false;

            this.showAlert("success", "Exito", `Se ha realizado la factura de los ${this.servicesToAdd.length && this.productsToAdd.length ? 'servicios y productos' : this.servicesToAdd.length ? 'servicios' : this.productsToAdd.length ? 'productos' : 'items' } seleccionados, podrá observarla en el módulo de POS en listo para facturar.`);
            await this.getItemsToBeBilled();        
        }
        catch(error) {
            console.log(error)
            this.loading = false
            this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
        }
    },

    onExporting(e){
      printToExcel(e, 'Historial de laboratorios')
    },
    showPrint(){

      console.log(this.addTemplateForm);
      console.log(this.data);
      this.print = {
        show: true,
        data: {
          header: this.addTemplateForm,
          body: this.pltp,
          profile: this.data
        }
      }
    },
    closeAlert() {
      this.alert.show = false;
      if (this.alert.options == "changeOtherLaboratory") {
        this.addTemplateForm.OtherLaboratory = false
      }
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "reloadtemplates") {
        this.getAlllabs();
      }
      if (this.alert.options == "confirmDelete") {
        this.deleteLab(this.dataTodelete);
      }
      if (this.alert.options == "changeOtherLaboratory") {
        this.pltp = []
        this.addTemplateForm.LaboratoryName = "OTRO"
        this.addTemplateForm.OtherLaboratory = true
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    selectedLab(data) {
      this.dataTodelete = data;
            this.addTemplateForm.ID = data.ID
            this.addTemplateForm.LaboratoryTestDate = data.LaboratoryTestDate;
            this.addTemplateForm.laboratorytestdate = data.LaboratoryTestDate;
            this.date = data.LaboratoryTestDate
            this.addTemplateForm.Billing = data.Billing
            this.addTemplateForm.Filling = data.Filling
            this.addTemplateForm.LaboratoryName = data.LaboratoryName;
            this.addTemplateForm.ID_LaboratoryTest = data.ID_LaboratoryTest;
            this.addTemplateForm.ID_Patient = data.ID_Patient
            this.addTemplateForm.Comment = data.Comment
            this.addTemplateForm.OtherLaboratory = data.OtherLaboratory == 1 ? true : false
            this.addTemplateForm.HeaderOtherLaboratory = data.HeaderOtherLaboratory
            this.addTemplateForm.ValueOtherLaboratory = data.ValueOtherLaboratory
            this.pltp = [];
            
            this.$API.laboratory.vetlabParams({
                idplt: data.ID,
                patient: data.ID_Patient
            }).then(response => {
                this.pltp = response;
                if (this.pltp.length == 0 && data.ID_LaboratoryTest != '') {
                    this.$API.laboratory.vetshowLabDetails(data.ID)
                        .then(response => {
                            this.pltp = response


                        })
                }
                this.pltp.sort(function(a, b) {
                    let aValue = parseInt(a.ParameterOrder);
                    let bValue = parseInt(b.ParameterOrder);
                    if (aValue !== bValue) {
                        return aValue - bValue;
                    }
                    return 0;
            });
            })

        },
    savedparams() {
      this.pltp.sort(function (a, b) {
        let aValue = parseInt(a.ParameterOrder);
        let bValue = parseInt(b.ParameterOrder);
        if (aValue !== bValue) {
          return aValue - bValue;
        }
        return 0;
      });
    },
    handleRowUpdating(e) {
      console.log(e);
      console.log(this.pltp);
      let { ParameterOrder } = e.newData;

      if (ParameterOrder) {
        if (isNaN(ParameterOrder) || ParameterOrder == "") {
          this.showAlert("danger", "Error", "El Orden debe ser un número");


        }
        this.pltp.forEach((element) => {
          if (element.ParameterOrder == ParameterOrder) {
            element.ParameterOrder = e.oldData.ParameterOrder;
            this.showAlert("danger", "Error", "El orden ya existe");

          }
        });
        this.pltp.sort(function (a, b) {
          let aValue = parseInt(a.ParameterOrder);
          let bValue = parseInt(b.ParameterOrder);
          if (aValue !== bValue) {
            return aValue - bValue;
          }
          return 0;
        });
      }
    },
    Preview_image() {
      this.url = URL.createObjectURL(this.image);
    },
    deleteLab(data) {
      console.log({ id: data.ID, patient: data.ID_Patient });
            this.$API.laboratory.vetdeleteLab({ id: data.ID, patient: data.ID_Patient })
                .then(() => {
                    this.showAlert('success', 'Exito', 'Datos eliminados correctamente');
                    this.addTemplateForm.ID = null
                    this.addTemplateForm.LaboratoryName = null
                    this.addTemplateForm.Billing = 0
                    this.addTemplateForm.Filling = 0
                    this.pltp = [];
                    this.getAlllabs();
                })
                .catch(() => {
                    this.showAlert('danger', 'Error', 'Ha ocurrido un error al eliminar los datos', );
                });
        },
        filterHandler(response) {
          this.laboratoryparameters = response;
        },
    getAlllabs() {
      //PAT22120004
      
      this.$API.laboratory
        .vetpatientLabTests(this.data.ID_Patient)
        .then((response) => {
          //console.log(response);
          //console.log("laboratorys");
          this.laboratoryparameters = response;
        });
    },
    templateSelected(payload, lab, id) {
      console.log(payload, id, lab);

      this.loading = true;
      this.searchTemplateDialog = false;
      this.selectedTemplate = payload;
      this.istemp = true;
      this.addTemplateForm.id_laboratorytest = id;
      this.addTemplateForm.LaboratoryName = lab;
     // this.ID_TestParam = payload[0].ID_TestParam;
      if(payload.length == 0){
        this.showAlert("warning", "Advertencia", "No se encontraron parametros para esta plantilla");
        this.loading = false;
        this.addDetails()
        return;
      }
      for (let i = 0; i < payload.length; i++) {
        this.pltp.push(payload[i]);
      }
      this.pltp.sort(function (a, b) {
        let aValue = parseInt(a.ParameterOrder);
        let bValue = parseInt(b.ParameterOrder);
        if (aValue !== bValue) {
          return aValue - bValue;
        }
        return 0;
      });
      this.addTemplateForm.Id_User_Insert = this.id_user_login;
      this.addTemplateForm.ID_Patient = this.data.ID_Patient;

      this.loading = false;
    },
    addDetails() {
      let newExam = {
        ID_LaboratoryTest: this.addTemplateForm.ID_LaboratoryTest,
        ID_Patient: this.data.ID_Patient,
     //   ID_TestParam: this.ID_TestParam,
        ParameterExpected: "",
        ParameterValue: "",
        ParameterOrder: this.getOrdernumber(),
        ParameterType: "W",
        ParameterName: "",
        ParameterUnit: "",
      };
      this.pltp.push(newExam);
    },
    getOrdernumber() {
      let order = 0;
      this.pltp.forEach((element) => {
        if (element.ParameterOrder > order) {
          order = element.ParameterOrder;
        }
      });
      return parseInt(order) + 1;
    },
    updateParam() {
      this.loading = true
      this.addTemplateForm.LaboratoryTestDate = this.date;
      this.addTemplateForm.laboratorytestdate = this.date;
      this.id_user_login = JSON.parse(localStorage.getItem("user")).id;
      this.addTemplateForm.ID_User_Update = this.id_user_login;
      this.addTemplateForm.ID_Patient = this.data.ID_Patient;
      //console.log(this.patientinfo);
      //console.log(this.data);
      for (let i = 0; i < this.pltp.length; i++) {
        if (this.pltp[i].ID_Patient === undefined) {
          this.pltp[i].ID_Patient = this.data.ID_Patient;
          this.pltp[i].ID_LaboratoryTest =
          this.addTemplateForm.id_laboratorytest;
          this.pltp[i].LaboratoryTestDate =
            this.addTemplateForm.laboratorytestdate;
          this.pltp[i].ID_User_Insert = this.id_user_login;
          this.pltp[i].ID_User_Update = this.id_user_login;
        }
        if (this.pltp[i].ID_Patient === undefined || this.pltp[i].ID_Patient === null) {
          this.pltp[i].ID_LaboratoryTest =
          this.addTemplateForm.id_laboratorytest;
        }
      }
     
      if (
     
        this.addTemplateForm.ID_Patient == undefined ||
        this.addTemplateForm.ID_Customer == undefined ||
        this.addTemplateForm.ID_User_Insert == undefined
      ) {
      
        this.addTemplateForm.ID_Patient = this.data.ID_Patient;
        this.addTemplateForm.ID_Customer = this.data.ID_Customer || "";
        this.addTemplateForm.ID_User_Insert = this.id_user_login;
        //console.log("pase x aqui");
      }
    
      console.log(this.addTemplateForm,'previo a guardar');
       this.$API.laboratory
        .vetsaveLaboratoryTest({
          labTest: this.addTemplateForm,
          params: this.pltp,
          date: this.addTemplateForm.laboratorytestdate,

        })
        .then(() => {
          this.loading = false
          this.showAlert("success", "Exito", "Datos guardados correctamente");
          this.getAlllabs();
          this.cleanForm();
        })
        .catch((res) => {
          this.loading = false
          console.log(res);
          this.showAlert(
            "danger",
            "Error",
            "Ha ocurrido un error al guardar los datos"
          );
        });
    },
    addLab(){
      this.loading = true
      this.id_user_login = JSON.parse(localStorage.getItem("user")).id;
      this.addTemplateForm.ID_User_Update = this.id_user_login;
      
      this.addTemplateForm.LaboratoryTestDate = this.date;
      this.addTemplateForm.laboratorytestdate = this.date;
      console.log(this.addTemplateForm.laboratorytestdate);
      this.addTemplateForm.ID_Customer = this.data.ID_Customer || "";
      this.addTemplateForm.ID_LaboratoryTest = this.addTemplateForm.id_laboratorytest;
      this.addTemplateForm.ID_Patient = this.data.ID_Patient;
      //console.log(this.patientinfo);
      //console.log(this.data);
      for (let i = 0; i < this.pltp.length; i++) {
        if (this.pltp[i].ID_Patient === undefined) {
          this.pltp[i].ID_Patient = this.data.ID_Patient;
          this.pltp[i].ID_LaboratoryTest =
          this.addTemplateForm.id_laboratorytest;
          this.pltp[i].LaboratoryTestDate =  this.addTemplateForm.LaboratoryTestDate
           
          this.pltp[i].ID_User_Insert = this.id_user_login;
          this.pltp[i].ID_User_Update = this.id_user_login;
        }
        if (this.pltp[i].ID_Patient === undefined || this.pltp[i].ID_Patient === null) {
          this.pltp[i].ID_LaboratoryTest =
          this.addTemplateForm.id_laboratorytest;
        }
      }
   
      
      
      if(this.pltp.length == 0 && !this.addTemplateForm.OtherLaboratory){
        this.loading = false
        this.showAlert("danger", "Error", "Debe agregar al menos un parametro");
        return;
      }

      this.$API.laboratory
        .AddVetLab({
          header: this.addTemplateForm,
          params: this.pltp,
        })
        .then(() => {
          this.loading = false
          this.showAlert("success", "Exito", "Datos guardados correctamente");
          this.getAlllabs();
          this.cleanForm();
        })
        .catch((res) => {
          this.loading = false
          console.log(res);
          this.showAlert(
            "danger",
            "Error",
            "Ha ocurrido un error al guardar los datos"
          );
        });
    },
    handleRowDeleting() {
      // if i need get alert before delete
    },
    onKeyDown(e) {
      if (e.event.key == "Tab") {
        e.event.preventDefault();
        var event = new KeyboardEvent("keydown", {
          bubbles: true,
          cancelable: true,
          keyCode: 13,
        });
        e.event.target.dispatchEvent(event);
        /* var clickEvent = new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window
                });
                e.event.target.dispatchEvent(clickEvent); */
      }
    },
    onEditingStart(e) {
      if (e.column.dataField == "ParameterName") {
        if (e.data.ParameterType == "W") e.cancel = true;
      } else if (e.column.dataField == "ParameterUnit") {
        if (
          e.data.ParameterType != "V" ||
          e.data.ParameterType == undefined ||
          e.data.ParameterType == null
        )
          e.cancel = true;
      } else if (e.column.dataField == "ParameterExpected") {
        if (
          e.data.ParameterType != "V" ||
          e.data.ParameterType == undefined ||
          e.data.ParameterType == null
        )
          e.cancel = true;
      } else if (e.column.dataField == "ParameterValue") {
        if (
          e.data.ParameterType != "V" ||
          e.data.ParameterType == undefined ||
          e.data.ParameterType == null
        )
          e.cancel = true;
      }
      //to cancel editing  e.cancel = true;
      //to check column name e.column.dataField
    },

    saveLabTest() {
      this.loading = true;
      if (this.istemp) {
        this.$API.veterinarylaboratorytest
          .addVeterinaryLaboratoryTest(this.addTemplateForm)
          .then(() => {
            this.loading = false;
            this.showAlert(
              "success",
              "Exito",
              "Datos guardados correctamente"
            );
            this.getDataLab();
            this.cleanForm();
          });
      } else {
        this.showAlert(
          "danger",
          "Error",
          "No se puede guardar un examen sin plantilla"
        );
        this.loading = false;
        return;
      }
    },

    insertParameters(id) {
      this.$API.laboratory.showLabDetails(id).then((response) => {
        Object.keys(response).map((i) => {
          this.addTemplateForm.parametername = response[i]["ParameterName"];
          this.addTemplateForm.parametervalue = response[i]["ParameterValue"];
          this.addTemplateForm.parameterunit = response[i]["ParameterUnit"];
          this.addTemplateForm.parameterexpected =
            response[i]["ParameterExpected"];
          this.addTemplateForm.parametertype = response[i]["ParameterType"];
          this.addTemplateForm.parameterorder = response[i]["ParameterOrder"];
          this.addTemplateForm.printrow = response[i]["PrintRow"];
          Vue.set(this.addTemplateForm, "id_user_insert", this.id_user_login);
          const formdata = new FormData();
          formdata.append(
            "veterinarylaboratorytest",
            JSON.stringify(this.addTemplateForm)
          );
          this.$API.veterinarylaboratorytest
            .addVeterinaryLaboratoryTest(formdata)
            .then((res) => {
              console.log(i, response[i]);
              response[i]["ID_VPTP"] = res.ID_VPTP;
              this.laboratoryparameters.push(response[i]);
              this.loading = false;
              this.showAlert(
                "success",
                "Exito",
                "Datos guardados correctamente"
              );
              //this.$store.dispatch('reloadData', 'veterinarypatients')
              this.getDataLab();
            })
            .catch(() => {
              this.loading = false;
            });
        });
      });
    },

    saveDataLab() {
      //alert(JSON.stringify(this.laboratoryparameters));
      Object.keys(this.laboratoryparameters).map((i) => {
        this.addTemplateForm.parametername =
          this.laboratoryparameters[i]["ParameterName"];
        this.addTemplateForm.parametervalue =
          this.laboratoryparameters[i]["ParameterValue"];
        //alert(JSON.stringify(this.addTemplateForm.parametervalue));
        this.addTemplateForm.parameterunit =
          this.laboratoryparameters[i]["ParameterUnit"];
        this.addTemplateForm.parameterexpected =
          this.laboratoryparameters[i]["ParameterExpected"];
        this.addTemplateForm.parametertype =
          this.laboratoryparameters[i]["ParameterType"];
        this.addTemplateForm.parameterorder =
          this.laboratoryparameters[i]["ParameterOrder"];
        this.addTemplateForm.printrow =
          this.laboratoryparameters[i]["PrintRow"];
        Vue.set(this.addTemplateForm, "id_user_update", this.id_user_login);
        this.addTemplateForm.id_user_insert =
          this.laboratoryparameters[i]["ID_User_Insert"];
        const formdata = new FormData();
        formdata.append(
          "veterinarylaboratorytest",
          JSON.stringify(this.addTemplateForm)
        );
        var id_vptp = this.laboratoryparameters[i]["ID_VPTP"];
        this.$API.veterinarylaboratorytest
          .updateVeterinaryLaboratoryTest(id_vptp, formdata)
          .then((response) => {
            this.laboratoryparameters[i] = response.veterinarylaboratorytest;
            this.laboratoryparameters[i]["ID_VPTP"] = id_vptp;
            this.loading = false;
            this.showAlert(
              "success",
              "Exito",
              "Datos guardados correctamente"
            );
            this.getDataLab();
            this.cleanForm();
          })
          .catch(() => {
            this.loading = false;
            this.showAlert("danger", "Error", "Error al guardar los datos");
          });
      });
    },

    getDataLab() {
      this.$API.veterinarylaboratorytest
        .getVeterinaryLaboratoryTest(this.patientinfo.id_patient)
        .then((response) => {
          this.laboratorys = response;
        });
    },

    showInfoSelected(data) {
      this.laboratoryparameters = [];
      this.istemp = true;
      this.selectedTemplate.LaboratoryName = data.LaboratoryName;
      this.addTemplateForm.laboratorytestdate = data.LaboratoryTestDate;
      this.addTemplateForm.id_laboratorytest = data.ID_LaboratoryTest;
      this.addTemplateForm.Comment = data.Comment;

      this.$API.veterinarylaboratorytest
        .getVeterinaryLaboratoryTestParameters({
          idlaboratorytest: data.ID_LaboratoryTest,
          idpatient: data.ID_Patient,
          date: data.LaboratoryTestDate,
        })
        .then((response) => {
          Object.keys(response).map((i) => {
            this.laboratoryparameters.push(response[i]);
          });
        });
    },

    deleteSelected(data) {
      this.loading = true;
      this.$API.veterinarylaboratorytest
        .deleteVeterinaryLaboratoryTest({
          patient: data.ID_Patient,
          labtestid: data.ID_LaboratoryTest,
          date: data.LaboratoryTestDate,
        })
        .then(() => {
          this.showAlert("success", "Exito", "Datos eliminados correctamente");
          this.getDataLab();
          this.cleanForm();
          this.loading = false;
        });
    },

    cleanForm() {
      //this.laboratoryparameters = [];
      this.dataTodelete = null;
      this.selectedTemplate = {};
      this.addTemplateForm = {};
      this.addTemplateForm.ID_Patient = this.patientinfo.ID_Patient;
      this.addTemplateForm.laboratorytestdate =
        this.moment().format("DD/MM/YYYY");
        this.date = this.moment().format("DD/MM/YYYY");
      this.istemp = false;
      this.pltp = [];
    },

    setOtherLaboratory() {
      if (this.addTemplateForm.OtherLaboratory) {
        if (this.pltp.length > 0 && this.addTemplateForm.LaboratoryName != "" && this.addTemplateForm.LaboratoryName != null) {
          this.showAlert(
            "question2",
            "Advertencia",
            "Al seleccionar Otro Laboratorio, se perdera la información del examen en progreso. ¿Esta seguro que desea continuar?",
            "changeOtherLaboratory"
          );
        }
        else {
          this.addTemplateForm.LaboratoryName = "OTRO"
        }
      }
      else {
        if (this.pltp.length == 0) { 
          this.addTemplateForm.LaboratoryName = undefined
        }
      }
    }
  },
};
</script>

<style scoped>
.btndisable {
    background-color: #41464c !important;
    color: white;
}
.btndisable2 {
    background-color: #1976d2 !important;
    color: white;
}

.btn-add {
  background-color: #3498db !important;
  color: white;
}
.btnsave {
  background-color: #00a178 !important;
  color: white;
}
.btnclean {
  background-color: #f29d35 !important;
  color: white;
}
.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.tabsB{
    width: 100%;
        padding: 10px;
        background-color: #1976d2;
        color: white;
 border-radius: 15px;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    }
    .tabsB:hover {
      cursor: pointer;
    }

.box{
    border-style: solid;
    border-color: black;
    border-width: 1px;
    background-color: white;
    
    border-radius: 15px;
   

}

.btnblack{
    background-color: rgba(60, 60, 60, 1) !important;
    color: white !important;

  }

.tabsNh{
    width: 100%;
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;
    
}
.btnblue {
    background-color: #1976d2 !important;
    color: white;
}

.tabs {

  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white;
}


.infopatient {
  margin-top: 5%;
}
.v-input.theme--light.v-input--selection-controls.v-input--checkbox {
  padding: 0 !important;
}
.microbox {
    display: flex;
    justify-content: center;
}
</style>
