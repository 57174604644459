import axios from "axios";
import store from "../store";
import { userData } from "./Currentuserdata";
import auth from "./auth";
import Vuex from "../store/index";
import router from "../router";

const { refreshAuth } = auth;

var navigator = window.navigator;

const detectOS = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.indexOf("Win") !== -1) {
    return "Windows" 
  } else if (userAgent.indexOf("Mac") !== -1) {
    return "macOS"
  } else if (userAgent.indexOf("Linux") !== -1) {
    return "Linux"
  } else if (userAgent.indexOf("Android") !== -1) {
    return "Android"
  } else if (userAgent.indexOf("iOS") !== -1) {
    return "iOS"
  } else {
    return "Unknown"
  }
};

const instance = axios.create({
  baseURL: getCookie("BackendUrl"),
  //baseURL: 'https://innova-vet-test-b47jp.ondigitalocean.app/api/', //Test
  //baseURL: 'https://apps.innovatebusiness.net/api/', //Guadalupe
  //baseURL: 'http://52.33.16.113:8000/api/', //Conexión a medicalSystem_ProductionTest
  //baseURL: 'http://44.226.253.76:8000/api/', //Conexión a Innova_lab_biologico
  // baseURL: "http://localhost:8000/api/",
  headers: {
    "Access-Control-Allow-Headers": "x-access-token",
    "x-access-token": localStorage.getItem("token"),
    deviceip: localStorage.getItem("clientIP"),
    deviceos: detectOS(),
    deviceName: "desktop",
    ID_Business: userData() ? userData().businessid : null,
    ID_Branch: userData() ? userData().branch : null,
    businesstype: userData() ? userData().businesstype : null,
    keymaster: userData() ? userData().keymaster : null
  },
});

instance.interceptors.request.use(
  function (config) {
    config.headers["ID_Business"] = userData() ? userData().businessid : null;
    config.headers["ID_Branch"] = userData() ? userData().branch : null;
    config.headers["businesstype"] = userData() ? userData().businesstype : null;
    config.headers["keymaster"] = userData() ? userData().keymaster : null;
    const token = localStorage.getItem("token");
    config.headers["user_id"] = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))?.id
      : "";
    if (token) config.headers["x-access-token"] = token;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      const resp = await refreshAuth();

      const { accessToken } = resp;

      Vuex.commit("setToken", accessToken);
      localStorage.setItem("token", accessToken);

      instance.defaults.headers.common["x-access-token"] = accessToken;

      return instance(originalRequest);
    } else if (error.response.status == 401) {
      store.commit("removeUser");
      router.push('/login');
      return Promise.reject(error);
    }
  }
);

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default instance;
