<template>
  <div>
    <block v-if="loading"></block>
    <v-card flat>
      <v-card-text>
          
        <template v-if="type=== 'medicalclinic'">
            <user-profile-lab :patient="data" :patientinfo="data" :useData="useData"></user-profile-lab>
        </template>
        <template v-else>
            <user-profile :patientinfo="patientinfo" :data="data" :useData="useData" />
        </template>

        <v-row style="margin-top: 1rem;">

          <block v-if="loading"></block>

          <v-col cols="12" class="box" style="background-color: white;margin-top: 1rem;">
            <v-form v-model="valid" class="position-relative">

              <v-row class="ml-2 mr-2" style="margin-top: .3rem;">

                <v-col class="tabsNh" cols="12">


                  <v-col cols="12" md="12" v-if="selectedvalues" style="display: flex; justify-content: flex-end">

                    <v-btn small class="mr-1"
                      @click="print = { show: true, data: { prescription: prescription, patientinfo: patientinfo, useData: useData, customer: patientinfo.customer, nextappointment: usenextappointment } }">
                      <v-icon>
                        mdi-printer
                      </v-icon>
                    </v-btn>
                    <v-btn small class="btnblue mr-1" @click="showPrescriptionHistory = !showPrescriptionHistory">
                      <v-icon>
                        mdi-magnify
                      </v-icon>
                    </v-btn>

                    <v-btn small class="btnclean" @click.prevent="resetData()">
                      <v-icon>
                        mdi-broom
                      </v-icon>
                    </v-btn>
                    <div>
                      <v-btn
                        v-if="rolesStatus.findIndex((role) => role.RoleName == 'admin') > -1 || rolesStatus.findIndex((role) => role.RoleName == 'root') > -1 || rolesStatus.findIndex((role) => role.ID_Role == 21) > -1"
                        small class="btnsave" @click.prevent.stop="addPrescription()">
                        <v-icon>
                          mdi-content-save
                        </v-icon>
                      </v-btn>
                    </div>

                  </v-col>
                  <v-col cols="12" v-if="prescription.id_pp" style=" display: flex; justify-content: space-between;">
                    <div>
                      <v-btn v-if="rolesStatus.findIndex((role) => role.RoleName == 'admin') > -1 || rolesStatus.findIndex((role) => role.RoleName == 'root') > -1 || rolesStatus.findIndex((role) => role.ID_Role == 23) > -1
                        " small color="error"
                        @click.prevent.stop='showAlert("question", "Confirmar", "Esta seguro de borrar el elemento esta accion no se puede dehacer", "deleteprescription"); confirmtoDelete = prescription'>
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                    <div class="d-flex">

                      <v-btn small class="mr-1"
                        @click="print = { show: true, data: { prescription: prescription, patientinfo: patientinfo, useData: useData, customer: patientinfo.customer, nextappointment: usenextappointment } }">


                        <v-icon>
                          mdi-printer
                        </v-icon>
                      </v-btn>
                      <v-btn small class="btnblue mr-1" @click="showPrescriptionHistory = !showPrescriptionHistory">
                        <v-icon>
                          mdi-magnify
                        </v-icon>
                      </v-btn>
                      <v-btn small class="btnclean" @click.prevent="resetData()">
                        <v-icon>
                          mdi-broom
                        </v-icon>
                      </v-btn>
                      <div>
                        <v-btn v-if="rolesStatus.findIndex((role) => role.RoleName == 'admin') > -1 || rolesStatus.findIndex((role) => role.RoleName == 'root') > -1 || rolesStatus.findIndex((role) => role.ID_Role == 22) > -1
                          " small class="btnsave" @click.prevent="updatePrescription()">
                          <v-icon>
                            mdi-content-save
                          </v-icon>
                        </v-btn>
                      </div>
                    </div>

                  </v-col>

                </v-col>
                <v-row style="margin-top: 1rem;">

                  <v-col cols="12" md="2" sm="12">

                    <date-picker label="Fecha" @dateSelected="(param) => {
                        prescription.prescriptiondate = param
                        checktodayprescription(param)


                      }
                      " :value="prescription.prescriptiondate"></date-picker>

                  </v-col>
                  <v-col cols="5"></v-col>
                  <v-col cols="12" md="1" style="display: flex;justify-content: flex-end;">
                    <v-btn @click="showComments = !showComments" this.prescription.ID_PP=data.ID_PP;
                      :class="prescription.comment ? 'btnclean' : 'btnblack'">
                      Comentarios
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="2">
                    <div style="margin-top: .4rem;display: flex;justify-content: flex-end;">
                      <v-checkbox v-model="usenextappointment" label="Próxima cita"
                        @change="NextAppointmentFunction($event)" value="Appointment"></v-checkbox>
                    </div>
                  </v-col>

                  <v-col cols="12" md="2" sm="12">
                    <date-picker @dateSelected="(param) =>
                        (prescription.nextappointment = param)
                      " :value="prescription.nextappointment" :disbaled="usenextappointment.length < 1"></date-picker>
                  </v-col>
                </v-row>

                <template v-if="data">



                  <!--v-btn @click.prevent="">
                    <v-icon> mdi-mail </v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn dark color="green" @click.prevent="">
                    <v-icon> mdi-whatsapp </v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn @click.prevent="">
                    <v-icon> mdi-file-excel </v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn @click.prevent="">
                    <v-icon> mdi-xml </v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn @click.prevent="">
                    <v-icon> mdi-file-pdf-box </v-icon>
                  </v-btn-->
                </template>



                <v-col cols="12" md="12">
                  <br />
                  <span class="ql-editor300">
                    <quill-editor v-model="prescription.prescription" :options="quillOptions"
                      @change="onEditorChange($event)"></quill-editor>
                  </span>
                  <br />
                </v-col>





                <v-col cols="12" style="margin-bottom: 1rem; ">


                </v-col>

              </v-row>

            </v-form>
          </v-col>
        </v-row>

        <v-dialog v-model="showPrescriptionHistory" scrollable :width="'90%'">
          <v-card class="pa-4">
            <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
              <v-btn small class="btndelete" @click.prevent="showPrescriptionHistory = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <GeneralFilter class="background border-box" entity="cliente"
              :filterEndpoint="$API.medicalprescription.getFilterPatientPrescription" :search="search"
              @emptyFilter="getPrescriptions" @filtered="filterHandler">
              <!-- <DxDataGrid class="isScrolledByGeneralFilter" @exporting="onExporting" :show-borders="true" :data-source="prescriptions" key-expr="ID_PP">
                <DxExport :enabled="true" />
                <DxColumn :width="70" cell-template="show-template" caption=""></DxColumn>
                <DxColumn data-field="PrescriptionDate" caption="Fecha"></DxColumn>
                <DxColumn data-field="NextAppointment" caption="Próxima cita"></DxColumn>
                <DxColumn data-field="fullName" caption="Atendido por"></DxColumn> -->

              <DxDataGrid 
                class="isScrolledByGeneralFilter" 
                @exporting="onExporting" 
                :show-borders="true" 
                :data-source="prescriptions" 
                key-expr="ID_PP" 
                :column-auto-width="true"
                style="width: fit-content;"
              >
                <DxExport :enabled="true" />
                <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                <DxColumn data-field="PrescriptionDate" caption="Fecha" :min-width="100"></DxColumn>
                <DxColumn data-field="NextAppointment" caption="Próxima cita" :min-width="100"></DxColumn>
                <DxColumn data-field="fullName" caption="Atendido por" :min-width="200"></DxColumn>
                <DxColumn data-field="PrescriptionTable" caption="Prescripción" :min-width="300" cssClass="left-align"></DxColumn>
                <DxColumn
                  v-if="rolesStatus.findIndex((role) => role.RoleName == 'admin') > -1 ||
                    rolesStatus.findIndex((role) => role.RoleName == 'root') > -1 || rolesStatus.findIndex((role) => role.ID_Role == 23) > -1"
                  :width="80" cell-template="show-delete" caption=""></DxColumn>
                <template #show-delete="{ data }">
                  <div>
                    <a href="" class="mx-2"
                      @click.prevent.stop='showAlert("question", "Confirmar", "Esta seguro de borrar el elemento esta accion no se puede dehacer", "deleteprescription"); confirmtoDelete = data.data; showPrescriptionHistory = false'>
                      <v-icon color="error">mdi-delete</v-icon>
                    </a>
                  </div>
                </template>
                <template #show-template="{ data }">
                  <div>
                    <a href="#" @click.prevent.stop="selectedPrescription(data.data); showPrescriptionHistory = false">
                      <v-icon color="primary">mdi-eye</v-icon>
                    </a>

                  </div>
                </template>
              </DxDataGrid>
            </GeneralFilter>
          </v-card>
        </v-dialog>
        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
        </alerts>

        <prescription :data="print"></prescription>
        <v-dialog v-model="showComments" scrollable :max-width="$vuetify.breakpoint.smAndDown ? '90%':'60%'">
          <v-card class="pa-4">
            <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
              <v-btn small class="btndelete" @click.prevent="showComments = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-col cols="12" md="12" style="">
              <quill-editor v-model="prescription.comment" :options="quillOptions"></quill-editor>
            </v-col>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue"
import Block from "@/components/Block"
import { DxDataGrid, DxColumn, DxExport } from "devextreme-vue/data-grid"
import DatePicker from "../../../components/DatePicker"
import Json from "../../../assets/Json/printTemplate.json"
import Alerts from "@/components/Alerts"
import Prescription from "../../../components/ReportsAndPrintings/Prescription.vue"
import { mapState } from "vuex"
import { printToExcel } from "@/helpers/printToexcel"
import GeneralFilter from "@/components/GeneralFilter"
import UserProfile from "@/components/Displays/UserProfileDisplay.vue";
import UserProfileLab from "@/components/Displays/UserProfileDisplayLab.vue";


export default {
  name: "MedicalPrescription",
  components: {
    DxDataGrid, DxColumn, DatePicker, Block, Alerts, Prescription, DxExport, UserProfile, UserProfileLab,
    GeneralFilter
  },
  props: ["data", "win",'type'],
  data: () => ({
    print: {
      show: false,
      data: []
    },
    alert: {
      type: "success",
      show: false,
      header: "",
      body: "",
    },
    confirmtoDelete: null,
    valid: false,
    search: {
    },
    url: null,
    patientinfo: [],
    prescriptions: {},
    prescription: {},
    loading: false,
    id_user_login: null,
    usenextappointment: [],
    dialogprint: false,
    searchbusiness: {},
    infobusiness: {},
    na: null,
    header: {},
    footer: {},
    template: {},
    prescriptionJSON: {},
    selectedvalues: true,
    showPrescriptionHistory: false,
    showComments: false,
    quillOptions: {
      modules: {
        toolbar: {
          container:
            [
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'align': [] }],

              ['clean'],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'font': [] }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'script': 'sub' }, { 'script': 'super' }],
              [{ 'formula': '' }]
            ],
          handlers: {}
        }
      },
      theme: 'snow'
    },
    useData: {},
  }),
  computed: {
    ...mapState(
      "rolesUser",
      {
        rolesStatus: (state) => state.roles,
      })
  },
  mounted() {
    this.prescriptionJSON = Json[0]['Prescription']
    this.$store.dispatch('rolesUser/getAllRoles')
    this.useData = JSON.parse(localStorage.getItem("user"))
    this.getHeaderFooter()
    // this.getBodyTemplate();
    this.searchbusiness.branch = JSON.parse(localStorage.getItem("user")).branch || ""
    this.searchbusiness.business = JSON.parse(localStorage.getItem("user")).businessid || ""
    this.getInfoBranch()
    if (this.data) {
      Object.keys(this.data).map((el) => {
        Vue.set(this.patientinfo, el.toLowerCase(), this.data[el])
      })
      this.url =
        this.$store.getters.getBackendUrl +
        "/public" +
        this.data.ProfilePicPath
      this.getPrescriptions()
    }

    Vue.set(this.prescription, "id_patient", this.patientinfo.id_patient)
    this.search.id_patient = this.patientinfo.id_patient
    this.id_user_login = JSON.parse(localStorage.getItem("user")).id
  },
  watch: {
    // prescriptions: {
    //     deep: true,
    //     inmediate: true,
    //     handler(newVal) {
    //         for (const item of newVal) {
    //           Object.keys(item).forEach(key =>{
    //             if(typeof(item[key]) === 'string') {
    //               const value = item[key].replace(/<[^>]+>/g, ' ');
    //               item[key] = value;
    //             }
    //             if(item[key] == 'undefined') item[key] = '';
    //           })
    //         }
    //         newVal = newVal.sort((a, b) => {
    //             const [aDay, aMonth, aYear] = a.PrescriptionDate.split('/');
    //             const aDate = new Date(aYear, aMonth - 1, aDay);
    //             const [bDay, bMonth, bYear] = b.PrescriptionDate.split('/');
    //             const bDate = new Date(bYear, bMonth - 1, bDay);
    //             return bDate - aDate;
    //         });
    //         return newVal;
    //     }
    // },
  },
  methods: {
    onExporting(e) {
      printToExcel(e, "Prescripciones")
    },

    getHeaderFooter() {

      let search = {
        ID_branch: JSON.parse(localStorage.getItem("user")).branch || "",
        ID_business: JSON.parse(localStorage.getItem("user")).businessid || ""
      }
      this.$API.HF.getHF(search).then((data) => {
        let header = [], footer = []
        header = data.filter((x) => x.State == '1' && x.Type == 'Header')
        this.header = header.length > 0 ? JSON.parse(header[0].Body).html : ''
        footer = data.filter((x) => x.State == '1' && x.Type == 'Footer')
        this.footer = footer.length > 0 ? JSON.parse(footer[0].Body).html : ''
      })
    },
    getBodyTemplate() {
      let data = {
        obj: {
          Branch_logo: this.infobusiness.BranchLogoPath,
          BusinessName: this.infobusiness.BusinessName,
          BranchName: this.infobusiness.BranchName,
          BranchAddress: this.infobusiness.BranchAddress,
          BranchPhone: this.infobusiness.BranchPhone,
          BranchEmail: this.infobusiness.BranchEmail,
          id_customer: this.patientinfo.id_customer,
          id_patient: this.patientinfo.id_patient,
          namepatient: this.patientinfo.namepatient,
          species: this.patientinfo.species,
          breed: this.patientinfo.breed,
          UserNextAppointmentLength: this.usenextappointment.length,
          next_appointment_date: this.prescription.nextappointment,
          prescription: this.prescription.prescription
        },
        type: 'Prescription'
      }
      this.$API.TM.getResolveTemplate(data).then((x) => {
        console.log(x)
        this.template = x[0]
        this.template.Body = JSON.parse(this.template.Body).html
        this.dialogprint = true
      }, (err) => {
        console.log(err)
      })
      // this.$API.TM.getTM().then((data)=>{
      //   this.template = data.filter((x)=>x.Type == 'Prescription')[0];
      // var html = this.template.Body.replace("{{Name}}", "Test1")
      // html = html.replace("@username", "alex@gmail.com")
      // html = html.replace("@password", "f1_M1@j3[I2~")
      // html = html.replace("@website", "www.google.com")
      // this.template.Body = JSON.parse(html).html
      // Object.entries(this.prescriptionJSON).map(([k]) => {
      //   this.template.Body = this.template.Body.replace(`@${k}`,
      //   k=="Branch_logo" ? this.infobusiness.BranchLogoPath
      //    : k=="BusinessName" ? this.infobusiness.BusinessName
      //     : k=="BranchName" ? this.infobusiness.BranchName
      //       : k=="BranchAddress" ? this.infobusiness.BranchAddress
      //        : k=="BranchPhone" ? this.infobusiness.BranchPhone
      //         : k=="BranchEmail" ? this.infobusiness.BranchEmail
      //           : k=="id_customer" ? this.patientinfo.id_customer
      //            : k=="id_patient" ? this.patientinfo.id_patient
      //             : k=="namepatient" ? this.patientinfo.namepatient
      //              : k=="species" ? this.patientinfo.species
      //               : k=="breed" ? this.patientinfo.breed
      //                : k=="UserNextAppointmentLength" ? this.usenextappointment.length
      //                 : k=="next_appointment_date" ? this.prescription.nextappointment
      //                  : k=="prescription" ? this.prescription.prescription
      //                   : '');
      // });
      // this.template.Body = JSON.parse(this.template.Body).html;
      // console.log(this.template.Body)
      // })
    },
    getPrescriptions() {
      this.$API.medicalprescription
        .getPatientPrescription(this.patientinfo.id_patient)
        .then((response) => {
          console.log(response)
          this.prescriptions = response
          let day = this.prescription.prescriptiondate

          response.forEach((el) => {
            if (el.PrescriptionDate == day) {

              this.selectedPrescription(el)

            }
          })

        })
    },

    selectedPrescription(data) {

      Object.keys(data).map((el) => {
        Vue.set(this.prescription, el.toLowerCase(), data[el])
        this.prescription.ID_PP = data.ID_PP
      })
      if (this.prescription.nextappointment != null) {
        this.usenextappointment.push("Appointment")
      } else {
        this.usenextappointment = []
      }
      this.selectedvalues = false
    },

    addPrescription() {

      const formdata = new FormData()
      if (this.usenextappointment.length < 1) {
        this.prescription.nextappointment = null
      }
      Vue.set(this.prescription, "id_user_insert", this.id_user_login)

      if (this.prescription.id_patient === null || this.prescription.id_patient === undefined) {
        Vue.set(this.prescription, "id_patient", this.patientinfo.id_patient)
      }

      formdata.append(
        "PatientPrescription",
        JSON.stringify(this.prescription)
      )

      this.$API.medicalprescription
        .addPatientPrescription(formdata)
        .then(() => {
          this.loading = false
          this.prescription.id_pp = ''
          this.showAlert("success", "Exito", "la prescripcion fue agregada de manera exitosa")
          this.$store.commit('vetPatientData/setReloaders', 'VeterinaryMedicalH')
          //this.$store.dispatch('reloadData', 'veterinarypatients')
          this.getPrescriptions()
        })
        .catch(() => {
          this.loading = false
          this.showAlert("danger", "Error", "No se pudo agregar la prescripcion")
        })
    },

    updatePrescription() {
      this.loading = true
      const formdata = new FormData()
      if (this.usenextappointment.length == 0) {
        this.prescription.nextappointment = null
      }
      Vue.set(this.prescription, "id_user_update", this.id_user_login)
      formdata.append(
        "PatientPrescription",
        JSON.stringify(this.prescription)
      )
      this.$API.medicalprescription
        .updatePatientPrescription(this.prescription.id_pp, formdata)
        .then(() => {
          this.loading = false
          // this.$emit('reloadData');
          this.showAlert("success", "Exito", "Se actualizo el registro al paciente")
          this.prescription.id_pp = ''
          //this.$store.dispatch('reloadData', 'veterinarypatients')
          this.getPrescriptions()
        })
        .catch(() => {
          this.loading = false
          this.showAlert("danger", "Error", "No se pudo actualizar el registro")
        })
    },

    deletePrescription(data) {
      this.loading = true
      const id = data.id_pp ? data.id_pp : data.ID_PP

      this.$API.medicalprescription
        .deletePatientPrescription(id)
        .then(() => {
          this.loading = false
          this.showAlert("success", "Exito", "Se elimino el registro al paciente")
          this.prescription.id_pp = ''
          this.getPrescriptions()
          this.selectedvalues = true
          //this.$store.dispatch('deleteWindowByUuid', {uuid: this.win.uuid});
          //this.$store.dispatch('reloadData', 'veterinarypatients')
        })
        .catch(() => {
          this.loading = false
          this.showAlert("danger", "Error", "No se pudo eliminar el registro")
        })
    },

    onEditorChange(e) {
      this.prescription.prescription = e.html
    },
    NextAppointmentFunction(e) {
      if (e[0] == "Appointment") this.usenextappointment.push("Appointment")
      else this.usenextappointment = []
    },

    Print() {
      this.$print(this.$refs.print)
    },

    getInfoBranch() {
      this.$API.branches.findSpecificBranch(this.searchbusiness)
        .then(res => {
          this.infobusiness = res[0]
        })
    },

    resetData() {
      this.prescription = {}
      this.prescription.prescriptiondate = this.moment().format("DD/MM/YYYY")
      this.prescription.nextappointment = this.moment().format("DD/MM/YYYY")
      this.selectedvalues = true
    },

    closeAlert() {
      this.alert.show = false
      if (this.alert.options == "deleteprescription") {
        this.confirmtoDelete = null
      }
    },
    acceptAlert() {
      this.alert.show = false

      if (this.alert.options == "deleteprescription") {
        this.deletePrescription(this.confirmtoDelete)
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type
      this.alert.type = type
      this.alert.header = header
      this.alert.body = body
      this.alert.show = true
      this.alert.options = options != null ? options : null
    },
    filterHandler(response) {
      console.log("se pone la response ", response)
      this.prescriptions = response
    },
  },

}
</script>

<style scoped>
.tabs {
  width: 100%;
  padding: 10px;
  background-color: #1976d2;
  color: white;
}

.tabs:hover {

  cursor: pointer;
}

.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;

}

.infopatient {
  margin-top: 5%;
}

.btnblack {
  background-color: rgba(60, 60, 60, 1) !important;
  color: white !important;

}

.btndelete {
  background-color: #E3191E !important;
  color: white;
}


.btn-add {
  background-color: #3498DB !important;
  color: white;
}

.btnsave {
  background-color: #00A178 !important;
  color: white;

}

.btnclean {
  background-color: #F29D35 !important;
  color: white;

  margin-right: .3rem;
}

.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;

  border-radius: 15px;


}

.btnblue {
  background-color: #1976d2 !important;
  color: white;
}
</style>
