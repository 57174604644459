<template>
  <div>
    <img :src="imageUrl" class="img" v-if="type.toLowerCase() != 'mp4'" />
    <video
      id="video"
      :src="url"
      type="video/mp4"
      class="video"
      controls
      v-if="type.toLowerCase() == 'mp4'"
    ></video>
  </div>
</template>

<script>
export default {
  name: "ZoomImage",
  props: ["imageUrl", "type", "ZoomDialog"],
  data() {
    return {
      url: null,
    };
  },
  mounted() {
    this.url = this.imageUrl;
  },
  watch: {
    ZoomDialog() {
      if (this.ZoomDialog == false) {
        var video = document.getElementById("video");
        video.pause();
      }
      if (this.url) this.url = null;
      else this.url = this.imageUrl;
    },
    imageUrl() {
      this.url = this.imageUrl;
    },
  },
};
</script>

<style>
.img {
  width: 100%;
  height: auto;
}
.video {
  width: 100%;
  height: auto;
}
</style>