const customerData = {
    namespaced: true,
    state: () => ({
        currentView: "tab-1",
        data: 'none',
        reloaders: {
            reloadmanagecustomer: 0,
            reloadcustomerslist: 0,
            reloadpatientofcustomer: 0,
            reloadserviceofcustomer: 0
        }
    }),
    mutations: {
        changeView(state, view){
            state.currentView = view
        },
        setData(state, data){
            state.data = data
        },
        setReloaders(state, payload){
            switch(payload) {
                case 'reloadmanagecustomer':
                    state.reloaders.reloadmanagecustomer++;
                    break;
                case 'reloadcustomerslist':
                    state.reloaders.reloadcustomerslist++;
                    break;
                case 'reloadpatientofcustomer':
                    state.reloaders.reloadpatientofcustomer++;
                    break;
                case 'reloadserviceofcustomer':
                    state.reloaders.reloadserviceofcustomer++;
                    break;
            }
        }
    },
    actions: {

    }
}

export default customerData