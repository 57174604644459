<template>
  <div>
    <block v-if="loading" />
    <v-simple-table>
      <template v-slot:default>
        <tbody class="white">
          <template v-if="roles && roles.length > 0">
            <v-card>
              <!-- Manage roles -->
              <v-row style="margin: 0.4rem">
                <v-col cols="12" md="6" style="margin-top: 0.8rem">
                  <h3 style="width: 60%"> {{ $t("Manage_user_roles") }}</h3>
                <v-spacer></v-spacer>
                </v-col>
              </v-row>
              <v-row style="margin: 0.4rem">
                <!--v-col cols="12" md="6" style="margin-top: 0.8rem">
                  <h3 style="width: 60%">{{ $t("Select_profile_def") }}</h3>
                </v-col-->
                <v-col cols="12" md="6">
                  <v-select outlined dense 
                    style="width: 60%" attach
                    v-model="predefinedrole"
                    :items="predefinedroles"
                    item-text="GroupRoleName"
                    item-value="GroupRoleName"
                    label="Seleccionar Perfil Existente"
                    @change="selectPredefinedRole()"
                  ></v-select>
                </v-col>
              </v-row>
              <!-- Manage roles -->
           
              <!-- Create role -->
              <v-row style="margin: 0.4rem">
                <v-col cols="12" md="6" style="margin-top: 0.8rem">
                  <h3 style="width: 60%"> {{ $t("Create_user_roles") }}</h3>
                <v-spacer></v-spacer>
                </v-col>
              </v-row>
              <v-row
                align="center"
                justify="space-between"
                style="margin: 0.4rem"
              >
                <!--v-col cols="5" md="0" style="margin-top: 0.8rem">
                  <h3 style="width: 60%">Guardar Perfil</h3>
                </v-col-->
                <v-col md="4">
                  <v-text-field outlined dense 
                    style="width: 92%"
                    v-model="customRole"
                    label="Nuevo Perfil"
                  ></v-text-field>
                  <v-btn color="primary" @click="saveRole"
                    ><v-icon color="white" class="mr-3">mdi-account-plus</v-icon> Guardar nuevo perfil</v-btn>
                </v-col>
                <v-col cols="0" md="0" class="text-md-right mr-5">
                </v-col>
                <v-row v-if="showSuccessMessage" justify="center">
                  <v-col md="12">
                    <div class="success-message">{{ successMessage }}</div>
                  </v-col>
                </v-row>
              </v-row>
              <!-- Create role -->

              <!-- Allow roles -->
              <v-row style="margin: 0.4rem" class="mt-3">
                <v-col cols="12" md="12" style="margin-top: 0.8rem" class="mt-5">
                  <h3 style="width: 60%"> {{ $t("Search_user_roles") }}</h3>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch
                    :label="allRolesSelected ? `Deseleccionar todos los roles` : `Seleccionar todos los roles`"
                    v-model="allRolesSelected"
                    @change="selectAllRoles(true)"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6" class="text-md-right">
                  <v-btn color="primary" @click="expandTable"
                    ><v-icon class="btn-add mr-3">mdi-arrow-expand-all</v-icon> {{ displayAll ? 'Contraer' : 'Expandir' }} tabla
                  </v-btn>
                </v-col>
              </v-row>
              <v-card-title>
               
                <v-text-field outlined dense 
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar..."
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>

              <v-data-table
                :headers="headers"
                :expanded="[]"
                :items="roles"
                ref="rolesTable"
                :search="search"
                item-key="ID_Role"
                sort-by="RoleName"
                group-by="category"      
                :expand="false"         
                :items-per-page="1000"
                :hide-default-footer="true"
                class="elevation-1"
              >
              <!-- Example -->
              <template v-slot:group.header="{items, isOpen,toggle}">
                <th colspan="3">
                  <v-icon @click="toggle"
                    >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                  </v-icon>
                  {{ items[0].category }}
                </th>
              </template>

              <template v-slot:[`item.active`]="{ item }">
                <v-switch
                v-model="item.active"
                @change="activeRole(item)"
                ></v-switch>
              </template>
       
          
              </v-data-table>
          <!-- Allow roles -->
            </v-card>
          </template>
          <tr v-else class="text-center">
           <div class="white" style="height: 400px;">

           </div>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    >
    </alerts>
  </div>
</template>
  
  <script>
import Vue from "vue";
import Block from '@/components/Block';
import { translateText } from "../../../../helpers/Interpreter/Roles";
import Alerts from "@/components/Alerts.vue";
import {
        VUE_APP_CARSEM_ID_KEYMASTER1,
        VUE_APP_CARSEM_ID_KEYMASTER2,
        VUE_APP_CARSEM_ID_KEYMASTER3
    } from "@/config";

export default {
  props: ["user"],
  components: {
    Alerts,
    Block
  },
  data() {
    return {
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      displayAll: false,
      showSuccessMessage: false,
      id_user_login: null,
      roles: [],
      busssniesName: "",
      predefinedrole: null,
      showModal: false,
      form: {},
      search: "",
      customRole: "",
      allRolesSelected: 0,
      loading: false,
      categorys: [
        "Generate Invoice",
      //  "Invoice History",
        "Ready to Bill",
        "Manage Customer",
        "Manage Patients",
        "Patients",
        "Billed Services",
        "Billing History",
   //     "Credits",
        "Laboratory Test",
        "Notifications",
        "Advertisement",
        "Manage Providers",
        "Manage Products",
        "Manage Services",
        "Manage Transfers",
        "Transfer Request",
        "Dashboard",
        "Quotation",
        "Billing Template",
        "Accounts Payable",
        "History of Withholding Vouchers",
        "Products Of A Supplier",
        "Cut Box",
        "Add Customer",
        "Search Customer",
        "Accounts Receivable",
        "Customer Patient",
        "Warehouse",
        "Add Providers",
        "Search Provider",
        "Purchase Order",
        "Add Products",
        "Search Products",
        "Add Transfers Request",
        "Add Transfers",
        "Search Transfers Request",
        "Search Transfers",
        "Search Patient",
        "Grooming",
        "Medical History",
        "Assignment of Accounts Products",
        "Assignment of Accounts Services",
        "Assignment of Accounts General Parameters",
        "Add Accounting Entry",
        "Accounting Search Entry",
        "Expense Management",
        "Expense Providers",
        "Expense Entry",
        "Expense History Entry",
        "Allow Accounting Module",
        "Prescription",
        "Medications",
        "Surgery",
        "Breeds",
        "Documents",
        "Medical Reports",
        "Register visit",
        "Management Report",
        "Credit Config",
        "Modulo Reportes",
        "Lista De Precios",
      ],
      isCarsem: false,
      headers: [
        {
          //text: this.$t("Feature") ,
          align: "start",
          value: "RoleName",
          groupable: false,
         
        },
        {  key: 'data-table-group'},
        { text: this.$t("Capabilities"), value: "active", align: "left" },
        { text: this.$t("Category"), value: "category", align: "right" },
      ],
    };
  },
  computed: {
    isTestBusiness(){
     return (JSON.parse(localStorage.getItem('branch')).ID_Business == '17' || JSON.parse(localStorage.getItem('branch')).ID_Business == '41') ? true : false;
    }
  },
  mounted() {
    this.verifyCarsem();
    this.id_user_login = JSON.parse(localStorage.getItem("user")).id;
    this.busssniesName = JSON.parse(localStorage.getItem("user")).businesstype;
    
    if (this.user) {
      Object.keys(this.user).map((el) => {
        if (el === "ID_User") {
          Vue.set(this.form, el, this.user[el]);
        }

        if (el === "RolesGrid") {
          Vue.set(this.form, "roles", this.user[el]);
        }
      });
    }

    this.setupRoles();
    this.getPredefinedRole();

    

   
    
  },
  methods: {
    getCookie(cname) {
                let name = cname + "=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(";");
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == " ") {
                    c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                    }
                }
                return "";
    },
    verifyCarsem(){
                const keyMastersArray = JSON.parse( this.getCookie( "ID_KeyMaster" ) );
                const specialKeyMasters = keyMastersArray.includes( VUE_APP_CARSEM_ID_KEYMASTER1 )  || keyMastersArray.includes( VUE_APP_CARSEM_ID_KEYMASTER2 ) || keyMastersArray.includes( VUE_APP_CARSEM_ID_KEYMASTER3 );
                console.log('',specialKeyMasters);
                if(specialKeyMasters){
                    this.isCarsem = true;
                    this.key++;
                }
                else{
                    this.isCarsem = false;
                }
            },
    async setupRoles(){
      this.loading = true;
      try{
        if(this.user.PredefinedRoleGroup != null || this.user.PredefinedRoleGroup != undefined) {
          this.predefinedrole = this.user.PredefinedRoleGroup;

          this.getAllRoles();
          await this.getUserRoles()
          //this.selectPredefinedRole();
        }
        else{
          const [roles,userRoles] = await Promise.all([
            this.$API.roles.getAllRoles(),
            this.$API.roles.getUserRole(this.form.ID_User)
          ]);
          this.assignRoleUser(userRoles);
          // console.log( 'from setup' );
          this.translateResponseRoles(roles);

        }
       
        this.loading = false;
      }
      catch(error){
        this.loading = false;
        console.error(error);
      }
      
    },
   
    getAllRoles(){
      this.$API.roles.getAllRoles().then((response) => {
        // console.log( 'from getAllRoles', response );
        this.assignRoleUser( response );
        this.translateResponseRoles(response);     
        });
    },
    translateResponseRoles(response) {
      response = response.filter(role => ![28, 29, 30, 31].includes(role.ID_Role));
      if(this.busssniesName != "veterinary"){
            response = response.filter(role => role.BusinessType ? role.BusinessType.includes("Veterinary") === false : true);
           
        }
        if(this.busssniesName != "bloodtestlaboratory"){
          response = response.filter(role => role.BusinessType ? role.BusinessType.includes("bloodtestlaboratory") === false : true);   
          
        }
        if(this.busssniesName == "accounting_and_bill"){
          response = response.filter(role => !role.RoleName.includes("Patient") );
          response = response.filter(role => ![ 32, 33].includes(role.ID_Role));

        } 
        if(!this.isCarsem && !this.isTestBusiness ){
          response = response.filter(role => role.RoleName ? role.RoleName.includes("Carsem") === false : true);
        }
        for (let i = 0; i < response.length; i++) {
          if (
            response[i]["RoleName"] !== "root" &&
            response[i]["RoleName"] !== "admin" &&
            response[i]["RoleName"] !== "user"
          ) {
            let entry1 = true;
            let entry2 = true;
            
            console.log( 'this.form.roles ', this.form.roles )

            for (let j = 0; j < this.form.roles.length; j++) {
              // console.log(this.form.roles[j]["active"], this.form.roles[j]["active"]);
              
              if (this.form.roles[j]["ID_Role"] === response[i]["ID_Role"]) {
                response[i]["active"] = 1;
                entry1 = false;
              }
            }

            for (let k = 0; k < this.categorys.length; k++) {
              // if( response[i]["RoleName"].includes('Generate Invoice') ) {
              //   console.log( response[ i ] );
              // }
              if (response[i]["RoleName"].indexOf(this.categorys[k]) === 0) {

                response[i]["RoleName"] = this.$t(translateText(response[i]["RoleName"]));
               
                response[i]["category"] = this.categorys[k];
                response[i]["category"] = this.interpreter(
                  response[i]["category"]
                );
                response[i]["category"] =  this.$t(response[i]["category"]);

                entry2 = false;
              }
            
              if(response[i]["RoleName"].startsWith("dashboard")) {
                
                response[i]["RoleName"] =  this.translateDashboardView(response[i]["RoleName"]);
                response[i]["category"] = "Dashboard";
                entry2 = false;
              }
            }


            if (entry1) {
              response[i]["active"] = 0;
            }

            if (entry2) {
              response[i]["category"] = "Sin Grupo Asignado";
            }
            
          

              this.roles.push(response[i]);
            
          }
        }
       
        if(this.busssniesName == "accounting_and_bill"){
          this.roles = this.roles.filter(role => role.category != "Prueba de laboratorio");
          this.roles = this.roles.filter(role => role.category != "Anuncio");
          this.roles = this.roles.filter(role => role.category != "Administrar pacientes");
          this.roles = this.roles.filter(role => role.category != "Administrar clientes");
        }

        const keyMastersArray = JSON.parse( this.getCookie( "ID_KeyMaster" ) );
        const specialKeyMasters = keyMastersArray.includes( VUE_APP_CARSEM_ID_KEYMASTER1 )  || keyMastersArray.includes( VUE_APP_CARSEM_ID_KEYMASTER2 ) || keyMastersArray.includes( VUE_APP_CARSEM_ID_KEYMASTER3 );
        if(!specialKeyMasters){
          this.roles = this.roles.filter(role => role.category != "Reportes Administrativos");  
        }

        setTimeout(()=>{
          for (let key in this.$refs.rolesTable.openCache) {
            this.$refs.rolesTable.openCache[key] =  false;
          }
     },10)
    },
    
    getUserRoles(){
      this.$API.roles.getUserRole(this.form.ID_User).then((response) => {
        this.assignRoleUser(response);

      });
    },
    assignRoleUser(response){
      this.form.roles = response;

        this.roles = this.roles.map((role) => {
          role.active = 0;
          for (let i = 0; i < this.form.roles.length; i++) {
            if (role.ID_Role === this.form.roles[i].ID_Role) {
              role.active = 1;
            }
          }
          return role;
        });
        const rolesInZero = this.roles.filter(current => current.active === 0);
        if(rolesInZero.length === 0) {
          this.allRolesSelected = true;
        } else {
          this.allRolesSelected = false;
        }
    },
    translateDashboardView(viewId) {
      switch (viewId) {
          case 'dashboard view all clients':
              return 'Vista del dashboard de todos los clientes';
          case 'dashboard view 7 days billing':
              return 'Vista del dashboard de facturación de 7 días';
          case 'dashboard view 7 new customers':
              return 'Vista del dashboard de 7 nuevos clientes';
          case 'dashboard view month billing':
              return 'Vista del dashboard de facturación mensual';
          case 'dashboard view all patients':
              return 'Vista del dashboard de todos los pacientes';
          case 'dashboard view 7 days patients':
              return 'Vista del dashboard de pacientes de 7 días';
          case 'dashboard view patient chart Veterinary':
              return 'Vista del dashboard de actualmente en clinica';
          case 'dashboard view donut product chart':
              return 'Vista del dashboard del gráfico de productos tipo donut';
          case 'dashboard view warehouses products':
              return 'Vista del dashboard de productos de almacenes';
          case 'dashboard view Scatter year':
              return 'Vista del dashboard de dispersión anual';
          case 'dashboard view Scatter week':
              return 'Vista del dashboard de dispersión semanal';
          case 'dashboard view top product sell':
              return 'Vista del dashboard de los productos más vendidos';
          case 'dashboard view top customers':
              return 'Vista del dashboard de los mejores clientes';
          default:
              return 'Vista del dashboard no reconocida';
      }
  },
    async selectAllRoles(btn = false){

      try {
        if(!btn) return;
       // this.allRolesSelected = !this.allRolesSelected;
       this.loading = true;
        const roleToSave = this.roles.map(role => {
          role.active = this.allRolesSelected ? 1 : 0;
          return role;
        });

        await this.$API.roles.setAllRoles({
          params : {
            ID_User: this.form.ID_User,
            addAllRoles: this.allRolesSelected
          },
          rolesList: roleToSave
        });
        this.showAlert("success", "Exito", "Roles guardados con exito");
        this.loading = false;
      }
      catch(error) {
        this.loading = false;
        console.error(error);
      }
      /* for(const item in this.roles){
        this.roles[item].active = this.allRolesSelected ? 1 : 0;
        this.activeRole(this.roles[item], true);
      } */
    },  
    saveRole() {
      const activeRoles = this.roles.filter(role => role.active === 1);
      const data = {
        groupRoleName: this.customRole,
        roles: activeRoles
      };

     
      this.loading = true
      return this.$API.roles
        .saveRole(data)
        .then((response) => {
         
         
          this.customRole = "";
          this.showAlert("success", "Exito", "Roles guardados con exito");
          this.getPredefinedRole();
          return response.data;
        })
        .catch((error) => {
          console.info("----", error.data);
          throw error.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeAlert() {
      this.alert.show = false;
    },
    showAlert(type, header, body, options = null) {
      type = type === null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options !== null ? options : null;
    },
    translateNames(data) {
      for (data of data) {
        data["RoleName"] = this.$t(data["RoleName"]);
      }
      return data;
    },
    async activeRole(data, fromToggle = false) {
      data.active = data.active ? 1 : 0;
      data.ID_User_Insert = this.id_user_login;
      data.ID_User = this.form.ID_User;
      
      const formdata = new FormData();
      formdata.append("form", JSON.stringify(data));
      
      this.$API.roles.assignRole(formdata);
      
      if(!fromToggle) {
        this.watchRoles();
      } 
      
      this.predefinedrole = null;
      // console.log(this.predefinedrole);
      await this.updateGroup();
      this.$store.dispatch("reloadData", "users");
    },
    expandTable(){
      console.log(this.$refs.rolesTable);
      for (let key in this.$refs.rolesTable.openCache) {
        this.$refs.rolesTable.openCache[key] =  !this.displayAll;
      }
      this.displayAll = !this.displayAll;
    },
    activeRoleFromGroup(data) {
      data.active = data.active ? 1 : 0;
      data.ID_User_Insert = this.id_user_login;
      data.ID_User = this.form.ID_User;
      
      const formdata = new FormData();
      formdata.append("form", JSON.stringify(data));
      
      this.$API.roles.assignRole(formdata);
      this.watchRoles();
      this.$store.dispatch("reloadData", "users");
    },
    watchRoles(){
      const rolesInZero = this.roles.filter(c => c.active === 0)
      if(rolesInZero.length > 0) {
        this.allRolesSelected = false;
      } else {
        this.allRolesSelected = true;
      }
    },
    getPredefinedRole(){
      this.$API.roles.getGroups()
        .then((data) => {
          this.predefinedroles = data
        }).catch((error) => {
          console.log(error)
        });
    },
    async selectPredefinedRole() {
      try {
        this.loading = true;
        const { branch: ID_Branch } = JSON.parse(localStorage.getItem('user'));
  
        await this.updateGroup()
  
        await this.$API.roles.assignFromRolesGroupName( { GroupRoleName: this.predefinedrole, ID_User: this.user.ID_User, ID_Branch } );
        //await this.setupRoles();
        await this.getUserRoles()
        
        this.showAlert("success", "Exito", "Perfil asignado con éxito");
      } catch ( error ) {
        console.log( error )
        this.showAlert("danger", "Error", `Ocurrió el siguiente error al asignar el perfil seleccionado ${ error }`);
      } finally {
        this.loading = false;
      }

      // this.$API.roles
      // .getRolesGroupName({ GroupRoleName: this.predefinedrole, ID_Branch, ID_Business })
      // .then((data) => {
      //   for (let i = 0; i < this.roles.length; i++) {

      //     if (data.some((item) => item.ID_Role === this.roles[i].ID_Role)) {
      //       this.roles[i].active = 1;
      //       this.activeRoleFromGroup(this.roles[i]);
      //     } else {
      //       this.roles[i].active = 0;
      //       this.activeRoleFromGroup(this.roles[i]);
      //     }
      //   }
      // })
      // .catch((error) => {
      //   console.error(error);
      // });
  },
    async updateGroup(){
      let userData = new FormData();
      userData.append('user', JSON.stringify({ predefinedrolegroup: this.predefinedrole }));
      return await this.$API.users.updateUser(this.user.ID_User, userData);
    },
    interpreter(value) {
      return value.replace(/ /g, "_");
    },
  },
};
</script>
  
  <style>
td.text-start button:nth-child(2) {
  display: none !important;
}
.success-message {
  margin-top: 0.5rem;
  color: green;
}
</style>
  