var render = function render(){var _vm=this,_c=_vm._self._c;return _c('window-portal',{attrs:{"open":_vm.dataRegular.show},on:{"close":() => {
      _vm.dataRegular.show = false;
      if (_vm.dataRegular?.closeCallback) {
        _vm.dataRegular?.closeCallback();
      }
      _vm.dataTicket.show = false;
      if (_vm.dataTicket?.closeCallback) {
        _vm.dataTicket?.closeCallback();
      }
    }}},[(_vm.type === 'regular')?_c('print-pos-factura-regular',{attrs:{"dataProps":_vm.dataRegular,"hideQr":_vm.hideQr},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}):(_vm.type === 'ticket')?_c('print-pos-factura-ticket',{attrs:{"dataProps":_vm.dataTicket,"hideQr":_vm.hideQr},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }