<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="mt-2">
      ALMACENES
      <template v-slot:actions>
        <v-btn class="mr-5" @click.native.stop="reload()" icon small>
          <v-icon color="primary">mdi-reload</v-icon>
        </v-btn>
        <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card>
        <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
          <v-tabs-slider color="red"></v-tabs-slider>
          <v-tab href="#tab-1">
            Almacenes
            <v-icon>mdi-account-search</v-icon>
          </v-tab>
        </v-tabs>
        <block v-if="loading"></block>
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <v-card
              rounded="lg"
              v-if="
                $store.getters['rolesUser/getCustomRole'](91)
              "
              flat
              class="white pa-5 ma-1 add-warehouse"
            >
              <v-form v-model="valid">
                <v-row align="center" class="mt-3">
                  <v-col cols="12" sm="6" md="8" lg="8" xl="8" >
                    <v-text-field
                      outlined
                      dense
                      v-model="form.Warehouse"
                      label="Agregar Almacén"
                      placeholder="Nombre del Almacén"
                      :rules="[
                        (v) => !!v || 'El nombre del almacén es requerido',
                      ]"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3" md="2" lg="2" xl="2">
     
      <v-checkbox
        v-model="form.IsGlobalBranch"
        label="Mostrar en todas las sucursales"
        true-value="1"
        false-value="0"
      ></v-checkbox>
    </v-col>
                  <v-col cols="6" sm="3" md="2" lg="2" xl="2" class="text-center d-flex justify-center align-self-start">
                      <v-btn 
                      v-if="$store.getters['rolesUser/getCustomRole'](91)"
                        @click.prevent="addWarehouse()"
                        class="btnsave"
                        :disabled="!valid"
                        >Agregar Almacén</v-btn
                      >
                  </v-col>
                  
                </v-row>
              </v-form>
            </v-card>

            <v-card
              v-if="
                $store.getters['rolesUser/getCustomRole'](90)"
              flat
              class="pa-1 position-relative"
            >
              <div class="d-flex filter-btn">
                <v-bottom-sheet v-model="showFilter" inset>
                  <!--template v-slot:activator="{ on, attrs }">
                                                        <v-btn color="orange" dark v-bind="attrs" v-on="on">FILTROS
                                                        </v-btn>
                                                    </template-->
                  <v-sheet class="text-center">
                    <v-btn
                      class="mt-6"
                      text
                      color="error"
                      @click="showFilter = !showFilter"
                      >Cerrar
                    </v-btn>
                    <v-btn class="mt-6" text color="primary">Filtros</v-btn>
                    <div class="my-3 pa-4">
                      <div>
                        <date-picker label="From Date"></date-picker>
                        <date-picker label="To Date"></date-picker>
                      </div>
                    </div>
                  </v-sheet>
                </v-bottom-sheet>
              </div>
              <GeneralFilter
                class="background border-box"
                :filterEndpoint="$API.warehouse.getWarehousesFiltered"
                :search="search"
                @emptyFilter="getAllWarehouses"
                @filtered="filterHandlerWarehouse"
              >
                <DxDataGrid class="isScrolledByGeneralFilter"
                  :data-source="warehouses"
                  key-expr="ID_Warehouse"
                  :show-row-lines="showRowLines"
                  :repaint-changes-only="true"
                  @exporting="onExporting"
                  :show-borders="showBorders"
                  :row-alternation-enabled="rowAlternationEnabled"
                >
                  <DxExport :enabled="true" />

                  <DxScrolling row-rendering-mode="virtual" />
                  <DxPaging :page-size="10" />

                  <DxColumn
                    :width="100"
                    v-if="$store.getters['rolesUser/getCustomRole'](92)"

                    cell-template="show-template"
                    caption="Ver productos"
                  ></DxColumn>

                  <DxColumn
                    data-field="ID_Warehouse"
                    caption="ID del Almacén"
                  ></DxColumn>
                  <DxColumn
                    data-field="Warehouse"
                    caption="Nombre de Almacén"
                  ></DxColumn>
                  <DxColumn
                  v-if="$store.getters['rolesUser/getCustomRole'](93)"

                    cell-template="IsGlobalBranch-show"
                    caption="Todas las sucursales"
                  ></DxColumn>
                  <DxColumn
                  v-if="$store.getters['rolesUser/getCustomRole'](93)"

                    cell-template="actives-show"
                    caption="Principal"
                  ></DxColumn>
                  <DxColumn
                    v-if="$store.getters['rolesUser/getCustomRole'](93) && (typebusiness == 'veterinary' || typebusiness == 'medicalclinic')"
                    cell-template="HospitalFlag-show"
                    caption="Hospital"
                  ></DxColumn>
                  <DxColumn
                    v-if="$store.getters['rolesUser/getCustomRole'](93) && (typebusiness == 'veterinary' || typebusiness == 'medicalclinic')"
                    cell-template="PatientFlag-show"
                    caption="Pacientes"
                  ></DxColumn>
                  <DxColumn
                    v-if="$store.getters['rolesUser/getCustomRole'](94)"

                    cell-template="add-template"
                    caption="Acciones"
                  ></DxColumn>
                  <DxColumn
                    v-if="$store.getters['rolesUser/getCustomRole'](94)"
                    :width="100"
                    cell-template="delete-template"
                    caption="Eliminar"
                  ></DxColumn>

                  <template #delete-template="{ data }">
                    <a href="#" class="mx-1" @click="confirmDelete(data.data.ID_Warehouse)">
                      <v-icon color="error">mdi-delete</v-icon>
                    </a>
                  </template>
                  <template #show-template="{ data }">
                    <a
                      class="mx-1"
                      @click="getWarehouseProducts(data.data.ID_Warehouse)"
                    >
                      <v-icon color="primary">mdi-eye</v-icon>
                    </a>
                  </template>
                  <template #add-template="{ data }">
                    <DxButton
                      :width="300"
                      text="Agregar productos"
                      type="default"
                      styling-mode="contained"
                      :elementAttr="{
                        style: 'background-color: #00A178 !important;',
                      }"
                      @click="
                        $store.dispatch('addWindow', {
                          name: 'addProduct',
                          component: 'addProduct',
                          unique: true,
                          meta: { warehouse: data.data.ID_Warehouse },
                        })
                      "
                    />
                  </template>
                  <template #actives-show="{ data }">
                    <div class="d-flex justify-center align-center">
                      <v-checkbox
                        v-model="data.data.Active"
                        @change="handleChanged(data.data)"
                      ></v-checkbox>
                    </div>
                  </template>
                  <template #IsGlobalBranch-show="{ data }">
                    <div class="d-flex justify-center align-center">
                      <v-checkbox
                        v-model="data.data.IsGlobalBranch"
                        @change="handleChangedGlobal(data.data)"
                      ></v-checkbox>
                    </div>
                  </template>
                  <template #HospitalFlag-show="{ data }">
                    <div class="d-flex justify-center align-center">
                      <v-checkbox
                        v-model="data.data.HospitalFlag"
                        @change="handleChangedHospitalFlag(data.data)"
                      ></v-checkbox>
                    </div>
                  </template>
                  <template #PatientFlag-show="{ data }">
                    <div class="d-flex justify-center align-center">
                      <v-checkbox
                        v-model="data.data.PatientFlag"
                        @change="handleChangedPatientFlag(data.data)"
                      ></v-checkbox>
                    </div>
                  </template>
                  <!--   <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click="
                            $store.dispatch('addWindow', {
                              name: 'ManageWarehouse',
                              component: 'ManageWarehouse',
                              unique: false,
                              meta: { warehouse: data.data }
                            })
                          ">
                            <v-icon color="primary">mdi-eye</v-icon>
                          </a>
                        </template> -->
                </DxDataGrid>
              </GeneralFilter>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <v-card flat class="pa-1 position-relative">
          <div v-if="showProducts"> 
            <GeneralFilter
              class="background border-box"
              :filterEndpoint="$API.products.getProductsWarehouseFiltered"
              :search="search"
              @emptyFilter="getWarehouseProducts"
              @filtered="filterHandlerProducts"
            >
              <DxDataGrid class="isScrolledByGeneralFilter"
                :data-source="products"
                key-expr="ID_Item"
                :show-row-lines="showRowLines"
                @exporting="onExportingP"
                :show-borders="showBorders"
                :row-alternation-enabled="rowAlternationEnabled"
              >
                <DxExport :enabled="true" />

                <DxScrolling row-rendering-mode="virtual" />
                <DxPaging :page-size="10" />
                <DxColumn data-field="ID_Item" caption="ID"></DxColumn>
                <DxColumn data-field="ItemName" caption="Nombre"></DxColumn>
                <DxColumn data-field="ItemType" caption="Tipo"></DxColumn>
                <DxColumn data-field="ItemPrice" caption="Precio"></DxColumn>

                <!--   <template #show-template="{ data }">
                        <a href="#" class="mx-1" @click="
                          $store.dispatch('addWindow', {
                            name: 'ManageWarehouse',
                            component: 'ManageWarehouse',
                            unique: false,
                            meta: { warehouse: data.data }
                          })
                        ">
                          <v-icon color="primary">mdi-eye</v-icon>
                        </a>
                      </template> -->
              </DxDataGrid>
            </GeneralFilter>
          </div>
        </v-card>
      </v-card>
    </v-expansion-panel-content>
    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    >
    </alerts>
  </v-expansion-panel>
</template>

<script>
import GeneralFilter from "@/components/GeneralFilter";
import DatePicker from "../../../components/DatePicker";
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxPaging,
  DxExport,
} from "devextreme-vue/data-grid";
import { mapGetters, mapState } from "vuex";
import Alerts from "@/components/Alerts";
import DxButton from "devextreme-vue/button";
import Block from "@/components/Block";
import { printToExcel } from "@/helpers/printToexcel";

export default {
  name: "Warehouses",
  components: {
    DatePicker,
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxPaging,
    Alerts,
    DxButton,
    Block,
    DxExport,
    GeneralFilter,
  },
  props: ["win"],
  data() {
    return {
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      typebusiness: JSON.parse(localStorage.getItem("user")).businesstype || "",
      tab: null,
      valid: false,
      form: {},
      showFilter: false,
      warehouses: [],
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      filterWarehouses: {},
      products: [],
      showProducts: false,
      loading: false,
      search: {
        filter: null,
      },
      warehouseId: null,
      selectedWarehouse: null,
    };
  },
  computed: {
    ...mapGetters(["reloadData"]),
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
  },
  watch: {
    reloadData: {
      immediate: true,
      handler: "reloadWarehouses",
    },
  },
  mounted() {
    this.$store.dispatch("rolesUser/getAllRoles");
    let idbusiness = JSON.parse(localStorage.getItem("user")).businessid || "";
    let idbranch = JSON.parse(localStorage.getItem("user")).branch || "";
    this.filterWarehouses.branch = idbranch;
    this.filterWarehouses.business = idbusiness;
    this.form.ID_Branch = idbranch;
    this.form.ID_Business = idbusiness;
   

    this.getAllWarehouses();
  },
  methods: {
    onExporting(e) {
      printToExcel(e, "Almacenes");
    },
    onExportingP(e) {
      printToExcel(e, "Productos de Almacén");
    },
    handleChanged(warehouseSelected) {
      if (!warehouseSelected.Active) {
        this.showAlert(
          "warning",
          "AccionNoPermitida",
          "No se puede desactivar el almacén, debe haber al menos un almacén activo"
        );
        this.warehouses = this.warehouses.map((warehouse) => {
          if (warehouse.ID_Warehouse == warehouseSelected.ID_Warehouse) {
            warehouse.Active = true;
          }
          return warehouse;
        });
      }
      this.loading = true;
      let data = {
        id: warehouseSelected.ID_Warehouse,
        active: warehouseSelected.Active,
      };
      this.$API.warehouse
        .changueWarehouseSelected(data)
        .then(() => {
          this.$store.commit("setChangueWarehouse", true);
          this.getAllWarehouses();
          this.loading = false;
          this.showAlert(
            "success",
            "Almacén actualizado",
            "El almacén se actualizó de forma correcta"
          );
        })
        .catch(() => {
          this.loading = false;
          this.showAlert(
            "danger",
            "Error",
            "No se pudo actualizar el almacén de forma correcta"
          );
          this.warehouses = this.warehouses.map((warehouse) => {
            if (warehouse.ID_Warehouse == warehouseSelected.ID_Warehouse) {
              warehouse.Active = true;
            }
            return warehouse;
          });
        });
    },
    handleChangedGlobal(warehouseSelected) {
      this.loading = true;
      let data = {
        id: warehouseSelected.ID_Warehouse,
        IsGlobalBranch: warehouseSelected.IsGlobalBranch,
      };
      this.$API.warehouse
        .updateWarehouseSelected(data)
        .then(() => {
          this.$store.commit("setChangueWarehouse", true);
          this.getAllWarehouses();
          this.loading = false;
          this.showAlert(
            "success",
            "Almacén actualizado",
            "El almacén se actualizó de forma correcta"
          );
        })
        .catch(() => {
          this.loading = false;
          this.showAlert(
            "danger",
            "Error",
            "No se pudo actualizar el almacén de forma correcta"
          );
          this.warehouses = this.warehouses.map((warehouse) => {
            if (warehouse.ID_Warehouse == warehouseSelected.ID_Warehouse) {
              warehouse.Active = true;
            }
            return warehouse;
          });
        });
    },
    handleChangedHospitalFlag(warehouseSelected) {
      this.loading = true;
      let data = {
        id: warehouseSelected.ID_Warehouse,
        HospitalFlag: warehouseSelected.HospitalFlag ? 1 : 0,
      };
      console.log(data);
      this.$API.warehouse.updateHospitalWarehouse(data)
        .then(() => {
          this.$store.commit("setChangueWarehouse", true);
          this.getAllWarehouses();
          this.loading = false;
          this.showAlert(
            "success",
            "Almacén actualizado",
            "El almacén se actualizó de forma correcta."
          );
        })
        .catch(() => {
          this.loading = false;
          this.showAlert(
            "danger",
            "Error",
            "No se pudo actualizar el almacén de forma correcta"
          );
          this.warehouses = this.warehouses.map((warehouse) => {
            if (warehouse.ID_Warehouse == warehouseSelected.ID_Warehouse) {
              warehouse.Active = true;
            }
            return warehouse;
          });
        });
    },
    handleChangedPatientFlag(warehouseSelected) {
      this.loading = true;
      let data = {
        id: warehouseSelected.ID_Warehouse,
        PatientFlag: warehouseSelected.PatientFlag ? 1 : 0,
      };
      console.log(data);
      this.$API.warehouse.updatePatientWarehouse(data)
        .then(() => {
          this.$store.commit("setChangueWarehouse", true);
          this.getAllWarehouses();
          this.loading = false;
          this.showAlert(
            "success",
            "Almacén actualizado",
            "El almacén se actualizó de forma correcta."
          );
        })
        .catch(() => {
          this.loading = false;
          this.showAlert(
            "danger",
            "Error",
            "No se pudo actualizar el almacén de forma correcta"
          );
          this.warehouses = this.warehouses.map((warehouse) => {
            if (warehouse.ID_Warehouse == warehouseSelected.ID_Warehouse) {
              warehouse.Active = true;
            }
            return warehouse;
          });
        });
    },
    confirmDelete(data){
      this.selectedWarehouse = data;
      this.showAlert('question','Eliminar' ,'¿Está seguro de eliminar este almacén?', 'deleteWarehouse');
    },
    deleteWarehouse() {
      console.log(this.selectedWarehouse);
      this.$API.warehouse.logicalDeleteWarehouse(this.selectedWarehouse)
        .then((res) => {
          console.log(res);
          this.showAlert("success", "Almacén eliminado", "El almacén ha sido eliminado correctamente");
          this.reload();
        })
        .catch((err) => {
          console.log(err);
          this.showAlert("danger", "Error", "Ocurrio un error al eliminar el almacén");
        });
    },
    closeAlert() {
      this.alert.show = false;
    },
    acceptAlert() {
      this.alert.show = false;
      if (this.alert.options == "deleteWarehouse") {
        this.deleteWarehouse();
      }
    },
    getWarehouseProducts(warehouseId) {
      if (warehouseId != null) {
        this.warehouseId = warehouseId;
        this.search.warehouse = warehouseId;
      }
      console.log(this.warehouseId, warehouseId)
      let user = JSON.parse(localStorage.getItem("user"));
      let data = {
        id: warehouseId || this.warehouseId,
        branch: user.branch,
        business: user.businessid,
      };
      this.showProducts = true;
      this.$API.products.getProductsWarehouseHistory(data).then((response) => {
        this.products = response;
      });
    },
    reload() {
      this.selectedWarehouse = null;
      this.getAllWarehouses();
      setTimeout(() => {
        window.scrollTo(0, this.$store.getters.getScrollTop);
        //alert(this.$store.getters.getScrollTop)
      }, 300);
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    getAllWarehouses() {
      this.$API.warehouse
        .getWarehousesHistory(this.filterWarehouses)
        .then((response) => {
          console.log(response);
          this.warehouses = response;
        });
    },
    addWarehouse() {
      this.form.ID_User_Insert =
        JSON.parse(localStorage.getItem("user")).id || "";

      this.$API.warehouse
        .addWarehouse(this.form)
        .then(() => {
          this.showAlert(
            "success",
            "Almacén agregado",
            "El almacén se agregó de forma correcta"
          );
          this.getAllWarehouses();
        })
        .catch(() => {
          this.showAlert(
            "danger",
            "Error",
            "No se pudo ingresar el almacén de forma correcta"
          );
        });
    },
    async reloadWarehouses() {
      if (this.reloadData == "warehouses") {
        this.$store.dispatch("reloadData", null);
      }
    },
    filterHandlerWarehouse(response) {
      this.warehouses = response;
    },
    filterHandlerProducts(response) {
      this.products = response;
    },
  },
};
</script>

<style scoped>
.show .v-list-item {
  min-height: auto !important;
}

.filter-btn {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 9;
}

.add-warehouse {
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 20px;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.border-box {
  border-radius: 15px !important;
}
.background {
  background: white !important;
  color: white;
}
</style>
