<template>
  <window-portal
    :open="dataRegular.show"
    @close="
      () => {
        dataRegular.show = false;
        if (dataRegular?.closeCallback) {
          dataRegular?.closeCallback();
        }
        dataTicket.show = false;
        if (dataTicket?.closeCallback) {
          dataTicket?.closeCallback();
        }
      }
    "
  >
    <print-pos-factura-regular
      v-if="type === 'regular'"
      v-model="type"
      :dataProps="dataRegular"
      :hideQr="hideQr"
    ></print-pos-factura-regular>
    <print-pos-factura-ticket
      v-else-if="type === 'ticket'"
      v-model="type"
      :dataProps="dataTicket"
      :hideQr="hideQr"
    ></print-pos-factura-ticket>
  </window-portal>
</template>

<script>
import PrintPosFacturaRegular from "./PrintPosFacturaRegular.vue";
import PrintPosFacturaTicket from "./PrintPosFacturaTicket.vue";
import WindowPortal from "./WindowPortal.vue";

export default {
  name: "PrintPosFactura",
  props: {
    dataTicket: {
      type: Object,
      default: () => ({}),
    },
    dataRegular: {
      type: Object,
      default: () => ({}),
    },
    hideQr: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    WindowPortal,
    PrintPosFacturaRegular,
    PrintPosFacturaTicket,
  },
  data: () => ({
    type: "ticket", // regular, ticket
  }),
  watch: {
    "dataRegular.show"(value) {
      if (!value) {
        this.type = "ticket";
      }
    },
  },
};
</script>
