<template>
    <div>
        <block v-if="loading"></block>
        <div class="d-flex flex-wrap align-self-center justify-end mb-3">
            <v-btn class="btn-add" @click="getAllInvoices()">Recargar historial
            </v-btn>
        </div>
        <div>
            <GeneralFilter class="backgroundGlobal border-boxGlobal mt-1"  :search="filter" :filterEndpoint="$API.invoices.getInvoicesFilteringNotBilled" @emptyFilter="getAllInvoices" @filtered="filterHandler">
                <DxDataGrid :scrolling="{useNative:true}" width="100%" :data-source="invoices" key-expr="ID_Invoice"
                  @exporting="onExporting" :paging="{ enabled: false }" style="max-height: 600px;"
                >
                    <DxExport :enabled="true" />
                    <!--DxPaging :page-size="10"/-->
                    <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                    <DxColumn :width="200" data-field="ID_Invoice" caption="ID de factura"></DxColumn>
                    <DxColumn :width="130" data-field="InvoiceDate" caption="Fecha de creación"></DxColumn>
                    <DxColumn :width="130" data-field="Hour" caption="Hora de creación"></DxColumn>
                    <DxColumn :width="170" data-field="ID_Customer" caption="ID de cliente o negocio"></DxColumn>
                    <DxColumn :width="400" data-field="InvoiceName" caption="Nombre Legal"></DxColumn>
                    <DxColumn :width="130" data-field="InvoiceTotalPagarToShow" caption="Total de la Factura"></DxColumn>
                    <DxColumn :width="300" cell-template="tipos-facturas" caption="Tipo de factura"></DxColumn>
                    <template #tipos-facturas="{ data }">
                        {{ data.data.InvoiceType == "CCF" ? "COMPROBANTE CRÉDITO FISCAL" : "" }}
                        {{ data.data.InvoiceType == "FCF" ? "FACTURA CONSUMIDOR FINAL" : "" }}
                        {{ data.data.InvoiceType == "DNC" ? "NOTA DE CRÉDITO" : "" }}
                        {{ data.data.InvoiceType == "DND" ? "NOTA DE DÉBITO" : "" }}
                        {{ data.data.InvoiceType == "DNR" ? "NOTA DE REMISIÓN" : "" }}
                        {{ data.data.InvoiceType == "FNS" ? "FACTURA DE SUJETO EXCLUIDO" : "" }}
                        {{ data.data.InvoiceType == "FEX" ? "FACTURA DE EXPORTACIÓNA" : "" }}
                        {{ data.data.InvoiceType == "DNC" ? "COMPROBANTE DE DONACIÓN" : "" }}
                    </template>
                    <DxColumn :width="300" data-field="FullName" caption="Usuario"></DxColumn>
                    <DxColumn :width="300" data-field="FullNameSeller" caption="Vendedor"></DxColumn>
                    <template #show-template="{data}">
                        <a href="#" class="mx-1" @click.prevent.stop="showInvoice = data; showModal = true">
                            <v-icon color="primary">mdi-eye</v-icon>
                        </a>
                    </template>
                </DxDataGrid>
            </GeneralFilter>
        </div>
        <!-- Show Invoice Modal -->
        <v-dialog v-model="showModal" scrollable :max-width="$vuetify.breakpoint.smAndDown ? '90%':'75%'">
            <v-card v-if="showInvoice" height="100%">
                <!--v-card-title class="text-h5">
                    Notas Internas de la Factura
                </v-card-title-->

                <invoice-details :hideQr="true" :hasPrintButtom="true" :hasDeleteBtn="true" :invoice="showInvoice.data" :key="showInvoice.data.ID_Invoice"
                                 @close="hideModal" @billNow="billNow"></invoice-details>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {DxDataGrid, DxColumn, DxExport } from 'devextreme-vue/data-grid';
    import GeneralFilter from '../../components/GeneralFilter.vue';
    import { printToExcel } from "@/helpers/printToexcel";
    import { numberWithCommas } from '../../helpers/money';

    import InvoiceDetails from "./InvoiceDetails";
    import Block from "@/components/Block";

    export default {
        name: "Bill",
        components: {DxColumn, DxDataGrid, GeneralFilter, InvoiceDetails, Block , DxExport},
        data() {
            return {
                loading: false,
                form: {},
                showModal: false,
                filter: {
                    //pay: true,
                    ID_Branch: null,
                    ID_Business: null,
                    status: 0,
                    istemplate: 0,
                    isquote: 0
                },
                invoices: null,
                showInvoice: null
            }
        },
        mounted() {
            this.filter.ID_Branch = JSON.parse(localStorage.getItem("user")).branch;
            this.filter.ID_Business = JSON.parse(localStorage.getItem("user")).businessid;

            this.getAllInvoices()
        },
        computed: {
            columnWidth() {
                return this.$vuetify.breakpoint.smAndDown ? '150px' : "auto !important";
            }
        },
        methods: {
            formatInvoiceFields() {
                // const dictionary = {
                //     '00': "Prueba",
                //     '01': "Producción"
                // };
                const { Currency } = JSON.parse(localStorage.getItem('branch'));
                for(const index in this.invoices) {
                    // const { IdentificacionHorEmi, InvoiceType, SystemDateInsert, IdentificacionTipoDteDesc } = this.invoices[ index ];

                    // this.invoices[index].AmbienteToShow = dictionary[this.invoices[index].IdentificacionAmbiente];
                    this.invoices[index].InvoiceTotalPagarToShow = `${Currency}${numberWithCommas(this.invoices[index].InvoiceTotalPagar)}`;

                    // if( InvoiceType === 'TCKS' ) {
                    //     this.invoices[ index ].HorEmi = this.formatDateToHour(SystemDateInsert);
                    //     this.invoices[ index ].TipoDocumento = "TICKET";
                    // } else {
                    //     if( IdentificacionTipoDteDesc != null ) {
                    //         this.invoices[ index ].TipoDocumento = IdentificacionTipoDteDesc;
                    //     } else {
                    //         this.invoices[ index ].TipoDocumento = "";
                    //     }
                    //     if( IdentificacionHorEmi != null ) {
                    //         this.invoices[ index ].HorEmi = IdentificacionHorEmi;
                    //     } else { 
                    //         this.invoices[ index ].HorEmi = "";
                    //     }
                    // }
                    // {{ data.data.IdentificacionHorEmi != null ? data.data.IdentificacionHorEmi : "" }}
                    //     {{ data.data.InvoiceType === 'TCKS' ? formatDateToHour(data.data.SystemDateInsert) : "" }}
                }
            },
            getAllInvoices() {
                this.loading = true
                this.$API.invoices.getInvoicesPOSHistoryNotBilled(this.filter)
                    .then(response => {
                        this.loading = false
                        this.invoices = [...response]
                        this.formatInvoiceFields();
                    })
            },
            filterHandler(response) {
                this.invoices = [...response];
                this.formatInvoiceFields();
            },
            hideModal() {
                this.showModal = false;
                this.showInvoice = null;
                this.getAllInvoices();
            },
            billNow() {
                this.hideModal();
                this.$emit('generateInvoice')
            },
            onExporting(e) {
                printToExcel(e, "Lista de Facturas")
            }
        }
    }
</script>

<style scoped>
.btn-add{
  background-color: #00A178 !important;
  color: white;
}
</style>