<template>
  <div class="relative-container">
    <v-menu
      attach
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
      :disabled="disbaled"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          outlined dense
          v-model="dateFormatted"
          :label="label"
          :disabled="disbaled"
          prepend-icon="mdi-calendar"
          v-bind="attrs"
          @blur="date = parseDate(dateFormatted)"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker locale="es-SV" v-model="date" no-title scrollable @input="menu = false"  :disabled="disbaled">

      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "DatePicker",
  props: ["label", "value", "reset", "disbaled"],
  data() {
    return {
      menu: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormatted: this.value
        ? this.value
        : this.formatDate(
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10)
          ),
      isdisbaled: false,
    };
  },
  mounted() {
    this.dateFormatted = this.formatDate(this.date);
    this.$emit("dateSelected", this.dateFormatted);
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
      this.$emit("dateSelected", this.dateFormatted);
    },
    value() {
      this.dateFormatted = this.value;
    },
    reset() {
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.dateFormatted = this.value
        ? this.value
        : this.formatDate(
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10)
          );
    },
    disbaled() {
      this.isdisbaled = this.disbaled
    },
  },
  methods: {
    
    formatDate(date) {
      if (!date) return null;
      
      const [year, month, day] = date.split("-");
      return this.moment(`${month}/${day}/${year}`).format('DD/MM/YYYY');
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped>
  .relative-container {
    position: relative;
  }
</style>