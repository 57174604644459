const posData = {
    namespaced: true,
    state: () => ({
        currentView: "tab-1",
        data: 'none',
        reloaders: {
            reloadCreate: 0,
            reloadHistory: 0,
            reloadBill: 0,
            reloadTemplateBill: 0,
            reloadQuotesBill: 0,
            reloadCashierCut: 0
        },
        customer: null,
        header: null,
        body: null,
        invalid: null,
        ndornc: null,
        quote: null,
        contingency: null
    }),
    mutations: {
        changeView(state, view){
            state.currentView = view
        },
        setData(state, data){
            state.data = data
        },
        setReloaders(state, payload){
            switch(payload){
                case 'reloadCreate':
                    state.reloaders.reloadCreate++;
                    break;
                case 'reloadHistory':
                    state.reloaders.reloadHistory++;
                    break;
                case 'reloadBill':
                    state.reloaders.reloadBill++;
                    break;
                case 'reloadTemplateBill':
                    state.reloaders.reloadTemplateBill++;
                    break;
                case 'reloadQuotesBill':
                    state.reloaders.reloadQuotesBill++;
                    break;
                case 'reloadCashierCut':
                    state.reloaders.reloadCashierCut++;
                    break;
            }
        },
        setCustomer(state, payload){
            state.customer = payload
        },
        setHeader(state, payload){
            state.header = payload
        },
        setBody(state, payload){
            state.body = payload
        },
        setInvalid(state, payload){
            state.invalid = payload
        },
        setNDorNC(state, payload){
            state.ndornc = payload
        },
        setQuote(state, payload){
            state.quote = payload
        },
        setContingency(state, payload){
            state.contingency = payload
        }
    },
    actions: {

    }
}

export default posData