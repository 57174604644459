<template>
    <div class="pa-1">
        <block v-if="loading"></block>
        <v-card flat>
            <v-card-text class=" white">
                <div class="pa-2 mt-2">
                    <v-row >
                        <v-col cols="12" md="12">
                            <h1> Exportar Datos</h1>
                        </v-col>
                    </v-row>
                </div>
                <div class="pa-4 mt-3">
                <v-row >
                    <v-col cols="12" md="6">
                        <h3> Exportar Clientes</h3>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-btn class="btn-add" @click="getAllCustomers()">Exportar</v-btn>
                    </v-col>
                </v-row>
                </div>
                <div class="pa-4" v-if="businesstype != 'accounting_and_bill'">
                <v-row >
                    <v-col cols="12" md="6" >
                        <h3> Exportar Pacientes</h3>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-btn class="btn-add" @click="getAllPatients()">Exportar</v-btn>
                    </v-col>
                    
                </v-row>
                </div>
                <div class="pa-4">
                    <v-row >
                        <v-col cols="12" md="6">
                            <h3> Exportar Productos</h3>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-btn class="btn-add" @click="getAllProducts()">Exportar</v-btn>
                        </v-col>
                    </v-row>
                </div>
                <div class="pa-4">
                    <v-row >
                        <v-col cols="12" md="6">
                            <h3> Exportar Proveedores</h3>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-btn class="btn-add" @click="getAllProviders()">Exportar</v-btn>
                        </v-col>
                    </v-row>
                </div>
                
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import ExcelJS from 'exceljs'
    export default {
        name: "ExportDetail",
        props: ['win'],
        data() {
            return {
                search: {
                    business: JSON.parse(localStorage.getItem("user")).businessid || "",
                    branch: JSON.parse(localStorage.getItem("user")).branch || "",
                    filter: true
                },
                patients: [],
                loading: false,
                businesstype : JSON.parse(localStorage.getItem("user")).businesstype || ""
            }
        },
        
        methods: {
            getAllPatients() {
                this.loading = true
                
                if(this.businesstype == 'veterinary'){
                    this.$API.veterinarypatients.getPatients(this.search)
                    
                        .then(response => {
                            let array = []
                            response.forEach(element => {
                                element.Status = element.Status ? 1 : 0
                                element.Live = element.Live ? 1 : 0
                                element.Mode = element.Mode ? 1 : 0

                                array.push(element)
                            });
                            this.loading = false
                            this.exportExcel('pacientes', array)
                        })
                        .catch(error => {
                            this.loading = false
                            console.log(error)
                        })
                }
                else if(this.businesstype == 'bloodtestlaboratory' || this.businesstype == 'medicalclinic'){
                    this.$API.patients.getPatients(this.search) 
                        .then(response => {
                            let array = []
                            response.forEach(element => {
                                array.push(element)
                            });
                            this.loading = false
                            this.exportExcel('pacientes', array)
                        })
                        .catch(error => {
                            this.loading = false
                            console.log(error)
                        })
                }
            },
            async getAllCustomers() {
                try {
                    this.loading = true
                    const response = await this.$API.customers.getCustomersExport(this.search)
                    
                    this.loading = false
                    let array = []
                    response.forEach(element => {
                        element.Is_a_business = element.Is_a_business ? 1 : 0
                        
                        array.push(element)
                    });
                    this.exportExcel('clientes', array)
                } catch (error) {
                    console.log(error)
                } finally {
                    this.loading = false
                }
            },
            getAllProducts(){
                this.loading = true
                this.$API.products.getProducts(this.search)
                    .then(response => {
                        this.loading = false
                        this.loading = false
                        let array = []
                        response.forEach(element => {
                            element.IsGlobalBranch = element.IsGlobalBranch ? 1 : 0
                            
                            array.push(element)
                        });
                        this.exportExcel('productos', array)
                    })
                    .catch(error => {
                        this.loading = false
                        console.log(error)
                    })
            },
            async getAllProviders() {
                try {
                    this.loading = true
                    const response = await this.$API.providers.getProviders(this.search)
                    let array = []
                    response.forEach(element => {
                        element.IsGlobalBranch = element.IsGlobalBranch ? 1 : 0
                        
                        array.push(element)
    
                        array.push(element)
                    });
                    
                    this.exportExcel('proveedores', array)
                } catch (error) {
                    console.log( error );
                } finally {
                    this.loading = false

                }
            },
            exportExcel(x='', data ){
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet(x);
                const header = Object.keys(data[0])
                worksheet.addRow(header);
                data.forEach(d => {
                    const row = [];
                    header.forEach(h => {
                        row.push(d[h]);
                    });
                    worksheet.addRow(row);
                });

                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.setAttribute('hidden', '');
                    a.setAttribute('href', url);
                    a.setAttribute('download', `${x}.xlsx`);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                });
            }
        }
    }
</script>