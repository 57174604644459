<template>
    <v-card>
        <v-card-title class="text-h5">
            {{$t('Are_you_sure_you_want_to_delete')}}<!--  - {{name}}  -->?
        </v-card-title>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="deleteTemplate">
                {{$t('del_Yes')}}
            </v-btn>
            <v-btn color="green darken-1" text @click="$emit('close')">
                {{$t('del_No')}}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "DeleteUserDialog",
        props: ['data','name'],
        methods: {
            deleteTemplate() {
                this.$emit('f_call',this.data)
                // this.$API.laboratory.deleteTemplate(this.data.ID_LaboratoryTest)
                //     .then((response) => {
                //         this.$emit('close');
                //         this.$toasted.success(response.message);

                //     })
            }
        }
    }
</script>

<style scoped>

</style>
