<template>
    <window-portal :open="dataProps.show" @close="() => {
        dataProps.show = false
        if(dataProps?.closeCallback){
            dataProps?.closeCallback()
        };
    }
        ">
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center><div><p style="font-size: 30px;">Vista Previa de Impresión de Historial Médico</p></div></center>
            <br/>
            <center>
                <button class="btnclean" style="font-size: 18px;" @click="Print()">
                    Imprimir
                </button>
                &nbsp;&nbsp;
                <button class="btnclean" style="font-size: 18px;" @click="Download()">
                    Descargar
                </button>
            </center>
            <center><div v-if="loading"><p>Cargando...</p></div></center>
        </div>
        <div class="book" style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px;">
                <div style="margin: 0 auto; width: 1000px;">
                    <div ref="dataprint" style="margin: 0 !important; padding: 0 !important;">
                        <!--div style="position: relative; width: 1000px; min-height: 1294px; background: white;"-->
                        <div v-for="(page, indexPage) in pages" :key="indexPage" style="position: relative; width: 1000px; min-height: 1293px; background: white;">
                            <hr/>
                            <div class="subpage" style="height: 1250px; padding-top: 20px;">
                                <div v-html="header" style="width: 60% !important; margin-left: 60px;"></div>
                                <div style="margin-left: 80px; margin-right: 50px;">
                                    <div style="text-align: center; font-size: 16px; font-weight: bold; margin-bottom: 15px;">Historial Clínico</div>
                                    <div style="text-align: center; font-size: 14px;"><b>Fecha Consulta</b> {{ dataProps.data.medicalhistory.MedicalHistoryDate }}</div>
                                    <div style="text-align: center; font-size: 14px;"><b>Veterinario Responsable</b> {{ dataProps.data.medicalhistory.FullName }}</div>

                                    <table style="width: 100%; border-collapse: collapse; margin-top: 15px;">
                                        <tr>
                                            <td colspan="2" style="font-size: 12px; font-weight: bold; padding: 5px; border-top: 1px solid;">Datos del Cliente</td>
                                            <td colspan="2" style="font-size: 12px; font-weight: bold; padding: 5px; border-top: 1px solid;">Datos del Paciente</td>
                                        </tr>
                                        <tr style="font-size: 12px;">
                                            <td style="text-decoration: underline; padding: 5px;">Ficha:</td>
                                            <td style="padding: 5px;">{{ dataProps.data.patientinfo.ID_Customer  }}</td>
                                            <td style="text-decoration: underline; padding: 5px;">Ficha Paciente:</td>
                                            <td style="padding: 5px;">{{ dataProps.data.patientinfo.ID_Patient  }}</td>
                                        </tr>
                                        <tr style="font-size: 12px;">
                                            <td style="text-decoration: underline; padding: 5px;">Nombres:</td>
                                            <td style="padding: 5px;">{{ dataProps.data.patientinfo.customer.FirstName || dataProps.data.patientinfo.customer.BusinessName  }}</td>
                                            <td style="text-decoration: underline; padding: 5px;">Paciente:</td>
                                            <td style="padding: 5px;">{{ dataProps.data.patientinfo.NamePatient  }}</td>
                                        </tr>
                                        <tr style="font-size: 12px;">
                                            <td style="text-decoration: underline; padding: 5px;">Apellidos:</td>
                                            <td style="padding: 5px;">{{ dataProps.data.patientinfo.customer.LastName }}</td>
                                            <td style="text-decoration: underline; padding: 5px;">Especie:</td>
                                            <td style="padding: 5px;">{{ dataProps.data.patientinfo.Species }}</td>
                                        </tr>
                                        <tr style="font-size: 12px;">
                                            <td style="text-decoration: underline; padding: 5px;">Teléfono:</td>
                                            <td style="padding: 5px;">{{ dataProps.data.patientinfo.customer.MobileNumber || dataProps.data.patientinfo.customer.PhoneNumber  || dataProps.data.patientinfo.customer.BusinessMobileNumber || dataProps.data.patientinfo.customer.BusinessPhone }}</td>
                                            <td style="text-decoration: underline; padding: 5px;">Raza:</td>
                                            <td style="padding: 5px;">{{ dataProps.data.patientinfo.Breed }}</td>
                                        </tr>
                                        <tr style="font-size: 12px;">
                                            <td style="text-decoration: underline; padding: 5px;">Dirección:</td>
                                            <td style="padding: 5px;">{{ dataProps.data.patientinfo.customer.LegalAddress }}</td>
                                            <td style="text-decoration: underline; padding: 5px;">Sexo:</td>
                                            <td style="padding: 5px;">{{ dataProps.data.patientinfo.Gender }}</td>
                                        </tr>
                                    </table>

                                    <table style="width: 100%; border-collapse: collapse; margin-top: 15px;">
                                        <tr style="font-size: 12px; text-align: left;">
                                            <td style="text-decoration: underline; padding: 5px; border-top: 1px solid;">Peso:</td>
                                            <td style="padding: 5px; border-top: 1px solid;">{{ dataProps.data.medicalhistory.Weight }}</td>
                                            <td style="text-decoration: underline; padding: 5px; border-top: 1px solid;">Talla:</td>
                                            <td style="padding: 5px; border-top: 1px solid;">{{ dataProps.data.medicalhistory.height }}</td>
                                            <td style="text-decoration: underline; padding: 5px; border-top: 1px solid;">Pulso:</td>
                                            <td style="padding: 5px; border-top: 1px solid;">{{ dataProps.data.medicalhistory.pulse }}</td>
                                            <td style="text-decoration: underline; padding: 5px; border-top: 1px solid;">Temperatura:</td>
                                            <td style="padding: 5px; border-top: 1px solid;">{{ dataProps.data.medicalhistory.temp }}</td>
                                        </tr>
                                    </table>

                                    <div
                                        style="margin-top: 15px;"
                                        v-html="valuesRows[indexPage]" 
                                        media="all" 
                                    ></div>
                                </div>
                                <div style="position: absolute; bottom: 0; width: 100%; left: 80px">
                                    <p style="font-size:10px;">Pagina {{ indexPage+1 }} de {{ numPages }}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
        </div>
    </window-portal>
</template>

<script>

import jsPDF from "jspdf"
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue"

export default {
    name: "PrintMedicalHistory",
    props: {
        dataProps: {
            type: Object,
        },
        hideQr: {
            type: Boolean,
            default: false
        }
    },
    components: {WindowPortal},
    data() {
        return {
            header: null,
            footer: null,
            loading: false,
            numPages: 0,
            valuesRows: [],
			ID_Branch: JSON.parse(localStorage.getItem("user")).branch,
			ID_Business: JSON.parse(localStorage.getItem("user")).businessid,
            roleuser: JSON.parse(localStorage.getItem("user")).roles[0],
        }
    },
    mounted() {
        this.getHeaderFooter()
    },
    computed: {
        pages() {
            // Generar un arreglo con `numPages` elementos
            return Array.from({ length: this.numPages });
        },
    },
    watch: {
        'dataProps.show'() {
            if(this.dataProps.show){
                setTimeout(async () => {
                    await this.calculatePagesOfDoc()
                }, 0);
            }
        }
    },
    methods: {
        getHeaderFooter() {
            let search = {};
            search.ID_business = JSON.parse(localStorage.getItem("user")).businessid || ""
            search.ID_branch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.$API.HF.getHF(search).then((data) => {
                let header = [], footer = [];
                header = data.filter((x) => x.State == '1' && x.Type == 'Header');
                this.header = header.length > 0 ? JSON.parse(header[0].Body).html : '';
                this.header = this.header.replace(/(\n)/gm, "");
                footer = data.filter((x) => x.State == '1' && x.Type == 'Footer');
                this.footer = footer.length > 0 ? JSON.parse(footer[0].Body).html : '';
            })
        },
        
        calculatePagesOfDoc() {
            this.valuesRows = []; // Asegúrate de inicializar el array de páginas

            try {
                // Obtén los datos
                const data = this.dataProps.data.medicalhistory;

                // Define los títulos para cada sección
                const sectionTitles = {
                    PhysicalHistorySummary: 'Resumen Historial y Examen Físico:',
                    PresumptiveDiagnosis: 'Diagnóstico Presuntivo:',
                    FinalDiagnosis: 'Diagnóstico Final:',
                    Treatment: 'Tratamiento:'
                };

                // Función para procesar el texto
                const processText = (text, title) => {
                    let cleanedText = text
                        .replace(/<ul[^>]*>/g, '')
                        .replace(/<\/ul>/g, '')
                        .replace(/<ol[^>]*>/g, '')
                        .replace(/<\/ol>/g, '');
                    cleanedText = cleanedText
                        .replace(/<li([^>]*)>(.*?)<\/li>/g, '<ul><li$1>$2</li></ul>')
                        .replace(/<p[^>]*>/g, '<p>').replace(/<\/p>/g, '</p>');
                    return `<p style="font-size: 12px; text-decoration: underline;">${title}</p>` + cleanedText
                        .replace(/<p>/g, `<p style="font-size: 12px; margin: 0; padding: 0;">`)
                        .replace(/<ol>/g, `<ol style="margin-block-start: 0; margin-block-end: 0; padding-inline-start: 40px;">`)
                        .replace(/<ul>/g, `<ul style="margin-block-start: 0; margin-block-end: 0; padding-inline-start: 40px;">`)
                        .replace(/<li>/g, `<li style="font-size: 12px;">`);
                };

                const sections = [
                    { text: data.PhysicalHistorySummary, title: sectionTitles.PhysicalHistorySummary },
                    { text: data.PresumptiveDiagnosis, title: sectionTitles.PresumptiveDiagnosis },
                    { text: data.FinalDiagnosis, title: sectionTitles.FinalDiagnosis },
                    { text: data.Treatment, title: sectionTitles.Treatment }
                ].map(section => processText(section.text, section.title));

                // Une todas las secciones en un solo string
                const combinedText = sections.join('<p><br></p>'); // Añade un separador entre secciones si es necesario

                // Divide el texto en partes manejables
                const splitedText = combinedText.split(/(<\/[^>]+>|<br\s*\/?>|\n|<p><br><\/p>|<p><br\/><\/p>|<p><br \/><\/p>|<p><\/p>)/g).filter(part => part !== undefined);
                
                const maxHeight = 800; // Altura máxima de la página en píxeles
                const tempDiv = document.createElement('div');
                tempDiv.style.position = 'absolute';
                tempDiv.style.visibility = 'hidden';
                tempDiv.style.width = '800px'; // Ajusta el ancho a tu necesidad
                tempDiv.style.fontSize = '12px'; // Usa el mismo tamaño de fuente que en el contenido
                document.body.appendChild(tempDiv);

                this.valuesRows = []; // Reinicia el array de páginas

                let index = 0; // Índice para la parte actual del texto
                while (index < splitedText.length) {
                    let pageContent = '';

                    while (index < splitedText.length) {
                        const string = splitedText[index];
                        const newContent = pageContent + string;
                        tempDiv.innerHTML = newContent;

                        if (tempDiv.offsetHeight > maxHeight) {
                            // Guarda la página actual sin el contenido que excedió
                            if (pageContent.trim()) {
                                this.valuesRows.push(pageContent);
                            }

                            // Comienza una nueva página con el contenido que excedió
                            pageContent = string;

                            // Avanza al siguiente fragmento
                            index++;
                        }
                        else{
                            // Continúa añadiendo contenido a la página actual
                            pageContent = newContent;
                            index++;
                        }
                    }

                    // Añade el contenido restante como una nueva página si no está vacío
                    if (pageContent.trim()) {
                        this.valuesRows.push(pageContent);
                    }
                }

                document.body.removeChild(tempDiv);
                this.numPages = this.valuesRows.length; // Número total de páginas
            } catch (err) {
                //alert(err);
                console.log(err)
            }
        },

        async Print(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            })
            
            //doc.deletePage(2)

            doc.autoPrint({variant: 'non-conform'});
            const blob = doc.output("bloburl")
            window.open(blob)

            this.loading = false
        },

        async Download(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            });
            
            //doc.deletePage(2)
            doc.save("CorteDeCajaDiario.pdf"); 

            this.loading = false
        },
    }
}
</script>

<style scoped>
    .u-row .u-col-100{
        width: 100% !important;
    }
    .btnclean{
        border-radius: 15px;
        border: 1px solid white;
        background-color: #F29D35 !important;
        color: white !important;
    }
    .btnclose{
        border-radius: 15px;
        border: 1px solid white;
        background-color: rgb(255, 38, 38) !important;
        color: white !important;
       
    }

    * {
        font-family: Arial, Helvetica, sans-serif !important;
    }

 

</style>
