import axios from "./axios";
// import Vuex from '../store/index'

export default {
    /**
     * Get All Emails
     * @api {Get} manageuser
     */
    getTM() {
        const options = {
            method: 'GET',
            url: 'tm',
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                    console.log(response.data)

                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    /**
     * Get All Emails
     * @api {Get} manageuser
     */
    getTMFiltered(params) {
        const options = {
            method: 'GET',
            url: 'tm/filtered',
            headers: {'content-type': 'application/json'},
            params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                    console.log(response.data)

                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getResolveTemplate(data) {
        const options = {
            method: 'POST',
            url: 'tm/type/resolveTemplate',
            headers: {'content-type': 'application/json'},
            data:data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                    console.log(response.data)

                })
                .catch(error => {
                    reject(error.messageageage)
                })
        })
    },
    addTM(data) {
        const options = {
            method: 'POST',
            url: 'tm',
            headers: {'content-type': 'application/json'},
            data:data
        };
        console.log(data);
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                    console.log(response.data)

                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    updateTM(data) {
        const options = {
            method: 'PUT',
            url: `tm`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deleteTM(id) {
        const options = {
            method: 'DELETE',
            url: `tm/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

}
