<template>
    <v-expansion-panel>
        <v-expansion-panel-header :key="showReload" class="mt-2">
            POS
            <template v-slot:actions>
                <v-btn @click.native.stop="reload()" icon small>
                    <v-icon color="primary">mdi-reload</v-icon>
                </v-btn>
                <!--v-icon color="error" @click="$store.dispatch('deleteWindowByUuid',{uuid : win.uuid})">
                    mdi-close
                </v-icon-->
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-tabs v-model="tab" background-color="primary" dark  >
                <v-tab v-if="$store.getters['rolesUser/getCustomRole'](4)"
                    href="#tab-1" @click="changeTab('tab-1')">
                    GENERAR FACTURAS
                </v-tab>

                <v-tab v-if="$store.getters['rolesUser/getCustomRole'](24)"
                 href="#tab-2" @click="changeTab('tab-2')">
                    HISTORIAL DE FACTURAS
                </v-tab>

                <v-tab  v-if="$store.getters['rolesUser/getCustomRole'](13)"
                 href="#tab-3" @click="changeTab('tab-3')">
                    LISTO PARA FACTURAR
                </v-tab>

                <v-tab  v-if="$store.getters['rolesUser/getCustomRole'](68)"
                 href="#tab-4" @click="changeTab('tab-4')">
                    PLANTILLAS DE FACTURACIÓN
                </v-tab>

                <v-tab  v-if="$store.getters['rolesUser/getCustomRole'](71)"
                    href="#tab-5" @click="changeTab('tab-5')">
                    COTIZACIONES
                </v-tab>
                <v-tab v-if="$store.getters['rolesUser/getCustomRole'](67)"
                    href="#tab-6" @click="changeTab('tab-6')">
                    CORTE DE CAJA
                </v-tab>
              
            </v-tabs>

            <v-tabs-items v-model="tab" touchless>
                <v-tab-item value="tab-1">
                    <v-card v-if="$store.getters['rolesUser/getCustomRole'](4)" flat>
                        <v-card-text :key="reloadCreate" class="px-1 py-3">
                            <Create :key="reloadNumber" :win="win"></Create>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <v-tab-item value="tab-2">
                    <v-card v-if="$store.getters['rolesUser/getCustomRole'](24)" flat class="mt-4">
                        <v-card-text :key="reloadHistory" class="px-1 py-1 mt-2">
                            <History :key="reloadNumber" :win="win"></History>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <v-tab-item value="tab-3">
                    <v-card v-if="$store.getters['rolesUser/getCustomRole'](13)" flat class="mt-2">
                        <v-card-text :key="reloadBill" class="px-1 py-1">
                            <Bill :key="reloadNumber" :win="win"></Bill>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <v-tab-item value="tab-4">
                    <v-card v-if="$store.getters['rolesUser/getCustomRole'](68)" flat class="mt-2">
                        <v-card-text :key="reloadTemplateBill" class="px-1 py-1">
                            <TemplateBill :key="reloadNumber" :win="win"></TemplateBill>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <v-tab-item value="tab-5">
                    <v-card v-if="$store.getters['rolesUser/getCustomRole'](71)" flat class="mt-0">
                        <v-card-text :key="reloadQuotesBill" class="px-1 py-1">
                            <QuotesBill :key="reloadNumber" :win="win"></QuotesBill>
                        </v-card-text>    
                    </v-card>
                </v-tab-item>
                
                <v-tab-item value="tab-6">
                    <v-card  v-if="$store.getters['rolesUser/getCustomRole'](67)" flat class="my-1">
                        <v-card-text :key="reloadCashierCut" class="px-1 py-1">
                            <CashierCut :key="reloadNumber" :win="win"></CashierCut>
                        </v-card-text>    
                    </v-card>
                </v-tab-item>
               
            </v-tabs-items>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import { mapState,mapGetters } from 'vuex';
import Create from "./create/Create";
import History from "./History";
import Bill from "./Bill";
import TemplateBill from './TemplateBill';
import QuotesBill from './QuotesBill';
import CashierCut from './CashierCut';

//import PriceList from "./PriceList";

export default {
    name: "Invoices",
    components: { Create, History, Bill, TemplateBill, QuotesBill, CashierCut },
    props: ['win'],
    data() {
        return {
            tabData: null,
            items: [
                { tab: this.$t('vwinvoices_generateinvoices'), content: 'Create' },
                { tab: this.$t('vwinvoices_invoiceshistory'), content: 'History' },
                { tab: this.$t('vwinvoices_readytobill'), content: 'Bill' },
                { tab: "PLANTILLAS DE FACTURACIÓN", content: 'TemplateBill' },
                { tab: "COTIZACIONES", content: 'QuotesBill' },
                { tab: "HISTORIAL DE VENTAS", content: 'SalesHistory' },
                { tab: "CORTE DE CAJA", content: 'CashierCut'},
            ],
            tab: null,
            reloadNumber: 0,
            showReload: true
        }
    },
    computed: {
        ...mapState('posData', {
            tabState: state => state.currentView,
            reloadCreate: state => state.reloaders.reloadCreate,
            reloadHistory: state => state.reloaders.reloadHistory,
            reloadBill: state => state.reloaders.reloadBill,
            reloadTemplateBill: state => state.reloaders.reloadTemplateBill,
            reloadQuotesBill: state=> state.reloaders.reloadQuotesBill,
            reloadCashierCut: state=> state.reloaders.reloadCashierCut,
        }),
        computed: {
        ...mapGetters('rolesUser', ['isAdminOrManager'])
        },

        ...mapState(
            "rolesUser",
            {
                rolesStatus: (state) => state.roles,
            }), 
        
           

        },
    watch: {
        tabState() {
            this.tab = this.tabState
        },
    },
    methods: {
        generateInvoice() {
            this.tabData = 0;
        },
        reload() {
            //this.$store.commit('posData/setReloaders', 'reloadCreate')
            //this.$store.commit('posData/setReloaders', 'reloadHistory')
            //this.$store.commit('posData/setReloaders', 'reloadBill')
            this.$store.commit("posData/setHeader", null);
            this.$store.commit("posData/setBody", null);
            this.$store.commit("posData/setCustomer", null);
            this.$store.commit("posData/setInvalid", null);
            this.$store.commit("posData/setNDorNC", null);
            this.$store.commit("posData/setQuote", null);
            this.reloadNumber++
            this.showReload = true
        },
        changeTab(tab) {
            this.$store.commit('posData/changeView', tab)
        }

    }
}
</script>

<style scoped></style>