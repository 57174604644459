<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            Exportar
            <template v-slot:actions>
                 <v-btn class="mr-5" @click.native.stop="reload++" icon small>
                    <v-icon color="primary">mdi-reload</v-icon>
                </v-btn>
                <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-card>
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>

                    <v-tab href="#tab-1">
                        Exportar datos 
                        <v-icon>mdi-export</v-icon>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                       
                            <ExportDetail  :key="reload" /> 

                           
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
   import ExportDetail from './partial/ExportDetail.vue'
    export default {
        name: "ExportData",
        components: {
            ExportDetail
        },
        props: ['win'],
        data() {
            return {
                reload: 0,
                tab: 0,
            }
        },
        
        
        methods: {
           
            
        },
    }
</script>

<style scoped>
    .v-input__control {
        background: white !important;
    }

</style>
