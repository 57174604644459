import axios from "./axios";

export default {

    /**
     * Get All Notification
     * @api {Get} notification
     */
    getNotification(data) {
        const options = {
            method: 'GET',
            url: 'notification',
            headers: {'content-type': 'application/json'},
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getNotificationFiltered(data) {
        const options = {
            method: 'GET',
            url: 'notification/filtered',
            headers: {'content-type': 'application/json'},
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getNotificationHistory(data) {
        const options = {
            method: 'GET',
            url: 'notification/history',
            headers: {'content-type': 'application/json'},
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

        /**
     * Add Mail-data
     * @api {POST} notification
     */
         addMail(data) {
            const options = {
                method: 'POST',
                url: 'notification',
                headers: {'content-type': 'application/json'},
                data: data
            };
    
            return new Promise((resolve, reject) => {
                axios(options)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error.message)
                    })
            })
        },

        sendVeterinaryReminder(data) {
            const options = {
                method: 'POST',
                url: 'notification/veterinaryreminder',
                headers: {'content-type': 'application/json'},
                data: data
            };
    
            return new Promise((resolve, reject) => {
                axios(options)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error.message)
                    })
            })
        }
}
