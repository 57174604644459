<template>
    <window-portal :open="data.show" @close="data.show = false">
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center>
                <div>
                    <p style="font-size: 30px;">Vista previa de impresión de orden de compra</p>
                </div>
            </center>
            <br />
            <center>
                <button class="btnclean" style="font-size: 18px;" @click="Print()">
                    Imprimir
                </button>
                &nbsp;&nbsp;
                <button class="btnclean" style="font-size: 18px;" @click="Download()">
                    Descargar
                </button>
            </center>
            <center>
                <div v-if="loading">
                    <p>Cargando...</p>
                </div>
            </center>
        </div>

        <div class="book"
            style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px;">
            <div style="margin: 0 auto; width: 1000px;">
                <!--Configuración de la pagina-->
                <div ref="dataprint" style="margin: 0 !important; padding: 0 !important;">
                    <div v-for="( page, pageIndex ) in pagesRender" :key="pageIndex" class="page" 
                    style="position: relative; width: 1000px; min-height: 1294px; background: white; margin: 0 auto; margin-bottom: 20px;">
        
                        <!-- Encabezado-->
                        <div v-html="header" style="width: 100% !important; padding-top: 50px"></div>
                        
                        <!-- Configuración del contenido de la pagina -->
                        <div class="subpage" style="height: 950px; padding-top: 20px; overflow: hidden;">
                            <div style="margin-left: 80px; margin-right: 50px;">
                                <div v-if="pageIndex === 0" style="width: 100%;">
                                    <table style="font-size: 12px; border-collapse: collapse; width: 100% !important">
                                        <tr style="background: gray;">
                                            <td colspan="4" style="text-align: center; color: white;">ORDEN DE COMPRA</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                <hr />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 25%;">
                                                <b>Proveedor:</b>
                                            </td>
                                            <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 25%;">
                                                {{ data.data.header?.PurchaseOrderName || data.data.header?.purchaseordername }} 
                                            </td>
        
                                            <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 25%;">
                                                <b>Tipo de factura:</b>
                                            </td>
                                            <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 25%;">
                                                {{ (data.data.header?.PurchaseOrderType == "CCF") ? "Crédito Fiscal" : "" }}
                                                {{ (data.data.header?.PurchaseOrderType == "FAF") ? "Consumidor Final" : "" }}
                                                {{ (data.data.header?.PurchaseOrderType == "NC") ? "Nota de Crédito" : "" }}
                                                {{ (data.data.header?.PurchaseOrderType == "ND") ? "Nota de Débito" : "" }}
                                                {{ (data.data.header?.PurchaseOrderType == "FE") ? "Factura Exportación" : "" }}
                                                
                                                {{ (data.data.header?.purchaseordertype == "CCF") ? "Crédito Fiscal" : "" }}
                                                {{ (data.data.header?.purchaseordertype == "FAF") ? "Consumidor Final" : "" }}
                                                {{ (data.data.header?.purchaseordertype == "NC") ? "Nota de Crédito" : "" }}
                                                {{ (data.data.header?.purchaseordertype == "ND") ? "Nota de Débito" : "" }}
                                                {{ (data.data.header?.purchaseordertype == "FE") ? "Factura Exportación" : "" }}
                                            </td>
        
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 25%;">
                                                <b>ID de orden de compra:</b>
                                            </td>
                                            <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 25%;">
                                                {{ data.data.header?.ID_PurchaseOrder }}
                                            </td>
        
                                            <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 25%;">
                                                <b>ID Legal de facturación:</b>
                                            </td>
                                            <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 25%;">
                                                {{ data.data.header?.ID_PurchaseLegalInvoiceNumber || data.data.header?.purchaselegalinvoicenumber }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 25%;">
                                                <b>Fecha de
                                                    orden de compra:</b>
                                            </td>
                                            <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 25%;">
                                                {{ data.data.header?.PurchaseOrderDate || data.data.header?.purchaseorderdate }}
                                            </td>
                                            <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 25%;">
                                                <b>Fecha de la
                                                    factura:</b>
                                            </td>
                                            <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 25%;">
                                                {{ data.data.header?.PurchaseInvoiceDate || data.data.header?.purchaseinvoicedate }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 25%;">
                                                <b>Número de referencia:</b>
                                            </td>
                                            <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 25%;">
                                                {{ data.data.header?.NReference || data.data.header?.nreference }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 25%;">
                                                <b>Comentario:</b>
                                            </td>
                                            <td v-if="data.data.header?.PurchaseOrderInternalNote" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"
                                            colspan="3">
                                                <div v-html="data.data.header?.PurchaseOrderInternalNote"></div>
                                            </td>
                                            <td v-else style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;" colspan="3">
                                                <div v-html="data.data.header?.purchaseorderinternalnote"></div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <br />
                                <div style="width: 100%;">
                                    <table
                                        style="width: 100% !important; font-size: 11px; border-collapse: collapse; border: 1px solid black;">
                                        <tr v-if="pageIndex === 0" style="background: gray;">
                                            <td colspan="9" style="text-align: center; color: white;">CUERPO DEL DOCUMENTO</td>
                                        </tr>
                                        <tr>
                                            <td style="border: 1px solid; padding-left: 2px; width: 35px;">No.<br />Item</td>
                                            <td style="border: 1px solid; padding-left: 2px; width: 35px;">Cant.</td>
                                            <td style="border: 1px solid; padding-left: 2px; width: 95px;">Código</td>
                                            <td style="border: 1px solid; padding-left: 2px; width: 95px;">Código Alterno</td>
                                            <td style="border: 1px solid; padding-left: 2px;">Descripcion</td>
                                            <td style="border: 1px solid; padding-left: 2px; width: 65px;">Precio<br />unitario
                                                {{ data.data.header.itemcurrency || "$" }}</td>
                                            <td style="border: 1px solid; padding-left: 2px; width: 65px;">Exento
                                                {{ data.data.header.itemcurrency || "$" }}</td>
                                            <td style="border: 1px solid; padding-left: 2px; width: 65px;">No Sujeto
                                                {{ data.data.header.itemcurrency || "$" }}</td>
                                            <td style="border: 1px solid; padding-left: 2px; width: 65px;">Total
                                                {{ data.data.header.itemcurrency || "$" }}</td>
                                        </tr>
                                        <tr v-for="(item, index) in page" :key="index">
                                            <td style="border-left: 1px solid; border-right: 1px solid;">{{ index + 1 }}</td>
                                            <td style="border-left: 1px solid; border-right: 1px solid;">{{ item?.ItemQuantity || item?.ItemPriceQty }}
                                            </td>
                                            <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.ID_Item }}</td>
                                            <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.BarCode }}</td>
                                            <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.ItemName }}
        
                                            </td>
        
                                            <!--Validación precio unitario por item-->
                                            <!--td v-if="item?.ItemNewPrice" style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{
                                                parseFloat((item?.ItemNewPrice)).toFixed(2) }}</td>
                                            <td v-if="item?.ItemUnitCost" style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{
                                                parseFloat((item?.ItemUnitCost)).toFixed(2) }}</td-->
                                            
                                            <!--Validacion precio total por item-->
                                            <td style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{
                                                parseFloat(item.ItemUnitCost).toFixed(2) }}</td>
                                            <td style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{
                                                parseFloat(item.ItemExento).toFixed(2) }}</td>
                                            <td style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{
                                                parseFloat(item.ItemNoSujeto).toFixed(2) }}</td>
                                            <td style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{
                                                parseFloat(item.ItemTotalCost - item.ItemIVA - item.ItemRetTax).toFixed(2) }}</td>
                                        </tr>
        
                                        <tr v-if="pageIndex === pagesRender.length - 1">
                                            <td colspan="5" style="text-align: center; border: 1px solid;"><b>Valor en letras: </b>
                                                {{  }}</td>
        
                                            <td colspan="4" style="border: 1px solid;">
                                                <table style="width: 100% !important; font-size: 11px;">
                                                    <tr>
                                                        <td>Sub total Gravadas</td>
                                                        <td>$</td>
                                                        <td style="text-align: right;">
                                                            {{ parseFloat(data.data.totalinvoice.PurchaseOrderSubTotal).toFixed(2) }}
                                                        </td>
                                                    </tr>
        
                                                    <tr >
                                                        <td>Sub total Exentas</td>
                                                        <td>$</td>
                                                        <td style="text-align: right;">
                                                            {{ parseFloat(data.data.totalinvoice.PurchaseOrderExento).toFixed(2) }}
                                                        </td>
                                                    </tr>
        
                                                    <tr >
                                                        <td>Sub total No Sujetas</td>
                                                        <td>$</td>
                                                        <td style="text-align: right;">
                                                            {{ parseFloat(data.data.totalinvoice.PurchaseOrderNoSujeto).toFixed(2) }}
                                                        </td>
                                                    </tr>
        
                                                    <tr >
                                                        <td>Sub-Total</td>
                                                        <td>$</td>
                                                        <td style="text-align: right;">
                                                            {{ parseFloat(data.data.totalinvoice.PurchaseOrderSubTotal + data.data.totalinvoice.PurchaseOrderExento + data.data.totalinvoice.PurchaseOrderNoSujeto).toFixed(2) }}
                                                        </td>
                                                    </tr>
        
                                                    <tr>
                                                        <td>IVA</td>
                                                        <td>$</td>
                                                        <td style="text-align: right;">
                                                            {{ parseFloat(data.data.totalinvoice.PurchaseOrderTotalIVA).toFixed(2) }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>IVA Retenido</td>
                                                        <td>$</td>
                                                        <td style="text-align: right;">
                                                            {{ parseFloat(data.data.totalinvoice.PurchaseOrderTotalRet).toFixed(2) }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>IVA Percibido</td>
                                                        <td>$</td>
                                                        <td style="text-align: right;">
                                                            {{ parseFloat(data.data.totalinvoice.PurchaseOrderIvaPerc).toFixed(2) }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total</td>
                                                        <td>$</td>
                                                        <td style="text-align: right;">
                                                            {{ parseFloat(data.data.totalinvoice.PurchaseOrderTotal).toFixed(2) }}
                                                        </td>
                                                    </tr>
                                                    
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div v-html="footer" style="width: 100% !important;"></div>
                    </div>
                </div>
            </div>
        </div>
    </window-portal>
</template>

<script>

import jsPDF from "jspdf"
//import html2canvas from "html2canvas"
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue"
export default {
    name: "PrintPurchaseOrder",
    props: {
        data: {
            type: Object,
        },
    },
    components: { WindowPortal },
    data() {
        return {
            header: null,
            footer: null,
            loading: false,
            pagesRender: []
        }
    },
    watch: {
        'data.data.body'() {
            this.calculatePages();
        }
    },
    mounted() {
        this.getHeaderFooter()
    },
    methods: {
        getHeaderFooter() {
            let search = {};
            search.ID_business = JSON.parse(localStorage.getItem("user")).businessid || ""
            search.ID_branch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.$API.HF.getHF(search).then((data) => {
                let header = [], footer = [];
                header = data.filter((x) => x.State == '1' && x.Type == 'Header');
                this.header = header.length > 0 ? JSON.parse(header[0].Body).html : '';
                this.header = this.header.replace(/(\n)/gm, "");
                footer = data.filter((x) => x.State == '1' && x.Type == 'Footer');
                this.footer = footer.length > 0 ? JSON.parse(footer[0].Body).html : '';
            })
        },
        calculatePages() {
            const { body } = this.data.data;
            const firstLength = 54;
            const headerLength = 9;
            let i = 0;
            this.pagesRender[ i ] = [];
            const pageLength = i > 0 ? firstLength + headerLength : firstLength;
            for( const element of body ) {
                this.pagesRender[ i ].push( element )
                if(  this.pagesRender[ i ].length === pageLength ) {
                    i++;
                    this.pagesRender[ i ] = [];
                }
            }
        },
        async Print(){
            this.loading = true;
            console.log(this.data);
            
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            })
            
            
            doc.deletePage(2)

            doc.autoPrint({variant: 'non-conform'});
            const blob = doc.output("bloburl")
            window.open(blob)

            this.loading = false
        },

        async Download(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            })
            
            
            doc.deletePage(2)
            if(this.data.data.header?.ID_PurchaseOrder){
                doc.save(this.data.data.header.ID_PurchaseOrder+ "_"+this.data.data.header.PurchaseOrderDate.replaceAll("/","-") + "_"+this.data.data.header.PurchaseOrderName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".pdf"); 
            }

            if(this.data.data.header?.orderid){
                doc.save(this.data.data.header.orderid+ "_"+this.data.data.header.purchaseorderdate.replaceAll("/","-") + "_"+this.data.data.header.purchaseordername.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".pdf"); 
            }
             
            this.loading = false
        }
    }
}
</script>

<style scoped>
.u-row .u-col-100 {
    width: 100% !important;
}

.btnclean {
    border-radius: 15px;
    border: 1px solid white;
    background-color: #F29D35 !important;
    color: white !important;
}

.btnclose {
    border-radius: 15px;
    border: 1px solid white;
    background-color: rgb(255, 38, 38) !important;
    color: white !important;

}

* {
    font-family: Arial, Helvetica, sans-serif !important;
}</style>
