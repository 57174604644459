<template>
    <window-portal :open="data.show" @close="data.show = false">
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center><div><p style="font-size: 30px;">Vista previa de impresión de Formulario de Hospitalización</p></div></center>
            <br/>
            <center>
                <button class="btnclean" style="font-size: 18px;" @click="Print()">
                    Imprimir
                </button>
            </center>
            <center><div v-if="loading"><p>Cargando...</p></div></center>
        </div>
        <div class="book" style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px;">

                <!--Configuración de la pagina-->
                <div class="page" ref="dataprint" style="position: relative; width: 1000px; min-height: 1294px; background: white; margin: 0 auto; margin-bottom: 20px;">
                    
                    <!-- Encabezado-->
                    <div v-html="header" style="width: 100% !important; padding-top: 50px"></div>
                        
                    <!-- Configuración del contenido de la pagina -->
                    <div class="subpage" style="height: 950px; padding-top: 20px; overflow: hidden;">
                        <center><h3>Formulario de internación de pacientes</h3></center>
                        <div style="margin-left: 80px; margin-right: 50px;">
                            <center></center>
                            <table style="border-collapse: collapse; width: 100%; margin: 1rem 0;">
                                <tr>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>Paciente</u></td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;">{{data.data.patient}}</td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>Cliente</u></td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;">{{data.data.client}}</td>
                                </tr>
                                <tr>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>Fecha de ingreso</u></td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;">{{data.data.admissionDate}}</td>
                                    <td v-if="data.data.egress" style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>Fecha de egreso</u></td>
                                    <td v-if="data.data.egress" style="padding-right:20px; padding-bottom: 10px; width: 25%;">{{data.data.egressDate}}</td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>Medico responsable</u></td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;">{{data.data.doctor}}</td>
                                </tr>
                            </table>

                            <div style="margin: 1rem 0;">
                                <h4>1. Hospitalización u hospedaje</h4>
                                <table>
                                    <tr>
                                        <td style="padding-right:20px; padding-bottom: 10px; width: 20%;"><strong>Hospitalizado</strong></td>
                                        <td style="padding-right:20px; padding-bottom: 10px; width: 20%;">{{data.data.hospitalized.status ? 'Si' : 'No'}}</td>
                                        <td v-if="data.data.hospitalized.status" style="padding-right:20px; padding-bottom: 10px; width: 20%;">Servicio</td>
                                        <td v-if="data.data.hospitalized.status" style="padding-right:20px; padding-bottom: 10px; width: 20%;">{{data.data.hospitalized.data.NameService}}</td>
                                    </tr>
                                    <tr>
                                        <td v-if="data.data.hospitalized.status" style="padding-right:20px; padding-bottom: 10px; width: 20%;">Tarifa diaria</td>
                                        <td v-if="data.data.hospitalized.status" style="padding-right:20px; padding-bottom: 10px; width: 20%;">{{data.data.hospitalized.price}}</td>
                                        <td v-if="data.data.hospitalized.status" style="padding-right:20px; padding-bottom: 10px; width: 20%;">Días</td>
                                        <td v-if="data.data.hospitalized.status" style="padding-right:20px; padding-bottom: 10px; width: 20%;">{{data.data.hospitalized.days}}</td>
                                    </tr>
                                    <tr>
                                        <td v-if="data.data.hospitalized.status" style="padding-right:20px; padding-bottom: 10px;"><u>Subtotal:</u></td>
                                        <td v-if="data.data.hospitalized.status" style="padding-right:20px; padding-bottom: 10px;">{{data.data.hospitalizedSubTotal}}</td>
                                    </tr>
                                    <tr>
                                        <td style="padding-right:20px; padding-bottom: 10px; width: 20%;"><strong>Hospedado</strong></td>
                                        <td style="padding-right:20px; padding-bottom: 10px; width: 20%;">{{data.data.hosted.status ? 'Si' : 'No'}}</td>
                                        <td v-if="data.data.hosted.status" style="padding-right:20px; padding-bottom: 10px; width: 20%;">Servicio</td>
                                        <td v-if="data.data.hosted.status" style="padding-right:20px; padding-bottom: 10px; width: 20%;">{{data.data.hosted.data.NameService}}</td>
                                    </tr>
                                    <tr>        
                                        <td v-if="data.data.hosted.status" style="padding-right:20px; padding-bottom: 10px; width: 20%;">Tarifa diaria</td>
                                        <td v-if="data.data.hosted.status" style="padding-right:20px; padding-bottom: 10px; width: 20%;">{{data.data.hosted.price}}</td>
                                        <td v-if="data.data.hosted.status" style="padding-right:20px; padding-bottom: 10px; width: 20%;">Días</td>
                                        <td v-if="data.data.hosted.status" style="padding-right:20px; padding-bottom: 10px; width: 20%;">{{data.data.hosted.days}}</td>
                                    </tr>
                                    <tr>
                                        <td v-if="data.data.hosted.status" style="padding-right:20px; padding-bottom: 10px;"><u>Subtotal:</u></td>
                                        <td v-if="data.data.hosted.status" style="padding-right:20px; padding-bottom: 10px;">{{data.data.hostedSubTotal}}</td>
                                    </tr>
                                </table>
                            </div>
                            <div style="margin: 1rem 0;">
                                <h4>{{'2. A) Medicamentos y otros aplicados'}}</h4>
                                <tr v-if="data.data.notDetails1">
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>Servicio a facturar:</u></td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;">{{data.data.serviceToInvoice1}}</td>
                                </tr>
                                <table style="width: 100%;">
                                    <thead style="width: 100% !important;">
                                        <tr style="font-size: 12px; text-align: left;">
                                            <td style="padding-right:20px; padding-bottom: 10px;">Fecha</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">Detalle</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">Medico</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">Cantidad</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">Precio</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">Total</td>
                                        </tr>   
                                    </thead>
                                    <tbody style="font-size: 12px;">
                                        <tr v-for="(item, index) in data.data.medications1" :key="index">
                                            <td style="padding-right:20px; padding-bottom: 10px;">{{item.date}}</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">{{item.detail}}</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">{{item.doctor}}</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">{{item.quantity}}</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">{{item.price}}</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">{{parseFloat(item.price * item.quantity).toFixed(2)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <tr>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>Subtotal:</u></td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;">{{data.data.medicationsSubTotal1}}</td>
                                </tr>
                            </div>
                            <div style="margin: 1rem 0;">
                                <h4>{{'2. B) Medicamentos de farmacia y otros accesorios'}}</h4>
                                <tr v-if="data.data.notDetails2">
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>Servicio a facturar:</u></td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;">{{data.data.serviceToInvoice2}}</td>
                                </tr>
                                <table style="width: 100%;">
                                    <thead style="width: 100% !important;">
                                        <tr style="font-size: 12px; text-align: left;">
                                            <td style="padding-right:20px; padding-bottom: 10px;">Fecha</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">Detalle</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">Medico</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">Cantidad</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">Precio</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">Total</td>
                                        </tr>   
                                    </thead>
                                    <tbody style="font-size: 12px;">
                                        <tr v-for="(item, index) in data.data.medications2" :key="index">
                                            <td style="padding-right:20px; padding-bottom: 10px;">{{item.date}}</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">{{item.detail}}</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">{{item.doctor}}</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">{{item.quantity}}</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">{{item.price}}</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">{{parseFloat(item.price * item.quantity).toFixed(2)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <tr>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>Subtotal:</u></td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;">{{data.data.medicationsSubTotal2}}</td>
                                </tr>
                            </div>
                            <div style="margin: 1rem 0;">
                                <h4>{{'3. Otros servicios'}}</h4>
                                <table style="width: 100%;">
                                    <thead style="width: 100% !important;">
                                        <tr style="font-size: 12px; text-align: left;">
                                            <td style="padding-right:20px; padding-bottom: 10px;">Fecha</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">Detalle</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">Medico</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">Cantidad</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">Precio</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">Total</td>
                                        </tr>   
                                    </thead>
                                    <tbody style="font-size: 12px;">
                                        <tr v-for="(item, index) in data.data.services" :key="index">
                                            <td style="padding-right:20px; padding-bottom: 10px;">{{item.date}}</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">{{item.detail}}</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">{{item.doctor}}</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">{{item.quantity}}</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">{{item.price}}</td>
                                            <td style="padding-right:20px; padding-bottom: 10px;">{{parseFloat(item.price * item.quantity).toFixed(2)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <tr>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>Subtotal:</u></td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;">{{data.data.servicesSubTotal}}</td>
                                </tr>
                            </div>

                            <div style="margin: 1rem 0;">
                                <h3>TOTAL: {{ data.data.total }}</h3>
                            </div>
                                
                        </div>
                    </div>

                    <div v-html="footer" style="width: 100% !important;"></div>
                </div>

        </div>
    </window-portal>
</template>

<script>

import jsPDF from "jspdf"
//import html2canvas from "html2canvas";
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue"
export default {
    name: "HospitalizationForm",
    props: ['data'],
    components: {WindowPortal},
    data() {
        return {

            loading: false,
            pages: 1,
            header: null,
            footer: null,
        }
    },
    mounted() {
        this.getHeaderFooter()
        this.loadContent()
    },
    computed: {
    },
    methods: {
        getHeaderFooter() {
            this.$API.HF.getHF().then((data) => {
                let header = [], footer = [];
                header = data.filter((x) => x.State == '1' && x.Type == 'Header');
                this.header = header.length > 0 ? JSON.parse(header[0].Body).html : '';
                this.header = this.header.replace(/(\n)/gm, "");
                footer = data.filter((x) => x.State == '1' && x.Type == 'Footer');
                this.footer = footer.length > 0 ? JSON.parse(footer[0].Body).html : '';
            })
        },

        async Print(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                    callback: function(doc){
                        doc.output()
                    },
                    x: 0,
                    y: 0,
                    margin: [0, 0, 0, 0],
                    html2canvas:{ 
                        scale: 0.612,
                        //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                        useCORS: false,
                        logging: false,
                        allowTaint: true
                    }
            })
            
            doc.deletePage(2)

            doc.autoPrint({variant: 'non-conform'});
            const blob = doc.output("bloburl")
            window.open(blob)

            this.loading = false
        }
    }
}
</script>

<style scoped>

    .btnclean{
        border-radius: 15px;
        border: 1px solid white;
        background-color: #F29D35 !important;
        color: white !important;
    }
    .btnclose{
        border-radius: 15px;
        border: 1px solid white;
        background-color: rgb(255, 38, 38) !important;
        color: white !important;
       
    }
    

</style>
