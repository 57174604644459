<template>
  <div>
    <v-card flat>
      <v-card-text>
        <template v-if="type === 'medicalclinic'">
          <user-profile-lab
            :patient="data"
            :patientinfo="data"
            :useData="useData"
          ></user-profile-lab>
        </template>
        <template v-else>
          <user-profile
            :patientinfo="patientinfo"
            :data="data"
            :useData="useData"
          />
        </template>
        <v-row style="margin-top: 1rem">
          <v-col
            class="box"
            cols="12"
            style="margin-top: 1rem; background-color: white"
          >
            <v-form v-model="valid" class="position-relative">
              <block v-if="loading"></block>

              <v-col cols="12" class="tabsNh">
                <v-col
                  v-if="
                    medication.length > 0 &&
                    rightTableUniqueParameter == 'ID_PMP'
                  "
                  cols="12"
                  style="display: flex; justify-content: space-between"
                >
                  <div>
                    <v-btn
                      color="error"
                      small
                      v-if="
                        rolesStatus.findIndex(
                          (role) => role.RoleName == 'admin'
                        ) > -1 ||
                        rolesStatus.findIndex(
                          (role) => role.RoleName == 'root'
                        ) > -1 ||
                        rolesStatus.findIndex((role) => role.ID_Role == 23) > -1
                      "
                      @click.prevent="
                        showAlert(
                          'question',
                          'Eiminar',
                          '¿Está seguro de eliminar este registro?',
                          'ConfirmDelete'
                        )
                      "
                      class="btndelete"
                    >
                      <v-icon> mdi-delete </v-icon>
                    </v-btn>
                  </div>
                  <div>
                    <v-btn
                      small
                      @click="
                        print = {
                          show: true,
                          data: {
                            date: date,
                            medication: medication,
                            patientinfo: patientinfo,
                            useData: useData,
                            customer: patientinfo.customer,
                          },
                        }
                      "
                      style="margin-right: 0.3rem"
                    >
                      <v-icon> mdi-printer </v-icon>
                    </v-btn>
                    <v-btn
                      small
                      class="btnblue mr-1"
                      @click="showhistory = !showhistory"
                    >
                      <v-icon> mdi-magnify </v-icon>
                    </v-btn>
                    <v-btn
                      small
                      class="btnclean mr-1"
                      @click.prevent="resetData"
                    >
                      <v-icon> mdi-broom </v-icon>
                    </v-btn>

                    <v-btn
                      small
                      v-if="
                        rolesStatus.findIndex(
                          (role) => role.RoleName == 'admin'
                        ) > -1 ||
                        rolesStatus.findIndex(
                          (role) => role.RoleName == 'root'
                        ) > -1 ||
                        rolesStatus.findIndex((role) => role.ID_Role == 22) > -1
                      "
                      class="btnsave"
                      @click.prevent="updateVeterinaryParameterData"
                    >
                      <v-icon> mdi-content-save </v-icon>
                    </v-btn>
                  </div>
                </v-col>

                <v-col
                  v-if="
                    medication.length > 0 &&
                    rightTableUniqueParameter == 'ID_Plan_Perameter'
                  "
                  cols="12"
                  style="display: flex; justify-content: flex-end"
                >
                  <v-btn
                    small
                    @click="
                      print = {
                        show: true,
                        data: {
                          date: date,
                          medication: medication,
                          patientinfo: patientinfo,
                          useData: useData,
                          customer: patientinfo.customer,
                        },
                      }
                    "
                    style="margin-right: 0.3rem"
                  >
                    <v-icon> mdi-printer </v-icon>
                  </v-btn>
                  <v-btn
                    small
                    class="btnblue mr-1"
                    @click="showhistory = !showhistory"
                  >
                    <v-icon> mdi-magnify </v-icon>
                  </v-btn>

                  <v-btn small class="btnclean mr-1" @click.prevent="resetData">
                    <v-icon> mdi-broom </v-icon>
                  </v-btn>
                  <div>
                    <v-btn
                      small
                      class="btnsave"
                      @click.prevent.stop="SaveMedication"
                      v-if="
                        rolesStatus.findIndex(
                          (role) => role.RoleName == 'admin'
                        ) > -1 ||
                        rolesStatus.findIndex(
                          (role) => role.RoleName == 'root'
                        ) > -1 ||
                        rolesStatus.findIndex((role) => role.ID_Role == 21) > -1
                      "
                    >
                      <v-icon> mdi-content-save </v-icon>
                    </v-btn>
                  </div>
                </v-col>

                <v-col
                  v-if="
                    !(
                      medication.length > 0 &&
                      rightTableUniqueParameter == 'ID_PMP'
                    ) &&
                    !(
                      medication.length > 0 &&
                      rightTableUniqueParameter == 'ID_Plan_Perameter'
                    )
                  "
                  cols="12"
                  style="display: flex; justify-content: flex-end"
                >
                  <v-btn
                    class="mr-1"
                    small
                    @click="
                      print = {
                        show: true,
                        data: {
                          date: date,
                          medication: medication,
                          patientinfo: patientinfo,
                          useData: useData,
                          customer: patientinfo.customer,
                        },
                      }
                    "
                  >
                    <v-icon> mdi-printer </v-icon>
                  </v-btn>
                  <v-btn
                    small
                    class="btnblue mr-1"
                    @click="showhistory = !showhistory"
                  >
                    <v-icon> mdi-magnify </v-icon>
                  </v-btn>

                  <v-btn small class="btnclean" @click.prevent="resetData">
                    <v-icon> mdi-broom </v-icon>
                  </v-btn>
                </v-col>
              </v-col>
              <v-col cols="12" md="3" sm="12" style="margin-top: 1rem">
                <div style="width: 265px !important; display: flex">
                  <date-picker
                    label="Fecha"
                    @dateSelected="
                      (param) => {
                        date = param;
                        plan.MedicationsDate = param;
                      }
                    "
                    v-model="date"
                  ></date-picker>
                </div>
              </v-col>
              <v-col cols="12" md="8" sm="12"> </v-col>

              <v-col
                cols="12"
                md="1"
                style="
                  display: flex;
                  justify-content: flex-end;
                  margin-top: 0.5rem;
                "
              >
                <v-btn
                  @click="showComments = !showComments"
                  :class="plan.Comment ? 'btnclean' : 'btnblack'"
                >
                  Comentarios
                </v-btn>
              </v-col>

              <v-col cols="12" md="4">
                <!--  <v-text-field outlined dense  disabled v-model="plan.PlanName"  label="Plantilla seleccionada"></v-text-field> -->
              </v-col>
              <v-col cols="12" md="6">
                <!--  <template>
                            <v-btn
                          class="btn-add"
                          @click.prevent="plansDialog = !plansDialog"
                          right
                        >
                        Seleccionar Plantilla de medicación/vacunacion
                        </v-btn>
                          &nbsp;
                  
                        </template> -->
              </v-col>

              <!--v-btn @click.prevent="">
                    <v-icon> mdi-mail </v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn dark color="green" @click.prevent="">
                    <v-icon> mdi-whatsapp </v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn @click.prevent="">
                    <v-icon> mdi-file-excel </v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn @click.prevent="">
                    <v-icon> mdi-xml </v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn @click.prevent="">
                    <v-icon> mdi-file-pdf-box </v-icon>
                  </v-btn-->

              <v-col cols="12" md="12">
                <v-col
                  cols="12"
                  style="display: flex; justify-content: flex-end"
                >
                  <!-- <v-btn
                    v-if="plan.PlanName"
                        class="btn-add"
                        @click.prevent.stop="()=>{ showAllMedication = false ;medicationDialog = !medicationDialog}"
                        right

                      >
                       Agregar Medicamentos o Vacunas de plantilla
                  </v-btn> -->
                  &nbsp;
                  <v-btn
                    class="btn-add"
                    @click.prevent.stop="
                      () => {
                        showAllMedication = true;
                        medicationDialog = !medicationDialog;
                      }
                    "
                  >
                    Agregar Medicamentos o Vacunas
                  </v-btn>
                </v-col>
              </v-col>

              <DxDataGrid
                :scrolling="{ useNative: true }"
                :show-borders="true"
                :data-source="medication"
                key-expr=""
                :onRowRemoving="handleRowDeleting"
                @row-updated="UpdateRow"
              >
                <DxEditing
                  :allow-updating="true"
                  :allow-deleting="true"
                  :confirmDelete="false"
                  :select-text-on-edit-start="true"
                  start-edit-action="click"
                  mode="cell"
                  :use-icons="true"
                />
                <DxColumn
                  :width="100"
                  :allow-editing="true"
                  caption="Orden"
                  data-field="Order"
                />
                <!--DxColumn
                  :width="30"
                    :allow-editing="false"
                    data-type="boolean"
                    data-field="checkbox"
                    header-cell-template="headerId"
                  ></DxColumn-->
                <DxKeyboardNavigation
                  :edit-on-key-press="true"
                  enter-key-action="moveFocus"
                  enter-key-direction="column"
                />
                <DxColumn
                  data-field="Medicine"
                  :allow-editing="false"
                  header-cell-template="headerVaccines"
                  :width="200"
                ></DxColumn>
                <DxColumn
                  :width="100"
                  data-field="PeriodTime"
                  header-cell-template="headerPeriodTime"
                  data-type="number"
                ></DxColumn>
                <DxColumn
                  :width="100"
                  cell-template="periodUnit-selection"
                  header-cell-template="headerUnitPeriod"
                >
                </DxColumn>
                <DxColumn
                  data-field="NextTime"
                  caption="Proxima vez"
                  data-type="date"
                  :allow-editing="false"
                  header-cell-template="headernextTime"
                ></DxColumn>
                <DxColumn
                  header-cell-template="headerItem"
                  cell-template="ItemType"
                  :allow-editing="false"
                />

                <template #periodUnit-selection="{ data }">
                  <span class="scroll-fixing-purchase">
                    <select
                      class="form-control custom-select"
                      v-model="data.data.PeriodUnit"
                      @change="handleMed"
                    >
                      <option
                        v-for="item in items"
                        :value="item.Name"
                        :key="item.ID"
                      >
                        {{ item.NameESP }}
                      </option>
                    </select>
                  </span>
                </template>

                <template #headerPeriodTime>
                  <div>Período</div>
                </template>
                <template #headerUnitPeriod>
                  <div>
                    Unidad de <br />
                    tiempo
                  </div>
                </template>
                <template #headerItem>
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <div style="margin-top: 0.5rem">Tipo</div>
                  </div>
                </template>
                <template #ItemType="{ data }">
                  <div>
                    <label v-if="data.data.ItemType == 'VAC'">Vacuna</label>
                    <label v-if="data.data.ItemType == 'MED'"
                      >Medicamento</label
                    >
                  </div>
                </template>
                <template #headernextTime>
                  <div>
                    Proxima <br />
                    Vez
                  </div>
                </template>
                <template #headerId>
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <div style="margin-top: 0.5rem">ID</div>
                  </div>
                </template>
                <template #headerVaccines>
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <div style="margin-top: 0.5rem">Medicamentos / Vacunas</div>
                  </div>
                </template>
              </DxDataGrid>

              <v-col style="margin-bottom: 1rem"> </v-col>
              <!-- <v-col cols="12">
                  <v-col  v-if="
                           medication.length > 0 &&
                          rightTableUniqueParameter == 'ID_PMP'
                        " cols="12" style="display: flex; justify-content: space-between;margin-bottom: 1rem;">
                    <v-btn
                  
                        @click.prevent=' showAlert("question","Eiminar" ,"¿Está seguro de eliminar este registro?","ConfirmDelete");'
                      class="btndelete"
                    >
                    Eliminar registro
                    </v-btn>
                  
                      <v-btn
                        class="btnsave"
                  
                        @click.prevent="updateVeterinaryParameterData"
                      >
                       Guardar Registro
                      </v-btn>
                  </v-col>
                </v-col>
                <v-col cols="12">
                  <v-col  v-if="
                          medication.length > 0 &&
                          rightTableUniqueParameter == 'ID_Plan_Perameter'
                        " cols="12" style="display: flex; justify-content: flex-end; margin-bottom: 1rem;">
                    <v-btn
                        class="btnsave"
                        @click.prevent.stop="SaveMedication"
                  
                      >
                       Agregar Registro
                      </v-btn>
                    </v-col>
                </v-col> -->
            </v-form>
          </v-col>
        </v-row>
        <br />

        <v-dialog v-model="plansDialog" max-width="700">
          <MedicationPlanPopUp
            @closePlan="closePlan($event)"
          ></MedicationPlanPopUp>
        </v-dialog>
        <v-dialog
          v-model="medicationDialog"
          v-if="plan"
          max-width="800"
          :key="reloadMed"
        >
          <VaccineListPopUp
            :PlanId="plan.ID_Medication_Plan"
            :species="patientinfo.species"
            @closeMedic="closeMedicPopUp($event)"
            :medicationDialog="medicationDialog"
            :GetALL="showAllMedication"
            :PatientMedic="medication"
            :key="reloadMed"
          ></VaccineListPopUp>
        </v-dialog>
        <v-dialog v-model="deleteDialog" v-if="deleteHeader" max-width="500">
          <DeleteUserDialog
            :data="deleteHeader"
            :name="deleteHeader.PlanName"
            :v-if="deleteDialog"
            @close="
              deleteDialog = false;
              deleteHeader = null;
              getHeaderData();
            "
            @f_call="deleteVeterinaryHeader($event)"
          ></DeleteUserDialog>
        </v-dialog>
        <MedicationVaccines :data="print"></MedicationVaccines>
        <alerts
          v-if="alert.show"
          v-on:close_alert="closeAlert"
          v-on:accept_alert="acceptAlert"
          :properties="alert"
        ></alerts>
      </v-card-text>
      <v-dialog v-model="showhistory" scrollable :max-width="$vuetify.breakpoint.smAndDown ? '90%':'60%'">
        <v-card class="pa-4">
          <div
            style="
              margin-top: 0.3rem;
              margin-bottom: 0.3rem;
              display: flex;
              justify-content: flex-end;
            "
          >
            <v-btn small class="btndelete" @click.prevent="showhistory = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <GeneralFilter
            entity="vacuna medicamento"
            :filterEndpoint="$API.medicalmedication.filterMedicationHeader"
            :search="search"
            @emptyFilter="getHeaderData"
            @filtered="filterHandler"
          >
            <DxDataGrid
              :scrolling="{ useNative: true }"
              :show-borders="true"
              :data-source="medicationsHeader"
              key-expr=""
              @exporting="onExporting"
            >
              <DxExport :enabled="true" />
              <DxPaging :page-size="10" />
              <DxColumn
                :width="80"
                cell-template="show-template"
                caption=""
              ></DxColumn>
              <DxColumn data-field="MedicationsDate" caption="Fecha"></DxColumn>
              <DxColumn data-field="PlanName" caption="Plantilla"></DxColumn>
              <DxColumn data-field="UserName" caption="Atendido por"></DxColumn>
              <DxColumn
                v-if="
                  rolesStatus.findIndex((role) => role.RoleName == 'admin') >
                    -1 ||
                  rolesStatus.findIndex((role) => role.RoleName == 'root') >
                    -1 ||
                  rolesStatus.findIndex((role) => role.ID_Role == 23) > -1
                "
                cell-template="show-delete"
                :width="80"
                caption=""
              ></DxColumn>
              <template #show-template="{ data }">
                <div>
                  <a
                    href="#"
                    @click.prevent.stop="
                      getVeterinaryDetails(data.data);
                      deleteHeader = data.data;
                      showhistory = false;
                    "
                  >
                    <v-icon color="primary">mdi-eye</v-icon>
                  </a>
                </div>
              </template>
              <template #show-delete="{ data }">
                <div>
                  <a
                    href=""
                    class="mx-2"
                    @click.prevent.stop="
                      deleteHeader = data.data;
                      showAlert(
                        'question',
                        'Eiminar',
                        '¿Está seguro de eliminar este registro?',
                        'ConfirmDelete'
                      );

                      showhistory = false;
                    "
                  >
                    <v-icon color="error">mdi-delete</v-icon>
                  </a>
                </div>
              </template>
              />
            </DxDataGrid>
          </GeneralFilter>
        </v-card>
      </v-dialog>
    </v-card>
    <v-dialog v-model="showComments" scrollable :max-width="$vuetify.breakpoint.smAndDown ? '90%':'60%'">
      <v-card class="pa-4">
        <div
          style="
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;
            display: flex;
            justify-content: flex-end;
          "
        >
          <v-btn small class="btndelete" @click.prevent="showComments = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-col cols="12" md="12" style="">
          <quill-editor v-model="plan.Comment"></quill-editor>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import Block from "@/components/Block";
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  // DxLookup,
  DxPaging,
  DxKeyboardNavigation,
  DxExport,
} from "devextreme-vue/data-grid";
import DatePicker from "../../../components/DatePicker";
import MedicationPlanPopUp from "@/components/VeterinaryMedication/MedicationPlanList.vue";
import VaccineListPopUp from "@/components/VeterinaryMedication/MedicationList.vue";
import DeleteUserDialog from "../../settings/business/partial/DeleteTemplateDialog.vue";
import alerts from "@/components/Alerts";
import MedicationVaccines from "../../../components/ReportsAndPrintings/MedicatinVaccines.vue";
import { mapState } from "vuex";
import GeneralFilter from "@/components/GeneralFilter.vue";
import { printToExcel } from "@/helpers/printToexcel";
import UserProfile from "@/components/Displays/UserProfileDisplay.vue";
import UserProfileLab from "@/components/Displays/UserProfileDisplayLab.vue";

export default {
  name: "MedicalMedications",
  components: {
    MedicationVaccines,
    alerts,
    DxDataGrid,
    DxColumn,
    DxEditing,
    UserProfile,
    UserProfileLab,
    //  DxLookup,
    DatePicker,
    DxPaging,
    Block,
    MedicationPlanPopUp,
    VaccineListPopUp,
    DeleteUserDialog,
    DxKeyboardNavigation,
    DxExport,
    GeneralFilter,
  },
  props: ["data", "win", "type"],
  data: () => ({
    print: {
      show: false,
      data: [],
    },
    alert: {
      type: "success",
      show: false,
      header: "",
      body: "",
    },
    date: "",
    valid: false,
    useData: null,
    url: null,
    patientinfo: [],
    medicationsHeader: [],
    medication: [],
    loading: false,
    plansDialog: false,
    plan: {},
    showComments: false,
    Comments: null,
    medicationDialog: false,
    deleteDialog: false,
    id_user_login: null,
    deleteHeader: null,
    rightTableUniqueParameter: "ID_Plan_Perameter",
    showAllMedication: false,
    dataToshow: null,
    newTable: [],
    reloadMed: 0,
    items: [
      {
        ID: "1",
        Name: "Day",
        NameESP: "Día",
      },
      {
        ID: "2",
        Name: "Week",
        NameESP: "Semana",
      },
      {
        ID: "3",
        Name: "Month",
        NameESP: "Mes",
      },
      {
        ID: "4",
        Name: "Year",
        NameESP: "Año",
      },
    ],
    showMedicalData: false,
    showhistory: false,
    search: {},
  }),
  computed: {
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
  },
  mounted() {
    this.$store.dispatch("rolesUser/getAllRoles");
    if (this.data) {
      Object.keys(this.data).map((el) => {
        Vue.set(this.patientinfo, el.toLowerCase(), this.data[el]);
      });
      this.url =
        this.$store.getters.getBackendUrl +
        "/public" +
        this.data.ProfilePicPath;
    }
    this.id_user_login = JSON.parse(localStorage.getItem("user")).id;
    this.getHeaderData();
    this.useData = JSON.parse(localStorage.getItem("user"));
    this.search.ID_Patient = this.data.ID_Patient;
  },
  methods: {
    filterHandler(response) {
      this.medicationsHeader = response;
    },
    onExporting(e) {
      printToExcel(e, "Medicamentos y Vacunas");
    },
    orderParams(array) {
      array.sort(function (a, b) {
        if (
          (a["Order"] === "" || isNaN(parseInt(a["Order"]))) &&
          b["Order"] !== "" &&
          !isNaN(parseInt(b["Order"]))
        ) {
          return 1;
        } else if (
          a["Order"] !== "" &&
          !isNaN(parseInt(a["Order"])) &&
          (b["Order"] === "" || isNaN(parseInt(b["Order"])))
        ) {
          return -1;
        } else if (
          (a["Order"] === "" || isNaN(parseInt(a["Order"]))) &&
          (b["Order"] === "" || isNaN(parseInt(b["Order"])))
        ) {
          return 0;
        } else if (parseInt(a["Order"]) === parseInt(b["Order"])) {
          return 0;
        } else {
          return parseInt(a["Order"]) < parseInt(b["Order"]) ? -1 : 1;
        }
      });
      return array;
    },

    closeAlert() {
      this.alert.show = false;
    },
    acceptAlert() {
      this.alert.show = false;
      if (this.alert.options == "ConfirmDelete") {
        this.deleteVeterinaryHeader(this.deleteHeader);
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    async closePlan(e) {
      this.plan = e;
      this.plansDialog = false;
      if (this.plan.ID_Medication_Plan) {
        this.loading = true;
        let data = {
          id: this.plan.ID_Medication_Plan,
          ID_Business:
            JSON.parse(localStorage.getItem("user")).businessid || "",
          ID_Branch: JSON.parse(localStorage.getItem("user")).branch || "",
        };
        this.$API.medicalmedication
          .showParametersByPlanID(data)
          .then(async (response) => {
            let data = [];
            data.push(...response);
            data.forEach((el) => {
              el.checkbox = true;
              el.methods = "insert";
              el.ID_PMP = Math.random().toString(36).substr(2, 9);
            });
            this.medication = await this.manageNextDate(data);
            this.orderParams(this.medication);

            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
            this.showAlert("danger", "Error", "Error al cargar los datos");
          });
      }
    },

    async closeMedicPopUp(e) {
      e.map((el) => {
        if (el.checkbox == true)
          if (this.medication.indexOf(el) == -1)
            this.medication.push({
              ...el,
              methods: "insert",
              ID_PMP: Math.random().toString(36).substr(2, 9),
            });
      });
      this.medication = await this.manageNextDate(this.medication);
      this.medicationDialog = false;
      this.reloadMed++;
    },

    SaveMedication() {
      this.loading = true;
      let exist = false;
      //check if exist some medication in the same day
      for (let x of this.medicationsHeader) {
        if (x.MedicationsDate == this.date) {
          exist = x;
        }
      }
      if (exist) {
        this.showAlert(
          "warning",
          "Error",
          "Ya existe un plan de medicación para la fecha seleccionada"
        );
        this.getVeterinaryDetails(exist);
        this.loading = false;
        return;
      }

      for (let x of this.medication) {
        let body = {
          ID_Medication_Plan: x.ID_Medication_Plan,
          ID_Patient: this.patientinfo.id_patient,
          ID_Medicine: x.ID_Medicine,
          MedicationsDate: this.date,
          Medicine: x.Medicine,
          PeriodTime: x.PeriodTime,
          PeriodUnit: x.PeriodUnit,
          NextTime: x.NextTime,
          Comment: this.plan.Comment,
          ID_User_Insert: this.id_user_login,
          ItemType: x.ItemType,
          Order: x.Order,
        };

        this.$API.medicalmedication.addMedicationParameter(body).then(
          () => {
            this.showAlert(
              "success",
              "Exito",
              "Se ingresaron datos de un medicamento del plan registrado."
            );

            this.loading = false;
            this.resetData();
            this.getHeaderData();
          },
          (err) => {
            console.log(err);
            this.showAlert(
              "danger",
              "Error",
              "No se pudo ingresar datos de un medicamento del plan registrado."
            );
            this.loading = false;
          }
        );
      }
    },

    async manageNextDate(e) {
      for (let x = 0; x < e.length; x++) {
        e[x].NextTime = await this.moment()
          .add(e[x].PeriodTime, e[x].PeriodUnit)
          .format("DD/MM/YYYY");
      }
      return Promise.all(e);
    },

    resetData() {
      this.medication = [];
      this.rightTableUniqueParameter = "ID_Plan_Perameter";
      this.plan = {};
      this.date = this.moment().format("DD/MM/YYYY");
      this.newTable = [];
    },

    DeleteRow(row) {
      this.loading = true;
      this.$API.medicalmedication
        .deleteMedicationPerameter(row.data.ID_PMP)
        .then(
          () => {
            //   this.$toasted.info("Se elimino el medicamento seleccionado");
            this.loading = false;
          },
          (err) => {
            console.log(err);
            this.loading = false;
          }
        );
    },
    handleRowDeleting(e) {
      if (this.rightTableUniqueParameter == "ID_PMP") {
        if (e.data.methods != "insert") {
          this.newTable.push({ data: { ...e.data }, methods: "delete" });
        }
      }
    },

    getHeaderData() {
      console.log(this.data.ID_Patient);
      this.$API.medicalmedication
        .getMedicationHeader(this.data.ID_Patient)
        .then(
          (res) => {
            this.medicationsHeader = res;
            console.log(res);

            if (this.$store.state.vetPatientData.dataLabs) {
              let date = this.$store.state.vetPatientData.dataLabs;

              let value = null;
              this.$store.commit("vetPatientData/cleanData");
              this.medicationsHeader.forEach((element) => {
                if (element.MedicationsDate == date) {
                  value = element;
                }
              });
              if (value) {
                this.getVeterinaryDetails(value);
              }
            }
          },
          (err) => {
            console.log(err);

            //this.$toasted.error(err.message);
          }
        );
    },

    getVeterinaryDetails(data) {
      this.dataToshow = data;
      this.loading = true;

      this.plan = data;
      const body = {
        ID_Medication_Plan: data.ID_Medication_Plan,
        ID_Patient: data.ID_Patient,
        MedicationsDate: data.MedicationsDate,
      };
      this.$API.medicalmedication
        .getPerameterOfPlans(body)
        .then(
          (res) => {
            this.rightTableUniqueParameter = "ID_PMP";
            res.map((data) => {
              data.checkbox = true;
            });
            this.medication = res;
            this.plan.Comment = res[0].Comment;
            this.plan.Prev = res[0].Comment;
            this.orderParams(this.medication);
            this.date = this.medication[0].MedicationsDate;
            this.loading = false;
          },
          (err) => {
            this.loading = false;
            this.showAlert(
              "danger",
              "Error",
              "No se pudo obtener los datos de los planes de medicamentos."
            );
            console.log(err);
            // this.$toasted.error(err.message);
          }
        )
        .catch(() => {
          this.loading = false;
          this.getHeaderData();
        });
    },

    deleteVeterinaryHeader(data) {
      this.loading = true;
      const body = {
        ID_Medication_Plan: data.ID_Medication_Plan,
        ID_Patient: data.ID_Patient,
        MedicationsDate: data.MedicationsDate,
      };
      this.$API.medicalmedication.deleteMedicationParameterBulk(body).then(
        () => {
          this.showAlert(
            "success",
            "Exito",
            "Se elimino el plan de medicamentos seleccionado."
          );
          this.deleteDialog = false;
          this.getHeaderData();
          this.resetData();
          this.loading = false;
        },
        (err) => {
          this.showAlert(
            "danger",
            "Error",
            "No se pudo eliminar el plan de medicamentos seleccionado."
          );
          console.log(err);
          // this.$toasted.error(err.message);
          this.loading = false;
        }
      );
    },
    async handleMed() {
      this.medication = await this.manageNextDate(this.medication);
    },
    async UpdateRow(e) {
      this.medication = await this.manageNextDate(this.medication);
      if (this.rightTableUniqueParameter == "ID_PMP") {
        if (e.data.methods != "insert") {
          this.newTable.push({ data: { ...e.data }, methods: "update" });
        }
      }
      this.orderParams(this.medication);
    },

    updateVeterinaryParameterData() {
      this.medication.forEach((el) => {
        if (el.methods == "insert") {
          this.newTable.push({
            data: { ...el },
            methods: "insert",
            Comment: this.plan.Comment,
          });
        }
      });
      if (this.newTable.length == 0) {
        this.showAlert(
          "danger",
          "Advertencia",
          "No se han realizado cambios en el plan de medicamentos."
        );
        return;
      }
      if (this.plan.Comment != this.plan.Prev) {
        this.plan.NewComment = this.plan.Comment;
      }

      this.$API.medicalmedication
        .updateMedicationPerameter(this.plan, this.newTable)
        .then(
          () => {
            this.showAlert(
              "success",
              "Exito",
              "Se actualizo el plan de medicamentos seleccionado."
            );
            this.getVeterinaryDetails(this.dataToshow);
            this.newTable = [];

            this.loading = false;
          },
          (err) => {
            this.showAlert(
              "danger",
              "Error",
              "No se pudo actualizar el plan de medicamentos seleccionado."
            );
            console.log(err);
            this.loading = false;
            this.newTable = [];
          }
        );
    },
  },
};
</script>

<style scoped>
.btnblack {
  background-color: rgba(60, 60, 60, 1) !important;
  color: white !important;
}
.tabs {
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white;
}

.infopatient {
  margin-top: 5%;
}
.btn-add {
  background-color: #3498db !important;
  color: white;
}
.btnsave {
  background-color: #00a178 !important;
  color: white;
}
.btnclean {
  background-color: #f29d35 !important;
  color: white;
}
.btndelete {
  background-color: #e3191e !important;
  color: white;
}
.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}
.btnblue {
  background-color: #1976d2 !important;
  color: white;
}
.tabsB {
  width: 100%;
  padding: 10px;
  background-color: #1976d2;
  color: white;
  border-radius: 15px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
}
.tabsB:hover {
  cursor: pointer;
}
.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;

  border-radius: 15px;
}
</style>
