import axios from "./axios";

export default {

    getMedicationPlans() {
        const options = {
            method: 'GET',
            url: `patientMedications`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getMedicationPlansByBranch({ID_Business,ID_Branch}) {
        const options = {
            method: 'GET',
            url: `patientMedications/ByBussnies?ID_Business=${ID_Business}&ID_Branch=${ID_Branch}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    showParametersByPlanID({id,ID_Business,ID_Branch}) {
        const options = {
            method: 'GET',
            url: `patientMedications/${id}?ID_Business=${ID_Business}&ID_Branch=${ID_Branch}`,
            headers: { 'content-type': 'application/json' },
            
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    addMedicationParameter(data) {
        const options = {
            method: 'POST',
            url: `patientMedications`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    updateMedicationPerameter(plan, data) {
        const options = {
            method: 'PUT',
            url: `patientMedications/`,
            headers: { 'content-type': 'application/json' },
            data: {
                headers: plan,
                details: data
            },

        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deleteMedicationPerameter(id) {
        const options = {
            method: 'DELETE',
            url: `patientMedications/${id}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deleteMedicationParameterBulk(data) {
        const options = {
            method: 'POST',
            url: `patientMedications/bulkDelete`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getPerameterOfPlans(data) {
        const options = {
            method: 'POST',
            url: `patientMedications/bulk/Manageperameters`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getMedicationHeader(id) {
        const options = {
            method: 'GET',
            url: `patientMedications/medical/headers/${id}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    filterMedicationHeader({ID_Patient, filter}) {
        console.log(ID_Patient, filter)
        const options = {
            method: 'GET',
            url: `patientMedications/medical/headersFilter`,
            headers: { 'content-type': 'application/json' },
            params: {
                id: ID_Patient,
                filter
            }
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    
    getMedicationProducts({ID_Business,ID_Branch}) {
        const options = {
            method: 'GET',
            url: `patientMedications/products?ID_Business=${ID_Business}&ID_Branch=${ID_Branch}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getMedicationProductsHistory() {
        const options = {
            method: 'GET',
            url: `patientMedications/products/history`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    }

}