<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            USUARIO: {{user.UserName}}
            <template v-slot:actions>
                <v-btn class="mr-5" @click.native.stop="reloadView++" icon small>
                    <v-icon color="primary">mdi-reload</v-icon>
                </v-btn>
                <v-btn @click="$store.dispatch('deleteWindowByUuid',{uuid : win.uuid})" icon small>
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-card>
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>

                    <v-tab href="#tab-1">
                        GESTIÓN DE USUARIO
                        <v-icon>mdi-account-box</v-icon>
                    </v-tab>


                    <v-tab href="#tab-2" v-if="$store.getters['rolesUser/isAdminOrManager']">
                        GESTIÓN DE ROL DE USUARIO
                        <v-icon>mdi-pencil-circle</v-icon>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                        <v-card flat class="pa-1">
                            <v-card-text class="white">
                                <ManageUser :key="reloadView" :win="win" :user="user"></ManageUser>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-2" v-if="$store.getters['rolesUser/isAdminOrManager']">
                        <v-card flat>
                            <v-card-text class="pa-1">
                                <ManageRoles :key="reloadView" :user="user"></ManageRoles>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import ManageUser from './ManageUser';
    import ManageRoles from './ManageRoles';

    export default {
        name: "UserData",
        props: ['win'],
        components: {
            ManageUser, ManageRoles
        },
        data() {
            return {
                tab: null,
                user: null,
                reloadView: 0
            }
        },
        mounted() {
            this.user = this.win.meta.user;
        },
        methods: {
            reloadData(payload) {
                this.$emit('reloadData', payload)
            }
        }

    }
</script>

<style scoped>

</style>
