const labcustomerDetails = {
    namespaced: true,
    state: () => ({
        currentView: "tab-1",
        data: 'none',
        reloaders: {
            reloadmanagecustomer: 0,
            reloadrpatientlist: 0,
            reloadinvoiceservices: 0,
            
        }
    }),
    mutations: {
        changeView(state, view){
            state.currentView = view
        },
        setData(state, data){
            state.data = data
        },
        setReloaders(state, payload){
            switch(payload) {
                case 'reloadmanagecustomer':
                    state.reloaders.reloadmanagecustomer++;
                    break;
                case 'reloadrpatientlist':
                    state.reloaders.reloadrpatientlist++;
                    break;
                case 'reloadinvoiceservices':
                    state.reloaders.reloadinvoiceservices++;
                    break;
                
            }
        }
    },
    actions: {

    }
}

export default labcustomerDetails