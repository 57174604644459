<template>
    <v-card>
        <v-card-title class="text-h5">
            <slot name="title"></slot>
        </v-card-title>

        <v-card-text>

            <div class="my-5">
                <slot name="body"></slot>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "Modal",
        props: ['title']
    }
</script>

<style scoped>

</style>