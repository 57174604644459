<template>
    <div class="text-center text--primary">
        CREATE A FRAME TO UPLOAD MANY FILES AND SAVE IT IN A PATH IN THE BACKEND. THE PATH WHERE ALL THE IMAGE ARE SAVED SHOULD BE upload/"The username"/
    </div>
</template>

<script>
    export default {
        name: "PersonalImages"
    }
</script>

<style scoped>

</style>
