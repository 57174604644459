<template>
    <v-card flat class=" position-relative">
        <block v-if="loading"></block>
        <v-row class="my-4">
            <v-col cols="6">
                <v-btn class="btn-add"  @click="createQuotesBill()" v-if="$store.getters['rolesUser/getCustomRole'](72)">
                    <v-icon class="mr-2">mdi-plus</v-icon>
                    Crear cotización
                </v-btn>
            </v-col>
        </v-row>

        <div>
            <GeneralFilter class="backgroundGlobal border-boxGlobal" :search="filter" :filterEndpoint="$API.invoices.getInvoicesFilteringNotBilled" @emptyFilter="getAllInvoices" @filtered="filterHandler">
                <DxDataGrid class="isScrolledByGeneralFilter" :data-source="invoices" key-expr="ID_Invoice" @exporting="onExporting"
                    :paging="{ enabled: false }" style="max-height: 600px;">
                    <DxExport :enabled="true" />
                    <!--DxPaging :page-size="10" /-->
                    <DxColumn :width="100" cell-template="show-template" caption=""></DxColumn>
                    <DxColumn data-field="ID_Invoice" caption="ID de cotización"></DxColumn>
                    <DxColumn data-field="InvoiceDate" caption="Fecha de creación"></DxColumn>
                    <DxColumn data-field="ID_Customer" caption="ID de cliente o negocio"></DxColumn>
                    <DxColumn data-field="InvoiceName" caption="Nombre Legal"></DxColumn>
                    <DxColumn cell-template="show-isbill" caption="Facturado"></DxColumn>
                    <DxColumn data-field="InvoiceTotalPagarToShow" caption="Total de la Factura"></DxColumn>

                    <template #show-isbill="{ data }">
                        <div>{{ data.data.IsQuoteBill ? "SI" : "NO" }}</div>
                    </template>

                    <template #show-template="{ data }">
                        <div>
                            <a href="#" class="mx-1" @click.prevent.stop="showInvoice = data; showModal = true">
                                <v-icon color="primary">mdi-eye</v-icon>
                            </a>

                            <a href="#" v-if="$store.getters['rolesUser/getCustomRole'](75)" class="mx-1" title="Exportar a excel" @click.prevent.stop="exportExcel(data.data)">
                                <v-icon>mdi-file-excel</v-icon>
                            </a>

                            <a href="#" v-if="$store.getters['rolesUser/getCustomRole'](76)"  class="mx-1" title="Imrpimir pdf" @click.prevent.stop="reloadPrint++ ; exportPdf(data.data)">
                                <v-icon>mdi-printer</v-icon>
                            </a>
                        </div>
                    </template>
                </DxDataGrid>
            </GeneralFilter>
        </div>

        <v-dialog v-model="showModal" max-width="75%">
            <v-card v-if="showInvoice">
                <invoice-details :hasDeleteBtn="true" :invoice="showInvoice.data" :key="showInvoice.data.ID_Invoice"  :hasQoute="true"
                    @close="hideModal" @billNow="billNow"></invoice-details>
            </v-card>
        </v-dialog>

        <alerts style="z-index: 20000 !important;" v-if="alert.show" v-on:close_alert="closeAlert"
            v-on:accept_alert="acceptAlert" :properties="alert">
        </alerts>

        <print-quotes-bill :dataProps="print" :key="reloadPrint"></print-quotes-bill>
    </v-card>
</template>

<script>
import ExcelJS from 'exceljs'
import { DxDataGrid, DxColumn, DxExport } from 'devextreme-vue/data-grid';
import GeneralFilter from '../../components/GeneralFilter.vue';
import Alerts from "@/components/Alerts";
import { printToExcel } from "@/helpers/printToexcel";
import PrintQuotesBill from "../../components/ReportsAndPrintings/PrintQuotesBill.vue";
import InvoiceDetails from "./InvoiceDetails";
import Block from "@/components/Block";
import { numberWithCommas } from '../../helpers/money';

export default {
    name: "QuotesBill",
    components: { Alerts, DxDataGrid, DxColumn, DxExport, GeneralFilter, InvoiceDetails, PrintQuotesBill, Block },
    data() {
        return {
            loading: false,
            quotesBill: [],
            alert: {
                type: null,
                show: false,
                header: "",
                body: "",
            },
            filter: {
                //pay: true,
                ID_Branch: null,
                ID_Business: null,
                status: 0,
                istemplate: 0,
                isquote: 1
            },
            invoices: null,
            showModal: false,
            showInvoice: null,
            print: {
                show: false,
                data: [],
            },
            reloadPrint: 0
        }
    },
    mounted() {
        this.filter.ID_Branch = JSON.parse(localStorage.getItem("user")).branch;
        this.filter.ID_Business = JSON.parse(localStorage.getItem("user")).businessid;
        this.initialDataCall()
    },
    methods: {
        formatInvoiceFields() {
            // const dictionary = {
            //     '00': "Prueba",
            //     '01': "Producción"
            // };
            const { Currency } = JSON.parse(localStorage.getItem('branch'));
            for(const index in this.invoices) {
                // const { IdentificacionHorEmi, InvoiceType, SystemDateInsert, IdentificacionTipoDteDesc } = this.invoices[ index ];

                // this.invoices[index].AmbienteToShow = dictionary[this.invoices[index].IdentificacionAmbiente];
                this.invoices[index].InvoiceTotalPagarToShow = `${Currency}${numberWithCommas(this.invoices[index].InvoiceTotalPagar)}`;

                // if( InvoiceType === 'TCKS' ) {
                //     this.invoices[ index ].HorEmi = this.formatDateToHour(SystemDateInsert);
                //     this.invoices[ index ].TipoDocumento = "TICKET";
                // } else {
                //     if( IdentificacionTipoDteDesc != null ) {
                //         this.invoices[ index ].TipoDocumento = IdentificacionTipoDteDesc;
                //     } else {
                //         this.invoices[ index ].TipoDocumento = "";
                //     }
                //     if( IdentificacionHorEmi != null ) {
                //         this.invoices[ index ].HorEmi = IdentificacionHorEmi;
                //     } else { 
                //         this.invoices[ index ].HorEmi = "";
                //     }
                // }
                // {{ data.data.IdentificacionHorEmi != null ? data.data.IdentificacionHorEmi : "" }}
                //     {{ data.data.InvoiceType === 'TCKS' ? formatDateToHour(data.data.SystemDateInsert) : "" }}
            }
        },
        hideModal() {
            this.showModal = false;
            this.showInvoice = null;
            this.getAllInvoices();
        },
        acceptAlert() {
            this.alert.show = false;
        },
        showAlert(type, header, body, options = null) {
            type = type == null ? "danger" : type;
            this.alert.type = type;
            this.alert.header = header;
            this.alert.body = body;
            this.alert.show = true;
            this.alert.options = options != null ? options : null;
        },
        closeAlert() {
            this.alert.show = false;
        },
        async initialDataCall() {
            await Promise.all([
                this.getAllInvoices(),
            ])
        },
        createQuotesBill(){
            this.$store.commit("posData/setReloaders", "reloadCreate");
            this.$store.commit("posData/setHeader", null);
            this.$store.commit("posData/setBody", null);
            this.$store.commit("posData/setInvalid", null);
            this.$store.commit("posData/setNDorNC", null);
            this.$store.commit("posData/setQuote", "active");
            this.$emit("close");
            this.$store.commit("posData/changeView", "tab-1");
        },
        filterHandler(response){
            this.invoices = [ ...response ];
        },
        getAllInvoices() {
            this.loading = true
            this.$API.invoices.getInvoicesPOSHistoryNotBilled(this.filter)
                .then(response => {
                    this.loading = false
                    this.invoices = [ ...response ];
                    this.formatInvoiceFields();
                })
        },
        onExporting(e) {
            printToExcel(e, "Lista de contizaciones")
        },
        async exportExcel(data){
            let items = await this.getInvoiceItems(data.ID_Invoice)
            const title = "cotizacion_" + data.ID_Invoice
            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet(title);
            const header = ['COTIZACIÓN CON CÓDIGO ' + data.ID_Invoice]
            worksheet.addRow(header)

            let targetRow = worksheet.getRow(1);
            targetRow.eachCell({ includeEmpty: false }, cell => {
                cell.font = { bold: true };
                cell.alignment = { vertical: 'middle', horizontal: 'left' };
            });

            worksheet.addRow([])

            
            /*CLIENT DATA*/
            const rows = [
                ['ID CLIENTE', data.ID_Customer],
                ['CLIENTE', data.InvoiceName],
                ['DIRECCIÓN', data.InvoiceAddress]
            ];

            rows.forEach(rowData => {
                const row = worksheet.addRow(rowData);
                row.eachCell(cell => {
                    cell.font = { bold: true };
                });
            });

            worksheet.columns.forEach(column => {
            column.width = 15;
            });

            worksheet.addRow([]);

            //PRODUCTS AND SERVICE DETAIL
            let rowIndex = 7;
            worksheet.mergeCells(`A${rowIndex}:E${rowIndex}`)
            worksheet.getCell(`A${rowIndex}`).value = 'PRODUCTO O SERVICIO';
            worksheet.getCell(`F${rowIndex}`).value = 'CANTIDAD';
            worksheet.getCell(`G${rowIndex}`).value = 'PRECIO UNITARIO';
            worksheet.getCell(`H${rowIndex}`).value = 'DESCUENTO';
            worksheet.getCell(`I${rowIndex}`).value = 'EXENTA';
            worksheet.getCell(`J${rowIndex}`).value = 'NO SUJETA';
            worksheet.getCell(`K${rowIndex}`).value = 'TOTAL';
            
            worksheet.getColumn('F').width = 15
            worksheet.getColumn('G').width = 20
            worksheet.getColumn('H').width = 15
            worksheet.getColumn('I').width = 15
            worksheet.getColumn('J').width = 15
            worksheet.getColumn('K').width = 15

            targetRow = worksheet.getRow(rowIndex);
            targetRow.eachCell({ includeEmpty: false }, cell => {
            cell.font = { bold: true };
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
            });

            items.forEach(d => {
                rowIndex++
                worksheet.mergeCells(`A${rowIndex}:E${rowIndex}`)
                worksheet.getCell(`A${rowIndex}`).value = d["ItemDescription"];
                worksheet.getCell(`F${rowIndex}`).value = d["ItemQuantity"];
                worksheet.getCell(`G${rowIndex}`).value = d["ItemUnitPrice"];
                worksheet.getCell(`H${rowIndex}`).value = d["ItemDiscount"];
                worksheet.getCell(`I${rowIndex}`).value = d["ItemExenta"];
                worksheet.getCell(`J${rowIndex}`).value = d["ItemNoSuj"];
                worksheet.getCell(`K${rowIndex}`).value = d["ItemGravada"];

                const targetRow = worksheet.getRow(rowIndex);

                targetRow.eachCell({ includeEmpty: false }, cell => {
                cell.font = { bold: false };
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
                });
            });

            rowIndex++
            worksheet.mergeCells(`I${rowIndex}:J${rowIndex}`)
            worksheet.getCell(`I${rowIndex}`).value = 'SUB TOTAL';
            worksheet.getCell(`K${rowIndex}`).value = data.InvoiceSubTotalVentas;

            targetRow = worksheet.getRow(rowIndex);
            targetRow.eachCell({ includeEmpty: false }, cell => {
            cell.font = { bold: true };
            cell.alignment = { vertical: 'middle', horizontal: 'right' };
            });

            rowIndex++
            worksheet.mergeCells(`I${rowIndex}:J${rowIndex}`)
            worksheet.getCell(`I${rowIndex}`).value = 'TOTAL DESCUENTOS';
            worksheet.getCell(`K${rowIndex}`).value = data.InvoiceTotalDescu;

            targetRow = worksheet.getRow(rowIndex);
            targetRow.eachCell({ includeEmpty: false }, cell => {
            cell.font = { bold: true };
            cell.alignment = { vertical: 'middle', horizontal: 'right' };
            });

            rowIndex++
            worksheet.mergeCells(`I${rowIndex}:J${rowIndex}`)
            worksheet.getCell(`I${rowIndex}`).value = 'IVA RETENIDO';
            worksheet.getCell(`K${rowIndex}`).value = data.InvoiceIvaRete1;

            targetRow = worksheet.getRow(rowIndex);
            targetRow.eachCell({ includeEmpty: false }, cell => {
            cell.font = { bold: true };
            cell.alignment = { vertical: 'middle', horizontal: 'right' };
            });
            
            rowIndex++
            worksheet.mergeCells(`I${rowIndex}:J${rowIndex}`)
            worksheet.getCell(`I${rowIndex}`).value = 'IMPUESTO SOBRE LA RENTA';
            worksheet.getCell(`K${rowIndex}`).value = data.InvoiceReteRenta;

            targetRow = worksheet.getRow(rowIndex);
            targetRow.eachCell({ includeEmpty: false }, cell => {
            cell.font = { bold: true };
            cell.alignment = { vertical: 'middle', horizontal: 'right' };
            });

            rowIndex++
            worksheet.mergeCells(`I${rowIndex}:J${rowIndex}`)
            worksheet.getCell(`I${rowIndex}`).value = 'TOTAL';
            worksheet.getCell(`K${rowIndex}`).value = data.InvoiceTotalPagar;

            targetRow = worksheet.getRow(rowIndex);
            targetRow.eachCell({ includeEmpty: false }, cell => {
            cell.font = { bold: true };
            cell.alignment = { vertical: 'middle', horizontal: 'right' };
            });

            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.setAttribute('hidden', '');
                a.setAttribute('href', url);
                a.setAttribute('download', `${title}.xlsx`);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
        },
        async getInvoiceItems(ID_Invoice) {
            let invoiceItems = []
            
            let response1 = await this.$API.invoices.getInvoiceProductItems(ID_Invoice)

            let response2 = await this.$API.invoices.getInvoiceServiceItems(ID_Invoice)
            
            invoiceItems = invoiceItems.concat(response1, response2)

            return invoiceItems
        },

        async exportPdf(data) {
            this.loading = true
            let branch = JSON.parse(localStorage.getItem("user")).branch
            let business = JSON.parse(localStorage.getItem("user")).businessid
            let businessAndBranchInfo = await this.$API.branches.findSpecificBranch({ "branch" : branch, "business": business })
            let items = await this.getInvoiceItems(data.ID_Invoice)
            this.loading = false
            this.print.data = { "businessInfo" : businessAndBranchInfo, "quoteInfo": data,  "items": items}
            this.print.show = true    
        }
    }
}

</script>

<style scoped>
    .close-icon {
        color: red;
    }

    .filter-btn {
        position: absolute;
        top: 15px;
        left: 0px;
        z-index: 9;
    }

    .btnclean {
        background-color: #F29D35 !important;
        color: white;
    }
</style>