const {
    VUE_APP_MASTER_BACKEND_URL,
    VUE_APP_ENV,
    VUE_APP_MASTER_FRONTEND_URL,
    VUE_APP_CARSEM_ID_KEYMASTER1,
    VUE_APP_CARSEM_ID_KEYMASTER2,
    VUE_APP_CARSEM_ID_KEYMASTER3
} = process.env


const config = {
    VUE_APP_MASTER_BACKEND_URL,
    VUE_APP_ENV,
    VUE_APP_MASTER_FRONTEND_URL,
    VUE_APP_CARSEM_ID_KEYMASTER1,
    VUE_APP_CARSEM_ID_KEYMASTER2,
    VUE_APP_CARSEM_ID_KEYMASTER3
};

for(const element in config){
    if( !config[ element ] ) {
        throw new Error(`Falta la variable de entorno ${element}`);
    }
}

export {
    VUE_APP_MASTER_BACKEND_URL,
    VUE_APP_ENV,
    VUE_APP_MASTER_FRONTEND_URL,
    VUE_APP_CARSEM_ID_KEYMASTER1,
    VUE_APP_CARSEM_ID_KEYMASTER2,
    VUE_APP_CARSEM_ID_KEYMASTER3
}