import axios from "./axios";
// import Vuex from '../store/index';


export default {
    /**
     * Get All Emails
     * @api {Get} manageuser
     */
    getSysparam() {
        const options = {
            method: 'GET',
            url: `sysparameter/getBussBran`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                   
                    resolve(response.data || response);
                 

                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    insertParams(data) {
        const options = {
            method: 'POST',
            url: 'sysparameter/insertbybrbis',
            headers: {'content-type': 'application/json'},
            data
        };
       
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                    console.log(response.data)

                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    updateParams(data) {
        const options = {
            method: 'PUT',
            url: `sysparameter/updatebybrbis`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
}
