import { render, staticRenderFns } from "./PrintPosFacturaTicket.vue?vue&type=template&id=d71ba1dc&scoped=true"
import script from "./PrintPosFacturaTicket.vue?vue&type=script&lang=js"
export * from "./PrintPosFacturaTicket.vue?vue&type=script&lang=js"
import style0 from "./PrintPosFacturaTicket.vue?vue&type=style&index=0&id=d71ba1dc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d71ba1dc",
  null
  
)

export default component.exports