<template>
    <div>
        <block v-if="loading"></block>
        <v-row class="mb-2 d-flex">
            <v-col class="mb-1 d-flex" :class="{'no-wrap': $vuetify.breakpoint.lgAndUp, 'flex-wrap': $vuetify.breakpoint.mdAndDown }" cols="12" sm="12" md="12" lm="12">
                <div>
                    <h4 class="hoveredDiv2 btn-add btn-wrap-text btn-padding mb-2" v-if="$store.getters['rolesUser/getCustomRole'](25)" @click="contingencyProcess()" style="margin-right: 20px;">Paso 1: Ejecutar contingencia
                    </h4>
                </div>
                <div>
                    <h4 class="hoveredDiv btnsave mb-2 btn-wrap-text"  @click="consultBatchProcess()" style="margin-right: 20px;">Paso 2 (a): Comprobar lotes enviados por contingencia
                    </h4>
                </div>
                <div>
                    <h4 class="hoveredDiv btnsave mb-2 btn-wrap-text" @click="consultBatchProcess2()" style="white-space: normal;">Paso 2 (b): Comprobar lotes enviados por contingencia (alternativo)
                    </h4>
                </div>
            </v-col>
            <v-col class="mb-1" cols="12" sm="12" md="5" style="text-align: left;">
                
            </v-col>
        </v-row>
        
        <div class="d-flex flex-wrap align-self-center justify-end mb-3">
            
            <!--v-bottom-sheet v-model="showFilter" inset>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="orange" dark v-bind="attrs" v-on="on">{{$t('vwinvoices_cfilter')}}
                    </v-btn>
                </template>
                <v-sheet class="text-center">
                    <v-btn class="mt-6" text color="error" @click="showFilter = !showFilter">{{$t('vwinvoices_close')}}
                    </v-btn>
                    <v-btn class="mt-6" text color="primary"
                           @click.prevent="getAllInvoices">{{$t('vwinvoices_cfilter')}}
                    </v-btn>
                    <div class="my-3 pa-4">
                        <div>
                            <date-picker :label="$t('vwinvoices_cfromdate')"
                                         @dateSelected="(param) => filter.start = moment(param).format('YYYY/MM/DD')"></date-picker>
                            <date-picker :label="$t('vwinvoices_ctodate')"
                                         @dateSelected="(param) => filter.end = moment(param).format('YYYY/MM/DD')"></date-picker>
                            <v-select outlined dense  v-model="filter.type" attach
                                      :items="filterType"
                                      item-text="name" item-value="key"
                                      :label="$t('vwinvoices_cinvoicetype')">

                            </v-select>
                            <v-checkbox v-model="filter.bill"
                                        :label="$t('vwinvoices_onlyshowpendingtobill')"
                            ></v-checkbox>
                            <v-checkbox v-model="filter.pay"
                                        :label="$t('vwinvoices_onlyshowpendingtopay')"
                            ></v-checkbox>
                            <v-checkbox v-model="filter.onlunull"
                                        :label="$t('vwinvoices_onlyshownullfied')"
                            ></v-checkbox>
                        </div>

                    </div>

                </v-sheet>
            </v-bottom-sheet-->
        </div>
     

        <div class="background rounded-lg">
            <v-row>
                <v-col cols="12">
                    <v-radio-group v-model="filter.typeData" class="ma-3">
                        <v-radio label="Ver reporte con datos de prueba" value="00"></v-radio>
                        <v-radio label="Ver reporte con datos de producción" value="01"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row class="ma-3">
                <v-col cols="12" md="3">
                    <v-checkbox v-model="filter.showInvalid" label="Mostrar invalidadas"></v-checkbox>
                </v-col>
                <v-col cols="12" md="3">
                    <v-checkbox v-model="filter.showRejected" label="Mostrar rechazadas"></v-checkbox>
                </v-col>
                <v-col cols="12" md="3">
                    <v-checkbox v-model="filter.showContingency" label="Mostrar en contingencia"></v-checkbox>
                </v-col>
            </v-row>
            <date-filter class=" mt-3" v-if="!loading" @filtered-data="dateFilterHandler" :dataToFilter="invoices" :search="{ ...this.filter }" nameOfDate="History" :filterEndpoint="$API.invoices.getInvoicesFilteringByDates" :isFilteredByOutsource="isFiltered"/>
            <GeneralFilter class="mt-9" :breakline="true" :filterEndpoint="$API.invoices.getInvoicesFiltering" :search="{ ...this.filter }" @emptyFilter="clearFilter" @filtered="filterHandler" :isAutoScrolled="true">
                <PrintPdfJson class="ml-6 mt-6" :data="invoices" @setLoading="(e) => loading = e "
                    @displaySuccessAlert="() => showAlert('success', 'Descarga exitosa', 'Documentos descargados con éxito.')"
                    @displayErrorAlert="() => showAlert('danger', 'Error al imprimir', 'Ha ocurrido un error al imprimir, por favor intente de nuevo. si el problema persiste contacte al administrador del sistema.')"
                    />
                    <!-- 
                      @displayErrorAlert="(e) => showAlert('danger', 'Error al imprimir', e)"
                   --> 
                <DxDataGrid :data-source="invoices" key-expr="ID_Invoice" @exporting="onExporting"
                    :scrolling="{ useNative: true }"    
                    :column-auto-width="true"
                    :paging="{ enabled: false }" 
                    style="max-height: 600px;"
                >
                    <DxExport :enabled="true"/>
                    <!--DxPaging :page-size="10"/-->
                    <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                    <DxColumn data-field="ID_Invoice" caption="ID" width="200px"></DxColumn>
                    <DxColumn data-field="InvoiceDate" width="100px" caption="Fecha de la Factura"></DxColumn>
                    <DxColumn data-field="HorEmi" width="100px" caption="Hora de la Factura"></DxColumn>
                    <!-- <DxColumn cell-template="hor-emi" width="100px" caption="Hora de la Factura"></DxColumn> -->
                    <!--DxColumn data-field="IdentificacionHorEmi" width="100px" caption="Hora de la Factura"></DxColumn-->
                    <!-- <DxColumn cell-template="tipo-documento" caption="Tipo de Documento"></DxColumn>  -->
                    <DxColumn data-field="TipoDocumento" caption="Tipo de Documento"></DxColumn> 
                    <!--DxColumn data-field="IdentificacionTipoDteDesc" caption="Tipo de Documento"></DxColumn-->
                    <DxColumn data-field="ID_Customer" width="180px" caption="ID de Cliente o Negocio"></DxColumn>
                    <DxColumn data-field="InvoiceName" caption="Nombre legal"></DxColumn>
                    <DxColumn data-field="InvoiceTotalPagarToShow" caption="Total de Factura"></DxColumn>
                    <DxColumn data-field="FeMhEstado" width="150px" caption="Estado"></DxColumn>
                    <!-- <DxColumn cell-template="tipo-ambiente"  width="150px" caption="Ambiente"></DxColumn> -->
                    <DxColumn data-field="AmbienteToShow" width="150px" caption="Ambiente"></DxColumn>
                    <DxColumn data-field="CodigoGeneracion" caption="Codigo Generación"></DxColumn>
                    <DxColumn data-field="NumeroControl" caption="Numero de Control"></DxColumn>
                    <DxColumn data-field="FullName" caption="Usuario"></DxColumn>
                    <DxColumn data-field="FullNameSeller" caption="Vendedor"></DxColumn>
                    <DxColumn data-field="SelloRecepcion" caption="Sello Recepcion"></DxColumn>
                    <DxColumn cell-template="add-nd-or-nc" caption=""></DxColumn>
                    <template #show-template="{data}">
                        <a href="#" class="mx-1" @click.prevent.stop="showInvoiceDetail(data)">
                            <v-icon color="primary">mdi-eye</v-icon>
                        </a>
                    </template>
                    <template #hor-emi="{data}">
                        {{ data.data.IdentificacionHorEmi != null ? data.data.IdentificacionHorEmi : "" }}
                        {{ data.data.InvoiceType === 'TCKS' ? formatDateToHour(data.data.SystemDateInsert) : "" }}
                    </template>
                    <template #tipo-documento="{data}">
                        {{ data.data.IdentificacionTipoDteDesc != null ? data.data.IdentificacionTipoDteDesc : "" }}
                        {{ data.data.InvoiceType === 'TCKS' ? "TICKET" : "" }}
                    </template>
                    <template #add-nd-or-nc="{data}">
                        <v-btn v-if="data.data.FeMhEstado == 'PROCESADO' && data.data.InvoiceType == 'CCF'" class="mx-1 btn-add" 
                        @click.prevent.stop="addNDorNC(data)" >
                            Relacionar ND o NC
                        </v-btn>
                    </template>
                    <template #tipo-ambiente="{data}">
                        {{ data.data.IdentificacionAmbiente == '00' ? "Prueba" : "" }}
                        {{ data.data.IdentificacionAmbiente == '01' ? "Producción" : "" }}
                    </template>
                </DxDataGrid>
            </GeneralFilter>
        </div>
        


        <!-- Show Invoice Modal -->
        <v-dialog v-model="showModal" scrollable max-width="75%" >
            <v-card v-if="showInvoice">
                <invoice-details :hasPrintButtom="true" @close="showModal = false;" :invoice="showInvoice.data" :key="showInvoice.data.ID_Invoice"></invoice-details>
            </v-card>
        </v-dialog>

        <alerts style="z-index: 20000 !important;"
        v-if="alert.show"
        v-on:close_alert="closeAlert"
        v-on:accept_alert="acceptAlert"
        :properties="alert"
        >
        </alerts>

    </div>
</template>

<script>
    import { DxDataGrid, DxColumn, DxExport} from 'devextreme-vue/data-grid';
    import { Workbook } from 'exceljs';
    import { saveAs } from 'file-saver-es';
    import { exportDataGrid } from 'devextreme/excel_exporter';
    import InvoiceDetails from "./InvoiceDetails"; 
    import Block from "@/components/Block";
    import Alerts from "@/components/Alerts";
    import DateFilter from '../../components/DateFilter.vue';
    import GeneralFilter from '../../components/GeneralFilter.vue';
    import PrintPdfJson from '../../components/POS/PrintPdfJson';
    import { numberWithCommas } from '../../helpers/money';

    export default {
        name: "History",
        components: { PrintPdfJson, InvoiceDetails, DxDataGrid, DxColumn, Block, Alerts, DxExport, DateFilter, GeneralFilter },
        data() {
            return {
                loading: false,
                form: {},
                showFilter: false,
                showModal: false,
                invoices: [],
                showInvoice: null,
                filter: {
                    codPointSale: localStorage.getItem("codPointSale"),
                    ID_Branch: JSON.parse(localStorage.getItem("user")).branch || "",
                    ID_Business: JSON.parse(localStorage.getItem("user")).businessid || "" ,
                    user: JSON.parse(localStorage.getItem("user")).id || "",
                    status: 1,
                    istemplate: 0,
                    typeData: '01',
                    showFNS: this.$store.getters['rolesUser/getCustomRole'](247) ? true : false,
                    showInvalid: true,
                    showRejected: true,
                    showContingency: true,
                },
                filterType: [
                    {
                        name: 'CRÉDITO FISCAL', key: 'CCF',
                    }, {
                        name: 'CONSUMIDOR FINAL', key: 'FAF'
                    }, {
                        name: 'NOTA DE CREDITO', key: 'NC'
                    }, {
                        name: 'NOTA DE DÉBITO', key: 'ND'
                    }
                ],
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
                isFiltered: false
            }
        },
        watch: {
          'showModal'() {
              if (!this.showModal) this.showInvoice = null
          },
          'filter.typeData'()  {
                this.getAllInvoices()
          },
          'filter.showInvalid'()  {
                this.getAllInvoices()
          },
          'filter.showRejected'()  {
                this.getAllInvoices()
          },
          'filter.showContingency'()  {
                this.getAllInvoices()
          },
        },
        computed: {
            
        },
        mounted() {
         /*    this.filter.ID_Branch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.filter.ID_Business = JSON.parse(localStorage.getItem("user")).businessid || "" ; */
             this.getAllInvoices()
        },
        methods: {
            formatDateToHour(data) {
                const completeDate = new Date(data);
                const hour = completeDate.getHours();
                const minutes = completeDate.getMinutes();
                const seconds = completeDate.getSeconds();

                return `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
            },
            filterHandler(response) {
                this.isFiltered = true;
                this.invoices = [...response];
                this.formatInvoiceFields();
            },
            dateFilterHandler(filteredData) {
                this.invoices = filteredData;
                this.formatInvoiceFields();
            },
            formatInvoiceFields() {
                const dictionary = {
                    '00': "Prueba",
                    '01': "Producción"
                };
                const { Currency } = JSON.parse(localStorage.getItem('branch'));
                for(const index in this.invoices) {
                    const { IdentificacionHorEmi, InvoiceType, SystemDateInsert, IdentificacionTipoDteDesc } = this.invoices[ index ];

                    this.invoices[index].AmbienteToShow = dictionary[this.invoices[index].IdentificacionAmbiente];
                    this.invoices[index].InvoiceTotalPagarToShow = `${Currency}${numberWithCommas(this.invoices[index].InvoiceTotalPagar.toFixed(2))}`;

                    if( InvoiceType === 'TCKS' ) {
                        this.invoices[ index ].HorEmi = this.formatDateToHour(SystemDateInsert);
                        this.invoices[ index ].TipoDocumento = "TICKET";
                    } else {
                        if( IdentificacionTipoDteDesc != null ) {
                            this.invoices[ index ].TipoDocumento = IdentificacionTipoDteDesc;
                        } else {
                            this.invoices[ index ].TipoDocumento = "";
                        }
                        if( IdentificacionHorEmi != null ) {
                            this.invoices[ index ].HorEmi = IdentificacionHorEmi;
                        } else { 
                            this.invoices[ index ].HorEmi = "";
                        }
                    }
                    // {{ data.data.IdentificacionHorEmi != null ? data.data.IdentificacionHorEmi : "" }}
                    //     {{ data.data.InvoiceType === 'TCKS' ? formatDateToHour(data.data.SystemDateInsert) : "" }}
                }
            },
            clearFilter(){
                this.isFiltered = false;
                this.getAllInvoices();
            },
            onExporting(e) {
                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet('HistorialDeFacturas');

                exportDataGrid({
                    component: e.component,
                    worksheet,
                    topLeftCell: { row: 4, column: 1 },
                }).then((cellRange) => {
                    // header
                    const headerRow = worksheet.getRow(2);
                    headerRow.height = 30;
                    worksheet.mergeCells(2, 1, 2, 8);

                    headerRow.getCell(1).value = 'Historial de Facturas Procesadas';
                    headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 };
                    headerRow.getCell(1).alignment = { horizontal: 'center' };

                    // footer
                    const footerRowIndex = cellRange.to.row + 2;
                    const footerRow = worksheet.getRow(footerRowIndex);
                    worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);

                    footerRow.getCell(1).value = '-';
                    footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true };
                    footerRow.getCell(1).alignment = { horizontal: 'right' };
                }).then(() => {
                    workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'HistorialDeFacturas.xlsx');
                    });
                });
                e.cancel = true;
            },

            closeAlert() {
                this.alert.show = false;

                if (this.alert.options == "reloadInvoices") {
                    this.getAllInvoices()
                }
            },

            showAlert(type, header, body, options = null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
            },

            getAllInvoices() {
                this.loading = true
                this.$API.invoices.getInvoicesPOSHistory(this.filter)
                    .then(response => {
                        this.loading = false
                        this.invoices = response
                        console.log('this.invoices ', this.invoices)
                        this.formatInvoiceFields();
                        console.log(this.invoices);
                    })
            },

            consultBatchProcess() {
                this.loading = true

                this.$API.invoices.consultBatchProcess(this.filter).then(response => {
                    if(response?.status == 1){
                        this.loading = false

                        this.showAlert(
                        "success",
                        "Lotes consultados y facturas actualizadas",
                        "Se ha consultado lotes enviados por contingencia y se ha recibido respuesta, las facturas han sido actualizadas con su respectivo sello y estado de PROCESADAS o RECHAZADAS.",
                        "reloadInvoices"
                        ); 
                    }
                    else if(response?.status == -1){
                        this.loading = false

                        this.showAlert(
                        "warning",
                        "Advertencia",
                        `El Ministerio de Hacienda no ha dado una respuesta sobre el lote de contingencia enviado, favor intentar más tarde.
                        Si el error persiste, favor comunicarse a nuestro contact center.`,
                        "reloadInvoices"
                        ); 
                    }
                    else{
                        this.loading = false
                        
                        this.showAlert(
                        "success",
                        "Lotes consultados",
                        "No se encontro lotes enviados por contingencia recientes.",
                        "reloadInvoices"
                        );
                    }
                })
            },

            consultBatchProcess2() {
                this.loading = true

                this.$API.invoices.consultBatchProcess2(this.filter).then(response => {
                    if(response?.status == 1){
                        this.loading = false

                        this.showAlert(
                        "success",
                        "Lotes consultados y facturas actualizadas",
                        "Se ha consultado lotes enviados por contingencia y se ha recibido respuesta, las facturas han sido actualizadas con su respectivo sello y estado de PROCESADAS.",
                        "reloadInvoices"
                        ); 
                    }
                    else if(response?.status == -1){
                        this.loading = false

                        this.showAlert(
                        "warning",
                        "Advertencia",
                        `El Ministerio de Hacienda no ha dado una respuesta sobre el lote de contingencia enviado, favor intentar más tarde.
                        Si el error persiste, favor comunicarse a nuestro contact center.`,
                        "reloadInvoices"
                        ); 
                    }
                    else{
                        this.loading = false
                        
                        this.showAlert(
                        "success",
                        "Lotes consultados",
                        "No se encontro lotes enviados por contingencia recientes.",
                        "reloadInvoices"
                        );
                    }
                })
            },

            contingencyProcess() {
                this.loading = true
                this.$API.invoices.contingencyProcess(this.filter).then(response => {
                    if(response?.resultContingency?.estado == "RECIBIDO"){
                        let mensaje = "Se ha enviado exitosamente las facturas en estado de contingencia. <br/><br/>";
                        mensaje += "Estado contingencía: " + response.resultContingency.estado + "<br/>";
                        mensaje += "Estado recepción lote: " + response.resultRecepcionLote.estado + "<br/>";
                        mensaje += "Mensaje contingencía: <br/> - "+ response.resultContingency.mensaje + "<br/>";
                        mensaje += "Mensaje recepción lote: <br/> - "+ response.resultRecepcionLote.descripcionMsg + "<br/>"; 
                        mensaje += "Observaciones: <br/>"
                        if(response.resultContingency.observaciones.length == 0){
                            mensaje += "- Ninguna observación <br/>"
                        }
                        else{
                            for(let i = 0; i < response.resultContingency.observaciones.length; i++){
                                mensaje += "- "+ response.resultContingency.observaciones[i] + "<br/>"
                            }
                        }

                        this.loading = false
                            
                        this.showAlert(
                        "successHtml",
                        "Proceso de contingencia realizado",
                        mensaje
                        );
                    }
                    else if (response?.estado == "Empty") {
                        this.loading = false
                        this.showAlert(
                            "successHtml",
                            "Información",
                            "No hay lotes para enviar a contingencia.<br/>" + "<br/>" + "En caso de tener DTEs con estado CONTINGENCIA, favor ejecutar el paso 2, para que pasen al estado PROCESADO o RECHAZADO, de acuerdo a la validación del Ministerio de Hacienda."
                        )
                    }
                    else{
                        let mensaje = "Se ha rechazado la contingencia, tomar en cuenta las observaciones del Ministerio de Hacienda y realizarla de nuevo.<br/><br/>";
                        mensaje += "Estado: " + response.estado + "<br/>";
                        mensaje += "Mensaje del DTE: <br/> - "+ response.mensaje + "<br/>";
                        mensaje += "Observaciones DTE: <br/>"
                        if(response.observaciones.length == 0){
                        mensaje += "- Ninguna observación <br/>"
                        }
                        else{
                            for(let i = 0; i < response.observaciones.length; i++){
                                mensaje += "- "+ response.observaciones[i] + "<br/>"
                            }
                        }

                        this.loading = false
                            
                        this.showAlert(
                        "successHtml",
                        "Proceso de contingencia rechazado",
                        mensaje
                        );
                    }
                })
                .catch(() => {
                    this.loading = false
                    this.showAlert("warning", "Advertencia", "Ocurrio un problema al ejecutar la contingencia. ¡Por favor contáctarse con el administrador del sistema!")
                })
            },

            addNDorNC(data){
                data.data.IsNDorNC = true
                this.showInvoice = data 
                this.showModal = true
            },

            showInvoiceDetail(data){
                data.data.IsNDorNC = false;
                this.showInvoice = data 
                this.showModal = true
            }
        }
    }
</script>

<style scoped>
.hoveredDiv{
    cursor: pointer;
}

.hoveredDiv:hover{
    background-color: #4e9ed3 !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
}

.hoveredDiv2{
    cursor: pointer;
}

.hoveredDiv2:hover{
    background-color: rgb(41, 182, 147) !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
}

.btn-add{
  background-color: #00A178 !important;
  color: white;
}
.btnsave {
  background-color: #3498db !important;
  color: white;
}
.background {
    background: white !important;
    color: white;
}
.btn-wrap-text {
padding: 10px 16px; 
width: fit-content;
font-weight: 500;
font-size:0.875rem;
align-items: center;
    border-radius: 4px;
    display: inline-flex;
    flex: 0 0 auto;
    font-weight: 500;
    letter-spacing: 0.0892857143em;
    justify-content: center;
    outline: 0;
    position: relative;
    text-decoration: none;
    text-indent: 0.0892857143em;
    text-transform: uppercase;
    transition-duration: 0.28s;
    transition-property: box-shadow, transform, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle;
   
}


@media screen and (max-width: 900px) {
    .item {
        padding-bottom: 20px; /* Añade padding en la parte inferior en pantallas más pequeñas */
    }
}



@media screen and (max-width: 521px) {
    .btn-padding {
        margin-bottom: 20px; 
    }
    .btn-wrap-text {
  
  text-align: center; 
  line-height: 1.5; 
  width: 100%; 
 
}

}


</style>