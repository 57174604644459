<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            Buscar usuario
            <template v-slot:actions>
                <v-btn class="mr-5" @click.native.stop="reload" icon small>
                    <v-icon color="primary">mdi-reload</v-icon>
                </v-btn>
                <v-btn @click="$store.dispatch('deleteWindowByUuid',{uuid : win.uuid})" icon small>
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content :key="reloadView">
            <v-card>
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>
                    <v-tab href="#tab-1">
                        Buscar usuario
                        <v-icon>mdi-account-search</v-icon>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                        <v-card flat>
                            <v-card-text class="pa-1">
                                <v-card flat>
                                    <template v-if="$store.getters['rolesUser/isAdminOrManager']">
                                        <GeneralFilter class="background border-box" :filterEndpoint="$API.users.getUsersFiltered" :search="{ business_user }" @emptyFilter="getAllUsers" @filtered="handleFilter">
                                            <DxDataGrid class="isScrolledByGeneralFilter" :data-source="users" key-expr="ID_User"
                                                        :show-row-lines="showRowLines"
                                                        :show-borders="showBorders"
                                                        :row-alternation-enabled="rowAlternationEnabled">
                                                <DxScrolling row-rendering-mode="virtual"/>
                                                <DxPaging :page-size="10"/>
                                                <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                                                <DxColumn data-field="ID_User" caption="ID"></DxColumn>
                                                <DxColumn data-field="UserName" caption="Usuario"></DxColumn>
                                                <DxColumn data-field="FirstName" caption="Nombres"></DxColumn>
                                                <DxColumn data-field="LastName" caption="Apellidos"></DxColumn>
                                                <DxColumn data-field="Email" caption="Correo"></DxColumn>
                                                <DxColumn data-field="seller.ID_Seller" caption="ID Vendedor"></DxColumn>
                                                <DxColumn cell-template="show-seller" caption="Vendedor"></DxColumn>
                                                <template #show-template="{data}">
                                                    <div>
                                                        <a href="javascript:void(0)"
                                                        @click="userSelected(data)">
                                                            <v-icon color="primary" small class="mr-2">mdi-eye</v-icon>
                                                        </a>
                                                        <a href="javascript:void(0)" v-if="data.data.ID_User != myuser"
                                                        @click.prevent="userToDelete = data.data; showAlert('question','Eliminar',
                                                        '¿Está seguro que desea eliminar este usuario?','deletecustomer'
                                                        )">
                                                            <v-icon color="error" small>mdi-delete</v-icon>
                                                        </a>
                                                    </div>
                                                </template>
                                                <template #show-seller="{ data }">
                                                    <div>
                                                        {{ data.data.seller.FirstName }}
                                                        {{ data.data.seller.LastName }}
                                                    </div>
                                                </template>
                                            </DxDataGrid>
                                        </GeneralFilter>
                                    </template>
                                    <template v-else>
                                        <DxDataGrid class="isScrolledByGeneralFilter" :data-source="users" key-expr="ID_User"
                                        :show-row-lines="showRowLines" :show-borders="showBorders" :row-alternation-enabled="rowAlternationEnabled">
                                            <DxScrolling row-rendering-mode="virtual"/>
                                            <DxPaging :page-size="10"/>
                                            <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                                            <DxColumn data-field="ID_User" caption="ID"></DxColumn>
                                            <DxColumn data-field="UserName" caption="Usuario"></DxColumn>
                                            <DxColumn data-field="FirstName" caption="Nombres"></DxColumn>
                                            <DxColumn data-field="LastName" caption="Apellidos"></DxColumn>
                                            <DxColumn data-field="Email" caption="Correo"></DxColumn>
                                            <DxColumn data-field="seller.ID_Seller" caption="ID Vendedor"></DxColumn>
                                            <DxColumn cell-template="show-seller" caption="Vendedor"></DxColumn>
                                            <template #show-template="{data}">
                                                <div>
                                                    <a href="javascript:void(0)"
                                                        @click="userSelected(data)">
                                                        <v-icon small class="mr-2" color="primary">mdi-eye</v-icon>
                                                    </a>
                                                    <a href="javascript:void(0)" v-if="data.data.ID_User != myuser"
                                                        @click.prevent="userToDelete = data.data; showAlert('question','Eliminar',
                                                        '¿Está seguro que desea eliminar este usuario?','deletecustomer'
                                                    )">
                                                        <v-icon small color="error">mdi-delete</v-icon>
                                                    </a>
                                                </div>
                                            </template>
                                            <template #show-seller="{ data }">
                                                <div>
                                                    {{ data.data.seller.FirstName }}
                                                    {{ data.data.seller.LastName }}
                                                </div>
                                            </template>
                                        </DxDataGrid>
                                    </template>
                                </v-card>

                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-expansion-panel-content>

        <v-dialog v-model="deleteDialog" max-width="290">
            <delete-user-dialog :data="userToDelete"
                                @close="closeDialog"
            ></delete-user-dialog>
        </v-dialog>
        <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    ></alerts>
    </v-expansion-panel>

<!--    <manage-user @reloadData="reloadUsers"-->
<!--                 v-if="showUser" :key="showUser.data.ID_User"-->
<!--                 :user="showUser.data"></manage-user>-->
</template>

<script>
    import {DxDataGrid, DxColumn, DxScrolling, DxPaging } from 'devextreme-vue/data-grid';
    import DeleteUserDialog from "./DeleteUserDialog";
    import { mapGetters } from 'vuex';
    import Alerts from "@/components/Alerts";
    import GeneralFilter from '@/components/GeneralFilter.vue';

    export default {
        components: {DeleteUserDialog, DxDataGrid, DxColumn, DxScrolling, DxPaging, Alerts, GeneralFilter},
        name: 'UserList',
        props: ['win'],
        data() {
            return {
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
                search: '',
                tab: null,
                defaultItem: {
                    username: '',
                    firstname: '',
                    lastname: '',
                    email: '',
                },
                users: null,
                showUser: false,
                deleteDialog: false,
                userToDelete: null,
                showRowLines: true,
                showBorders: true,
                rowAlternationEnabled: true,
                business_user: null,
                myuser: null,
                branch_user: null,
                reloadView: 0
            }
        },

        mounted() {
            this.business_user = JSON.parse(localStorage.getItem("user")).business;
            this.branch_user = JSON.parse(localStorage.getItem("user")).branch;
            this.myuser = JSON.parse(localStorage.getItem("user")).id;
            this.getAllUsers();

        },
        computed: {
            ...mapGetters(['reloadData'])
        },
        watch: {
            reloadData: {
                immediate: true,
                handler: 'reloadUsers'
            }
        },
        methods: {
            handleFilter(response) {
                this.users = response;
            },
            closeAlert() {
                this.alert.show = false;
            },
            deleteUser(data) {
                this.$API.users.deleteUser(data.ID_User)
                    .then(() => {
                        this.showAlert("success", "Usuario eliminado", "El usuario ha sido eliminado correctamente");
                        this.getAllUsers()
                    })
            },
            acceptAlert() {
                this.alert.show = false;

                if (this.alert.options == "deletecustomer") {
                    this.deleteUser(this.userToDelete);
                }
            },
            showAlert(type, header, body, options = null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
            },
            getAllUsers() {
                if (!this.$store.getters['rolesUser/isAdminOrManager']) {
                    this.$API.users.getUsers({ID_User: this.myuser})
                    .then(response => {
                        // console.log(response);
                        this.users = response;
                    })
                }
                else {
                    this.$API.users.getUsers(this.business_user)
                    .then(response => {
                        // console.log(response);
                        this.users = response;
                    })    
                }
            },
            async reloadUsers() {
                if (this.reloadData == 'users') {
                    await this.getAllUsers();
                    this.$store.dispatch('reloadData', null)
                }
            },
            closeDialog(payload) {
                this.userToDelete = null;
                this.deleteDialog = false;
                if (payload.isDeleted) {
                    this.getAllUsers()
                }
            },
            userSelected(data) {
                this.$store.dispatch('addWindow', {
                    'name': 'userData',
                    component: 'UserData',
                    unique: false,
                    meta: {user: data.data}
                })
            },
            reload() {
                this.getAllUsers()
                setTimeout(() => {
                    window.scrollTo(0, this.$store.getters.getScrollTop)
                }, 500);
            },
        }
    }
</script>

<style scoped>
    .border-box {
        border-radius: 15px !important;
    }
    .background {
        background: white !important;
        color: white;
    }
</style>
