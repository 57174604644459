import axios from "./axios";

export default {

    /**
     * Add groming related to patient in veterinary
     * @api {POST} veterinarygrooming
     */
     addVeterinaryPrescription(data) {
        const options = {
            method: 'POST',
            url: 'veterinaryprescription',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    /**
     * Get grooming related to patient
     * @param {string} patientID
     * @returns {Promise}
     */
     getVeterinaryPrescription(patientID) {
        const options = {
            method: 'GET',
            url: `veterinaryprescription/${patientID}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getFilterVeterinaryPrescription(data) {
        const options = {
            method: 'GET',
            url: `veterinaryprescription/filter`,
            headers: {'content-type': 'application/json'},
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    /**
     * Update Grooming to Patient
     * @api {PUT} veterinarypatientgrooming
     */
     updateVeterinaryPrescription(id, data) {
        const options = {
            method: 'PUT',
            url: `veterinaryprescription/${id}`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    /**
     * Delete Grooming related to Patient
     * @api {DELETE} veterinarypatientgrooming/:id
     */
     deleteVeterinaryPrescription(id) {
        const options = {
            method: 'DELETE',
            url: `veterinaryprescription/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    }

}