import axios from "./axios";
import axiosPublic from "./axiosPublic";
import Vuex from '../store/index'
import store from "../store";
import router from "../router";

export default {
    /**
     * Login user
     * @param {object} data
     * @api {POST} api/web/login
     */
    login(data, busniessdata) {
        if(process.env.VUE_APP_ENV === '2') { 
            data.WebAccess = true;
        } else {
            data.WebAccess = false;
        }
        const options = {
            method: 'POST',
            url: 'auth/signin',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axiosPublic(options)
                .then(response => {
                    
                    let color;
                    switch (response.data.sidebarColor) {
                        case 'rgba(59, 151, 211,0.8)':
                            case 'rgba(59, 151, 211,1)':
                              color = 'white';
                              break;
                            case 'rgba(65, 70, 77,0.8)':
                            case 'rgba(65, 70, 77,1)':
                              this.color = 'white';
                              break;
                            case 'rgba(227, 29, 31,0.8)':
                            case 'rgba(227, 29, 31,1)':
                              color = 'white';
                              break;
                            case 'rgba(0, 161, 120,0.8)':
                            case 'rgba(0, 161, 120,1)':
                              color = 'white';
                              break;
                            case 'rgba(243, 158, 55,0.8)':
                            case 'rgba(243, 158, 55,1)':
                              color = 'black';
                              break;
                            case 'rgba(255, 255, 255,0.8)':
                            case 'rgba(255, 255, 255,1)':
                              color = 'black';
                              break;
                            case 'rgba(0, 0, 0,0.8)':
                            case 'rgba(0,0,0,0.8)':
                            case 'rgba(0, 0, 0,1)':
                              color = 'white';
                              break;
                            default:
                              color = 'black';
                              break;
                    }
                   
                    let customizedOptions = {
                        backgroundImage: response.data.backgroundImage,
                        sidebarColor: response.data.sidebarColor,
                        sidebarImage: response.data.sidebarImage,
                        color: color,
                        sidebarImageB: response.data.sidebarImageB,
                        minimizeSidebar: response.data.minimizeSidebar,
                        /* sidebarImageB : res.sidebarImage ? res.sidebarImage : previousOptions.sidebarImage,
                        minimizeSidebar: res.MinimizeSidebar? res.MinimizeSidebar: previousOptions.minimizeSidebar, */
                    };
                    resolve(response);
                    busniessdata = busniessdata[0];
                    console.log('==================');
                    console.log(response.data);
                    console.log('TipoPlan ', busniessdata.TipoPlan);
                    //BusinessType businesstype
                    //ID_Business  businessid
                    //BusinessName business;
/*                     business:"none"
businesstype:"none" */
                    if(response.data.business == "none") response.data.business = busniessdata.BusinessName;
                    if(response.data.businesstype == "none") response.data.businesstype = busniessdata.BusinessType;
                    if(!response.data.TipoPlan) response.data.TipoPlan = busniessdata.TipoPlan;
                    if(response.data.businessid == "none" ||  !response.data.businessid   ) response.data.businessid = busniessdata.ID_Business;
                    Vuex.commit('setToken', response.data.accessToken);
                    localStorage.setItem('token', response.data.accessToken);
                    Vuex.commit('setRefreshToken', response.data.refreshToken);
                    localStorage.setItem('refreshToken', response.data.refreshToken);
                    Vuex.commit('setUser', response.data);
                    console.log(response.data)
                    localStorage.setItem('user', JSON.stringify(response.data));
                    Vuex.commit('setCustomizeOptions', JSON.stringify(customizedOptions));
                    console.log(customizedOptions);
                    localStorage.setItem('customizeOptions', JSON.stringify(customizedOptions));
                   
                    console.log(localStorage.getItem('customizeOptions'));
                    localStorage.setItem('globalScheduleTime', response.data?.globalScheduleTime || 0);
                    
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    async logout() {
        try {
            const options = {
                method: 'POST',
                url: 'auth/signout',
                data: {
                    token: localStorage.getItem('refreshToken')
                }
            };

            const response = await axios(options);

            return response;
        } catch (error) {
            console.log("Error ", error)
        }
    },
    async refreshAuth(){
        try {
            const response = await axiosPublic.post("/auth/refreshToken", {
                token: localStorage.getItem('refreshToken')
            } );

            return response.data;
        } catch (error) {
            console.log("Error ", error)
            store.commit("removeUser");
            router.push('/login');
        }
    }
}