<template>
    <window-portal :open="data.show" @close="data.show = false">
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;" v-if="data.prevView == false">
            <center><div><p style="font-size: 30px;">Vista previa de impresión de prescripción</p></div></center>
            <br/>
            <center>
                <button class="btnclean" style="font-size: 18px;" @click="Print()">
                    Imprimir
                </button>
            </center>
            <center><div v-if="loading"><p>Cargando...</p></div></center>
        </div>

        <div class="book" style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px;">
                <div style="margin: 0 auto; width: 1000px;">
                    <div ref="dataprint" style="maring: 0 !important; padding: 0 !important;">
                        <div v-for="(page, indexPage) in pages" :key="indexPage" style="position: relative; width: 1000px; min-height: 1294px; background: white;">
                            <hr/>
                            <div v-html="header" style="width: 100% !important; padding-top: 50px"></div>

                            <div class="subpage" style="height: 950px; padding-top: 20px; overflow: hidden;">
                                <div style="margin-left: 80px; margin-right: 50px;">
                                    <table style="font-size: 12pt; border-collapse: collapse; width: 100%;" v-if="data.prevView == false">
                                        <tr>
                                            <td style="padding-right:20px; padding-bottom: 5px;"><b>ID del registro: </b> {{data.data.header.ID_LaboratoryTest}}</td>
                                            <td style="padding-right:20px; padding-bottom: 5px;"><b>Fecha de realización: </b> {{data.data.header.LaboratoryTestDate}}</td>
                                        </tr>
                                        <tr>
                                            <td style="padding-right:20px; padding-bottom: 5px;"><b>Nombre del paciente: </b> {{data.data.profile.FirstName +" "+ data.data.profile.LastName}}</td>
                                            <td style="padding-right:20px; padding-bottom: 5px;"><b>Edad: </b> {{edad}}</td>
                                        </tr>
                                    </table>
                                    <br/>
                                    <p style="font-size: 12pt;"><b>Resultados del examen:</b></p>
                                    <br/>

                                    <table style="font-size: 12pt; border-collapse: collapse; width: 100%;" v-for="(list, index) in valuesRows[indexPage]" :key="list.ID_PTP">
                                        <template v-if="list.ParameterType == 'T'">
                                            <tr>
                                                <td style="padding-right:20px; padding-bottom: 5px; text-align: center;" colspan="4"><h4>{{list.ParameterName}}</h4></td>
                                            </tr>                        
                                        </template>
                                        
                                        <template v-if="list.ParameterType == 'T' && valuesRows[indexPage][index].ParameterType != 'S'" >
                                            <tr>
                                                <td style="padding-right:20px; padding-bottom: 5px; width: 35%"><b><p>Prueba</p></b></td>
                                                <td style="padding-right:20px; padding-bottom: 5px; width: 20%"><b><p>Resultado</p></b></td>
                                                <td style="padding-right:20px; padding-bottom: 5px; width: 20%"><b><p>Unidad</p></b></td>
                                                <td style="padding-right:20px; padding-bottom: 5px; width: 25%"><b><p>Valores de referencia</p></b></td>
                                            </tr>
                                        </template>

                                        <template v-if="list.ParameterType == 'V'">
                                            <tr>  
                                                <td style="padding-right:20px; padding-bottom: 5px; width: 35%">{{list.ParameterName}}</td>
                                                <td style="padding-right:20px; padding-bottom: 5px; width: 20%">{{list.ParameterValue}}</td>
                                                <td style="padding-right:20px; padding-bottom: 5px; width: 20%">{{list.ParameterUnit}}</td>
                                                <td style="padding-right:20px; padding-bottom: 5px; width: 25%"> {{list.ParameterExpected}}</td>
                                            </tr>                            
                                        </template>

                                        <template v-if="list.ParameterType == 'C'">
                                            <tr>
                                                <td style="padding-right:20px; padding-bottom: 5px;" colspan="3"><br/></td>
                                            </tr>
                                            <tr>  
                                                <td style="padding-right:20px; padding-bottom: 5px;" colspan="5"><b>Comentario: </b>{{list.ParameterName}}</td>
                                            </tr>
                                            <tr>
                                                <td style="padding-right:20px; padding-bottom: 5px;" colspan="3"><br/></td>
                                            </tr>                            
                                        </template>

                                        <template v-if="list.ParameterType == 'W'">
                                            <tr>
                                                <td style="padding-right:20px; padding-bottom: 5px;" colspan="3"><br/></td>
                                            </tr>
                                        </template>

                                        <template v-if="list.ParameterType == 'S'">
                                            <tr>                                                                
                                                <td style="padding-right:20px; padding-bottom: 5px; text-align: center;" colspan="4"><p><u>{{list.ParameterName}}</u></p></td>
                                            </tr>
                                            <tr>
                                                <td style="padding-right:20px; padding-bottom: 5px; width: 35%"><b><p>Prueba</p></b></td>
                                                <td style="padding-right:20px; padding-bottom: 5px; width: 20%"><b><p>Resultado</p></b></td>
                                                <td style="padding-right:20px; padding-bottom: 5px; width: 20%"><b><p>Unidad</p></b></td>
                                                <td style="padding-right:20px; padding-bottom: 5px; width: 25%"><b><p>Valores de referencia</p></b></td>
                                            </tr>
                                        </template>

                                        <template v-if="list.ParameterType == 'F'">
                                            <td style="padding-right:20px; padding-bottom: 5px; text-align: center;" colspan="2">
                                                <center><img height="100px" :src="'data:image/png;base64,' + seal" crossorigin="anonymous"  /></center>
                                            </td>
                                            <td style="padding-right:20px; padding-bottom: 5px; text-align: center;" colspan="2">
                                                <center><img v-if="sealBranch != ''" height="100px" :src="'data:image/png;base64,' + sealBranch" crossorigin="anonymous"  /></center>
                                            </td>
                                        </template>
                                    </table>
                                </div>
                            </div>

                            <div v-html="footer" style="width: 100% !important; border-top: 1px solid;"></div>
                        </div>
                    </div>
                </div>
                <!--div class="page" ref="dataprint" style="position: relative; width: 1000px; min-height: 1294px; background: white; margin: 0 auto; margin-bottom: 20px;">
                    
                    
                        
                    <div class="subpage" style="height: 950px; padding-top: 20px; overflow: hidden;">

                        
                            
                        </div>
                    </div>

                    

                    <div v-html="footer" style="width: 100% !important; border-top: 1px solid;"></div>
                </div-->

        </div>
    </window-portal>
</template>

<script>

import jsPDF from "jspdf"
//import html2canvas from "html2canvas"
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue"
export default {
    name: "PrintLabTest",
    props: ['data'],
    components: {WindowPortal},
    data() {
        return {
            header: null,
            footer: null,
            loading: false,
            seal: "",
            sealBranch: "",
            numPages: 0,
            valuesRows: []
        }
    },
    mounted() {
        this.seal = JSON.parse(localStorage.getItem("user")).seal || ""
        this.sealBranch = JSON.parse(localStorage.getItem("user")).sealBranch || ""
        this.getHeaderFooter()
    },
    
    computed: {
        edad() {
            let edad = this.data.data.profile.Age
            if (edad == null || edad == undefined) {
                return ''
            } else {
                return edad
            }
        },
        pages() {
            // Generar un arreglo con `numPages` elementos
            return Array.from({ length: this.numPages });
        },
    },
    watch: {
        'data.show'() {
            if(this.data.show){
                this.calculatePagesOfDoc()
            }
        }
    },
    methods: {
        getHeaderFooter() {
            let search = {};
            search.ID_business = JSON.parse(localStorage.getItem("user")).businessid || ""
            search.ID_branch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.$API.HF.getHF(search).then((data) => {
                let header = [], footer = [];
                header = data.filter((x) => x.State == '1' && x.Type == 'Header');
                this.header = header.length > 0 ? JSON.parse(header[0].Body).html : '';
                this.header = this.header.replace(/(\n)/gm, "");
                footer = data.filter((x) => x.State == '1' && x.Type == 'Footer');
                this.footer = footer.length > 0 ? JSON.parse(footer[0].Body).html : '';
            })
        },

        calculatePagesOfDoc() {
            let pages = 0
            let rows = 0
            this.valuesRows[pages] = []
            try{
                for(let i = 0; i < this.data.data.body.length; i++){
                    if(rows < 31){
                        if(this.data.data.body[i].ParameterType == 'W'){
                            rows++
                            this.valuesRows[pages].push(this.data.data.body[i])
                        }

                        if(this.data.data.body[i].ParameterType == 'T'){
                            rows = rows + 3
                            this.valuesRows[pages].push(this.data.data.body[i])
                        }

                        if(this.data.data.body[i].ParameterType == 'S'){
                            rows = rows + 2
                            this.valuesRows[pages].push(this.data.data.body[i])
                        }

                        if(this.data.data.body[i].ParameterType == 'V'){
                            rows++
                            this.valuesRows[pages].push(this.data.data.body[i])
                        }

                        if(this.data.data.body[i].ParameterType == 'F'){
                            rows = rows + 4
                            this.valuesRows[pages].push(this.data.data.body[i])
                        }

                        if(this.data.data.body[i].ParameterType == 'C'){
                            rows = rows + 3
                            this.valuesRows[pages].push(this.data.data.body[i])
                        }

                        if(this.data.data.body[i].ParameterType == 'P'){
                            pages++
                            rows = 0
                            this.valuesRows[pages] = []
                        }
                    }
                    else{
                        if(this.data.data.body[i].ParameterType == 'W'){
                            this.valuesRows[pages].push(this.data.data.body[i])
                        }

                        if(this.data.data.body[i].ParameterType == 'T'){
                            this.valuesRows[pages].push(this.data.data.body[i])
                        }

                        if(this.data.data.body[i].ParameterType == 'S'){
                            this.valuesRows[pages].push(this.data.data.body[i])
                        }

                        if(this.data.data.body[i].ParameterType == 'V'){
                            this.valuesRows[pages].push(this.data.data.body[i])
                        }

                        if(this.data.data.body[i].ParameterType == 'F'){
                            this.valuesRows[pages].push(this.data.data.body[i])
                        }

                        if(this.data.data.body[i].ParameterType == 'C'){
                            this.valuesRows[pages].push(this.data.data.body[i])
                        }

                        pages++
                        rows = 0
                        this.valuesRows[pages] = []
                    }
                }
            }
            catch(err) {
                alert(err)
            }
            pages = pages + 1
            this.numPages = pages
        },

        async Print(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                }
            })
            
            
            //doc.deletePage(2)

            doc.autoPrint({variant: 'non-conform'});
            const blob = doc.output("bloburl")
            window.open(blob)

            this.loading = false
        }
    }
}
</script>

<style scoped>
    .u-row .u-col-100{
        width: 100% !important;
    }
    .btnclean{
        border-radius: 15px;
        border: 1px solid white;
  background-color: #F29D35 !important;
  color: white !important;
}
    .btnclose{
        border-radius: 15px;
        border: 1px solid white;
        background-color: rgb(255, 38, 38) !important;
        color: white !important;
       
    }

    * {
        font-family: Arial, Helvetica, sans-serif !important;
    }

</style>
