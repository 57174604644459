import axios from "./axios";

export default {
    getAllAccountingExpensesEntryDetails(ID_AccountingExpensesEntry) {
        const options = {
            method: 'GET',
            url: '/accountingexpensesentrydetail',
            headers: {'content-type': 'application/json'},
            params: {ID_AccountingExpensesEntry}
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    filteredAccountingExpensesEntryDetails(params) {
        const options = {
            method: 'GET',
            url: '/accountingexpensesentrydetail/list/filtered',
            headers: {'content-type': 'application/json'},
            params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    createAccountingExpensesEntryDetail(data) {
        const options = {
            method: 'POST',
            url: '/accountingexpensesentrydetail',
            headers: {'content-type': 'application/json'},
            data,
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};
