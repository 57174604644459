import axios from "./axios";
import Vuex from '../store/index'

export default {
    /**
     * Get All Users
     * @api {Get} manageuser
     */
    getUsers(business) {
        const options = {
            method: 'GET',
            url: `manageuser/${business}`,
            headers: {'content-type': 'application/json'},
            params: business
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    /**
     * Get All Users
     * @api {Get} manageuser
     */
    getUsersHistory(business) {
        const options = {
            method: 'GET',
            url: `manageuser/history/${business}`,
            headers: {'content-type': 'application/json'},
            params: business
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    /**
     * Get All Users
     * @api {Get} manageuser
     */
    getUsersFiltered(business) {
        const options = {
            method: 'GET',
            url: `manageuser/filtered/${business}`,
            headers: {'content-type': 'application/json'},
            params: business
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    
    /**
     * Get business assigned to the user
     * @api {Get} manageuser/sysbusinessuser/:id
     */
    getSysBusinessUser(id) {
        const options = {
            methos: 'GET',
            url: `manageuser/sysbusinessuser/${id}`,
            headers: {'content-type': 'application/json'},
            params: id
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    /**
     * Add Users
     * @api {POST} manageuser
     */
    addUser(data) {
        const options = {
            method: 'POST',
            url: 'manageuser',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },


    /**
     * Add User
     * @api {PUT} manageuser
     */
    updateUser(id, data) {
        const options = {
            method: 'PUT',
            url: `manageuser/${id}`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    },

    updateUserSeller(data) {
        const options = {
            method: 'PUT',
            url: `manageuser/update/seller`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    },


    /**
     * Delete User
     * @api {DELETE} manageuser/:id
     */
    deleteUser(id) {
        const options = {
            method: 'DELETE',
            url: `manageuser/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageageage)
                })
        })
    },

    customize(id, data) {
      
        const options = {
            method: 'PUT',
            url: `manageuser/${id}`,
            headers: {'content-type': 'application/json'},
            data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                    let res = response.data.user;
                    let previousOptions = JSON.parse(localStorage.getItem('customizeOptions'));
                   /*  var color = previousOptions.color;
                    if (data.user) {
                        color = JSON.parse(data.user).Color
                    } */
                    
                    let color;

                    if(res.SidebarColor) {
                    switch (res.SidebarColor) {
                        case 'rgba(59, 151, 211,0.8)':
                            case 'rgba(59, 151, 211,1)':
                              color = 'white';
                              break;
                            case 'rgba(65, 70, 77,0.8)':
                            case 'rgba(65, 70, 77,1)':
                              this.color = 'white';
                              break;
                            case 'rgba(227, 29, 31,0.8)':
                            case 'rgba(227, 29, 31,1)':
                              color = 'white';
                              break;
                            case 'rgba(0, 161, 120,0.8)':
                            case 'rgba(0, 161, 120,1)':
                              color = 'white';
                              break;
                            case 'rgba(243, 158, 55,0.8)':
                            case 'rgba(243, 158, 55,1)':
                              color = 'black';
                              break;
                            case 'rgba(255, 255, 255,0.8)':
                            case 'rgba(255, 255, 255,1)':
                              color = 'black';
                              break;
                            case 'rgba(0, 0, 0,0.8)':
                            case 'rgba(0,0,0,0.8)':
                            case 'rgba(0, 0, 0,1)':
                              color = 'white';
                              break;
                            default:
                              color = 'black';
                              break;
                          
                    }}
                    else{
                     switch (previousOptions.sidebarColor) {
                        case 'rgba(59, 151, 211,0.8)':
                            case 'rgba(59, 151, 211,1)':
                              color = 'white';
                              break;
                            case 'rgba(65, 70, 77,0.8)':
                            case 'rgba(65, 70, 77,1)':
                              this.color = 'white';
                              break;
                            case 'rgba(227, 29, 31,0.8)':
                            case 'rgba(227, 29, 31,1)':
                              color = 'white';
                              break;
                            case 'rgba(0, 161, 120,0.8)':
                            case 'rgba(0, 161, 120,1)':
                              color = 'white';
                              break;
                            case 'rgba(243, 158, 55,0.8)':
                            case 'rgba(243, 158, 55,1)':
                              color = 'black';
                              break;
                            case 'rgba(255, 255, 255,0.8)':
                            case 'rgba(255, 255, 255,1)':
                              color = 'black';
                              break;
                            case 'rgba(0, 0, 0,0.8':
                            case 'rgba(0, 0, 0,1)':
                            case 'rgba(0,0,0,0.8)':
                              color = 'white';
                              break;
                            default:
                              color = 'black';
                              break;
                     }
                    }
                

                if(res.minimizeSidebar==null || res.minimizeSidebar==undefined){
                    res.minimizeSidebar= 'no data'
                  }
                  if(res.sidebarImage==null || res.sidebarImage==undefined){
                    res.sidebarImage= 'no data'
                  }
                  console.log('prev',res.sidebarImage)
                  console.log('news',res.minimizeSidebar)
                    let customizedOptions = {
                        backgroundImage: res.BackgroundImagePath? res.BackgroundImagePath: previousOptions.backgroundImage,
                        sidebarColor: res.SidebarColor ?  res.SidebarColor: previousOptions.sidebarColor,
                        sidebarImage: res.SidebarImagePath? res.SidebarImagePath: previousOptions.sidebarImage,
                        color: color,
                        sidebarImageB : res.sidebarImage == 'no data' ? previousOptions.sidebarImage : res.sidebarImage,
                        minimizeSidebar: res.minimizeSidebar == 'no data' ? previousOptions.minimizeSidebar : res.minimizeSidebar,
                    };
                    Vuex.commit('setCustomizeOptions', JSON.stringify(customizedOptions));
                    localStorage.setItem('customizeOptions', JSON.stringify(customizedOptions));

                })
                .catch(() => {
                    reject()
                })
        })
    },

    getUsersByBranch() {
        const options = {
            method: 'POST',
            url: `manageuser/getUsersByBranch`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
}