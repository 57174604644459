import axios from "./axios";

export default {

    getMedicationPlans(params) {
        const options = {
            method: 'GET',
            url: `managemedication`,
            headers: { 'content-type': 'application/json' },
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    addMedicationPlan(data) {
        const options = {
            method: 'POST',
            url: `managemedication`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    updateMedicationPlan(data) {
        const options = {
            method: 'PUT',
            url: `managemedication`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deleteMedicationPlan(id) {
        const options = {
            method: 'DELETE',
            url: `managemedication/${id}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    showParametersByPlanID(id) {
        const options = {
            method: 'GET',
            url: `managemedication/${id}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getMedicationInventry({business,branch}) {
        const options = {
            method: 'GET',
            url: `managemedication/medication/list?branch=${branch}&business=${business}`,
            headers: { 'content-type': 'application/json' }
          };   
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    addMedicationParameter(data) {
        const options = {
            method: 'POST',
            url: `managemedication/parameters`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deleteMedicationPerameter(id) {
        const options = {
            method: 'DELETE',
            url: `managemedication/parameters/${id}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    updateMedicationPerameter(data) {
        const options = {
            method: 'PUT',
            url: `managemedication/parameters/`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getMedicalsByDate({date,patientid}) {
        const options = {
            method: 'GET',
            url: `managemedication/medicationsBydate/${patientid}`,
            headers: { 'content-type': 'application/json' },
            params: {date:date}
           
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    }
}