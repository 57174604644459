import { getAllIndexes } from "./object";

const nitScores = [
    4,
    10,
    13
];

const dui = async ( model, assignment ) => {
    try {
        let numericValue = await onlyDigits(model, assignment);
        numericValue = numericValue.replace(/-/g, "");
        let formattedValue = "";

        for (let i = 0; i < numericValue.length; i++) {
            formattedValue += numericValue[i];
            if ((i + 1) % 8 === 0 && i !== numericValue.length - 1) {
                formattedValue += "-";
            }
        }

        if (formattedValue.length > 10) {
            await model.$nextTick(() => {
                formattedValue = formattedValue.slice(0, 10);
            });
        }
    
        return formattedValue;
    } catch (error) {
        throw new Error(`Ocurrió el siguiente error al intentar enmascarar el campo dui ${error}`);
    }
};

const duiForHomologation = async ( model, assignment ) => {
    try {
        let numericValue = await onlyDigits(model, assignment);
        numericValue = numericValue.replace(/-/g, "");
        let formattedValue = "";

        for (let i = 0; i < numericValue.length; i++) {
            formattedValue += numericValue[i];
            if ((i + 1) % 8 === 0 && i !== numericValue.length - 1) {
                formattedValue += "-";
            }
        }

        // if (formattedValue.length > 10) {
        //     await model.$nextTick(() => {
        //         formattedValue = formattedValue.slice(0, 10);
        //     });
        // }
    
        return formattedValue;
    } catch (error) {
        throw new Error(`Ocurrió el siguiente error al intentar enmascarar el campo dui ${error}`);
    }
};

const nrc = async ( model, assignment ) => {
    try {
        let numericValue = await onlyDigits(model, assignment);
        numericValue = numericValue.replace(/-/g, "");
        let formattedValue = "";

        for (let i = 0; i < numericValue.length; i++) {
            formattedValue += numericValue[i];
            if (i === numericValue.length - 2) {
                formattedValue += "-";
            }
        }
    
        return formattedValue;
    } catch (error) {
        throw new Error(`Ocurrió el siguiente error al intentar enmascarar el campo dui ${error}`);
    }
};

const nit = async ( model, assignment ) => {
    try {
        let numericValue = await onlyDigits(model, assignment);
        numericValue = numericValue.replace(/-/g, "");
        let formattedValue = "";

        for (let i = 0; i < numericValue.length; i++) {
            formattedValue += numericValue[i];
            if (nitScores.includes(i + 1)) {
                formattedValue += "-";
            }
        }

        if (formattedValue.length > 17) {
            await model.$nextTick(() => {
                formattedValue = formattedValue.slice(0, 17);
            });
        }

        return formattedValue;
        
    } catch (error) {
        throw new Error(`Ocurrió el siguiente error al intentar enmascarar el campo dui ${error}`);
    }
}

const nitForHomologation = async ( model, assignment ) => {
    try {
        let numericValue = await onlyDigits(model, assignment);
        numericValue = numericValue.replace(/-/g, "");
        let formattedValue = "";

        for (let i = 0; i < numericValue.length; i++) {
            formattedValue += numericValue[i];
            if (nitScores.includes(i + 1)) {
                formattedValue += "-";
            }
        }

        if (formattedValue.length > 17) {
            await model.$nextTick(() => {
                formattedValue = formattedValue.slice(0, 17);
            });
        }

        // console.log(formattedValue);

        return formattedValue;
        
    } catch (error) {
        throw new Error(`Ocurrió el siguiente error al intentar enmascarar el campo dui ${error}`);
    }
}

const nitMaskHandlingHomologation = async ( model, assignment ) => {
    try {
        if(assignment.replace('-', '').length >= 14) {
            return await nitForHomologation(model, assignment);
        } else {
            return await duiForHomologation(model, assignment);
        }
    } catch ( error ) {
        throw new Error(`Ocurrió el siguiente error al intentar enmascarar el campo nit ${error}`);
    }
}

const applyNitMaskHandlingHomologationOutOfInput = assignment => {
    try {
        if(assignment.replace( '-', '' ).length >= 14) {
            return applyNITMaskOutOfInput( assignment );
        } else {
            return applyDUIMaskOutOfInput( assignment );
        }
    } catch (error) {
        throw new Error(`Ocurrió el siguiente error al intentar enmascarar el campo nit ${error}`);
    }
}

const onlyDigits = async ( model, assignment ) => {
    // Eliminar letras del campo de entrada
    await model.$nextTick(() => {
        assignment = assignment.replace(/[^0-9]/g, "");
    });

    return assignment;
}

const onlyDigitsAndAPoint = async ( model, assignment ) => {
    // Eliminar letras del campo de entrada
    await model.$nextTick(() => {
        console.log(assignment)
        assignment = assignment.replace(/[^0-9.]/g, "");
        
        const arrayOfChars = assignment.split("");
        
        const allPointIndexes = getAllIndexes( assignment, '.' );
        for( const pointIndex of allPointIndexes ) {
            pointIndex != allPointIndexes[ 0 ] && arrayOfChars.splice( pointIndex, 1 );
        }

        assignment = arrayOfChars.join('');
        
    });

    return assignment.toString();
}

const numberSV =  async ( model, assignment ) => {
    //
    let numericValue = await onlyDigits(model, assignment);
    numericValue = numericValue.replace(/-/g, "");
    
    //accept only 8 digits
    if(numericValue.length > 8){
        await model.$nextTick(() => {
            numericValue = numericValue.slice(0, 8);
        });
    }

    return numericValue;
}

const applyDUIMaskOutOfInput = value => {
    try {
        let newDui = value.split('');
        const newDuiLength = newDui.length;
        const aux = newDui[ newDuiLength - 1];
        newDui[ newDuiLength - 1 ] = '-';
        newDui[ newDuiLength ] = aux;
        
        return newDui.toString().replaceAll(',', '');
    } catch (error) {
        console.error(`Ocurrió el siguiente error al intentar aplicar una máscara sobre el valor del campo dui ${error}`);
    }
}

const applyNITMaskOutOfInput = value => {
    try {
        let newNit = value.split('');
        let counter = 0;
        
        nitScores.map( score => {
            newNit = [
                ...newNit.slice(0, score + counter),
                '-',
                ...value.slice(score)
            ];
            counter ++;
        } );
        
        return newNit.toString().replaceAll(',', '');
    } catch (error) {
        throw new Error(`Ocurrió el siguiente error al intentar aplicar una máscara sobre el valor del campo dui ${error}`);
    }
}

export {
    dui,
    nit,
    nrc,
    onlyDigits,
    applyDUIMaskOutOfInput,
    applyNITMaskOutOfInput,
    numberSV,
    nitMaskHandlingHomologation,
    applyNitMaskHandlingHomologationOutOfInput,
    duiForHomologation,
    nitForHomologation,
    onlyDigitsAndAPoint
};