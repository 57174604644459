<template>
    <v-expansion-panel>
        <v-expansion-panel-header :key="showReload" class="mt-2">
            PACIENTE: {{win.meta.veterinarypatients.NamePatient}}
            <template v-slot:actions>
                <v-btn class="mr-5" @click.native.stop="reload()" icon small>
                    <v-icon color="primary">mdi-reload</v-icon>
                </v-btn>
                <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-card >
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>

                    <v-tab href="#tab-1" @click="changeTab('tab-1')">
                      Datos<Br/> Generales
                        <v-icon>mdi-account-box</v-icon>
                    </v-tab>

                    <v-tab href="#tab-13" @click="changeTab('tab-13')" v-if="EnableViewDoctorPet == 1 && $store.getters['rolesUser/getCustomRole'](137)">
                        Historial <Br/> Médico
                        <v-icon>mdi-clipboard-pulse</v-icon>
                    </v-tab>

                    <v-tab href="#tab-2" @click="changeTab('tab-2')" v-if="EnableViewDoctorPet == 0 && $store.getters['rolesUser/getCustomRole'](137)">
                        Historial <Br/> Médico
                        <v-icon>mdi-battery-check-outline</v-icon>
                    </v-tab>

                    <v-tab href="#tab-10" @click="changeTab('tab-10')" v-if="EnableViewDoctorPet == 0 && $store.getters['rolesUser/getCustomRole'](161)">
                        Prescripción
                        <v-icon>mdi-notebook-check</v-icon>
                    </v-tab>

                    <v-tab href="#tab-3" @click="changeTab('tab-3')" v-if="$store.getters['rolesUser/getCustomRole'](132)">
                        Peluquería
                        <v-icon>mdi-content-cut</v-icon>
                    </v-tab>

                   <!--  <v-tab href="#tab-4" @click="changeTab('tab-4')">
                        Vacunas
                        <v-icon>mdi-needle</v-icon>
                    </v-tab> -->

                    <v-tab href="#tab-5" @click="changeTab('tab-5')" v-if="$store.getters['rolesUser/getCustomRole'](163)">
                        Vacunas<Br/>
                        Medicamentos
                        <v-icon>mdi-needle</v-icon>
                    </v-tab>

                    <v-tab href="#tab-6" @click="changeTab('tab-6')" v-if="$store.getters['rolesUser/getCustomRole'](32)" >
                        Exámenes de <BR/>
                         laboratorio
                        <v-icon>mdi-test-tube</v-icon>
                    </v-tab>

                    <v-tab href="#tab-7" @click="changeTab('tab-7')" v-if="EnableViewDoctorPet == 0 && $store.getters['rolesUser/getCustomRole'](143)">
                        Reproducción
                        <v-icon>mdi-heart-pulse</v-icon>
                    </v-tab>

                    <v-tab href="#tab-8" @click="changeTab('tab-8')" v-if="$store.getters['rolesUser/getCustomRole'](148)">
                        Cirugías
                        <v-icon>mdi-box-cutter</v-icon>
                    </v-tab>

                    <v-tab href="#tab-9" @click="changeTab('tab-9')" v-if="$store.getters['rolesUser/getCustomRole'](153)">
                        Documentos <br />
                        Imagenes
                        <v-icon>mdi-file-document-multiple</v-icon>
                    </v-tab>
                    <v-tab href="#tab-11" @click="changeTab('tab-11')" v-if="$store.getters['rolesUser/getCustomRole'](157)">
                        Reportes Medicos
                        <v-icon>mdi-chart-line-stacked</v-icon>
                    </v-tab>

                    <v-tab href="#tab-12" @click="changeTab('tab-12')" v-if="$store.getters['rolesUser/getCustomRole'](137)">
                        Hospitalización / <Br/>Hospedaje
                        <v-icon>mdi-hospital-box-outline</v-icon>
                    </v-tab>

                </v-tabs>

                <v-tabs-items v-model="tab" touchless >
                    <v-tab-item value="tab-1">
                        <v-card  background-color="transparent">
                            <v-card-text  key="reloadManageVeterinaryPatient" class="pa-1">
                                <ManageVeterinaryPatient :key="reloadNumber"  v-on:updatePatient="setingsprops"   
                                    v-on:rechargue="reload"
                                :data="veterinarypatients" :win="win"></ManageVeterinaryPatient>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-12">
                        <v-card  background-color="transparent">
                            <v-card-text :key="reloadManageVeterinaryHospitalization" class="pa-1">
                                <ManageVeterinaryHospitalization :key="reloadNumber" :data="veterinarypatients" :win="win"></ManageVeterinaryHospitalization>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-2">
                        <v-card  background-color="transparent">
                            <v-card-text :key="reloadVeterinaryMedicalH">
                                <VeterinaryMedicalH :key="reloadNumber" :data="veterinarypatients" :win="win"></VeterinaryMedicalH>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-13">
                        <v-card  background-color="transparent">
                            <v-card-text :key="reloadVeterinaryMedicalHConsolidatedView" class="pa-1">
                                <VeterinaryMedicalHConsolidatedView :key="reloadNumber" :data="veterinarypatients" :win="win"></VeterinaryMedicalHConsolidatedView>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-10">
                        <v-card background-color="transparent">
                            <v-card-text :key="reloadVeterinaryPrescription">
                                <VeterinaryPrescription :key="reloadNumber" :data="veterinarypatients" :win="win"></VeterinaryPrescription>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-3">
                        <v-card flat>
                            <v-card-text :key="reloadVeterinaryGrooming" class="pa-1">
                                <VeterinaryGrooming :key="reloadNumber" :data="veterinarypatients" :win="win"></VeterinaryGrooming>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                  <!--   <v-tab-item value="tab-4">
                        <v-card flat>
                            <v-card-text :key="reloadVeterinaryVaccines">
                                <VeterinaryVaccines :key="reloadNumber" :data="veterinarypatients" :win="win"></VeterinaryVaccines>
                            </v-card-text>
                        </v-card>
                    </v-tab-item> -->

                    <v-tab-item value="tab-5">
                        <v-card flat>
                            <v-card-text :key="reloadVeterinaryMedications" class="pa-1">
                                <VeterinaryMedications :key="reloadNumber" :data="veterinarypatients" :win="win"></VeterinaryMedications>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item value="tab-6">
                        <v-card flat>
                            <v-card-text :key="reloadVeterinaryLaboratoryTest" class="pa-1">
                                <VeterinaryLaboratoryTest :key="reloadNumber" :data="veterinarypatients" :win="win"></VeterinaryLaboratoryTest>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item value="tab-7">
                        <v-card flat>
                            <v-card-text :key="reloadVeterinaryReproduction">
                                <VeterinaryReproduction :key="reloadNumber" :data="veterinarypatients" :win="win"></VeterinaryReproduction>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item value="tab-8">
                        <v-card flat>
                            <v-card-text :key="reloadVeterinarySurgery" class="pa-1">
                                <VeterinarySurgery :key="reloadNumber" :data="veterinarypatients" :win="win"></VeterinarySurgery>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item value="tab-9">
                        <v-card flat>
                            <v-card-text :key="reloadVeterinaryImages" class="pa-1">
                                <VeterinaryImages :key="reloadNumber" :data="veterinarypatients" :win="win"></VeterinaryImages>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-11">
                        <v-card flat>
                            <v-card-text :key="reloadMedicalReports" class="pa-1">
                               <MedicalReports :key="reloadNumber" :data="veterinarypatients" :win="win"></MedicalReports>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>


                </v-tabs-items>
            </v-card>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import ManageVeterinaryPatient from './partial/ManageVeterinaryPatient';
    import ManageVeterinaryHospitalization from './partial/ManageVeterinaryHospitalization';
    import VeterinaryGrooming from './partial/VeterinaryGrooming';
    import VeterinaryMedicalH from './partial/VeterinaryMedicalH';
    import VeterinaryMedicalHConsolidatedView from './partial/VeterinaryMedicalHConsolidatedView';
   // import VeterinaryVaccines from './partial/VeterinaryVaccines';
    import VeterinaryMedications from './partial/VeterinaryMedications';
    import VeterinaryLaboratoryTest from './partial/VeterinaryLaboratoryTest';
    import VeterinaryReproduction from './partial/VeterinaryReproduction';
    import VeterinarySurgery from './partial/VeterinarySurgery';
    import VeterinaryImages from './partial/VeterinaryImages';
    import VeterinaryPrescription from './partial/VeterinaryPrescription';
    import MedicalReports from './partial/MedicalReports.vue';
    import { mapState } from 'vuex';

    export default {
        name: "VeterinaryPatientData",
        props: ['win'],
        data() {
            return {
                tab: null,
                veterinarypatients: null,
                reloadNumber: 0,
                showReload: true,
                useData: {},
                branchConfig: {},
                EnableViewDoctorPet: JSON.parse( localStorage.getItem("user")).enableviewdoctorpet,
            }
        },
        components: {
            ManageVeterinaryPatient,
            ManageVeterinaryHospitalization,
            VeterinaryGrooming,
            VeterinaryMedicalH,
            VeterinaryMedicalHConsolidatedView,
     //       VeterinaryVaccines,
            VeterinaryMedications,
            VeterinaryLaboratoryTest,
            VeterinaryReproduction,
            VeterinarySurgery,
            VeterinaryImages,
            VeterinaryPrescription,
            MedicalReports,
        },
        mounted() {
            this.veterinarypatients = this.win.meta.veterinarypatients;

            if(this.win.meta.reload ){

                this.win.meta.reload = false;
                this.reload();
            }

            this.useData = JSON.parse(localStorage.getItem("user"));
            this.branchConfig = JSON.parse(localStorage.getItem("branch")); 
        },

        computed: {
            ...mapState('vetPatientData', {
                tabState: state => state.currentView,
                reloadManageVeterinaryPatient: state => state.reloaders.ManageVeterinaryPatient,
                reloadManageVeterinaryHospitalization: state => state.reloaders.ManageVeterinaryHospitalization,
                reloadVeterinaryGrooming: state => state.reloaders.VeterinaryGrooming,
                reloadVeterinaryMedicalH: state => state.reloaders.VeterinaryMedicalH,
                reloadVeterinaryMedicalHConsolidatedView: state => state.reloaders.VeterinaryMedicalHConsolidatedView,
                reloadVeterinaryVaccines: state => state.reloaders.VeterinaryVaccines,
                reloadVeterinaryMedications: state => state.reloaders.VeterinaryMedications,
                reloadVeterinaryLaboratoryTest: state => state.reloaders.VeterinaryLaboratoryTest,
                reloadVeterinaryReproduction: state => state.reloaders.VeterinaryReproduction,
                reloadVeterinarySurgery: state => state.reloaders.VeterinarySurgery,
                reloadVeterinaryImages: state => state.reloaders.VeterinaryImages,
                reloadVeterinaryPrescription: state => state.reloaders.VeterinaryPrescription,
                reloadMedicalReports: state => state.reloaders.MedicalReports,
            }),            
        ...mapState(
            "rolesUser",
            {
                rolesStatus: (state) => state.roles,
            })
        },
        watch: {
        tabState() {
            this.tab = this.tabState
        },
    },
        methods: {
            setingsprops(payload) {
                this.veterinarypatients.Weight = payload.veterinarypatient.Weight;
                this.veterinarypatients.weight = payload.veterinarypatient.Weight;
            },
          
            reload(){
                this.reloadNumber++;
                this.$store.commit('vetPatientData/cleanData');
                this.showReload = true
                setTimeout(() => {
                window.scrollTo(0, this.$store.getters.getScrollTop)
                //alert(this.$store.getters.getScrollTop)
                },300);
            },
            changeTab(tab){
                this.$store.commit('vetPatientData/changeView', tab)
                setTimeout(() => {
                window.scrollTo(0, this.$store.getters.getScrollTop)
                //alert(this.$store.getters.getScrollTop)
                },300);
            }
        }

    }
</script>

<style scoped>

</style>
