import axios from "./axios";

export default {

    addVeterinaryReproduction(data) {
        const options = {
            method: 'POST',
            url: 'veterinaryreproduction',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    updateVeterinaryReproduction(data) {
        const options = {
            method: 'POST',
            url: `veterinaryreproduction/update`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getVeterinaryReproduction(params) {
        const options = {
            method: 'GET',
            url: `veterinaryreproduction/${params}`,
            headers: {'content-type': 'application/json'}
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getfilterVeterinaryReproduction(params) {
        const options = {
            method: 'GET',
            url: `veterinaryreproduction/filter`,
            headers: {'content-type': 'application/json'},
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deleteVeterinaryReproduction(id) {
        const options = {
            method: 'DELETE',
            url: `veterinaryreproduction/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getAllReproductionsWith(id){
        const options = {
            method: 'GET',
            url: `veterinaryreproduction/getreproductionswith/${id}`,
            headers: {'content-type': 'application/json'}
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    }
}
