
import axios from "./axios";
// import Vuex from "../store";

export default {
    systemParam() {
        const options = {
            method: 'GET',
            url: 'sysparameter',
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response);
                    // Vuex.commit('setSystemParam', response.data);
                    // localStorage.setItem('systemParam', JSON.stringify(response.data));
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getParameterPool( ParameterType ) {
        const options = {
            method: 'GET',
            url: `sysparameter/pool/${ParameterType}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    // console.log(ParameterType);
                    // console.log(response);
                    resolve(response.data);
                    // Vuex.commit('setSystemParam', response.data);
                    // localStorage.setItem('systemParam', JSON.stringify(response.data));
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    addSystemParam(data) {
        const options = {
            method: 'POST',
            url: 'sysparameter/addparam',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response);
                    // Vuex.commit('setSystemParam', response.data);
                    // localStorage.setItem('systemParam', JSON.stringify(response.data));
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },


    getCatalogMH() {
        const options = {
            method: 'POST',
            url: 'innovacatalogmh/getall',
            headers: {'content-type': 'application/json'},

        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                    // Vuex.commit('setCatalogMH', response.data);
                    // localStorage.setItem('catalogMH', JSON.stringify(response.data));
                })
                .catch(error => {
                    reject(error.message)
                })
        });
    },

    getCitiesCountries() {
        const options = {
            method: 'GET',
            url: 'countriescities/getall',
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    console.log(response.data);
                    resolve(response.data);
                    // Vuex.commit('setCatalogMH', response.data);
                    // localStorage.setItem('catalogMH', JSON.stringify(response.data));
                })
                .catch(error => {
                    reject(error.messageageage)
                })
        });
    },

    getCountries() {
        const options = {
            method: 'GET',
            url: 'countriescities/getCountries',
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                    // Vuex.commit('setCatalogMH', response.data);
                    // localStorage.setItem('catalogMH', JSON.stringify(response.data));
                })
                .catch(error => {
                    reject(error.messageage)
                })
        });
    },

    getStates(countryname) {
        const options = {
            method: 'GET',
            url: 'countriescities/getStates',
            headers: {'content-type': 'application/json'},
            params: {
                countryname
            }
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                    // Vuex.commit('setCatalogMH', response.data);
                    // localStorage.setItem('catalogMH', JSON.stringify(response.data));
                })
                .catch(error => {
                    reject(error.messageage)
                })
        });
    },
    getCities(statename) {
        const options = {
            method: 'GET',
            url: 'countriescities/getCities',
            headers: {'content-type': 'application/json'},
            params: {
                statename
            }
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                    // Vuex.commit('setCatalogMH', response.data);
                    // localStorage.setItem('catalogMH', JSON.stringify(response.data));
                })
                .catch(error => {
                    reject(error.messageageage)
                })
        });
    }


}